import { SVGProps } from 'react';

export const MLBLogo = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="20"
    viewBox="0 0 36 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.0042 0.0757483H3.00156C2.20619 0.0732055 1.44187 0.390789 0.874175 0.959697C0.593593 1.24669 0.371799 1.58788 0.221739 1.96336C0.071679 2.33884 -0.00364046 2.74108 0.000176871 3.14661V16.8191C-0.0041429 17.2232 0.0707045 17.624 0.220317 17.9981C0.369929 18.3722 0.591286 18.712 0.871365 18.9974C1.15106 19.283 1.48321 19.5093 1.84876 19.6635C2.21431 19.8176 2.60607 19.8965 3.00156 19.8957H33.0042C33.8005 19.8919 34.5628 19.566 35.1243 18.9894C35.6858 18.4127 36.0007 17.6323 35.9999 16.8191V3.14374C36.0029 2.74072 35.9275 2.34111 35.7781 1.96807C35.6287 1.59503 35.4083 1.256 35.1296 0.970611C34.851 0.685226 34.5196 0.459174 34.1547 0.305563C33.7899 0.151952 33.3988 0.0738375 33.0042 0.0757483V0.0757483Z"
      fill="white"
    />
    <path
      d="M34.5021 3.47232C34.505 3.2232 34.4581 2.976 34.3643 2.74518C34.2704 2.51436 34.1314 2.30454 33.9554 2.128C33.7794 1.95145 33.5699 1.81172 33.3391 1.71698C33.1084 1.62224 32.8611 1.57439 32.6116 1.57622H24.4478L29.1316 9.39283L29.5639 9.44747L29.8374 9.80538V10.1387L30.1329 10.1988L30.4065 10.5704V10.8928L30.7129 10.9474L31.0221 11.2835V12.0266C31.3901 12.3584 31.8049 12.6346 32.2532 12.8462C32.6663 13.0047 32.7128 13.6659 32.9618 14.0183C33.2737 14.532 33.7005 14.7369 33.6102 15.0237C33.4023 15.7915 32.6143 17.0865 31.8811 17.1466H28.9811V18.387H32.6143C32.8644 18.3859 33.1117 18.3355 33.3422 18.2387C33.5727 18.1419 33.7818 18.0006 33.9574 17.8229C34.1331 17.6452 34.2719 17.4346 34.3658 17.2032C34.4597 16.9718 34.507 16.7242 34.5048 16.4745V3.47232"
      fill="#BF0D3E"
    />
    <path
      d="M14.5304 17.1657H13.1269C13.1269 13.6631 14.3116 11.726 15.7315 11.3408C15.9257 11.3053 15.8327 10.3491 15.5865 10.0567H14.7657C14.6344 10.0567 14.711 9.81083 14.711 9.81083L15.3758 8.39285L15.2855 8.00489H12.8287L14.8478 6.59512C14.9408 2.8767 18.7601 2.58709 21.0609 4.1362C22.4288 5.04054 22.5328 6.83281 22.4288 8.07047C22.4124 8.1497 22.0732 8.09778 22.0732 8.09778C22.0732 8.09778 21.8406 9.46385 22.448 9.46385H25.1319C26.2262 9.42013 27.2822 10.1605 27.2822 10.1605L27.5394 9.22342L21.6601 1.5762H3.40371C2.89869 1.57326 2.41293 1.76965 2.0522 2.12263C1.87632 2.3004 1.73741 2.51119 1.6435 2.74283C1.54959 2.97448 1.50253 3.2224 1.50503 3.4723V16.4909C1.50189 16.74 1.54867 16.9873 1.64264 17.2182C1.73661 17.449 1.87586 17.6588 2.0522 17.8351C2.41071 18.1931 2.89673 18.3945 3.40371 18.3952H15.2554C14.9654 17.8979 14.6344 17.3324 14.5332 17.1657"
      fill="#041E42"
    />
    <path
      d="M4.13696 14.4034C4.13697 14.1468 4.21331 13.896 4.35629 13.6828C4.49928 13.4696 4.70246 13.3036 4.94005 13.2059C5.17765 13.1082 5.43895 13.0832 5.6908 13.1341C5.94265 13.185 6.17369 13.3094 6.3546 13.4916C6.53552 13.6738 6.65816 13.9056 6.70695 14.1575C6.75575 14.4094 6.7285 14.6701 6.62867 14.9066C6.52884 15.143 6.36092 15.3445 6.14623 15.4855C5.93154 15.6265 5.67975 15.7006 5.42281 15.6984C5.25292 15.6988 5.08465 15.6655 4.92776 15.6004C4.77087 15.5353 4.62848 15.4397 4.50887 15.3193C4.38925 15.1988 4.29477 15.0558 4.23093 14.8986C4.16709 14.7413 4.13515 14.573 4.13696 14.4034Z"
      fill="white"
    />
  </svg>
);
