import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';

import { sortBy } from '@sorare/core/src/lib/arrays';

import { FeaturedPlayersList } from '@sorare/us-sports/src/components/game/FeaturedPlayersList';
import { FeaturedPlayersListItem } from '@sorare/us-sports/src/components/game/FeaturedPlayersListItem';
import { ColoredScoreWithDelta } from '@sorare/us-sports/src/components/scores/ColoredScoreWithDelta';

import {
  FeaturedPlayersOutperformers_basketballPlayerGameScore,
  FeaturedPlayersOutperformers_team,
} from './__generated__/index.graphql';

type Props = {
  title: ReactNode;
  team: FeaturedPlayersOutperformers_team;
  playerGamesScores: FeaturedPlayersOutperformers_basketballPlayerGameScore[];
  over?: boolean;
  openPlayerInfoModal: (playerSlug: string) => void;
  onMouseHoverPlayer: (playerSlug: string) => void;
  onMouseLeavePlayer: () => void;
  mouseOnHoverPlayer: string | null;
};
export const FeaturedPlayersOutperformers = ({
  title,
  team,
  playerGamesScores,
  openPlayerInfoModal,
  onMouseHoverPlayer,
  onMouseLeavePlayer,
  mouseOnHoverPlayer,
  over,
}: Props) => {
  const sortedPlayerStats = sortBy(
    pS => (pS.score || 0) - (pS.anyPlayer.lastTenPlayedSo5AverageScore || 0),
    [
      ...playerGamesScores.filter(pS => {
        const diff =
          Math.floor(pS.score || 0) -
          Math.floor(pS.anyPlayer.lastTenPlayedSo5AverageScore || 0);
        return over ? diff > 0 : diff <= 0;
      }),
    ]
  );

  const topPlayerStats = over
    ? [...sortedPlayerStats].reverse()
    : sortedPlayerStats;

  return (
    <FeaturedPlayersList title={title} team={team}>
      {topPlayerStats.map(pS => {
        return (
          <FeaturedPlayersListItem
            key={pS.anyPlayer.slug}
            player={pS.anyPlayer}
            onClick={() => openPlayerInfoModal(pS.anyPlayer.slug)}
            onMouseEnter={() => onMouseHoverPlayer(pS.anyPlayer.slug)}
            onMouseLeave={onMouseLeavePlayer}
            highlighted={mouseOnHoverPlayer === pS.anyPlayer.slug}
            score={
              <ColoredScoreWithDelta
                score={pS.score || 0}
                averageGameScore={
                  pS.anyPlayer?.lastTenPlayedSo5AverageScore || 0
                }
                playedInGame={
                  (pS.basketballPlayerGameStats?.minsPlayed || 0) > 0
                }
              />
            }
          />
        );
      })}
    </FeaturedPlayersList>
  );
};

FeaturedPlayersOutperformers.fragments = {
  basketballPlayerGameScore: gql`
    fragment FeaturedPlayersOutperformers_basketballPlayerGameScore on BasketballPlayerGameScore {
      id
      score
      anyGame {
        id
      }
      anyPlayer {
        slug
        lastTenPlayedSo5AverageScore: averageScore(
          type: LAST_TEN_PLAYED_SO5_AVERAGE_SCORE
        )
        ...FeaturedPlayerListItem_anyPlayerInterface
      }
      basketballPlayerGameStats {
        id
        minsPlayed
        anyTeam {
          slug
        }
      }
    }
    ${FeaturedPlayersListItem.fragments.anyPlayerInterface}
  ` as TypedDocumentNode<FeaturedPlayersOutperformers_basketballPlayerGameScore>,
  team: gql`
    fragment FeaturedPlayersOutperformers_team on TeamInterface {
      slug
      ...FeaturedPlayersList_team
    }
    ${FeaturedPlayersList.fragments.team}
  ` as TypedDocumentNode<FeaturedPlayersOutperformers_team>,
};
