import { useCallback } from 'react';

import { AlgoliaCardIndexesName } from 'contexts/config';
import { useSportContext } from 'contexts/sport';
import { useGetInteractionContext } from 'hooks/useGetInteractionContext';

import useLifecycle, { LIFECYCLE } from './useLifecycle';

export const useMarketplaceLifecycle = () => {
  const { sport } = useSportContext();
  const { update, lifecycle } = useLifecycle();
  const getInteractionContext = useGetInteractionContext();

  const key = `${
    sport ? sport.toLocaleLowerCase() : ''
  }_${getInteractionContext()}`;

  const updateSort = useCallback(
    (sort: AlgoliaCardIndexesName) => {
      update(LIFECYCLE.marketSorts, {
        ...lifecycle?.marketSorts,
        [key]: sort,
      });
    },
    [update, lifecycle?.marketSorts, key]
  );

  return {
    sort: lifecycle?.marketSorts?.[key],
    updateSort,
  };
};
