import { TypedDocumentNode, gql } from '@apollo/client';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

import { SocialUser } from '../SocialUser';
import { DiscordUser_userProfile } from './__generated__/index.graphql';

type Props = {
  userProfile: DiscordUser_userProfile;
  coloredIcon?: boolean;
};

export const DiscordUser = ({
  userProfile: { discordUsername },
  coloredIcon,
}: Props) => {
  return (
    <SocialUser
      nickname={discordUsername}
      icon={faDiscord}
      color={coloredIcon ? 'var(--c-social-discord)' : undefined}
    />
  );
};

DiscordUser.fragments = {
  userProfile: gql`
    fragment DiscordUser_userProfile on UserProfile {
      id
      discordUsername
    }
  ` as TypedDocumentNode<DiscordUser_userProfile>,
};
