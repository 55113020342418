import { FC, Fragment } from 'react';
import styled from 'styled-components';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { Vertical } from 'atoms/layout/flex';

import { Dropdown, Props as DropdownProps } from '../Dropdown';

type Props = Omit<DropdownProps, 'children'> & {
  children: (
    | FC<React.PropsWithChildren<{ closeDropdown?: () => void }>>
    | undefined
    | null
    | false
  )[];
};

export const Item = styled(ButtonBase)`
  color: var(--c-neutral-1000);
  flex: 1;
  padding: var(--unit) var(--double-unit);
  display: flex;
  align-items: center;
  gap: var(--double-unit);
  &:hover,
  &:focus {
    background: var(--c-neutral-400);
  }
  &:disabled {
    background: var(--c-neutral-300);
    color: var(--c-neutral-600);
  }
`;

export const ItemText = styled.span`
  flex: 1;
  display: inline-flex;
  justify-self: flex-start;
  font: var(--t-bold) var(--t-16);
`;

const Wrapper = styled(Vertical).attrs({ gap: 0 })`
  background: var(--c-neutral-300);
`;

// SmartDropdown renders a dropdown only when the list of actions is strictly bigger than 1
export const SmartDropdown = ({ children, ...rest }: Props) => {
  const truthyChildren = children.filter(Boolean);
  if (truthyChildren.length === 0) {
    return null;
  }
  if (truthyChildren.length === 1) {
    return (
      <div>
        {truthyChildren.map(c => (
          <Fragment key="0">{c({})}</Fragment>
        ))}
      </div>
    );
  }

  return (
    <Dropdown {...rest}>
      {({ closeDropdown }: { closeDropdown: () => void }) => (
        <Wrapper>
          {truthyChildren.map((c, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>{c({ closeDropdown })}</Fragment>
          ))}
        </Wrapper>
      )}
    </Dropdown>
  );
};
