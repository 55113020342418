import { TypedDocumentNode, gql } from '@apollo/client';

// eslint-disable-next-line no-restricted-imports
import { RewardBox as RewardBoxForBuyConfirmation } from '@sorare/trois/src/components/BuyConfirmationRewardBox';
import { ProbabilisticBundleDesign } from '__generated__/globalTypes';
import { withFragments } from 'gql';
import {
  BOX_COLOR,
  BOX_LIGHT_INTENSITY,
  FRAME_COLOR,
} from 'lib/probabilisticBundle';

import { useFormatBoxForDisplay_ProbabilisticBundle } from './__generated__/useFormatBoxForDisplay.graphql';

type RewardBox = RewardBoxForBuyConfirmation & {
  name: string;
  pictureUrl: string;
};

export const useFormatBoxForDisplay = withFragments(
  (rewardBoxes: useFormatBoxForDisplay_ProbabilisticBundle[]): RewardBox[] => {
    return rewardBoxes.map<RewardBox>(box => {
      return {
        id: box.id,
        type:
          box.config.design === ProbabilisticBundleDesign.RIVALS
            ? 'rivals'
            : 'pro',
        name: box.config.name,
        pictureUrl: box.config.pictureUrl,
        boxColor: BOX_COLOR[box.config.quality],
        frameColor: FRAME_COLOR[box.config.quality],
        neonColor: box.config.neonColor,
        rewardColor: box.items.at(0)?.neonColor || '',
        intensity: BOX_LIGHT_INTENSITY[box.items.at(0)?.intensity || 'DEFAULT'],
      };
    });
  },
  {
    ProbabilisticBundle: gql`
      fragment useFormatBoxForDisplay_ProbabilisticBundle on ProbabilisticBundle {
        id
        opened
        config {
          id
          name
          pictureUrl
          design
          neonColor
          quality
        }
        items {
          id
          neonColor
          intensity
        }
      }
    ` as TypedDocumentNode<useFormatBoxForDisplay_ProbabilisticBundle>,
  }
);
