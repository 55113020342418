import { TypedDocumentNode, gql } from '@apollo/client';

import { getPositionInitials } from '@sorare/core/src/lib/positions';

import { MobileTokenDetails_BaseballCard } from './__generated__/index.graphql';

interface Props {
  card: MobileTokenDetails_BaseballCard;
}

export const MobileTokenDetails = ({ card }: Props) => {
  const positionsInitials = card.anyPositions.map(p => getPositionInitials(p));

  return <> • {positionsInitials.join(', ')}</>;
};

MobileTokenDetails.fragments = {
  anyCard: gql`
    fragment MobileTokenDetails_BaseballCard on BaseballCard {
      slug
      anyPositions
    }
  ` as TypedDocumentNode<MobileTokenDetails_BaseballCard>,
};

export default MobileTokenDetails;
