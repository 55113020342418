import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { absCenter } from '@sorare/core/src/lib/style';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import Signature from 'components/rewards/deliverableItem/DeliverableItemFooter/jersey/Signature';
import jersey_front_square from 'components/rewards/deliverableItem/DeliverableItemFooter/jersey/assets/jersey_front_square.png';
import mystery_logo from 'components/rewards/deliverableItem/DeliverableItemFooter/jersey/assets/mystery_logo.png';

import { JerseyRewardPreview_JerseyDetails } from './__generated__/index.graphql';

const JerseyBackground = styled(Vertical).attrs({ gap: 0 })`
  position: relative;
  width: 100%;
  max-width: 180px;
  background-image: url('${jersey_front_square}');
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 86 / 100;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--double-unit);
  margin: 0 auto;
  @media ${tabletAndAbove} {
    max-width: 300px;
    margin: 0;
  }
`;
const Logo = styled.img`
  width: 20%;
`;
const SignatureCtn = styled.div`
  ${absCenter}
  max-width: 90%;
  width: 90%;

  svg {
    max-width: 100%;
  }
`;

const JerseyRewardPreview = ({
  jersey,
  hideSignedVariant,
}: {
  jersey: JerseyRewardPreview_JerseyDetails;
  hideSignedVariant?: boolean;
}) => {
  const { team, competition, signed } = jersey;
  const logoUrl = team?.pictureUrl || competition?.pictureUrl || mystery_logo;
  return (
    <JerseyBackground>
      {signed && !hideSignedVariant && (
        <SignatureCtn>
          <Signature />
        </SignatureCtn>
      )}
      <Logo src={logoUrl} />
    </JerseyBackground>
  );
};

JerseyRewardPreview.fragments = {
  jersey: gql`
    fragment JerseyRewardPreview_JerseyDetails on JerseyDeliverableItem {
      slug
      id
      signed
      competition {
        slug
        name
        pictureUrl
      }
      team {
        ... on TeamInterface {
          slug
          name
          pictureUrl
        }
      }
    }
  ` as TypedDocumentNode<JerseyRewardPreview_JerseyDetails>,
};

export default JerseyRewardPreview;
