import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { keyframes } from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { BodyL, DisplayS, LabelL } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { glossary } from '@sorare/core/src/lib/glossary';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import haaland from './assets/haaland.png';
import notificationsPromptBg from './assets/notifications-prompt-bg.jpg';

const Wrapper = styled(Vertical).attrs({ gap: 3 })`
  background: url(${notificationsPromptBg}) center / cover no-repeat;
  display: flex;
  padding: var(--double-unit) 0 0;
  align-items: center;
  gap: var(--triple-unit);
  height: var(--100vh);
  overflow: hidden;
  @media ${laptopAndAbove} {
    max-height: min(90vh, 900px);
  }
`;

const titleAnimation = keyframes`
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Title = styled(Vertical)`
  opacity: 0;
  animation: ${titleAnimation} 0.4s 0.7s ease-in forwards;
  padding: 0 var(--double-unit);
`;

const Title1 = styled(BodyL)`
  background: linear-gradient(323deg, #ae5e13 14.3%, #dfad46 85.7%);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

const contentAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CenterContent = styled(Vertical)`
  flex: 1;
  overflow: hidden;
  opacity: 0;
  animation: ${contentAnimation} 0.4s 0.7s ease-in forwards;
`;

const MbappeImgWrapper = styled(Horizontal).attrs({ gap: 0 })`
  flex: 1;
  overflow: hidden;
  align-items: flex-end;
  & > img {
    aspect-ratio: 745/1166;
    max-height: 100%;
    max-width: 110%;
    object-fit: cover;
  }
`;

const Buttons = styled(SBHorizontal)`
  align-self: stretch;
  opacity: 0;
  animation: ${contentAnimation} 0.4s 0.7s ease-in forwards;
  padding: 0 var(--double-unit);
  position: absolute;
  bottom: var(--double-unit);
  left: 0;
  right: 0;
`;
type Props = {
  onNext: () => void;
  open: boolean;
};

export const EnableNotificationsPrompt = ({ onNext, open }: Props) => {
  const { postMessage } = useIsMobileApp();
  const [messagePosted, setMessagePosted] = useState(false);
  return (
    <Dialog open={open} maxWidth="xs">
      <Wrapper>
        <Title center gap={0}>
          <FormattedMessage
            id="EnableNotificationsPrompt.title"
            defaultMessage="<line1>Your Pro</line1><line2>Adventure Begins</line2>"
            values={{
              line1: chunks => <Title1 bold>{chunks}</Title1>,
              line2: chunks => <DisplayS>{chunks}</DisplayS>,
            }}
          />
          <LabelL color="var(--c-neutral-700)" className="text-center">
            <FormattedMessage
              id="EnableNotificationsPrompt.subtitle"
              defaultMessage="You can be notified when you start scoring points."
            />
          </LabelL>
        </Title>
        <CenterContent center gap={0}>
          <MbappeImgWrapper>
            <img src={haaland} alt="" />
          </MbappeImgWrapper>
        </CenterContent>
        <Buttons>
          {!messagePosted && (
            <Button color="quaternary" size="large" fullWidth onClick={onNext}>
              <FormattedMessage
                id="EnableNotificationsPrompt.disable"
                defaultMessage="Not now"
              />
            </Button>
          )}
          <Button
            color="secondary"
            size="large"
            fullWidth
            onClick={() => {
              if (!messagePosted) {
                postMessage('enablePushNotifications');
                setMessagePosted(true);
              } else {
                onNext();
              }
            }}
          >
            {messagePosted ? (
              <FormattedMessage {...glossary.continue} />
            ) : (
              <FormattedMessage
                id="EnableNotificationsPrompt.enable"
                defaultMessage="Enable notifications"
              />
            )}
          </Button>
        </Buttons>
      </Wrapper>
    </Dialog>
  );
};
