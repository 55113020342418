import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import defaultShield from '@sorare/core/src/assets/club/shield_none.png';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { BodyM, LabelL, LabelM } from '@sorare/core/src/atoms/typography';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';
import { Color } from '@sorare/core/src/style/types';

import { isGameStarted } from 'lib/so5';

import { GameStatusLabel } from '../GameStatusLabel';
import { BriefGame_anyGameInterface } from './__generated__/index.graphql';

const Root = styled(ButtonBase)`
  width: 100%;
  text-align: left;
  cursor: default;
  background: var(--c-nd-50);
  border-radius: var(--double-unit);
  padding: var(--double-unit);

  @media ${tabletAndAbove} {
    &.withHoverStyles {
      padding: var(--double-unit);
      cursor: pointer;
      border-radius: var(--double-unit);

      &:hover {
        background-color: var(--c-nd-50);
      }
    }
  }
`;
const StyledImg = styled.img`
  object-fit: contain;
`;

const getTeamTextColor = (
  game: BriefGame_anyGameInterface,
  teamSlug?: string
): Color => {
  return !game.winner || game.winner?.slug === teamSlug
    ? 'var(--c-neutral-1000)'
    : 'var(--c-neutral-500)';
};

type Props = {
  game: BriefGame_anyGameInterface;
  onClick?: () => void;
};
export const BriefGame = ({ game, onClick }: Props) => {
  const { sport } = useSportContext();
  const hasGameStarted = isGameStarted(game.status);
  const isUsSports = sport !== Sport.FOOTBALL;

  const homeTeam = isUsSports ? game.awayTeam : game.homeTeam;
  const homeScore = isUsSports ? game.awayScore : game.homeScore;
  const awayTeam = isUsSports ? game.homeTeam : game.awayTeam;
  const awayScore = isUsSports ? game.homeScore : game.awayScore;

  return (
    <Root
      as={onClick ? 'button' : 'div'}
      onClick={onClick}
      className={classNames({ withHoverStyles: !!onClick })}
    >
      <Vertical gap={1.5}>
        <LabelM bold color="var(--c-nd-600)">
          <GameStatusLabel game={game} />
        </LabelM>
        <Vertical gap={1}>
          <SBHorizontal>
            <Horizontal gap={1}>
              <StyledImg
                src={homeTeam?.pictureUrl || defaultShield}
                alt=""
                width="16px"
                height="16px"
              />
              <LabelL bold color={getTeamTextColor(game, homeTeam?.slug)}>
                {homeTeam?.name}
              </LabelL>
            </Horizontal>
            <BodyM
              as="p"
              bold
              color={
                hasGameStarted
                  ? getTeamTextColor(game, homeTeam?.slug)
                  : 'var(--c-nd-600)'
              }
            >
              {hasGameStarted ? homeScore : '-'}
            </BodyM>
          </SBHorizontal>
          <SBHorizontal>
            <Horizontal gap={1}>
              <StyledImg
                src={awayTeam?.pictureUrl || defaultShield}
                alt=""
                width="16px"
                height="16px"
              />
              <LabelL bold color={getTeamTextColor(game, awayTeam?.slug)}>
                {awayTeam?.name}
              </LabelL>
            </Horizontal>
            <BodyM
              as="p"
              bold
              color={
                hasGameStarted
                  ? getTeamTextColor(game, awayTeam?.slug)
                  : 'var(--c-nd-600)'
              }
            >
              {hasGameStarted ? awayScore : '-'}
            </BodyM>
          </SBHorizontal>
        </Vertical>
      </Vertical>
    </Root>
  );
};

BriefGame.fragments = {
  anyGameInterface: gql`
    fragment BriefGame_anyGameInterface on AnyGameInterface {
      id
      status: statusTyped
      homeScore
      homeTeam {
        slug
        name
        pictureUrl
        ... on NationalTeam {
          slug
          country {
            slug
            code
          }
        }
      }
      awayScore
      awayTeam {
        slug
        name
        pictureUrl
        ... on NationalTeam {
          slug
          country {
            slug
            code
          }
        }
      }
      winner {
        slug
      }
      ...GameStatusLabel_anyGameInterface
    }
    ${GameStatusLabel.fragments.anyGameInterface}
  ` as TypedDocumentNode<BriefGame_anyGameInterface>,
};
