import {
  type ComponentProps,
  MutableRefObject,
  Ref,
  RefCallback,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Link as RRDLink,
  UNSAFE_DataRouterContext,
  matchRoutes,
} from 'react-router-dom';
import Helmet from 'react-helmet';

export { getRoutesFromFiles, toRoutePath } from './autoroute';

function mergeRefs<T = any>(
  ...refs: Array<MutableRefObject<T> | Ref<T>>
): RefCallback<T> {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };
}

type PreloadRoute =
  | {
      routeToFile: Record<string, string>;
      fileToImports: Record<string, string[]>;
    }
  | undefined;

function unique<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}

function getFilesFromMatch(
  matches: NonNullable<ReturnType<typeof matchRoutes>>
) {
  return unique(
    matches.flatMap((match, i) =>
      [
        i === matches.length - 1 ? match.route.handle?.page : null,
        match.route.handle?.layout,
      ].filter(Boolean)
    )
  );
}

type Prefetch =
  // Explicitly opt out of prefetch
  | null
  // Prefetch on link render
  | 'render'
  // Prefetch on hover
  | 'intent';

const PrefetchContext = createContext<Prefetch>(null);

export const usePrefetch = <T extends HTMLElement>(
  ref: MutableRefObject<T> | Ref<T>,
  prefetchProp?: Prefetch
) => {
  const defaultPrefetch = useContext(PrefetchContext) || null;
  const prefetch = prefetchProp ?? defaultPrefetch;
  const innerRef = useRef<T>(null);
  const mergedRef = mergeRefs(ref, innerRef);
  const [shouldPrefetch, setShouldPrefetch] = useState(false);

  useEffect(() => {
    const node = innerRef.current;
    if (node && prefetch === 'intent') {
      const cb = () => {
        setShouldPrefetch(true);
      };
      node.addEventListener('pointerenter', cb);
      return () => node.removeEventListener('pointerenter', cb);
    }
    return () => {};
  }, [prefetch]);

  return [mergedRef, prefetch === 'render' ? true : shouldPrefetch] as const;
};

export const PrefetchLink = ({
  to,
}: Pick<ComponentProps<typeof RRDLink>, 'to'>) => {
  const { router } = useContext(UNSAFE_DataRouterContext) ?? {};
  // @ts-expect-error preloadRoute
  const { preloadRoutes }: { preloadRoutes: PreloadRoute } = window;
  if (preloadRoutes && router) {
    const match = matchRoutes(router.routes, to);
    if (match) {
      const files = getFilesFromMatch(match);
      const outputFiles = files.map(file => preloadRoutes.routeToFile[file]);
      const imports = new Set(outputFiles);
      for (const f of outputFiles) {
        for (const i of preloadRoutes.fileToImports[f]) {
          imports.add(i);
        }
      }
      return (
        <Helmet>
          {[...imports].map(e => (
            <link rel="modulepreload" key={e} href={`/${e}`} />
          ))}
        </Helmet>
      );
    }
  }
  return null;
};

export const DefaultPrefetchProvider = PrefetchContext.Provider;
