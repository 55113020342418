import { TypedDocumentNode, gql, useMutation } from '@apollo/client';

import { useGetInteractionContext } from 'hooks/useGetInteractionContext';

import {
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables,
} from './__generated__/useSubscribe.graphql';

const CREATE_SUBSCRIPTION_MUTATION = gql`
  mutation CreateSubscriptionMutation($input: createSubscriptionInput!) {
    createSubscription(input: $input) {
      subscription {
        slug
        anySubscribable {
          slug
          currentUserSubscription {
            slug
          }
          subscriptionsCount
        }
        preferences {
          slug
          notifyForRarities
        }
      }
    }
  }
` as TypedDocumentNode<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;

export default function useSubscribe() {
  const [subscribe] = useMutation(CREATE_SUBSCRIPTION_MUTATION);
  const interactionContext = useGetInteractionContext()();

  return async (subscribable: { __typename: string; slug: string }) => {
    return subscribe({
      variables: {
        input: {
          subscribable: {
            type: subscribable.__typename,
            slug: subscribable.slug,
          },
          interactionContext,
        },
      },
    });
  };
}
