import { TypedDocumentNode, gql } from '@apollo/client';
import { Suspense, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';

import { So5LeaderboardType } from '@sorare/core/src/__generated__/globalTypes';
import {
  FOOTBALL_BESTOFEUROPE,
  FOOTBALL_PLAY,
} from '@sorare/core/src/constants/__generated__/routes';
import { useSeoContext } from '@sorare/core/src/contexts/seo';
import { useIsLoggedIn } from '@sorare/core/src/hooks/auth/useIsLoggedIn';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import useAfterLoggedInTarget from '@sorare/core/src/hooks/useAfterLoggedInTarget';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { useLocalVariants } from '@sorare/core/src/hooks/useLocalVariants';
import { sortByTheMiddle } from '@sorare/core/src/lib/arrays';
import { lazy } from '@sorare/core/src/lib/retry';

import {
  FootballLeaguesQuery,
  FootballLeaguesQueryVariables,
} from './__generated__/page.graphql';
import competeCardsAvif from './assets/compete/cards.avif';
import competeCards from './assets/compete/cards.png';
import competeFieldAvif from './assets/compete/field.avif';
import competeField from './assets/compete/field.jpg';
import scoutBackgroundAvif from './assets/scout/background.avif';
import scoutBackground from './assets/scout/background.jpg';
import scoutPlayersAvif from './assets/scout/players.avif';
import scoutPlayers from './assets/scout/players.png';
import winCardsAvif from './assets/win/cards.avif';
import winCards from './assets/win/cards.png';

const FootballPublicHome = lazy(
  async () => import('components/landing/Public')
);
const EarlyAccess = lazy(async () => import('components/landing/EarlyAccess'));

const messages = defineMessages({
  metadatasTitle: {
    id: 'Football.Home.Public.meta.title',
    defaultMessage:
      'Play fantasy football with ownable digital player cards - Sorare',
  },
  metadatasDescription: {
    id: 'Football.Home.Public.meta.description',
    defaultMessage:
      "Play Sorare's free fantasy football game. Collect, buy, sell & compete with ownable digital player cards from the world's top leagues to win cash prizes.",
  },
});
const assets = {
  scout: {
    background: {
      avif: scoutBackgroundAvif,
      pictureUrl: scoutBackground,
    },
    players: { avif: scoutPlayersAvif, pictureUrl: scoutPlayers },
  },
  compete: {
    field: {
      avif: competeFieldAvif,
      pictureUrl: competeField,
    },
    cards: { avif: competeCardsAvif, pictureUrl: competeCards },
  },
  win: {
    cards: {
      avif: winCardsAvif,
      pictureUrl: winCards,
    },
  },
};

const FOOTBALL_LEAGUES_QUERY = gql`
  query FootballLeaguesQuery {
    football {
      leaguesOpenForGameStats(sortType: { direction: ASC, type: PRIORITY }) {
        slug
        id
        displayName
        pictureSecondaryUrl
      }
    }
  }
` as TypedDocumentNode<FootballLeaguesQuery, FootballLeaguesQueryVariables>;

type Props = {
  isRoot?: boolean;
};
const FootballPage = ({ isRoot }: Props) => {
  const { flags: { useEarlyAccessLanding = false } = {} } = useFeatureFlags();
  const isLoggedIn = useIsLoggedIn();
  const { formatMessage } = useIntl();
  const { setPageMetadata } = useSeoContext();
  const afterLoggedInTarget = useAfterLoggedInTarget();

  const { data } = useQuery(FOOTBALL_LEAGUES_QUERY, {
    fetchPolicy: 'cache-first',
  });
  const filteredLeagues = (
    data?.football?.leaguesOpenForGameStats || []
  ).filter(({ pictureSecondaryUrl }) => !!pictureSecondaryUrl);
  const leagues = sortByTheMiddle(filteredLeagues);
  const abTestUseBestOfEuropeLanding = useLocalVariants(
    'useAbTestBestOfEuropeLanding'
  );

  useEffect(
    () =>
      setPageMetadata(formatMessage(messages.metadatasTitle), {
        img: 'https://frontend-assets.sorare.com/meta/social-picture-football-2024.jpg',
        description: formatMessage(messages.metadatasDescription),
      }),
    [setPageMetadata, formatMessage]
  );

  if (!isLoggedIn) {
    if (abTestUseBestOfEuropeLanding === 'enabled' && isRoot) {
      return (
        <Navigate to={FOOTBALL_BESTOFEUROPE} state={{ afterLoggedInTarget }} />
      );
    }
    return (
      <Suspense>
        {useEarlyAccessLanding ? (
          <EarlyAccess
            leagues={leagues}
            showFAQ={!isRoot}
            starPlayerSlug="erling-haland"
            assets={assets}
            variant="Football Early Access"
            leaderboards={[
              So5LeaderboardType.IN_SEASON_CHAMPIONS_LIMITED,
              So5LeaderboardType.IN_SEASON_CHALLENGERS_UNIQUE,
              So5LeaderboardType.IN_SEASON_CONTENDERS_RARE,
            ]}
          />
        ) : (
          <FootballPublicHome />
        )}
      </Suspense>
    );
  }

  return <Navigate to={FOOTBALL_PLAY} replace />;
};

export default FootballPage;
