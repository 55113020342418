import { TypedDocumentNode, gql } from '@apollo/client';
import {
  faCalendar,
  faCalendarXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { compareAsc } from 'date-fns';
import { ComponentType, ReactNode, useMemo, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { KnownTarget } from 'styled-components/dist/types';

import {
  GameStatus,
  ScoreStatus,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon, IconDefinition } from '@sorare/core/src/atoms/icons';
import { Select } from '@sorare/core/src/atoms/inputs/Select';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelS, Title6 } from '@sorare/core/src/atoms/typography';
import { TeamAvatar } from '@sorare/core/src/components/club/TeamAvatar';
import { FixtureChart as DumbFixtureChart } from '@sorare/core/src/components/player/FixtureChart';
import { StatIcon } from '@sorare/core/src/components/stats/StatIcon';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { groupBy, range } from '@sorare/core/src/lib/arrays';
import {
  gameStatusMessages,
  playerDetails,
  playerGameStatusLabels,
} from '@sorare/core/src/lib/glossary';
import {
  negativeDecisiveStatKeys,
  positiveDecisiveStatKeys,
} from '@sorare/core/src/lib/scoring';
import { findThresholdColor } from '@sorare/core/src/lib/thresholds';

import PlayerUnavailabilityBadge from 'components/player/PlayerUnavailabilityBadge';
import { PlayerGameScoreDialog } from 'components/stats/PlayerGameScoreDialog';
import { PlayerGameScoreStatusIcon } from 'components/stats/PlayerGameScoreStatusIcon';
import useGetSuspensionsAndInjuries from 'hooks/useGetSuspensionsAndInjuries';
import { getDecisiveActions } from 'lib/so5';

import {
  FixtureChart_anyPlayer,
  FixtureChart_playerGameScoreInterface,
} from './__generated__/index.graphql';

export type Props = {
  so5Scores: (FixtureChart_playerGameScoreInterface | null)[];
  player: FixtureChart_anyPlayer;
  InfiniteScrollLoader?: ReactNode;
  onScoreSelect?: ({
    scoreId,
    gameId,
  }: {
    scoreId: string;
    gameId: string;
  }) => void;
  disableScoreSelect?: boolean;
  TitleComponent?: ComponentType<{ children: ReactNode; as: KnownTarget }>;
  inDrawer?: boolean;
};

enum TabValue {
  all = 'all',
  home = 'home',
  away = 'away',
}
const NO_GAME_WEEK_NUMBER = '0';
const tabLabels = defineMessages({
  home: {
    id: 'Score.home',
    defaultMessage: 'Home',
  },
  away: {
    id: 'Score.away',
    defaultMessage: 'Away',
  },
  all: {
    id: 'Score.all',
    defaultMessage: 'All',
  },
});

const GamesContainer = styled.div`
  position: relative;
  mask-image: linear-gradient(to right, transparent 0%, black 10%);
`;

const isHomeGame = (
  gameStats: FixtureChart_playerGameScoreInterface['anyPlayerGameStats']
): boolean => {
  return gameStats.anyTeam.slug === gameStats.anyGame.homeTeam?.slug;
};

const gameOpponent = (
  gameStats: FixtureChart_playerGameScoreInterface['anyPlayerGameStats']
):
  | FixtureChart_playerGameScoreInterface['anyPlayerGameStats']['anyGame']['homeTeam']
  | FixtureChart_playerGameScoreInterface['anyPlayerGameStats']['anyGame']['awayTeam']
  | null => {
  return isHomeGame(gameStats)
    ? gameStats.anyGame.awayTeam
    : gameStats.anyGame.homeTeam;
};

const Avatar = styled(TeamAvatar)`
  margin: auto;
  height: 20px;
  max-width: 20px;
  --size: 20px;
`;
const StyledLabelS = styled(LabelS)`
  word-spacing: 100vw;
`;

const StatusIcon: {
  [key in GameStatus]?: IconDefinition;
} = {
  [GameStatus.postponed]: faCalendar,
  [GameStatus.cancelled]: faCalendarXmark,
  [GameStatus.canceled]: faCalendarXmark,
};

export const FixtureChart = ({
  so5Scores,
  player,
  onScoreSelect,
  InfiniteScrollLoader,
  TitleComponent = Title6,
  disableScoreSelect = false,
  inDrawer,
}: Props) => {
  const { sport } = useSportContext();
  const { isIosApp } = useIsMobileApp();
  const { formatMessage, formatDate } = useIntl();
  const [playerGameScore, setPlayerGameScore] = useState<
    FixtureChart_playerGameScoreInterface | undefined
  >();

  const [homeAwayTab, setHomeAwayTab] = useState<TabValue>(TabValue.all);

  const getSuspensionsAndInjuries = useGetSuspensionsAndInjuries(player);
  const variant = sport === Sport.FOOTBALL ? 'month' : 'fixture';
  const maxScore = useMemo(() => {
    if (sport === Sport.FOOTBALL) {
      return 100;
    }
    return undefined;
  }, [sport]);

  if (!so5Scores?.length) return null;

  const deduplicatedScores = so5Scores
    .filter(Boolean)
    .filter(
      (score, index, self) =>
        index ===
        self.findIndex(
          t =>
            t.anyPlayerGameStats.anyGame.id ===
            score.anyPlayerGameStats.anyGame.id
        )
    );

  const sortedScores = deduplicatedScores.sort((a, b) =>
    compareAsc(a.anyGame.date, b.anyGame.date)
  );

  const gamesByMonth = groupBy(sortedScores, t => {
    const { date } = t.anyGame;
    return new Date(date.getFullYear(), date.getMonth(), 1).toString();
  });

  const gamesByFixture = groupBy(sortedScores, t => {
    const gameWeek = t.anyPlayerGameStats.anyGame.so5Fixture?.gameWeek;
    return gameWeek?.toString() || NO_GAME_WEEK_NUMBER;
  });

  const sortedGames = variant === 'month' ? gamesByMonth : gamesByFixture;
  const normalized = Object.keys(sortedGames).map(key => ({
    key,
    label:
      variant === 'month'
        ? formatDate(key, { month: 'short', year: '2-digit' })
        : `${key === NO_GAME_WEEK_NUMBER ? '' : `GW ${key}`} `,
    games: (sortedGames[key] ?? []).map(score => {
      const { status } = score.anyPlayerGameStats.anyGame;
      const gamePlayed =
        status !== GameStatus.postponed &&
        status !== GameStatus.canceled &&
        status !== GameStatus.cancelled;
      const isDNP = score.scoreStatus === ScoreStatus.DID_NOT_PLAY;
      const playerScore =
        !isDNP && gamePlayed && typeof score.score === 'number'
          ? score.score
          : undefined;
      const decisiveActions = getDecisiveActions(score);
      return {
        id: score.anyGame.id,
        playerScore,
        startDate: score.anyGame.date,
        gameLabel: (
          <Vertical center>
            <Avatar team={gameOpponent(score.anyPlayerGameStats)} />
            <PlayerGameScoreStatusIcon size={2} playerGameScore={score} />
            {variant !== 'fixture' && (
              <StyledLabelS
                color="var(--c-neutral-600)"
                className="text-center"
              >
                {score.anyPlayerGameStats.anyGame.so5Fixture?.shortDisplayName.replace(
                  /GW\s?([0-9]+)/,
                  'GW $1'
                )}
              </StyledLabelS>
            )}
          </Vertical>
        ),
        barContent: (
          <Vertical gap={0.5}>
            {positiveDecisiveStatKeys.map(statKey =>
              range(decisiveActions[statKey] || 0).map(i => (
                <StatIcon key={`${statKey}-${i}`} statKey={statKey} />
              ))
            )}
          </Vertical>
        ),
        aboveBarContent: (
          <Vertical gap={0.5}>
            {negativeDecisiveStatKeys.map(statKey =>
              range(decisiveActions[statKey] || 0).map(i => (
                <StatIcon key={`${statKey}-${i}`} statKey={statKey} />
              ))
            )}
          </Vertical>
        ),
        color:
          homeAwayTab === TabValue.all ||
          (homeAwayTab === TabValue.away &&
            !isHomeGame(score.anyPlayerGameStats)) ||
          (homeAwayTab === TabValue.home &&
            isHomeGame(score.anyPlayerGameStats))
            ? findThresholdColor(
                score.score || 0,
                sport || Sport.FOOTBALL,
                'PLAYER_SCORE'
              )
            : 'var(--c-neutral-500)',
        ...(!isIosApp &&
          !disableScoreSelect && {
            onClick: () => {
              if (onScoreSelect) {
                onScoreSelect({
                  scoreId: idFromObject(score.id),
                  gameId: idFromObject(score.anyGame.id),
                });
                return;
              }
              setPlayerGameScore(score);
            },
          }),
        dnpLabel: !gamePlayed ? (
          <FontAwesomeIcon
            icon={StatusIcon[status]!}
            title={formatMessage(gameStatusMessages[status])}
            size="lg"
          />
        ) : (
          <>
            <PlayerUnavailabilityBadge
              {...getSuspensionsAndInjuries<FixtureChart_anyPlayer>(
                score.anyGame
              )}
            />
            <FormattedMessage {...playerGameStatusLabels.did_not_play_short} />
          </>
        ),
      };
    }),
  }));

  return (
    <>
      <Vertical gap={1.5}>
        <SBHorizontal gap={2}>
          <TitleComponent as="h2">
            <FormattedMessage {...playerDetails.performance} />
          </TitleComponent>
          <Select
            small
            menuLateralAlignment="right"
            options={Object.values(TabValue).map(val => ({
              value: val,
              label: <FormattedMessage {...tabLabels[val]} />,
            }))}
            value={{
              value: homeAwayTab,
              label: <FormattedMessage {...tabLabels[homeAwayTab]} />,
            }}
            onChange={received => received && setHomeAwayTab(received.value)}
          />
        </SBHorizontal>
        <GamesContainer>
          <DumbFixtureChart
            InfiniteScrollLoader={InfiniteScrollLoader}
            fixturesStats={normalized}
            yMaxValue={maxScore}
          />
        </GamesContainer>
      </Vertical>
      {playerGameScore && (
        <PlayerGameScoreDialog
          playerGameScoreId={playerGameScore.id}
          onClose={() => setPlayerGameScore(undefined)}
          open={!!playerGameScore}
          showUpsell
          inDrawer={inDrawer}
        />
      )}
    </>
  );
};

FixtureChart.fragments = {
  playerGameScoreInterface: gql`
    fragment FixtureChart_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      score
      scoreStatus
      anyPlayerGameStats {
        id
        ... on PlayerGameStats {
          id
          minsPlayed
        }
        anyTeam {
          slug
        }
        anyGame {
          id
          status: statusTyped
          so5Fixture {
            slug
            gameWeek
            shortDisplayName
          }
          homeTeam {
            slug
            ...TeamAvatar_team
          }
          awayTeam {
            slug
            ...TeamAvatar_team
          }
        }
      }
      ...useGetSuspensionsAndInjuries_playerGameScoreInterface
      ...PlayerGameScoreStatusIcon_playerGameScoreInterface
      ...getDecisiveActions_playerGameScoreInterface
    }
    ${TeamAvatar.fragments.team}
    ${useGetSuspensionsAndInjuries.fragments.playerGameScoreInterface}
    ${PlayerGameScoreStatusIcon.fragments.playerGameScoreInterface}
    ${getDecisiveActions.fragments.playerGameScoreInterface}
  ` as TypedDocumentNode<FixtureChart_playerGameScoreInterface>,
  anyPlayer: gql`
    fragment FixtureChart_anyPlayer on AnyPlayerInterface {
      slug
      injuries {
        id
        ...PlayerUnavailabilityBadge_injury
      }
      suspensions {
        id
        ...PlayerUnavailabilityBadge_suspension
      }
      ...useGetSuspensionsAndInjuries_anyPlayer
    }
    ${useGetSuspensionsAndInjuries.fragments.anyPlayer}
    ${PlayerUnavailabilityBadge.fragments.injury}
    ${PlayerUnavailabilityBadge.fragments.suspension}
  ` as TypedDocumentNode<FixtureChart_anyPlayer>,
};
