import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';

const ListContainer = styled(Vertical)`
  align-items: flex-start;
  position: relative;
  width: 100%;
`;
const ListOptionsContainer = styled.div`
  width: 100%;
`;

type Props = {
  children?: ReactNode;
  hidden?: boolean;
  search?: ReactNode;
};

export const FilterSection = ({ children, hidden, search }: Props) => {
  return (
    <ListContainer className={classNames('FilterWidget', { visible: !hidden })}>
      {search}
      <ListOptionsContainer>{children}</ListOptionsContainer>
    </ListContainer>
  );
};
