import { ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { BoxLink } from '@sorare/core/src/atoms/ui/Box';
import { CardImg } from '@sorare/core/src/components/card/CardImg';

const LineupCardBox = styled(BoxLink)`
  padding: var(--unit);
  outline: 1px solid rgba(var(--c-rgb-neutral-400), 0.25);

  & img {
    width: calc(10 * var(--unit));
    object-fit: cover;
  }
`;

type Props = {
  to: string;
  fullImageUrl: string;
  label?: string;
  tooltip: NonNullable<ReactNode>;
  playerCardScore: ReactNode;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const PlayerInLineupLinkBox = ({
  to,
  fullImageUrl,
  label,
  tooltip,
  playerCardScore,
  className,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  return (
    <Tooltip title={tooltip}>
      <LineupCardBox
        to={to}
        className={className}
        labelProps={{ value: label }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Vertical gap={1} center>
          <Horizontal>
            <CardImg src={fullImageUrl} alt="" width={160} />
          </Horizontal>
          {playerCardScore}
        </Vertical>
      </LineupCardBox>
    </Tooltip>
  );
};
