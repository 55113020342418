import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Title5 } from '@sorare/core/src/atoms/typography';
import { InjuryTable } from '@sorare/core/src/components/player/InjuryTable';
import { isType } from '@sorare/core/src/gql';
import { getPlayerInjuryColor } from '@sorare/core/src/lib/baseball';
import { playerDetails } from '@sorare/core/src/lib/glossary';

import { PlayerInjuryReport_anyPlayer } from './__generated__/index.graphql';

type Props = {
  player: PlayerInjuryReport_anyPlayer;
};

export const PlayerInjuryReport = ({ player }: Props) => {
  if (
    (!isType(player, 'NBAPlayer') && !isType(player, 'BaseballPlayer')) ||
    !player.playerInjury
  )
    return null;
  return (
    <Vertical gap={1}>
      <Title5>
        <FormattedMessage {...playerDetails.injuryReport} />
      </Title5>
      <InjuryTable
        injury={player.playerInjury}
        getPlayerInjuryColor={getPlayerInjuryColor}
      />
    </Vertical>
  );
};

PlayerInjuryReport.fragments = {
  anyPlayer: gql`
    fragment PlayerInjuryReport_anyPlayer on AnyPlayerInterface {
      slug
      ... on BaseballPlayer {
        slug
        # eslint-disable-next-line @graphql-eslint/no-deprecated
        playerInjury {
          ...InjuryTable_PlayerInjury
        }
      }
      ... on NBAPlayer {
        slug
        # eslint-disable-next-line @graphql-eslint/no-deprecated
        playerInjury {
          ...InjuryTable_PlayerInjury
        }
      }
    }
    ${InjuryTable.fragments.PlayerInjury}
  ` as TypedDocumentNode<PlayerInjuryReport_anyPlayer>,
};
