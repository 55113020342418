import { Sport, SportOrShared } from '__generated__/globalTypes';

export const sportToSportOrShared: Record<Sport, SportOrShared> = {
  [Sport.FOOTBALL]: SportOrShared.FOOTBALL,
  [Sport.NBA]: SportOrShared.NBA,
  [Sport.BASEBALL]: SportOrShared.BASEBALL,
};

export const sportOrSharedToSport: Record<SportOrShared, Sport | null> = {
  [SportOrShared.FOOTBALL]: Sport.FOOTBALL,
  [SportOrShared.NBA]: Sport.NBA,
  [SportOrShared.BASEBALL]: Sport.BASEBALL,
  [SportOrShared.GLOBAL]: null,
};

export const getSportFromSportOrShared = (
  sportOrShared: SportOrShared | null | undefined
) => {
  if (!sportOrShared) return null;
  return sportOrSharedToSport[sportOrShared];
};

export const getSportOrSharedFromSport = (sport: Sport | null | undefined) => {
  if (!sport) return null;
  return sportToSportOrShared[sport];
};
