import { useEffect } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Options, useSeoContext } from 'contexts/seo';

type useTitleAndDescriptionType = {
  (
    title: MessageDescriptor,
    description?: MessageDescriptor,
    options?: Omit<Options, 'description'>
  ): void;
  /**
   * Set values to false until they are loaded
   */
  (
    title: MessageDescriptor,
    description: MessageDescriptor | undefined,
    values: false | Record<string, any>,
    options?: Omit<Options, 'description'>
  ): void;
};

export const useTitleAndDescription: useTitleAndDescriptionType = (
  title: MessageDescriptor,
  description?: MessageDescriptor,
  values?: false | Record<string, any>,
  options?: Omit<Options, 'description'>
) => {
  const { setPageMetadata } = useSeoContext();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (values !== false) {
      return setPageMetadata(formatMessage(title, values), {
        ...(options || {}),
        description: description
          ? formatMessage(description, values)
          : undefined,
      });
    }
    return () => {};
  }, [setPageMetadata, formatMessage, title, values, description, options]);
};
