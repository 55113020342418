import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import {
  MarketplacePromotionalEvent,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { useConfigContext } from '@sorare/core/src/contexts/config';

import { useTokenTakesPartPromotionalEvent_anyCard } from './__generated__/useTokenTakesPartPromotionalEvent.graphql';

const getTokenEvent = (
  token: { assetId: string; slug?: string; sport: Sport } | undefined | null,
  promotionalEvents: {
    sport: Sport;
    events: MarketplacePromotionalEvent[];
  }[]
) => {
  if (!token) return undefined;

  const { sport, slug, assetId } = token;

  const objectId =
    sport === Sport.FOOTBALL ? slug! : `baseball-assetId:${assetId}`;
  const sportEvents = promotionalEvents.find(
    event => event.sport === sport
  )?.events;

  return sportEvents?.find(
    event => event.rewardDetailsHref && event.objectIds.includes(objectId)
  );
};

const useTokenTakesPartPromotionalEvent = () => {
  const { marketplacePromotionalEvents } = useConfigContext();

  return useCallback(
    (
      cards: useTokenTakesPartPromotionalEvent_anyCard[]
    ): MarketplacePromotionalEvent | undefined => {
      const tokenEvents = cards.map(card =>
        getTokenEvent(card, marketplacePromotionalEvents)
      );

      return tokenEvents.find(event => event);
    },
    [marketplacePromotionalEvents]
  );
};

useTokenTakesPartPromotionalEvent.fragments = {
  anyCard: gql`
    fragment useTokenTakesPartPromotionalEvent_anyCard on AnyCardInterface {
      sport
      slug
      assetId
    }
  ` as TypedDocumentNode<useTokenTakesPartPromotionalEvent_anyCard>,
};

export default useTokenTakesPartPromotionalEvent;
