import {
  ProbabilisticBundleIntensity,
  ProbabilisticBundleQuality,
} from '__generated__/globalTypes';

export const BOX_COLOR: { [k in ProbabilisticBundleQuality]: string } = {
  [ProbabilisticBundleQuality.LITE]: '#999999',
  [ProbabilisticBundleQuality.STANDARD]: '#444444',
  [ProbabilisticBundleQuality.PREMIUM]: '#090909',
};
export const FRAME_COLOR: { [k in ProbabilisticBundleQuality]: string } = {
  [ProbabilisticBundleQuality.LITE]: '#999999',
  [ProbabilisticBundleQuality.STANDARD]: '#444444',
  [ProbabilisticBundleQuality.PREMIUM]: '#000000',
};
export const BOX_LIGHT_INTENSITY: {
  [k in ProbabilisticBundleIntensity]: number;
} = {
  [ProbabilisticBundleIntensity.DEFAULT]: 1,
  [ProbabilisticBundleIntensity.MODERATE]: 2,
  [ProbabilisticBundleIntensity.BRIGHT]: 4,
  [ProbabilisticBundleIntensity.HIGH]: 6,
  [ProbabilisticBundleIntensity.INTENSE]: 10,
};
