import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { PostalAddressInput } from '__generated__/globalTypes';
import { Vertical } from 'atoms/layout/flex';
import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import {
  GraphQLResult,
  GraphqlForm,
  SubmitButtonProps,
  TextField,
} from 'components/form/Form';
import Select from 'components/form/Form/Select';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useQuery } from 'hooks/graphql/useQuery';
import { glossary } from 'lib/glossary';

import {
  DeliverableCountriesQuery,
  DeliverableCountriesQueryVariables,
  PostalAddressForm_userSettings,
} from './__generated__/index.graphql';

const DELIVERABLE_COUNTRIES_QUERY = gql`
  query DeliverableCountriesQuery {
    config {
      id
      deliverableCountries {
        slug
        name
      }
    }
  }
` as TypedDocumentNode<
  DeliverableCountriesQuery,
  DeliverableCountriesQueryVariables
>;
const LoadingWrapper = styled.div`
  padding: var(--double-unit);
`;

const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
`;

const FormWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: var(--unit);
  grid-template-areas:
    'first_name last_name'
    'country country'
    'address address'
    'additional_address additional_address'
    'zip city'
    'cta cta';
  grid-template-columns: repeat(2, 1fr);
`;
const Root = styled(Vertical).attrs({ gap: 2 })`
  width: 100%;
`;
const Country = styled.div`
  grid-area: country;
`;
const FirstName = styled.div`
  grid-area: first_name;
`;
const LastName = styled.div`
  grid-area: last_name;
`;
const Address = styled.div`
  grid-area: address;
`;
const AdditionalAddress = styled.div`
  grid-area: additional_address;
`;
const ZipCode = styled.div`
  grid-area: zip;
`;
const City = styled.div`
  grid-area: city;
`;

const messages = defineMessages({
  address: {
    id: 'ItemPreviewDialog.PostalAddressForm.address',
    defaultMessage: 'Address',
  },
  additionalAddress: {
    id: 'ItemPreviewDialog.PostalAddressForm.additionalAddress',
    defaultMessage: 'Additional Address',
  },
  city: {
    id: 'ItemPreviewDialog.PostalAddressForm.city',
    defaultMessage: 'City',
  },
  country: {
    id: 'ItemPreviewDialog.PostalAddressForm.country',
    defaultMessage: 'Country',
  },
});

export type Attributes = {
  additionalAddress: string | null;
  city: string | null;
  countryCode: string | null;
  country: { name: string } | null;
  firstName: string | null;
  lastName: string | null;
  streetAddress: string | null;
  zipcode: string | null;
};
type Props = {
  onSubmit: (
    attributes: PostalAddressInput,
    onResult: (result: GraphQLResult) => void,
    onCancel: () => void
  ) => void;
  onSuccess: () => void;
  onChange?: (attributes: Attributes) => void;
  button: (
    SubmitButton: React.ComponentType<
      React.PropsWithChildren<SubmitButtonProps>
    >
  ) => ReactNode;
};

export const PostalAddressForm = ({
  onSubmit,
  onSuccess,
  onChange,
  button,
}: Props) => {
  const [initialOnChangeCalled, setInitialOnChangeCalled] = useState(false);
  const { currentUser } = useCurrentUserContext();
  const { formatMessage } = useIntl();
  const { data, loading } = useQuery(DELIVERABLE_COUNTRIES_QUERY);
  const countriesOptions = [
    {
      label: formatMessage({
        id: 'ShopItemPicker.ItemPreviewDialog.PostalAddressForm.countryDefaultOption',
        defaultMessage: 'Choose a country',
      }),
      value: '',
    },
    ...(data?.config.deliverableCountries || [])
      .map(country => ({
        label: country.name,
        value: country.slug,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1)),
  ];

  if (!currentUser) {
    return null;
  }

  const defaultValues = currentUser.userSettings.postalAddress;
  if (!initialOnChangeCalled) {
    onChange?.(defaultValues);
    setInitialOnChangeCalled(true);
  }

  return (
    <StyledGraphqlForm
      onSubmit={onSubmit}
      onChange={onChange}
      onSuccess={onSuccess}
      render={(
        Error: React.ComponentType<React.PropsWithChildren<unknown>>,
        SubmitButton: React.ComponentType<
          React.PropsWithChildren<SubmitButtonProps>
        >
      ) => (
        // key based on firstName to reset the form when the user delete his address
        <Root key={defaultValues.firstName || 'postal-address'}>
          <FormWrapper>
            <Country>
              {countriesOptions.length && !loading ? (
                <Select
                  id="countryCode"
                  name="countryCode"
                  initialValue={
                    countriesOptions.find(
                      ({ value }) => value === defaultValues.country?.slug
                    ) || countriesOptions[0]
                  }
                  label={formatMessage(messages.country)}
                  options={countriesOptions}
                  required
                />
              ) : (
                <LoadingWrapper>
                  <LoadingIndicator small />
                </LoadingWrapper>
              )}
            </Country>
            <FirstName>
              <TextField
                name="firstName"
                defaultValue={defaultValues.firstName || undefined}
                label={formatMessage(glossary.firstName)}
                required
              />
            </FirstName>
            <LastName>
              <TextField
                name="lastName"
                defaultValue={defaultValues.lastName || undefined}
                label={formatMessage(glossary.lastName)}
                required
              />
            </LastName>
            <Address>
              <TextField
                name="streetAddress"
                defaultValue={defaultValues.streetAddress || undefined}
                label={formatMessage(messages.address)}
                required
              />
            </Address>
            <AdditionalAddress>
              <TextField
                name="additionalAddress"
                defaultValue={defaultValues.additionalAddress || undefined}
                label={formatMessage(messages.additionalAddress)}
              />
            </AdditionalAddress>
            <ZipCode>
              <TextField
                name="zipcode"
                defaultValue={defaultValues.zipcode || undefined}
                label={formatMessage(glossary.zipCode)}
                required
              />
            </ZipCode>
            <City>
              <TextField
                name="city"
                defaultValue={defaultValues.city || undefined}
                label={formatMessage(messages.city)}
                required
              />
            </City>
          </FormWrapper>
          {button(SubmitButton)}
        </Root>
      )}
    />
  );
};

PostalAddressForm.fragments = {
  userSettings: gql`
    fragment PostalAddressForm_userSettings on UserSettings {
      id
      postalAddress {
        firstName
        lastName
        streetAddress
        additionalAddress
        zipcode
        city
        countryCode
        country {
          slug
          name
        }
      }
    }
  ` as TypedDocumentNode<PostalAddressForm_userSettings>,
};
