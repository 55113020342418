// Inlined from "graphql-ruby-client@1.11.9/subscriptions/ActionCableLink.ts"

import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client';
import type { Consumer } from '@rails/actioncable';
import { print } from 'graphql';

import { IS_TEST_RUNNER } from 'config';

type RequestResult = FetchResult<
  { [key: string]: any },
  Record<string, any>,
  Record<string, any>
>;
type ConnectionParams = object | ((operation: Operation) => object);

class ActionCableLink extends ApolloLink {
  cable: Consumer;

  channelName: string;

  actionName: string;

  connectionParams: ConnectionParams;

  constructor(options: {
    cable: Consumer;
    channelName?: string;
    actionName?: string;
    connectionParams?: ConnectionParams;
  }) {
    super();
    this.cable = options.cable;
    this.channelName = options.channelName || 'GraphqlChannel';
    this.actionName = options.actionName || 'execute';
    this.connectionParams = options.connectionParams || {};
  }

  // Interestingly, this link does _not_ call through to `next` because
  // instead, it sends the request to ActionCable.
  request(
    operation: Operation,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _next: NextLink
  ): Observable<RequestResult> {
    return new Observable(observer => {
      if (IS_TEST_RUNNER) {
        // Until we have a clean way to mock apollo subscriptions with msw, we can just have them do nothing
        // https://github.com/mswjs/msw/issues/285
        return undefined;
      }
      const channelId = Math.round(
        Date.now() + Math.random() * 100000
      ).toString(16);
      const { actionName } = this;
      const connectionParams =
        typeof this.connectionParams === 'function'
          ? this.connectionParams(operation)
          : this.connectionParams;
      const channel = this.cable.subscriptions.create(
        {
          channel: this.channelName,
          channelId,
          ...connectionParams,
        },
        {
          connected() {
            const { operationId } = operation as { operationId?: string };
            this.perform(actionName, {
              // This is added for persisted operation support:
              operationId,
              query:
                operation.query && !operationId ? print(operation.query) : null,
              variables: operation.variables,
              operationName: operation.operationName,
            });
          },
          received(payload) {
            if (payload?.result?.data || payload?.result?.errors) {
              observer.next(payload.result);
            }

            if (!payload.more) {
              observer.complete();
            }
          },
        }
      );
      // Make the ActionCable subscription behave like an Apollo subscription
      return Object.assign(channel, { closed: false });
    });
  }
}

export default ActionCableLink;
