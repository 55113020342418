import { faInfoCircle, faStar } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Caption, LabelM, Text16, Title2 } from 'atoms/typography';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';
import { fiatWallet, glossary } from 'lib/glossary';

import {
  LEARN_MORE_ABOUT_FIAT_WALLET,
  NOT_SUPPORTED_COUNTRIES_LINK,
} from '../../externalLinks';
import { CreateFiatWalletSteps } from '../../type';
import { ReinsuranceItem } from '../ReinsuranceItem';
import { StaticCashBalance } from '../StaticCashBalance';

const Wrapper = styled(Vertical).attrs({ gap: 4, center: true })`
  padding-top: var(--double-unit);
  justify-content: stretch;
`;

const Actions = styled(Vertical).attrs({ center: true })`
  width: 100%;
  justify-content: stretch;
`;

const News = styled(Vertical)`
  align-items: stretch;
  justify-content: center;
`;

const Helper = styled(Horizontal).attrs({ gap: 2 })`
  color: var(--c-neutral-600);
  background-color: var(--c-neutral-300);
  padding: var(--double-unit);
  border-radius: var(--double-unit);
`;

const StyledLink = styled.a`
  text-decoration: underline;
  color: var(--c-link);
`;

type Props = {
  setStep: (tab: CreateFiatWalletSteps) => void;
  onDismiss?: () => void;
  title: ReactNode;
  news: { id: string; title: ReactNode; desc: ReactNode }[];
};

export const Content = ({ title, news, setStep, onDismiss }: Props) => {
  const { canListAndTrade } = useFiatBalance();
  return (
    <Wrapper>
      <StaticCashBalance />
      <Title2 className="text-center">{title}</Title2>
      {!canListAndTrade && (
        <Helper>
          <FontAwesomeIcon icon={faInfoCircle} />
          <LabelM color="var(--c-neutral-600)">
            <FormattedMessage
              id="walletSetup.whatsNew.helper"
              defaultMessage="Cards currently listed will continue to be sold for ETH. After activating your Cash Wallet, you can sell cards for cash or ETH."
            />
          </LabelM>
        </Helper>
      )}
      <News>
        {news.map(({ id, title: newsTitle, desc }) => (
          <ReinsuranceItem
            key={id}
            title={newsTitle}
            desc={desc}
            icon={faStar}
          />
        ))}
      </News>
      <Text16 className="text-center" bold color="var(--c-link)">
        <StyledLink
          href={LEARN_MORE_ABOUT_FIAT_WALLET}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage
            id="walletSetup.whatsNew.learnMoreLink"
            defaultMessage="Learn more about Cash Wallet"
          />
        </StyledLink>
      </Text16>
      <Caption color="var(--c-neutral-600)">
        <FormattedMessage
          id="walletSetup.whatsNew.disclaimer"
          defaultMessage="Cash Wallet is powered by Mangopay. Availability may vary depending on your country of residence.{br} <link>Learn more</link>"
          values={{
            link: (...chunks) => (
              <StyledLink
                target="_blank"
                href={NOT_SUPPORTED_COUNTRIES_LINK}
                rel="noopener noreferrer"
              >
                {chunks}
              </StyledLink>
            ),
            br: <br />,
          }}
        />
      </Caption>
      <Actions>
        <Button
          color="primary"
          size="medium"
          fullWidth
          onClick={() => setStep(CreateFiatWalletSteps.INTRO)}
        >
          <FormattedMessage
            {...(canListAndTrade
              ? glossary.continue
              : fiatWallet.activateCashWallet)}
          />
        </Button>
        {onDismiss && (
          <Button color="tertiary" size="medium" fullWidth onClick={onDismiss}>
            <FormattedMessage {...glossary.doItLater} />
          </Button>
        )}
      </Actions>
    </Wrapper>
  );
};
