import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentProps, FC, Fragment, PropsWithChildren } from 'react';
import { generatePath } from 'react-router-dom';

import { Link as RouterLink } from '@sorare/routing';

import { Sport } from '__generated__/globalTypes';
import {
  FOOTBALL_MY_CLUB_SLUG,
  MLB_MY_CLUB_SLUG,
  NBA_MY_CLUB_SLUG,
} from 'constants/__generated__/routes';
import { useSportContext } from 'contexts/sport';

import { MyClubLink_publicUserInfoInterface } from './__generated__/index.graphql';

type MyClubPathFactory = (slug: string) => string;

const myClubPathFactories: Readonly<{
  [key in Sport]: MyClubPathFactory;
}> = {
  [Sport.BASEBALL]: slug => generatePath(MLB_MY_CLUB_SLUG, { slug }),
  [Sport.NBA]: slug => generatePath(NBA_MY_CLUB_SLUG, { slug }),
  [Sport.FOOTBALL]: slug => generatePath(FOOTBALL_MY_CLUB_SLUG, { slug }),
};

type Props = PropsWithChildren<
  {
    user: { slug: string; suspended: boolean };
    sport?: Sport;
    onClick?: ComponentProps<typeof RouterLink>['onClick'];
  } & {
    WhenSuspended?: FC<React.PropsWithChildren<unknown>>;
  } & (
      | {
          Link?: never;
          className?: string;
        }
      | {
          Link?: FC<
            React.PropsWithChildren<{
              to: ComponentProps<typeof RouterLink>['to'];
              onClick: ComponentProps<typeof RouterLink>['onClick'];
            }>
          >;
          className?: never;
        }
    )
>;

export const MyClubLink = ({
  user,
  sport = undefined,
  children,
  Link,
  WhenSuspended = Fragment,
  className,
  onClick,
}: Props) => {
  const { sport: currentSport } = useSportContext() || {
    sport: Sport.FOOTBALL,
  };

  if (user.suspended) {
    return <WhenSuspended>{children}</WhenSuspended>;
  }

  const actualSport = sport || currentSport || Sport.FOOTBALL;
  const to = myClubPathFactories[actualSport](user.slug);

  if (Link) {
    return (
      <Link to={to} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <RouterLink to={to} className={className} onClick={onClick}>
      {children}
    </RouterLink>
  );
};

MyClubLink.fragments = {
  user: gql`
    fragment MyClubLink_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      suspended
    }
  ` as TypedDocumentNode<MyClubLink_publicUserInfoInterface>,
};
