import styled from 'styled-components';

import { FontAwesomeIcon, IconDefinition } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { Color } from 'style/types';

const Container = styled(Horizontal).attrs({ gap: 0, center: true })`
  height: 30px;
  width: calc(5 * var(--unit));
  background-color: var(--background-color, var(--c-neutral-900));
  border-radius: var(--half-unit);

  &.xs {
    height: 18px;
    width: 18px;
  }
`;

export type Props = {
  size?: 'xs' | 'xl';
  backgroundColor?: Color;
  icon: IconDefinition;
  color?: Color;
};

export const PaymentMethodIcon = ({
  size = 'xl',
  backgroundColor = 'var(--c-neutral-1000)',
  icon,
  color = 'var(--c-neutral-100)',
}: Props) => {
  return (
    <Container
      className={size}
      style={
        {
          '--background-color': backgroundColor,
        } as React.CSSProperties
      }
    >
      <FontAwesomeIcon size={size} icon={icon} color={color} />
    </Container>
  );
};

export default PaymentMethodIcon;
