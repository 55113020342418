import { defineMessages } from 'react-intl';

export const creditCardGlossary = defineMessages({
  number: {
    id: 'CreditCardInputPlaceholders.number',
    defaultMessage: 'Card number',
  },
  cvc: {
    id: 'CreditCardInputPlaceholders.cvc',
    defaultMessage: 'CVC',
  },
  expiry: {
    id: 'CreditCardInputPlaceholders.expiry',
    defaultMessage: 'MM/YY',
  },
  expiryYear: {
    id: 'CreditCardInputPlaceholders.expiryYear',
    defaultMessage: 'YY',
  },
  expiryMonth: {
    id: 'CreditCardInputPlaceholders.expiryMonth',
    defaultMessage: 'MM',
  },
});
