import { Sport } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';

export const useCoinBalance = () => {
  const { sport } = useSportContext();
  const { currentUser } = useCurrentUserContext();

  const coinBalances = {
    [Sport.BASEBALL]: currentUser?.coinBalanceOfBaseball || 0,
    [Sport.FOOTBALL]: currentUser?.coinBalanceOfFootball || 0,
    [Sport.NBA]: 0,
  };

  const coinBalance = (sport && coinBalances[sport]) || 0;

  return {
    coinBalance,
    coinBalances,
  };
};
