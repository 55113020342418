import { useEffect } from 'react';

import { useIntlContext } from 'contexts/intl';
import useLocalStorage, { STORAGE } from 'hooks/useLocalStorage';

interface Props {
  id: string;
  skip?: boolean;
}

export const useViewItem = ({ id, skip }: Props) => {
  const { language } = useIntlContext();
  const [consent] = useLocalStorage<false | true>(
    STORAGE.trackingConsent,
    false
  );

  useEffect(() => {
    if (!window.gtag || !consent || skip) {
      // user didn't accept cookies
      return;
    }

    window.gtag('event', 'view_item', {
      items: [
        {
          id: `${id}-${language}`,
          google_business_vertical: 'custom',
        },
      ],
    });
    // we depend on `consent` to be able to track this post cookie acceptance
  }, [language, id, consent, skip]);

  return null;
};
