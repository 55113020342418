import { useEffect } from 'react';
import { Navigate, useRouteError } from 'react-router-dom';

import { SilencedError } from '@sorare/error-boundary';

import { LANDING } from 'constants/__generated__/routes';
import { sendSafeError } from 'lib/error';
import { isNoInternetConnectionError } from 'lib/http';
import { HandledError } from 'routing/HandledError';

const wrapHandledError = (code: number | string, message?: string) => {
  return {
    handleError: true,
    error: { code, message },
  };
};

const formatError = (error?: any) => {
  if (!error) return null;

  const { graphQLErrors } = error || {};
  const graphqlErrorCode = graphQLErrors?.[0]?.extensions?.code;

  if (error instanceof SilencedError) {
    return wrapHandledError('OOPS', error.message);
  }
  if (error.statusCode === 401) {
    return wrapHandledError(error.statusCode, 'Unauthorized.');
  }
  if (error.statusCode === 404 || graphqlErrorCode === 'NOT_FOUND') {
    return wrapHandledError(error.statusCode, 'Page not found.');
  }
  if (error.statusCode === 429) {
    return wrapHandledError(
      error.statusCode,
      'Too many requests, try again later.'
    );
  }
  if (error.statusCode === 503) {
    return wrapHandledError(
      error.statusCode,
      'Maintenance operation in progress. Please retry later.'
    );
  }
  if (error.statusCode) {
    return wrapHandledError(
      error.statusCode,
      `Something went wrong! (${error.statusCode})`
    );
  }
  if (error.networkError) {
    if (isNoInternetConnectionError(error.networkError)) {
      if (process.env.NODE_ENV === 'development') {
        return wrapHandledError(
          'OOPS',
          `Couldn't connect to the Sorare API: ${error.message}.`
        );
      }
      return wrapHandledError('OOPS', 'Lost internet connection.');
    }
    if (error.networkError instanceof TypeError) {
      // this happens when the API send invalid JSON
      // TypeError: Failed to execute 'text' on 'Response': body stream already read.
      if (process.env.NODE_ENV === 'development') {
        return wrapHandledError(
          'OOPS',
          error.message || error.networkError.message
        );
      }
      return wrapHandledError('OOPS', 'Internal server error.');
    }
    return wrapHandledError('OOPS', `Unexpected error: ${error.networkError}.`);
  }
  return null;
};

const UnhandledError = ({ baseError }: { baseError: any }) => {
  useEffect(() => {
    sendSafeError(baseError);
  }, [baseError]);
  return (
    <HandledError code={500} message={`Unexpected ${baseError?.toString()}`} />
  );
};

export const RouteErrorBoundary = () => {
  const baseError: any = useRouteError();
  const { error, handleError = false } = formatError(baseError) || {};

  useEffect(() => {
    if (!handleError) sendSafeError(baseError);
  }, [baseError, handleError]);

  if (error && handleError) {
    if (error.code === 401) {
      return (
        <Navigate
          replace
          to={{
            pathname: LANDING,
          }}
        />
      );
    }

    return <HandledError code={error.code} message={error.message || ''} />;
  }

  return <UnhandledError baseError={baseError} />;
};
