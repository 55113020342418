import { ACTUAL_ENV, REVISION, SO5_SENTRY_DSN } from '@sorare/core/src/config';
import { startSentry } from '@sorare/core/src/contexts/sentry/sentry';

const { createBrowserRouter } = startSentry({
  dsn: SO5_SENTRY_DSN,
  env: ACTUAL_ENV,
  release: REVISION.toString(),
  app: 'sorare',
});

export { createBrowserRouter };
