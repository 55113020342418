import { ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { CardImg } from '@sorare/core/src/components/card/CardImg';

const LineupCardBox = styled.div`
  position: relative;
  border-radius: var(--double-unit);
  /**
    * no "overflow: hidden" should be applied to box style otherwise scroll-margin-top
    * would break
    */
  scroll-margin-top: var(--current-stack-height);
  padding: var(--unit);
  opacity: 50%;
  &:hover {
    opacity: 90%;
  }
  & img {
    width: calc(10 * var(--unit));
    object-fit: cover;
  }
`;

type Props = {
  fullImageUrl: string;
  playerCardScore: ReactNode;
  className?: string;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const PlayerInFixtureLinkBox = ({
  fullImageUrl,
  playerCardScore,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
}: Props) => {
  return (
    <LineupCardBox
      as="button"
      onClick={evt => {
        onClick();
        evt.stopPropagation();
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
    >
      <Vertical gap={1} center>
        <Horizontal>
          <CardImg src={fullImageUrl} alt="" width={160} />
        </Horizontal>
        {playerCardScore}
      </Vertical>
    </LineupCardBox>
  );
};
