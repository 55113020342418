import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { ActiveUserAvatar } from '@sorare/core/src/components/user/ActiveUserAvatar';
import { MyClubLink } from '@sorare/core/src/components/user/MyClubLink';
import { UserName } from '@sorare/core/src/components/user/UserName';

import {
  OwnerMyClubLink_User,
  TokenOwner_anyCard,
} from './__generated__/index.graphql';

interface Props {
  card: TokenOwner_anyCard;
  withAvatar?: boolean;
}

// min-width: 0 allow child to have text-overflow: ellipsis;
const StyledMyClubLink = styled(MyClubLink)`
  display: flex;
  gap: var(--unit);
  max-width: 100%;
  align-items: center;
  min-width: 0;
`;

const Name = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font: var(--t-12);
  font-weight: var(--t-bold);
`;

const DisabledMyClubLink = styled(Horizontal)`
  opacity: 0.5;
`;

interface GalleryProps {
  sport: Sport;
  user: OwnerMyClubLink_User;
  withAvatar: boolean;
  onClick?: () => void;
}

export const OwnerMyClubLink = ({
  sport,
  user,
  withAvatar,
  onClick,
}: GalleryProps) => {
  return (
    <StyledMyClubLink
      user={user}
      sport={sport}
      WhenSuspended={DisabledMyClubLink}
      onClick={onClick}
    >
      {withAvatar && <ActiveUserAvatar user={user} variant="extraSmall" />}
      <Name>
        <UserName user={user} />
      </Name>
    </StyledMyClubLink>
  );
};

export const TokenOwner = ({ card, withAvatar = false }: Props) => {
  if (card?.user) {
    return (
      <OwnerMyClubLink
        user={card.user}
        sport={card.sport}
        withAvatar={withAvatar}
      />
    );
  }
  return null;
};

OwnerMyClubLink.fragments = {
  user: gql`
    fragment OwnerMyClubLink_User on User {
      slug
      ...ActiveUserAvatar_user
      ...UserName_publicUserInfoInterface
    }
    ${ActiveUserAvatar.fragments.user}
    ${UserName.fragments.user}
  ` as TypedDocumentNode<OwnerMyClubLink_User>,
};

TokenOwner.fragments = {
  anyCard: gql`
    fragment TokenOwner_anyCard on AnyCardInterface {
      slug
      sport
      user {
        slug
        ...OwnerMyClubLink_User
      }
    }
    ${OwnerMyClubLink.fragments.user}
  ` as TypedDocumentNode<TokenOwner_anyCard>,
};

export default TokenOwner;
