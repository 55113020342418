import { useEffect } from 'react';
import Helmet from 'react-helmet';

type LinkProps = React.ComponentProps<'link'> & {
  onLoad?: GlobalEventHandlers['onload'];
};

const Link = ({ onLoad, rel, as, href }: LinkProps) => {
  useEffect(
    () => {
      const link = document.createElement('link');
      if (rel) link.rel = rel;
      if (as) link.as = as;
      if (href) link.href = href;
      if (onLoad) link.onload = onLoad;
      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    },
    // We only want this on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return null;
};

type ScriptPreloadProps = {
  scriptUrls?: string[];
  onLoad?: LinkProps['onLoad'];
};

/**
 * HACK workaround https://github.com/nfl/react-helmet/pull/299
 */
export const ScriptPreload = ({ scriptUrls, onLoad }: ScriptPreloadProps) => {
  return (
    <>
      {[...new Set(scriptUrls)].map(url => (
        <Link
          key={url}
          rel="modulepreload"
          as="script"
          href={url}
          onLoad={onLoad}
        />
      ))}
    </>
  );
};

type Props = {
  imageUrls?: string[];
  videoUrls?: string[];
};

export const Preloader = ({ imageUrls, videoUrls }: Props) => {
  if (!imageUrls?.length && !videoUrls?.length) {
    return null;
  }

  return (
    <Helmet>
      {[...new Set(imageUrls)].map(url => (
        <link rel="preload" as="image" key={url} href={url} />
      ))}
      {[...new Set(videoUrls)].map(url => (
        <link rel="preload" as="video" key={url} href={url} />
      ))}
    </Helmet>
  );
};
