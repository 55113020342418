import { TypedDocumentNode, gql } from '@apollo/client';

import { getHumanReadableSerialNumber } from 'lib/cards';
import { format } from 'lib/seasons';

import { TokenMetas_anyCard } from './__generated__/index.graphql';

interface Props {
  card: TokenMetas_anyCard;
  separator?: string;
}

export const TokenMetas = ({ card, separator }: Props) => {
  const { seasonYear, singleCivilYear } = card;
  return (
    <>
      {[
        format(seasonYear, { singleCivilYear }),
        separator,
        getHumanReadableSerialNumber(card),
      ]}
    </>
  );
};

TokenMetas.fragments = {
  anyCard: gql`
    fragment TokenMetas_anyCard on AnyCardInterface {
      slug
      seasonYear
      singleCivilYear
      ...getHumanReadableSerialNumber_anyCard
    }
    ${getHumanReadableSerialNumber.fragments.anyCard}
  ` as TypedDocumentNode<TokenMetas_anyCard>,
};
