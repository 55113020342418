import { TypedDocumentNode, gql } from '@apollo/client';

import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import CollectionLeaderboard from 'components/collections/CollectionLeaderboard';

import {
  CollectionLeaderboardPageQuery,
  CollectionLeaderboardPageQueryVariables,
} from './__generated__/index.graphql';

const COLLECTION_LEADERBOARD_QUERY = gql`
  query CollectionLeaderboardPageQuery($collection: String!, $slug: String!) {
    cardCollection(slug: $collection) {
      slug
      slotsCount
      userCardCollection(forUserSlug: $slug) {
        id
        ...CollectionLeaderboard_userCardCollection
      }
    }
  }
  ${CollectionLeaderboard.fragments.userCardCollection}
` as TypedDocumentNode<
  CollectionLeaderboardPageQuery,
  CollectionLeaderboardPageQueryVariables
>;

type Props = {
  collection: string;
  slug: string;
};

const LeaderboardPage = ({ collection, slug }: Props) => {
  const { data } = useQuery(COLLECTION_LEADERBOARD_QUERY, {
    variables: {
      collection,
      slug,
    },
  });
  const { cardCollection } = data || {};

  return (
    <CollectionLeaderboard
      slug={collection}
      slotsCount={cardCollection?.slotsCount || 0}
      myUserCardCollection={cardCollection?.userCardCollection}
    />
  );
};

export default LeaderboardPage;
