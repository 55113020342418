import { TypedDocumentNode, gql } from '@apollo/client';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { isToday } from 'date-fns';
import { ReactNode } from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  MessageDescriptor,
  useIntl,
} from 'react-intl';
import styled from 'styled-components';

import { ScoreStatus, Sport } from '__generated__/globalTypes';
import { FontAwesomeIcon } from 'atoms/icons';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { BodyS, LabelXS } from 'atoms/typography';
import { Chip } from 'atoms/ui/Chip';
import { Cost } from 'components/player/Cost';
import { Scoring, Props as ScoringProps } from 'components/scoring/Scoring';
import { useSportContext } from 'contexts/sport';
import { ScoreType } from 'lib/footballThresholds';
import { shortStatusMessages, statusMessages } from 'lib/players';
import { findThresholdColor } from 'lib/thresholds';

import { Score_anyGameInterface } from './__generated__/index.graphql';

const StyledChip = styled(Chip)`
  min-width: var(--quadruple-unit);
  background: var(--c-neutral-150);
  font: var(--t-label-xs);
  font-weight: var(--t-bold);
`;

export type Props = {
  score: number;
  scoreStatus: ScoreStatus;
  forceScoreStatus?: ScoreStatus;
  withTooltip?: boolean;
  tooltipTitle?: MessageDescriptor;
  withDate?: boolean;
  hideReviewing?: boolean;
  hideClock?: boolean;
  upcomingGame?: Score_anyGameInterface;
  hasPlayedGames?: boolean;
  scoreType?: ScoreType;
  isCappedMode?: boolean;
  animationDelayMs?: number;
  animated?: boolean;
  withSuffix?: boolean;
  isAffordable?: boolean;
} & Pick<ScoringProps, 'shape' | 'size' | 'inverted'>;

export const Score = ({
  score,
  shape = 'PILL',
  scoreStatus,
  forceScoreStatus,
  withTooltip,
  tooltipTitle,
  withDate,
  hideReviewing,
  hideClock,
  upcomingGame,
  hasPlayedGames,
  isCappedMode,
  scoreType = 'CARD_SCORE',
  animationDelayMs,
  animated,
  withSuffix,
  size,
  isAffordable = true,
  inverted,
}: Props) => {
  const { sport } = useSportContext();
  const { formatMessage } = useIntl();

  const actualScoreStatus = forceScoreStatus || scoreStatus;

  const hasNotPlayedAnyGame =
    actualScoreStatus === ScoreStatus.PENDING &&
    ((upcomingGame && !hasPlayedGames) ||
      (upcomingGame === undefined && hasPlayedGames === undefined));

  const isReviewing =
    !hideReviewing && actualScoreStatus === ScoreStatus.REVIEWING;

  const roundedScore = Math.floor(score);

  let content: ReactNode;
  if (hasNotPlayedAnyGame && withDate && upcomingGame) {
    const { date } = upcomingGame;

    content = (
      <BodyS as="span" uppercase bold color="var(--c-neutral-1000)">
        {isToday(date) ? (
          <FormattedTime value={date} />
        ) : (
          <FormattedDate value={date} weekday="short" day="numeric" />
        )}
      </BodyS>
    );
  } else if (
    [ScoreStatus.DID_NOT_PLAY, ScoreStatus.NO_GAME].includes(
      actualScoreStatus
    ) ||
    hasNotPlayedAnyGame
  ) {
    const badgeContent = hasNotPlayedAnyGame ? (
      <>
        {!hideClock && (
          <FontAwesomeIcon
            icon={faClock}
            aria-label={formatMessage(statusMessages.PENDING)}
            color="var(--c-neutral-600)"
            size={shape === 'HEXAGON' ? 'lg' : undefined}
          />
        )}
      </>
    ) : (
      <FormattedMessage {...shortStatusMessages[actualScoreStatus]} />
    );

    switch (shape) {
      case 'TEXT':
        return (
          <span style={{ color: 'var(--c-neutral-600)' }}>{badgeContent}</span>
        );
      case 'PILL':
        return <StyledChip size="medium">{badgeContent}</StyledChip>;
      default:
        return (
          <Cost
            score={
              <LabelXS as="span" color="var(--c-neutral-1000)" bold>
                {badgeContent}
              </LabelXS>
            }
            color="var(--c-neutral-150)"
          />
        );
    }
  } else if (shape === 'HEXAGON' && isCappedMode) {
    content = (
      <Scoring
        shape={shape}
        score={roundedScore}
        reviewing={isReviewing}
        delayMs={animationDelayMs}
        animated={animated}
        withSuffix={withSuffix}
        size={size}
        color={!isAffordable ? 'var(--c-neutral-500)' : undefined}
        inverted={inverted}
        invalid={isAffordable !== undefined ? !isAffordable : undefined}
      />
    );
  } else {
    content = (
      <Scoring
        shape={shape}
        score={roundedScore}
        color={findThresholdColor(
          roundedScore,
          sport || Sport.FOOTBALL,
          scoreType
        )}
        reviewing={isReviewing}
        delayMs={animationDelayMs}
        animated={animated}
        withSuffix={withSuffix}
        size={size}
        inverted={inverted}
      />
    );
  }

  if (tooltipTitle || withTooltip) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            {...(tooltipTitle || statusMessages[actualScoreStatus])}
          />
        }
      >
        {content}
      </Tooltip>
    );
  }
  return content;
};

Score.fragments = {
  anyGameInterface: gql`
    fragment Score_anyGameInterface on AnyGameInterface {
      id
      date
    }
  ` as TypedDocumentNode<Score_anyGameInterface>,
};
