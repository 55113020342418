import { Outlet } from 'react-router-dom';

import { NotFound, getRoutesFromFiles } from '@sorare/routing';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import SportSwitcher from '@sorare/core/src/contexts/sport/SportSwitcher';

import CurrentBaseballUserProvider from 'contexts/currentBaseballUser/Provider';
import { MLBMarketSubscriptions } from 'routing/MLBMarketSubscriptions';

export const routeObjects = [
  {
    element: (
      <CurrentBaseballUserProvider>
        <SportSwitcher sport={Sport.BASEBALL} />
        <MLBMarketSubscriptions />
        <Outlet />
      </CurrentBaseballUserProvider>
    ),
    children: [
      ...getRoutesFromFiles(
        import.meta.glob('./**/(page|layout).tsx'),
        '../../packages/baseball/src/pages'
      ),
      { path: '*', element: <NotFound /> },
    ],
  },
];
