import { createContext, useContext } from 'react';

import { CurrentNBAUserQuery } from './__generated__/queries.graphql';

type CurrentNBAUserQuery_currentUser = NonNullable<
  CurrentNBAUserQuery['currentUser']
>;

export type CurrentNBAUser = CurrentNBAUserQuery_currentUser;

interface CurrentNBAUserContext {
  currentNBAUser: CurrentNBAUser | null | undefined;
  needsOnboarding: boolean;
  fetching: boolean;
}

export const currentNBAUserContext =
  createContext<CurrentNBAUserContext | null>(null);

export const useCurrentUserContext = () => useContext(currentNBAUserContext)!;

export default currentNBAUserContext.Provider;
