import { TypedDocumentNode, gql } from '@apollo/client';
import { isFuture } from 'date-fns';
import { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';

// eslint-disable-next-line no-restricted-imports
import type { CardRepresentation } from '@sorare/trois/src/atoms/Card/Card';
import {
  BlueprintRevealStatus,
  CardQuality,
  CardRarity,
  OfferType,
  Rarity,
  Sport,
  SupportedCurrency,
} from '__generated__/globalTypes';
import { ValidWidths } from 'atoms/ui/ResponsiveImg';
import { statsOverlay } from 'components/card/Stats/statsOverlay';
import { GqlType, withFragments } from 'lib/gql';

import {
  getAnimatedCardVideoSources_anyCardInterface,
  getAnimatedCardVideoSources_so5Appearance,
  getCardRepresentation_anyCardInterface,
  getHumanReadableSerialNumber_anyCard,
  getName_anyCard,
  isMyCardListedOnMarket_anyCard,
  isSentInDirectOffer_anyCard,
  isTokenListedOnMarket_anyCard,
  isTokenSentInDirectOffer_anyCard,
} from './__generated__/cards.graphql';
import { sortBy } from './arrays';
import { dateProgress } from './dates';
import { Token } from './deal';
import { formatWord } from './humanize';
import { MonetaryAmountParams } from './monetaryAmount';
import { format } from './seasons';

export type CardType = 'Card' | 'BaseballCard' | 'NBACard';

export const cardTypes: Record<Sport, CardType> = {
  [Sport.FOOTBALL]: 'Card',
  [Sport.BASEBALL]: 'BaseballCard',
  [Sport.NBA]: 'NBACard',
} as const;

export const CARD_SIZE = 320;
export const CARD_ASPECT_RATIO = 50 / 81;
export const CARD_ASPECT_RATIO_SMALL = 16 / 17;

export const getCardBorderRadiusWithOffsets = (
  cardBorderRadiusInPx = 24,
  offsetWidth = 0,
  offsetHeight = 0,
  aspectRatio = CARD_ASPECT_RATIO,
  cardWidth = 250
) => {
  return `${(100 / (cardWidth + offsetWidth)) * cardBorderRadiusInPx}% /
  ${(100 / (cardWidth / aspectRatio + offsetHeight)) * cardBorderRadiusInPx}%`;
};

/* border radius in % to be able to scale - only valid for 2022+ cards */
export const CARD_BORDER_RADIUS = getCardBorderRadiusWithOffsets();
export const CARD_BORDER_RADIUS_2024 = getCardBorderRadiusWithOffsets(
  40,
  0,
  0,
  CARD_ASPECT_RATIO,
  772
);

export interface TokenWithUser extends Token {
  owner: {
    user: {
      sorareAddress: string;
      slug: string;
    };
  };
}

export interface Card extends GqlType {
  slug: string;
  liveSingleSaleOffer?: {
    receiverSide: {
      amounts: MonetaryAmountParams;
    };
  } | null;
  latestEnglishAuction?: {
    id: string;
    currentPrice: string;
    currency: SupportedCurrency;
    endDate: Date;
    open: boolean;
  } | null;
  latestPrimaryOffer?: {
    price: MonetaryAmountParams | null;
    endDate: Date;
    nfts?:
      | {
          __typename: 'Token';
        }[]
      | null;
  } | null;
  user?: { __typename: 'User' } | null;
  tokenOwner?: {
    amounts: MonetaryAmountParams | null;
    user?: { __typename: 'User' } | null;
    from?: Date | null;
  } | null;
  priceRange?: { min: bigint; max: bigint } | null;
}

export const blockchainRarities = [
  'custom_series',
  'limited',
  'rare',
  'super_rare',
  'unique',
] as const;

export const playableBlockchainRarities = [
  'limited',
  'rare',
  'super_rare',
  'unique',
] as const;

export const nonPlayableBlockchainRarities = ['custom_series'] as const;

export const rarities = ['common', ...blockchainRarities] as const;

export const usSportRarities = [
  CardRarity.common,
  CardRarity.limited,
  CardRarity.rare,
  CardRarity.super_rare,
  CardRarity.unique,
] as const;

export const subscribableRarities = ['rare', 'super_rare', 'unique'];
export const usSportPlayableRarities = playableBlockchainRarities;
export const usSportSubscribableRarities = ['super_rare', 'unique'];

export type BlockchainScarcity = (typeof blockchainRarities)[number];
export type Scarcity = (typeof rarities)[number];

export const blockchainCamelCaseScarcities = [
  'customSeries',
  'limited',
  'rare',
  'superRare',
  'unique',
] as const;
export const camelCaseScarcities = [
  'common',
  ...blockchainCamelCaseScarcities,
] as const;

export type BlockchainCamelCaseScarcity =
  (typeof blockchainCamelCaseScarcities)[number];
export type CamelCaseScarcity = (typeof camelCaseScarcities)[number];

export const scarcityNames: { [name: string]: string } = {
  common: 'Common',
  custom_series: 'Custom Series',
  limited: 'Limited',
  rare: 'Rare',
  super_rare: 'Super Rare',
  superRare: 'Super Rare',
  unique: 'Unique',
};

export const isBlockchainScarcity = (
  scarcity: Nullable<string>
): scarcity is BlockchainScarcity => {
  return blockchainRarities.includes(scarcity as BlockchainScarcity);
};

export const OLD_US_SPORTS_SUPPLY = {
  [Rarity.unique]: 1,
  [Rarity.super_rare]: 100,
  [Rarity.rare]: 1000,
  [Rarity.limited]: 5000,
} as Record<Rarity, number>;

export const SUPPLY = {
  [Rarity.unique]: 1,
  [Rarity.super_rare]: 10,
  [Rarity.rare]: 100,
  [Rarity.limited]: 1000,
  [Rarity.custom_series]: 2022,
} as Record<Rarity, number>;

export const formatScarcity = (
  rarity: Scarcity | string,
  cardEdition?: string | null
) => {
  if (rarity === 'custom_series' && cardEdition) {
    return cardEdition;
  }
  return scarcityNames[rarity] || formatWord(rarity);
};

const nonEnumerableScarcities = ['unique', 'common'];

export const highestAvailableScarcity = (
  cardCounts: Partial<Record<CamelCaseScarcity, number>> | undefined,
  criteria: (cardCount: number | undefined) => boolean
): Scarcity | undefined => {
  if (cardCounts) {
    for (let i = rarities.length - 1; i >= 0; i -= 1) {
      if (criteria(cardCounts[camelCaseScarcities[i]])) {
        return rarities[i];
      }
    }
  }
  return undefined;
};

export const getHumanReadableScarcity = (
  card: {
    rarity: string;
    serialNumber: number;
  },
  { forceSerialNumber = false, oldUSSupply = false } = {}
) => {
  if (card.rarity === 'common') return formatScarcity(card.rarity);
  if (nonEnumerableScarcities.includes(card.rarity) && !forceSerialNumber)
    return formatScarcity(card.rarity);

  return `${card.serialNumber}/${
    (oldUSSupply ? OLD_US_SPORTS_SUPPLY : SUPPLY)[card.rarity as Rarity]
  }`;
};

export const getHumanReadableQuality = (quality: CardQuality) => {
  return quality.replace(/TIER_(.)/, 'T$1');
};

export const getHumanReadableSerialNumber = withFragments(
  (item: getHumanReadableSerialNumber_anyCard, { separator = ' ' } = {}) => {
    const formattedRarity = item.displayRarity;

    if (nonEnumerableScarcities.includes(item.rarityTyped)) {
      return formattedRarity;
    }

    const serial = `${item.serialNumber}/${item.supply}`;
    return `${formattedRarity}${separator}${serial}`;
  },
  {
    anyCard: gql`
      fragment getHumanReadableSerialNumber_anyCard on AnyCardInterface {
        slug
        displayRarity
        rarityTyped
        serialNumber
        supply
      }
    ` as TypedDocumentNode<getHumanReadableSerialNumber_anyCard>,
  }
);

export const getName = withFragments(
  (
    item: getName_anyCard,
    { separator = ' ', nameSeparator = ' \u2013 ' } = {}
  ) => {
    return [
      item.anyPlayer.displayName,
      nameSeparator,
      format(item.seasonYear, { singleCivilYear: item.singleCivilYear }),
      nameSeparator,
      getHumanReadableSerialNumber(item, { separator }),
    ].join('');
  },
  {
    anyCard: gql`
      fragment getName_anyCard on AnyCardInterface {
        slug
        seasonYear
        singleCivilYear
        anyPlayer {
          slug
          displayName
        }
        ...getHumanReadableSerialNumber_anyCard
      }
      ${getHumanReadableSerialNumber.fragments.anyCard}
    ` as TypedDocumentNode<getName_anyCard>,
  }
);

export const sortHitByRarity = (items: RefinementListItem[]) =>
  sortBy(
    item => {
      return (rarities as unknown as string[]).indexOf(item.label);
    },
    [...items]
  );

interface XpCard {
  xp: number;
  xpNeededForNextGrade: number | null;
  xpNeededForCurrentGrade: number;
}

export const percentageToNextLevel = ({
  xp,
  xpNeededForCurrentGrade,
  xpNeededForNextGrade,
}: XpCard) =>
  xpNeededForNextGrade
    ? ((xp - xpNeededForCurrentGrade) /
        (xpNeededForNextGrade - xpNeededForCurrentGrade)) *
      100
    : 100;

export const LEVEL_MIN = 0;
export const LEVEL_MAX = 20;

export const APPEARANCES_MIN = 0;
export const APPEARANCES_5_MAX = 5;
export const APPEARANCES_15_MAX = 15;

export const isTokenListedOnMarket = withFragments(
  (token: isTokenListedOnMarket_anyCard | null) => {
    // sentInLiveOffers will only have data if the card belongs to the currentUser
    return (token?.sentInLiveOffers ?? []).some(
      offer =>
        offer.type === OfferType.SINGLE_SALE_OFFER &&
        // handle case when token listing ended but frontend hasn't
        // refetched the data yet
        isFuture(offer.endDate)
    );
  },
  {
    anyCard: gql`
      fragment isTokenListedOnMarket_anyCard on AnyCardInterface {
        slug
        sentInLiveOffers {
          id
          type
          endDate
        }
      }
    ` as TypedDocumentNode<isTokenListedOnMarket_anyCard>,
  }
);

export const isMyCardListedOnMarket = withFragments(
  (card: isMyCardListedOnMarket_anyCard) => {
    return isTokenListedOnMarket(card);
  },
  {
    anyCard: gql`
      fragment isMyCardListedOnMarket_anyCard on AnyCardInterface {
        slug
        ...isTokenListedOnMarket_anyCard
      }
      ${isTokenListedOnMarket.fragments.anyCard}
    ` as TypedDocumentNode<isMyCardListedOnMarket_anyCard>,
  }
);

export const isTokenSentInDirectOffer = withFragments(
  (card: isTokenSentInDirectOffer_anyCard | null) => {
    return (card?.sentInLiveOffers ?? []).some(
      offer =>
        offer.type === OfferType.DIRECT_OFFER &&
        // handle case when token listing ended but frontend hasn't
        // refetched the data yet
        isFuture(offer.endDate)
    );
  },
  {
    anyCard: gql`
      fragment isTokenSentInDirectOffer_anyCard on AnyCardInterface {
        slug
        sentInLiveOffers {
          id
          type
          endDate
        }
      }
    ` as TypedDocumentNode<isTokenSentInDirectOffer_anyCard>,
  }
);

export const isSentInDirectOffer = withFragments(
  (card: isSentInDirectOffer_anyCard) => {
    return isTokenSentInDirectOffer(card);
  },
  {
    anyCard: gql`
      fragment isSentInDirectOffer_anyCard on AnyCardInterface {
        slug
        ...isTokenSentInDirectOffer_anyCard
      }
      ${isTokenSentInDirectOffer.fragments.anyCard}
    ` as TypedDocumentNode<isSentInDirectOffer_anyCard>,
  }
);

export const isCardQuality = (
  quality: Nullable<string>
): quality is CardQuality => {
  return Object.values(CardQuality).some(q => q === quality);
};

export const getAnimatedCardVideoSources = withFragments(
  (
    card:
      | getAnimatedCardVideoSources_anyCardInterface
      | getAnimatedCardVideoSources_so5Appearance,
    width: ValidWidths = 640
  ): { src: string; type: string }[] => {
    const { webmVideoUrl, webmLowResVideoUrl, movVideoUrl, movLowResVideoUrl } =
      card;

    const optimalWebmUrl =
      width > 320 ? webmVideoUrl : webmLowResVideoUrl || webmVideoUrl; // tmp fallback until low res webm are generated
    const optimalMovUrl =
      width > 320 ? movVideoUrl : movLowResVideoUrl || movVideoUrl; // tmp fallback until low res mov are generated

    return [
      optimalMovUrl
        ? { src: optimalMovUrl, type: 'video/mp4; codecs="hvc1"' }
        : null,
      optimalWebmUrl
        ? { src: optimalWebmUrl, type: 'video/webm; codecs="vp9"' }
        : null,
    ].filter(Boolean);
  },
  {
    anyCardInterface: gql`
      fragment getAnimatedCardVideoSources_anyCardInterface on AnyCardInterface {
        slug
        webmVideoUrl: videoUrl
        webmLowResVideoUrl: videoUrl(derivative: "low_res")
        movVideoUrl: videoUrl(derivative: "mov")
        movLowResVideoUrl: videoUrl(derivative: "mov_low_res")
      }
    ` as TypedDocumentNode<getAnimatedCardVideoSources_anyCardInterface>,
    so5Appearance: gql`
      fragment getAnimatedCardVideoSources_so5Appearance on So5AppearanceInterface {
        id
        webmVideoUrl: videoUrl
        webmLowResVideoUrl: videoUrl(derivative: "low_res")
        movVideoUrl: videoUrl(derivative: "mov")
        movLowResVideoUrl: videoUrl(derivative: "mov_low_res")
      }
    ` as TypedDocumentNode<getAnimatedCardVideoSources_so5Appearance>,
  }
);

export const getCardRepresentation = withFragments(
  (
    card: getCardRepresentation_anyCardInterface,
    options: { rewrapCard?: boolean } = {
      rewrapCard: false,
    }
  ): CardRepresentation => {
    const { rewrapCard } = options;
    if (!card.threeDimensionalAsset) {
      return { frontUrl: card.pictureUrl!, backUrl: card.backPictureUrl };
    }
    const {
      threeDimensionalAsset: { gltfUrl },
    } = card;

    if (card.blueprint && rewrapCard) {
      if (card.blueprint.revealStatus === BlueprintRevealStatus.REVEALED) {
        return {
          gltfUrl: card.blueprint.gltfUrl,
          state: BlueprintRevealStatus.REVEALABLE,
          progress: 100,
        };
      }

      return {
        gltfUrl: card.blueprint.gltfUrl,
        state: BlueprintRevealStatus.NOT_YET_REVEALABLE,
        progress: 90,
      };
    }

    return card.blueprint &&
      card.blueprint.revealStatus !== BlueprintRevealStatus.REVEALED
      ? {
          gltfUrl,
          state: card.blueprint.revealStatus,
          progress: card.blueprint.revealDate
            ? dateProgress(
                card.blueprint.launchDate ?? new Date(2024, 6, 15),
                card.blueprint.revealDate
              )
            : 0,
        }
      : {
          gltfUrl,
          videoSources: getAnimatedCardVideoSources(card),
          backOverlay: statsOverlay(card),
        };
  },
  {
    anyCardInterface: gql`
      fragment getCardRepresentation_anyCardInterface on AnyCardInterface {
        slug
        pictureUrl
        backPictureUrl
        blueprint {
          id
          launchDate
          revealDate
          revealStatus
          gltfUrl
        }
        threeDimensionalAsset {
          gltfUrl
        }
        ...getAnimatedCardVideoSources_anyCardInterface
        ...statsOverlay_AnyCardInterface
      }
      ${getAnimatedCardVideoSources.fragments.anyCardInterface}
      ${statsOverlay.fragments.AnyCardInterface}
    ` as TypedDocumentNode<getCardRepresentation_anyCardInterface>,
  }
);
