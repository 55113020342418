import { TypedDocumentNode, gql } from '@apollo/client';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import {
  MonetaryAmountOutput,
  useMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import FeesDetailsTooltip from 'components/offer/FeesDetailsTooltip';

import {
  MarketFeesOfferQuery,
  MarketFeesOfferQueryVariables,
  SalePriceFeesTooltip_offer,
} from './__generated__/index.graphql';

const MARKET_FEES_OFFER_QUERY = gql`
  query MarketFeesOfferQuery($id: String!) {
    tokens {
      offer(id: $id) {
        id
        marketFeeAmounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<MarketFeesOfferQuery, MarketFeesOfferQueryVariables>;

type Props = {
  sale: SalePriceFeesTooltip_offer;
  completed?: boolean;
  monetaryAmount: MonetaryAmountOutput;
  referenceCurrency: SupportedCurrency;
};

export const SalePriceFeesTooltip = ({
  sale,
  completed = false,
  monetaryAmount,
  referenceCurrency,
}: Props) => {
  const { toMonetaryAmount } = useMonetaryAmount();

  const { data } = useQuery(MARKET_FEES_OFFER_QUERY, {
    variables: { id: idFromObject(sale.id) },
  });

  const marketFeeAmounts = data?.tokens.offer.marketFeeAmounts;
  const marketFeeMonetaryAmount =
    marketFeeAmounts && toMonetaryAmount(marketFeeAmounts);
  const hasFees = marketFeeMonetaryAmount && marketFeeMonetaryAmount.eur > 0;

  if (!hasFees) return null;

  return (
    <FeesDetailsTooltip
      completed={completed}
      monetaryAmount={monetaryAmount}
      marketFeeMonetaryAmount={marketFeeMonetaryAmount}
      referenceCurrency={referenceCurrency}
    />
  );
};

SalePriceFeesTooltip.fragments = {
  offer: gql`
    fragment SalePriceFeesTooltip_offer on TokenOffer {
      id
    }
  ` as TypedDocumentNode<SalePriceFeesTooltip_offer>,
};
