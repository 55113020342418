import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Title4 } from '@sorare/core/src/atoms/typography';
import { OpenItemDialogLink } from '@sorare/core/src/components/link/OpenItemDialogLink';

import { CardCount } from 'components/ItemPreview/ui';
import FlexCard from 'components/token/FlexCard';
import { useClickBundleEvent } from 'hooks/events/useClickBundleEvent';

import {
  BundledAuctionPreview_anyCard,
  BundledAuctionPreview_auction,
} from './__generated__/index.graphql';

type Props = {
  bundledAuction: BundledAuctionPreview_auction;
  noMargin?: boolean;
  displayOneCard?: boolean;
};

const BundlePreviewContainer = styled.div`
  position: relative;
  margin-bottom: var(--intermediate-unit);
  &.noMargin {
    margin-bottom: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: var(--unit);
  aspect-ratio: var(--card-aspect-ratio);
`;

const OverlayContainer = styled.div`
  position: relative;
  pointer-events: auto;
`;

const Cell = styled.div`
  &:nth-of-type(3),
  &:nth-of-type(4) {
    display: flex;
    align-items: flex-end;
  }
`;

const Overlay = styled(Horizontal).attrs({ gap: 0 })`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: space-around;
  color: white;
  border-radius: var(--unit);
  pointer-events: none;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

export const BundledAuctionPreview = ({
  bundledAuction,
  noMargin,
  displayOneCard,
}: Props) => {
  const trackClickBundle = useClickBundleEvent();
  const { cards } = bundledAuction;

  if (displayOneCard) {
    return (
      <OpenItemDialogLink
        item={bundledAuction}
        sport={bundledAuction.cards[0].sport}
      >
        <RelativeContainer>
          <FlexCard card={bundledAuction.cards[0]} width={160} />
          <CardCount as="div">x{bundledAuction.cards.length}</CardCount>
        </RelativeContainer>
      </OpenItemDialogLink>
    );
  }

  return (
    <BundlePreviewContainer className={classNames({ noMargin })}>
      <OpenItemDialogLink
        item={bundledAuction}
        onClick={() =>
          trackClickBundle(bundledAuction.id, cards, cards[0].sport)
        }
        sport={cards[0].sport}
      >
        <Grid>
          {cards.slice(0, 4).map((card, i) => {
            return (
              <Cell key={card.slug}>
                <OverlayContainer>
                  <FlexCard card={card} width={160} />
                  {cards.length > 4 && i === 3 && (
                    <Overlay>
                      <Title4>+{cards.length - 4}</Title4>
                    </Overlay>
                  )}
                </OverlayContainer>
              </Cell>
            );
          })}
        </Grid>
      </OpenItemDialogLink>
    </BundlePreviewContainer>
  );
};

const cardFragment = gql`
  fragment BundledAuctionPreview_anyCard on AnyCardInterface {
    slug
    sport
    ...FlexCard_anyCard
    ...useClickBundleEvent_anyCard
  }
  ${FlexCard.fragments.anyCard}
  ${useClickBundleEvent.fragments.anyCard}
` as TypedDocumentNode<BundledAuctionPreview_anyCard>;

BundledAuctionPreview.fragments = {
  anyCard: cardFragment,
  auction: gql`
    fragment BundledAuctionPreview_auction on TokenAuction {
      id
      cards: anyCards {
        slug
        sport
        rarityTyped
        ...BundledAuctionPreview_anyCard
      }
    }
    ${cardFragment}
  ` as TypedDocumentNode<BundledAuctionPreview_auction>,
};
