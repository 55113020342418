import { defineMessages } from 'react-intl';

export const metadatas = {
  default: defineMessages({
    title: {
      id: 'Meta.baseball.default.title',
      defaultMessage: 'Best Fantasy MLB – Sorare: MLB',
    },
    description: {
      id: 'Meta.baseball.default.description',
      defaultMessage:
        "Collect, play and win officially licensed digital cards featuring the world's best baseball players from the Major League Baseball teams.",
    },
  }),
  market: defineMessages({
    title: {
      id: 'Meta.baseball.market.title',
      defaultMessage: 'Cards Market – Sorare: MLB',
    },
    description: {
      id: 'Meta.baseball.market.description',
      defaultMessage:
        "Discover new Sorare cards featuring the world's best Major League Baseball teams and players on Sorare: MLB",
    },
  }),
  newSignings: defineMessages({
    title: {
      id: 'Meta.baseball.newSignings.title',
      defaultMessage: 'New Cards Auctions – Sorare: MLB',
    },
    description: {
      id: 'Meta.baseball.newSignings.description',
      defaultMessage:
        "Find and collect Sorare cards featuring the world's best Major League Baseball teams and players on Sorare: MLB",
    },
  }),
  transfers: defineMessages({
    title: {
      id: 'Meta.baseball.transfers.title',
      defaultMessage: 'Manager Sales Market – Sorare: MLB',
    },
    description: {
      id: 'Meta.baseball.transfers.description',
      defaultMessage:
        'Find, collect and trade cards with other Sorare Managers',
    },
  }),
  team: defineMessages({
    title: {
      id: 'Meta.baseball.team.title',
      defaultMessage: '{team} – Team Profile – Sorare: MLB',
    },
    description: {
      id: 'Meta.baseball.team.description',
      defaultMessage:
        'Find all the statistics of {team}, collect and trade officially licensed digital cards of your favorite team on Sorare: MLB, the #1 Fantasy MLB game',
    },
  }),
};
