import { Sport } from '__generated__/globalTypes';
import { PrizesBlock } from 'components/landing/PrizesBlock';
import { JulioRodriguezPrize } from 'components/landing/PrizesBlock/JulioRodriguezPrize';

import { ACMilanPrize } from './ACMilanPrize';
import { CashPrize } from './CashPrize';
import { SevillaDerbyPrize } from './SevillaDerbyPrize';

export const Prizes = () => {
  return (
    <PrizesBlock>
      <ACMilanPrize />
      <CashPrize sport={Sport.FOOTBALL} />
      <SevillaDerbyPrize />
      <JulioRodriguezPrize />
    </PrizesBlock>
  );
};
