import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  MarketplacePromotionalEvent,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Title2 } from '@sorare/core/src/atoms/typography';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import { glossary } from '@sorare/core/src/lib/glossary';

import { ItemSpecialRewardBadge } from 'components/ItemPreview/ItemSpecialRewardBadge';
import AuctionTimeLeft from 'components/auction/AuctionTimeLeft';

const Column = styled(Vertical).attrs({ gap: 0 })``;
const Line = styled.div`
  display: flex;
  align-items: baseline;
  gap: var(--unit);
`;

type Props = {
  priceMonetaryAmount: MonetaryAmountOutput;
  endDate: Date;
  cancelled: boolean;
  privatePrice?: WeiAmount;
  promotionalEvent?: MarketplacePromotionalEvent;
};

export const SingleSaleOfferDetails = ({
  priceMonetaryAmount,
  privatePrice,
  endDate,
  cancelled,
  promotionalEvent,
}: Props) => {
  const usePrivatePrice =
    privatePrice && privatePrice > priceMonetaryAmount.wei;
  const monetaryAmount = usePrivatePrice
    ? {
        referenceCurrency: SupportedCurrency.WEI,
        wei: privatePrice,
      }
    : priceMonetaryAmount;

  const { main, exponent } = useAmountWithConversion({
    monetaryAmount,
  });
  return (
    <Column>
      <Line>
        {main && <Title2>{main}</Title2>}
        {exponent && <LabelM color="var(--c-neutral-600)">{exponent}</LabelM>}
      </Line>
      {promotionalEvent && <ItemSpecialRewardBadge event={promotionalEvent} />}
      {cancelled ? (
        <FormattedMessage {...glossary.canceled} />
      ) : (
        <LabelM color="var(--c-neutral-600)">
          <AuctionTimeLeft endDate={endDate} />
        </LabelM>
      )}
    </Column>
  );
};
