import styled, { css } from 'styled-components';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';

export const SegmentButton = styled(ButtonBase)<{
  $customCss?: ReturnType<typeof css>;
}>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: var(--unit);
  padding: var(--half-unit) var(--double-unit);
  border-radius: var(--unit);
  border: 1px solid var(--c-neutral-300);
  ${({ $customCss }) => $customCss || ''}

  &.checked {
    background: var(--c-neutral-1000);
    color: var(--c-neutral-100);
  }
`;
