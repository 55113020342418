import { TypedDocumentNode, gql } from '@apollo/client';

import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  RemoveAuctionReminderMutation,
  RemoveAuctionReminderMutationVariables,
} from './__generated__/useRemoveReminder.graphql';

const REMOVE_REMINDER = gql`
  mutation RemoveAuctionReminderMutation($input: removeAuctionReminderInput!) {
    removeAuctionReminder(input: $input) {
      auction {
        id
        myReminder {
          id
          enabled
        }
      }
      errors {
        code
        message
      }
    }
  }
` as TypedDocumentNode<
  RemoveAuctionReminderMutation,
  RemoveAuctionReminderMutationVariables
>;

type Props = {
  id: string;
  reminder: {
    id: string;
  };
};

export const useRemoveReminder = () => {
  const [removeReminder, { loading }] = useMutation(REMOVE_REMINDER, {
    showErrorsWithSnackNotification: true,
  });

  return {
    removeReminder: async ({ id, reminder }: Props) => {
      const { data } = await removeReminder({
        variables: { input: { auctionReminderId: reminder.id } },
        optimisticResponse: {
          removeAuctionReminder: {
            __typename: 'removeAuctionReminderPayload',
            auction: {
              __typename: 'TokenAuction',
              id,
              myReminder: {
                __typename: 'AuctionReminder',
                id: reminder.id,
                enabled: false,
              },
            },
            errors: [],
          },
        },
      });
      return data?.removeAuctionReminder?.auction?.myReminder;
    },
    loading,
  };
};
