import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { isType } from '@sorare/core/src/gql';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';

import Player from 'components/MatchView/Player';

import { FootballBench_anyPlayer } from './__generated__/index.graphql';

const Root = styled(Vertical)`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: var(--double-unit);
  align-items: center;

  &.desktop {
    flex-direction: row;
    align-items: flex-start;
  }
  & > * {
    flex: 1;
  }
`;

type Props = {
  benchPlayers: FootballBench_anyPlayer[];
  onClick: (playerSlug: string, scoreId?: string) => void;
  desktop?: boolean;
};
export const FootballBench = ({ benchPlayers, onClick, desktop }: Props) => {
  const sortedPlayers = [
    ...benchPlayers.filter(
      ({ playerGameScore }) =>
        ((isType(playerGameScore?.anyPlayerGameStats, 'PlayerGameStats') &&
          playerGameScore?.anyPlayerGameStats.minsPlayed) ||
          0) > 0
    ),
  ].sort((a, b) =>
    (a.playerGameScore?.score || 0) > (b.playerGameScore?.score || 0) ? -1 : 1
  );

  return (
    <Root gap={2} className={classNames({ desktop })}>
      {sortedPlayers.map(player => (
        <button
          key={player.slug}
          type="button"
          onClick={() =>
            onClick(player.slug, idFromObject(player.playerGameScore?.id))
          }
        >
          <Player player={player} startedBench />
        </button>
      ))}
    </Root>
  );
};

FootballBench.fragments = {
  anyPlayer: gql`
    fragment FootballBench_anyPlayer on AnyPlayerInterface {
      slug
      playerGameScore(gameId: $id) {
        id
        score
      }
      ...Player_anyPlayer
    }
    ${Player.fragments.anyPlayer}
  ` as TypedDocumentNode<FootballBench_anyPlayer>,
};
