import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { SeasonEligibility } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineXS, LabelS } from '@sorare/core/src/atoms/typography';
import { formatScarcity } from '@sorare/core/src/lib/cards';
import { seasonEligibilityMessages } from '@sorare/core/src/lib/glossary';

import CompetitionLogo from '../CompetitionLogo';
import { EligibleCompetition_so5Leaderboard } from './__generated__/index.graphql';

const Root = styled.div`
  padding: var(--triple-unit);
  border-radius: var(--double-unit);
  background-color: var(--c-neutral-300);

  &.common {
    --scarcity-bg-color: var(--c-rgb-common);
  }
  &.limited {
    --scarcity-bg-color: var(--c-rgb-limited);
  }
  &.rare {
    --scarcity-bg-color: var(--c-rgb-rare);
  }
  &.super_rare {
    --scarcity-bg-color: 0, 117, 255;
  }
  &.unique {
    --gradient: linear-gradient(
        103deg,
        rgba(173, 0, 255, 0.3) 6.52%,
        rgba(0, 0, 0, 0) 68.23%
      ),
      linear-gradient(
        160deg,
        rgba(22, 27, 28, 0) 35.97%,
        rgba(255, 0, 199, 0.4) 53.25%,
        rgba(62, 65, 66, 0) 68.55%
      );
  }
  &.inSeason {
    background: var(
        --gradient,
        linear-gradient(
          160deg,
          rgba(22, 27, 28, 0) 35.97%,
          rgba(var(--scarcity-bg-color, var(--c-rgb-neutral-200)), 0.4) 53.25%,
          rgba(62, 65, 66, 0) 68.55%
        )
      ),
      #231b1b;
  }
`;

const Metas = styled.div`
  margin-left: var(--double-unit);
`;

const Category = styled(LabelS)<{ $inSeason?: boolean }>`
  text-transform: uppercase;
  color: var(--c-text-secondary);
  ${({ $inSeason }) =>
    $inSeason
      ? css`
          background: linear-gradient(
            92deg,
            #fff 0%,
            #eabfe2 28.88%,
            #fff 48.67%,
            #bedecb 64.58%,
            #fff 100%
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
        `
      : ''}
`;

type Props = {
  so5Leaderboard: EligibleCompetition_so5Leaderboard;
  inSeason?: boolean;
};

export const EligibleCompetition = ({ so5Leaderboard, inSeason }: Props) => {
  const { mainRarityType, so5League } = so5Leaderboard;
  return (
    <Root
      className={classNames(mainRarityType, {
        inSeason,
      })}
    >
      <Horizontal gap={1}>
        <CompetitionLogo so5Leaderboard={so5Leaderboard} />
        <Metas>
          <Category color="var(--c-neutral-600)" $inSeason={inSeason}>
            <FormattedMessage
              {...(inSeason
                ? seasonEligibilityMessages[SeasonEligibility.IN_SEASON]
                : seasonEligibilityMessages[SeasonEligibility.CLASSIC])}
            />
          </Category>
          <HeadlineXS as="h6">{`${so5League.displayName} - ${
            mainRarityType && formatScarcity(mainRarityType)
          }`}</HeadlineXS>
        </Metas>
      </Horizontal>
    </Root>
  );
};

EligibleCompetition.fragments = {
  so5Leaderboard: gql`
    fragment EligibleCompetition_so5Leaderboard on So5Leaderboard {
      slug
      displayName
      mainRarityType
      so5League {
        slug
        displayName
      }
      ...CompetitionLogo_so5Leaderboard
    }
    ${CompetitionLogo.fragments.so5Leaderboard}
  ` as TypedDocumentNode<EligibleCompetition_so5Leaderboard>,
};
