import { Color } from '@sorare/core/src/style/types';

const InstantBuy = ({ color = 'var(--c-brand-300)' }: { color?: Color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.85255C0 2.3817 0.380764 2 0.850383 2H18.6188C19.5266 2 20.1893 2.86505 19.9512 3.74396L18.536 9.01385C18.2426 10.1004 17.2588 10.8581 16.137 10.8581H6.17893L6.36526 11.8443C6.44131 12.2353 6.78297 12.519 7.17991 12.519H17.1312C17.5903 12.519 17.9596 12.8893 17.9596 13.3495C17.9596 13.8097 17.5903 14.1799 17.1312 14.1799H7.17991C5.98558 14.1799 4.96045 13.3287 4.7396 12.1557L4.11962 8.89125C4.11263 8.85446 4.14084 8.82039 4.17829 8.82039H8.74679C9.21655 8.82039 9.59731 8.43869 9.59731 7.96784C9.59731 7.497 9.21655 7.11529 8.74679 7.11529H2.83254C2.40957 7.06589 2.08153 6.70781 2.08153 6.27336C2.08153 5.80515 2.46256 5.42561 2.93272 5.42561H6.64126C7.11102 5.42561 7.49178 5.04045 7.49178 4.56535C7.49178 4.09025 7.11102 3.7051 6.64126 3.7051H0.850383C0.380764 3.7051 0 3.32339 0 2.85255Z"
        fill={color}
      />
      <path
        d="M4.83115 16.0703C4.74796 16.2718 4.70508 16.4878 4.70508 16.7059C4.70508 16.924 4.74796 17.14 4.83115 17.3415C4.91447 17.543 5.0365 17.7261 5.19034 17.8803C5.34418 18.0345 5.52675 18.1569 5.72778 18.2404C5.92881 18.3238 6.14427 18.3668 6.36189 18.3668C6.57937 18.3668 6.79484 18.3238 6.99587 18.2404C7.1969 18.1569 7.3796 18.0345 7.53344 17.8803C7.68729 17.7261 7.80931 17.543 7.8925 17.3415C7.97583 17.14 8.0187 16.924 8.0187 16.7059C8.0187 16.4878 7.97583 16.2718 7.8925 16.0703C7.80931 15.8688 7.68729 15.6857 7.53344 15.5314C7.3796 15.3772 7.1969 15.2549 6.99587 15.1714C6.79484 15.0879 6.57937 15.045 6.36189 15.045C6.22652 15.045 6.09196 15.0616 5.96117 15.0943C5.88176 15.1142 5.80369 15.1399 5.72778 15.1714C5.52675 15.2549 5.34418 15.3772 5.19034 15.5314C5.0365 15.6857 4.91447 15.8688 4.83115 16.0703Z"
        fill={color}
      />
      <path
        d="M17.4743 15.5314C17.1635 15.22 16.7422 15.045 16.3028 15.045C15.8633 15.045 15.4419 15.22 15.1312 15.5314C14.8204 15.8429 14.6459 16.2654 14.6459 16.7059C14.6459 17.1464 14.8204 17.5688 15.1312 17.8803C15.4419 18.1918 15.8633 18.3668 16.3028 18.3668C16.7422 18.3668 17.1635 18.1918 17.4743 17.8803C17.785 17.5688 17.9596 17.1464 17.9596 16.7059C17.9596 16.2654 17.785 15.8429 17.4743 15.5314Z"
        fill={color}
      />
    </svg>
  );
};

export default InstantBuy;
