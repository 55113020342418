import { useIntl } from 'react-intl';

import { Sport } from '__generated__/globalTypes';
import { Button } from 'atoms/buttons/Button';
import { Coin } from 'atoms/icons/Coin';
import { LabelL } from 'atoms/typography';
import {
  FOOTBALL_CLUB_SHOP,
  MLB_CLUB_SHOP,
} from 'constants/__generated__/routes';
import { useSportContext } from 'contexts/sport';
import { useCoinBalance } from 'hooks/clubShop/useCoinBalance';

const CLUB_SHOP_ROUTES = {
  [Sport.FOOTBALL]: FOOTBALL_CLUB_SHOP,
  [Sport.BASEBALL]: MLB_CLUB_SHOP,
  [Sport.NBA]: undefined,
};

type Props = {
  onClick?: () => void;
};

export const CoinBalance = ({ onClick }: Props) => {
  const { sport } = useSportContext();
  const { formatNumber } = useIntl();

  const { coinBalance } = useCoinBalance();

  if (!sport || !CLUB_SHOP_ROUTES[sport]) {
    return null;
  }

  return (
    <Button
      size="medium"
      color="transparent"
      to={CLUB_SHOP_ROUTES[sport!]!}
      onClick={onClick}
    >
      <Coin />
      <LabelL as="span" bold>
        {formatNumber(coinBalance)}
      </LabelL>
    </Button>
  );
};
