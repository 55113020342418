import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { So5FixtureEvent } from '@sorare/core/src/__generated__/globalTypes';
import { ANY_SPORT_PLAY_EVENTTYPE } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { glossary } from '@sorare/core/src/lib/glossary';
import {
  ClickAfterPurchase_Action,
  ClickInstantBuy_Flow,
} from '@sorare/core/src/protos/events/so5/shared/events';

import {
  AvailablePrimaryBuyForAPlayer,
  Variant as AvailablePrimaryBuyForAPlayerVariant,
} from '@sorare/marketplace/src/components/primaryBuy/AvailablePrimaryBuyForAPlayer';

const StyledAvailablePrimaryBuyForAPlayer = styled(
  AvailablePrimaryBuyForAPlayer
)`
  background: var(--c-neutral-200);
  padding: var(--double-unit);
  border-radius: var(--double-unit);
`;

type Props = {
  playerSlug: string;
};

export const PrimaryBuyForAPlayer = ({ playerSlug }: Props) => {
  const { generateSportContextPath } = useSportContext();
  return (
    <StyledAvailablePrimaryBuyForAPlayer
      origin={ClickInstantBuy_Flow.MATCH_VIEW}
      playerSlug={playerSlug}
      title={
        <FormattedMessage
          id="primaryBuy.primaryBuyForAPlayer.title"
          defaultMessage="Scouted!"
        />
      }
      description={
        <FormattedMessage
          id="primaryBuy.primaryBuyForAPlayer.helper"
          defaultMessage="Crush others with this card and grab the rewards. You can also trade and sell it!"
        />
      }
      variant={AvailablePrimaryBuyForAPlayerVariant.PLAYER_VIEW}
      primaryBuyActions={{
        primary: {
          label: <FormattedMessage {...glossary.continue} />,
          action: ClickAfterPurchase_Action.CONTINUE,
        },
        secondary: {
          label: <FormattedMessage {...glossary.playInPro} />,
          to: generateSportContextPath(ANY_SPORT_PLAY_EVENTTYPE, {
            params: {
              eventType: So5FixtureEvent.CLASSIC.toLowerCase(),
            },
          }),
          action: ClickAfterPurchase_Action.PLAY_PRO,
        },
      }}
    />
  );
};
