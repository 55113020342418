import { useCurrentUserContext } from 'contexts/currentUser';
import { useAmountWithConversion } from 'hooks/useAmountWithConversion';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import {
  MonetaryAmountOutput,
  zeroMonetaryAmount,
} from 'hooks/useMonetaryAmount';
import { CurrencyCode } from 'lib/fiat';
import { MonetaryAmountParams } from 'lib/monetaryAmount';

const symbolRx: Record<CurrencyCode, RegExp> = {
  EUR: /(^|[0-9]|\s)€($|[0-9]|\s)/,
  USD: /(^|[0-9]|\s)\$($|[0-9]|\s)/,
  GBP: /(^|[0-9]|\s)£($|[0-9]|\s)/,
};

export const useStoreAmount = ({
  monetaryAmount,
  googlePrice,
  applePrice,
}: {
  monetaryAmount: MonetaryAmountParams | MonetaryAmountOutput;
  googlePrice: string | null | undefined;
  applePrice: MonetaryAmountParams | null | undefined;
}) => {
  const { isAndroidApp, isIosApp } = useIsMobileApp();
  const { fiatCurrency } = useCurrentUserContext();
  const { main } = useAmountWithConversion({ monetaryAmount });
  const { main: mainApplePrice, exponent: mainAppleExponent } =
    useAmountWithConversion({
      monetaryAmount: applePrice || zeroMonetaryAmount,
    });

  if (isAndroidApp && googlePrice) {
    const samePriceOrSameCurrency =
      main === googlePrice || googlePrice.match(symbolRx[fiatCurrency.code]);

    const storeMain = samePriceOrSameCurrency ? googlePrice : main;
    const storeExponent = samePriceOrSameCurrency ? undefined : googlePrice;
    return { storeMain, storeExponent };
  }
  if (isIosApp && applePrice) {
    return { storeMain: mainApplePrice, storeExponent: mainAppleExponent };
  }
  return { storeMain: undefined, storeExponent: undefined };
};
