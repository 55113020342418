import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Text16 } from 'atoms/typography';
import { SocialLinks } from 'atoms/ui/SocialMedias';
import { LanguageSwitcher } from 'components/user/LanguageSwitcher';
import {
  BLOG,
  GAME_RULES,
  LEGAL_NOTICE,
  SETTINGS,
  TERMS_AND_CONDITIONS,
} from 'constants/__generated__/routes';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { navLabels } from 'lib/glossary';
import { tabletAndAbove } from 'style/mediaQuery';

import { FooterLinks } from './FooterLinks';
import { copyright } from './copyright';
import socialLinks from './socialLinks';

const Footer = styled.footer`
  isolation: isolate;
  background: var(--c-neutral-100);
  color: var(--c-neutral-1000);
`;
const CopyAndLanguage = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--double-unit);
  padding: var(--double-unit) 0;
  @media ${tabletAndAbove} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
const CopyContainer = styled(Text16)`
  @media ${tabletAndAbove} {
    text-align: right;
  }
`;

const ShortCopyContainer = styled.div`
  font: var(--t-14);
  padding: var(--unit) 0;
  color: var(--c-neutral-500);
  @media ${tabletAndAbove} {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
`;

const ShortFooterLinkWrapper = styled(Horizontal).attrs({ gap: 0.5 })`
  flex-wrap: wrap;
`;

const ShortFooterLink = styled(Link)`
  color: var(--c-neutral-500);
  &:hover,
  &:focus {
    color: var(--c-neutral-1000);
    text-decoration: underline;
  }
`;

export const MultiSportFooter = () => {
  const isLoggedIn = useIsLoggedIn();
  const { isMobileApp } = useIsMobileApp();

  if (isLoggedIn || isMobileApp) {
    return (
      <Footer>
        <FullWidthContainer>
          <ShortCopyContainer>
            <Vertical gap={0}>
              <ShortFooterLinkWrapper>
                <ShortFooterLink to={TERMS_AND_CONDITIONS}>
                  <FormattedMessage {...navLabels.termsAndPrivacy} />
                </ShortFooterLink>
                ·
                <ShortFooterLink to={GAME_RULES}>
                  <FormattedMessage {...navLabels.gameRules} />
                </ShortFooterLink>
                ·
                <ShortFooterLink to={LEGAL_NOTICE}>
                  <FormattedMessage {...navLabels.legalNotice} />
                </ShortFooterLink>
                ·
                <ShortFooterLink to={BLOG}>
                  <FormattedMessage {...navLabels.blog} />
                </ShortFooterLink>
                ·
                <ShortFooterLink to={SETTINGS}>
                  <FormattedMessage {...navLabels.settings} />
                </ShortFooterLink>
              </ShortFooterLinkWrapper>
              {copyright}
            </Vertical>
          </ShortCopyContainer>
        </FullWidthContainer>
      </Footer>
    );
  }

  return (
    <Footer>
      <FullWidthContainer>
        <SocialLinks socialLinks={socialLinks} />
        <FooterLinks />
        <CopyAndLanguage>
          <LanguageSwitcher />
          <CopyContainer>{copyright}</CopyContainer>
        </CopyAndLanguage>
      </FullWidthContainer>
    </Footer>
  );
};
