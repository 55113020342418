import { defineMessages } from 'react-intl';

const messages = defineMessages({
  noneName: {
    id: 'club.TeamAvatar.none.name',
    defaultMessage: 'Coming soon',
    description:
      'The label to display in lieue of the club name when the club is not known yet',
  },
});

export default messages;
