import { createContext, useContext } from 'react';

export type RegisterFieldOptions<T> = {
  onChange: (val: T) => void;
  onError: (err: string | null) => void;
  defaultValue?: T;
  validate?: (val: T) => string | null;
};

export type FieldOptions<T> = {
  onChange: (value: T) => void;
  onError: (error: string | null) => void;
  validate?: (value: T) => string | null;
};

type ContextType<T> = {
  registerField: (name: string, options: RegisterFieldOptions<T>) => void;
  handleChange: (name: string) => (
    value: T,
    target?: {
      setCustomValidity?: (error: string) => void;
      reportValidity?: () => boolean;
    }
  ) => void;
  error: string | null;
  submitting: boolean;
  fields: { [name: string]: FieldOptions<any> };
};

export const formContext = createContext<ContextType<any> | null>(null);

export const useFormContext = () => useContext(formContext)!;

export default formContext.Provider;
