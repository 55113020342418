import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { OwnerTransfer } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { Caption, Text16 } from '@sorare/core/src/atoms/typography';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { glossary } from '@sorare/core/src/lib/glossary';
import { isType } from '@sorare/core/src/lib/gql';
import { transferTypes } from '@sorare/core/src/lib/owners';

import { useOwnerAccount } from 'hooks/tokens/useOwnerAccount';

import { TokenOwnerPrice } from '../TokenOwnerPrice';
import { AuctionOwnershipDetails } from './AuctionOwnershipDetails';
import { DirectOfferOwnershipDetails } from './DirectOfferOwnershipDetails';
import { Ownership_tokenOwner } from './__generated__/index.graphql';

type Props = {
  owner: Ownership_tokenOwner;
};

const Row = styled(SBHorizontal).attrs({ gap: 1 })`
  background-color: var(--c-neutral-300);
  border-radius: var(--double-unit);
  padding: var(--intermediate-unit);
`;
const Content = styled(Horizontal).attrs({ gap: 2 })`
  min-width: 0;
`;

const MinWidth = styled.div`
  min-width: 0;
`;

const DetailsContent = styled.div`
  background-color: var(--c-neutral-300);
  border-radius: var(--double-unit);
`;

export const Ownership = ({ owner }: Props) => {
  const [showDetails, setShowDetails] = useState(false);
  const { formatDistanceToNow, formatDate } = useIntlContext();
  const { up: laptop } = useScreenSize('laptop');

  const date = owner.from;
  const auction = isType(owner.deal, 'TokenAuction') ? owner.deal : undefined;
  const directOffer =
    isType(owner.deal, 'TokenOffer') &&
    owner.transferType === OwnerTransfer.DIRECT_OFFER
      ? owner.deal
      : undefined;

  const showViewMore = (auction && auction.bidsCount > 1) || directOffer;
  const ownerAccount = useOwnerAccount(owner);

  return (
    <Vertical>
      <div>
        <Row>
          <Content>
            {ownerAccount?.avatar}
            <MinWidth>
              <Text16>
                <FormattedMessage
                  {...transferTypes[owner.transferType]}
                  values={{
                    owner: ownerAccount?.owner,
                  }}
                />
              </Text16>
              <Tooltip
                title={formatDate(date, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              >
                <Caption color="var(--c-neutral-600)">
                  {formatDistanceToNow(date)}
                </Caption>
              </Tooltip>
            </MinWidth>
            {laptop && showViewMore && !showDetails && (
              <Button
                color="quaternary"
                size="small"
                onClick={() => setShowDetails(true)}
              >
                <FormattedMessage {...glossary.viewMore} />
              </Button>
            )}
          </Content>
          <div>
            <TokenOwnerPrice tokenOwner={owner} />
          </div>
        </Row>
      </div>
      {showDetails && (
        <DetailsContent>
          {auction && <AuctionOwnershipDetails auction={auction} />}
          {directOffer && <DirectOfferOwnershipDetails offer={directOffer} />}
        </DetailsContent>
      )}
    </Vertical>
  );
};

Ownership.fragments = {
  tokenOwner: gql`
    fragment Ownership_tokenOwner on TokenOwner {
      id
      from
      transferType
      ...TokenOwnerPrice_tokenOwner
      account {
        id
        ...useOwnerAccount_account
      }
      deal {
        ... on TokenAuction {
          id
          bidsCount
        }
        ... on Node {
          id
        }
      }
    }
    ${useOwnerAccount.fragments.account}
    ${TokenOwnerPrice.fragments.tokenOwner}
  ` as TypedDocumentNode<Ownership_tokenOwner>,
};

export default Ownership;
