interface Props {
  className?: string;
  color?: string;
}

const Pack = ({ className, color }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.5 0H4.5C3.65625 0 3 0.6875 3 1.5V3H1.5C0.65625 3 0 3.6875 0 4.5V14.5C0 15.3438 0.65625 16 1.5 16H11.5C12.3125 16 13 15.3438 13 14.5V13H14.5C15.3125 13 16 12.3438 16 11.5V1.5C16 0.6875 15.3125 0 14.5 0ZM11.3125 14.5H1.6875C1.5625 14.5 1.5 14.4375 1.5 14.3125V4.6875C1.5 4.59375 1.5625 4.5 1.6875 4.5H3V11.5C3 12.3438 3.65625 13 4.5 13H11.5V14.3125C11.5 14.4375 11.4062 14.5 11.3125 14.5ZM14.3125 11.5H4.6875C4.5625 11.5 4.5 11.4375 4.5 11.3125V1.6875C4.5 1.59375 4.5625 1.5 4.6875 1.5H14.3125C14.4062 1.5 14.5 1.59375 14.5 1.6875V11.3125C14.5 11.4375 14.4062 11.5 14.3125 11.5Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default Pack;
