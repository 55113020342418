import { TypedDocumentNode, gql } from '@apollo/client';

import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import {
  FetchGameStatusQuery,
  FetchGameStatusQueryVariables,
} from './__generated__/useFetchGameStatus.graphql';

const FETCH_GAME_STATUS_QUERY = gql`
  query FetchGameStatusQuery($slug: String!) {
    football {
      id
      rivals {
        id
        game(slug: $slug) {
          slug
          id
          game {
            id
            status: statusTyped
          }
        }
      }
    }
  }
` as TypedDocumentNode<FetchGameStatusQuery, FetchGameStatusQueryVariables>;

export const useFetchGameStatus = (slug: string) => {
  const { data, loading } = useQuery(FETCH_GAME_STATUS_QUERY, {
    variables: { slug },
  });

  return {
    status: data?.football.rivals.game.game.status,
    id: data?.football.rivals.game.game.id,
    loading,
  };
};
