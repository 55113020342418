import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Color } from '@sorare/core/src/atoms/buttons/Button';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { useTokenOfferBelongsToUser } from '@sorare/core/src/hooks/useTokenOfferBelongsToUser';

import useCancelOffer from 'hooks/offers/useCancelOffer';

import { CancelOffer_anyCard } from './__generated__/index.graphql';

interface Props {
  card: CancelOffer_anyCard;
  variant: 'small' | 'medium';
  color?: Color;
  fullWidth?: boolean;
}

const Root = styled(LoadingButton)`
  flex-shrink: 0;
`;

const CancelOffer = ({
  card: { myMintedSingleSaleOffer: offer },
  variant,
  color = 'red',
  fullWidth = false,
}: Props) => {
  const { showNotification } = useSnackNotificationContext();
  const [processing, setProcessing] = useState(false);
  const belongsToUser = useTokenOfferBelongsToUser();
  const cancelOffer = useCancelOffer();

  const { blockchainId } = offer!;

  const handleClick = async () => {
    setProcessing(true);
    const error = await cancelOffer(blockchainId!);
    if (!error) showNotification('cancelOffer');
    setProcessing(false);
  };

  if (offer && belongsToUser(offer)) {
    return (
      <Root
        loading={processing}
        color={color}
        size={variant}
        fullWidth={fullWidth}
        onClick={() => {
          handleClick();
        }}
      >
        <FormattedMessage
          id="CancelOffer.submit"
          defaultMessage="Cancel listing"
        />
      </Root>
    );
  }

  return null;
};

CancelOffer.fragments = {
  anyCard: gql`
    fragment CancelOffer_anyCard on AnyCardInterface {
      slug
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        blockchainId
        ...useTokenOfferBelongsToUser_offer
      }
    }
    ${useTokenOfferBelongsToUser.fragments.offer}
  ` as TypedDocumentNode<CancelOffer_anyCard>,
};

export default CancelOffer;
