import { isEqual } from 'date-fns';
import { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { LabelM, Text16, Title3 } from 'atoms/typography';
import { IntlDate } from 'components/IntlDate';
import { GraphQLResult, GraphqlForm, TextField } from 'components/form/Form';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useCreateOrUpdateFiatWallet } from 'hooks/wallets/useCreateOrUpdateFiatWallet';
import { glossary } from 'lib/glossary';

import { EditableRow } from '../EditableRow';
import { CreateFiatWalletSteps } from '../type';
import { useIsNotMajor } from '../useIsNotMajor';

const Content = styled(Vertical).attrs({ gap: 0 })`
  height: 100%;
`;

const Rows = styled(Vertical).attrs({ gap: 0 })``;

const Actions = styled(Vertical).attrs({ gap: 0 })`
  margin-top: var(--double-unit);
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;
`;
const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
  height: 100%;
`;

const Label = styled(Text16)`
  color: var(--c-neutral-1000);
`;

type Props = {
  setStep: (step: CreateFiatWalletSteps) => void;
};

export const ReviewInfoBeforeAddingId = ({ setStep }: Props) => {
  const { update } = useCreateOrUpdateFiatWallet();

  const { fiatWalletAccountable } = useCurrentUserContext();
  const { firstName, lastName, dob } = fiatWalletAccountable || {};

  // Dob should be not null at this step.
  const [year, month, day] = dob ? dob.split('-').map(Number) : [1990, 0, 1];

  const [newDob, setNewDob] = useState<Date>(
    new Date(Date.UTC(year, month - 1, day))
  );
  const [newFirstName, setNewFirstName] = useState<string>(firstName || '');
  const [newLastName, setNewLastName] = useState<string>(lastName || '');

  const updateAndSubmit = async (onResult: (result: GraphQLResult) => void) => {
    const newData = {
      firstName: newFirstName,
      lastName: newLastName,
      dob: newDob,
    };
    if (
      firstName !== newFirstName ||
      lastName !== newLastName ||
      (dob && !isEqual(newDob, new Date(dob)))
    ) {
      const updateResult = await update(newData);

      onResult(updateResult);
    }
    setStep(CreateFiatWalletSteps.CHOOSE_DOCUMENT);
  };

  const isNotMajor = useIsNotMajor(newDob);

  const disabled = isNotMajor || newFirstName === '' || newLastName === '';

  return (
    <>
      <Title3>
        <FormattedMessage
          id="CreateFiatWallet.ReviewInfo.edit.title"
          defaultMessage="Before adding your ID, review your personal info"
        />
      </Title3>
      <Text16>
        <FormattedMessage
          id="CreateFiatWallet.ReviewInfo.edit.description"
          defaultMessage="Please review the info below for accuracy so we can verify your identity. Your ID should match the personal info in your Sorare account."
        />
      </Text16>
      <StyledGraphqlForm
        onSubmit={(variables, onResult) => {
          updateAndSubmit(onResult);
        }}
        onSuccess={() => {
          setStep(CreateFiatWalletSteps.CHOOSE_DOCUMENT);
        }}
        render={(Error, SubmitButton) => (
          <Content>
            <Rows>
              <EditableRow
                value={newFirstName}
                label={
                  <Label>
                    <FormattedMessage {...glossary.firstName} />
                  </Label>
                }
              >
                <TextField
                  autoFocus
                  name="firstName"
                  defaultValue={newFirstName}
                  onChange={event => setNewFirstName(event?.target.value)}
                />
              </EditableRow>
              <EditableRow
                value={newLastName}
                label={
                  <Label>
                    <FormattedMessage {...glossary.lastName} />
                  </Label>
                }
              >
                <TextField
                  autoFocus
                  name="lastName"
                  onChange={event => {
                    setNewLastName(event?.target.value);
                  }}
                  defaultValue={newLastName}
                />
              </EditableRow>
              <EditableRow
                value={
                  <FormattedDate
                    value={newDob}
                    day="numeric"
                    month="long"
                    year="numeric"
                    timeZone="UTC"
                  />
                }
                label={
                  <Label>
                    <FormattedMessage {...glossary.dateOfBirth} />
                  </Label>
                }
              >
                <IntlDate
                  value={newDob}
                  onChange={date => {
                    setNewDob(date);
                  }}
                />
              </EditableRow>
              {isNotMajor && (
                <LabelM color="var(--c-red-600)">
                  <FormattedMessage {...glossary.notMajorError} />
                </LabelM>
              )}
            </Rows>
            <Error />
            <Actions>
              <Error />
              <SubmitButton fullWidth size="medium" disabled={disabled}>
                <FormattedMessage {...glossary.next} />
              </SubmitButton>
            </Actions>
          </Content>
        )}
      />
    </>
  );
};
