import styled from 'styled-components';

import Dialog from 'components/dialog';
import WalletPlaceholder from 'contexts/wallet/Placeholder';
import useScreenSize from 'hooks/device/useScreenSize';
import { useIsMobileApp } from 'hooks/useIsMobileApp';

const WalletFrame = styled.div`
  display: flex;
  min-height: 250px;
  padding: var(--triple-unit);
  & > :first-child {
    display: flex;
    width: 100%;
    min-height: 100%;
  }
`;

type Props = {
  open: boolean;
  onBack: () => void;
};
export const PasswordForgottenDialog = ({ open, onBack }: Props) => {
  const { up: isTablet } = useScreenSize('tablet');
  const { isMobileApp } = useIsMobileApp();

  return (
    <Dialog
      open={open}
      onBack={onBack}
      maxWidth="sm"
      fullWidth
      hideHeader
      fullScreen={isTablet || isMobileApp}
    >
      <WalletFrame>
        <WalletPlaceholder />
      </WalletFrame>
    </Dialog>
  );
};

export default PasswordForgottenDialog;
