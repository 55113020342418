import { TypedDocumentNode, gql } from '@apollo/client';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  differenceInMinutes,
  differenceInSeconds,
  isFuture,
  isPast,
} from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Popup } from '@sorare/core/src/atoms/layout/Popup';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, Caption } from '@sorare/core/src/atoms/typography';
import { Gauge } from '@sorare/core/src/atoms/ui/Gauge';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import TokenNameCancelSalePopin from 'components/token/TokenNameCancelSalePopin';
import useCancelOffer from 'hooks/offers/useCancelOffer';

import {
  CancelSalePopinQuery,
  CancelSalePopinQueryVariables,
  CancelSalePopin_anyCard,
} from './__generated__/index.graphql';

export interface Props {
  slug: string;
  onClose?: () => void;
}
const PaddedCaption = styled(Caption)`
  padding-right: var(--quadruple-unit);
`;

const RemainingMinutesCounter = ({
  remainingMinutes,
}: {
  remainingMinutes: number;
}) => (
  <PaddedCaption>
    {remainingMinutes <= 0 ? (
      <FormattedMessage
        id="CancelSalePopin.title.confirmed"
        defaultMessage="Listing confirmed."
      />
    ) : (
      <FormattedMessage
        id="CancelSalePopin.title"
        defaultMessage="Listing confirmed. It will be visible on the Market in {addedMinutes, plural, one {# minute} other {# minutes}}"
        values={{
          addedMinutes: remainingMinutes,
        }}
      />
    )}
  </PaddedCaption>
);

const cardFragment = gql`
  fragment CancelSalePopin_anyCard on AnyCardInterface {
    slug
    myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
      id
      createdAt
      startDate
      blockchainId
      receiverSide {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
    }
    liveSingleSaleOffer @skip(if: $onlyPrimary) {
      id
    }
    ...TokenNameCancelSalePopin_anyCard
  }
  ${monetaryAmountFragment}
  ${TokenNameCancelSalePopin.fragments.anyCard}
` as TypedDocumentNode<CancelSalePopin_anyCard>;

const CANCEL_SALE_POPIN_QUERY = gql`
  query CancelSalePopinQuery($slug: String!, $onlyPrimary: Boolean = false) {
    card: anyCard(slug: $slug) {
      slug
      ...CancelSalePopin_anyCard
    }
  }
  ${cardFragment}
` as TypedDocumentNode<CancelSalePopinQuery, CancelSalePopinQueryVariables>;

const Popin = styled(Vertical)`
  padding: var(--unit);
  color: var(--c-neutral-1000);
  background-color: var(--c-neutral-200);
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightContent = styled(Vertical).attrs({ gap: 0 })`
  align-items: flex-end;
  justify-content: center;
  text-align: right;
`;
const LeftContent = styled(Vertical).attrs({ gap: 0 })``;

const CloseButton = styled(Button)`
  position: absolute;
  top: 7px;
  right: 10px;
  color: var(--c-neutral-600);
  height: 20px;
  min-width: 0;
  width: var(--double-and-a-half-unit);
  padding: 0;
`;

const Close = styled(CloseButton)`
  position: absolute;
  top: 7px;
  right: 10px;
  color: var(--c-neutral-600);
  height: 20px;
  min-width: unset;
  width: 20px;
  padding: 0;
`;

const addedMinutesBeforeSaleStartDate = 2;

export const CancelSalePopin = ({ slug, onClose }: Props) => {
  const { showNotification } = useSnackNotificationContext();
  const cancelOffer = useCancelOffer();
  const [remainingMinutes, setRemainingMinutes] = useState(
    addedMinutesBeforeSaleStartDate
  );
  const [progress, setProgress] = useState(0);

  const [processing, setProcessing] = useState(false);
  const [closed, setClosed] = useState(false);

  const handleClose = useCallback(() => {
    setClosed(true);
    if (onClose) onClose();
  }, [onClose]);

  const { data } = useQuery(CANCEL_SALE_POPIN_QUERY, {
    variables: {
      slug,
    },
    fetchPolicy: 'cache-first',
  });

  const card = data?.card;

  const myMintedSingleSaleOffer = card?.myMintedSingleSaleOffer;
  const liveSingleSaleOffer = card?.liveSingleSaleOffer;

  useEffect(() => {
    if (myMintedSingleSaleOffer && !liveSingleSaleOffer) {
      const { startDate, createdAt } = myMintedSingleSaleOffer;
      setRemainingMinutes(differenceInMinutes(startDate, new Date()));

      if (isFuture(startDate)) {
        const differenceInSecondsBetweenInitialAndEndDate = differenceInSeconds(
          startDate,
          createdAt
        );
        const timer = setInterval(() => {
          const now = new Date();
          setRemainingMinutes(differenceInMinutes(startDate, now) + 1);
          setProgress(() => {
            const differenceInSecondsBetweenInitialDateAndNow =
              differenceInSeconds(now, createdAt);

            const progression =
              (differenceInSecondsBetweenInitialDateAndNow /
                differenceInSecondsBetweenInitialAndEndDate) *
              100.0;

            return Math.min(progression, 100);
          });
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      }
    }
    return () => {};
  }, [myMintedSingleSaleOffer, liveSingleSaleOffer]);

  if (!myMintedSingleSaleOffer || closed || liveSingleSaleOffer) {
    return null;
  }
  const {
    receiverSide: { amounts },
    startDate,
    blockchainId,
  } = myMintedSingleSaleOffer;

  if (isPast(startDate)) {
    return null;
  }

  const handleClick = async () => {
    setProcessing(true);
    const error = await cancelOffer(blockchainId!);
    if (!error) showNotification('cancelOffer');

    handleClose();
    setProcessing(false);
  };
  return (
    <Popup>
      <Gauge
        percentage={`${progress}%`}
        color="var(--c-yellow-600)"
        bgColor="rgba(var(--c-rgb-yellow-600), 0.2)"
        bgRadius="0"
        bgPadding="0"
        height="var(--half-unit)"
      />
      <Popin>
        <Close size="large" color="tertiary" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </Close>
        <RemainingMinutesCounter remainingMinutes={remainingMinutes} />
        <Content>
          <LeftContent>
            <TokenNameCancelSalePopin card={card} />
          </LeftContent>
          <RightContent>
            <AmountWithConversion monetaryAmount={amounts} column />
          </RightContent>
        </Content>
        <div>
          <LoadingButton
            size="compact"
            color="red"
            loading={processing}
            onClick={() => {
              handleClick();
            }}
          >
            <BodyS as="span">
              <FormattedMessage
                id="CancelSalePopin.cancel"
                defaultMessage="Cancel listing"
              />
            </BodyS>
          </LoadingButton>
        </div>
      </Popin>
    </Popup>
  );
};

CancelSalePopin.fragments = {
  anyCard: cardFragment,
};

export default CancelSalePopin;
