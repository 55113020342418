import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { currentUser } from 'contexts/currentUser/queries';
import { useSportContext } from 'contexts/sport';

import {
  AcceptTermsMutation,
  AcceptTermsMutationVariables,
} from './__generated__/useAcceptTerms.graphql';

type AcceptTermsMutation_acceptTerms = NonNullable<
  AcceptTermsMutation['acceptTerms']
>;

export interface AcceptTermsArgs {
  acceptTerms: boolean;
  acceptAgeLimit: boolean;
  acceptPrivacyPolicy: boolean;
  acceptGameRules: boolean;
  agreedToReceiveOffersFromPartners: boolean;
  tcuToken?: string | null;
}

const ACCEPT_TERMS_MUTATION = gql`
  mutation AcceptTermsMutation($input: acceptTermsInput!, $sport: Sport!) {
    acceptTerms(input: $input) {
      result
      currentUser {
        slug
        mustAcceptTcus
        ...CurrentUserProvider_currentUser
      }
      errors {
        code
        message
        path
      }
    }
  }
  ${currentUser}
` as TypedDocumentNode<AcceptTermsMutation, AcceptTermsMutationVariables>;

export default (): [
  (
    args: AcceptTermsArgs
  ) => Promise<AcceptTermsMutation_acceptTerms | null | undefined>,
] => {
  const [acceptTerms] = useMutation(ACCEPT_TERMS_MUTATION);
  const { sport } = useSportContext();

  return [
    useCallback(
      async (
        input: AcceptTermsArgs
      ): Promise<AcceptTermsMutation_acceptTerms | null | undefined> => {
        const result = await acceptTerms({
          variables: {
            input,
            sport,
          },
        });
        return result.data?.acceptTerms;
      },
      [acceptTerms, sport]
    ),
  ];
};
