import { Avatar } from 'components/avatar/Avatar';
import { CLUB_PLACEHOLDER } from 'constants/assets';

type Props = {
  club: {
    name?: string;
    avatarUrl?: string | null;
  };
  withTooltip?: boolean;
  className?: string;
  size?: number;
};

export const DumbClubAvatar = ({ club, ...rest }: Props) => {
  return <Avatar {...club} {...rest} fallbackUrl={CLUB_PLACEHOLDER} />;
};
