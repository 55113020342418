import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';

import { ColoredScore } from '@sorare/us-sports/src/components/scores/ColoredScore';
import { ColoredScoreWithDelta } from '@sorare/us-sports/src/components/scores/ColoredScoreWithDelta';

const Wrapper = styled(Vertical).attrs({ gap: 0, center: true })`
  justify-content: stretch;
`;

const Line = styled(Horizontal).attrs({ gap: 0.5 })`
  font: var(--t-12);
  font-weight: var(--t-bold);
  &.small {
    font-size: 10px;
  }
`;

type Props = {
  cardInLineupScoreWithBonus: number;
  playedInGame: boolean;
  tenGameAverage: number;
  coloredWithDelta?: boolean;
  displayFinal?: boolean;
};

export const FinalPlayerCardScore = ({
  cardInLineupScoreWithBonus,
  playedInGame,
  tenGameAverage,
  coloredWithDelta,
  displayFinal,
}: Props) => {
  return (
    <Wrapper>
      <Line className={classNames({ small: displayFinal })}>
        {coloredWithDelta ? (
          <ColoredScoreWithDelta
            score={cardInLineupScoreWithBonus}
            averageGameScore={tenGameAverage}
            playedInGame={playedInGame}
          />
        ) : (
          <ColoredScore
            score={cardInLineupScoreWithBonus}
            playedInGame={playedInGame}
          />
        )}
      </Line>
      {displayFinal && (
        <Line className="small">
          <FormattedMessage id="Lineup.player.final" defaultMessage="Final" />
        </Line>
      )}
    </Wrapper>
  );
};
