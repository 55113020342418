import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Drawer } from 'atoms/layout/Drawer';
import Dialog from 'components/dialog';
import { useIsDesktopAndAbove } from 'hooks/device/useIsDesktopAndAbove';

import {
  SocialShareContent,
  Props as SocialShareContentProps,
} from '../Content';
import { SocialShare_SocialPictures_Dialog } from './__generated__/index.graphql';

const Wrapper = styled.div`
  background-color: var(--c-nd-100-opaque);
  border-radius: var(--double-unit) var(--double-unit) 0 0;
`;

type Props = SocialShareContentProps & {
  opened: boolean;
  close: () => void;
};
const SocialShareDialog = ({ opened, close, ...rest }: Props) => {
  const isDesktopAndAbove = useIsDesktopAndAbove();
  const content = <SocialShareContent skip={!opened} {...rest} />;

  if (isDesktopAndAbove) {
    return (
      <Dialog onClose={close} open={opened} maxWidth="sm" fullWidth hideHeader>
        {content}
      </Dialog>
    );
  }

  return (
    <Drawer onBackdropClick={close} open={opened} side="bottom">
      <Wrapper>{content}</Wrapper>
    </Drawer>
  );
};

SocialShareDialog.fragments = {
  socialPictures: gql`
    fragment SocialShare_SocialPictures_Dialog on SocialPictureDerivative {
      ...SocialShare_Content_socialPictureDerivative
    }
    ${SocialShareContent.fragments.socialPictures}
  ` as TypedDocumentNode<SocialShare_SocialPictures_Dialog>,
};

export default SocialShareDialog;
