import { defineMessages, useIntl } from 'react-intl';

import { NonComponentWithTooltip } from '@sorare/core/src/atoms/tooltip/NonComponentWithTooltip';
import { Color } from '@sorare/core/src/style/types';

import Icon from './Icon';

const messages = defineMessages({
  title: {
    id: 'transfers.transfer.title',
    defaultMessage: 'Transfer',
  },
});
type Props = {
  color?: Color;
};
export const Transfer = ({ color }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <NonComponentWithTooltip title={formatMessage(messages.title)}>
      <Icon color={color} />
    </NonComponentWithTooltip>
  );
};

export default Transfer;
