import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelL, LabelM } from '@sorare/core/src/atoms/typography';

import Boost from './Boost';
import { Boosts_card } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 0, center: true })`
  padding: var(--triple-unit);
  justify-content: stretch;
`;
const FlexContainer = styled(Horizontal).attrs({ gap: 2 })`
  margin-top: var(--double-unit);
`;

type Props = {
  card: Boosts_card;
  onMaxLevelUpAppliedCountReached: () => void;
  closeDropdown: () => void;
};
const Boosts = ({
  card,
  onMaxLevelUpAppliedCountReached,
  closeDropdown,
}: Props) => {
  return (
    <Root>
      <LabelL as="span" color="var(--c-neutral-1000)" bold>
        <FormattedMessage
          id="CardPage.Boosts.title"
          defaultMessage="Level Up your card"
        />
      </LabelL>
      <LabelM color="var(--c-neutral-600)">
        <FormattedMessage
          id="CardPage.Boosts.subtitle"
          defaultMessage="Improve your player’s XP"
        />
      </LabelM>
      <FlexContainer>
        {card.availableCardBoosts.map(cardBoost => (
          <Boost
            key={cardBoost.shopItem.id}
            cardId={card.id}
            cardBoost={cardBoost}
            maxLevelUpAppliedCountReached={
              card.levelUpAppliedCount === card.maxLevelUpAppliedCount
            }
            onMaxLevelUpAppliedCountReached={onMaxLevelUpAppliedCountReached}
            closeDropdown={closeDropdown}
          />
        ))}
      </FlexContainer>
    </Root>
  );
};

Boosts.fragments = {
  card: gql`
    fragment Boosts_card on Card {
      id
      slug
      levelUpAppliedCount
      maxLevelUpAppliedCount
      # shopItem does not expose an ID because it's a union type
      # eslint-disable-next-line sorare/enforce-apollo-typepolicies
      availableCardBoosts {
        id
        ...Boost_cardBoost
      }
    }
    ${Boost.fragments.cardBoost}
  ` as TypedDocumentNode<Boosts_card>,
};

export default Boosts;
