import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card3DWithFallback } from 'components/3d/Card3DWithFallback';
import { UninteractiveBundledCardsPreview } from 'components/bundled/UninteractiveBundledCardsPreview';
import { CongratsScreen } from 'components/congratulations/CongratsScreen';
import { BundleWrapper, TokenImg } from 'components/congratulations/ui';

import {
  BuyConfirmation_anyCard,
  BuyConfirmation_anyReward,
} from './__generated__/index.graphql';

export type Props = {
  cards: BuyConfirmation_anyCard[];
  additionalRewards?: Nullable<BuyConfirmation_anyReward[]>;
  actions?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
};

export const BuyConfirmation = ({
  actions,
  cards,
  additionalRewards,
  title,
  subtitle,
}: Props) => {
  const isBundle = cards.length > 1;
  const card = cards[0];

  return (
    <CongratsScreen
      rarityBackground={card.rarityTyped}
      title={
        title || (
          <FormattedMessage
            id="buyTokenConfirmation.buy.title"
            defaultMessage="New{br}signing"
            values={{ br: <br /> }}
          />
        )
      }
      subtitle={
        subtitle || (
          <FormattedMessage
            id="buyTokenConfirmation.buy.subtitle"
            defaultMessage="Welcome {playerDisplayName} to your team!"
            values={{ playerDisplayName: card.anyPlayer.displayName }}
          />
        )
      }
      main={
        isBundle ? (
          <BundleWrapper>
            <UninteractiveBundledCardsPreview cards={cards} size="md" />
          </BundleWrapper>
        ) : (
          <TokenImg>
            <Card3DWithFallback card={card} disableFallback />
          </TokenImg>
        )
      }
      additionalRewards={additionalRewards}
      actions={actions}
      isBundle={isBundle}
    />
  );
};

BuyConfirmation.fragments = {
  anyCard: gql`
    fragment BuyConfirmation_anyCard on AnyCardInterface {
      slug
      sport
      anyPlayer {
        slug
        displayName
      }
      ...Card3DWithFallback_anyCardInterface
      ...UninteractiveBundledCardsPreview_anyCard
    }
    ${Card3DWithFallback.fragments.anyCardInterface}
    ${UninteractiveBundledCardsPreview.fragments.anyCard}
  ` as TypedDocumentNode<BuyConfirmation_anyCard>,
  anyReward: gql`
    fragment BuyConfirmation_anyReward on AnyRewardInterface {
      id
      ...CongratsScreen_anyReward
    }
    ${CongratsScreen.fragments.anyReward}
  ` as TypedDocumentNode<BuyConfirmation_anyReward>,
};
