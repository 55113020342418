import { NoIndex } from '@sorare/core/src/contexts/seo';

import CardPageContent from 'components/cards/CardPageContent';

const CardPage = () => {
  return (
    <>
      <NoIndex />
      <CardPageContent />
    </>
  );
};

export default CardPage;
