import styled from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import footballLogo from 'assets/logos/football/main.svg';
import mlbLogo from 'assets/logos/mlb/mlb.svg';
import nbaLogo from 'assets/logos/nba/nba.svg';
import { FOOTBALL_PATH, MLB_PATH, NBA_PATH } from 'constants/routes';
import { useIntlContext } from 'contexts/intl';
import { sportsLabelsMessages } from 'lib/glossary';

import { NavItem } from '../NavItem';

const Wrapper = styled.div`
  margin-top: auto;
`;

const SportIcon = styled.img`
  width: var(--triple-unit);
  height: var(--triple-unit);
  object-fit: contain;
`;

export const SportsNavItems = ({ current }: { current: Sport }) => {
  const { formatMessage } = useIntlContext();
  return (
    <Wrapper>
      {current !== Sport.NBA && (
        <NavItem to={NBA_PATH}>
          <SportIcon
            src={nbaLogo}
            alt={formatMessage(sportsLabelsMessages.NBA)}
          />
          {formatMessage(sportsLabelsMessages.NBA)}
        </NavItem>
      )}
      {current !== Sport.BASEBALL && (
        <NavItem to={MLB_PATH}>
          <SportIcon
            src={mlbLogo}
            alt={formatMessage(sportsLabelsMessages.BASEBALL)}
          />
          {formatMessage(sportsLabelsMessages.BASEBALL)}
        </NavItem>
      )}
      {current !== Sport.FOOTBALL && (
        <NavItem to={FOOTBALL_PATH}>
          <SportIcon
            src={footballLogo}
            alt={formatMessage(sportsLabelsMessages.FOOTBALL)}
          />
          {formatMessage(sportsLabelsMessages.FOOTBALL)}
        </NavItem>
      )}
    </Wrapper>
  );
};
