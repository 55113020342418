import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { RadioGroup } from '@sorare/core/src/atoms/inputs/RadioGroup';
import { Switch } from '@sorare/core/src/atoms/inputs/Switch';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import {
  Caption,
  LabelM,
  Text16,
  Title4,
  Title6,
} from '@sorare/core/src/atoms/typography';
import { LEARN_MORE_ABOUT_FIAT_WALLET } from '@sorare/core/src/components/fiatWallet/CreateFiatWallet/externalLinks';
import { AcceptedCurrenciesValue } from '@sorare/core/src/hooks/useAcceptedCurrencies';
import { useFiatBalance } from '@sorare/core/src/hooks/wallets/useFiatBalance';
import { payment } from '@sorare/core/src/lib/glossary';

const Wrapper = styled(Vertical)`
  padding: var(--double-and-a-half-unit) var(--double-unit);
  border-radius: var(--double-unit);
  background-color: var(--c-neutral-300);
`;

const HelperBlock = styled(Horizontal).attrs({ gap: 2 })`
  background-color: var(--c-neutral-400);
  padding: var(--unit);
  border-radius: var(--unit);
`;
const Option = styled(Vertical).attrs({ gap: 0.5 })`
  align-items: flex-start;
`;

const RecommendedBorder = styled.div`
  display: inline-flex;
  background: var(--c-gradient-conversionCreditBorder);
  border-radius: var(--half-unit);
  padding: 1px;
`;

const Recommended = styled.div`
  display: flex;
  background-color: var(--c-neutral-300);
  padding: 0 var(--half-unit);
  border-radius: var(--half-unit);
`;

const LinkToTerms = styled.a`
  text-decoration: underline;
`;

const StyledCaption = styled(Caption)`
  background: -webkit-linear-gradient(85deg, #7724ff 0%, #f3d0dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const Optional = styled(SBHorizontal)`
  background-color: var(--c-neutral-400);
  padding: var(--double-unit);
  border-radius: var(--unit);
`;
const OptionalBlock = styled.div`
  padding: 0 var(--double-unit) var(--double-unit);
`;
const Text = styled(Vertical).attrs({ gap: 0 })``;

const messages = defineMessages({
  announcement: {
    id: 'postLaunchFiatWalletListing.anouncement',
    defaultMessage: 'Cash Wallet has landed!',
  },
  cashAndEthDesc: {
    id: 'postLaunchFiatWalletListing.options.cashAndEth.description',
    defaultMessage:
      'A card has more than double the chance of being sold when listed in both Cash and ETH. You can choose to list in both Cash and ETH, with the card prices still set in ETH.',
  },
});

type Props = {
  onChange: (a: AcceptedCurrenciesValue, pegToEth?: boolean) => void;
};

export const AcceptedCurrenciesOnListing = ({ onChange }: Props) => {
  const { canListAndTrade } = useFiatBalance();
  const [selectedOption, setSelectedOption] =
    useState<AcceptedCurrenciesValue | null>(null);
  const [referenceCurrencyForSale, setReferenceCurrencyForSale] =
    useState<SupportedCurrency | null>(null);

  const options = [
    {
      label: (
        <Option>
          <RecommendedBorder>
            <Recommended>
              <StyledCaption bold color="var(--c-neutral-1000)">
                <FormattedMessage
                  id="PreLaunchFiatWalletListing.options.recommended"
                  defaultMessage="Recommended"
                />
              </StyledCaption>
            </Recommended>
          </RecommendedBorder>
          <Title6 color="var(--c-neutral-1000)">
            <FormattedMessage
              id="PreLaunchFiatWalletListing.options.cashAndEth.label"
              defaultMessage="Cash and ETH"
            />
          </Title6>
          <LabelM color="var(--c-neutral-800)">
            <FormattedMessage {...messages.cashAndEthDesc} />
          </LabelM>
        </Option>
      ),
      value: AcceptedCurrenciesValue.BOTH,
      optional: (
        <OptionalBlock>
          <Optional>
            <Text>
              <Text16 bold color="var(--c-neutral-1000)">
                <FormattedMessage {...payment.peggingTitle} />
              </Text16>
              <LabelM color="var(--c-neutral-600)">
                <FormattedMessage {...payment.peggingDescription} />
              </LabelM>
            </Text>
            <Switch
              name="reference-currency-for-sale"
              checked={referenceCurrencyForSale === SupportedCurrency.WEI}
              onChange={() => {
                const value =
                  referenceCurrencyForSale === SupportedCurrency.WEI
                    ? null
                    : SupportedCurrency.WEI;
                setReferenceCurrencyForSale(value);
                if (value) {
                  setSelectedOption(AcceptedCurrenciesValue.BOTH);
                  onChange(AcceptedCurrenciesValue.BOTH, true);
                }
              }}
              $backgroundColor="var(--c-neutral-500)"
            />
          </Optional>
        </OptionalBlock>
      ),
    },
    {
      label: (
        <Option>
          <Title6 color="var(--c-neutral-600)">
            <FormattedMessage
              id="PreLaunchFiatWalletListing.options.eth.label"
              defaultMessage=" Continue listing in ETH only"
            />
          </Title6>
        </Option>
      ),
      value: AcceptedCurrenciesValue.ETH,
    },
  ];

  return (
    <Wrapper>
      <Title4>
        <FormattedMessage
          id="preLaunchFiatWalletListing.inputLabel"
          defaultMessage="How would you like to get paid?"
        />
      </Title4>
      <LabelM color="var(--c-neutral-600)">
        <FormattedMessage
          id="preLaunchFiatWalletListing.helper"
          defaultMessage="You can update your preferences at any time within your settings."
        />
      </LabelM>
      <RadioGroup<AcceptedCurrenciesValue>
        modal
        rounded
        withSpacing
        options={options}
        value={selectedOption || undefined}
        preventPreselection
        name="pre-launch-fiat-wallet-listing"
        onChange={value => {
          setSelectedOption(value!);
          onChange(value);
          if (value === AcceptedCurrenciesValue.ETH) {
            setReferenceCurrencyForSale(null);
          }
        }}
      />
      {!canListAndTrade && (
        <HelperBlock>
          <FontAwesomeIcon icon={faInfoCircle} color="var(--c-neutral-1000)" />
          <LabelM color="var(--c-neutral-1000)">
            <FormattedMessage
              id="preLaunchFiatWalletListing.helperBlock"
              defaultMessage="Not sure? Learn more about Cash Wallet. <link>See details</link>"
              values={{
                link: s => (
                  <LinkToTerms
                    href={LEARN_MORE_ABOUT_FIAT_WALLET}
                    target="_blank"
                  >
                    {s}
                  </LinkToTerms>
                ),
              }}
            />
          </LabelM>
        </HelperBlock>
      )}
    </Wrapper>
  );
};
