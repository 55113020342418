import { TypedDocumentNode, gql } from '@apollo/client';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { BlueprintRevealStatus } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineXS } from '@sorare/core/src/atoms/typography';

import { RevealDelayedWarning_anyCard } from './__generated__/index.graphql';

interface Props {
  card: RevealDelayedWarning_anyCard;
}

const Root = styled(Horizontal)`
  background: rgba(var(--c-rgb-yellow-300), 0.16);
  padding: var(--unit);
  border-radius: var(--unit);

  a {
    text-decoration: underline;
  }
`;

export const RevealDelayedWarning = ({ card }: Props) => {
  if (card.blueprint?.revealStatus === BlueprintRevealStatus.DELAYED) {
    return (
      <Root>
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          color="var(--c-yellow-600)"
        />
        <HeadlineXS>
          <FormattedMessage
            id="RevealDelayedWarning.text"
            defaultMessage="Delayed Cards can’t be revealed now. <link>Learn more</link>"
            values={{
              link: (...children) => (
                <Link to="/help/a/20047651185821/what-happens-if-sorare-doesn-t-have-the-official-photos-of-my-player-before-the-reveal">
                  {children}
                </Link>
              ),
            }}
          />
        </HeadlineXS>
      </Root>
    );
  }
  return null;
};

RevealDelayedWarning.fragments = {
  anyCard: gql`
    fragment RevealDelayedWarning_anyCard on AnyCardInterface {
      slug
      blueprint {
        id
        revealStatus
      }
    }
  ` as TypedDocumentNode<RevealDelayedWarning_anyCard>,
};

export default RevealDelayedWarning;
