import { generatePath } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { FOOTBALL_MY_CARDS_BOXES_TYPE } from '@sorare/core/src/constants/__generated__/routes';

const BoxesPage = () => {
  return (
    <Navigate
      to={generatePath(FOOTBALL_MY_CARDS_BOXES_TYPE, {
        type: Rarity.limited,
      })}
    />
  );
};
export default BoxesPage;
