import { isPast } from 'date-fns';
import { useEffect, useState } from 'react';

import { GameStatus } from '@sorare/core/src/__generated__/globalTypes';

import { isGameLive } from '@sorare/gameplay/src/lib/so5';

const isLive = (game: { date: Date; status: GameStatus }) => {
  if (game.status === GameStatus.scheduled) {
    return isPast(game.date);
  }
  return isGameLive(game.status);
};

const MAX_TIMEOUT = 2147483647;

const useHasLiveGame = (games: { date: Date; status: GameStatus }[]) => {
  const [hasLiveGame, setHasLiveGame] = useState(false);

  const nextStartingGameTimestamp = Math.min(
    ...games
      .map(game => (isPast(game.date) ? Infinity : game.date.getTime()))
      .filter(Boolean)
  );

  if (games.some(game => isLive(game)) !== hasLiveGame) {
    setHasLiveGame(!hasLiveGame);
  }

  useEffect(() => {
    const msToNextLiveGame =
      isPast(nextStartingGameTimestamp) ||
      nextStartingGameTimestamp === Infinity
        ? MAX_TIMEOUT
        : nextStartingGameTimestamp - Date.now();

    const timeout = setTimeout(() => {
      setHasLiveGame(true);
    }, msToNextLiveGame);
    return () => clearTimeout(timeout);
  }, [nextStartingGameTimestamp]);

  return hasLiveGame;
};

export default useHasLiveGame;
