import { generatePath } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { NBA_MY_CARDS_ESSENCE_TYPE } from '@sorare/core/src/constants/__generated__/routes';

const EssencePage = () => {
  return (
    <Navigate
      to={generatePath(NBA_MY_CARDS_ESSENCE_TYPE, { type: Rarity.limited })}
    />
  );
};
export default EssencePage;
