import { ApolloLink } from '@apollo/client';
import { useMemo } from 'react';

import { useDebugContext } from 'contexts/debug';

import { ACTUAL_ENV } from '../../config';

export const useDebugLink = () => {
  const recordGQLOperation = useDebugContext()?.recordGQLOperation;

  const debugLink = useMemo(() => {
    if (
      !recordGQLOperation ||
      ACTUAL_ENV === 'production' ||
      process.env.NODE_ENV === 'test'
    ) {
      return undefined;
    }

    return new ApolloLink((operation, forward) => {
      return forward(operation).map(response => {
        const context = operation.getContext();
        if (!context.response) {
          // this is a websocket response, no HTTP response provided
          return response;
        }
        const {
          response: { headers },
        } = context;

        recordGQLOperation({
          id: crypto.getRandomValues(new Uint32Array(4)).join(''),
          operation,
          response: response.data,
          complexity: Number(headers.get('x-gql-complexity')),
          depth: Number(headers.get('x-gql-depth')),
          subgraphs: headers.get('x-gql-subgraphs')?.split(',') || [],
        });

        return response;
      });
    });
  }, [recordGQLOperation]);

  return debugLink;
};
