import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  FiatWalletAccountState,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import { CreateFiatWallet } from '@sorare/core/src/components/fiatWallet/CreateFiatWallet';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { useCashWalletKycEvents } from '@sorare/core/src/hooks/wallets/useCashWalletKycEvents';
import { useFiatBalance } from '@sorare/core/src/hooks/wallets/useFiatBalance';
import { glossary } from '@sorare/core/src/lib/glossary';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import {
  EnterCashWalletKYCFlow_Source,
  EnterCashWalletKYCFlow_Target,
} from '@sorare/core/src/protos/events/platform/web/events';

import OfferDealSummary from 'components/offer/OfferDealSummary';
import { TokenTransferValidator } from 'components/token/TokenTransferValidator';
import { useMarketplaceEvents } from 'lib/events';

import { switchToBuilding } from '../actions';
import { CardDataType, StateProps } from '../types';
import { OfferBuilderSummary_anyCard } from './__generated__/index.graphql';

type BaseType = CardDataType & OfferBuilderSummary_anyCard;

type Props<D extends BaseType> = StateProps<D> & {
  cancel: () => void;
  title: string;
  sender: ReactNode;
  receiver: ReactNode;
};

const Content = styled.div`
  width: 100%;
  padding: 0 var(--triple-unit) var(--triple-unit) var(--triple-unit);
`;
const ButtonsBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--double-unit);
`;
const CancelCta = styled(Button)`
  width: 50%;
`;
const ConfirmCta = styled(LoadingButton)`
  width: 50%;
`;
const FooterWrapper = styled(Vertical)`
  padding: var(--unit) var(--triple-unit) var(--triple-unit) var(--triple-unit);
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.2);
  width: 100%;
`;

export const Summary = <D extends BaseType>({
  title,
  cancel,
  dispatch,
  state,
  sender,
  receiver,
}: Props<D>) => {
  const { trackEnter } = useCashWalletKycEvents();
  const [needsCreateFiatWallet, setNeedsCreateFiatWallet] = useState(false);
  const { canListAndTrade } = useFiatBalance();

  const { up: isTablet } = useScreenSize('tablet');
  const track = useMarketplaceEvents();

  const {
    sendAmount,
    receiveAmount,
    receiveMarketFeesAmount,
    sendAmountCurrency,
    receiveAmountCurrency,
    sendCards,
    cardsData,
    receiveCards,
    stage,
    duration,
    paymentMethod,
  } = state;

  const setBuilding = () => dispatch(switchToBuilding);

  const onConfirm = () => {
    setNeedsCreateFiatWallet(false);
    if (
      !canListAndTrade &&
      receiveAmountCurrency !== SupportedCurrency.WEI &&
      receiveAmount.eur > 0
    ) {
      setNeedsCreateFiatWallet(true);
      trackEnter({
        source: EnterCashWalletKYCFlow_Source.COUNTEROFFER_TRADE_OFFER,
        target: EnterCashWalletKYCFlow_Target.OWNER,
      });
      return;
    }
    state.submit(dispatch, state);
    track('Click Submit Trade');
  };
  const [consentAgreed, setConsentAgreed] = useState(false);

  const receiveCardsWithToken = receiveCards.map(c => cardsData[c.objectID]);

  const sendCardsWithToken = sendCards.map(c => cardsData[c.objectID]);

  const isSubmitting = stage === 'submitting';

  if (needsCreateFiatWallet) {
    return (
      <CreateFiatWallet
        statusTarget={FiatWalletAccountState.OWNER}
        onClose={() => setNeedsCreateFiatWallet(false)}
        onDismissActivationSuccess={onConfirm}
        canDismissAfterActivation
      />
    );
  }
  return (
    <TokenTransferValidator
      cards={sendCardsWithToken}
      transferContext="send_trade"
    >
      {({ validationMessages, ConsentMessage }) => (
        <Dialog
          open
          maxWidth="md"
          fullWidth
          onBack={setBuilding}
          fullScreen={!isTablet}
          title={<Text16 className="text-center">{title}</Text16>}
          footer={
            <FooterWrapper>
              {ConsentMessage && (
                <ConsentMessage
                  value={consentAgreed}
                  onChange={setConsentAgreed}
                />
              )}
              <ButtonsBar>
                <CancelCta
                  color="tertiary"
                  onClick={cancel}
                  disabled={isSubmitting}
                  size="medium"
                >
                  <FormattedMessage {...glossary.cancel} />
                </CancelCta>
                <ConfirmCta
                  onClick={onConfirm}
                  size="medium"
                  color="primary"
                  loading={isSubmitting}
                  disabled={!!ConsentMessage && !consentAgreed}
                >
                  <FormattedMessage {...glossary.confirm} />
                </ConfirmCta>
              </ButtonsBar>
            </FooterWrapper>
          }
        >
          <Content>
            <OfferDealSummary
              sendAmount={sendAmount}
              receiveAmount={receiveAmount}
              sendAmountCurrency={sendAmountCurrency}
              receiveAmountCurrency={receiveAmountCurrency}
              marketFeeAmount={receiveMarketFeesAmount}
              receiveCards={receiveCardsWithToken}
              sendCards={sendCardsWithToken}
              duration={duration}
              withEmpty
              sender={sender}
              receiver={receiver}
              validationMessages={validationMessages}
              paymentMethod={paymentMethod}
            />
          </Content>
        </Dialog>
      )}
    </TokenTransferValidator>
  );
};

Summary.fragments = {
  anyCard: gql`
    fragment OfferBuilderSummary_anyCard on AnyCardInterface {
      slug
      assetId
      walletStatus
      tradeableStatus
      user {
        slug
      }
      publicMinPrices {
        ...MonetaryAmountFragment_monetaryAmount
      }
      ...OfferDealSummary_anyCard
    }
    ${OfferDealSummary.fragments.anyCard}
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<OfferBuilderSummary_anyCard>,
};

export default Summary;
