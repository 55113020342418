import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { differenceInHours } from 'date-fns';
import { FunctionComponent, ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { useIntlContext } from 'contexts/intl';
import { useTickerContext } from 'contexts/ticker';
import useScreenSize from 'hooks/device/useScreenSize';
import { useTimeLeft } from 'hooks/useTimeLeft';
import { TimeOptions } from 'lib/time';

type LayoutProps = {
  children: ReactNode;
};

type Props = {
  time: Date;
  Layout?: FunctionComponent<React.PropsWithChildren<LayoutProps>>;
  endLabel?: string;
  onEnded?: () => void;
  withExplicitTime?: boolean;
  forceInlineLayout?: boolean;
  hideTooltip?: boolean;
  options?: TimeOptions;
  children?: (args: {
    timeLeft: ReactNode | null;
    isEnded: boolean;
  }) => ReactNode;
};

const NoWrap = styled.span`
  white-space: nowrap;
`;

const DefaultLayout = ({ children }: LayoutProps) => (
  <NoWrap>{children}</NoWrap>
);

const Icon = styled.span.attrs({
  role: 'img',
})`
  font-size: 0.75em;
  line-height: 1;
`;

const ExplicitTime = ({
  time,
  forceInlineLayout,
}: Pick<Props, 'time' | 'forceInlineLayout'>) => {
  const { up: isTabletOrDesktop } = useScreenSize('tablet');
  const { now } = useTickerContext();
  const hoursDiff = differenceInHours(time, now);
  const { formatDate } = useIntlContext();

  const longTime = formatDate(time, {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  const inlineLayout = !isTabletOrDesktop || forceInlineLayout;

  if (inlineLayout) {
    const worthDisplayingTime = hoursDiff >= 3 || forceInlineLayout;

    if (worthDisplayingTime) {
      return (
        <>
          {' • '}
          <NoWrap>{longTime}</NoWrap>
        </>
      );
    }

    return null;
  }

  return (
    <Icon>
      <Tooltip title={longTime} placement="top">
        <FontAwesomeIcon icon={faClock} />
      </Tooltip>
    </Icon>
  );
};

export const TimeLeft = ({
  time,
  Layout = DefaultLayout,
  endLabel,
  onEnded,
  withExplicitTime = false,
  forceInlineLayout = false,
  hideTooltip = false,
  options,
  children,
}: Props) => {
  const { isEnded, message } = useTimeLeft(time, options);

  useEffect(() => {
    if (isEnded && onEnded) onEnded();
  }, [isEnded, onEnded]);

  if (isEnded) {
    if (endLabel) {
      return <span>{endLabel}</span>;
    }
    return <FormattedMessage id="TimeLeft.ended" defaultMessage="Ended" />;
  }

  let content = children ? children({ timeLeft: message, isEnded }) : message;
  if (withExplicitTime) {
    content = (
      <FormattedMessage
        id="TimeLeft.left"
        defaultMessage="{timeleft} left"
        values={{
          timeleft: message,
        }}
      />
    );
  }

  return (
    <>
      <Layout>{content}</Layout>{' '}
      {withExplicitTime && !hideTooltip && (
        <ExplicitTime time={time} forceInlineLayout={forceInlineLayout} />
      )}
    </>
  );
};

export default TimeLeft;
