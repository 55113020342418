import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { Title6 } from '@sorare/core/src/atoms/typography';

import Pack from 'components/transfers/Pack';

import { PaymentBoxBlock } from '../PaymentBox/ui';
import { TokensSummary } from '../TokensSummary';
import { BidBundleSummary_auction } from './__generated__/index.graphql';

type Props = {
  auction: BidBundleSummary_auction;
};

export const BidBundleSummary = ({ auction }: Props) => {
  return (
    <PaymentBoxBlock>
      <TokensSummary
        packTitle={
          <>
            <Pack />
            <Title6>
              <FormattedMessage
                id="BidBundleSummary.packTitle"
                defaultMessage="Bundle"
              />
            </Title6>
          </>
        }
        cards={auction.cards}
      />
    </PaymentBoxBlock>
  );
};

BidBundleSummary.fragments = {
  auction: gql`
    fragment BidBundleSummary_auction on TokenAuction {
      id
      cards: anyCards {
        slug
        ...TokensSummary_anyCard
      }
    }
    ${TokensSummary.fragments.anyCard}
  ` as TypedDocumentNode<BidBundleSummary_auction>,
};

export default BidBundleSummary;
