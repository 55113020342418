import { FC, ReactElement } from 'react';

import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useRedirectToSignup } from '@sorare/core/src/hooks/auth/useRedirectToLandingWithAction';

interface Props {
  element: ReactElement;
  withLayout?: boolean;
}

const WithLayout: FC<React.PropsWithChildren<{ withLayout?: boolean }>> = ({
  withLayout,
  children,
}) => {
  return withLayout ? <AppLayout>{children}</AppLayout> : <>{children}</>;
};

export const PrivateRoute = ({ element, withLayout }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const redirectToSignup = useRedirectToSignup();

  if (!element) {
    throw new Error('Missing component to render');
  }
  if (!currentUser) {
    return redirectToSignup();
  }
  return <WithLayout withLayout={withLayout}>{element}</WithLayout>;
};

export default PrivateRoute;
