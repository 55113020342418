import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, defineMessages } from 'react-intl';

import { LoadingButton } from 'atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';

const messages = defineMessages({
  soldOut: {
    id: 'ItemPreviewDialog.SizeSelector.Cta.soldOut',
    defaultMessage: 'Sold out',
  },
  buy: {
    id: 'ItemPreviewDialog.SizeSelector.Cta.buy',
    defaultMessage: 'Buy this item',
  },
});

type Props = {
  onClick: () => void;
  disabled?: boolean;
  soldOut?: boolean;
  loading?: boolean;
  locked?: boolean;
};

export const ClubShopItemBuyButton = ({
  onClick,
  disabled,
  loading,
  soldOut,
  locked,
}: Props) => {
  return (
    <LoadingButton
      color="primary"
      onClick={onClick}
      disabled={locked || soldOut || disabled}
      loading={!!loading}
      size="medium"
    >
      <Horizontal>
        {locked && <FontAwesomeIcon icon={faLock} />}
        <FormattedMessage {...(soldOut ? messages.soldOut : messages.buy)} />
      </Horizontal>
    </LoadingButton>
  );
};
