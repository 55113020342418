import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '__generated__/globalTypes';
import useMutation from 'hooks/graphql/useMutation';

import {
  DeleteDeckMutation,
  DeleteDeckMutationVariables,
} from './__generated__/useDeleteDeck.graphql';

const DELETE_CUSTOM_DECK_MUTATION = gql`
  mutation DeleteDeckMutation($input: deleteDeckInput!) {
    deleteDeck(input: $input) {
      userSportProfile {
        ... on UserSportProfileInterface {
          id
          decks(query: "") {
            nodes {
              slug
              name
              visible
              sport
              tokensCount
            }
          }
        }
      }
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<DeleteDeckMutation, DeleteDeckMutationVariables>;

export default () => {
  const [deleteDeck] = useMutation(DELETE_CUSTOM_DECK_MUTATION);

  return async (deck: { id: string; slug: string; sport: Sport }) =>
    deleteDeck({
      variables: {
        input: {
          deckSlug: deck.slug,
        },
      },
      update: cache => {
        cache.evict({
          id: cache.identify({
            slug: deck.slug,
            __typename: 'Deck',
          }),
        });
        cache.gc();
      },
    });
};
