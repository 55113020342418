import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

import { PageParams } from './__generated__/routeParams';

const MySquadLeaderboardSeasonSlugPage = () => {
  const { seasonSlug } = useTypedParams<PageParams>();
  const { currentUser } = useCurrentUserContext();

  if (!currentUser) {
    return null;
  }

  return (
    <Navigate
      to={generatePath(
        FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG,
        {
          seasonSlug,
          userSlug: currentUser.slug,
        }
      )}
      replace
    />
  );
};
export default MySquadLeaderboardSeasonSlugPage;
