import { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { DisplayS, LabelL } from '@sorare/core/src/atoms/typography';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

import { DialogDescription } from '../DialogDescription';
import paidCardDialogBg from './paid-card-dialog-bg.jpg';
import paidEarlyAccessCard from './paid-early-access-dialog-bg.jpg';

const Wrapper = styled(Vertical).attrs({ center: true })`
  height: 100%;
  justify-content: space-between;
  align-items: stretch;
  padding: var(--double-unit);
  background: var(--bg-image) no-repeat center / cover;
`;

const TitleSection = styled(Vertical)`
  padding: 0 var(--double-unit);
`;

const StyledDescription = styled(DialogDescription)`
  margin: 0;
  background: rgba(var(--c-rgb-neutral-100), 0.3);
  backdrop-filter: blur(10px);
`;

type Props = {
  isEarlyAccessCard?: boolean;
  description?: ReactNode;
};
export const GetFirstCard = ({
  children,
  isEarlyAccessCard,
  description,
}: PropsWithChildren<Props>) => {
  const {
    flags: { prePaywallDialogVariant = 'card' },
  } = useFeatureFlags();
  const isEarlyAccess =
    isEarlyAccessCard || prePaywallDialogVariant === 'blueprint';
  return (
    <Wrapper
      gap={4}
      className={prePaywallDialogVariant}
      style={
        {
          '--bg-image': `url(${isEarlyAccess ? paidEarlyAccessCard : paidCardDialogBg})`,
        } as CSSProperties
      }
    >
      <TitleSection center>
        <DisplayS color="var(--c-neutral-100)">
          <FormattedMessage
            id="PaidCardExplanatoryDialog.limitedCard"
            defaultMessage="Limited Cards"
          />
        </DisplayS>
        <LabelL className="text-center" color="var(--c-neutral-100)">
          <FormattedMessage
            id="PaidCardExplanatoryDialog.subtitle"
            defaultMessage="Collect cards of your favorite players, play them, and sell them anytime!"
          />
        </LabelL>
      </TitleSection>
      {description && <StyledDescription>{description}</StyledDescription>}
      {children}
    </Wrapper>
  );
};
