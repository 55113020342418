import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { Text16 } from '@sorare/core/src/atoms/typography';

import LiveOffer from '../LiveOffer';
import { MyOffers_anyCard } from './__generated__/index.graphql';

interface Props {
  card: MyOffers_anyCard;
}

export const MyOffers = ({ card }: Props) => {
  if (card.liveSingleBuyOffers.length === 0) return null;

  return (
    <div>
      <Text16 bold>
        <FormattedMessage id="MyOffers.title" defaultMessage="My Offers" />
      </Text16>
      {card.liveSingleBuyOffers.map(o => (
        <LiveOffer key={o.id} offer={o} />
      ))}
    </div>
  );
};

MyOffers.fragments = {
  anyCard: gql`
    fragment MyOffers_anyCard on AnyCardInterface {
      slug
      liveSingleBuyOffers {
        id
        ...LiveOffer_tokenOffer
      }
    }
    ${LiveOffer.fragments.tokenOffer}
  ` as TypedDocumentNode<MyOffers_anyCard>,
};

export default MyOffers;
