const Signature = () => {
  return (
    <svg
      width="226"
      viewBox="0 0 226 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_120_29816)">
        <path
          d="M5 88.0206C123.456 24.2562 116 -18.4794 79 21.5206C51.6893 51.0458 52.5 64.2971 64.5 64.2971C76.5 64.2971 101.475 38.5332 105.5 36.5207C112.5 33.0206 92 60.5207 101 59.0207C110 57.5207 123.202 42.5207 127 42.5207C130.798 42.5207 109.27 69.5207 116 69.5207C122.73 69.5207 162.5 36.5207 155.5 36.5207C146 36.5207 116.375 88.5742 220.5 27.5207"
          stroke="white"
          strokeWidth="2"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_120_29816"
          x="0.359334"
          y="0.833333"
          width="224.813"
          height="92.2345"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.08333" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_120_29816"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_120_29816"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Signature;
