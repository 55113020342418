import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Collapse } from '@sorare/core/src/atoms/layout/Collapse';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text14, Text16, Title6 } from '@sorare/core/src/atoms/typography';
import { UserName } from '@sorare/core/src/components/user/UserName';
import useToggle from '@sorare/core/src/hooks/useToggle';

import DirectOfferBody from '../DirectOfferBody';
import {
  CounteredOffer_publicUserInfoInterface,
  CounteredOffer_tokenOffer,
} from './__generated__/index.graphql';

const Container = styled(Vertical).attrs({ gap: 0.5 })`
  align-items: flex-start;
`;

const Offer = styled(Vertical).attrs({ gap: 2 })`
  background-color: var(--c-neutral-300);
  padding: var(--double-unit);
  border-radius: var(--intermediate-unit);
`;
const Button = styled(ButtonBase)`
  color: var(--c-brand-600);
`;

const WarningContainer = styled.div`
  padding: var(--unit) var(--double-unit);
  border: 1px solid var(--c-yellow-300);
  background-color: rgba(var(--c-rgb-yellow-300), 0.05);
  border-radius: var(--unit);
`;

const messages = defineMessages({
  title: {
    id: 'CounteredOffer.title',
    defaultMessage: 'You send this counter offer to {username}',
  },
  wasCurrentUserSenderTitle: {
    id: 'CounteredOffer.wasCurrentUserSenderTitle',
    defaultMessage: 'This is a counteroffer from {username}',
  },
  expandCta: {
    id: 'CounteredOffer.expandCta',
    defaultMessage: 'Show previous offer details',
  },
  expandedCta: {
    id: 'CounteredOffer.expandedCta',
    defaultMessage: 'Hide previous offer details',
  },
  offerTitle: {
    id: 'CounteredOffer.offerTitle',
    defaultMessage: 'Previous offer details',
  },
  offerWarning: {
    id: 'CounteredOffer.offerWarning',
    defaultMessage:
      'This offer is no longer valid because you sent a counter offer.',
  },
  wasCurrentUserSenderOfferWarning: {
    id: 'CounteredOffer.wasCurrentUserSenderOfferWarning',
    defaultMessage:
      'This offer was countered by {username} and it is no longer valid.',
  },
});

type Props = {
  offer: CounteredOffer_tokenOffer;
  wasCurrentUserSender: boolean;
  counterpartUser: CounteredOffer_publicUserInfoInterface;
};

const CounteredOffer = ({
  offer,
  wasCurrentUserSender,
  counterpartUser,
}: Props) => {
  const [expanded, toggleExpanded] = useToggle(false);
  return (
    <Container>
      <Text16 bold>
        <FormattedMessage
          {...(wasCurrentUserSender
            ? messages.wasCurrentUserSenderTitle
            : messages.title)}
          values={{
            username: <UserName user={counterpartUser} />,
          }}
        />
      </Text16>
      <Button type="button" onClick={toggleExpanded}>
        <Text16>
          <FormattedMessage
            {...(expanded ? messages.expandedCta : messages.expandCta)}
          />
        </Text16>
      </Button>

      <Collapse open={expanded} hideGap>
        <Offer>
          <Title6>
            <FormattedMessage {...messages.offerTitle} />
          </Title6>

          <WarningContainer>
            <Text14>
              <FormattedMessage
                {...(wasCurrentUserSender
                  ? messages.wasCurrentUserSenderOfferWarning
                  : messages.offerWarning)}
                values={{
                  username: <UserName user={counterpartUser} />,
                }}
              />
            </Text14>
          </WarningContainer>
          <DirectOfferBody
            offer={offer}
            counterpartUser={counterpartUser}
            isCurrentUserSender={wasCurrentUserSender}
          />
        </Offer>
      </Collapse>
    </Container>
  );
};
CounteredOffer.fragments = {
  user: gql`
    fragment CounteredOffer_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      ...UserName_publicUserInfoInterface
    }
    ${UserName.fragments.user}
  ` as TypedDocumentNode<CounteredOffer_publicUserInfoInterface>,
  tokenOffer: gql`
    fragment CounteredOffer_tokenOffer on TokenOffer {
      id
      sender {
        ... on User {
          slug
          ...UserName_publicUserInfoInterface
        }
      }
      receiver {
        ... on User {
          slug
          ...UserName_publicUserInfoInterface
        }
      }
      ...MySorareDirectOfferBody_tokenOffer
    }
    ${DirectOfferBody.fragments.tokenOffer}
    ${UserName.fragments.user}
  ` as TypedDocumentNode<CounteredOffer_tokenOffer>,
};

export default CounteredOffer;
