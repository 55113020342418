import { SVGProps } from 'react';

export const StackedCards = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.53042 1.71413V7.59189C3.53042 8.53872 4.04267 8.93245 4.9705 8.93245H8.5705C9.49842 8.93245 10.2506 8.29382 10.2506 7.34699V1.71427C10.2506 0.767539 9.49842 0 8.5705 0H5.21056C4.28273 0 3.53042 0.767373 3.53042 1.71413ZM0.170481 4.65301V10.2857C0.170481 11.2325 0.922625 12 1.85055 12H5.21049C5.8937 12 6.48154 11.5835 6.74405 10.9861C6.63628 11.0082 6.52471 11.0204 6.41047 11.0204H2.81047C1.88264 11.0204 1.01049 10.7242 1.01049 9.7774V3.67334C1.01049 3.42362 0.170481 3.82275 0.170481 4.65301ZM1.85055 3.18357V9.28764C1.85055 10.2345 2.3627 10.4017 3.29052 10.4017H6.89077C7.57388 10.4017 8.16173 10.1141 8.42433 9.51679C8.31647 9.53893 8.20499 9.55107 8.09075 9.55107H4.49075C3.56283 9.55107 2.69067 9.25484 2.69067 8.30801V2.20394C2.69067 1.95422 1.85055 2.35331 1.85055 3.18357Z" />
  </svg>
);
