import { useCallback } from 'react';

import {
  Currency,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { useMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { fromWei, toWei } from '@sorare/core/src/lib/wei';

export const algoliaWeiFactor = BigInt(1e13);

type ReturnedProps = {
  algoliaWeiFactor: bigint;
  formatPrice: (value: number, maxReachesEnd?: boolean) => string;
  sliderToPrice: (value: number) => string;
  priceToSlider: (value: number) => number;
};

const useFormatFilters = (): ReturnedProps => {
  const { currency, fiatCurrency } = useCurrentUserContext();
  const { toMonetaryAmount, getUserMonetaryAmount, getUserFiatAmount } =
    useMonetaryAmount();
  const { formatEth, formatNumber } = useIntlContext();

  const sliderToPrice = useCallback(
    (value: number): string => {
      const monetaryAmount = toMonetaryAmount({
        referenceCurrency: SupportedCurrency.WEI,
        wei: (value * Number(algoliaWeiFactor)).toFixed(0),
      });

      if (currency === Currency.FIAT) {
        return (getUserFiatAmount(monetaryAmount) / 100).toFixed(2);
      }
      return fromWei(monetaryAmount.wei).toString();
    },
    [currency, toMonetaryAmount, getUserFiatAmount]
  );

  const formatPrice = useCallback(
    (value: number, maxReachesEnd?: boolean) => {
      const price = sliderToPrice(value);

      let formattedNumber;
      if (currency === Currency.FIAT) {
        formattedNumber = formatNumber(Number(price), {
          style: 'currency',
          currency: fiatCurrency.code,
        });
      } else {
        formattedNumber = formatEth(price);
      }

      return `${formattedNumber}${maxReachesEnd ? '+' : ''}`;
    },
    [currency, fiatCurrency.code, formatEth, formatNumber, sliderToPrice]
  );

  const priceToSlider = useCallback(
    (value: number): number => {
      let wei;
      if (currency === Currency.FIAT) {
        wei = getUserMonetaryAmount(value).wei;
      } else {
        wei = toWei(value);
      }

      return Number(wei / algoliaWeiFactor);
    },
    [currency, getUserMonetaryAmount]
  );

  return {
    algoliaWeiFactor,
    formatPrice,
    sliderToPrice,
    priceToSlider,
  };
};

export default useFormatFilters;
