import { TypedDocumentNode, gql } from '@apollo/client';
import { isPast } from 'date-fns';

import {
  ConversionCredit,
  ConversionCreditStatus,
  MonetaryAmount,
  Sport,
} from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useQuery } from 'hooks/graphql/useQuery';
import { monetaryAmountFragment } from 'lib/monetaryAmount';

import {
  CurrentUser_conversionCredits,
  SportConversionCreditsQuery,
  SportConversionCreditsQueryVariables,
  useConversionCredits_conversionCredits,
} from './__generated__/useConversionCredits.graphql';
import { MonetaryAmountOutput, useMonetaryAmount } from './useMonetaryAmount';

export type ConversionCreditWithAmounts = ConversionCredit & {
  maxDiscount: MonetaryAmount & {
    eur: number;
    usd: number;
    gbp: number;
    wei: string;
  };
  totalDiscount: MonetaryAmount & {
    eur: number;
    usd: number;
    gbp: number;
    wei: string;
  };
  disabled: boolean;
};

export const conversionCreditFragment = gql`
  fragment useConversionCredits_conversionCredits on ConversionCredit {
    id
    endDate
    maxDiscount {
      ...MonetaryAmountFragment_monetaryAmount
    }
    totalDiscount {
      ...MonetaryAmountFragment_monetaryAmount
    }
    percentageDiscount
    singleUse
    status
    sport
    purchase {
      ... on TokenAuction {
        id
        anyCards {
          slug
          sport
        }
      }
      ... on TokenPrimaryOffer {
        id
        anyCards {
          slug
          sport
        }
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<useConversionCredits_conversionCredits>;

export const currentUserConversionCredits = gql`
  fragment CurrentUser_conversionCredits on CurrentUser {
    slug
    sportConversionCredits(first: 5, includeUsing: true, sport: $sport) {
      nodes {
        id
        ...useConversionCredits_conversionCredits
      }
    }
  }
  ${conversionCreditFragment}
` as TypedDocumentNode<CurrentUser_conversionCredits>;

const CONVERSION_CREDITS_QUERY = gql`
  query SportConversionCreditsQuery($sport: Sport) {
    currentUser {
      slug
      ...CurrentUser_conversionCredits
    }
  }
  ${currentUserConversionCredits}
` as TypedDocumentNode<
  SportConversionCreditsQuery,
  SportConversionCreditsQueryVariables
>;

export const getBestConversionCredit = (
  amount: MonetaryAmountOutput,
  conversionCredits: ConversionCreditWithAmounts[]
) => {
  return conversionCredits.reduce((acc, conversionCredit) => {
    if (
      Math.max(
        conversionCredit.maxDiscount.eur,
        (amount.eur * conversionCredit.percentageDiscount) / 100
      ) >
      Math.max(acc.maxDiscount.eur, (amount.eur * acc.percentageDiscount) / 100)
    )
      return conversionCredit;
    return acc;
  }, conversionCredits[0]);
};

export const useConversionCredits = ({
  allowedStatuses = [
    ConversionCreditStatus.CLAIMED,
    ConversionCreditStatus.RECLAIMED,
    ConversionCreditStatus.CREATED,
    ConversionCreditStatus.USING,
  ],
  currentlyUsedConversionCredit,
  sport,
  skip,
  forceRefetch,
}: {
  currentlyUsedConversionCredit?: ConversionCreditWithAmounts;
  allowedStatuses?: ConversionCreditStatus[];
  sport?: Sport;
  skip?: boolean;
  forceRefetch?: boolean;
}): {
  conversionCredits: ConversionCreditWithAmounts[] | undefined;
  loading: boolean;
} => {
  const { toMonetaryAmount } = useMonetaryAmount();
  const { currentUser } = useCurrentUserContext();
  const { data, loading } = useQuery(CONVERSION_CREDITS_QUERY, {
    skip: skip || !currentUser,
    fetchPolicy: forceRefetch ? 'cache-and-network' : 'cache-first',
    variables: { sport },
  });

  const conversionCredits =
    data?.currentUser?.sportConversionCredits?.nodes || [];

  const filteredConversionCredit = conversionCredits.reduce<
    ConversionCreditWithAmounts[]
  >((acc, conversionCredit) => {
    if (
      !allowedStatuses.includes(conversionCredit.status) ||
      isPast(conversionCredit.endDate)
    ) {
      return acc;
    }
    acc.push({
      ...conversionCredit,
      disabled:
        conversionCredit.status === ConversionCreditStatus.USING &&
        conversionCredit.id !== currentlyUsedConversionCredit?.id,
      ...{ maxDiscount: toMonetaryAmount(conversionCredit.maxDiscount) },
      ...{ totalDiscount: toMonetaryAmount(conversionCredit.totalDiscount) },
    } as ConversionCreditWithAmounts);
    return acc;
  }, []);

  return {
    conversionCredits: filteredConversionCredit,
    loading,
  };
};
