import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';

import { Sport } from '__generated__/globalTypes';
import { Bold } from 'atoms/typography/Bold';
import { DumbNotification } from 'components/activity/DumbNotification';
import { ANY_SPORT_CARD_SHOW } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import { getName } from 'lib/cards';
import { getSportOrSharedFromSport } from 'lib/sports';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { CardNotification_cardNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: CardNotification_cardNotification;
};

const messages = defineMessages({
  card_withdrawal_started: {
    id: 'Activity.Notifications.cardWithdrawalStarted',
    defaultMessage: 'Your card <b>{card}</b> is transferring to Ethereum',
  },
  card_withdrawn: {
    id: 'Activity.Notifications.cardWithdrawn',
    defaultMessage: 'Your card <b>{card}</b> has been transferred to Ethereum',
  },
});

export const CardNotification = ({ notification, ...rest }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { generateSportContextPath } = useSportContext();

  const { name, createdAt, anyCard: card, sport, read } = notification;

  if (!['card_withdrawal_started', 'card_withdrawn'].includes(name) && !card)
    return null;

  const link = generateSportContextPath(ANY_SPORT_CARD_SHOW, {
    params: {
      slug: card?.slug,
    },
    sport: card?.sport || Sport.FOOTBALL,
  });

  const title = messages[name as keyof typeof messages];

  return (
    <DumbNotification
      title={
        title && (
          <FormattedMessage
            {...title}
            values={{ b: Bold, card: card && getName(card) }}
          />
        )
      }
      userAvatar={currentUser}
      sport={getSportOrSharedFromSport(card?.sport) || sport}
      createdAt={createdAt}
      cardPicture={card}
      link={link}
      read={read}
      {...rest}
    />
  );
};

CardNotification.fragments = {
  cardNotification: gql`
    fragment CardNotification_cardNotification on CardNotification {
      id
      ...Notification_notificationInterface
      anyCard {
        slug
        sport
        ...getName_anyCard
        ...DumbNotification_cardPicture
      }
    }
    ${getName.fragments.anyCard}
    ${commonNotificationInterfaceFragment}
    ${DumbNotification.fragments.cardPicture}
  ` as TypedDocumentNode<CardNotification_cardNotification>,
};
