import { ComponentProps, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { HeadlineM, LabelM } from 'atoms/typography';
import { tabletAndAbove } from 'style/mediaQuery';

import defaultImage from './assets/matches.svg';

const Wrapper = styled(Vertical).attrs({ center: true, gap: 1 })`
  text-align: center;
  margin: auto;
  height: 100%;
  padding: calc(10 * var(--unit)) var(--triple-unit) var(--triple-unit);
`;

const Message = styled(LabelM).attrs({ as: 'div' })`
  @media ${tabletAndAbove} {
    max-width: calc(60 * var(--unit));
  }
`;

const StyledButton = styled(Button)`
  margin-top: var(--unit);
`;

const Ctas = styled(Horizontal)`
  flex-wrap: wrap;
  justify-content: center;
`;

export type Props = {
  className?: string;
  title: ReactNode;
  message?: ReactNode;
  image?: ReactNode;
  imageUrl?: string;
  ctaProps?: Partial<ComponentProps<typeof Button>>;
  secondaryCtaProps?: Partial<ComponentProps<typeof Button>>;
};

export const EmptyState = ({
  className,
  title,
  message,
  image,
  imageUrl = defaultImage,
  ctaProps,
  secondaryCtaProps,
}: Props) => {
  return (
    <Wrapper className={className}>
      {image || <img src={imageUrl} alt="" height={100} />}
      {title && <HeadlineM as="div">{title}</HeadlineM>}
      {message && <Message color="var(--c-neutral-600)">{message}</Message>}
      <Ctas>
        {secondaryCtaProps && (
          <StyledButton color="tertiary" size="small" {...secondaryCtaProps} />
        )}
        {ctaProps && (
          <StyledButton color="primary" size="small" to="/" {...ctaProps}>
            {ctaProps?.children || (
              <FormattedMessage
                id="EmptyState.cta"
                defaultMessage="Back to home"
              />
            )}
          </StyledButton>
        )}
      </Ctas>
    </Wrapper>
  );
};
