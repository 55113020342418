import { TypedDocumentNode, gql } from '@apollo/client';
import { FC, MouseEvent, MouseEventHandler, useEffect } from 'react';

import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { FOOTBALL } from '@sorare/core/src/constants/__generated__/routes';
import { useCloseDialog } from '@sorare/core/src/hooks/navigation/useCloseDialog';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';
import { useDeeplinkFromNativeApp } from '@sorare/core/src/hooks/useDeeplinkFromNativeApp';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import {
  useMonetaryAmount,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { fromWei } from '@sorare/core/src/lib/wei';
import { ClickInstantBuy_Flow } from '@sorare/core/src/protos/events/so5/shared/events';
import {
  collectionFromJSON,
  rarityFromJSON,
} from '@sorare/core/src/protos/webview/channel/native_messages';

import {
  PrimaryBuyConfirmationOptions,
  useBuyConfirmationContext,
} from 'contexts/buyingConfirmation';

import { PrimaryOfferPrice } from '../../Price';
import { MobileAppPrimaryOfferBuyFieldWrapper_anyCard } from './__generated__/index.graphql';

export type Props = {
  card: MobileAppPrimaryOfferBuyFieldWrapper_anyCard;
  primaryBuyConfirmationOptions?: PrimaryBuyConfirmationOptions;
  origin: ClickInstantBuy_Flow;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  gameSlug?: string;
  onBuyConfirmationClose?: () => void;
  composeSessionId?: string;
  children: FC<
    React.PropsWithChildren<{
      onClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
      loading: boolean;
      disabled?: boolean;
    }>
  >;
};

const MobileAppPrimaryOfferBuyFieldWrapper = ({
  children,
  card,
  origin,
  gameSlug,
  onBuyConfirmationClose,
  primaryBuyConfirmationOptions,
  onClick,
  composeSessionId = '',
}: Props) => {
  const {
    showBuyingConfirmation,
    setShowBuyingConfirmation,
    setBuyConfirmationProps,
  } = useBuyConfirmationContext();
  const {
    flags: { androidEnableInstantBuy = false },
  } = useFeatureFlags();

  const { navigateWithDeeplink } = useDeeplinkFromNativeApp();
  const track = useEvents();
  const { toMonetaryAmount } = useMonetaryAmount();
  const { isAndroidApp, isIosApp, postMessage, canUseIosPostMessageAndForYou } =
    useIsMobileApp();
  const idParam = useDialogParam(DialogKey.primaryOfferBought);
  const closeDialog = useCloseDialog();
  useEffect(() => {
    if (
      idParam &&
      card.latestPrimaryOffer?.id === idParam &&
      isAndroidApp &&
      !showBuyingConfirmation
    ) {
      setShowBuyingConfirmation(true);
    }
  }, [
    idParam,
    card.latestPrimaryOffer?.id,
    isAndroidApp,
    showBuyingConfirmation,
    setShowBuyingConfirmation,
  ]);

  if (!card.latestPrimaryOffer?.price) return null;

  const { sport, latestPrimaryOffer } = card;
  const { id, price } = latestPrimaryOffer;
  const priceMonetaryAmount =
    (price && toMonetaryAmount(price)) || zeroMonetaryAmount;

  const buyWithNativeApp = (
    e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    if (
      (isAndroidApp &&
        (!card.latestPrimaryOffer?.googlePrice || !androidEnableInstantBuy)) ||
      (isIosApp && !card.latestPrimaryOffer?.appleProductId)
    )
      return;
    onClick?.(e);
    track('Click Instant Buy', {
      offerId: id,
      cardSlug: card.slug,
      ethAmount: fromWei(priceMonetaryAmount.wei),
      eurAmount: priceMonetaryAmount.eur / 100,
      gameSlug,
      origin,
      scarcity: card.rarityTyped,
      sport,
      composeSessionId,
    });
    if (isAndroidApp) {
      setBuyConfirmationProps({
        primaryBuyId: card.latestPrimaryOffer?.id,
        onClose: () => {
          onBuyConfirmationClose?.();
        },
        beforeOnClose: () => {
          closeDialog(DialogKey.primaryOfferBought);
        },
        primaryBuyConfirmationOptions,
      });
      postMessage('instantBuy', {
        cardSlug: card.slug,
        playerSlug: card.anyPlayer.slug,
        collection: collectionFromJSON(card.collection),
        rarity: rarityFromJSON(card.rarityTyped.toUpperCase()),
      });
    } else if (canUseIosPostMessageAndForYou) {
      postMessage('instantBuy', {
        cardSlug: card.slug,
        playerSlug: card.anyPlayer.slug,
        collection: collectionFromJSON(card.collection),
        rarity: rarityFromJSON(card.rarityTyped.toUpperCase()),
      });
    } else {
      navigateWithDeeplink(FOOTBALL, undefined, { cardSlug: card.slug });
    }
  };

  return children({
    onClick: buyWithNativeApp,
    loading: false,
    disabled:
      (isAndroidApp &&
        (!card.latestPrimaryOffer?.googlePrice || !androidEnableInstantBuy)) ||
      (isIosApp && !card.latestPrimaryOffer?.appleProductId),
  });
};

MobileAppPrimaryOfferBuyFieldWrapper.fragments = {
  anyCard: gql`
    fragment MobileAppPrimaryOfferBuyFieldWrapper_anyCard on AnyCardInterface {
      slug
      collection
      sport
      rarityTyped
      anyPlayer {
        slug
      }
      latestPrimaryOffer {
        id
        appleProductId
        ...PrimaryOfferPrice_primaryOffer
      }
    }
    ${/* eslint-disable-next-line sorare/no-detached-fragments */ ''}
    ${PrimaryOfferPrice.fragments.primaryOffer}
  ` as TypedDocumentNode<MobileAppPrimaryOfferBuyFieldWrapper_anyCard>,
};

export default MobileAppPrimaryOfferBuyFieldWrapper;
