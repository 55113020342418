import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Caption, Text14 } from '@sorare/core/src/atoms/typography';
import { SimpleCardBonus } from '@sorare/core/src/components/card/SimpleCardBonus';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { CardHit, convertCardHitToCard } from '@sorare/core/src/lib/algolia';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import CardDescription from 'components/token/CardDescription';
import FlexCard from 'components/token/FlexCard';
import { algoliaWeiFactor } from 'hooks/filters/useFormatFilters';

import { SaleRow } from './SaleRow';
import { DirectOffer_CardRow_anyCard } from './__generated__/index.graphql';

interface BaseProps {
  card: CardHit;
  children?: React.ReactNode;
  selected?: boolean;
  hideBonus?: boolean;
}

interface NoMinPriceProps extends BaseProps {
  displayMinPrice?: never;
  minPriceCurrency?: never;
  cardData?: never;
}

interface WithMinPriceProps extends BaseProps {
  displayMinPrice: boolean;
  cardData?: DirectOffer_CardRow_anyCard;
}

type Props = NoMinPriceProps | WithMinPriceProps;

const MinimumPriceDisplay = ({
  publicMinPrices,
}: {
  publicMinPrices: NonNullable<
    NonNullable<DirectOffer_CardRow_anyCard>['publicMinPrices']
  >;
}) => {
  const { main } = useAmountWithConversion({
    monetaryAmount: {
      ...publicMinPrices,
    },
  });

  return (
    <Caption color="var(--c-neutral-600)">
      <FormattedMessage
        id="OfferSide.minPrice"
        defaultMessage="The seller set a minimum price of {amount}"
        values={{
          amount: main,
        }}
      />
    </Caption>
  );
};

const Root = styled(Horizontal).attrs({ gap: 1 })`
  width: 100%;
  padding: var(--double-unit);
`;

const Card = styled.div`
  width: 30px;
`;
const Container = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Description = styled.div`
  align-items: flex-start;
  text-align: left;
`;

const Bonus = styled.span<{ selected?: boolean }>``;

export const CardRow = ({
  card,
  cardData,
  displayMinPrice,
  children,
  selected,
  hideBonus,
}: Props) => {
  const convertedCardHit = useMemo(() => convertCardHitToCard(card), [card]);
  const wei = BigInt(card?.sale?.price || 0) * algoliaWeiFactor;

  return (
    <Root>
      <Card>
        <FlexCard card={convertedCardHit} width={40} />
      </Card>
      <Container>
        <Description>
          <CardDescription
            card={convertedCardHit}
            withoutLink
            detailsColor="var(--c-neutral-600)"
            {...(!hideBonus &&
              card.power && {
                bonus:
                  (
                    <Bonus selected={selected}>
                      <SimpleCardBonus power={card.power} />
                    </Bonus>
                  ) || undefined,
              })}
            Details={Text14}
          />
          {wei !== 0n && <SaleRow wei={wei} />}
        </Description>
        {cardData?.publicMinPrices && displayMinPrice && (
          <MinimumPriceDisplay publicMinPrices={cardData.publicMinPrices} />
        )}
      </Container>
      {children}
    </Root>
  );
};

CardRow.fragments = {
  anyCard: gql`
    fragment DirectOffer_CardRow_anyCard on AnyCardInterface {
      slug
      publicMinPrices {
        ...MonetaryAmountFragment_monetaryAmount
      }
      ...FlexCard_anyCard
      ...CardDescription_anyCard
    }
    ${monetaryAmountFragment}
    ${FlexCard.fragments.anyCard}
    ${CardDescription.fragments.anyCard}
  ` as TypedDocumentNode<DirectOffer_CardRow_anyCard>,
};

export default CardRow;
