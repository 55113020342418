import { BaseSubscriptionOptions } from '@apollo/client';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';

import { Subscription } from 'components/Subscription';
import {
  primaryOfferSubscription,
  tokenAuctionSubscription,
  tokenOfferSubscription,
} from 'lib/subscriptions';

export const matchRoutes = (routes: string[], pathname: string) =>
  routes.some(route => matchPath(route, pathname));

const subscriptionsOptions = (sports: Sport[]): BaseSubscriptionOptions => ({
  variables: { sports },
});

type Props = {
  sports: Sport[];
  auctionsRoutes: string[];
  offersRoutes: string[];
  primaryOffersRoutes: string[];
};

export const MarketSubscriptions = ({
  sports,
  auctionsRoutes,
  offersRoutes,
  primaryOffersRoutes,
}: Props) => {
  const location = useLocation();

  const subscribeToAuctions = useMemo(
    () => matchRoutes(auctionsRoutes, location.pathname),
    [auctionsRoutes, location.pathname]
  );

  const subscribeToOffers = useMemo(
    () => matchRoutes(offersRoutes, location.pathname),
    [location.pathname, offersRoutes]
  );

  const subscribeToPrimaryOffer = useMemo(
    () => matchRoutes(primaryOffersRoutes, location.pathname),
    [location.pathname, primaryOffersRoutes]
  );
  return (
    <>
      {subscribeToAuctions && (
        <Subscription
          gql={tokenAuctionSubscription}
          options={subscriptionsOptions(sports)}
        />
      )}
      {subscribeToOffers && (
        <Subscription
          gql={tokenOfferSubscription}
          options={subscriptionsOptions(sports)}
        />
      )}
      {subscribeToPrimaryOffer && (
        <Subscription
          gql={primaryOfferSubscription}
          options={subscriptionsOptions(sports)}
        />
      )}
    </>
  );
};
