import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import {
  Currency,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Text16, Text20 } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import {
  MonetaryAmountOutput,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { glossary } from '@sorare/core/src/lib/glossary';
import { WalletPaymentMethod } from '@sorare/core/src/lib/paymentMethod';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import SelectedPaymentMethodForConfirmation from 'components/buyActions/PaymentBox/Methods/SelectedPaymentMethodForConfirmation';
import TokenSummary from 'components/buyActions/TokenSummary';
import useMarketFeesHelperStatus, {
  MarketFeeStatus,
  isMarketFeeEnabled,
} from 'hooks/useMarketFeesHelperStatus';

import FeesDetailsTooltip from '../FeesDetailsTooltip';
import MonetaryCard from '../MonetaryCard';
import { OfferDealSummary_anyCard } from './__generated__/index.graphql';

type NonDirectOfferDealTypes = 'auction' | 'sell' | 'buy';

export type DealType = NonDirectOfferDealTypes | 'offer';

const messages = defineMessages({
  amount: {
    id: 'DealSummary.amount',
    defaultMessage: 'Amount',
  },
  details: {
    id: 'DealSummary.details',
    defaultMessage: 'Offer details',
  },
  duration: {
    id: 'DealSummary.duration',
    defaultMessage: 'Offer duration',
  },
  durationWarnings: {
    id: 'DealSummary.duration.warning',
    defaultMessage:
      'The Cards and money you send in an offer are held. You cannot include the Cards or money in another offer.',
  },
  nbDays: {
    id: 'DealSummary.duration.nbDays',
    defaultMessage: '{nbDays, plural, one {# day} other {# days}}',
  },
  nothing: {
    id: 'DealSummary.section.nothing',
    defaultMessage: 'Nothing',
  },
});

type Props = {
  withEmpty?: boolean;
  receiver?: ReactNode;
  sender?: ReactNode;
  validationMessages?: Record<string, ReactNode>;
  sendAmount?: MonetaryAmountOutput;
  sendCards?: OfferDealSummary_anyCard[];
  receiveAmount?: MonetaryAmountOutput;
  marketFeeAmount?: MonetaryAmountOutput;
  receiveCards?: OfferDealSummary_anyCard[];
  sendAmountCurrency: SupportedCurrency;
  receiveAmountCurrency: SupportedCurrency;
  duration?: number;
  paymentMethod: WalletPaymentMethod | null;
  withMonetaryCard?: boolean;
};

const NothingWarning = styled.div`
  padding: var(--unit) var(--double-unit);
  border-radius: var(--unit);
  background: rgba(var(--c-rgb-red-600), 0.05);
  border: var(--c-red-600) solid 1px;
`;
const UppercaseText16 = styled(Text16)`
  text-transform: uppercase;
`;

const Nothing = () => {
  return (
    <NothingWarning>
      <UppercaseText16>
        <FormattedMessage {...messages.nothing} />
      </UppercaseText16>
    </NothingWarning>
  );
};

interface TokensProps {
  cards: OfferDealSummary_anyCard[];
  validationMessages?: Record<string, ReactNode>;
}

const Root = styled(Vertical).attrs({ gap: 2 })``;

const WarningWrapper = styled.div`
  padding: var(--double-unit) 0 var(--unit);
`;

const ItemWrapper = styled(Vertical).attrs({ gap: 0.5 })`
  border-radius: var(--unit);
  background: var(--c-neutral-300);
  border: var(--c-neutral-400) solid 1px;
  padding: var(--double-unit);
`;

const Tokens = ({ cards, validationMessages }: TokensProps) => {
  return (
    <>
      {cards.map(card => (
        <ItemWrapper key={card.slug}>
          <TokenSummary
            small
            card={card}
            withoutRecentSales
            context="Offer Deal summary"
          />
          {validationMessages?.[card.slug] && (
            <WarningWrapper>{validationMessages[card.slug]}</WarningWrapper>
          )}
        </ItemWrapper>
      ))}
    </>
  );
};

const AmountLabel = styled(Text20)`
  font-style: italic;
`;

const ExponentLabel = styled(LabelM)`
  color: var(--c-neutral-600);
`;

interface AmountsProps {
  amount: MonetaryAmountOutput;
  marketFeeAmount?: MonetaryAmountOutput;
  marketFeeStatus: MarketFeeStatus;
  referenceCurrency: SupportedCurrency;
  withMonetaryCard?: boolean;
}

const Amounts = ({
  amount,
  marketFeeStatus = MarketFeeStatus.DISABLED,
  marketFeeAmount,
  referenceCurrency,
  withMonetaryCard = false,
}: AmountsProps) => {
  const {
    walletPreferences: { onlyShowFiatCurrency },
  } = useCurrentUserContext();
  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: amount,
    primaryCurrency:
      referenceCurrency === SupportedCurrency.WEI
        ? Currency.ETH
        : Currency.FIAT,
  });
  if (amount.eur === 0) {
    return null;
  }
  const isFeeEnabled = isMarketFeeEnabled(marketFeeStatus);
  if (withMonetaryCard)
    return (
      <ItemWrapper>
        <MonetaryCard
          amount={{ ...amount, referenceCurrency }}
          marketFeeAmount={marketFeeAmount}
          displayFees={isFeeEnabled}
          marketFeeStatus={marketFeeStatus}
        />
      </ItemWrapper>
    );
  return (
    <ItemWrapper>
      <AmountLabel bold>{main}</AmountLabel>
      {((exponent && !onlyShowFiatCurrency) ||
        (marketFeeAmount && isFeeEnabled)) && (
        <Horizontal>
          {exponent && !onlyShowFiatCurrency && (
            <ExponentLabel>{`≈ ${exponent}`}</ExponentLabel>
          )}
          {marketFeeAmount && isFeeEnabled && (
            <FeesDetailsTooltip
              monetaryAmount={amount}
              marketFeeMonetaryAmount={marketFeeAmount}
              referenceCurrency={referenceCurrency}
              marketFeeStatus={marketFeeStatus}
            />
          )}
        </Horizontal>
      )}
    </ItemWrapper>
  );
};

const DurationWarnings = styled.div`
  padding: var(--unit) var(--double-unit);
  border-radius: var(--unit);
  background: var(--c-neutral-300);
  border: var(--c-neutral-400) solid 1px;
`;

const OfferDetails = ({ duration }: Pick<Props, 'duration'>) => {
  if (!duration) {
    return null;
  }
  return (
    <Vertical>
      <div>
        <LabelM color="var(--c-neutral-600)">
          <FormattedMessage {...messages.duration} />
        </LabelM>
        <Text16>
          <FormattedMessage
            {...messages.nbDays}
            values={{ nbDays: duration }}
          />
        </Text16>
      </div>
      <DurationWarnings>
        <LabelM>
          <FormattedMessage {...messages.durationWarnings} />
        </LabelM>
      </DurationWarnings>
    </Vertical>
  );
};

interface OfferLegDescriptionProps {
  withEmpty: boolean;
  title: ReactNode;
  amount: MonetaryAmountOutput;
  marketFeeStatus?: MarketFeeStatus;
  marketFeeAmount?: MonetaryAmountOutput;
  cards: OfferDealSummary_anyCard[];
  validationMessages?: Record<string, ReactNode>;
  paymentMethod?: WalletPaymentMethod | null;
  referenceCurrency: SupportedCurrency;
  withMonetaryCard?: boolean;
}

const Description = styled(Vertical)`
  width: 100%;
`;

const OfferLegDescription = ({
  withEmpty,
  amount,
  marketFeeAmount,
  title,
  cards,
  marketFeeStatus = MarketFeeStatus.DISABLED,
  validationMessages,
  paymentMethod,
  referenceCurrency,
  withMonetaryCard = false,
}: OfferLegDescriptionProps) => {
  const empty = amount.wei === 0n && cards.length === 0;

  if (empty && !withEmpty) {
    return null;
  }
  return (
    <Description>
      {title}
      {empty ? (
        <Nothing />
      ) : (
        <>
          <Tokens cards={cards} validationMessages={validationMessages} />
          <Amounts
            amount={amount}
            marketFeeAmount={marketFeeAmount}
            marketFeeStatus={marketFeeStatus}
            referenceCurrency={referenceCurrency}
            withMonetaryCard={withMonetaryCard}
          />
          {amount.eur !== 0 && paymentMethod && (
            <>
              <LabelM color="var(--c-neutral-600)">
                <FormattedMessage {...glossary.payWith} />
              </LabelM>
              <SelectedPaymentMethodForConfirmation
                paymentMethod={paymentMethod}
                paymentCurrency={
                  paymentMethod === WalletPaymentMethod.ETH_WALLET
                    ? Currency.ETH
                    : Currency.FIAT
                }
              />
            </>
          )}
        </>
      )}
    </Description>
  );
};

const OfferRow = styled.div`
  display: flex;
  gap: var(--double-unit);
  flex-direction: column;
  @media ${tabletAndAbove} {
    flex-direction: row;
    padding-bottom: var(--double-unit);
    border-bottom: var(--c-neutral-300) solid 1px;
  }
`;

const OfferDealSummary = ({
  sendAmount = zeroMonetaryAmount,
  sendAmountCurrency,
  sendCards = [],
  receiveAmount = zeroMonetaryAmount,
  marketFeeAmount = zeroMonetaryAmount,
  receiveAmountCurrency,
  receiveCards = [],
  withEmpty = false,
  duration,
  sender,
  receiver,
  validationMessages,
  paymentMethod,
  withMonetaryCard,
}: Props) => {
  const marketFeeStatus = useMarketFeesHelperStatus(sendCards);
  return (
    <Root>
      <OfferRow>
        <OfferLegDescription
          withEmpty={withEmpty}
          title={sender}
          amount={sendAmount}
          cards={sendCards}
          validationMessages={validationMessages}
          paymentMethod={paymentMethod}
          referenceCurrency={sendAmountCurrency}
          withMonetaryCard={withMonetaryCard}
        />
        <OfferLegDescription
          withEmpty={withEmpty}
          title={receiver}
          amount={receiveAmount}
          marketFeeStatus={marketFeeStatus}
          marketFeeAmount={marketFeeAmount}
          cards={receiveCards}
          referenceCurrency={receiveAmountCurrency}
          withMonetaryCard={withMonetaryCard}
        />
      </OfferRow>
      <OfferDetails duration={duration} />
    </Root>
  );
};

OfferDealSummary.fragments = {
  anyCard: gql`
    fragment OfferDealSummary_anyCard on AnyCardInterface {
      slug
      ...TokenSummary_anyCard
      ...useMarketFeesHelperStatus_anyCard
    }
    ${useMarketFeesHelperStatus.fragments.anyCard}
    ${TokenSummary.fragments.anyCard}
  ` as TypedDocumentNode<OfferDealSummary_anyCard>,
};

export default OfferDealSummary;
