import { useState } from 'react';

import { FiatWalletAccountState } from '__generated__/globalTypes';

import { CreateFiatWalletWithInterstitialModal } from '../CreateFiatWalletWithInterstitialModal';
import { InterstitialContextModalMode } from '../InterstitialContextModal';

export const CreateFiatWalletWrapper = ({
  children,
  mode = InterstitialContextModalMode.DEPOSIT,
  statusTarget = FiatWalletAccountState.VALIDATED_OWNER,
  canDismissAfterActivation = true,
}: {
  mode?: InterstitialContextModalMode;
  statusTarget?: FiatWalletAccountState;
  canDismissAfterActivation?: boolean;
  children: (props: {
    setShowCreateFiatWallet: (value: boolean) => void;
  }) => React.JSX.Element;
}) => {
  const [showCreateFiatWallet, setShowCreateFiatWallet] =
    useState<boolean>(false);
  return (
    <>
      {showCreateFiatWallet && (
        <CreateFiatWalletWithInterstitialModal
          onDismissActivationSuccess={() => setShowCreateFiatWallet(false)}
          statusTarget={statusTarget}
          onClose={() => setShowCreateFiatWallet(false)}
          canDismissAfterActivation={canDismissAfterActivation}
          onDecline={() => setShowCreateFiatWallet(false)}
          mode={mode}
        />
      )}
      {children({ setShowCreateFiatWallet })}
    </>
  );
};
