import { TypedDocumentNode, gql } from '@apollo/client';

import { DumbNotification } from 'components/activity/DumbNotification';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { MarketingNotification_marketingNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: MarketingNotification_marketingNotification;
};

export const MarketingNotification = ({ notification, ...rest }: Props) => {
  const { createdAt, title, url, imageUrl, sport, read } = notification;

  if (imageUrl) {
    return (
      <DumbNotification
        title={title}
        link={url}
        createdAt={createdAt}
        sport={sport}
        read={read}
        avatarUrl={imageUrl}
        {...rest}
      />
    );
  }

  return (
    <DumbNotification
      title={title}
      link={url}
      createdAt={createdAt}
      sport={sport}
      read={read}
      {...rest}
    />
  );
};

MarketingNotification.fragments = {
  marketingNotification: gql`
    fragment MarketingNotification_marketingNotification on MarketingNotification {
      id
      ...Notification_notificationInterface
      title
      imageUrl
      url
    }
    ${commonNotificationInterfaceFragment}
  ` as TypedDocumentNode<MarketingNotification_marketingNotification>,
};
