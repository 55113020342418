import { useIntl } from 'react-intl';
import { UseSpringProps, animated, useSpring } from 'react-spring';

type Props = {
  value?: number;
  animationProps?: UseSpringProps;
  disableAnimationOnFirstRender?: boolean;
};

export const AnimatedNumber = ({
  value,
  animationProps,
  disableAnimationOnFirstRender,
}: Props) => {
  const { formatNumber } = useIntl();
  const { animatedValue } = useSpring<{ animatedValue: number }>({
    from: disableAnimationOnFirstRender
      ? undefined
      : {
          animatedValue: 0,
        },
    to: {
      animatedValue: value,
    },
    ...animationProps,
  });

  return (
    <animated.span>
      {animatedValue.to(x =>
        formatNumber(x, {
          maximumFractionDigits: 0,
        })
      )}
    </animated.span>
  );
};
