import {
  Children,
  ComponentProps,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import Dialog from 'components/dialog';
import { CloseButton } from 'components/dialog/CloseButton';
import { Stepper } from 'components/onboarding/Dialog/Stepper';
import { glossary } from 'lib/glossary';

const Root = styled(Vertical).attrs({ gap: 2 })`
  justify-content: space-between;
  height: 100%;
  isolation: isolate;
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: var(--double-unit);
  right: var(--double-unit);
`;

const SlidingContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease;
  height: 100%;
`;

const StepperWrapper = styled(Horizontal).attrs({ center: true })`
  z-index: 1;
  position: absolute;
  width: 112px;
  align-self: center;
  padding: var(--quadruple-unit) 0 var(--double-unit);
  isolation: isolate;
`;

const FooterButtons = styled(Horizontal).attrs({ gap: 2 })`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 var(--double-unit) var(--double-unit);
  & > * {
    flex: 1;
  }
`;
const Step = styled.div`
  min-width: 100%;
  display: grid;
  > * {
    padding-top: calc(
      8 * var(--unit)
    ) !important; /** This can't be override by the component itself */
    padding-bottom: calc(
      9 * var(--unit)
    ) !important; /** This can't be override by the component itself */
  }
`;

type Props = {
  open: boolean;
  onClose?: () => void;
  children: ReactNode | ((args: { currentStep: number }) => ReactNode);
  onStepChanged?: (step: number, prevStep: number) => void;
  className?: string;
  disableClose?: boolean;
  disableSkip?: boolean;
  confirmCtaProps?: Partial<ComponentProps<typeof Button>>;
};

export const TutorialDialog = ({
  open,
  onClose: onCloseProp,
  children,
  onStepChanged,
  className,
  disableClose,
  disableSkip,
  confirmCtaProps,
}: Props) => {
  const [step, setStep] = useState(0);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const updateStep = (s: number) => {
    onStepChanged?.(s, step);
    setStep(s);
  };
  useEffect(() => {
    const clearTimeoutFn = () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    };
    clearTimeoutFn();
    return clearTimeoutFn;
  }, []);

  const onClose = () => {
    onCloseProp?.();
    timeout.current = setTimeout(() => {
      setStep(0);
    }, 500);
  };

  const computedChildren =
    typeof children === 'function' ? children({ currentStep: step }) : children;

  const stepsNumber = Children.toArray(computedChildren).length;
  const isLastStep = step === stepsNumber - 1;
  const isFirstStep = step === 0;

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={disableClose ? undefined : onClose}
      hideHeader
    >
      <Root>
        <StepperWrapper>
          <Stepper step={step} length={stepsNumber} setStep={updateStep} />
        </StepperWrapper>
        {!disableClose && (
          <CloseButtonWrapper>
            <CloseButton onClose={onClose} />
          </CloseButtonWrapper>
        )}

        <SlidingContentWrapper
          style={{
            transform: `translateX(calc(-100% * ${step})`,
          }}
        >
          {Children.map(computedChildren, (item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Step key={index} className={className}>
              {item}
            </Step>
          ))}
        </SlidingContentWrapper>
        <FooterButtons>
          {(!disableSkip || !isFirstStep) && (
            <Button
              size="medium"
              disableDebounce
              color="quaternary"
              onClick={() => {
                if (isFirstStep) {
                  onClose();
                } else {
                  updateStep(step - 1);
                }
              }}
            >
              <FormattedMessage
                {...(isFirstStep ? glossary.skip : glossary.back)}
              />
            </Button>
          )}
          {isLastStep ? (
            <Button
              size="medium"
              disableDebounce
              color="primary"
              onClick={() => {
                onClose();
              }}
              {...confirmCtaProps}
            >
              {confirmCtaProps?.children || (
                <FormattedMessage {...glossary.letsGo} />
              )}
            </Button>
          ) : (
            <Button
              size="medium"
              disableDebounce
              color="secondary"
              onClick={() => {
                updateStep(step + 1);
              }}
            >
              <FormattedMessage {...glossary.next} />
            </Button>
          )}
        </FooterButtons>
      </Root>
    </Dialog>
  );
};
