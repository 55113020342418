import { SVGProps } from 'react';

export const Verified = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="var(--c-brand-600)"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.05797 9.27309L1.76912 9.98294C1.94421 10.1575 2.04245 10.3947 2.0419 10.642V11.4736C2.04245 11.9796 2.24371 12.4646 2.60142 12.8224C2.95911 13.1802 3.44405 13.3816 3.95004 13.3823H4.78168C5.02851 13.3831 5.26508 13.4811 5.44008 13.6551L6.15059 14.3656C6.50874 14.7231 6.99401 14.9238 7.5 14.9238C8.00598 14.9238 8.49126 14.7231 8.8494 14.3656L9.55927 13.6551C9.73458 13.4812 9.97128 13.3832 10.2183 13.3823H11.0506C11.5565 13.3816 12.0415 13.1802 12.3992 12.8224C12.7569 12.4646 12.9582 11.9796 12.9586 11.4736V10.642C12.9582 10.3947 13.0564 10.1575 13.2315 9.98294L13.942 9.27309C14.2993 8.91484 14.5 8.42957 14.5 7.92371C14.5 7.41785 14.2993 6.93258 13.942 6.57433L13.2309 5.86448C13.0558 5.68994 12.9575 5.4527 12.9581 5.20545V4.37382C12.9575 3.86785 12.7563 3.38281 12.3986 3.02501C12.0409 2.6672 11.5559 2.46583 11.05 2.46516H10.2183C9.97159 2.4644 9.73502 2.36661 9.55991 2.19294L8.8494 1.4818C8.49114 1.12453 8.00587 0.923828 7.49999 0.923828C6.99412 0.923828 6.50885 1.12454 6.15059 1.4818L5.44072 2.19294C5.26541 2.36683 5.02871 2.46484 4.78168 2.46571H3.94939C3.44352 2.46647 2.95847 2.66784 2.60077 3.02563C2.24307 3.38344 2.04181 3.86848 2.04137 4.37445V5.20541C2.04191 5.45256 1.94401 5.68978 1.76913 5.86444L1.05798 6.57429C0.700702 6.93254 0.5 7.41781 0.5 7.92367C0.5 8.42953 0.700713 8.9148 1.05798 9.27305L1.05797 9.27309ZM5.20124 7.57986C5.29271 7.48829 5.41689 7.43672 5.54641 7.43672C5.67592 7.43672 5.80011 7.48829 5.89157 7.57986L6.84892 8.53521L9.10867 6.27606C9.2334 6.15995 9.40957 6.11711 9.57366 6.16322C9.73763 6.20923 9.86585 6.33744 9.91196 6.50152C9.95797 6.6656 9.91512 6.84176 9.79901 6.96638L7.19409 9.57125V9.57136C7.10262 9.66304 6.97844 9.71472 6.84893 9.71472C6.7193 9.71472 6.59512 9.66304 6.50376 9.57136L5.20125 8.26887C5.10967 8.1774 5.0581 8.05322 5.0581 7.92371C5.0581 7.7942 5.10967 7.67002 5.20125 7.57855L5.20124 7.57986Z" />
  </svg>
);
