import { TypedDocumentNode, gql } from '@apollo/client';
import { faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowLeft,
  faArrowUpFromBracket,
  faDownload,
  faEllipsis,
  faLink,
} from '@fortawesome/pro-solid-svg-icons';
import { useRef, useState } from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import { IconButton } from 'atoms/buttons/IconButton';
import { RoundedButton } from 'atoms/buttons/RoundedButton';
import { Vertical } from 'atoms/layout/flex';
import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { Dots } from 'atoms/navigation/Dots';
import { Scrollable } from 'atoms/navigation/Scrollable';
import { Text14, Title3 } from 'atoms/typography';
import { ResponsiveImg } from 'atoms/ui/ResponsiveImg';
import { IS_TEST_RUNNER } from 'config';
import { useEventsContext } from 'contexts/events';
import { useIntlContext } from 'contexts/intl';
import useScreenSize from 'hooks/device/useScreenSize';
import useImagePrefetcher from 'hooks/useImagePrefetcher';
import { useSport } from 'hooks/useSport';
import {
  UTM_CAMPAIGNS,
  UTM_MEDIUMS,
  UTM_SOURCES,
  getUtmTermFromSport,
  useUtmParams,
} from 'hooks/useUtmParams';
import { sortByArrayIndex } from 'lib/arrays';
import {
  SocialShareEventContext,
  SocialShareEventName,
  shareByCopyLinkEvent,
  shareByImageEvent,
  shareOnFacebookEvent,
  shareOnTwitterEvent,
  shareWithShareSheetEvent,
} from 'lib/events';
import { glossary } from 'lib/glossary';
import { ImageVariations, derivatives } from 'lib/share';
import { absCenter } from 'lib/style';

import useShareSheet from '../useShareSheet';
import { SocialShare_Content_socialPictureDerivative } from './__generated__/index.graphql';

const messages = defineMessages({
  copyLink: {
    id: 'SocialShareDialog.clipboard',
    defaultMessage: 'Copy link',
  },
  copied: {
    id: 'SocialShareDialog.copied',
    defaultMessage: 'Copied!',
  },
});

const Aside = styled.aside`
  display: flex;
  justify-content: space-between;
  margin-bottom: auto;
`;
const Header = styled.header`
  text-align: center;
  margin-bottom: var(--quadruple-unit);
`;
const DialogContent = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--double-unit) var(--double-unit) var(--quadruple-unit);
  text-align: center;
  overflow: hidden;
  height: 100%;
  justify-content: center;
`;
const Buttons = styled.div`
  display: flex;
  gap: var(--double-unit);
  justify-content: center;
  margin-bottom: auto;
`;
const ScrollableWrapper = styled(Vertical).attrs({ center: true })`
  padding: var(--unit);
  margin-bottom: var(--quadruple-unit);
  justify-content: stretch;
`;
const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
  border: 1px solid var(--c-neutral-400);
  border-radius: var(--unit);
`;
const ImgWrapper = styled.figure`
  isolation: isolate;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  height: 300px;
  margin: auto;
`;
const Loader = styled.span`
  ${absCenter}
`;
const buildShareUrl = (
  type: 'x' | 'facebook',
  url?: string,
  message?: string | ((link: string | undefined) => string)
) => {
  const actualUrl = url || '';
  const encodedUrl = encodeURIComponent(
    type === 'x' && typeof message !== 'function' ? actualUrl : ''
  );
  const encodedMessage = message
    ? encodeURIComponent(typeof message === 'function' ? message(url) : message)
    : '';

  switch (type) {
    case 'x':
      return `https://x.com/intent/tweet?text=${encodedMessage}&url=${encodedUrl}`;
    case 'facebook':
      return `https://www.facebook.com/dialog/share?app_id=966242223397117&href=${encodeURIComponent(
        actualUrl
      )}`;
    default:
      return url;
  }
};

export type Props = {
  skip?: boolean;
  title?: string;
  description?: string;
  message?: string | ((link: string | undefined) => string);
  image?: Omit<
    SocialShare_Content_socialPictureDerivative,
    '__typename'
  > | null;
  shareProps: {
    url?: string;
    trackingEventName: SocialShareEventName;
    trackingEventContext: SocialShareEventContext;
    sharedItemId?: string;
    sharedItem?: UTM_CAMPAIGNS;
  };
};

const Preview = styled.div`
  width: 100%;
  aspect-ratio: ${derivatives.post.width} / ${derivatives.post.height};
`;

const StyledResponsiveImg = styled(ResponsiveImg).attrs({ xl: true })`
  width: 100%;
`;

export const SocialShareContent = ({
  skip,
  image,
  title,
  description,
  message,
  shareProps,
}: Props) => {
  const images = Object.entries(image || [])
    .sort((a, b) => sortByArrayIndex(['story', 'post', 'square'], a[0], b[0]))
    .filter(([variant, src]) => variant !== '__typename' && !!src) as [
    ImageVariations,
    string,
  ][];
  const availableSteps = [
    shareProps.url ? 'share_link' : undefined,
    images.length ? 'select_image_to_share' : undefined,
  ].filter(Boolean) as ('share_link' | 'select_image_to_share')[];
  const defaultStep = availableSteps[0];
  const sport = useSport();
  const { formatMessage } = useIntlContext();
  const [copied, setCopied] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const timeoutRef = useRef(setTimeout(() => {}, 0));
  const { track } = useEventsContext();
  const { setParams } = useUtmParams();
  const [step, setStep] = useState(defaultStep);
  const { up: isTabletOrAbove } = useScreenSize('tablet');
  const [prerenderFetched, setPreprendFetched] = useState(false);
  const selectedImage = images[selectedImageIndex]?.[1];
  const linkUsedInMessage = typeof message === 'function';
  const shareSheetProps = {
    image: selectedImage,
    title: linkUsedInMessage ? message(shareProps.url) : message,
    skip: !!skip,
  };
  const sharedParams = {
    url: shareProps.url,
    medium: UTM_MEDIUMS.SOCIAL,
    term: getUtmTermFromSport(sport),
    campaign: shareProps.sharedItem,
  };
  const {
    share: shareWithShareSheet,
    enabled: shareSheetEnabled,
    loading: shareSheetLoading,
  } = useShareSheet({
    ...shareSheetProps,
    url: setParams({
      ...sharedParams,
    }),
  });
  const trackingProps = [
    shareProps.trackingEventName,
    shareProps.trackingEventContext,
  ] as const;
  const copyToClipboard = () => {
    const clipboardUrl = setParams({ ...sharedParams });
    if (!clipboardUrl) return;
    const { name, properties } = shareByCopyLinkEvent(...trackingProps);
    track(name, properties);
    navigator.clipboard.writeText(clipboardUrl);
    clearTimeout(timeoutRef.current);
    setCopied(true);
    timeoutRef.current = setTimeout(() => setCopied(false), 1000);
  };
  const onShareImageClick = () => {
    const { name, properties } = shareByImageEvent(...trackingProps);
    track(name, properties);
  };
  const onTwitterClick = () => {
    const { name, properties } = shareOnTwitterEvent(...trackingProps);
    track(name, properties);
  };
  const onFacebookClick = () => {
    const { name, properties } = shareOnFacebookEvent(...trackingProps);
    track(name, properties);
  };
  const onShareSheetClick = () => {
    const { name, properties } = shareWithShareSheetEvent(...trackingProps);
    track(name, properties);
    shareWithShareSheet();
  };

  const imageSrcs = [image?.post, image?.square, image?.story].filter(Boolean);
  const firstImageLoaded = useImagePrefetcher(imageSrcs.slice(0, 1), 'auto');
  useImagePrefetcher(imageSrcs.slice(1));

  if (
    !prerenderFetched &&
    !IS_TEST_RUNNER &&
    process.env.NODE_ENV !== 'development'
  ) {
    /* load the URL as prerender.io would see */
    fetch(`${shareProps.url}?_escaped_fragment_=1`);
    setPreprendFetched(true);
  }

  return (
    <DialogContent>
      {isTabletOrAbove && (
        <Aside>
          {availableSteps.length > 1 && step !== 'share_link' && (
            <IconButton
              color="tertiary"
              icon={faArrowLeft}
              onClick={() => setStep('share_link')}
              aria-label={formatMessage(glossary.back)}
            />
          )}
        </Aside>
      )}
      {(title || description) && (
        <Header>
          {title && <Title3>{title}</Title3>}
          {description && <Text14>{description}</Text14>}
        </Header>
      )}
      {step === 'share_link' && imageSrcs.length > 0 && (
        <Preview>
          {firstImageLoaded ? (
            <StyledResponsiveImg
              src={imageSrcs[0]}
              alt={shareSheetProps.title!}
            />
          ) : (
            <LoadingIndicator />
          )}
        </Preview>
      )}
      {step === 'share_link' ? (
        <Buttons>
          <RoundedButton
            href={buildShareUrl(
              'x',
              setParams({
                ...sharedParams,
                source: UTM_SOURCES.TWITTER,
              }),
              message
            )}
            onClick={onTwitterClick}
            color="var(--c-social-x-twitter)"
            icon={faXTwitter}
            label="X"
          />
          <RoundedButton
            href={buildShareUrl(
              'facebook',
              setParams({
                ...sharedParams,
                source: UTM_SOURCES.FACEBOOK,
              })
            )}
            onClick={onFacebookClick}
            color="var(--c-social-facebook)"
            icon={faFacebook}
            label="Facebook"
          />
          <RoundedButton
            onClick={copyToClipboard}
            color="var(--c-static-neutral-700)"
            icon={faLink}
            label={copied ? messages.copied : glossary.copy}
          />
          {images.length > 0 && (
            <RoundedButton
              onClick={() => setStep('select_image_to_share')}
              color="var(--c-static-neutral-700)"
              label={glossary.more}
              icon={faEllipsis}
            />
          )}
          {images.length === 0 && shareSheetEnabled && (
            <RoundedButton
              onClick={onShareSheetClick}
              color="var(--c-static-neutral-700)"
              label={glossary.share}
              icon={faEllipsis}
            />
          )}
        </Buttons>
      ) : (
        <>
          <ScrollableWrapper>
            <Scrollable
              itemToDisplay={1}
              onVisibleItemsChanged={items => {
                setSelectedImageIndex(items[0]);
              }}
              indexToScroll={selectedImageIndex}
            >
              {images.map(([imageType, src]) => (
                <ImgWrapper key={src}>
                  <Img src={src} alt={imageType} />
                  <Loader>
                    <LoadingIndicator small />
                  </Loader>
                </ImgWrapper>
              ))}
            </Scrollable>
            <Dots
              selectedIndex={selectedImageIndex}
              setSelectedIndex={setSelectedImageIndex}
              titles={images.map(([type]) => type)}
            />
          </ScrollableWrapper>
          <Buttons>
            {!!selectedImage && (
              <RoundedButton
                href={selectedImage}
                download
                onClick={onShareImageClick}
                color="var(--c-static-neutral-700)"
                icon={faDownload}
                label={glossary.download}
              />
            )}
            {shareSheetEnabled && (
              <RoundedButton
                onClick={onShareSheetClick}
                color="var(--c-brand-600)"
                icon={faArrowUpFromBracket}
                label={glossary.share}
                disabled={shareSheetLoading}
              />
            )}
          </Buttons>
        </>
      )}
    </DialogContent>
  );
};

SocialShareContent.fragments = {
  socialPictures: gql`
    fragment SocialShare_Content_socialPictureDerivative on SocialPictureDerivative {
      post
      square
      story
    }
  ` as TypedDocumentNode<SocialShare_Content_socialPictureDerivative>,
};
