import { useEffect } from 'react';

import { SilencedError } from '@sorare/error-boundary';

import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { reloadPage } from '@sorare/core/src/routing/EnsureLatestAppVersion/reloadPage';

export const ViteErrorHandler = () => {
  const { isAndroidApp } = useIsMobileApp();

  useEffect(() => {
    if (isAndroidApp) return;

    window.addEventListener('vite:preloadError', event => {
      event.payload.cause = new SilencedError(
        'vite:preloadError',
        event.payload
      );
      reloadPage();
    });
  }, [isAndroidApp]);

  return null;
};
