import classNames from 'classnames';
import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isAfter,
} from 'date-fns';
import { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { IStyledComponent } from 'styled-components';

import { Horizontal, Vertical } from 'atoms/layout/flex';
import {
  HeadlineXL,
  LabelS,
  MandatoryAsProp,
  TypographyProps,
} from 'atoms/typography';
import { useTickerContext } from 'contexts/ticker';

const CountdownWrapper = styled(Horizontal)`
  align-self: center;

  & > * {
    width: 2ch;
  }
`;
const Wrapper = styled(Vertical)`
  &.disabled {
    opacity: 0.5;
  }
`;

const StyledHeadlineXL = styled(HeadlineXL)`
  font: var(--t-48);
  font-variant-numeric: tabular-nums;
`;
const StyledLabelS = styled(LabelS)`
  color: var(--c-neutral-600);
`;

interface Props {
  date: Date;
  Label?: IStyledComponent<'web', TypographyProps & MandatoryAsProp>;
  Caption?: IStyledComponent<'web', TypographyProps & MandatoryAsProp>;
  greyOutZeros?: boolean;
  gap?: ComponentProps<typeof Horizontal>['gap'];
}

export const Countdown = ({
  date,
  Label = StyledHeadlineXL,
  Caption = StyledLabelS,
  greyOutZeros,
  gap = 3,
}: Props) => {
  const { now } = useTickerContext();
  const daysLeft = differenceInDays(date, now);
  const addedDays = addDays(now, daysLeft);
  const hoursLeft = differenceInHours(date, addedDays);
  const addedHours = addHours(addedDays, hoursLeft);
  const minutesLeft = differenceInMinutes(date, addedHours);
  const addedMinutes = addMinutes(addedHours, minutesLeft);
  const secondsLeft = differenceInSeconds(date, addedMinutes);
  if (isAfter(now, date)) {
    return null;
  }
  return (
    <CountdownWrapper gap={gap}>
      <Label as="div">
        <Wrapper
          gap={0}
          center
          className={classNames({ disabled: greyOutZeros && daysLeft === 0 })}
        >
          <FormattedMessage
            id="Countdown.daysLeft"
            defaultMessage="{count, plural, one {# <l>day</l>} other {# <l>days</l>}}"
            values={{
              count: daysLeft,
              l: chunks => <Caption as="span">{chunks}</Caption>,
            }}
          />
        </Wrapper>
      </Label>
      <Label as="div">
        <Wrapper
          gap={0}
          center
          className={classNames({
            disabled: greyOutZeros && daysLeft === 0 && hoursLeft === 0,
          })}
        >
          <FormattedMessage
            id="Countdown.hoursLeft"
            defaultMessage="{count, plural, one {# <l>hour</l>} other {# <l>hours</l>}}"
            values={{
              count: hoursLeft,
              l: chunks => <Caption as="span">{chunks}</Caption>,
            }}
          />
        </Wrapper>
      </Label>
      <Label as="div">
        <Wrapper
          gap={0}
          center
          className={classNames({
            disabled:
              greyOutZeros &&
              daysLeft === 0 &&
              hoursLeft === 0 &&
              minutesLeft === 0,
          })}
        >
          <FormattedMessage
            id="Countdown.minutesLeft"
            defaultMessage="{count, plural, one {# <l>minutes</l>} other {# <l>minutes</l>}}"
            values={{
              count: minutesLeft,
              l: chunks => <Caption as="span">{chunks}</Caption>,
            }}
          />
        </Wrapper>
      </Label>
      <Label as="div">
        <Wrapper gap={0} center>
          <FormattedMessage
            id="Countdown.secondsLeft"
            defaultMessage="{count, plural, one {# <l>second</l>} other {# <l>seconds</l>}}"
            values={{
              count: secondsLeft,
              l: chunks => <Caption as="span">{chunks}</Caption>,
            }}
          />
        </Wrapper>
      </Label>
    </CountdownWrapper>
  );
};
