import { local as localStorage } from 'lib/storage';

export const QSUTMKeys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
] as const;

export const QSKeys = [
  'referrer',
  'irclickid',
  'gclid',
  'fbclid',
  'twclid',
  'ttclid',
  'invitation_token',
  'traffic_category',
  'partner',
  'user',
  ...QSUTMKeys,
] as const;

export type QSKey = (typeof QSKeys)[number];

export type LSKey =
  | 'REFERRER'
  | 'IMPACT_CLICKID'
  | 'GOOGLE_CLICKID'
  | 'FACEBOOK_CLICKID'
  | 'TWITTER_CLICKID'
  | 'TIKTOK_CLICKID'
  | 'INVITATION_TOKEN'
  | 'UTM_SOURCE'
  | 'UTM_MEDIUM'
  | 'UTM_CAMPAIGN'
  | 'UTM_TERM'
  | 'UTM_CONTENT'
  | 'CUSTOM_PROFILE_ID'
  | 'TRAFFIC_CATEGORY'
  | 'PARTNER'
  | 'USER';

export const keys: Record<QSKey, LSKey> = {
  referrer: 'REFERRER',
  irclickid: 'IMPACT_CLICKID',
  gclid: 'GOOGLE_CLICKID',
  fbclid: 'FACEBOOK_CLICKID',
  twclid: 'TWITTER_CLICKID',
  ttclid: 'TIKTOK_CLICKID',
  invitation_token: 'INVITATION_TOKEN',
  utm_source: 'UTM_SOURCE',
  utm_medium: 'UTM_MEDIUM',
  utm_campaign: 'UTM_CAMPAIGN',
  utm_term: 'UTM_TERM',
  utm_content: 'UTM_CONTENT',
  traffic_category: 'TRAFFIC_CATEGORY',
  partner: 'PARTNER',
  user: 'USER',
} as const;

interface Storage {
  get: (key: LSKey) => string | null;
  set: (key: LSKey, value?: string) => void;
  remove: (key: LSKey) => void;
}

const localStorageFactory: () => Storage = () => {
  return {
    get: localStorage.getItem,
    set: (key, value) => {
      if (value) {
        localStorage.setItem(key, value);
      }
    },
    remove: key => localStorage.removeItem(key),
  };
};

const data: Partial<Record<LSKey, string>> = {};
const objectStorageFactory = (): Storage => {
  return {
    get: key => data[key] || null,
    set: (key, value) => {
      if (value) {
        data[key] = value;
      }
    },
    remove: key => delete data[key],
  };
};

export const storageFactory: () => Storage = () => {
  if (localStorage) {
    return localStorageFactory();
  }
  return objectStorageFactory();
};

export const getValue = (key: QSKey) => {
  return storageFactory().get(keys[key]);
};
