import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { TeamAvatar } from '@sorare/core/src/components/club/TeamAvatar';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { fantasy } from '@sorare/core/src/lib/glossary';

import { UpcomingGames_anyGame } from './__generated__/index.graphql';

const GameWeek = styled.article<{ scrollSnap: boolean }>`
  border-radius: var(--double-unit);
  background-color: var(--c-neutral-300);
  padding: var(--double-unit);
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--unit);
  max-width: 400px;

  ${({ scrollSnap }) =>
    scrollSnap
      ? css`
          scroll-snap-align: start;
          min-width: 100%;
        `
      : null}
`;

const Games = styled.div<{ moreThanThreeGames: boolean }>`
  display: inherit;
  flex-direction: inherit;
  gap: var(--half-unit);
  ${({ moreThanThreeGames }) =>
    !moreThanThreeGames
      ? css`
          height: 100%;
        `
      : null}
`;

const Row = styled(Horizontal).attrs({ gap: 0.5 })`
  flex: 1;
  white-space: nowrap;
`;

const LogoWrapper = styled.div`
  flex-grow: 1;
`;

const SizedTeamAvatar = styled(TeamAvatar)`
  --size: var(--quadruple-unit);
`;

type Props = {
  playerTeamSlug: string | undefined;
  games: UpcomingGames_anyGame[];
  scrollSnap?: boolean;
};

export const UpcomingGames = ({ playerTeamSlug, games, scrollSnap }: Props) => {
  const { formatDate } = useIntlContext();

  const grouped = Object.entries(
    games.reduce<Record<number, UpcomingGames_anyGame[]>>((acc, game) => {
      // backend sends 0 for fixtures too far away in the future
      if (game.so5Fixture?.gameWeek === 0 || !game.so5Fixture) {
        return acc;
      }

      if (acc[game.so5Fixture?.gameWeek]) {
        acc[game.so5Fixture?.gameWeek].push(game);
      } else {
        acc[game.so5Fixture?.gameWeek] = [game];
      }
      return acc;
    }, {})
  ).slice(0, 3);

  const moreThanThreeGames = grouped.some(([, ugs]) => ugs.length > 3);

  return (
    <Vertical>
      {!grouped.length ? (
        <div>
          <FormattedMessage
            id="UpcomingGames.Baseball.NoGame"
            defaultMessage="No games"
          />
        </div>
      ) : (
        grouped.map(([gameWeek, upcomingGames]) => {
          return (
            <GameWeek key={gameWeek} scrollSnap={!!scrollSnap}>
              <Text16 bold as="header">
                <FormattedMessage
                  id="UpcomingGames.Gameweek"
                  defaultMessage="Game Week"
                />
                {` ${gameWeek}`}
              </Text16>
              <Games moreThanThreeGames={moreThanThreeGames}>
                {upcomingGames.map(game => {
                  const playingAtHome = game.homeTeam?.slug === playerTeamSlug;
                  return (
                    <Row key={game.id}>
                      <Text16 bold>
                        {playingAtHome ? (
                          <FormattedMessage {...fantasy.vs} />
                        ) : (
                          <FormattedMessage
                            id="UpcomingGames.game.at"
                            defaultMessage="@"
                          />
                        )}
                      </Text16>
                      <LogoWrapper>
                        <SizedTeamAvatar
                          team={playingAtHome ? game.awayTeam : game.homeTeam}
                        />
                      </LogoWrapper>
                      <Text16 color="var(--c-neutral-600)">
                        {formatDate(game.date, {
                          month: 'short',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </Text16>
                    </Row>
                  );
                })}
                {
                  /* for gameweek only having one upcoming game, we are positioning the game at the top as if we are flexing 2 games */
                  upcomingGames.length === 1 && !moreThanThreeGames && <Row />
                }
              </Games>
            </GameWeek>
          );
        })
      )}
    </Vertical>
  );
};

UpcomingGames.fragments = {
  anyGame: gql`
    fragment UpcomingGames_anyGame on AnyGameInterface {
      id
      date
      homeTeam {
        slug
        ...TeamAvatar_team
      }
      awayTeam {
        slug
        ...TeamAvatar_team
      }
      so5Fixture {
        slug
        gameWeek
      }
    }
    ${TeamAvatar.fragments.team}
  ` as TypedDocumentNode<UpcomingGames_anyGame>,
};
