import { ReactNode, useCallback, useState } from 'react';

import HighlightContext, { Highlight, OnboardingHighlightName } from '.';

type Props = {
  children: ReactNode;
};

const OnboardingHighlightProvider = ({ children }: Props) => {
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [activeHighlights, setActiveHighlights] = useState<
    OnboardingHighlightName[]
  >([]);

  const registerHighlight = useCallback((highlight: Highlight) => {
    setHighlights(old => {
      const oldWithoutHighlight = old.filter(h => h.name !== highlight.name);

      return [...oldWithoutHighlight, highlight];
    });
  }, []);

  return (
    <HighlightContext.Provider
      value={{
        registerHighlight,
        highlights,
        activeHighlights,
        setActiveHighlights,
      }}
    >
      {children}
    </HighlightContext.Provider>
  );
};

export default OnboardingHighlightProvider;
