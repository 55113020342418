import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import { GameStatus } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Caption } from '@sorare/core/src/atoms/typography';
import { LiveDot } from '@sorare/core/src/components/live/LiveDot';
import { gameStatusMessages } from '@sorare/core/src/lib/glossary';

import { GameTime_AnyGameInterface } from './__generated__/index.graphql';

type Props = {
  game: GameTime_AnyGameInterface;
  secondLine?: ReactNode;
};
export const GameTime = ({ game, secondLine = null }: Props) => {
  const { statusTyped, date } = game;

  if (statusTyped === GameStatus.scheduled) {
    return (
      <Vertical gap={0.5} center>
        <Caption color="var(--c-neutral-600)">
          <FormattedTime value={date} hour="numeric" minute="numeric" />
        </Caption>
        <Caption color="var(--c-neutral-600)">
          <FormattedDate value={date} month="short" day="numeric" />
        </Caption>
      </Vertical>
    );
  }

  if (statusTyped === GameStatus.canceled) {
    <FormattedMessage {...gameStatusMessages[statusTyped]} />;
  }

  if (statusTyped === GameStatus.playing) {
    return (
      <Vertical gap={0.5} center>
        <Horizontal gap={0.5}>
          <LiveDot animate />
          <Caption color="var(--c-neutral-600)">
            <FormattedMessage {...gameStatusMessages.live} />
          </Caption>
        </Horizontal>
        {secondLine}
      </Vertical>
    );
  }

  if (statusTyped === GameStatus.played) {
    return (
      <Vertical gap={0.5} center>
        <Caption color="var(--c-neutral-600)">
          <FormattedDate value={date} month="short" day="numeric" />
        </Caption>
        <Caption color="var(--c-neutral-600)">
          <FormattedMessage id="Game.status.final" defaultMessage="Final" />
        </Caption>
      </Vertical>
    );
  }

  return null;
};

GameTime.fragments = {
  AnyGameInterface: gql`
    fragment GameTime_AnyGameInterface on AnyGameInterface {
      id
      date
      statusTyped
    }
  ` as TypedDocumentNode<GameTime_AnyGameInterface>,
};
