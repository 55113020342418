import { TypedDocumentNode, gql, useApolloClient } from '@apollo/client';

import { Blockchain } from '@sorare/core/src/__generated__/globalTypes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useWalletContext } from '@sorare/core/src/contexts/wallet';

import {
  currentBlockHeightQuery,
  currentBlockHeightQueryVariables,
  useMigrateCards_anyCard,
} from './__generated__/useMigrateCards.graphql';

const CURRENT_BLOCK_HEIGHT_QUERY = gql`
  query currentBlockHeightQuery {
    currentBlockHeight
  }
` as TypedDocumentNode<
  currentBlockHeightQuery,
  currentBlockHeightQueryVariables
>;

interface MigrationInput {
  migrateInternalCardsSignature?: string;
  migrateMappedCardsSignature?: string;
  expirationBlock: number;
}

const useMigrateCards = () => {
  const { currentUser } = useCurrentUserContext();
  const { signMigration } = useWalletContext();
  const client = useApolloClient();

  const getCurrentBlockNumber = async () => {
    const { data } = await client.query<currentBlockHeightQuery>({
      query: CURRENT_BLOCK_HEIGHT_QUERY,
    });

    return data.currentBlockHeight;
  };

  return async (cards: useMigrateCards_anyCard[]) => {
    if (!currentUser) return null;

    const requiredSignatures = cards
      .filter(
        ({ tokenOwner }) =>
          tokenOwner &&
          // TODO(jerome+baptiste): understand why it's lowercase
          tokenOwner.blockchain.toUpperCase() === Blockchain.ETHEREUM
      )
      .map(({ ethereumId }) => {
        if (!ethereumId) throw new Error('card should have a blockchainId');
        return ethereumId;
      });

    if (requiredSignatures.length === 0) return null;

    const currentBlock = await getCurrentBlockNumber();
    const expirationBlock = currentBlock + 200000;

    const result: MigrationInput = {
      expirationBlock,
    };

    const signature = await signMigration(requiredSignatures, expirationBlock);
    result.migrateInternalCardsSignature = signature;

    return result;
  };
};

useMigrateCards.fragments = {
  anyCard: gql`
    fragment useMigrateCards_anyCard on AnyCardInterface {
      slug
      walletStatus
      ethereumId
      tokenOwner {
        id
        blockchain
      }
    }
  ` as TypedDocumentNode<useMigrateCards_anyCard>,
};

export default useMigrateCards;
