import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Injured } from '@sorare/core/src/atoms/icons/Injured';
import { Suspended } from '@sorare/core/src/atoms/icons/Suspended';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelL, LabelM } from '@sorare/core/src/atoms/typography';
import { isType } from '@sorare/core/src/gql';
import { playerUnavailability } from '@sorare/core/src/lib/glossary';

import {
  PlayerUnavailabilityPanel_anyPlayer,
  PlayerUnavailabilityPanel_anyPlayer_Player_,
} from './__generated__/index.graphql';

type PlayerUnavailabilityPanel_player_activeInjuries =
  PlayerUnavailabilityPanel_anyPlayer_Player_['activeInjuries'][number];

type PlayerUnavailabilityPanel_player_activeSuspensions =
  PlayerUnavailabilityPanel_anyPlayer_Player_['activeSuspensions'][number];

const FlexWrapper = styled.div`
  display: flex;
  gap: var(--unit);
  overflow-x: auto;
`;

const InjuredIcon = styled(Injured)`
  color: var(--c-red-600);
`;
const SuspendedIcon = styled(Suspended)`
  color: var(--c-red-600);
`;

const messages = defineMessages({
  panelTitle: {
    id: 'ComposeTeam.PlayerDetailsDialog.PlayerUnavailability.Title',
    defaultMessage: 'Injuries and suspensions',
  },
});

const Injury = ({
  kind,
  startDate,
  expectedEndDate,
}: PlayerUnavailabilityPanel_player_activeInjuries) => {
  return (
    <Vertical gap={0.5}>
      <Horizontal>
        <InjuredIcon />
        <LabelL bold>
          <FormattedMessage {...playerUnavailability.injuryTitle} />
        </LabelL>
      </Horizontal>
      <Vertical gap={0}>
        <LabelL color="var(--c-nd-600)">
          {kind}
          {startDate && (
            <>
              {' '}
              <FormattedMessage
                {...playerUnavailability.unavailableSince}
                values={{
                  unavailableSince: startDate,
                }}
              />
            </>
          )}
        </LabelL>
        <LabelL color="var(--c-nd-600)">
          {expectedEndDate ? (
            <FormattedMessage
              {...playerUnavailability.expectedReturnDate}
              values={{
                date: expectedEndDate,
              }}
            />
          ) : (
            <FormattedMessage {...playerUnavailability.unknownReturnDate} />
          )}
        </LabelL>
      </Vertical>
    </Vertical>
  );
};

const Suspension = ({
  competition,
  reason,
  matches,
  endDate,
}: PlayerUnavailabilityPanel_player_activeSuspensions) => {
  return (
    <Vertical gap={0.5}>
      <Horizontal>
        <SuspendedIcon />
        <LabelL bold>
          <FormattedMessage {...playerUnavailability.suspendedTitle} />
        </LabelL>
      </Horizontal>
      <Vertical gap={0}>
        <LabelL color="var(--c-nd-600)">
          {reason}
          {matches && (
            <>
              {' '}
              <FormattedMessage
                {...playerUnavailability.gamesUnavailableCount}
                values={{
                  gamesCount: matches,
                  competition: competition.displayName,
                }}
              />
            </>
          )}
        </LabelL>
        <LabelL color="var(--c-nd-600)">
          {endDate ? (
            <FormattedMessage
              {...playerUnavailability.expectedReturnDate}
              values={{
                date: endDate,
              }}
            />
          ) : (
            <FormattedMessage {...playerUnavailability.unknownReturnDate} />
          )}
        </LabelL>
      </Vertical>
    </Vertical>
  );
};

type Props = {
  player: PlayerUnavailabilityPanel_anyPlayer;
};
const PlayerUnavailabilityPanel = ({ player }: Props) => {
  if (!isType(player, 'Player')) return null;
  const { activeInjuries, activeSuspensions } = player;
  if (!activeInjuries.length && !activeSuspensions.length) return null;

  return (
    <Vertical gap={1.5}>
      <LabelM bold color="var(--c-nd-600)">
        <FormattedMessage {...messages.panelTitle} />
      </LabelM>
      <FlexWrapper>
        {activeInjuries.map(injury => (
          <Injury key={injury.id} {...injury} />
        ))}
        {activeSuspensions.map(suspension => (
          <Suspension key={suspension.id} {...suspension} />
        ))}
      </FlexWrapper>
    </Vertical>
  );
};

PlayerUnavailabilityPanel.fragments = {
  anyPlayer: gql`
    fragment PlayerUnavailabilityPanel_anyPlayer on AnyPlayerInterface {
      slug
      activeInjuries {
        id
        kind
        startDate
        expectedEndDate
      }
      activeSuspensions {
        id
        competition {
          slug
          id
          displayName
        }
        matches
        reason
        startDate
        endDate
      }
    }
  ` as TypedDocumentNode<PlayerUnavailabilityPanel_anyPlayer>,
};

export default PlayerUnavailabilityPanel;
