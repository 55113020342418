import fingerprintJS from '@fingerprintjs/fingerprintjs';
import { ReactNode, useEffect } from 'react';

import { Deferred } from '@sorare/wallet-shared';
import { DEVICE_FINGERPRINT_KEY } from 'constants/intl';
import { local as localStorage } from 'lib/storage';

import Provider from '.';

interface Props {
  children: ReactNode;
}

const computeDeviceFingerprint = async () => {
  const fp = await fingerprintJS.load();
  const fpResult = await fp.get();

  // https://github.com/fingerprintjs/fingerprintjs/blob/master/docs/extending.md#extending-fingerprintjs
  /* eslint-disable @typescript-eslint/no-unused-vars */
  // as of March 2, remove the `audio`, `screenResolution` & `screenFrame` sources
  // to avoid depending on external monitors or headphones
  const { audio, screenResolution, screenFrame, ...componentsMarch2nd } =
    fpResult.components;

  // as of March 4, remove the `deviceMemory`, `hardwareConcurrency`, `domBlockers`, `canvas` and `plugins`
  // sources to be compliant with Privacy-first browsers like Brave randomizing those
  // as well as `colorDepth`, `forcedColors`, `contrast`, `reducedMotion` and `hdr`
  // which could end up being different depending on screens
  const {
    canvas,
    plugins,
    domBlockers,
    colorDepth,
    forcedColors,
    contrast,
    reducedMotion,
    hdr,
    hardwareConcurrency,
    deviceMemory,
    ...componentsMarch4th
  } = componentsMarch2nd;
  /* eslint-enable @typescript-eslint/no-unused-vars */

  return [
    fingerprintJS.hashComponents({ ...componentsMarch2nd }),
    fingerprintJS.hashComponents({ ...componentsMarch4th }),
  ].join(',');
};

const deferred = new Deferred<string>();
const value = { deviceFingerprint: async () => deferred.promise };

const DeviceFingerPrintProvider = ({ children }: Props) => {
  useEffect(() => {
    if (!deferred.pending) {
      return () => {};
    }
    const localStorageDeviceFingerprint = localStorage.getItem(
      DEVICE_FINGERPRINT_KEY
    );
    if (localStorageDeviceFingerprint) {
      deferred.resolve(JSON.parse(localStorageDeviceFingerprint));
      return () => {};
    }
    let cancelled = false;
    computeDeviceFingerprint()
      .then(deviceFingerPrint => {
        if (!cancelled) {
          // we JSON.stringify the value to stay backward compatible with the previous
          // implementation relying on `useLocalStorage`
          localStorage.setItem(
            DEVICE_FINGERPRINT_KEY,
            JSON.stringify(deviceFingerPrint)
          );
        }
        return deviceFingerPrint;
      })
      .then(
        deviceFingerPrint => {
          if (deferred.pending) {
            deferred.resolve(deviceFingerPrint);
          }
        },
        error => deferred.reject(error)
      );
    return () => {
      cancelled = true;
    };
  }, []);

  return <Provider value={value}>{children}</Provider>;
};

export default DeviceFingerPrintProvider;
