import { Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { AppLayout } from '@sorare/core/src/components/navigation/AppLayout';
import { useDefaultSportPages } from '@sorare/core/src/constants/routes';
import { useConfigContext } from '@sorare/core/src/contexts/config';
import { useIsLoggedIn } from '@sorare/core/src/hooks/auth/useIsLoggedIn';
import useLifecycle from '@sorare/core/src/hooks/useLifecycle';
import { lazy } from '@sorare/core/src/lib/retry';

const NBALanding = lazy(async () => import('@sorare/nba/src/pages/page'));
const FootballLanding = lazy(
  async () => import('@sorare/football/src/pages/page')
);

const landings = {
  [Sport.NBA]: NBALanding,
  [Sport.FOOTBALL]: FootballLanding,
};

const Landing = () => {
  const { mainSport } = useConfigContext();
  const { lifecycle } = useLifecycle();
  const location = useLocation();
  const SportLanding = landings[mainSport];
  const loggedIn = useIsLoggedIn();
  const defaultSportPages = useDefaultSportPages();

  if (loggedIn) {
    return (
      <Navigate
        to={`${defaultSportPages[lifecycle?.lastVisitedSport || mainSport]}${location.search}`}
        replace
        state={location.state}
      />
    );
  }

  return (
    <AppLayout>
      <Suspense>
        <SportLanding isRoot />
      </Suspense>
    </AppLayout>
  );
};

export default Landing;
