import { TypedDocumentNode, gql } from '@apollo/client';
import { Dispatch, useCallback } from 'react';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { errorCodes } from '@sorare/core/src/hooks/graphql/useMutation';
import { useFiatBalance } from '@sorare/core/src/hooks/wallets/useFiatBalance';

import useCreateDirectOffer from 'hooks/offers/useCreateDirectOffer';

import { useOnSubmit_anyCard } from './__generated__/useOnSubmit.graphql';
import { switchToBuilding, switchToSubmitting } from './actions';
import { Actions, State } from './types';

const useOnSubmit = <D extends useOnSubmit_anyCard>(
  to: { slug: string },
  onDone: () => void,
  counteredOfferId?: string
) => {
  const createOffer = useCreateDirectOffer();
  const { canListAndTrade } = useFiatBalance();
  const { showNotification } = useSnackNotificationContext();

  return useCallback(
    async (dispatch: Dispatch<Actions<D>>, state: State<D>) => {
      dispatch(switchToSubmitting);
      try {
        const {
          sendCards,
          receiveCards,
          cardsData,
          sendAmount: sendMonetaryAmount,
          receiveAmount: receiveMonetaryAmount,
          sendAmountCurrency: stateSendAmountCurrency,
          receiveAmountCurrency: stateReceiveAmountCurrency,
        } = state;

        const sendAmountCurrency = canListAndTrade
          ? stateSendAmountCurrency
          : SupportedCurrency.WEI;
        const receiveAmountCurrency = canListAndTrade
          ? stateReceiveAmountCurrency
          : SupportedCurrency.WEI;

        if (![...sendCards, ...receiveCards].every(c => cardsData[c.objectID]))
          throw new Error('Please retry later [Missing Card data]');

        const errors = await createOffer({
          receiverSlug: to.slug,
          sendMonetaryAmount,
          receiveMonetaryAmount,
          sendTokens: sendCards.map(c => cardsData[c.objectID]),
          receiveTokens: receiveCards.map(c => cardsData[c.objectID]),
          duration: state.duration * 24 * 60 * 60,
          counteredOfferId,
          sendAmountCurrency,
          receiveAmountCurrency,
        });
        if (errors) {
          if (
            errors.some(
              error =>
                typeof error === 'object' &&
                'code' in error &&
                error?.code === errorCodes.unverifiedEmail
            )
          ) {
            onDone();
          }
          dispatch(switchToBuilding);
        } else {
          onDone();
          showNotification('offerSent');
        }
      } catch (e) {
        if (e && typeof e === 'object' && 'message' in e) {
          showNotification('errors', { errors: e.message });
        }
        dispatch(switchToBuilding);
      }
    },
    [
      canListAndTrade,

      createOffer,
      to.slug,
      counteredOfferId,
      onDone,
      showNotification,
    ]
  );
};

useOnSubmit.fragments = {
  anyCard: gql`
    fragment useOnSubmit_anyCard on AnyCardInterface {
      slug
      ...useCreateDirectOffer_anyCard
    }
    ${useCreateDirectOffer.fragments.anyCard}
  ` as TypedDocumentNode<useOnSubmit_anyCard>,
};

export default useOnSubmit;
