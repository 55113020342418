import { ShopItemType } from '@sorare/core/src/__generated__/globalTypes';

const isCosmetic = (item: { position: ShopItemType }) =>
  [
    ShopItemType.BACKGROUND,
    ShopItemType.CLUB_BADGE,
    ShopItemType.ACHIEVEMENT_BADGE,
  ].includes(item.position);

export const canEquip = (item: {
  position: ShopItemType;
  myPurchasesCount: number;
}) => item.myPurchasesCount > 0 && isCosmetic(item);

export const limitReached = (item: {
  myPurchasesCount: number;
  limitPerUser: number;
}) => item.myPurchasesCount === item.limitPerUser;
