import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';

const Wrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  width: var(--triple-unit);
  height: var(--triple-unit);
  background-color: var(--c-yellow-600);
  border-radius: 100%;
  border: 3px solid var(--c-neutral-200);
`;

const Icon = styled(FontAwesomeIcon).attrs({ size: 'sm' })`
  color: var(--c-static-neutral-1000);
`;

export const ExclamationIcon = ({ className }: { className?: string }) => {
  return (
    <Wrapper className={className}>
      <Icon icon={faExclamation} />
    </Wrapper>
  );
};
