import { TypedDocumentNode, gql, useMutation } from '@apollo/client';

import {
  Disable2FAMutation,
  Disable2FAMutationVariables,
  Enable2FAMutation,
  Enable2FAMutationVariables,
  GenerateOtpBackupCodesMutation,
  GenerateOtpBackupCodesMutationVariables,
  GenerateOtpProvisioningUriMutation,
  GenerateOtpProvisioningUriMutationVariables,
} from './__generated__/useUpdate2FA.graphql';

const ENABLE_2FA_MUTATION = gql`
  mutation Enable2FAMutation($input: enable2faInput!) {
    enable2Fa(input: $input) {
      currentUser {
        slug
        otpRequiredForLogin
      }
      otpBackupCodes
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<Enable2FAMutation, Enable2FAMutationVariables>;

const DISABLE_2FA_MUTATION = gql`
  mutation Disable2FAMutation($input: disable2faInput!) {
    disable2Fa(input: $input) {
      currentUser {
        slug
        otpRequiredForLogin
      }
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<Disable2FAMutation, Disable2FAMutationVariables>;

export const GENERATE_OTP_PROVISIONING_URI_MUTATION = gql`
  mutation GenerateOtpProvisioningUriMutation(
    $input: generateOtpProvisioningUriInput!
  ) {
    generateOtpProvisioningUri(input: $input) {
      currentUser {
        slug
      }
      otpProvisioningUri
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  GenerateOtpProvisioningUriMutation,
  GenerateOtpProvisioningUriMutationVariables
>;

const GENERATE_OTP_BACKUP_CODES_MUTATION = gql`
  mutation GenerateOtpBackupCodesMutation(
    $input: generateOtpBackupCodesInput!
  ) {
    generateOtpBackupCodes(input: $input) {
      currentUser {
        slug
        otpRequiredForLogin
      }
      otpBackupCodes
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  GenerateOtpBackupCodesMutation,
  GenerateOtpBackupCodesMutationVariables
>;

export const useUpdate2FA = () => {
  const [enable2FAMutation] = useMutation(ENABLE_2FA_MUTATION);
  const [disable2FAMutation] = useMutation(DISABLE_2FA_MUTATION);
  const [generateRecoveryCodesMutation] = useMutation(
    GENERATE_OTP_BACKUP_CODES_MUTATION
  );

  const [generateOtpProvisioningUriMutation] = useMutation(
    GENERATE_OTP_PROVISIONING_URI_MUTATION
  );

  return {
    enable2FAMutation,
    disable2FAMutation,
    generateRecoveryCodesMutation,
    generateOtpProvisioningUriMutation,
  };
};
