import { useId } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { unitMapping } from 'lib/style';

const titles = defineMessages({
  yellow: {
    id: 'SubIcon.titles.yellowCard',
    defaultMessage: 'yellow card',
  },
  red: {
    id: 'SubIcon.titles.redCard',
    defaultMessage: 'red card',
  },
});

type Props = {
  color: keyof typeof titles;
  className?: string;
  size?: keyof typeof unitMapping;
};

export const CardIcon = ({ color, className, size = 2 }: Props) => {
  const { formatMessage } = useIntl();
  const clipPathId = useId();

  const cardColor = color === 'yellow' ? '#F1FF00' : '#FF4E22';

  return (
    <svg
      className={className}
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 16 16"
      fill="none"
    >
      <title>{formatMessage(titles[color])}</title>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8Z"
          fill={cardColor}
        />
        <path
          d="M3.7168 3.24672L8.5768 1.16339L12.5935 8.84006L7.77346 10.7367L3.7168 3.24672Z"
          fill="black"
        />
        <path
          d="M5.79658 14.5799V12.8833C5.55991 12.3899 4.51325 10.2166 4.39991 9.92661C4.25991 9.56995 4.32325 9.30328 4.36325 9.12995C4.36991 9.10328 4.37658 9.07661 4.37991 9.05328C4.41325 8.86995 4.74658 8.04328 4.94325 7.56995L5.23325 6.86328L6.81991 9.85661H7.13658L7.19991 7.52328L8.65325 7.17995L9.62325 11.4966L8.91991 13.0933L8.97325 14.5866H5.79325L5.79658 14.5799Z"
          fill="black"
        />
        <path
          d="M8.38683 7.60664L9.25016 11.4533L8.56016 13.0166L8.6035 14.22H6.15683V12.8C6.15683 12.8 4.85683 10.1 4.73683 9.79331C4.61683 9.48664 4.7035 9.28997 4.73683 9.11664C4.77016 8.94331 5.28016 7.70331 5.28016 7.70331L6.60683 10.21L7.4935 10.22L7.56016 7.80331L8.39016 7.60664M5.20016 6.01331L4.61683 7.42997C4.08016 8.73664 4.04683 8.91664 4.0335 8.98331C4.03016 9.00331 4.0235 9.02331 4.02016 9.04331C3.9735 9.23664 3.89016 9.59331 4.07016 10.0566C4.1835 10.3466 5.11683 12.2933 5.44016 12.9633V14.94H8.60683H9.3535L9.32683 14.1966L9.29016 13.16L9.9135 11.7466L10.0102 11.53L9.95683 11.3L9.0935 7.45331L8.9335 6.74331L8.22683 6.90997L7.39683 7.10664L6.86016 7.23331L6.8435 7.78664L6.81016 9.04997L5.92016 7.36997L5.2035 6.01664L5.20016 6.01331Z"
          fill={cardColor}
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
