import { TypedDocumentNode, useMutation } from '@apollo/client';
import gqlMacro from 'graphql-tag.macro';
import { ReactNode, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useInterval, useTimeout } from 'react-use';

import { Sport } from '__generated__/globalTypes';
import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { useGraphqlContext } from 'contexts/graphql';
import { useSportContext } from 'contexts/sport';
import { useTMContext } from 'contexts/tm';
import { useQuery } from 'hooks/graphql/useQuery';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { CurrencyCode, currency } from 'lib/fiat';
import { asObject } from 'lib/json';

import ConfigContextProvider, { AlgoliaCardIndexes, AlgoliaIndexes } from '.';
import { currentUser } from '../currentUser/queries';
import {
  ConfigProvider_TeamsPlayingNextGameWeek,
  ConfigQuery,
  ConfigQueryVariables,
  PingConfigQuery,
  PingConfigQueryVariables,
  ReportTelemetry,
  ReportTelemetryVariables,
} from './__generated__/Provider.graphql';
import { ConfigQuery_currentUser } from './types';

const teamPlayingNextGameWeekFragment = gqlMacro`
  fragment ConfigProvider_TeamsPlayingNextGameWeek on TeamsPlayingNextGameWeek {
    slug
    gameWeek
    shortDisplayName
    endDate
    startDate
    teamSlugs
  }
` as TypedDocumentNode<ConfigProvider_TeamsPlayingNextGameWeek>;

const CONFIG_QUERY = gqlMacro`
  query ConfigQuery($sport: Sport!) {
    config {
      id
      algoliaIndexSuffix
      algoliaApplicationId
      algoliaSearchApiKey
      giphyApiKey
      bankAddress
      relayAddress
      starkExchangeAddress
      ethereumNetworkId
      ethereumEndpoint
      sorareTokensAddress
      baseballTokensAddress
      nbaTokensAddress
      sorareCardsAddress
      footballNationalSeriesTokensAddress
      sorareEncryptionKey
      sponsorAccountAddress
      migratorAddress
      minimumReceiveWeiAmount
      marketFeeRateBasisPoints
      marketplacePromotionalEvents {
        id
        sport
        events {
          id
          name
          objectIds
          slugs
          rewardDetailsHref
        }
      }
      idealDepositFeesAmountMinor
      mangopayAmexFeesBasisPoints: creditCardFeesBasisPoints(
        creditCardType: AMEX
        provider: MANGOPAY
      )
      mangopayVisaFeesBasisPoints: creditCardFeesBasisPoints(
        creditCardType: CB_VISA_MASTERCARD
        provider: MANGOPAY
      )
      checkoutFeesBasisPoints: creditCardFeesBasisPoints(
        creditCardType: CB_VISA_MASTERCARD
        provider: CHECKOUT
      )
      paypalDepositFeesBasisPoints
      mangopayApplePayDepositFeesBasisPoints
      stripeCreditCardFeesBasisPoints: creditCardFeesBasisPoints(
        provider: STRIPE
      )
      exchangeRate {
        id
        ethRates {
          eurMinor
          usdMinor
          gbpMinor
        }
        time
      }
      defaultFiatCurrency
      ethAssetType
      ethQuantum
      so5 {
        id
        competitionEligibilityAlgoliaFilters: leaguesAlgoliaFilters(
          sport: $sport
        )
        teamsPlayingNextDailyGameWeeks: teamsPlayingNextGameWeeks(
          eventType: DAILY
          sport: $sport
        ) {
          slug
          ...ConfigProvider_TeamsPlayingNextGameWeek
        }
        teamsPlayingNextClassicGameWeeks: teamsPlayingNextGameWeeks(
          eventType: CLASSIC
          sport: $sport
          first: 3
        ) {
          slug
          ...ConfigProvider_TeamsPlayingNextGameWeek
        }
      }
      currentLocation {
        countryCode
        regionCode
      }
      counts {
        usersCount
      }
    }
    currentUser {
      slug
      ...CurrentUserProvider_currentUser
    }
    tokens {
      promotionalEvents: promotionalEvents(sport: $sport) {
        slug
        name
        algoliaFilters
      }
    }
  }
  ${currentUser}
  ${teamPlayingNextGameWeekFragment}
` as TypedDocumentNode<ConfigQuery, ConfigQueryVariables>;

const PING_QUERY = gqlMacro`
  query PingConfigQuery {
    config {
      id
      exchangeRate {
        id
        ethRates { eurMinor usdMinor gbpMinor }
        time
      }
      minimumReceiveWeiAmount
    }
    currentUser {
      slug
    }
  }
` as TypedDocumentNode<PingConfigQuery, PingConfigQueryVariables>;

const TM_MUTATION = gqlMacro`
  mutation ReportTelemetry($input: reportTelemetryInput!) {
    reportTelemetry(input: $input) {
      errors {
        message
      }
    }
  }
` as TypedDocumentNode<ReportTelemetry, ReportTelemetryVariables>;

interface Props {
  children: ReactNode;
}

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

const pingInterval = 115_000 + getRandomInt(10_000);
const tmInterval = 30_000 + getRandomInt(5_000);

/**
 * Provides environment dependent configuration keys.
 * Aggregates all startup loading states so that initialization can be done in parallel
 */
export const ConfigProvider = ({ children }: Props) => {
  const { sport, setSport } = useSportContext();
  const { pathname } = useLocation();
  const { data, loading, refetch, updateQuery } = useQuery(CONFIG_QUERY, {
    variables: { sport },
  });
  const [isPingQueryReady] = useTimeout(pingInterval);
  useQuery(PING_QUERY, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    pollInterval: pingInterval,
    skip: !isPingQueryReady(),
  });
  const {
    flags: { useReportTelemetry },
  } = useFeatureFlags();
  const { refreshWsCable } = useGraphqlContext();
  const flushOperations = useTMContext()?.flushOperations;
  const [tm] = useMutation(TM_MUTATION, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });

  const mainSport: Sport.NBA | Sport.FOOTBALL =
    data?.config.currentLocation.countryCode?.toLowerCase() === 'us'
      ? Sport.NBA
      : Sport.FOOTBALL;

  if (
    data &&
    !data.currentUser &&
    sport !== mainSport &&
    matchPath('/', pathname)
  ) {
    // Consider the main sport as the one from the user's location
    // this will re-trigger the config query with the correct sport
    setSport(mainSport);
  }

  const value = useMemo(() => {
    if (loading || !data?.config) {
      return null;
    }
    const { config, tokens } = data;
    const { algoliaIndexSuffix, exchangeRate, minimumReceiveWeiAmount } =
      config;
    const algoliaCardIndexes: AlgoliaCardIndexes = {
      // cards indices
      'Cards New': `Card${algoliaIndexSuffix}`,
      'Cards Highest Average Score': `Card_HighestAverageScore${algoliaIndexSuffix}`,
      'Cards Highest Price': `Card_HighestPrice${algoliaIndexSuffix}`,
      'Cards Lowest Price': `Card_LowestPrice${algoliaIndexSuffix}`,
      'Cards Player Name': `Card_PlayerName${algoliaIndexSuffix}`,

      // blockchain cards indices
      'Ending Soon': `BlockchainCard_EndingSoon${algoliaIndexSuffix}`,
      'Newly Listed': `BlockchainCard_NewlyListed${algoliaIndexSuffix}`,
      'Highest Average Score': `BlockchainCard_HighestAverageScore${algoliaIndexSuffix}`,
      'Highest Price': `BlockchainCard${algoliaIndexSuffix}`,
      'Lowest Price': `BlockchainCard_LowestPrice${algoliaIndexSuffix}`,
      'Best Value': `BlockchainCard_BestValue${algoliaIndexSuffix}`,
      'Popular Player': `BlockchainCard_Popularity${algoliaIndexSuffix}`,

      'Cards On Sale Ending Soon': `CardsOnSale_EndingSoon${algoliaIndexSuffix}`,
      'Cards On Sale Newly Listed': `CardsOnSale_NewlyListed${algoliaIndexSuffix}`,
      'Cards On Sale Highest Average Score': `CardsOnSale_HighestAverageScore${algoliaIndexSuffix}`,
      'Cards On Sale Highest Price': `CardsOnSale${algoliaIndexSuffix}`,
      'Cards On Sale Lowest Price': `CardsOnSale_LowestPrice${algoliaIndexSuffix}`,
      'Cards On Sale Best Value': `CardsOnSale_BestValue${algoliaIndexSuffix}`,
      'Cards On Sale Popular Player': `CardsOnSale_Popularity${algoliaIndexSuffix}`,

      // Common cards indices
      'Common Cards': `CommonCard${algoliaIndexSuffix}`,
      'Common Cards Highest Average Score': `CommonCard_HighestAverageScore${algoliaIndexSuffix}`,
      'Common Cards Player Name': `CommonCard_PlayerName${algoliaIndexSuffix}`,
    };
    const algoliaIndexes: AlgoliaIndexes = {
      ...algoliaCardIndexes,
      User: `User${algoliaIndexSuffix}`,
      Player: `Player${algoliaIndexSuffix}`,
      Club: `Club${algoliaIndexSuffix}`,
      League: `League${algoliaIndexSuffix}`,
      Country: `Country${algoliaIndexSuffix}`,
      'National Team': `NationalTeam${algoliaIndexSuffix}`,
      Competition: `Competition${algoliaIndexSuffix}`,
    };

    return {
      ...config,
      ...tokens,
      mainSport,
      so5: {
        ...config.so5,
        competitionEligibilityAlgoliaFilters: asObject(
          config.so5.competitionEligibilityAlgoliaFilters
        ),
      },
      marketFeeRateBasisPoints: config.marketFeeRateBasisPoints / 10000,
      marketFeePercentagePoints: BigInt(config.marketFeeRateBasisPoints) / 100n,
      minimumReceiveWeiAmount,
      algoliaIndexes,
      algoliaCardIndexes,
      currentUser: data.currentUser,
      exchangeRate: {
        ...exchangeRate,
        getEthRate: (to: CurrencyCode) =>
          (exchangeRate.ethRates[
            `${to.toLowerCase()}Minor` as keyof typeof exchangeRate.ethRates
          ] as number) / 100,
      },
      refetch,
      updateQuery: (newCurrentUser: ConfigQuery_currentUser) => {
        updateQuery(() => ({ ...data, currentUser: newCurrentUser }));
        refreshWsCable();
      },
      defaultFiatCurrency: currency(config.defaultFiatCurrency),
    };
  }, [loading, data, mainSport, refetch, updateQuery, refreshWsCable]);

  useInterval(
    () => {
      if (flushOperations) {
        const operations = flushOperations();
        if (!operations.length) {
          return;
        }
        tm({ variables: { input: { operations } } });
      }
    },
    useReportTelemetry ? tmInterval : null
  );

  if (!value) return <LoadingIndicator fullScreen />;

  return (
    <ConfigContextProvider value={value}>{children}</ConfigContextProvider>
  );
};

export default ConfigProvider;
