import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { KycDocumentRequirement } from '__generated__/globalTypes';
import { FormControlLabel } from 'atoms/inputs/FormControlLabel';
import { NativeSelect } from 'atoms/inputs/NativeSelect';
import { RadioGroup } from 'atoms/inputs/RadioGroup';
import { Block } from 'atoms/layout/Block';
import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { LabelM, Text16, Title3, Title4 } from 'atoms/typography';
import { StepWrapperFromProps } from 'components/fiatWallet/CreateFiatWallet/StepWrapper';
import { MANGOPAY_PRIVACY_POLICY } from 'components/fiatWallet/CreateFiatWallet/externalLinks';
import { GraphqlForm } from 'components/form/Form';
import { useIntlContext } from 'contexts/intl';
import { useIntlCountries } from 'hooks/useIntlCountries';
import { glossary } from 'lib/glossary';

import { kycDocumentTypeLabels } from './messages';

const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
  height: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  > * {
    width: 100%;
  }
`;

const LinkInFormattedMessage = styled.a`
  text-decoration: underline;
`;

type Props = {
  issuingCountry?: { value: string; label: string };
  setIssuingCountry: (country: { value: string; label: string }) => void;
  kycDocumentRequirements: KycDocumentRequirement[];
  kycDocumentRequirementsLoading: boolean;
  documentRequirement?: KycDocumentRequirement;
  setDocumentRequirement: (type: KycDocumentRequirement) => void;
  onBack: () => void;
  onNext: () => void;
};

export const ChooseDocument = ({
  issuingCountry,
  setIssuingCountry,
  kycDocumentRequirements,
  kycDocumentRequirementsLoading,
  documentRequirement,
  onBack,
  onNext,
  setDocumentRequirement,
}: Props) => {
  const { formatMessage } = useIntlContext();
  const countries = useIntlCountries(true);

  const documentRequirementsOptions = Object.values(
    kycDocumentRequirements
  ).map(kycDocumentRequirement => {
    return {
      value: kycDocumentRequirement.documentType,
      label: (
        <Text16 color="var(--c-neutral-1000)" bold>
          {formatMessage(
            kycDocumentTypeLabels[kycDocumentRequirement.documentType]
          )}
        </Text16>
      ),
    };
  });

  return (
    <StyledGraphqlForm
      onSuccess={() => {
        onNext();
      }}
      onSubmit={(values, onResult) => {
        onResult({});
      }}
      render={(Error, SubmitButton) => (
        <StepWrapperFromProps
          onBack={onBack}
          submitButton={
            <SubmitButton size="medium" disabled={!documentRequirement}>
              <FormattedMessage {...glossary.next} />
            </SubmitButton>
          }
        >
          <Title3>
            <FormattedMessage
              id="createFiatWallet.chooseId.title"
              defaultMessage="Add your ID"
            />
          </Title3>
          <LabelM>
            <FormattedMessage
              id="createFiatWallet.chooseId.description"
              defaultMessage="Please provide a government-issued ID to enable cash deposits, withdrawals, and rewards."
            />
          </LabelM>
          <Title4>
            <FormattedMessage
              id="createFiatWallet.chooseId.subtitle"
              defaultMessage="Choose an ID type to add"
            />
          </Title4>
          <StyledFormControlLabel
            label={
              <Text16 bold color="var(--c-neutral-1000)">
                <FormattedMessage
                  id="ChooseDocument.issuer"
                  defaultMessage="Issuing country/region"
                />
              </Text16>
            }
            control={
              <NativeSelect
                placeholder={formatMessage({
                  id: 'createFiatWallet.tellUsAboutYou.issuer.placeholder',
                  defaultMessage: 'Select an issuing country/region',
                })}
                fullWidth
                name="country"
                value={issuingCountry?.value ?? ''}
                values={countries}
                onChange={value => {
                  setIssuingCountry(countries.find(c => c.value === value)!);
                }}
              />
            }
            labelPlacement="top"
          />
          {kycDocumentRequirementsLoading ? (
            <LoadingIndicator small />
          ) : (
            <>
              {!!kycDocumentRequirements.length && (
                <StyledFormControlLabel
                  label={null}
                  control={
                    <RadioGroup
                      options={documentRequirementsOptions}
                      value={documentRequirement?.documentType}
                      name="currency"
                      modal
                      border
                      withSpacing
                      onChange={(value: string) => {
                        const selectedDocumentRequirement =
                          kycDocumentRequirements?.find(
                            requirement => requirement.documentType === value
                          );

                        if (selectedDocumentRequirement) {
                          setDocumentRequirement(selectedDocumentRequirement);
                        }
                      }}
                    />
                  }
                  labelPlacement="top"
                />
              )}
            </>
          )}
          <div>
            <Block>
              <LabelM color="var(--c-neutral-600)">
                <FormattedMessage
                  id="createFiatWallet.uploadId.privacy"
                  defaultMessage="Your ID remains private and will be handled according to Mangopay’s <link>Privacy policy</link>."
                  values={{
                    link: (...text) => (
                      <LinkInFormattedMessage
                        target="_blank"
                        href={MANGOPAY_PRIVACY_POLICY}
                      >
                        {text}
                      </LinkInFormattedMessage>
                    ),
                  }}
                />
              </LabelM>
            </Block>
          </div>
        </StepWrapperFromProps>
      )}
    />
  );
};
