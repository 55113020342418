import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { useGetInteractionContext } from '@sorare/core/src/hooks/useGetInteractionContext';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';

import { tokensInfoProperties } from 'lib/tokenEvents';

import { useClickBundleEvent_anyCard } from './__generated__/useClickBundleEvent.graphql';

export const useClickBundleEvent = () => {
  const track = useEvents();
  const getInteractionContext = useGetInteractionContext();

  const trackClickBundle = useCallback(
    (
      auctionId: string,
      cards: useClickBundleEvent_anyCard[],
      sport: Sport,
      subPath?: string
    ) => {
      const sharedProperties = {
        auctionId,
        secondary: false,
        interactionContext: getInteractionContext(subPath),
        sport,
      };

      track('Click Bundle', {
        ...sharedProperties,
        ...tokensInfoProperties(cards),
      });
    },
    [track, getInteractionContext]
  );

  return trackClickBundle;
};

useClickBundleEvent.fragments = {
  anyCard: gql`
    fragment useClickBundleEvent_anyCard on AnyCardInterface {
      slug
      sport
      rarityTyped
    }
  ` as TypedDocumentNode<useClickBundleEvent_anyCard>,
};
