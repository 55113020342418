import classnames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';

import { BackButton } from '../BackButton';

const Root = styled.div`
  width: 100%;
  &.stickyHeader {
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const Content = styled.div`
  padding: var(--double-unit);
  &.stickyHeader {
    flex: 1;
    overflow: auto;
  }
  &.noPadding {
    padding: 0;
  }
`;
const Nav = styled(Horizontal).attrs({ gap: 0, center: true })`
  position: relative;
  min-height: 60px;
  border-bottom: 1px solid var(--c-neutral-300);
`;
const Title = styled.div`
  flex: 1;
  text-align: center;
  margin: 0 var(--unit);
`;
const Left = styled(Horizontal).attrs({ gap: 0 })`
  justify-content: flex-start;
  margin-left: var(--double-unit);
  min-width: 40px;
`;
const Right = styled(Horizontal).attrs({ gap: 0 })`
  justify-content: flex-end;
  margin-right: var(--double-unit);
  min-width: 40px;
`;
const Footer = styled(Horizontal).attrs({ gap: 0, center: true })`
  position: relative;
  min-height: 60px;
  margin: 0 var(--double-unit);
`;

type Props = {
  title?: ReactNode;
  right?: ReactNode;
  children: ReactNode;
  backButton?: ReactNode;
  onBackButton?: () => void;
  stickyHeader?: boolean;
  noPadding?: boolean;
  footer?: ReactNode;
};
export const DialogContentWithNavigation = ({
  children,
  onBackButton,
  backButton: backButtonCustom,
  title,
  right,
  stickyHeader,
  noPadding,
  footer,
}: Props) => {
  return (
    <Root className={classnames({ stickyHeader })}>
      <Nav>
        <Left>
          {backButtonCustom}
          {!backButtonCustom && onBackButton && (
            <BackButton onBack={onBackButton} />
          )}
        </Left>
        <Title>{title}</Title>
        <Right>{right || null}</Right>
      </Nav>
      <Content className={classnames({ stickyHeader, noPadding })}>
        {children}
      </Content>
      {footer && <Footer>{footer}</Footer>}
    </Root>
  );
};
