import { TypedDocumentNode, gql, useMutation } from '@apollo/client';

import {
  DeleteSubscriptionMutation,
  DeleteSubscriptionMutationVariables,
} from './__generated__/useUnsubscribe.graphql';

const DELETE_SUBSCRIPTION_MUTATION = gql`
  mutation DeleteSubscriptionMutation($input: deleteSubscriptionInput!) {
    deleteSubscription(input: $input) {
      subscription {
        slug
        anySubscribable {
          slug
          subscriptionsCount
          currentUserSubscription {
            slug
          }
        }
      }
    }
  }
` as TypedDocumentNode<
  DeleteSubscriptionMutation,
  DeleteSubscriptionMutationVariables
>;

export default function useUnsubscribe() {
  const [unsubscribe] = useMutation(DELETE_SUBSCRIPTION_MUTATION);

  return async (subscription: { slug: string }) =>
    unsubscribe({
      variables: {
        input: {
          slug: subscription.slug,
        },
      },
    });
}
