import { So5FixtureEvent } from '__generated__/globalTypes';
import { useIntlContext } from 'contexts/intl';

export const useFormatGameWeekLabel = () => {
  const { formatDate } = useIntlContext();
  return ({
    gameWeek,
    shortDisplayName,
    startDate,
    endDate,
    type,
  }: {
    gameWeek?: number;
    shortDisplayName: string;
    startDate: Date;
    endDate: Date;
    type: So5FixtureEvent;
  }) => {
    if (type === So5FixtureEvent.DAILY) return shortDisplayName;
    const shortGWlabel =
      shortDisplayName ?? (gameWeek ? `GW ${gameWeek}` : undefined);
    const dates = `${formatDate(startDate, {
      month: 'short',
    })} ${formatDate(startDate, { day: 'numeric' })}-${formatDate(endDate, {
      day: 'numeric',
    })}`;
    return [shortGWlabel, dates].filter(Boolean).join(' – ');
  };
};
