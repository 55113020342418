import { useCallback } from 'react';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { useConfigContext } from '@sorare/core/src/contexts/config';
import {
  MonetaryAmountOutput,
  calculateFees,
  useMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { getMonetaryAmountIndex } from '@sorare/core/src/lib/monetaryAmount';

export const useCalculateDirectOfferMarketFee = () => {
  const { marketFeePercentagePoints } = useConfigContext();
  const { toMonetaryAmount } = useMonetaryAmount();
  return useCallback(
    (
      monetaryAmount: MonetaryAmountOutput,
      referenceCurrency: SupportedCurrency
    ) => {
      const monetaryAmountIndex = getMonetaryAmountIndex(referenceCurrency);
      const { feesMonetaryAmount } = calculateFees({
        monetaryAmount,
        feesPercentagePoints: marketFeePercentagePoints,
      });
      const res = feesMonetaryAmount[monetaryAmountIndex];

      return toMonetaryAmount({
        [monetaryAmountIndex]: res.toString(),
        referenceCurrency,
      });
    },
    [marketFeePercentagePoints, toMonetaryAmount]
  );
};
