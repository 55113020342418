import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Tooltip } from 'atoms/tooltip/Tooltip';
import { Ellipsis } from 'atoms/typography/Ellipsis';
import { Nickname } from 'components/user/Nickname';

import { UserName_publicUserInfoInterface } from './__generated__/index.graphql';

const Root = styled.span`
  display: inline-flex;
  align-items: center;
  gap: var(--half-unit);
  max-width: 100%;
`;

const Badge = styled.img`
  width: min(1em, 18px);
  height: min(1em, 18px);
  margin-bottom: var(--half-unit);
`;

type Props = {
  user: UserName_publicUserInfoInterface;
};

export const UserName = ({ user }: Props) => {
  return (
    <Root>
      <Ellipsis>
        <Nickname user={user} />
      </Ellipsis>
      {user.profile.badge && (
        <Tooltip title={user.profile.badge.description}>
          <Badge
            src={user.profile.badge.pictureUrl}
            alt={user.profile.badge.description}
          />
        </Tooltip>
      )}
    </Root>
  );
};

UserName.fragments = {
  user: gql`
    fragment UserName_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      profile {
        id
        badge {
          slug
          pictureUrl
          description
        }
      }
      ...Nickname_publicUserInfoInterface
    }
    ${Nickname.fragments.user}
  ` as TypedDocumentNode<UserName_publicUserInfoInterface>,
};
