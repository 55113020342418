import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  access: {
    id: 'Landing.Prizes.access',
    defaultMessage: 'Access',
  },
  learnCTA: {
    id: 'Landing.Prizes.learnCTA',
    defaultMessage: 'Learn more',
  },
  watchCTA: {
    id: 'Landing.Prizes.watchCTA',
    defaultMessage: 'Watch now',
  },
  lifetime: {
    id: 'Landing.Prizes.lifetime',
    defaultMessage: 'Once in a lifetime',
  },
});
