import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentProps, useMemo } from 'react';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { StatsIcon as DumbStatsIcon } from '@sorare/core/src/components/draft/StatsIcon';
import {
  MAX_SCORE as FOOTBALL_MAX_SCORE,
  playerScoreThresholds,
} from '@sorare/core/src/lib/footballThresholds';
import { findThresholdColor } from '@sorare/core/src/lib/thresholds';
import {
  MAX_SCORE as US_SPORTS_MAX_SCORE,
  thresholds as usSportThresholds,
} from '@sorare/core/src/lib/usSportsThresholds';
import { Color } from '@sorare/core/src/style/types';

import { AnyPlayerStatsIcon_anyPlayer } from './__generated__/index.graphql';

type Props = {
  player: AnyPlayerStatsIcon_anyPlayer;
  onClick?: ComponentProps<typeof DumbStatsIcon>['onClick'];
} & Pick<ComponentProps<typeof DumbStatsIcon>, 'outlined' | 'transparent'>;

export const AnyPlayerStatsIcon = ({
  player,
  onClick,
  ...dumbStatIconProps
}: Props) => {
  const sportThresholds =
    player.sport === Sport.FOOTBALL ? playerScoreThresholds : usSportThresholds;

  const thresholdsScores = sportThresholds.map(t => t[0]);

  // Handles two scales per thresholds
  const scaleScores = [
    ...thresholdsScores,
    player.sport === Sport.FOOTBALL ? FOOTBALL_MAX_SCORE : US_SPORTS_MAX_SCORE,
  ]
    .map((s, i, arr) => (arr[i + 1] ? [s, s + (arr[i + 1] - s) / 2] : s))
    .flat();

  const scaleSize = scaleScores.length;

  const stats = useMemo(() => {
    return [...player.rawPlayerGameScores].reverse().map(so5Score => {
      let color: Color;
      let percent: number;
      if (so5Score === null) {
        percent = 1 / scaleSize;
        color = 'var(--c-neutral-600)';
      } else if (so5Score < 0) {
        percent = 1 / scaleSize;
        color = 'var(--c-red-600)';
      } else {
        const thresholdIndex = scaleScores.findIndex(n => n > so5Score);
        percent =
          ((thresholdIndex >= 0 ? thresholdIndex : scaleScores.length) + 1) /
          scaleSize;
        color = findThresholdColor(so5Score, player.sport);
      }

      return { percent, color };
    });
  }, [player.rawPlayerGameScores, player.sport, scaleScores, scaleSize]);

  return (
    <DumbStatsIcon stats={stats} onClick={onClick} {...dumbStatIconProps} />
  );
};

AnyPlayerStatsIcon.fragments = {
  anyPlayer: gql`
    fragment AnyPlayerStatsIcon_anyPlayer on AnyPlayerInterface {
      slug
      rawPlayerGameScores(last: 5)
      sport
    }
  ` as TypedDocumentNode<AnyPlayerStatsIcon_anyPlayer>,
};
