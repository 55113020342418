import { useSyncExternalStore } from 'react';

import { Notification } from 'contexts/snackNotification';

let notifications: Notification[] = [];
let listeners: (() => void)[] = [];

function emitChange() {
  for (const listener of listeners) {
    listener();
  }
}

export const notificationsQueue = {
  addNotification(
    id: Notification['id'],
    values?: Notification['values'],
    options?: Notification['options']
  ) {
    if (
      options?.ignoreDuplicates &&
      !!notifications.find(notification => {
        return notification.id === id;
      })
    )
      return;

    notifications = [...notifications, { id, values, options }];
    emitChange();
  },
  popNotification() {
    const notification = notifications.shift();
    emitChange();
    return notification || null;
  },
  subscribe(listener: () => void) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  },
  getSnapshot() {
    return notifications;
  },
};

export const useNotificationsQueue = () =>
  useSyncExternalStore(
    notificationsQueue.subscribe,
    notificationsQueue.getSnapshot
  );
