import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';

import { Sport } from '__generated__/globalTypes';
import { useSportContext } from 'contexts/sport';

import { useAcceptedCurrencies_publicUserInfoInterface } from './__generated__/useAcceptedCurrencies.graphql';

export const MARKETPLACE_PREFERENCES_ACCEPTED_CURRENCIES =
  'accepted_currencies' as const;

export enum AcceptedCurrenciesValue {
  FIAT = 'fiat',
  ETH = 'eth',
  BOTH = 'both',
}
type Props = {
  user?: useAcceptedCurrencies_publicUserInfoInterface | null;
};

export const useAcceptedCurrencies = ({ user }: Props) => {
  const { sport = Sport.FOOTBALL } = useSportContext();

  const { enabledWallets, marketplacePreferences } = user?.profile || {};

  const storedAcceptedCurrencies = marketplacePreferences
    // we currently use the same preferences for all sports
    ?.find(mp => mp && mp.sport === sport)
    ?.preferences?.find(
      p => p.name === MARKETPLACE_PREFERENCES_ACCEPTED_CURRENCIES
    )?.value;

  const acceptedCurrencies = useMemo(() => {
    if (!enabledWallets) {
      return AcceptedCurrenciesValue.ETH;
    }
    if (enabledWallets.length === 1) {
      return AcceptedCurrenciesValue[enabledWallets[0]];
    }
    if (storedAcceptedCurrencies) {
      return storedAcceptedCurrencies as AcceptedCurrenciesValue;
    }
    return AcceptedCurrenciesValue.BOTH;
  }, [enabledWallets, storedAcceptedCurrencies]);

  return {
    acceptedCurrencies,
    storedAcceptedCurrencies,
  };
};

useAcceptedCurrencies.fragments = {
  publicUserInfoInterface: gql`
    fragment useAcceptedCurrencies_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      profile {
        id
        enabledWallets
        marketplacePreferences(sports: [FOOTBALL, NBA, BASEBALL]) {
          sport
          preferences {
            name
            value
          }
        }
      }
    }
  ` as TypedDocumentNode<useAcceptedCurrencies_publicUserInfoInterface>,
};
