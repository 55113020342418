import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { isPast } from 'date-fns';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import {
  AndroidAppFeature,
  IOsAppFeature,
  NotMobileAppFeature,
  UnvailableButtonForAndroid,
} from '@sorare/core/src/components/BuyableFeature';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import { SorareUser } from '@sorare/core/src/components/user/SorareUser';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { ItemSpecialRewardBadge } from 'components/ItemPreview/ItemSpecialRewardBadge';
import {
  ButtonContainer,
  TokenDetailsRoot,
  TokenDetailsRow,
} from 'components/ItemPreview/ui';
import { AuctionReminder } from 'components/auction/AuctionReminder';
import { AuctionStatus } from 'components/auction/AuctionStatus';
import BidField from 'components/buyActions/BidField';
import useTokenTakesPartPromotionalEvent from 'hooks/offers/useTokenTakesPartPromotionalEvent';
import {
  auctionCurrentPrice,
  promotionalEventsExcludeSpecialRewardBadge,
} from 'lib/auctions';

import { AuctionWinner } from './AuctionWinner';
import { AuctionDetails_auction } from './__generated__/index.graphql';

type Props = {
  auction: AuctionDetails_auction;
  isDesktopLayout: boolean;
  hideSorareUser?: boolean;
  allowColumnLayout?: boolean;
  useConversionRate?: boolean;
};

export const AuctionDetails = ({
  auction,
  isDesktopLayout,
  hideSorareUser,
  allowColumnLayout,
  useConversionRate = false,
}: Props) => {
  const takesPartInEvent = useTokenTakesPartPromotionalEvent();

  const currentPriceMonetary = auctionCurrentPrice(auction);
  const { endDate, cards, bestBid } = auction;
  const hasEnded = isPast(endDate);
  const promotionalEvent = takesPartInEvent(cards);
  const {
    flags: { buyOnWebMarketplaceFromAndroid = false },
  } = useFeatureFlags();

  return (
    <TokenDetailsRoot className={classNames({ allowColumnLayout })}>
      <div>
        <TokenDetailsRow>
          <AmountWithConversion
            monetaryAmount={
              !useConversionRate && hasEnded && bestBid
                ? bestBid.amounts
                : currentPriceMonetary
            }
          />
        </TokenDetailsRow>
        {promotionalEvent &&
          !promotionalEventsExcludeSpecialRewardBadge.includes(
            promotionalEvent.name
          ) && <ItemSpecialRewardBadge event={promotionalEvent} />}
        <TokenDetailsRow>
          {hasEnded ? (
            <AuctionWinner auction={auction} />
          ) : (
            <AuctionStatus auction={auction} />
          )}
        </TokenDetailsRow>
      </div>
      {isDesktopLayout && !hideSorareUser && (
        <TokenDetailsRow>
          <SorareUser />
        </TokenDetailsRow>
      )}
      {!hasEnded && (
        <ButtonContainer $isDesktopLayout={isDesktopLayout}>
          <NotMobileAppFeature>
            <Horizontal>
              <BidField
                color="quaternary"
                variant="small"
                auction={auction}
                cards={cards}
              />
              <AuctionReminder auction={auction} small />
            </Horizontal>
          </NotMobileAppFeature>
          <AndroidAppFeature>
            <Horizontal>
              <UnvailableButtonForAndroid
                color="tertiary"
                size="small"
                fullWidth
              />
              {!buyOnWebMarketplaceFromAndroid && (
                <AuctionReminder auction={auction} small />
              )}
            </Horizontal>
          </AndroidAppFeature>
          <IOsAppFeature>
            <AuctionReminder auction={auction} withText small />
          </IOsAppFeature>
        </ButtonContainer>
      )}
    </TokenDetailsRoot>
  );
};

AuctionDetails.fragments = {
  auction: gql`
    fragment AuctionDetails_auction on TokenAuction {
      id
      open
      bestBid {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
      cards: anyCards {
        slug
        assetId
        sport
        ...BidField_anyCard
      }
      ...BidField_auction
      ...AuctionStatus_auction
      ...auctionCurrentPrice_auction
      ...AuctionWinner_auction
      ...auctionCurrentPrice_auction
      ...AuctionReminder_auction
    }
    ${monetaryAmountFragment}
    ${auctionCurrentPrice.fragments.auction}
    ${BidField.fragments.anyCard}
    ${BidField.fragments.auction}
    ${AuctionStatus.fragments.auction}
    ${auctionCurrentPrice.fragments.auction}
    ${AuctionWinner.fragments.auction}
    ${AuctionReminder.fragments.auction}
  ` as TypedDocumentNode<AuctionDetails_auction>,
};
