import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Block } from '@sorare/core/src/atoms/layout/Block';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { useMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { useTokenOfferBelongsToUser } from '@sorare/core/src/hooks/useTokenOfferBelongsToUser';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import BuyField from 'components/buyActions/BuyField';
import MakeOffer from 'components/directOffer/MakeOffer';
import { SalePriceFeesTooltip } from 'components/sale/Sale/SaleDetails/SalePrice/SalePriceFeesTooltip';

import OwnerAccount from '../OwnerAccount';
import { SingleSaleOfferDetails } from './SingleSaleOfferDetails';
import { SingleSaleOffer_anyCard } from './__generated__/index.graphql';

type Props = {
  card: SingleSaleOffer_anyCard;
};

const Header = styled(SBHorizontal)`
  width: 100%;
  padding-bottom: var(--double-unit);
  border-bottom: 1px solid var(--c-neutral-400);
  flex-wrap: wrap;
`;

const Content = styled(Vertical)`
  @media ${tabletAndAbove} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
const Actions = styled(Vertical)`
  display: flex;
  flex-direction: column;
  @media ${tabletAndAbove} {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const SingleSaleOffer = ({ card }: Props) => {
  const { toMonetaryAmount } = useMonetaryAmount();
  const { up: isDesktopLayout } = useScreenSize('tablet');
  const belongsToUser = useTokenOfferBelongsToUser();

  const { myMintedSingleSaleOffer, liveSingleSaleOffer, tokenOwner } = card;
  const isCurrentUserSelling =
    myMintedSingleSaleOffer && belongsToUser(myMintedSingleSaleOffer);
  const offer =
    liveSingleSaleOffer ||
    (isCurrentUserSelling ? myMintedSingleSaleOffer : null);
  const user = tokenOwner?.user;

  if (!user || user.suspended || !offer) return null;

  const {
    receiverSide: { amounts },
    endDate,
  } = offer;

  const priceMonetaryAmount = toMonetaryAmount(amounts);

  return (
    <div>
      <Block>
        <Vertical gap={2}>
          <Header>
            <OwnerAccount account={tokenOwner.account} />
            {isCurrentUserSelling && isDesktopLayout && (
              <BuyField
                card={card}
                variant="medium"
                fullWidth={!isDesktopLayout}
                color="secondary"
              />
            )}
          </Header>
          <Content>
            <SingleSaleOfferDetails
              priceMonetaryAmount={priceMonetaryAmount}
              endDate={endDate}
              cancelled={false}
            />
            {myMintedSingleSaleOffer &&
              liveSingleSaleOffer &&
              belongsToUser(myMintedSingleSaleOffer) && (
                <SalePriceFeesTooltip
                  sale={liveSingleSaleOffer}
                  monetaryAmount={toMonetaryAmount(
                    liveSingleSaleOffer.receiverSide.amounts
                  )}
                  referenceCurrency={
                    liveSingleSaleOffer.receiverSide.amounts.referenceCurrency
                  }
                />
              )}
            <Actions gap={1}>
              {(!isCurrentUserSelling || !isDesktopLayout) && (
                <BuyField
                  card={card}
                  variant="medium"
                  fullWidth={!isDesktopLayout}
                  color="secondary"
                />
              )}
              <MakeOffer card={card} />
            </Actions>
          </Content>
        </Vertical>
      </Block>
    </div>
  );
};

SingleSaleOffer.fragments = {
  anyCard: gql`
    fragment SingleSaleOffer_anyCard on AnyCardInterface {
      slug
      tokenOwner {
        id
        user {
          slug
          ...ActiveUserAvatar_user
          ...UserName_publicUserInfoInterface
        }
        account {
          id
          ...OwnerAccount_account
        }
      }
      liveSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        endDate
        receiverSide {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
      }
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        endDate
        sender {
          ... on User {
            slug
          }
        }
        receiverSide {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
        ...SalePriceFeesTooltip_offer
      }
      ...MakeOffer_anyCard
      ...BuyField_anyCard
    }
    ${monetaryAmountFragment}
    ${BuyField.fragments.anyCard}
    ${MakeOffer.fragments.anyCard}
    ${OwnerAccount.fragments.account}
    ${SalePriceFeesTooltip.fragments.offer}
  ` as TypedDocumentNode<SingleSaleOffer_anyCard>,
};

export default SingleSaleOffer;
