import { TypedDocumentNode, gql } from '@apollo/client';
import { Fragment } from 'react';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Box } from '@sorare/core/src/atoms/ui/Box';
import { sortBy } from '@sorare/core/src/lib/arrays';
import { hideScrollbar } from '@sorare/core/src/style/utils';

import { PlayerInFixtureLinkBox } from 'components/game/GameView/PlayerInFixtureLinkBox';
import { PlayerInLineupLinkBox } from 'components/game/GameView/PlayerInLineupLinkBox';

import {
  LiveCardsInLineup_anyCard,
  LiveCardsInLineup_basketballPlayerGameScore,
  LiveCardsInLineup_so5Appearance,
  LiveCardsInLineup_so5Fixture,
} from './__generated__/index.graphql';

type Props = {
  fixture: LiveCardsInLineup_so5Fixture;
  playerStats: {
    playerGameScore: LiveCardsInLineup_basketballPlayerGameScore;
    so5Appearances?: LiveCardsInLineup_so5Appearance[];
    unusedCards?: LiveCardsInLineup_anyCard[];
  }[];
  onMouseHoverPlayer: (playerSlug: string) => void;
  onMouseLeavePlayer: () => void;
  openCardInfoModal: (cardSlug: string) => void;
};

const Scrollable = styled(Horizontal)`
  ${hideScrollbar}
  display: flex;
  align-items: flex-start;
  overflow: auto;
  scroll-snap-type: x mandatory;
  margin: calc(-1 * var(--unit)) calc(-1 * var(--double-unit));
  padding: var(--unit) var(--double-unit);
`;

const GAP = 3;
export const LiveCardsInLineup = ({
  fixture,
  playerStats,
  openCardInfoModal,
  onMouseLeavePlayer,
  onMouseHoverPlayer,
}: Props) => {
  const showCardsInLineup = true;
  const showCardsInGallery = true;
  const sortedPlayersByScore = sortBy(
    ({ playerGameScore }) => playerGameScore.score || 0,
    [...playerStats]
  );
  const playedInGame = sortedPlayersByScore.filter(
    ({ playerGameScore }) =>
      (playerGameScore.basketballPlayerGameStats?.minsPlayed || 0) > 0
  );

  const topPlayerStats = [...playedInGame].reverse();

  if (!topPlayerStats.length) {
    return <div />;
  }
  return (
    <Box>
      <Scrollable gap={GAP}>
        {topPlayerStats.map(
          ({
            playerGameScore,
            so5Appearances: hiddenSo5Appearances = [],
            unusedCards: hiddenUnusedCards = [],
          }) => {
            const cards = showCardsInLineup ? hiddenSo5Appearances : [];
            const unusedCards =
              showCardsInLineup && showCardsInGallery ? hiddenUnusedCards : [];

            const nbCardsInGame = cards.length + unusedCards.length;
            return (
              <Fragment key={playerGameScore.anyPlayer.slug}>
                {nbCardsInGame > 0 && (
                  <>
                    {cards.map(appearance => (
                      <PlayerInLineupLinkBox
                        key={appearance.anyCard?.slug}
                        fixture={fixture}
                        so5Appearance={appearance}
                        onMouseEnter={() =>
                          onMouseHoverPlayer(playerGameScore.anyPlayer.slug)
                        }
                        onMouseLeave={onMouseLeavePlayer}
                      />
                    ))}
                  </>
                )}
              </Fragment>
            );
          }
        )}
        {topPlayerStats.map(
          ({
            playerGameScore,
            so5Appearances: hiddenSo5Appearances = [],
            unusedCards: hiddenUnusedCards = [],
          }) => {
            const cards = showCardsInLineup ? hiddenSo5Appearances : [];
            const unusedCards =
              showCardsInLineup && showCardsInGallery ? hiddenUnusedCards : [];

            const nbCardsInGame = cards.length + unusedCards.length;
            return (
              <Fragment key={playerGameScore.anyPlayer.slug}>
                {nbCardsInGame > 0 && (
                  <>
                    {unusedCards.map(card => (
                      <PlayerInFixtureLinkBox
                        key={card.slug}
                        card={card}
                        onMouseEnter={() =>
                          onMouseHoverPlayer(playerGameScore.anyPlayer.slug)
                        }
                        onMouseLeave={onMouseLeavePlayer}
                        onClick={() => {
                          openCardInfoModal(card.slug);
                        }}
                      />
                    ))}
                  </>
                )}
              </Fragment>
            );
          }
        )}
      </Scrollable>
    </Box>
  );
};

LiveCardsInLineup.fragments = {
  so5Fixture: gql`
    fragment LiveCardsInLineup_so5Fixture on So5Fixture {
      slug
      ...PlayerInLineupLinkBox_so5Fixture
    }
    ${PlayerInLineupLinkBox.fragments.so5Fixture}
  ` as TypedDocumentNode<LiveCardsInLineup_so5Fixture>,
  basketballPlayerGameScore: gql`
    fragment LiveCardsInLineup_basketballPlayerGameScore on BasketballPlayerGameScore {
      id
      score
      anyGame {
        id
      }
      anyPlayer {
        slug
        lastTenPlayedSo5AverageScore: averageScore(
          type: LAST_TEN_PLAYED_SO5_AVERAGE_SCORE
        )
      }
      basketballPlayerGameStats {
        id
        minsPlayed
        anyTeam {
          slug
        }
      }
    }
  ` as TypedDocumentNode<LiveCardsInLineup_basketballPlayerGameScore>,
  anyCard: gql`
    fragment LiveCardsInLineup_anyCard on AnyCardInterface {
      slug
      ...PlayerInFixtureLinkBox_anyCard
    }
    ${PlayerInFixtureLinkBox.fragments.anyCard}
  ` as TypedDocumentNode<LiveCardsInLineup_anyCard>,
  so5Appearance: gql`
    fragment LiveCardsInLineup_so5Appearance on So5Appearance {
      id
      anyCard {
        slug
      }
      ...PlayerInLineupLinkBox_so5Appearance
    }
    ${PlayerInLineupLinkBox.fragments.so5Appearance}
  ` as TypedDocumentNode<LiveCardsInLineup_so5Appearance>,
};
