import { CreditCardBrand, CreditCardType } from '__generated__/globalTypes';
import { useConfigContext } from 'contexts/config';
import { useMangopayCreditCardsEnabled } from 'hooks/useMangopayCreditCardsEnabled';

export const useGetCreditCardsFeesBasisPoints = () => {
  const mangopayCreditCardsEnabled = useMangopayCreditCardsEnabled();
  const {
    checkoutFeesBasisPoints,
    mangopayVisaFeesBasisPoints,
    mangopayAmexFeesBasisPoints,
  } = useConfigContext();
  return (cardType?: CreditCardBrand | CreditCardType | string | undefined) => {
    if (!mangopayCreditCardsEnabled) {
      return +checkoutFeesBasisPoints;
    }
    if (
      CreditCardBrand.AMERICAN_EXPRESS === cardType ||
      CreditCardType.AMEX === cardType
    )
      return +mangopayAmexFeesBasisPoints;
    return +mangopayVisaFeesBasisPoints;
  };
};

export const useGetCreditCardFees = () => {
  const getCreditCardsFeesBasisPoints = useGetCreditCardsFeesBasisPoints();
  return (cardType?: CreditCardBrand | CreditCardType | string | undefined) => {
    return getCreditCardsFeesBasisPoints(cardType) / 10000;
  };
};
