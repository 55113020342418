import { TypedDocumentNode, gql } from '@apollo/client';

import {
  CardCoverageStatus,
  So5LeaderboardSeasonality,
} from '@sorare/core/src/__generated__/globalTypes';
import { isType } from '@sorare/core/src/gql';

import { useCompetitionsEligibility_anyCard } from './__generated__/useCompetitionsEligibility.graphql';

type EligibleLeaderboard =
  useCompetitionsEligibility_anyCard['eligibleUpcomingLeagueTracks'][0]['so5Leaderboards'][0];

const sortCompetitions = (a: EligibleLeaderboard, b: EligibleLeaderboard) => {
  if (a.seasonality && !b.seasonality) {
    return -1;
  }
  if (!a.seasonality && b.seasonality) {
    return -1;
  }
  if (a.displayName < b.displayName) {
    return -1;
  }
  if (a.displayName > b.displayName) {
    return 1;
  }
  return 0;
};

const reduceLeaderboards = (
  rarityTyped: string,
  seasonalities: (So5LeaderboardSeasonality | null)[]
) => {
  return (acc: EligibleLeaderboard[], l: EligibleLeaderboard) => {
    if (
      l.mainRarityType === rarityTyped &&
      seasonalities.includes(l.seasonality) &&
      !acc.some(accL => accL.so5League.displayName === l.so5League.displayName)
    ) {
      acc.push(l);
    }
    return acc;
  };
};

const useCompetitionsEligibility = <
  T extends useCompetitionsEligibility_anyCard,
>(
  card: T
) => {
  if (
    isType(card, 'Card') &&
    card.coverageStatus === CardCoverageStatus.NOT_COVERED
  ) {
    return { inSeasonLeaderboards: [], classicCompetitions: [] };
  }
  const leaderboards = card.eligibleUpcomingLeagueTracks.flatMap(
    lt => lt.so5Leaderboards[0] || []
  );
  const inSeasonLeaderboards = card.inSeasonEligible
    ? leaderboards
        .reduce<
          T['eligibleUpcomingLeagueTracks'][0]['so5Leaderboards'][0][]
        >(reduceLeaderboards(card.rarityTyped, [So5LeaderboardSeasonality.IN_SEASON]), [])
        .sort(sortCompetitions)
    : [];
  const classicCompetitions = leaderboards
    .reduce<
      T['eligibleUpcomingLeagueTracks'][0]['so5Leaderboards'][0][]
    >(reduceLeaderboards(card.rarityTyped, [So5LeaderboardSeasonality.ALL_SEASONS, null]), [])
    .sort(sortCompetitions);
  return { inSeasonLeaderboards, classicCompetitions };
};

useCompetitionsEligibility.fragments = {
  anyCard: gql`
    fragment useCompetitionsEligibility_anyCard on AnyCardInterface {
      slug
      rarityTyped
      inSeasonEligible
      sport
      eligibleUpcomingLeagueTracks {
        slug
        displayName
        so5Leaderboards {
          slug
          displayName
          mainRarityType
          seasonality
          so5League {
            slug
            displayName
          }
        }
      }
      ... on Card {
        slug
        coverageStatus
      }
    }
  ` as TypedDocumentNode<useCompetitionsEligibility_anyCard>,
};

export default useCompetitionsEligibility;
