import { TypedDocumentNode, gql } from '@apollo/client';
import { Navigate, Outlet, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_SQUADSLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import {
  GetCurrentSeasonSlugQuery,
  GetCurrentSeasonSlugQueryVariables,
} from './__generated__/layout.graphql';
import { LayoutParams } from './__generated__/routeParams';

const GET_CURRENT_SEASON_SLUG = gql`
  query GetCurrentSeasonSlugQuery($userSlug: String!) {
    football {
      rivals {
        id
        currentSeason {
          slug
          seasonSquad(userSlug: $userSlug) {
            id
            squad {
              slug
            }
          }
        }
      }
    }
  }
` as TypedDocumentNode<
  GetCurrentSeasonSlugQuery,
  GetCurrentSeasonSlugQueryVariables
>;

const SquadLeaderboardLayout = () => {
  const { seasonSlug, squadSlug } = useTypedParams<LayoutParams>();

  const { data, loading } = useQuery(GET_CURRENT_SEASON_SLUG, {
    skip: !!seasonSlug && !!squadSlug,
  });

  if (seasonSlug && squadSlug) {
    return <Outlet />;
  }

  if (loading) {
    return <LoadingIndicator fullHeight />;
  }

  const { currentSeason } = data?.football.rivals || {};
  const { squad } = currentSeason?.seasonSquad || {};

  if (!currentSeason || !squad) {
    return null;
  }

  return (
    <Navigate
      to={generatePath(
        FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_SQUADSLUG,
        {
          seasonSlug: currentSeason.slug,
          squadSlug: squad.slug,
        }
      )}
    />
  );
};

export default SquadLeaderboardLayout;
