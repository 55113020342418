import { useIntl } from 'react-intl';

import { Sport } from '__generated__/globalTypes';
import { PrizeDumb } from 'components/landing/PrizesBlock/PrizeDumb';
import { messages } from 'components/landing/PrizesBlock/messages';

import cashRewards from './assets/cashRewards.jpg';

type Props = {
  isHovered?: boolean;
  sport: Sport;
};

const links: Record<Sport, string> = {
  [Sport.FOOTBALL]:
    'https://sorare.com/help/a/4402879613969/what-rewards-or-prizes-can-i-win-in-sorare-football',
  [Sport.BASEBALL]:
    'https://sorare.com/help/a/15119711065885/what-rewards-or-prizes-can-i-win-in-sorare-mlb',
  [Sport.NBA]:
    'https://sorare.com/help/a/15119600335901/what-rewards-or-prizes-can-i-win-in-sorare-nba',
};

export const CashPrize = ({ isHovered, sport }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <PrizeDumb
      title={formatMessage(
        {
          id: 'Landing.Prizes.money',
          defaultMessage: 'Cash{br}prizes',
        },
        { br: <br /> }
      )}
      subtitle={formatMessage({
        id: 'Landing.Prizes.moneySub',
        defaultMessage: 'Cash Rewards',
      })}
      isHovered={isHovered}
      cta={formatMessage(messages.learnCTA)}
      bgImage={cashRewards}
      link={links[sport]}
    />
  );
};
