import styled, { css, keyframes } from 'styled-components';

import { tabletAndAbove } from 'style/mediaQuery';

import { ContentContainer } from '../LandingMultiSport/ui';
import logos from './assets/logos.svg';
import logosMobile from './assets/logosMobile.svg';

// 453px is the width of the image so when the animation reset it's seamless
const animation = keyframes`
  0% {
    background-position: left;
  }
  100% {
    background-position: 453px;
  }
`;

const animationMobile = keyframes`
  0% {
    background-position: left;
  }
  100% {
    background-position: 327px;
  }
`;

const Wrapper = styled(ContentContainer)<{
  $backgroundImage: string;
  $mobileBackgroundImage: string;
}>`
  height: 100px;
  position: relative;
  overflow: hidden;
  ${props => css`
    background-image: url(${props.$mobileBackgroundImage});
  `}
  animation: ${animationMobile} 8s linear infinite;

  @media ${tabletAndAbove} {
    ${props => css`
      background-image: url(${props.$backgroundImage});
    `}
    animation: ${animation} 8s linear infinite;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    background: linear-gradient(
      to right,
      black,
      transparent 10%,
      transparent 90%,
      black 100%
    );
  }
`;

interface Props {
  backgroundImage?: string;
  mobileBackgroundImage?: string;
}

export const LogosTickerBlock = ({
  backgroundImage,
  mobileBackgroundImage,
}: Props) => {
  return (
    <Wrapper
      $backgroundImage={backgroundImage || logos}
      $mobileBackgroundImage={mobileBackgroundImage || logosMobile}
    />
  );
};
