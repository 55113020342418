import { TypedDocumentNode, gql } from '@apollo/client';
import { Navigate, generatePath, useParams } from 'react-router-dom';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { MLB_PLAY_PRO_FIXTURE_MY_LINEUPS } from '@sorare/core/src/constants/__generated__/routes';
import { randomizedPollInterval } from '@sorare/core/src/gql';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import {
  BaseballPlayProLayoutQuery,
  BaseballPlayProLayoutQueryVariables,
} from './__generated__/layout.graphql';

const BASEBALL_PLAY_PRO_LAYOUT_QUERY = gql`
  query BaseballPlayProLayoutQuery($slug: String) {
    so5 {
      so5Fixture(slug: $slug) {
        slug
      }
    }
  }
` as TypedDocumentNode<
  BaseballPlayProLayoutQuery,
  BaseballPlayProLayoutQueryVariables
>;

const POLL_INTERVAL = randomizedPollInterval(5);

const useProPlayLayoutQuery = (skip?: boolean) => {
  const { fixture: fixtureSlug } = useParams();
  const query = useQuery(BASEBALL_PLAY_PRO_LAYOUT_QUERY, {
    pollInterval: POLL_INTERVAL,
    variables: {
      slug: fixtureSlug,
    },
    skip,
  });

  return query;
};

const PlayProLayout = () => {
  const { data, loading } = useProPlayLayoutQuery();

  if (!data || (!data && loading)) {
    return <LoadingIndicator fullHeight />;
  }

  return (
    <Navigate
      to={generatePath(MLB_PLAY_PRO_FIXTURE_MY_LINEUPS, {
        fixture: 'ongoing',
      })}
      replace
    />
  );
};

export default PlayProLayout;
