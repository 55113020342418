import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { MLB_PLAY_PRO_FIXTURE_POSTSEASON_USERGROUP_USER } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

import { PageParams } from './__generated__/routeParams';

const PlayProFixturePostseasonUserGroupRedirectionPage = () => {
  const { fixture: fixtureSlug, userGroup: userGroupSlug } =
    useTypedParams<PageParams>();
  const { currentUser } = useCurrentUserContext();

  return (
    <Navigate
      to={generatePath(MLB_PLAY_PRO_FIXTURE_POSTSEASON_USERGROUP_USER, {
        fixture: fixtureSlug,
        userGroup: userGroupSlug,
        user: currentUser?.slug ?? '',
      })}
      replace
    />
  );
};

export default PlayProFixturePostseasonUserGroupRedirectionPage;
