import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';

export const Root = styled(Vertical).attrs({ gap: 2 })``;

export const FiltersContainer = styled.div`
  display: flex;
  gap: var(--unit);
`;

export const ItemsContainer = styled.div`
  width: 100%;
`;
