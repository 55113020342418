import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { useMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { useLazyGetPriceHistory } from 'hooks/useGetPriceHistory';

import { useCheckOfferFairness_tokenOffer } from './__generated__/useCheckOfferFairness.graphql';

const UNFAIR_OFFER_LIMIT_RATIO = 5;

export const useCheckOfferFairness = ({
  offer,
}: {
  offer: useCheckOfferFairness_tokenOffer;
}) => {
  const { cards, amounts } = offer.receiverSide;
  const { cards: cardsSent, amounts: amountsSent } = offer.senderSide;
  const [getPrices] = useLazyGetPriceHistory();
  const { toMonetaryAmount } = useMonetaryAmount();
  const getCardPrice = useCallback(
    async (
      card: useCheckOfferFairness_tokenOffer['senderSide']['cards'][number]
    ) => {
      const result = await getPrices({
        variables: {
          rarity: card.rarityTyped,
          playerSlug: card.anyPlayer.slug,
        },
      });
      if (
        !result?.data?.tokens?.tokenPrices ||
        result.data.tokens.tokenPrices.length === 0
      )
        return undefined;
      const avgPrice =
        (result?.data.tokens.tokenPrices || []).reduce(
          (acc, token) => acc + toMonetaryAmount(token.amounts).usd,
          0
        ) / result?.data?.tokens.tokenPrices.length;
      return avgPrice;
    },
    [getPrices, toMonetaryAmount]
  );

  const checkIsOfferUnfair = useCallback(async () => {
    const receiverCardsEstimations = await Promise.all(
      cards.map(async card => getCardPrice(card))
    );
    const senderCardsEstimations = await Promise.all(
      cardsSent.map(async card => getCardPrice(card))
    );

    if (
      !receiverCardsEstimations.some(item => item === undefined) &&
      !senderCardsEstimations.some(item => item === undefined)
    ) {
      const receiverCardsEstimation = receiverCardsEstimations.reduce(
        (acc, price) => acc! + price!,
        0
      );
      const senderCardsEstimation = senderCardsEstimations.reduce(
        (acc, price) => acc! + price!,
        0
      );
      const receiverLegEstimation =
        receiverCardsEstimation! + toMonetaryAmount(amounts).usd;
      const senderLegEstimation =
        senderCardsEstimation! + toMonetaryAmount(amountsSent).usd;
      if (
        receiverLegEstimation / senderLegEstimation >
          UNFAIR_OFFER_LIMIT_RATIO ||
        receiverLegEstimation / senderLegEstimation <
          1 / UNFAIR_OFFER_LIMIT_RATIO
      ) {
        return true;
      }
    }
    return false;
  }, [amounts, amountsSent, cards, cardsSent, getCardPrice, toMonetaryAmount]);

  return { checkIsOfferUnfair };
};

useCheckOfferFairness.fragments = {
  tokenOffer: gql`
    fragment useCheckOfferFairness_tokenOffer on TokenOffer {
      id
      senderSide {
        id
        wei
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        cards: anyCards {
          slug
          rarityTyped
          anyPlayer {
            slug
          }
        }
      }
      receiverSide {
        id
        wei
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        cards: anyCards {
          slug
          rarityTyped
          anyPlayer {
            slug
          }
        }
      }
    }
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<useCheckOfferFairness_tokenOffer>,
};
