import { TypedDocumentNode } from '@apollo/client';
import classNames from 'classnames';
import gqlMacro from 'graphql-tag.macro';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { catchAll } from '@sorare/routing';

import { Sport } from '__generated__/globalTypes';
import { Cards } from 'atoms/icons/Cards';
import { Home } from 'atoms/icons/Home';
import { Market } from 'atoms/icons/Market';
import { MyCards } from 'atoms/icons/MyCards';
import { PlayFootball } from 'atoms/icons/PlayFootball';
import { PlayMLB } from 'atoms/icons/PlayMLB';
import { PlayNBA } from 'atoms/icons/PlayNBA';
import { Badge } from 'atoms/ui/Badge';
import { LiveDot } from 'components/live/LiveDot';
import { ManagerTaskTooltip } from 'components/onboarding/managerTask/ManagerTaskTooltip';
import { MarketplaceOnboardingTask } from 'components/onboarding/managerTask/MarketplaceOnboardingTask';
import { ClubShield } from 'components/user/ClubShield';
import {
  FOOTBALL_CAMPAIGNS,
  FOOTBALL_MARKET,
  FOOTBALL_MY_CLUB_SLUG,
  FOOTBALL_PLAY,
  MLB_MARKET,
  MLB_MY_CLUB_SLUG,
  MLB_PLAY,
  NBA_MY_CLUB_SLUG,
  NBA_PLAY,
} from 'constants/__generated__/routes';
import { ANY_SPORT_MY_CARDS, NBA_MARKET } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import {
  MarketplaceOnboardingStep,
  useManagerTaskContext,
} from 'contexts/managerTask';
import { randomizedPollInterval } from 'gql';
import { useQuery } from 'hooks/graphql/useQuery';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { navLabels } from 'lib/glossary';
import { mergeCoinRewards } from 'lib/rewards';
import { generateSportPath } from 'lib/routing/generateSportPath';

import { NavItem } from '../NavItem';
import { MyClubOnboardingTooltip } from './MyClubOnboardingTooltip';
import {
  BaseballNavItemsQuery,
  BaseballNavItemsQueryVariables,
  FootballNavItemsQuery,
  FootballNavItemsQueryVariables,
  NBANavItemsQuery,
  NBANavItemsQueryVariables,
} from './__generated__/index.graphql';

const PlayLiveDotWrapper = styled.div`
  position: absolute;
  top: 0;
  right: calc(-1 * var(--unit));
  &.withBadge {
    top: unset;
    bottom: 0;
  }
`;

const messages = defineMessages({
  matchesInProgress: {
    id: 'FootballNavItems.matchesInProgress',
    defaultMessage: 'Matches in progress',
  },
});

const fragment = gqlMacro`
  fragment NavItems_currentUser on CurrentUser {
    slug
    unclaimedSo5Rewards(sport: $sport) {
      id
      slug
      coinAmount
      so5Fixture {
        slug
        endDate
      }
    }
    unopenedProbabilisticBundles(sport: $sport) {
      totalCount
    }
    managerProgressionTasksCount(
      sport: $sport
      type: MANAGER_PROGRESSION_CHALLENGES
      state: COMPLETED
    )
  }
`;

const NAV_ITEMS_QUERY_FOOTBALL = gqlMacro`
  query FootballNavItemsQuery($sport: Sport = FOOTBALL) {
    currentUser {
      slug
      ...NavItems_currentUser
    }
    football {
      currentManagerHasLiveGames
      rivals {
        id
        currentManager {
          id
          unreadStories {
            id
          }
        }
      }
    }
  }
  ${fragment}
` as TypedDocumentNode<FootballNavItemsQuery, FootballNavItemsQueryVariables>;

const NAV_ITEMS_QUERY_BASEBALL = gqlMacro`
  query BaseballNavItemsQuery($sport: Sport = BASEBALL) {
    currentUser {
      slug
      ...NavItems_currentUser
    }
    so5 {
      so5Fixture(sport: BASEBALL, type: LIVE) {
        slug
        mySo5LineupsCount
      }
    }
  }
  ${fragment}
` as TypedDocumentNode<BaseballNavItemsQuery, BaseballNavItemsQueryVariables>;

const NAV_ITEMS_QUERY_NBA = gqlMacro`
  query NBANavItemsQuery($sport: Sport = NBA) {
    currentUser {
      slug
      ...NavItems_currentUser
    }
    so5 {
      so5Fixture(sport: NBA, type: LIVE) {
        slug
        mySo5LineupsCount
      }
    }
  }
  ${fragment}
` as TypedDocumentNode<NBANavItemsQuery, NBANavItemsQueryVariables>;

const POLL_INTERVAL = randomizedPollInterval(5);

type Props = {
  sport: Sport;
};

const PAGES = {
  play: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY,
    [Sport.BASEBALL]: MLB_PLAY,
    [Sport.NBA]: NBA_PLAY,
  },
  market: {
    [Sport.FOOTBALL]: FOOTBALL_MARKET,
    [Sport.BASEBALL]: MLB_MARKET,
    [Sport.NBA]: NBA_MARKET,
  },
  myClub: {
    [Sport.FOOTBALL]: FOOTBALL_MY_CLUB_SLUG,
    [Sport.BASEBALL]: MLB_MY_CLUB_SLUG,
    [Sport.NBA]: NBA_MY_CLUB_SLUG,
  },
} as const;

const PLAY_ICONS_MAP = {
  [Sport.FOOTBALL]: PlayFootball,
  [Sport.BASEBALL]: PlayMLB,
  [Sport.NBA]: PlayNBA,
};

export const NavItems = ({ sport }: Props) => {
  const { currentUser, sportProfileBySport } = useCurrentUserContext();
  const { task, setStep } = useManagerTaskContext();
  const {
    flags: { enableMyClubSplit = false },
  } = useFeatureFlags();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  // Work-around a ApolloRouter limitation where we can't rely on `@include(if: $condition)` and
  // therefore split the query into multiple queries and skip them based on the sport.
  // https://github.com/apollographql/router/issues/2140#issuecomment-1652194194
  const { data: dataFootball } = useQuery(NAV_ITEMS_QUERY_FOOTBALL, {
    pollInterval: POLL_INTERVAL,
    skip: sport !== Sport.FOOTBALL,
  });
  const { data: dataBaseball } = useQuery(NAV_ITEMS_QUERY_BASEBALL, {
    pollInterval: POLL_INTERVAL,
    skip: sport !== Sport.BASEBALL,
  });
  const { data: dataNBA } = useQuery(NAV_ITEMS_QUERY_NBA, {
    pollInterval: POLL_INTERVAL,
    skip: sport !== Sport.NBA,
  });

  const data = {
    ...(dataFootball || {}),
    ...(dataBaseball || {}),
    ...(dataNBA || {}),
  };

  const showLiveDot = {
    [Sport.FOOTBALL]: !!data.football?.currentManagerHasLiveGames,
    [Sport.BASEBALL]: !!data.so5?.so5Fixture?.mySo5LineupsCount,
    [Sport.NBA]: !!data.so5?.so5Fixture?.mySo5LineupsCount,
  }[sport];

  const nbChallengesToClaim =
    data.currentUser?.managerProgressionTasksCount || 0;

  const nbRewards =
    mergeCoinRewards(data.currentUser?.unclaimedSo5Rewards || []).length +
    (data.currentUser?.unopenedProbabilisticBundles?.totalCount || 0);

  const playBadgeCount = {
    [Sport.FOOTBALL]:
      nbRewards +
      (data.football?.rivals?.currentManager?.unreadStories?.length || 0),
    [Sport.BASEBALL]: nbRewards + nbChallengesToClaim,
    [Sport.NBA]: nbRewards,
  }[sport];

  const PlayIcon = PLAY_ICONS_MAP[sport];

  return (
    <>
      <NavItem
        to={PAGES.play[sport]}
        discardHistory={!!playBadgeCount}
        matches={[catchAll(FOOTBALL_CAMPAIGNS)]}
      >
        {({ isActive, isPending }) => (
          <>
            <Badge
              badgeContent={playBadgeCount}
              aria-label={formatMessage(
                {
                  id: 'NavItems.playItemsToClaim',
                  defaultMessage:
                    '{count, plural, one {# item} other {# items}} to claim',
                },
                {
                  count: playBadgeCount,
                }
              )}
            >
              {enableMyClubSplit ? (
                <PlayIcon active={isActive || isPending} />
              ) : (
                <Home />
              )}
              {showLiveDot && sport !== Sport.NBA && (
                <PlayLiveDotWrapper
                  className={classNames({
                    withBadge: playBadgeCount > 0,
                  })}
                >
                  <LiveDot
                    animate
                    size="md"
                    label={formatMessage(messages.matchesInProgress)}
                  />
                </PlayLiveDotWrapper>
              )}
            </Badge>
            <FormattedMessage {...navLabels.play} />
          </>
        )}
      </NavItem>

      <ManagerTaskTooltip
        TaskComponent={MarketplaceOnboardingTask}
        onClick={() => {
          navigate(PAGES.market[sport]);
          setStep(MarketplaceOnboardingStep.managerSalesLink);
        }}
        disable={!task}
        name={MarketplaceOnboardingStep.menu}
      >
        <NavItem to={PAGES.market[sport]}>
          {({ isActive, isPending }) => (
            <>
              {enableMyClubSplit ? (
                <Market active={isActive || isPending} />
              ) : (
                <Cards size={2.5} />
              )}
              <FormattedMessage {...navLabels.transferMarket} />
            </>
          )}
        </NavItem>
      </ManagerTaskTooltip>

      {enableMyClubSplit ? (
        <NavItem to={generateSportPath(ANY_SPORT_MY_CARDS, { sport })}>
          {({ isActive, isPending }) => (
            <>
              <MyCards active={isActive || isPending} />
              <FormattedMessage {...navLabels.myCards} />
            </>
          )}
        </NavItem>
      ) : (
        <NavItem
          to={generatePath(PAGES.myClub[sport], {
            slug: currentUser?.slug || '',
          })}
        >
          <MyClubOnboardingTooltip>
            <ClubShield
              size={2.5}
              clubName={sportProfileBySport[sport]?.clubName}
              shieldUrl={sportProfileBySport[sport]?.clubBadge?.pictureUrl}
            />
          </MyClubOnboardingTooltip>
          <FormattedMessage {...navLabels.myClub} />
        </NavItem>
      )}
    </>
  );
};
