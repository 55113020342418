import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text14 } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { hideEmailDetails } from '@sorare/core/src/lib/privacy';

import Details from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/Details';
import ItemImagePreview from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/ItemImagePreview';

import { Congrats_shopItem } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 3 })`
  height: 100%;
  justify-content: space-around;
`;
const FlexColContainer = styled(Vertical).attrs({ center: true })`
  text-align: center;
  justify-content: stretch;
  & p {
    color: var(--c-neutral-600);
  }
`;

type Props = {
  item: Congrats_shopItem;
  inventory?: boolean;
  onClose: () => void;
};
const Congrats = ({ item, inventory, onClose }: Props) => {
  const { currentUser } = useCurrentUserContext();
  return (
    <Root>
      <FlexColContainer>
        <ItemImagePreview
          pictureUrl={item.pictureUrl}
          name={item.name}
          type={item.position}
        />
        <Details item={item} orderConfirmed />
      </FlexColContainer>
      <FlexColContainer>
        {inventory ? (
          <Text14>
            <FormattedMessage
              id="ItemPreviewDialog.Congrats.inventory"
              defaultMessage="Your order was confirmed. You should receive your item in a few weeks."
            />
          </Text14>
        ) : (
          <>
            <Text14>
              <FormattedMessage
                id="ItemPreviewDialog.Congrats.shop"
                defaultMessage="We will send you an email confirmation to {email} shortly."
                values={{ email: hideEmailDetails(currentUser!.email) }}
              />
            </Text14>
            <Text14>
              <FormattedMessage
                id="ItemPreviewDialog.Congrats.panopli"
                defaultMessage="Tracking updates will be provided via email by our dispatch partner, Panopli."
              />
            </Text14>
          </>
        )}
        <Button onClick={onClose} color="primary" size="medium">
          <FormattedMessage
            id="ItemPreviewDialog.Congrats.Cta.BackToClubShop"
            defaultMessage="Return to Club Shop"
          />
        </Button>
      </FlexColContainer>
    </Root>
  );
};

Congrats.fragments = {
  shopItem: gql`
    fragment Congrats_shopItem on ClubShopItem {
      ... on ShopItemInterface {
        id
        name
        position
        pictureUrl
      }
      ...Details_shopItem
    }
    ${Details.fragments.shopItem}
  ` as TypedDocumentNode<Congrats_shopItem>,
};

export default Congrats;
