import { defineMessages } from 'react-intl';

export const imageVariations = ['post', 'square', 'story'] as const;
export type ImageVariations = (typeof imageVariations)[number];

export const derivatives = {
  post: {
    height: 630,
    width: 1200,
  },
  square: {
    height: 1080,
    width: 1080,
  },
  story: {
    height: 1920,
    width: 1080,
  },
};

export const aspectRatios: Record<ImageVariations, `${number}/${number}`> = {
  post: `${derivatives.post.width}/${derivatives.post.height}`,
  square: `${derivatives.square.width}/${derivatives.square.height}`,
  story: `${derivatives.story.width}/${derivatives.story.height}`,
};

export const socialSharingMessages = defineMessages({
  lineup: {
    id: 'SocialSharing.lineup',
    defaultMessage: 'Check out that lineup',
  },
  myLineup: {
    id: 'SocialSharing.myLineup',
    defaultMessage: 'Check out my lineup',
  },
  card: {
    id: 'SocialSharing.card',
    defaultMessage: 'Check out this card {link}',
  },
});
