import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';

import {
  Position,
  Rarity,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import usePaginatedQuery from '@sorare/core/src/hooks/graphql/usePaginatedQuery';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import useInfiniteScroll from '@sorare/core/src/hooks/useInfiniteScroll';
import { blockchainRarities } from '@sorare/core/src/lib/cards';
import { positionNames } from '@sorare/core/src/lib/players';
import { positionsBySport } from '@sorare/core/src/lib/positions';

import { DeckEditorDialog as GenericDeckEditorDialog } from 'searchCards/CustomDecksFilter/DeckEditorDialog';

import {
  CardsForDeckQuery,
  CardsForDeckQueryVariables,
  DeckEditorQuery,
  DeckEditorQueryVariables,
} from './__generated__/index.graphql';

const DECK_EDITOR_QUERY = gql`
  query DeckEditorQuery($slug: String!, $sport: Sport!) {
    currentUser {
      slug
      userSportProfile: anyUserSportProfile(sport: $sport) {
        id
        deck(slug: $slug) {
          slug
          tokensCount
          ...DeckEditorDialog_deck
        }
      }
    }
  }
  ${GenericDeckEditorDialog.fragments.deck}
` as TypedDocumentNode<DeckEditorQuery, DeckEditorQueryVariables>;

const CARDS_FOR_DECK_QUERY = gql`
  query CardsForDeckQuery(
    $positions: [Position!]
    $rarities: [Rarity!]
    $deck: String
    $cursor: String
    $sport: Sport
  ) {
    currentUser {
      slug
      cards(
        positions: $positions
        rarities: $rarities
        withinDeckFirst: $deck
        after: $cursor
        sport: $sport
      ) {
        nodes {
          slug
          ...DeckEditorDialog_anyCard
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${GenericDeckEditorDialog.fragments.anyCard}
` as TypedDocumentNode<CardsForDeckQuery, CardsForDeckQueryVariables>;

const BLOCKCHAIN_RARITIES = [...blockchainRarities] as Rarity[];

export const DeckEditorDialog = ({
  slug: initialSlug,
  defaultName,
  onClose,
  sport,
}: {
  slug?: string;
  defaultName?: string;
  onClose: () => void;
  sport: Sport;
}) => {
  const [slug, setSlug] = useState<string | undefined>(initialSlug);
  const [selectedPosition, setSelectedPosition] = useState<Position>();

  const { data: deckData } = useQuery(DECK_EDITOR_QUERY, {
    variables: { slug: slug!, sport },
    skip: !slug,
  });

  const {
    data: cardData,
    loading,
    loadMore,
  } = usePaginatedQuery(CARDS_FOR_DECK_QUERY, {
    variables: {
      positions: selectedPosition ? [selectedPosition] : null,
      rarities: BLOCKCHAIN_RARITIES,
      deck: slug,
      sport,
    },
    connection: 'AnyCardInterfaceConnection',
  });

  const hasNextPage = Boolean(
    cardData?.currentUser?.cards?.pageInfo?.hasNextPage
  );

  const { InfiniteScrollLoader } = useInfiniteScroll(
    () => {
      loadMore(false, {
        cursor: cardData?.currentUser?.cards?.pageInfo.endCursor,
        positions: selectedPosition ? [selectedPosition] : null,
      });
    },
    hasNextPage,
    loading
  );

  const deck = deckData?.currentUser?.userSportProfile?.deck;
  const cards = cardData?.currentUser?.cards.nodes;

  if (initialSlug && slug && !deck) {
    return null;
  }

  return (
    <GenericDeckEditorDialog
      deck={deck}
      defaultName={defaultName}
      sport={sport}
      cards={cards || []}
      tokensLoading={loading}
      infiniteScrollLoader={<InfiniteScrollLoader />}
      positions={positionsBySport[sport]}
      positionsMessages={positionNames}
      selectedPosition={selectedPosition}
      onSelectedPositionChange={pos => setSelectedPosition(pos)}
      onClose={onClose}
      onCreateDeck={newSlug => setSlug(newSlug)}
    />
  );
};

export default DeckEditorDialog;
