import { useCallback } from 'react';

import { Currency } from '__generated__/globalTypes';
import {
  MonetaryInput,
  Props as MonetaryInputProps,
} from 'atoms/inputs/MonetaryInput';
import { useCurrentUserContext } from 'contexts/currentUser';
import { MonetaryAmountOutput } from 'hooks/useMonetaryAmount';

import Field, { Props as FieldProps, RenderProps } from '../Field';

type Props = {
  name: string;
  defaultCurrency?: Currency;
  onChange?: (monetaryAmount: MonetaryAmountOutput) => void;
  onToggleEthDisplay?: (ethDisplay: boolean) => void;
  placeholder?: string;
} & Omit<FieldProps<MonetaryAmountOutput>, 'render'> &
  Omit<
    MonetaryInputProps,
    'fiatCurrency' | 'onChange' | 'onlyShowFiatCurrency'
  >;

export const MonetaryInputField = ({
  name,
  defaultValue,
  onChange,
  validate,
  ...rest
}: Props) => {
  const {
    fiatCurrency: { code: currencyCode },
    walletPreferences: { onlyShowFiatCurrency },
  } = useCurrentUserContext();

  const render = useCallback(
    ({ error, handleChange, disabled }: RenderProps<MonetaryAmountOutput>) => {
      return (
        <MonetaryInput
          defaultValue={defaultValue}
          fiatCurrency={currencyCode}
          onChange={(newMonetaryAmount, target) => {
            onChange?.(newMonetaryAmount);
            handleChange(newMonetaryAmount, target);
          }}
          onlyShowFiatCurrency={onlyShowFiatCurrency}
          error={error}
          {...rest}
          disabled={disabled || rest.disabled}
        />
      );
    },
    [currencyCode, defaultValue, onlyShowFiatCurrency, rest, onChange]
  );

  return (
    <Field
      defaultValue={defaultValue}
      name={name}
      validate={validate}
      render={render}
    />
  );
};

export default MonetaryInputField;
