import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import {
  MLB_CARDS_SLUG,
  MLB_MARKET,
  MLB_MARKET_INSTANT_BUY,
  MLB_MARKET_PRIMARY,
  MLB_MARKET_SECONDARY,
  MLB_MARKET_SECONDARY_PLAYERSLUG_RARITY,
  MLB_MY_CLUB_SLUG_CARDS,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_TEAMS_SLUG_CARDS,
} from '@sorare/core/src/constants/__generated__/routes';

import { MarketSubscriptions } from '@sorare/marketplace/src/components/MarketSubscriptions';

const AUCTION_SUBSCRIPTION_ROUTES = [
  MLB_MARKET,
  MLB_MARKET_PRIMARY,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_CARDS_SLUG,
  MLB_TEAMS_SLUG_CARDS,
  MLB_MY_CLUB_SLUG_CARDS,
];

const OFFERS_SUBSCRIPTION_ROUTES = [
  MLB_MARKET,
  MLB_MARKET_SECONDARY,
  MLB_MARKET_SECONDARY_PLAYERSLUG_RARITY,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_CARDS_SLUG,
  MLB_TEAMS_SLUG_CARDS,
  MLB_MY_CLUB_SLUG_CARDS,
];

const PRIMARY_OFFERS_SUBSCRIPTION_ROUTES = [
  MLB_MARKET_INSTANT_BUY,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_CARDS_SLUG,
];

export const MLBMarketSubscriptions = () => {
  return (
    <MarketSubscriptions
      sports={[Sport.BASEBALL]}
      auctionsRoutes={AUCTION_SUBSCRIPTION_ROUTES}
      offersRoutes={OFFERS_SUBSCRIPTION_ROUTES}
      primaryOffersRoutes={PRIMARY_OFFERS_SUBSCRIPTION_ROUTES}
    />
  );
};
