import { useIntl } from 'react-intl';

import { Rarity } from '__generated__/globalTypes';
import { scarcityNames } from 'lib/cards';
import { glossary } from 'lib/glossary';

type Props = { quantity?: number; rewardName: string };
export const RewardCount = ({ quantity = 1, rewardName }: Props) => {
  return `${quantity}× ${rewardName}`;
};

export const EssenceRewardCount = ({
  quantity = 1,
  rarity,
}: {
  quantity?: number;
  rarity?: Rarity;
}) => {
  const { formatMessage } = useIntl();
  return (
    <RewardCount
      quantity={quantity}
      rewardName={
        rarity
          ? formatMessage(glossary.essenceRarityLabel, {
              quantity,
              rarity: scarcityNames[rarity],
            })
          : formatMessage(glossary.essenceLabel, { quantity })
      }
    />
  );
};
