import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { Text16 } from 'atoms/typography';

const Text = styled.div`
  color: var(--c-brand-600);
  width: 100%;
`;

const Row = styled(Horizontal)`
  gap: var(--intermediate-unit);
  &.column {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    gap: var(--half-unit);
    text-align: left;

    > ${Text} {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      gap: var(--half-unit);
      row-gap: 0;
    }
  }
`;

const Balance = styled.div`
  display: flex;
  gap: var(--unit);
`;

type Props = {
  icon: ReactNode;
  label: ReactNode;
  balance: ReactNode;
  withoutBalance?: boolean;
  column?: boolean;
};

export const SorareWallet = ({
  icon,
  label,
  balance,
  withoutBalance,
  column,
}: Props) => {
  return (
    <Row className={classNames({ column })}>
      {icon}
      <Text>
        <Text16 color="var(--c-neutral-1000)">{label}</Text16>
        {!withoutBalance && <Balance>{balance}</Balance>}
      </Text>
    </Row>
  );
};
