import { useMedia } from 'react-use';

import { useIsMobile } from 'hooks/device/useIsMobile';

export const useMediaCardsPerPage = () => {
  const isMobile = useIsMobile();
  const is1400AndAbove = useMedia('(min-width: 1400px)');
  const is1600AndAbove = useMedia('(min-width: 1600px)');
  const is2000AndAbove = useMedia('(min-width: 2000px)');

  if (process.env.NODE_ENV === 'development') {
    // in dev, too many cards loaded at once are anyway above 30k complexity limit
    return 20;
  }

  if (isMobile) return 20; // 1 column

  if (!is1400AndAbove) return 36; // 2, 3 columns

  if (is1400AndAbove && !is1600AndAbove) return 40; // 4, 5 columns

  if (is1600AndAbove && !is2000AndAbove) return 42; // 6, 7 columns

  return 40; // 8, 10 columns
};
