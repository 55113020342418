import { TypedDocumentNode, gql } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text14, Text16 } from '@sorare/core/src/atoms/typography';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import { UserName } from '@sorare/core/src/components/user/UserName';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { isType } from '@sorare/core/src/gql';
import {
  useMonetaryAmount,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { tradeLabels } from '@sorare/core/src/lib/glossary';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import MonetaryCard from 'components/offer/MonetaryCard';
import { useCalculateDirectOfferMarketFee } from 'hooks/offers/useCalculateDirectOfferMarketFee';
import useMarketFeesHelperStatus, {
  isMarketFeeEnabled,
} from 'hooks/useMarketFeesHelperStatus';

import CardOffer from '../../CardOffer';
import CardsChanged from '../CardsChanged';
import { useGetCardsDetails } from '../useGetCardsDetails';
import {
  MySorareDirectOfferBody_anyCard,
  MySorareDirectOfferBody_publicUserInfoInterface,
  MySorareDirectOfferBody_tokenOffer,
} from './__generated__/index.graphql';

const messages = defineMessages({
  nothing: {
    id: 'DirectOffer.nothing',
    defaultMessage: 'NOTHING',
  },
  to: {
    id: 'DirectOffer.to',
    defaultMessage: 'To',
  },
  from: {
    id: 'DirectOffer.from',
    defaultMessage: 'From',
  },
  sendingEth: {
    id: 'DirectOffer.sendingMoney',
    defaultMessage:
      '⚠️ You are about to send money to a manager named "{nickname}".',
  },
  cardAdded: {
    id: 'DirectOffer.cardAdded',
    defaultMessage: 'This card has been added to the trade',
  },
});

type Props = {
  offer: MySorareDirectOfferBody_tokenOffer;
  counterpartUser: MySorareDirectOfferBody_publicUserInfoInterface;
  isCurrentUserSender: boolean;
  validationMessages?: Record<string, ReactNode>;
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--double-unit);
  @media ${tabletAndAbove} {
    flex-direction: row;
  }
`;

const Column = styled(Vertical)`
  flex: 1;
`;

const BodyTitle = styled(Text16)`
  display: flex;
  flex-direction: row;
  gap: var(--unit);
  color: var(--c-neutral-600);
`;

const Frame = styled.div`
  padding: var(--double-unit);
  border-radius: var(--unit);
  border: 1px solid var(--c-neutral-400);
  background: var(--c-neutral-300);
  .inModale & {
    background: var(--c-neutral-400);
  }
`;

const Warning = styled.div`
  padding: var(--unit) var(--double-unit);
  border-radius: var(--unit);
  border: solid 1px;
  &.red {
    background-color: rgba(var(--c-rgb-red-600), 0.05);
    border-color: var(--c-red-600);
  }
  &.grey {
    background-color: var(--c-neutral-300);
    color: var(--c-neutral-600);
    border-color: var(--c-neutral-400);
  }
`;

const ValidationMessageWrapper = styled.div`
  margin-top: var(--unit);
  color: initial;
`;

const CardChanged = styled(Text14)`
  display: flex;
  align-items: center;
  gap: var(--unit);
  margin-top: var(--double-unit);
  border: 1px solid var(--c-yellow-800);
  background-color: rgba(var(--c-rgb-yellow-300), 0.05);
  border-radius: var(--unit);
  padding: var(--half-unit) var(--unit);
`;

const DirectOfferBody = ({
  offer,
  counterpartUser,
  isCurrentUserSender,
  validationMessages,
}: Props) => {
  const { toMonetaryAmount } = useMonetaryAmount();
  const {
    currentUser,
    fiatCurrency: { code },
  } = useCurrentUserContext();
  const getCardsDetails = useGetCardsDetails();
  const { receivedCards, sendCards } =
    getCardsDetails<MySorareDirectOfferBody_anyCard>(
      offer,
      isCurrentUserSender
    );
  const getMarketFeeAmount = useCalculateDirectOfferMarketFee();

  const {
    sender,
    senderSide,
    marketFeeAmounts,
    receiver,
    receiverSide,
    counteredOffer,
  } = offer;

  const marketFeeStatusForReceiverSideNfts = useMarketFeesHelperStatus(
    receiverSide.cards
  );
  const isFeeEnabledForReceiverSideNfts = isMarketFeeEnabled(
    marketFeeStatusForReceiverSideNfts
  );
  const calculatedMarketFeeForSender =
    (isFeeEnabledForReceiverSideNfts &&
      getMarketFeeAmount(
        toMonetaryAmount(senderSide.amounts),
        code as SupportedCurrency
      )) ||
    zeroMonetaryAmount;

  const marketFeeStatusForSenderSideNfts = useMarketFeesHelperStatus(
    senderSide.cards
  );
  const isFeeEnabledForSenderSideNfts = isMarketFeeEnabled(
    marketFeeStatusForSenderSideNfts
  );
  const calculatedMarketFeeForReceiver =
    (isFeeEnabledForSenderSideNfts &&
      getMarketFeeAmount(
        toMonetaryAmount(receiverSide.amounts),
        code as SupportedCurrency
      )) ||
    zeroMonetaryAmount;

  if (!isType(sender, 'User') || !receiver || !isType(receiver, 'User')) {
    return null;
  }

  const marketFeeMonetaryAmountFromGql = marketFeeAmounts
    ? toMonetaryAmount(marketFeeAmounts)
    : null;

  const senderMonetaryAmount = toMonetaryAmount(senderSide.amounts);
  const displaySenderMonetaryAmount = senderMonetaryAmount.eur !== 0;
  const renderSendAmount = (displayFees: boolean) =>
    displaySenderMonetaryAmount && (
      <Frame>
        <MonetaryCard
          amount={senderSide.amounts}
          marketFeeAmount={
            marketFeeMonetaryAmountFromGql || calculatedMarketFeeForSender
          }
          displayFees={displayFees}
          marketFeeStatus={marketFeeStatusForReceiverSideNfts}
        />
      </Frame>
    );

  const receiverMonetaryAmount = toMonetaryAmount(receiverSide.amounts);
  const displayReceiverAmount = receiverMonetaryAmount.eur !== 0;
  const renderReceiveAmount = (displayFees: boolean) =>
    displayReceiverAmount && (
      <Frame>
        <MonetaryCard
          amount={receiverSide.amounts}
          marketFeeAmount={
            marketFeeMonetaryAmountFromGql || calculatedMarketFeeForReceiver
          }
          displayFees={displayFees}
          marketFeeStatus={marketFeeStatusForSenderSideNfts}
        />
      </Frame>
    );

  const sendAmountToDisplay = isCurrentUserSender
    ? renderSendAmount(false)
    : renderReceiveAmount(false);

  const hasSendToDisplay = sendAmountToDisplay || sendCards.length;

  const receiveAmountToDisplay = isCurrentUserSender
    ? renderReceiveAmount(true)
    : renderSendAmount(true);

  const hasReceivedToDisplay = receiveAmountToDisplay || receivedCards.length;

  const otherUser = counterpartUser.slug === sender.slug ? receiver : sender;

  const counteredOfferCards = counteredOffer
    ? getCardsDetails(counteredOffer, !isCurrentUserSender)
    : undefined;

  return (
    <>
      {counteredOffer && (
        <CardsChanged
          offer={offer}
          counterpartUser={counterpartUser}
          isCurrentUserSender={isCurrentUserSender}
        />
      )}
      <Body>
        <Column>
          <BodyTitle>
            {otherUser.slug === currentUser?.slug ? (
              <FormattedMessage {...tradeLabels.youSend} />
            ) : (
              <FormattedMessage
                {...tradeLabels.usernameSends}
                values={{
                  b: Bold,
                  username: <UserName user={otherUser} />,
                }}
              />
            )}
          </BodyTitle>
          <Vertical>
            {hasSendToDisplay ? (
              <>
                {[...sendCards]
                  .sort(card => {
                    if (!counteredOfferCards) return 0;
                    if (
                      !counteredOfferCards.receivedCards.find(
                        c => c.slug === card.slug
                      )
                    )
                      return -1;
                    return 1;
                  })
                  .map(card => (
                    <CardOffer item={card} key={card.slug}>
                      {validationMessages?.[card.slug] && (
                        <ValidationMessageWrapper>
                          {validationMessages[card.slug]}
                        </ValidationMessageWrapper>
                      )}
                      {counteredOfferCards &&
                        !counteredOfferCards.sendCards.find(
                          c => c.slug === card.slug
                        ) && (
                          <CardChanged>
                            <FontAwesomeIcon
                              color="var(--c-yellow-600)"
                              icon={faInfoCircle}
                            />
                            <FormattedMessage {...messages.cardAdded} />
                          </CardChanged>
                        )}
                    </CardOffer>
                  ))}
                {sendAmountToDisplay}
              </>
            ) : (
              <Warning className="grey">
                <FormattedMessage {...messages.nothing} />
              </Warning>
            )}
            {sendAmountToDisplay &&
              offer.status === 'opened' &&
              !isCurrentUserSender && (
                <Warning className="red">
                  <FormattedMessage
                    {...messages.sendingEth}
                    values={{
                      nickname: (
                        <em>
                          <UserName user={counterpartUser} />
                        </em>
                      ),
                    }}
                  />
                </Warning>
              )}
          </Vertical>
        </Column>
        <Column>
          <BodyTitle>
            <FormattedMessage
              {...tradeLabels.usernameSends}
              values={{
                b: Bold,
                username: <UserName user={counterpartUser} />,
              }}
            />
          </BodyTitle>
          <Vertical>
            {hasReceivedToDisplay ? (
              <>
                {[...receivedCards]
                  .sort(a => {
                    if (!counteredOfferCards) return 0;
                    if (
                      !counteredOfferCards.receivedCards.find(
                        card => card.slug === a.slug
                      )
                    )
                      return -1;
                    return 1;
                  })
                  .map(card => (
                    <CardOffer key={card.slug} item={card}>
                      {counteredOfferCards &&
                        !counteredOfferCards.receivedCards.find(
                          c => c.slug === card.slug
                        ) && (
                          <CardChanged>
                            <FontAwesomeIcon
                              color="var(--c-yellow-600)"
                              icon={faInfoCircle}
                            />
                            <FormattedMessage {...messages.cardAdded} />
                          </CardChanged>
                        )}
                    </CardOffer>
                  ))}
                {receiveAmountToDisplay}
              </>
            ) : (
              <Warning className="red">
                <Text16>
                  <FormattedMessage {...messages.nothing} />
                </Text16>
              </Warning>
            )}
          </Vertical>
        </Column>
      </Body>
    </>
  );
};

const anyCardFragment = gql`
  fragment MySorareDirectOfferBody_anyCard on AnyCardInterface {
    slug
    assetId
    collection
    ...useMarketFeesHelperStatus_anyCard
    ...CardOffer_anyCard
  }
  ${CardOffer.fragments.anyCard}
  ${useMarketFeesHelperStatus.fragments.anyCard}
` as TypedDocumentNode<MySorareDirectOfferBody_anyCard>;

DirectOfferBody.fragments = {
  token: anyCardFragment,
  tokenOffer: gql`
    fragment MySorareDirectOfferBody_tokenOffer on TokenOffer {
      id
      status
      marketFeeAmounts {
        ...MonetaryAmountFragment_monetaryAmount
      }
      sender {
        ...UserName_publicUserInfoInterface
      }
      senderSide {
        id
        wei
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        cards: anyCards {
          slug
          ...MySorareDirectOfferBody_anyCard
        }
      }
      receiver {
        ...UserName_publicUserInfoInterface
      }
      receiverSide {
        id
        wei
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        cards: anyCards {
          slug
          ...MySorareDirectOfferBody_anyCard
        }
      }
      counteredOffer {
        id
        senderSide {
          id
          cards: anyCards {
            slug
            ...MySorareDirectOfferBody_anyCard
          }
        }
        receiverSide {
          id
          cards: anyCards {
            slug
            ...MySorareDirectOfferBody_anyCard
          }
        }
      }
      ...CardsChanged_tokenOffer
    }
    ${anyCardFragment}
    ${monetaryAmountFragment}
    ${UserName.fragments.user}
    ${CardsChanged.fragments.tokenOffer}
  ` as TypedDocumentNode<MySorareDirectOfferBody_tokenOffer>,
  user: gql`
    fragment MySorareDirectOfferBody_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      ...UserName_publicUserInfoInterface
      ...CardsChanged_user
    }
    ${UserName.fragments.user}
    ${CardsChanged.fragments.user}
  ` as TypedDocumentNode<MySorareDirectOfferBody_publicUserInfoInterface>,
};

export default DirectOfferBody;
