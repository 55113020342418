import './fa.css';
/* eslint-disable no-restricted-imports */
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { FontAwesomeIconProps as FAIP } from '@fortawesome/react-fontawesome';
/* eslint-enable no-restricted-imports */
import { forwardRef, useId } from 'react';

export type { IconDefinition };

export type FontAwesomeIconProps = Pick<
  FAIP,
  | 'aria-label'
  | 'className'
  | 'color'
  | 'fontSize'
  | 'height'
  | 'size'
  | 'title'
  | 'width'
> & {
  icon: IconDefinition;
};

export const FontAwesomeIcon = forwardRef<SVGSVGElement, FontAwesomeIconProps>(
  function FontAwesomeIcon(props, ref) {
    const id = useId();

    return (
      <svg
        ref={ref}
        aria-labelledby={props.title ? id : undefined}
        aria-hidden={props.title ? undefined : 'true'}
        aria-label={props['aria-label']}
        focusable={props.title ? undefined : 'false'}
        data-prefix={props.icon.prefix}
        data-icon={props.icon.iconName}
        className={[
          'svg-inline--fa',
          `fa-${props.icon.iconName}`,
          props.size ? `fa-${props.size}` : null,
          props.className ?? '',
        ]
          .filter(e => e != null)
          .join(' ')}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${props.icon.icon[0]} ${props.icon.icon[1]}`}
        width={props.width}
        height={props.height}
        fontSize={props.fontSize}
        color={props.color}
      >
        {props.title ? <title id={id}>{props.title}</title> : null}
        <path fill="currentColor" d={props.icon.icon[4] as string} />
      </svg>
    );
  }
);
