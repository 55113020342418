import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';

import { BuyButton } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/BuyButton';
import { useOnBuyButtonClick } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/BuyButton/useOnBuyButtonClick';
import Details from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/Details';
import ItemImagePreview from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/ItemImagePreview';
import { Requirements } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/Requirements';

import { DefaultContent_clubShopItem } from './__generated__/index.graphql';

type Props = {
  item: DefaultContent_clubShopItem;
  itemEquipped?: boolean;
  inventory: boolean;
  onBuy?: () => void;
  userCoinBalance: number;
};

export const DefaultContent = ({
  item,
  itemEquipped,
  inventory,
  onBuy,
  userCoinBalance,
}: Props) => {
  const [currentStep, setCurrentStep] = useState<'step1' | 'step2'>(
    inventory || item.myPurchasesCount > 0 ? 'step2' : 'step1'
  );
  const { onBuyButtonClick, loading } = useOnBuyButtonClick(
    () => setCurrentStep('step2'),
    onBuy
  );

  return (
    <>
      <ItemImagePreview
        pictureUrl={item.pictureUrl}
        name={item.name}
        type={item.position}
      />
      <Details item={item} itemEquipped={itemEquipped} inventory={inventory} />
      {currentStep === 'step1' && (
        <>
          {item.disabled && <Requirements item={item} />}
          <BuyButton
            item={item}
            onClick={() => onBuyButtonClick(item.id)}
            loading={loading}
            userCoinBalance={userCoinBalance}
          />
        </>
      )}
    </>
  );
};

DefaultContent.fragments = {
  clubShopItem: gql`
    fragment DefaultContent_clubShopItem on ClubShopItem {
      ... on ShopItemInterface {
        id
        pictureUrl
        name
        position
        myPurchasesCount
        myLimitResetAt
        disabled
      }
      ...Details_shopItem
      ...Requirements_clubShopItem
      ...BuyButton_clubShopItem
    }
    ${Details.fragments.shopItem}
    ${Requirements.fragments.clubShopItem}
    ${BuyButton.fragments.clubShopItem}
  ` as TypedDocumentNode<DefaultContent_clubShopItem>,
};
