import { defineMessages, useIntl } from 'react-intl';

import { unitMapping } from '@sorare/core/src/lib/style';

import sub_in from './assets/sub_in.svg';
import sub_out from './assets/sub_out.svg';

const titles = defineMessages({
  sub_in: {
    id: 'SubIcon.titles.subIn',
    defaultMessage: 'Sub In',
  },
  sub_out: {
    id: 'SubIcon.titles.subOut',
    defaultMessage: 'Sub Out',
  },
});

type Props = {
  type: keyof typeof titles;
  size?: keyof typeof unitMapping;
};
export const SubIcon = ({ type, size = 2 }: Props) => {
  const { formatMessage } = useIntl();

  const image = type === 'sub_in' ? sub_in : sub_out;

  return (
    <img
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      src={image}
      alt=""
      title={formatMessage(titles[type])}
    />
  );
};
