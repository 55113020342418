import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { LabelM } from '@sorare/core/src/atoms/typography';
import { ManagerTaskTooltip } from '@sorare/core/src/components/onboarding/managerTask/ManagerTaskTooltip';
import {
  MarketplaceOnboardingTask,
  marketplaceTaskDescription,
} from '@sorare/core/src/components/onboarding/managerTask/MarketplaceOnboardingTask';
import { useConfigContext } from '@sorare/core/src/contexts/config';
import {
  MarketplaceOnboardingStep,
  useManagerTaskContext,
} from '@sorare/core/src/contexts/managerTask';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { useFacetedSearchCards } from '@sorare/core/src/hooks/search/useFacetedSearchCards';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { zeroMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { StackProps, assetIdFromHit } from '@sorare/core/src/lib/algolia';
import { groupBy } from '@sorare/core/src/lib/arrays';
import { formatScarcity } from '@sorare/core/src/lib/cards';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { livePrimaryOffer } from '@sorare/core/src/lib/token';

import { TokenContent } from './TokenContent';
import {
  AlternativeStackedTokensByIdsQuery,
  AlternativeStackedTokensByIdsQueryVariables,
  Token_anyCard,
} from './__generated__/index.graphql';

export type Props = {
  card: Token_anyCard;
  hideOwner?: boolean;
  disableSportSpecific?: boolean;
  displayMarketplaceOnboardingTooltip?: boolean;
  forceMobileLayout?: boolean;
  forceDesktopLayout?: boolean;
  galleryOwnerSlug?: string;
  hideSorareUser?: boolean;
  stack?: StackProps;
  onInfoClick?: () => void;
  onSuccess?: () => void;
  hideDetails?: boolean;
  action?: ReactNode;
  onlyPrimary: boolean;
};

const cardFragment = gql`
  fragment Token_anyCard on AnyCardInterface {
    slug
    assetId
    liveSingleSaleOffer @skip(if: $onlyPrimary) {
      id
      receiverSide {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
    }
    ...TokenContent_anyCard
  }
  ${monetaryAmountFragment}
  ${TokenContent.fragments.anyCard}
` as TypedDocumentNode<Token_anyCard>;

export const ALTERNATIVE_STACKED_TOKENS_BY_IDS_QUERY = gql`
  query AlternativeStackedTokensByIdsQuery(
    $assetIds: [String!]!
    $onlyPrimary: Boolean = false
    $googlePlayStoreCountryCode: String
    $isAndroidApp: Boolean = false
    $isIosApp: Boolean = false
  ) {
    anyCards(assetIds: $assetIds) {
      slug
      ...Token_anyCard
    }
  }
  ${cardFragment}
` as TypedDocumentNode<
  AlternativeStackedTokensByIdsQuery,
  AlternativeStackedTokensByIdsQueryVariables
>;

const ATTRIBUTES_TO_RETRIEVE = ['objectID', 'asset_id', 'sport', 'slug'];

const TooltipDescription = ({ card }: { card: NonNullable<Token_anyCard> }) => {
  const { main } = useAmountWithConversion({
    monetaryAmount:
      card.liveSingleSaleOffer?.receiverSide.amounts || zeroMonetaryAmount,
  });

  return (
    <LabelM color="var(--c-neutral-600)">
      <FormattedMessage
        {...marketplaceTaskDescription[
          MarketplaceOnboardingStep.marketplaceItem
        ]}
        values={{
          playerName: card.anyPlayer.displayName,
          price: main,
          rarity: formatScarcity(card.rarityTyped),
        }}
      />
    </LabelM>
  );
};

export const Token = ({
  card,
  stack,
  displayMarketplaceOnboardingTooltip,
  hideDetails,
  action,
  ...rest
}: Props) => {
  const { step, setStep, task } = useManagerTaskContext();
  const { up: isTablet } = useScreenSize('tablet');
  const { algoliaCardIndexes } = useConfigContext();

  const stacked = stack && stack.count > 1;
  const isStackedOrLiveOffer =
    !stacked || !!card.liveSingleSaleOffer || livePrimaryOffer(card);

  const { results: lowestPriceHits } = useFacetedSearchCards<{
    objectID: string;
    asset_id: string;
    sport: string;
  }>({
    index: algoliaCardIndexes['Cards On Sale Lowest Price'],
    distinct: false,
    facetFilters: `sale.distinct_key:"${stack?.algoliaDistinctKey}"`,
    attributesToRetrieve: ATTRIBUTES_TO_RETRIEVE,
    hitsPerPage: 20,
    params: stack?.params,
    skip: isStackedOrLiveOffer,
  });
  const { isIosApp, isAndroidApp, googlePlayStoreCountryCode } =
    useIsMobileApp();

  const lowestPriceAssetIds = useMemo(
    () =>
      lowestPriceHits?.hits.map(hit => assetIdFromHit(hit)).filter(Boolean) ||
      [],
    [lowestPriceHits?.hits]
  );

  const { data } = useQuery(ALTERNATIVE_STACKED_TOKENS_BY_IDS_QUERY, {
    variables: {
      assetIds: lowestPriceAssetIds,
      isIosApp,
      isAndroidApp,
      googlePlayStoreCountryCode,
    },
    skip: !lowestPriceHits || lowestPriceHits.length === 0,
  });

  const actualCard = useMemo(() => {
    if (!lowestPriceHits || !data) {
      return card;
    }
    const cardsByAssetId = groupBy(data.anyCards, t => t.assetId);
    const alternativeHit = lowestPriceHits.hits.find(
      hit => cardsByAssetId[assetIdFromHit(hit)]?.[0]?.liveSingleSaleOffer
    );
    if (alternativeHit) {
      return cardsByAssetId[assetIdFromHit(alternativeHit)]?.[0] || card;
    }

    const alternativePrimaryBuyHit = lowestPriceHits.hits.find(hit =>
      livePrimaryOffer(cardsByAssetId[assetIdFromHit(hit)]?.[0])
    );
    if (alternativePrimaryBuyHit) {
      return (
        cardsByAssetId[assetIdFromHit(alternativePrimaryBuyHit)]?.[0] || card
      );
    }
    return card;
  }, [card, data, lowestPriceHits]);

  const stackedTokensCount = useMemo(() => {
    if (!lowestPriceHits) return stack?.count || 1;
    const alreadySoldHits =
      data?.anyCards.filter(t => {
        if (t.liveSingleSaleOffer) {
          return false;
        }
        if (t?.latestPrimaryOffer?.price && !t.tokenOwner) return false;
        return true;
      }).length || 0;

    return lowestPriceHits.nbHits - alreadySoldHits;
  }, [data?.anyCards, lowestPriceHits, stack?.count]);
  return (
    <ManagerTaskTooltip
      name={MarketplaceOnboardingStep.marketplaceItem}
      onClick={() => {
        setStep(MarketplaceOnboardingStep.buy);
      }}
      TaskComponent={MarketplaceOnboardingTask}
      taskProps={{
        content: (
          <>
            <TooltipDescription card={card} />
            <LabelM color="var(--c-yellow-100)">
              <FormattedMessage
                id="MarketplaceTaskDescription.tryToWin"
                defaultMessage="🌟 You can try to win one as a reward in Sorare Competitions"
              />
            </LabelM>
          </>
        ),
      }}
      disable={!task || !displayMarketplaceOnboardingTooltip}
      placement={isTablet ? 'right-start' : 'bottom-start'}
      forceAreaHighlight={MarketplaceOnboardingStep.buy === step}
    >
      <TokenContent
        card={actualCard}
        stackedTokensCount={stackedTokensCount}
        displayMarketplaceOnboardingTooltip={
          displayMarketplaceOnboardingTooltip
        }
        hideDetails={!!hideDetails}
        action={action}
        {...rest}
      />
    </ManagerTaskTooltip>
  );
};

Token.fragments = {
  anyCard: cardFragment,
};
