import { Navigate, generatePath } from 'react-router-dom';

import { FOOTBALL_PLAY_REWARDS_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';

const Rewards = () => {
  return (
    <Navigate
      to={generatePath(FOOTBALL_PLAY_REWARDS_FIXTURE, { fixture: 'ongoing' })}
      replace
    />
  );
};

export default Rewards;
