import { PropsWithChildren } from 'react';
import { generatePath, matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { HeadlineS } from 'atoms/typography';
import { FOOTBALL_MY_CLUB_SLUG } from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useIsTabletAndAbove } from 'hooks/device/useIsTabletAndAbove';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useLifecycle, { LIFECYCLE } from 'hooks/useLifecycle';

const TitleWrapper = styled(Horizontal)`
  padding: 0 var(--unit);
`;

export const MyClubOnboardingTooltip = ({ children }: PropsWithChildren) => {
  const isTabletAndAbove = useIsTabletAndAbove();
  const { lifecycle } = useLifecycle();
  const {
    flags: { enableFootballMyClubOnboarding = 'out' },
  } = useFeatureFlags();
  const location = useLocation();
  const { currentUser, blockchainCardsCount } = useCurrentUserContext();
  if (
    enableFootballMyClubOnboarding !== 'treatment' ||
    lifecycle?.[LIFECYCLE.sawFootballMyClubOnboarding] ||
    blockchainCardsCount === 0 ||
    !currentUser ||
    matchPath(
      generatePath(FOOTBALL_MY_CLUB_SLUG, {
        slug: currentUser?.slug || '',
      }),
      location.pathname
    )
  ) {
    return children;
  }

  return (
    <Tooltip
      open
      placement={isTabletAndAbove ? 'right' : 'top'}
      zIndex={2}
      title={
        <TitleWrapper>
          <HeadlineS>{blockchainCardsCount}</HeadlineS>
          <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.370605 1C0.370605 0.447715 0.818321 0 1.37061 0H11.3706C11.9229 0 12.3706 0.447715 12.3706 1V15C12.3706 15.5523 11.9229 16 11.3706 16H1.37061C0.818321 16 0.370605 15.5523 0.370605 15V1ZM2.37061 2V14H10.3706V2H2.37061ZM6.43311 6C6.15696 6 5.93311 6.22386 5.93311 6.5C5.93311 6.77614 6.15696 7 6.43311 7C6.70925 7 6.93311 6.77614 6.93311 6.5C6.93311 6.22386 6.70925 6 6.43311 6ZM3.93311 6.5C3.93311 5.11929 5.05239 4 6.43311 4C7.81382 4 8.93311 5.11929 8.93311 6.5C8.93311 7.88071 7.81382 9 6.43311 9C5.05239 9 3.93311 7.88071 3.93311 6.5ZM6.37061 11C5.75457 11 5.30811 11.4765 5.30811 12H3.30811C3.30811 10.3144 4.70846 9 6.37061 9C8.03275 9 9.43311 10.3144 9.43311 12H7.43311C7.43311 11.4765 6.98664 11 6.37061 11Z"
              fill="white"
            />
          </svg>
        </TitleWrapper>
      }
    >
      {children}
    </Tooltip>
  );
};
