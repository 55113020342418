import { createContext, useContext } from 'react';

export interface NethoneContext {
  attemptReference: Promise<string | null>;
}

export const nethoneContext = createContext<NethoneContext | null>(null);

export const useNethoneContext = () => useContext(nethoneContext)!;

export default nethoneContext.Provider;
