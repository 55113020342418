import { TypedDocumentNode, gql } from '@apollo/client';
import { Drawer as MuiDrawer } from '@material-ui/core';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import { GameStatus } from '@sorare/core/src/__generated__/globalTypes';
import { Container } from '@sorare/core/src/atoms/layout/Container';
import {
  ContentWithStickyHeader,
  resetStack,
} from '@sorare/core/src/atoms/layout/ContentWithStickyHeader';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';
import { OverrideClasses } from '@sorare/core/src/style/utils';

import { USInfoModal } from '@sorare/us-sports/src/components/USInfoModal';

import { GameHeader } from './GameHeader';
import { LiveGameView } from './LiveGameView';
import { PastGameView } from './PastGameView';
import { UpcomingGameView } from './UpcomingGameView';
import {
  GameView_anyCard,
  GameView_anyGame,
  GameView_anyPlayer,
  GameView_basketballPlayerGameScore,
  GameView_so5Fixture,
} from './__generated__/index.graphql';

const Wrapper = styled(Vertical).attrs({ gap: 0 })`
  --team-logo-width: var(--quadruple-unit);
  --team-score-width: calc(7 * var(--unit));
  ${resetStack}
`;

const [Drawer, classes] = OverrideClasses(MuiDrawer, null, {
  paper: css`
    width: 100%;
    background-color: var(--c-neutral-200);
    @media ${tabletAndAbove} {
      width: 380px;
    }
  `,
});

type Props = {
  fixture: GameView_so5Fixture;
  game: GameView_anyGame;
  allMyCards: GameView_anyCard[];
  awayTeamPlayers: GameView_anyPlayer[];
  homeTeamPlayers: GameView_anyPlayer[];
  playerGameScores: GameView_basketballPlayerGameScore[];
  backRoute: string;
  hideHeader?: boolean;
  inDialog?: boolean;
};

export const GameView = ({
  fixture,
  game,
  allMyCards,
  awayTeamPlayers,
  homeTeamPlayers,
  playerGameScores,
  backRoute,
  hideHeader,
  inDialog = false,
}: Props) => {
  const [infoModalSlug, setInfoModalSlug] = useState<
    { card?: string; player?: string } | undefined
  >(undefined);
  const [mouseOnHoverPlayer, setMouseOnHoverPlayer] = useState<string | null>(
    null
  );

  if (!game) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <ContentWithStickyHeader
          header={
            hideHeader ? null : (
              <GameHeader
                game={game}
                backRoute={backRoute}
                inDialog={inDialog}
              />
            )
          }
        >
          <Container>
            {game.statusTyped === GameStatus.played ? (
              <PastGameView
                fixture={fixture}
                game={game}
                allMyCards={allMyCards}
                playerGameScores={playerGameScores}
                openPlayerInfoModal={(playerSlug: string) =>
                  setInfoModalSlug({ player: playerSlug })
                }
                openCardInfoModal={(cardSlug: string) =>
                  setInfoModalSlug({ card: cardSlug })
                }
                setMouseOnHoverPlayer={setMouseOnHoverPlayer}
                mouseOnHoverPlayer={mouseOnHoverPlayer}
              />
            ) : (
              <>
                {game.statusTyped === GameStatus.playing ? (
                  <LiveGameView
                    fixture={fixture}
                    game={game}
                    allMyCards={allMyCards}
                    playerGameScores={playerGameScores}
                    openPlayerInfoModal={(playerSlug: string) =>
                      setInfoModalSlug({ player: playerSlug })
                    }
                    openCardInfoModal={(cardSlug: string) =>
                      setInfoModalSlug({ card: cardSlug })
                    }
                    setMouseOnHoverPlayer={setMouseOnHoverPlayer}
                    mouseOnHoverPlayer={mouseOnHoverPlayer}
                  />
                ) : (
                  <UpcomingGameView
                    fixture={fixture}
                    game={game}
                    allMyCards={allMyCards}
                    homeTeamPlayers={homeTeamPlayers}
                    awayTeamPlayers={awayTeamPlayers}
                    openPlayerInfoModal={(playerSlug: string) =>
                      setInfoModalSlug({ player: playerSlug })
                    }
                    openCardInfoModal={(cardSlug: string) =>
                      setInfoModalSlug({ card: cardSlug })
                    }
                  />
                )}
              </>
            )}
          </Container>
        </ContentWithStickyHeader>
      </Wrapper>
      <Drawer
        open={!!infoModalSlug}
        anchor="right"
        classes={{
          paper: classes.paper,
        }}
        onClose={() => {
          setInfoModalSlug(undefined);
        }}
        variant="temporary"
      >
        {infoModalSlug && (
          <USInfoModal
            cardSlug={infoModalSlug?.card}
            playerSlug={infoModalSlug?.player}
            onClose={() => {
              setInfoModalSlug(undefined);
            }}
            withPlayerLink
          />
        )}
      </Drawer>
    </>
  );
};

GameView.fragments = {
  basketballPlayerGameScore: gql`
    fragment GameView_basketballPlayerGameScore on BasketballPlayerGameScore {
      id
      ...PastGameView_basketballPlayerGameScore
      ...LiveGameView_basketballPlayerGameScore
    }
    ${PastGameView.fragments.basketballPlayerGameScore}
    ${LiveGameView.fragments.basketballPlayerGameScore}
  ` as TypedDocumentNode<GameView_basketballPlayerGameScore>,
  anyGame: gql`
    fragment GameView_anyGame on AnyGameInterface {
      id
      statusTyped
      ...GameHeader_anyGame
      ...PastGameView_anyGame
      ...LiveGameView_anyGame
      ...UpcomingGameView_anyGame
    }
    ${GameHeader.fragments.anyGame}
    ${PastGameView.fragments.anyGame}
    ${LiveGameView.fragments.anyGame}
    ${UpcomingGameView.fragments.anyGame}
  ` as TypedDocumentNode<GameView_anyGame>,
  anyPlayer: gql`
    fragment GameView_anyPlayer on AnyPlayerInterface {
      slug
      ...UpcomingGameView_anyPlayer
    }
    ${UpcomingGameView.fragments.anyPlayer}
  ` as TypedDocumentNode<GameView_anyPlayer>,
  so5Fixture: gql`
    fragment GameView_so5Fixture on So5Fixture {
      slug
      ...PastGameView_so5Fixture
      ...LiveGameView_so5Fixture
      ...UpcomingGameView_so5Fixture
    }
    ${PastGameView.fragments.so5Fixture}
    ${LiveGameView.fragments.so5Fixture}
    ${UpcomingGameView.fragments.so5Fixture}
  ` as TypedDocumentNode<GameView_so5Fixture>,

  anyCard: gql`
    fragment GameView_anyCard on AnyCardInterface {
      slug
      ...PastGameView_anyCard
      ...LiveGameView_anyCard
      ...UpcomingGameView_anyCard
    }
    ${PastGameView.fragments.anyCard}
    ${LiveGameView.fragments.anyCard}
    ${UpcomingGameView.fragments.anyCard}
  ` as TypedDocumentNode<GameView_anyCard>,
};
