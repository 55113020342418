import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LiveIndicator } from '@sorare/core/src/atoms/ui/LiveIndicator';
import { isType } from '@sorare/core/src/gql';
import { gameStatusMessages } from '@sorare/core/src/lib/glossary';

import { isGameFinished, isGameLive } from 'lib/so5';

import { GameStatusLabel_anyGameInterface } from './__generated__/index.graphql';

type Props = { game: GameStatusLabel_anyGameInterface };

export const GameStatusLabel = ({ game }: Props) => {
  return (
    <>
      {isGameFinished(game.status) ? (
        <FormattedMessage {...gameStatusMessages.played} />
      ) : (
        <>
          {isGameLive(game.status) ? (
            <Horizontal as="span">
              <LiveIndicator />
              {isType(game, 'Game') ? (
                <>{game.minute}&lsquo;</>
              ) : (
                <FormattedMessage {...gameStatusMessages.live} />
              )}
            </Horizontal>
          ) : (
            <FormattedDate
              value={game.date}
              month="short"
              day="numeric"
              hour="2-digit"
              minute="2-digit"
            />
          )}
        </>
      )}
    </>
  );
};

GameStatusLabel.fragments = {
  anyGameInterface: gql`
    fragment GameStatusLabel_anyGameInterface on AnyGameInterface {
      id
      status: statusTyped
      date
      ... on Game {
        id
        minute
      }
    }
  ` as TypedDocumentNode<GameStatusLabel_anyGameInterface>,
};
