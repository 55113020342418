import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useConfigContext } from '@sorare/core/src/contexts/config';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { currentUser as currentUserFragment } from '@sorare/core/src/contexts/currentUser/queries';
import useRedirectAfterSignIn from '@sorare/core/src/contexts/currentUser/useRedirectAfterSignIn';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import useQueryString from '@sorare/core/src/hooks/useQueryString';

import {
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables,
} from './__generated__/index.graphql';

type ConfirmEmailMutation_confirmEmail_currentUser = NonNullable<
  NonNullable<ConfirmEmailMutation['confirmEmail']>['currentUser']
>;

const confirmEmailMutation = gql`
  mutation ConfirmEmailMutation($input: confirmEmailInput!, $sport: Sport!) {
    confirmEmail(input: $input) {
      currentUser {
        slug
        email
        ...CurrentUserProvider_currentUser
      }
      errors {
        message
        code
      }
    }
  }
  ${currentUserFragment}
` as TypedDocumentNode<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

export const InnerConfirmEmail = ({
  confirmEmail,
}: {
  confirmEmail: (
    token: string
  ) => Promise<
    ConfirmEmailMutation_confirmEmail_currentUser | null | undefined
  >;
}) => {
  const navigate = useNavigate();
  const { updateQuery } = useConfigContext();
  const { currentUser } = useCurrentUserContext();
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const redirectUser = useRedirectAfterSignIn();
  const token = useQueryString('token');

  useEffect(() => {
    if (token && !loading && !confirmed) {
      setLoading(true);
      confirmEmail(token)
        .then(user => {
          if (user) updateQuery(user);
        })
        .finally(() => {
          setConfirmed(true);
          setLoading(false);
        });
    }
  }, [token, loading, confirmed, confirmEmail, updateQuery]);

  useEffect(() => {
    if (confirmed) {
      redirectUser(currentUser);
    }
  }, [currentUser, navigate, confirmed, redirectUser]);

  return null;
};

export const ConfirmEmail = () => {
  const { sport } = useSportContext();
  const [confirm] = useMutation(confirmEmailMutation, {
    showErrorsWithSnackNotification: true,
  });
  const redirectUser = useRedirectAfterSignIn();

  const confirmEmail = useCallback(
    async (token: string) => {
      const { data } = await confirm({
        variables: { input: { token }, sport },
      });
      // If already confirm, error is returned
      if (data?.confirmEmail?.errors) {
        redirectUser(data.confirmEmail.currentUser);
      }
      return data?.confirmEmail?.currentUser;
    },
    [confirm, redirectUser, sport]
  );

  return <InnerConfirmEmail confirmEmail={confirmEmail} />;
};

export default ConfirmEmail;
