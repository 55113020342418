import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { CardRarity, Rarity } from '__generated__/globalTypes';
import { scarcityMessages } from 'lib/scarcity';
import { Color } from 'style/types';

const Root = styled.span<{ color: Color }>`
  color: ${({ color }) => color};
  font-weight: var(--t-bold);
`;

type Props = { scarcity: Rarity | CardRarity };

const colorMapping = {
  [Rarity.common]: 'var(--c-collection-common)',
  [Rarity.limited]: 'var(--c-yellow-600)',
  [Rarity.rare]: 'var(--c-red-600)',
  [Rarity.super_rare]: 'var(--c-brand-600)',
  [Rarity.unique]: 'var(--c-rivals-purple-800)',
  [Rarity.custom_series]: 'var(--c-collection-customSeries)',
} as Record<Rarity | CardRarity, Color>;

export const ScarcityLabel = ({ scarcity }: Props) => {
  const { formatMessage } = useIntl();

  const label = formatMessage(scarcityMessages[scarcity]);

  return <Root color={colorMapping[scarcity]}>{label}</Root>;
};
