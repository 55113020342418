import { useLazyQuery } from '@apollo/client';
import { ReactNode, useEffect } from 'react';

import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import usePrevious from '@sorare/core/src/hooks/usePrevious';

import CurrentBaseballUserContextProvider from './index';
import { CURRENT_BASEBALL_USER_QUERY } from './queries';

interface Props {
  children: ReactNode;
}

export const CurrentBaseballUserProvider = ({ children }: Props) => {
  const { currentUser } = useCurrentUserContext();

  const [getCurrentBaseballUser, { data, loading: fetchingBaseballUser }] =
    useLazyQuery(CURRENT_BASEBALL_USER_QUERY);
  const currentBaseballUser = data?.currentUser;

  const currentUserSlug = currentUser?.slug;
  const previousSlug = usePrevious(currentUserSlug);

  useEffect(() => {
    if (fetchingBaseballUser) {
      return;
    }
    if (currentUserSlug !== previousSlug) {
      getCurrentBaseballUser();
    }
  }, [
    currentUserSlug,
    fetchingBaseballUser,
    getCurrentBaseballUser,
    previousSlug,
  ]);

  return (
    <CurrentBaseballUserContextProvider
      value={{
        currentBaseballUser,
        fetching: currentUserSlug !== previousSlug || fetchingBaseballUser,
      }}
    >
      {children}
    </CurrentBaseballUserContextProvider>
  );
};

export default CurrentBaseballUserProvider;
