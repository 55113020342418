import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import useToggle from '@sorare/core/src/hooks/useToggle';

import { RowGroup } from '../ui';
import { CustomStatCategory, DECISIVE_STAT_CATEGORY } from '../utils';
import { CategoryHeader } from './CategoryHeader';
import { CategoryStat } from './CategoryStat';
import {
  Category_playerGameScoreInterface,
  Category_statScore,
} from './__generated__/index.graphql';

type Props = {
  category: CustomStatCategory;
  playerGameScores: Category_playerGameScoreInterface[];
  stats: Category_statScore[];
  defaultExpanded?: boolean;
};

export const Category: FC<PropsWithChildren<Props>> = ({
  category,
  playerGameScores,
  stats,
  defaultExpanded,
}: Props) => {
  const isDecisive = category === DECISIVE_STAT_CATEGORY;
  const [expanded, toggleExpanded] = useToggle(
    isDecisive ? true : !!defaultExpanded
  );

  return (
    <RowGroup className={classNames({ isDecisive, expanded })}>
      {!isDecisive && (
        <CategoryHeader
          category={category}
          playerGameScores={playerGameScores}
          onClick={toggleExpanded}
          showExpandedColumn={!defaultExpanded}
          expanded={expanded}
        />
      )}
      {stats.map(stat => (
        <CategoryStat
          key={stat.stat}
          category={category}
          stat={stat}
          playerGameScores={playerGameScores}
        />
      ))}
    </RowGroup>
  );
};

Category.fragments = {
  playerGameScoreInterface: gql`
    fragment Category_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      ...CategoryHeader_playerGameScoreInterface
      ...CategoryStat_playerGameScoreInterface
    }
    ${CategoryHeader.fragments.playerGameScoreInterface}
    ${CategoryStat.fragments.playerGameScoreInterface}
  ` as TypedDocumentNode<Category_playerGameScoreInterface>,
  statScore: gql`
    fragment Category_statScore on StatScore {
      ...CategoryStat_statScore
    }
    ${CategoryStat.fragments.statScore}
  ` as TypedDocumentNode<Category_statScore>,
};
