import { useEffect, useRef } from 'react';

export const useAutoscroll = <
  T extends HTMLElement,
  U extends HTMLElement = never,
>(
  active: boolean
) => {
  const ref = useRef<T>(null);
  const scrollContainer = useRef<U>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (ref.current && active && isFirstRender.current) {
      if (scrollContainer.current) {
        scrollContainer.current.scrollTo(
          ref.current.offsetLeft - scrollContainer.current.offsetLeft,
          ref.current.offsetTop - scrollContainer.current.offsetTop
        );
      } else {
        ref.current?.scrollIntoView();
      }
    }
    isFirstRender.current = false;
  }, [active]);

  return { ref, scrollContainer };
};
