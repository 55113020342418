import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';

import { useIntlContext } from 'contexts/intl';
import { useSentryContext } from 'contexts/sentry';
import { Level, useSnackNotificationContext } from 'contexts/snackNotification';
import usePrevious from 'hooks/usePrevious';
import { fetchFile } from 'lib/files';
import { toSnakeCase } from 'lib/toSnakeCase';

const messages = defineMessages({
  notSupported: {
    id: 'ShareSheet.notSupported',
    defaultMessage: 'Your browser does not support sharing files',
  },
});

export default ({
  url,
  image,
  title,
  skip,
}: {
  url?: string;
  image?: string | null;
  title?: string;
  skip: boolean;
}) => {
  const { showNotification } = useSnackNotificationContext();
  const { sendSafeError } = useSentryContext();
  const { formatMessage } = useIntlContext();
  const [loading, setLoading] = useState(false);
  const previousImage = usePrevious(image);
  const [imageFile, setImageFile] = useState<File>();

  const shareProps = useMemo(() => {
    const props: {
      url?: string;
      text?: string;
      title?: string;
      files?: File[];
    } = { url, text: title, title };
    if (imageFile) {
      props.files = [imageFile];
    }
    return props;
  }, [imageFile, url, title]);

  const enabled = useMemo(() => {
    return navigator.canShare?.(shareProps);
  }, [shareProps]);

  useEffect(() => {
    if (
      skip ||
      !enabled ||
      !image ||
      (imageFile && previousImage === image) ||
      loading
    )
      return;

    const fetchImage = async () => {
      setLoading(true);
      setImageFile(
        await fetchFile(image, toSnakeCase(title || `Sorare-${Date.now()}`))
      );
      setLoading(false);
    };

    fetchImage();
  }, [enabled, image, imageFile, loading, title, skip, previousImage]);

  const share = useCallback(async () => {
    try {
      setLoading(true);
      if (enabled) {
        await navigator.share(shareProps);
      } else {
        showNotification(
          'errors',
          { errors: formatMessage(messages.notSupported) },
          { level: Level.INFO }
        );
      }
    } catch (error: any) {
      if (['AbortError', 'NotAllowedError'].includes(error.name)) return;

      showNotification(
        'errors',
        { errors: error.message },
        { level: Level.ERROR }
      );
      sendSafeError(error);
    } finally {
      setLoading(false);
    }
  }, [enabled, formatMessage, shareProps, sendSafeError, showNotification]);
  return { share, enabled, loading };
};
