import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { deckFragment } from '@sorare/core/src/hooks/decks/fragments';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import CurrentMarketplaceUserContextProvider from '.';
import {
  CurrentMarketplaceUserQuery,
  CurrentMarketplaceUserQueryVariables,
  CurrentMarketplaceUser_currentUser,
} from './__generated__/Provider.graphql';

export const CURRENT_MARKETPLACE_USER_FRAGMENT = gql`
  fragment CurrentMarketplaceUser_currentUser on CurrentUser {
    slug
    wonTokenAuctions(first: 0, sport: [$sport]) {
      hasAny
    }
    boughtSingleSaleTokenOffers(first: 0) {
      hasAny
    }
    involvedLiveTokenAuctions(sport: [$sport], first: 200) {
      nodes {
        id
        anyCards {
          slug
        }
      }
    }
    cardFilters(sport: $sport)
    userSportProfile: anyUserSportProfile(sport: $sport) {
      id
      decks {
        nodes {
          slug
          ...deckFragments_deck
        }
      }
    }
  }
  ${deckFragment}
` as TypedDocumentNode<CurrentMarketplaceUser_currentUser>;

export const CURRENT_MARKETPLACE_USER_QUERY = gql`
  query CurrentMarketplaceUserQuery($sport: Sport!) {
    currentUser {
      slug
      ...CurrentMarketplaceUser_currentUser
    }
  }
  ${CURRENT_MARKETPLACE_USER_FRAGMENT}
` as TypedDocumentNode<
  CurrentMarketplaceUserQuery,
  CurrentMarketplaceUserQueryVariables
>;

const loggedOutResult = {
  __typename: 'CurrentUser' as const,
  slug: '',
  wonTokenAuctions: {
    __typename: 'TokenAuctionConnection' as const,
    hasAny: false,
  },
  boughtSingleSaleTokenOffers: {
    __typename: 'TokenOfferConnection' as const,
    hasAny: false,
  },
  involvedLiveTokenAuctions: {
    __typename: 'TokenAuctionConnection' as const,
    nodes: [],
  },
  cardFilters: [],
  userSportProfile: null,
};

const CurrentMarketplaceUserProvider = ({
  children,
  sport,
}: {
  children: ReactNode;
  sport: Sport;
}) => {
  const { data } = useQuery(CURRENT_MARKETPLACE_USER_QUERY, {
    variables: { sport },
  });

  if (!data) {
    return null;
  }

  return (
    <CurrentMarketplaceUserContextProvider
      value={data.currentUser || loggedOutResult}
    >
      {children}
    </CurrentMarketplaceUserContextProvider>
  );
};

export default CurrentMarketplaceUserProvider;
