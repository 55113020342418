import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, LabelS } from '@sorare/core/src/atoms/typography';
import { StatIcon } from '@sorare/core/src/components/stats/StatIcon';
import { useIntlContext } from '@sorare/core/src/contexts/intl';

import { Cell, Row } from '../../ui';
import {
  CustomStatCategory,
  DECISIVE_STAT_CATEGORY,
  statToLabel,
} from '../../utils';
import {
  CategoryStat_playerGameScoreInterface,
  CategoryStat_statScore,
} from './__generated__/index.graphql';

const Stat = styled(BodyS)`
  &::first-letter {
    text-transform: uppercase;
  }
`;
const Score = styled(BodyS)`
  min-width: var(--quadruple-unit);
  font-variant-numeric: tabular-nums;
`;
const Value = styled(LabelS)`
  font-variant-numeric: tabular-nums;
`;

type Props = {
  category: CustomStatCategory;
  stat: CategoryStat_statScore;
  playerGameScores: CategoryStat_playerGameScoreInterface[];
};

export const CategoryStat = ({ category, stat, playerGameScores }: Props) => {
  const { formatNumber } = useIntlContext();
  const isDecisive = category === DECISIVE_STAT_CATEGORY;

  const statLabel = statToLabel(stat.stat);

  return (
    <Row className={classNames('stat')}>
      <Cell>
        <Horizontal>
          {isDecisive && <StatIcon statKey={stat.stat} />}
          <Stat as="p" color="var(--c-neutral-600)">
            {typeof statLabel === 'string' ? (
              statLabel
            ) : (
              <FormattedMessage {...statLabel} />
            )}
          </Stat>
        </Horizontal>
      </Cell>
      {playerGameScores.map(playerGameScore => {
        const statScore = playerGameScore.detailedScore.find(
          score => score.stat === stat.stat
        );

        const scoreString = isDecisive
          ? statScore?.statValue
          : statScore?.totalScore;

        return (
          <Cell key={playerGameScore.id}>
            <Horizontal>
              {!isDecisive && !!statScore?.statValue && (
                <Value as="p" color="var(--c-neutral-500)">
                  (
                  <FormattedNumber value={statScore.statValue} />)
                </Value>
              )}
              <Score as="p" color="var(--c-neutral-600)">
                {scoreString ? formatNumber(scoreString) : '-'}
              </Score>
            </Horizontal>
          </Cell>
        );
      })}
    </Row>
  );
};

CategoryStat.fragments = {
  statScore: gql`
    fragment CategoryStat_statScore on StatScore {
      stat
    }
  ` as TypedDocumentNode<CategoryStat_statScore>,
  playerGameScoreInterface: gql`
    fragment CategoryStat_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      detailedScore {
        stat
        statValue
        totalScore
      }
    }
  ` as TypedDocumentNode<CategoryStat_playerGameScoreInterface>,
};
