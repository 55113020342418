import {
  faCardsBlank,
  faCircleCheck,
  faUpRightFromSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineXL, LabelM } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { glossary } from '@sorare/core/src/lib/glossary';

import bg from './assets/bg.jpg';
import starIcon from './assets/star.png';

const messages = defineMessages({
  title: {
    id: 'marketplace.earlyAccessDialog.title',
    defaultMessage: 'Early Access Cards',
  },
  specialEditions: {
    id: 'marketplace.earlyAccessDialog.specialEditions',
    defaultMessage: 'Special Editions',
  },
  specialEditionChance: {
    id: 'marketplace.earlyAccessDialog.specialEditionChance',
    defaultMessage: '10% chance that your card is a Special Edition',
  },
  playNow: {
    id: 'marketplace.earlyAccessDialog.playNow',
    defaultMessage: 'Play now in eligible competitions',
  },
  revealed: {
    id: 'marketplace.earlyAccessDialog.revealed',
    defaultMessage: 'Discover your card’s 2024-25 design in September',
  },
});

const Content = styled(Vertical).attrs({ gap: 3 })`
  padding: var(--triple-unit);
  text-align: center;
`;

const Item = styled(Horizontal)`
  text-align: left;
  padding: var(--intermediate-unit);
  border-radius: var(--unit);
  background: rgba(var(--c-rgb-neutral-1000), 0.12);
`;

const MainItem = styled(Item)`
  padding: var(--double-unit) var(--intermediate-unit);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${bg});
  background-size: cover;
`;

const StarIcon = styled.div`
  font-family: 'Druk Text Wide';
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  background: url(${starIcon});
  background-size: contain;
  background-repeat: no-repeat;
  color: var(--c-neutral-100);
  font-size: 8px;
  font-weight: var(--t-bold);
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.1em;
`;

const EarlyAccessDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    flags: { useEarlyAccessDetailsLink = '/blog' },
  } = useFeatureFlags();
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullScreen={false}
      hideHeader
      onClose={onClose}
    >
      <Content>
        <HeadlineXL>
          <FormattedMessage {...messages.title} />
        </HeadlineXL>
        <Vertical gap={0.5}>
          <MainItem>
            <StarIcon>
              <FormattedMessage {...messages.specialEditions} />
            </StarIcon>
            <LabelM>
              <FormattedMessage {...messages.specialEditionChance} />
            </LabelM>
          </MainItem>
          <Item>
            <FontAwesomeIcon icon={faCircleCheck} />
            <LabelM>
              <FormattedMessage {...messages.playNow} />
            </LabelM>
          </Item>
          <Item>
            <FontAwesomeIcon icon={faCardsBlank} />
            <LabelM>
              <FormattedMessage {...messages.revealed} />
            </LabelM>
          </Item>
        </Vertical>
        <Button
          size="medium"
          color="tertiary"
          to={useEarlyAccessDetailsLink}
          externalLink
          fullWidth
        >
          <FontAwesomeIcon icon={faUpRightFromSquare} />
          <FormattedMessage {...glossary.learnMore} />
        </Button>
        <Button size="medium" color="secondary" onClick={onClose}>
          <FormattedMessage {...glossary.gotIt} />
        </Button>
      </Content>
    </Dialog>
  );
};

export default EarlyAccessDialog;
