import { TypedDocumentNode, gql } from '@apollo/client';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { InventoryCounterInline } from '@sorare/core/src/components/clubShop/ClubShopItem/Labels/InventoryCounterInline';
import { ResetIn } from '@sorare/core/src/components/clubShop/ClubShopItem/Labels/ResetIn';
import { ANY_SPORT_CLUB_SHOP_INVENTORY } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';

import { ConsumableCta_shopItem } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ center: true })`
  justify-content: stretch;
`;

type ConsumableCtaProps = PropsWithChildren<{
  item: ConsumableCta_shopItem;
  inventory?: boolean;
  onClose: () => void;
}>;
const ConsumableCta = ({
  item,
  inventory,
  onClose,
  children,
}: ConsumableCtaProps) => {
  const { generateSportContextPath } = useSportContext();
  const cooldown = item.myLimitResetAt !== null;
  return (
    <Root>
      <InventoryCounterInline amount={item.myBalance} name={item.name} />
      {cooldown && !inventory && (
        <ResetIn myLimitResetAt={item.myLimitResetAt!} />
      )}
      {children}
      {!inventory && (
        <Button
          to={generateSportContextPath(ANY_SPORT_CLUB_SHOP_INVENTORY)}
          color="tertiary"
          size="medium"
          onClick={onClose}
        >
          <FormattedMessage
            id="ClubShop.ItemPreviewDialog.Cta.Inventory"
            defaultMessage="Go to inventory"
          />
        </Button>
      )}
    </Root>
  );
};

ConsumableCta.fragments = {
  shopItem: gql`
    fragment ConsumableCta_shopItem on ClubShopItem {
      ... on ShopItemInterface {
        id
        name
        myBalance
        myLimitResetAt
      }
    }
  ` as TypedDocumentNode<ConsumableCta_shopItem>,
};

export default ConsumableCta;
