import { useCallback } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import { ExtendedUIState } from 'components/search/InstantSearch/types';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSearchCardsContext } from 'contexts/searchCards';
import { VIRTUAL_TOGGLE_FILTERS } from 'lib/filters';

import useVirtualToggle from './useVirtualToggle';

export const useVirtualToggleManager = () => {
  const {
    setGameweekFilter,
    setDailyFilter,
    setProbablePitchersFilter,
    setFavoriteFilter,
    hasCollectibleFilter,
    setNonPlayableCards,
    setLegend,
    setCompetitionEligibilityFilter,
    setPromotion,
    setCustomDecksFilter,
    setNotInLineUpFilter,
    setChallengerFilter,
    setContenderFilter,
    setChampionFilter,
    setFirstEditionWeekFilter,
    setPromotionalEvent,
  } = useSearchCardsContext() ?? {};

  const { currentUser } = useCurrentUserContext();
  const { setIndexUiState } = useInstantSearch<ExtendedUIState>();

  const { toggle: toggleGameweekFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.gameweekFilter.name,
  });
  const { toggle: toggleDailyFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.dailyFilter.name,
  });
  const { toggle: toggleProbablePitchersFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.probablePitchersFilter.name,
  });
  const { toggle: toggleFavoriteFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.favoriteFilter.name,
  });
  const { toggle: toggleNotInLineupFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.notInLineupFilter.name,
  });
  const { toggle: toggleNonPlayableCardsFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.nonPlayableCardsFilter.name,
  });
  const { toggle: toggleLegendFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.legendFilter.name,
  });
  const { toggle: togglePromotedCardsFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.promotedCardsFilter.name,
  });
  const { toggle: toggleCompetitionEligibilityFilter } =
    useVirtualToggle<boolean>({
      name: VIRTUAL_TOGGLE_FILTERS.competitionEligibilityFilter.name,
    });
  const { toggle: toggleUnstackedFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.unstackedFilter.name,
  });
  const { toggle: toggleCustomDecksFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.customDecksFilter.name,
  });

  const { toggle: toggleContenderFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.contenderFilter.name,
  });
  const { toggle: toggleChallengerFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.challengerFilter.name,
  });
  const { toggle: toggleChampionFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.championFilter.name,
  });

  const { toggle: toggleFirstEditionWeekFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.firstEditionWeekFilter.name,
  });
  const { toggle: togglePromotionalEventFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.promotionalEventFilter.name,
  });

  return useCallback(
    (
      virtualToggle: {
        [name: string]: string | boolean | string[] | undefined;
      },
      shouldSetUiState = true
    ) => {
      if (!virtualToggle) return;

      const actionsPerVirtualToggle: {
        [key: string]: {
          toggle: (value?: any) => void;
          setState?: (value: any) => void;
          condition?: boolean;
        };
      } = {
        [VIRTUAL_TOGGLE_FILTERS.gameweekFilter.name]: {
          toggle: toggleGameweekFilter,
          setState: setGameweekFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.dailyFilter.name]: {
          toggle: toggleDailyFilter,
          setState: setDailyFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.probablePitchersFilter.name]: {
          toggle: toggleProbablePitchersFilter,
          setState: setProbablePitchersFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.favoriteFilter.name]: {
          toggle: toggleFavoriteFilter,
          setState: setFavoriteFilter,
          condition: !!currentUser,
        },
        [VIRTUAL_TOGGLE_FILTERS.notInLineupFilter.name]: {
          toggle: toggleNotInLineupFilter,
          setState: setNotInLineUpFilter,
          condition: !!currentUser,
        },
        [VIRTUAL_TOGGLE_FILTERS.nonPlayableCardsFilter.name]: {
          toggle: toggleNonPlayableCardsFilter,
          setState: setNonPlayableCards,
          condition: !!hasCollectibleFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.legendFilter.name]: {
          toggle: toggleLegendFilter,
          setState: setLegend,
          condition: !!hasCollectibleFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.promotedCardsFilter.name]: {
          toggle: togglePromotedCardsFilter,
          setState: setPromotion,
        },
        [VIRTUAL_TOGGLE_FILTERS.competitionEligibilityFilter.name]: {
          toggle: toggleCompetitionEligibilityFilter,
          setState: setCompetitionEligibilityFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.unstackedFilter.name]: {
          toggle: toggleUnstackedFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.customDecksFilter.name]: {
          toggle: toggleCustomDecksFilter,
          setState: setCustomDecksFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.contenderFilter.name]: {
          toggle: toggleContenderFilter,
          setState: setContenderFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.challengerFilter.name]: {
          toggle: toggleChallengerFilter,
          setState: setChallengerFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.championFilter.name]: {
          toggle: toggleChampionFilter,
          setState: setChampionFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.firstEditionWeekFilter.name]: {
          toggle: toggleFirstEditionWeekFilter,
          setState: setFirstEditionWeekFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.promotionalEventFilter.name]: {
          toggle: togglePromotionalEventFilter,
          setState: setPromotionalEvent,
        },
      };

      Object.entries(virtualToggle).forEach(([name, value]) => {
        const { toggle, setState, condition } = actionsPerVirtualToggle[name];
        if (condition ?? true) {
          toggle(value);
          setState?.(value);
        }
      });
      if (shouldSetUiState) {
        setIndexUiState(oldState => ({
          ...oldState,
          virtualToggle: { ...oldState.virtualToggle, ...virtualToggle },
        }));
      }
    },
    [
      toggleGameweekFilter,
      setGameweekFilter,
      toggleDailyFilter,
      setDailyFilter,
      toggleProbablePitchersFilter,
      setProbablePitchersFilter,
      toggleFavoriteFilter,
      setFavoriteFilter,
      currentUser,
      toggleNotInLineupFilter,
      setNotInLineUpFilter,
      toggleNonPlayableCardsFilter,
      setNonPlayableCards,
      hasCollectibleFilter,
      toggleLegendFilter,
      setLegend,
      togglePromotedCardsFilter,
      setPromotion,
      toggleCompetitionEligibilityFilter,
      setCompetitionEligibilityFilter,
      toggleUnstackedFilter,
      toggleCustomDecksFilter,
      setCustomDecksFilter,
      toggleContenderFilter,
      setContenderFilter,
      toggleChallengerFilter,
      setChallengerFilter,
      toggleChampionFilter,
      setChampionFilter,
      toggleFirstEditionWeekFilter,
      setFirstEditionWeekFilter,
      togglePromotionalEventFilter,
      setPromotionalEvent,
      setIndexUiState,
    ]
  );
};
