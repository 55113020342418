import classNames from 'classnames';
import { ReactNode } from 'react';
import styled, { CSSProperties, keyframes } from 'styled-components';

import { range } from 'lib/arrays';

const animate = keyframes`
 10% {
  opacity: 1;
 }
  to {
      transform: translateY(-100%)
  }
`;

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 60%;
  &.fullHeight {
    top: 0;
  }
  overflow: hidden;
  mask-image: linear-gradient(to bottom, transparent, white 80%);
  .emoji {
    position: absolute;
    display: flex;
    align-items: flex-end;
    height: 100%;
    left: var(--x);
    opacity: 0;
    transform: scale(var(--scale)) translateY(0);
    animation: ${animate} 1s infinite linear;
    contain: content;
  }
`;

const NB_EMOJI = 20;

type Props = {
  emoji: ReactNode;
  fullHeight?: boolean;
};
export const EmojiFlood = ({ emoji, fullHeight }: Props) => {
  return (
    <Wrapper className={classNames({ fullHeight })}>
      {range(NB_EMOJI).map(i => {
        const x = `${Math.random() * 100}%`;
        const scale = Math.random() * (4 - 1) + 1;
        const delay = `${Math.random()}s`;
        return (
          <div
            className="emoji"
            key={i}
            style={
              {
                '--x': x,
                '--scale': scale,
                animationDelay: delay,
              } as CSSProperties
            }
          >
            {emoji}
          </div>
        );
      })}
    </Wrapper>
  );
};
