import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { groupBy } from '@sorare/core/src/lib/arrays';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { TeamPlayers } from 'components/game/GameView/UpcomingGameView/TeamPlayers';

import {
  UpcomingGameView_anyCard,
  UpcomingGameView_anyGame,
  UpcomingGameView_anyPlayer,
  UpcomingGameView_so5Fixture,
} from './__generated__/index.graphql';

const Wrapper = styled.div`
  margin: var(--double-unit) 0;
`;

const ResponsiveWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--double-unit);
  flex-wrap: wrap;

  & > * {
    flex-basis: 100%;
    align-self: stretch;
    border-radius: var(--double-unit);

    @media (${tabletAndAbove}) {
      flex-basis: calc(50% - var(--unit));
    }
  }
`;

type Props = {
  fixture: UpcomingGameView_so5Fixture;
  game: UpcomingGameView_anyGame;
  allMyCards: UpcomingGameView_anyCard[];
  awayTeamPlayers: UpcomingGameView_anyPlayer[];
  homeTeamPlayers: UpcomingGameView_anyPlayer[];
  openPlayerInfoModal: (playerSlug: string) => void;
  openCardInfoModal: (cardSlug: string) => void;
};

export const UpcomingGameView = ({
  fixture,
  game,
  allMyCards,
  awayTeamPlayers,
  homeTeamPlayers,
  openPlayerInfoModal,
  openCardInfoModal,
}: Props) => {
  const cardsInLineups = groupBy(
    fixture?.mySo5Lineups
      ?.filter(lineup => !lineup.so5Leaderboard?.trainingCenter)
      .flatMap(({ so5Appearances }) => so5Appearances) || [],
    so5Appearance => so5Appearance.anyCard!.anyPlayer?.slug
  );

  const unusedCards = groupBy(
    allMyCards.filter(
      card =>
        !(cardsInLineups[card.anyPlayer.slug] || []).find(
          c => card.slug === c.anyCard?.slug
        )
    ),
    card => card.anyPlayer?.slug
  );

  const [awayPlayers] = useMemo(
    () => [
      awayTeamPlayers.map(player => ({
        player,
        cards: cardsInLineups[player.slug],
        unusedCards: unusedCards[player.slug],
      })),
    ],
    [awayTeamPlayers, cardsInLineups, unusedCards]
  );

  const [homePlayers] = useMemo(
    () => [
      homeTeamPlayers.map(player => ({
        player,
        cards: cardsInLineups[player.slug],
        unusedCards: unusedCards[player.slug],
      })),
    ],
    [homeTeamPlayers, cardsInLineups, unusedCards]
  );

  return (
    <Wrapper>
      <Vertical gap={2}>
        <ResponsiveWrapper>
          <TeamPlayers
            title={game.awayTeam?.name}
            team={game.awayTeam!}
            players={awayPlayers}
            fixture={fixture}
            openPlayerInfoModal={openPlayerInfoModal}
            openCardInfoModal={openCardInfoModal}
          />
          <TeamPlayers
            title={game.homeTeam?.name}
            team={game.homeTeam!}
            players={homePlayers}
            fixture={fixture}
            openPlayerInfoModal={openPlayerInfoModal}
            openCardInfoModal={openCardInfoModal}
          />
        </ResponsiveWrapper>
      </Vertical>
    </Wrapper>
  );
};

UpcomingGameView.fragments = {
  anyGame: gql`
    fragment UpcomingGameView_anyGame on AnyGameInterface {
      id
      awayTeam {
        slug
        name
        ...TeamPlayers_team
      }
      homeTeam {
        slug
        name
        ...TeamPlayers_team
      }
    }
    ${TeamPlayers.fragments.team}
  ` as TypedDocumentNode<UpcomingGameView_anyGame>,
  anyPlayer: gql`
    fragment UpcomingGameView_anyPlayer on AnyPlayerInterface {
      slug
      ...TeamPlayers_anyPlayer
    }
    ${TeamPlayers.fragments.anyPlayer}
  ` as TypedDocumentNode<UpcomingGameView_anyPlayer>,
  so5Fixture: gql`
    fragment UpcomingGameView_so5Fixture on So5Fixture {
      slug
      mySo5Lineups {
        id
        so5Leaderboard {
          slug
          trainingCenter
        }
        so5Appearances {
          id
          anyCard {
            slug
            anyPlayer {
              slug
            }
          }
          ...TeamPlayers_so5Appearance
        }
      }
      ...TeamPlayers_so5Fixture
    }
    ${TeamPlayers.fragments.so5Appearance}
    ${TeamPlayers.fragments.so5Fixture}
  ` as TypedDocumentNode<UpcomingGameView_so5Fixture>,
  anyCard: gql`
    fragment UpcomingGameView_anyCard on AnyCardInterface {
      slug
      ...TeamPlayers_anyCard
    }
    ${TeamPlayers.fragments.anyCard}
  ` as TypedDocumentNode<UpcomingGameView_anyCard>,
};
