import { CSSProperties, ReactNode, createContext, useContext } from 'react';

export type OnboardingHighlightName =
  | 'RivalsDraftCard'
  | 'RivalsDraftCost'
  | 'RivalsDraftForm'
  | 'RivalsDraftBudget'
  | 'RivalsTactics'
  | 'RivalsTacticBar'
  | 'ArenaPlayButton'
  | 'ClaimFirstBoxMissionDesktop'
  | 'ClaimFirstBoxMissionMobile'
  | 'RankDiamond'
  | 'DivDiamond'
  | 'RepDiamond'
  | 'PlayBanners'
  | 'NBAHomeDailyBlock'
  | 'ComposeTeamAverageScore'
  | 'ManagerProfileGallery'
  | 'ManagerProfileGalleryCard'
  | 'MyCardsCommonMarket'
  | 'BuyCommonCard'
  | 'CommonMarketHeader'
  | 'ManagerProfileCollectionHeader'
  | 'ManagerProfileCollectionBonus'
  | 'ManagerProfileCollectionCards'
  | 'ManagerProfileCollectionScore'
  | 'ManagerProfileCollectionRank'
  | 'FixtureSelectorContainer'
  | 'PlayProEmptyState'
  | 'DailyClaimButton'
  | 'ComposeTeamRemainingPoints';

export type OnboardingHighlightConfig = {
  circle?: boolean;
  padding?: number;
  borderRadius?: CSSProperties['borderRadius'];
  border?: boolean;
  filter?: CSSProperties['filter'];
  onClick?: () => void;
};
export type MeasureHighlightCallback = () => DOMRect | null;

export type Highlight = {
  name: OnboardingHighlightName;
  measureCallback: MeasureHighlightCallback;
  config?: OnboardingHighlightConfig;
  children: ReactNode;
};
export type OnboardingHighlightContext = {
  registerHighlight: ({
    name,
    measureCallback,
    config,
    children,
  }: Highlight) => void;
  highlights: Highlight[];
  activeHighlights: OnboardingHighlightName[];
  setActiveHighlights: (highlights: OnboardingHighlightName[]) => void;
};

export const onboardigHighlightContext =
  createContext<OnboardingHighlightContext | null>(null);

export const useOnboardingHighLightContext = () =>
  useContext(onboardigHighlightContext)!;

export default onboardigHighlightContext;
