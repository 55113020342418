import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Horizontal, SBHorizontal } from 'atoms/layout/flex';
import { useIsTabletAndAbove } from 'hooks/device/useIsTabletAndAbove';
import { tabletAndAbove } from 'style/mediaQuery';

import creditCoin from './assets/credit-coin.png';

const Root = styled.div<{ fullWidth?: boolean }>`
  background: linear-gradient(
      277deg,
      rgba(39, 36, 33, 0.5) 0%,
      rgba(100, 65, 16, 0.5) 49.95%,
      rgba(137, 106, 27, 0.5) 100%
    ),
    var(--surface-primary-nd-50, #212121);
  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      border-radius: var(--unit);
      margin-top: var(--double-unit);
    `}
`;
const StyledHorizontal = styled(SBHorizontal)`
  padding: var(--double-unit) 0;
  flex-direction: column;
  @media ${tabletAndAbove} {
    flex-direction: row;
  }
`;

const ImgWrapper = styled.div`
  background-image: radial-gradient(
    rgba(252, 213, 60, 1) 0%,
    rgba(252, 213, 60, 0) 70%
  );
`;

export const DiscountBannerWrapper = ({
  children,
  rightNode,
  icon,
  fullWidth,
}: {
  children: ReactNode;
  rightNode?: ReactNode;
  icon?: ReactNode;
  fullWidth?: boolean;
}) => {
  const isTabletAndAbove = useIsTabletAndAbove();
  return (
    <Root fullWidth={fullWidth}>
      <FullWidthContainer>
        <StyledHorizontal gap={2}>
          <Horizontal>
            {icon || (
              <ImgWrapper>
                <img
                  src={creditCoin}
                  alt=""
                  width={isTabletAndAbove ? 40 : 20}
                />
              </ImgWrapper>
            )}
            {children}
          </Horizontal>
          {rightNode}
        </StyledHorizontal>
      </FullWidthContainer>
    </Root>
  );
};
