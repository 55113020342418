import { useCallback } from 'react';

import { MonetaryAmount, SupportedCurrency } from '__generated__/globalTypes';
import { useConfigContext } from 'contexts/config';
import { useCurrentUserContext } from 'contexts/currentUser';
import MonetaryAmountClass, {
  MonetaryAmountParams,
  getFiatMonetaryAmountIndex,
} from 'lib/monetaryAmount';

type NonNullableObj<T> = { [K in keyof T]: NonNullable<T[K]> };

export type MonetaryAmountOutput = Omit<
  NonNullableObj<Required<MonetaryAmount>>,
  '__typename' | 'referenceCurrency'
>;

export const zeroMonetaryAmount: MonetaryAmountOutput = {
  eur: 0,
  usd: 0,
  gbp: 0,
  wei: 0n,
};

export const Sum = (amounts: MonetaryAmountOutput[]) => {
  return amounts.reduce((acc, amount) => {
    return {
      eur: acc.eur + amount.eur,
      usd: acc.usd + amount.usd,
      gbp: acc.gbp + amount.gbp,
      wei: acc.wei + amount.wei,
    };
  }, zeroMonetaryAmount);
};

export const calculateFees = ({
  monetaryAmount,
  feesPercentagePoints,
}: {
  monetaryAmount: MonetaryAmountOutput;
  feesPercentagePoints: bigint;
}) => {
  const feesMonetaryAmount =
    feesPercentagePoints === 0n
      ? zeroMonetaryAmount
      : {
          eur: (BigInt(monetaryAmount.eur) * feesPercentagePoints) / 100n,
          usd: (BigInt(monetaryAmount.usd) * feesPercentagePoints) / 100n,
          gbp: (BigInt(monetaryAmount.gbp) * feesPercentagePoints) / 100n,
          wei: (monetaryAmount.wei * feesPercentagePoints) / 100n,
        };
  const youReceiveMonetaryAmount = {
    eur: BigInt(monetaryAmount.eur) - BigInt(feesMonetaryAmount.eur),
    usd: BigInt(monetaryAmount.usd) - BigInt(feesMonetaryAmount.usd),
    gbp: BigInt(monetaryAmount.gbp) - BigInt(feesMonetaryAmount.gbp),
    wei: monetaryAmount.wei - feesMonetaryAmount.wei,
  };

  return {
    feesMonetaryAmount,
    youReceiveMonetaryAmount,
  };
};

export const useMonetaryAmount = () => {
  const { exchangeRate } = useConfigContext();
  const {
    fiatCurrency: { code },
  } = useCurrentUserContext();

  const toMonetaryAmount = useCallback(
    (params: MonetaryAmountParams): MonetaryAmountOutput => {
      const monetaryAmount = new MonetaryAmountClass(params);

      return monetaryAmount.inCurrencies(exchangeRate.ethRates);
    },
    [exchangeRate.ethRates]
  );

  const getUserMonetaryAmount = useCallback(
    (amount: number | bigint) =>
      toMonetaryAmount({
        [code.toLowerCase()]: amount,
        referenceCurrency: code as SupportedCurrency,
      }),
    [toMonetaryAmount, code]
  );

  const getUserFiatAmount = useCallback(
    (monetaryAmount: MonetaryAmountOutput) =>
      monetaryAmount[getFiatMonetaryAmountIndex(code)],
    [code]
  );

  return { toMonetaryAmount, getUserFiatAmount, getUserMonetaryAmount };
};
