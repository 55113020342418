import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { isType } from '@sorare/core/src/gql';

import { ItemPropertiesContainer } from 'components/ItemPreview/ui';
import { AuctionEligibility } from 'components/auction/AuctionEligibility';
import Bundle from 'components/auction/Bundle';
import CardProperties from 'components/card';

import { TokenProperties_anyCard } from './__generated__/index.graphql';

type Props = {
  card: TokenProperties_anyCard;
  isBundledAuction: boolean;
  disableSportSpecific?: boolean;
  onInfoClick?: () => void;
  supensionsAndInjuries?: React.ReactNode;
};

const StyledItemPropertiesContainer = styled(ItemPropertiesContainer)<{
  spaceBetween?: boolean;
}>`
  ${({ spaceBetween }) => spaceBetween && `justify-content: space-between;`};
`;

export const TokenProperties = ({
  card,
  isBundledAuction,
  disableSportSpecific,
  onInfoClick,
  supensionsAndInjuries,
}: Props) => {
  const { currentUser } = useCurrentUserContext();

  if (isBundledAuction) {
    return (
      <StyledItemPropertiesContainer>
        <Bundle />
        {!disableSportSpecific && (
          <AuctionEligibility auction={card.latestEnglishAuction!} />
        )}
      </StyledItemPropertiesContainer>
    );
  }

  const withTransferMalus =
    currentUser &&
    card.liveSingleSaleOffer &&
    isType(card.liveSingleSaleOffer.sender, 'User') &&
    card.liveSingleSaleOffer.sender.slug !== currentUser.slug;

  if (!disableSportSpecific) {
    return (
      <StyledItemPropertiesContainer spaceBetween>
        <CardProperties
          withTransferMalus={!!withTransferMalus}
          card={card}
          onInfoClick={onInfoClick}
          supensionsAndInjuries={supensionsAndInjuries}
          cardWithStats={card}
        />
      </StyledItemPropertiesContainer>
    );
  }

  return null;
};

TokenProperties.fragments = {
  anyCard: gql`
    fragment TokenProperties_anyCard on AnyCardInterface {
      slug
      latestEnglishAuction {
        id
        ...AuctionEligibility_tokenAuction
      }
      liveSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        sender {
          ... on User {
            slug
          }
        }
      }
      ...CardProperties_anyCard
      ...CardPropertiesWithStats_anyCard
    }
    ${CardProperties.fragments.anyCard}
    ${CardProperties.fragments.anyCardWithStats}
    ${AuctionEligibility.fragments.tokenAuction}
  ` as TypedDocumentNode<TokenProperties_anyCard>,
};
