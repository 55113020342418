import classNames from 'classnames';
import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { CAPTAIN, fantasy } from '@sorare/core/src/lib/glossary';
import { unitMapping } from '@sorare/core/src/lib/style';

interface Props {
  active?: boolean;
  size?: keyof typeof unitMapping;
}

const Wrapper = styled(Horizontal).attrs({ gap: 0, center: true })<Props>`
  border-radius: 50%;
  box-shadow: -6px 12px 20px rgba(0, 0, 0, 0.3);
  width: var(--size, 32px);
  height: var(--size, 32px);
  font-size: calc(var(--size, 32px) * 3 / 5);
  font-weight: bold;
  line-height: 1;
  background-color: var(--c-neutral-1000);
  color: var(--c-static-neutral-1000);

  &.active {
    background-color: var(--c-rivals-arena);
    color: var(--c-neutral-1000);
  }
`;

export const Captain = ({ active, size = 2 }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <Wrapper
      style={{ '--size': unitMapping[size] } as CSSProperties}
      className={classNames({ active })}
      active={active}
      title={formatMessage(fantasy.captain)}
    >
      {CAPTAIN}
    </Wrapper>
  );
};

export default Captain;
