import { defineMessages } from 'react-intl';

export const rivalsExperienceMessages = defineMessages({
  title: {
    id: 'RivalsLanding.Experience.title',
    defaultMessage: 'Earn Your Respect.{br}Win Big Rewards.',
  },
  challengeTitle: {
    id: 'RivalsLanding.Experience.challengeTitle',
    defaultMessage: 'Challenge Rivals',
  },
  challengeText: {
    id: 'RivalsLanding.Experience.challengeText',
    defaultMessage:
      "Challenge friends and rivals in Sorare's all-action head-to-head gameplay. Prove you're the superior football manager.",
  },
  buildTitle: {
    id: 'RivalsLanding.Experience.buildTitle',
    defaultMessage: 'Build Lineups',
  },
  buildText: {
    id: 'RivalsLanding.Experience.buildText',
    defaultMessage:
      'With more than 350 licensed clubs, there is non-stop football action on Sorare Rivals. Each matchday, set five-player lineups for your favourite matches within a draft budget.',
  },
  tacticsTitle: {
    id: 'RivalsLanding.Experience.tacticsTitle',
    defaultMessage: 'Select Your Tactics',
  },
  tacticsText: {
    id: 'RivalsLanding.Experience.tacticsText',
    defaultMessage:
      'Are you a tactical mastermind? Choose from a range of tactics, such as Tika Taka and Gegenpressing. Each tactic tracks unique collective stats for bonus points.',
  },
  actionTitle: {
    id: 'RivalsLanding.Experience.actionTitle',
    defaultMessage: 'Feel Every Action',
  },
  actionText: {
    id: 'RivalsLanding.Experience.actionText',
    defaultMessage:
      "Track your lineup's live performance with Sorare's 48-stat scoring system. Be warned: Every action counts! Rivals will transform the way you watch football.",
  },
  prizesTitle: {
    id: 'RivalsLanding.Experience.prizesTitle',
    defaultMessage: 'Win Big Rewards',
  },
  prizesText: {
    id: 'RivalsLanding.Experience.prizesText',
    defaultMessage:
      'By proving yourself in Rivals, you unlock amazing prizes like signed football jerseys, digital player cards you can trade and sell, and much more!',
  },
  respectTitle: {
    id: 'RivalsLanding.Experience.respectTitle',
    defaultMessage: 'Earn Respect',
  },
  respectText: {
    id: 'RivalsLanding.Experience.respectText',
    defaultMessage:
      'Win and get the ultimate prize: Respect among top football managers. Sorare Rivals is a statement. So what are you waiting for? Play Rivals. Earn respect. Win rewards.',
  },
});
