import { TypedDocumentNode, gql } from '@apollo/client';

import { useMarketFeesHelperStatus_anyCard } from './__generated__/useMarketFeesHelperStatus.graphql';

export enum MarketFeeStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  PARTIALLY_ENABLED = 'partially-enabled',
}

export const isMarketFeeEnabled = (
  status: MarketFeeStatus
): status is MarketFeeStatus.ENABLED | MarketFeeStatus.PARTIALLY_ENABLED =>
  [MarketFeeStatus.ENABLED, MarketFeeStatus.PARTIALLY_ENABLED].includes(status);

export const useMarketFeesHelperStatus = (
  cards: useMarketFeesHelperStatus_anyCard[]
) => {
  const enabledTokens = cards.filter(c => c.secondaryMarketFeeEnabled);

  if (enabledTokens.length === 0) {
    return MarketFeeStatus.DISABLED;
  }

  if (enabledTokens.length === cards.length) {
    return MarketFeeStatus.ENABLED;
  }

  return MarketFeeStatus.PARTIALLY_ENABLED;
};

useMarketFeesHelperStatus.fragments = {
  anyCard: gql`
    fragment useMarketFeesHelperStatus_anyCard on AnyCardInterface {
      slug
      secondaryMarketFeeEnabled
    }
  ` as TypedDocumentNode<useMarketFeesHelperStatus_anyCard>,
};

export default useMarketFeesHelperStatus;
