import classnames from 'classnames';
import {
  Children,
  ComponentProps,
  MutableRefObject,
  ReactNode,
  UIEventHandler,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDebounce } from 'react-use';
import styled from 'styled-components';

import { range } from 'lib/arrays';
import { unitMapping } from 'lib/style';
import { desktopAndAbove } from 'style/mediaQuery';
import { hideScrollbar } from 'style/utils';

import { WithScrollButtons } from '../WithScrollButtons';

const ScrollableContent = styled.div<{
  itemToDisplay: number;
  withMask?: boolean;
  maskSize: number;
  shouldOverhang?: boolean;
  overhang: keyof typeof unitMapping;
  itemGap: keyof typeof unitMapping;
}>`
  --mask-size: calc(${({ maskSize }) => maskSize} * var(--unit));
  --item-gap: ${({ itemGap }) => unitMapping[itemGap]};
  --overhang: ${({ overhang }) => unitMapping[overhang]};
  --final-overhang: ${({ shouldOverhang }) =>
    shouldOverhang ? 'var(--overhang)' : '0px'};
  --item-wrapper-width: calc(
    (
        (100% - var(--final-overhang)) - var(--item-gap) *
          ${({ itemToDisplay }) => itemToDisplay - 1}
      ) / ${({ itemToDisplay }) => itemToDisplay}
  );
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  gap: var(--item-gap);

  /* With this negative margin technique, Scrollable won't work with flex-direction: row-reverse
   * See https://gitlab.com/sorare/frontend/-/merge_requests/15065
   */
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
  ${hideScrollbar}

  @media ${desktopAndAbove} {
    &.withMask {
      padding-inline: var(--mask-size);
      scroll-padding-inline: var(--mask-size);
      margin-inline: calc(-1 * var(--mask-size));
      mask-image: linear-gradient(
        to right,
        transparent,
        white var(--mask-size),
        white calc(100% - var(--mask-size)),
        transparent
      );
    }
  }
`;

const ItemWrapper = styled.div`
  scroll-snap-align: center;
  min-width: var(--item-wrapper-width, 100%);
  max-width: var(--item-wrapper-width, 100%);

  &:empty {
    display: none;
  }
`;

type Props = {
  children: ReactNode;
  itemToDisplay: number;
  withMask?: boolean;
  maskSize?: number;
  className?: string;
  onVisibleItemsChanged?: (items: number[]) => void;
  contained?: boolean;
  /**
   * In a mobile viewport when there are multiple items to display, show a
   * partial edge of the next offscreen item to indicate to the user that
   * there are more items to scroll to (since no arrow buttons shown in mobile).
   */
  overhang?: boolean | keyof typeof unitMapping;
  indexToScroll?: number;
  itemGap?: keyof typeof unitMapping;
  scrollContainerRef?: MutableRefObject<HTMLDivElement | null>;
  scrollButtonsProps?: Partial<ComponentProps<typeof WithScrollButtons>>;
};

export const Scrollable = ({
  children,
  withMask,
  className,
  itemToDisplay,
  onVisibleItemsChanged,
  contained,
  overhang,
  indexToScroll = 0,
  scrollContainerRef,
  itemGap = 2,
  scrollButtonsProps,
  maskSize = 5,
}: Props) => {
  const hasScrolledRef = useRef(false);
  const numberOfItems = Children.toArray(children).length;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [visibleIndexes, setVisibleIndexes] = useState(range(itemToDisplay));
  const containerRef = useRef<HTMLDivElement>(null);

  // Scroll to the indexToScroll when the component is mounted.
  // We need the useLayoutEffect here to not conflict with ScrollRestore
  useLayoutEffect(() => {
    if (hasScrolledRef.current) return;
    hasScrolledRef.current = true;

    containerRef?.current?.children[indexToScroll]?.scrollIntoView({
      behavior: scrollButtonsProps?.behavior ?? 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [indexToScroll, containerRef, scrollButtonsProps?.behavior]);

  let overhangSize: keyof typeof unitMapping = 0;
  if (overhang) {
    if (typeof overhang === 'number') {
      overhangSize = overhang;
    } else {
      overhangSize = 2;
    }
  }
  const detectVisibleIndexes: UIEventHandler<HTMLDivElement> = e => {
    const { scrollLeft, children: child } = e.target as HTMLDivElement;
    const itemWidth = child[0].clientWidth;

    const gap = itemGap * 8;
    const firstVisibleItem = Math.floor(
      (scrollLeft + 8 * overhangSize) / (itemWidth + gap)
    );

    setVisibleIndexes(
      range(itemToDisplay).map(i =>
        Math.min(firstVisibleItem + i, Children.count(children) - 1)
      )
    );
  };

  useDebounce(() => onVisibleItemsChanged?.(visibleIndexes), 50, [
    visibleIndexes,
  ]);

  return (
    <WithScrollButtons
      contained={contained}
      wrapperRef={wrapperRef}
      scrollContainerRef={containerRef}
      itemGap={itemGap}
      overhangSize={overhangSize}
      {...scrollButtonsProps}
    >
      <ScrollableContent
        itemToDisplay={itemToDisplay}
        ref={ref => {
          (containerRef as MutableRefObject<HTMLDivElement | null>).current =
            ref;
          if (scrollContainerRef) {
            scrollContainerRef.current = ref;
          }
        }}
        onScroll={onVisibleItemsChanged && detectVisibleIndexes}
        role="navigation"
        className={classnames(className, { withMask })}
        shouldOverhang={!!overhang && numberOfItems > itemToDisplay}
        overhang={overhangSize}
        itemGap={itemGap}
        maskSize={maskSize}
      >
        {Children.toArray(children).map((child, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ItemWrapper key={index}>{child}</ItemWrapper>
        ))}
      </ScrollableContent>
    </WithScrollButtons>
  );
};
