import { ReactNode } from 'react';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { Title3 } from 'atoms/typography';

import EmptyCards from './cards.svg';

const Wrapper = styled(Vertical).attrs({ gap: 2, center: true })`
  margin: calc(var(--unit) * 5) 0;
  text-align: center;
  color: var(--c-neutral-1000);
`;

const Image = styled.img`
  aspect-ratio: 125 / 100;
  width: 240px;
`;

export const Empty = ({
  title,
  description,
}: {
  title: ReactNode;
  description: ReactNode;
}) => {
  return (
    <Wrapper>
      <Image src={EmptyCards} alt="" />
      <Title3>{title}</Title3>
      <footer>{description}</footer>
    </Wrapper>
  );
};
