import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { useEventsContext } from '@sorare/core/src/contexts/events';
import {
  Level,
  useSnackNotificationContext,
} from '@sorare/core/src/contexts/snackNotification';
import { formatGqlErrors } from '@sorare/core/src/gql';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import { useCreateEthMigration } from '@sorare/core/src/hooks/starkware/useCreateEthMigration';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import { generateDealId, isHandledError } from '@sorare/core/src/lib/deal';
import {
  getMonetaryAmountIndex,
  monetaryAmountFragment,
} from '@sorare/core/src/lib/monetaryAmount';

import {
  CreateDirectOfferMutation,
  CreateDirectOfferMutationVariables,
  useCreateDirectOffer_anyCard,
} from './__generated__/useCreateDirectOffer.graphql';
import useApproveMigrator from './useApproveMigrator';
import useMigrateCards from './useMigrateCards';
import usePrepareOffer from './usePrepareOffer';

const CREATE_DIRECT_OFFER_MUTATION = gql`
  mutation CreateDirectOfferMutation($input: createDirectOfferInput!) {
    createDirectOffer(input: $input) {
      tokenOffer {
        id
        counteredOffer {
          id
          status
        }
        senderSide {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
        receiverSide {
          id
          cards: anyCards {
            slug
            liveSingleBuyOffers {
              id
            }
          }
        }
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<
  CreateDirectOfferMutation,
  CreateDirectOfferMutationVariables
>;

type CreateDirectOfferArgs = {
  receiverSlug: string;
  sendMonetaryAmount: MonetaryAmountOutput;
  receiveMonetaryAmount: MonetaryAmountOutput;
  sendTokens: useCreateDirectOffer_anyCard[];
  receiveTokens: useCreateDirectOffer_anyCard[];
  duration?: number;
  counteredOfferId?: string;
  sendAmountCurrency: SupportedCurrency;
  receiveAmountCurrency: SupportedCurrency;
};

const useCreateDirectOffer = () => {
  const [createOffer] = useMutation(CREATE_DIRECT_OFFER_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const { showNotification } = useSnackNotificationContext();
  const approveMigrator = useApproveMigrator();
  const migrateCards = useMigrateCards();
  const createEthMigration = useCreateEthMigration();
  const prepareOffer = usePrepareOffer();
  const { track } = useEventsContext();

  return useCallback(
    async (args: CreateDirectOfferArgs) => {
      const {
        receiverSlug,
        sendMonetaryAmount,
        receiveMonetaryAmount,
        sendTokens,
        receiveTokens,
        duration,
        counteredOfferId,
        sendAmountCurrency,
        receiveAmountCurrency,
      } = args;
      try {
        await approveMigrator(sendTokens);
        await createEthMigration();
        const migrationData = await migrateCards(sendTokens);

        const sendMonetaryAmountIndex =
          getMonetaryAmountIndex(sendAmountCurrency);
        const receiveMonetaryAmountIndex = getMonetaryAmountIndex(
          receiveAmountCurrency
        );
        const sendAmount = {
          amount: sendMonetaryAmount[sendMonetaryAmountIndex]?.toString(),
          currency: sendAmountCurrency,
        };
        const receiveAmount = {
          amount: receiveMonetaryAmount[receiveMonetaryAmountIndex]?.toString(),
          currency: receiveAmountCurrency,
        };

        const settlementCurrencies =
          sendMonetaryAmount.eur > 0
            ? [sendAmountCurrency]
            : [receiveAmountCurrency];

        const { approvals, errors } = await prepareOffer({
          sendAssetIds: sendTokens.map(t => t.assetId!),
          receiveAssetIds: receiveTokens.map(t => t.assetId!),
          sendAmount,
          receiveAmount,
          settlementCurrencies,
        });

        if (errors?.length) return errors;

        const result = await createOffer({
          variables: {
            input: {
              dealId: generateDealId(),
              receiveAssetIds: receiveTokens.map(t => t.assetId!),
              sendAssetIds: sendTokens.map(t => t.assetId!),
              receiverSlug,
              migrationData,
              duration,
              counteredOfferId,
              ...(counteredOfferId && { receiveAmount }),
              sendAmount,
              approvals,
            },
          },
        });

        const createErrors = result.data?.createDirectOffer?.errors || [];

        if (createErrors.length) {
          showNotification(
            'errors',
            { errors: formatGqlErrors(createErrors) },
            { level: Level.WARN }
          );
          return createErrors;
        }
        track('Send Direct Offer', {
          offer_id: result.data?.createDirectOffer?.tokenOffer?.id,
        });

        return null;
      } catch (e) {
        if (e instanceof Error && isHandledError(e)) {
          return [{ message: e.message }];
        }

        throw e;
      }
    },
    [
      track,
      approveMigrator,
      createEthMigration,
      createOffer,
      migrateCards,
      prepareOffer,
      showNotification,
    ]
  );
};

useCreateDirectOffer.fragments = {
  anyCard: gql`
    fragment useCreateDirectOffer_anyCard on AnyCardInterface {
      slug
      assetId
      ...useApproveMigrator_anyCard
      ...useMigrateCards_anyCard
    }
    ${useApproveMigrator.fragments.anyCard}
    ${useMigrateCards.fragments.anyCard}
  ` as TypedDocumentNode<useCreateDirectOffer_anyCard>,
};

export default useCreateDirectOffer;
