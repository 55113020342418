import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineS } from '@sorare/core/src/atoms/typography';
import { isType } from '@sorare/core/src/gql';

import JerseyRewardPreview from 'components/rewards/deliverableItem/DeliverableItemFooter/jersey/JerseyPreview';

import { DeliverableItemPreview_deliverableItem } from './__generated__/index.graphql';

type Props = {
  deliverableItem: DeliverableItemPreview_deliverableItem;
  hideSignedVariant?: boolean;
};

const Card = styled(Horizontal).attrs({ gap: 0, center: true })`
  max-width: 180px;
  aspect-ratio: 86 / 100;
  text-align: center;
  padding: var(--double-unit);
`;

const DeliverableItemPreview = ({
  deliverableItem,
  hideSignedVariant,
}: Props) => {
  if (isType(deliverableItem, 'JerseyDeliverableItem')) {
    return (
      <JerseyRewardPreview
        jersey={deliverableItem}
        hideSignedVariant={hideSignedVariant}
      />
    );
  }
  return (
    <Card>
      <HeadlineS>{deliverableItem.name}</HeadlineS>
    </Card>
  );
};

DeliverableItemPreview.fragments = {
  deliverableItem: gql`
    fragment DeliverableItemPreview_deliverableItem on DeliverableItemInterface {
      slug
      id
      name
      ... on JerseyDeliverableItem {
        slug
        id
        ...JerseyRewardPreview_JerseyDetails
      }
      ... on WearableDeliverableItem {
        slug
        id
        signed
      }
    }
    ${JerseyRewardPreview.fragments.jersey}
  ` as TypedDocumentNode<DeliverableItemPreview_deliverableItem>,
};

export default DeliverableItemPreview;
