import { TypedDocumentNode, gql } from '@apollo/client';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

import { SocialUser } from 'components/user/SocialUser';

import { TwitterUser_userProfile } from './__generated__/index.graphql';

const Root = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;
type Props = {
  userProfile: TwitterUser_userProfile;
  coloredIcon?: boolean;
};

export const TwitterUser = ({
  userProfile: { twitterUsername },
  coloredIcon,
}: Props) => {
  if (!twitterUsername) return null;

  return (
    <Root
      href={`https://x.com/${twitterUsername}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <SocialUser
        nickname={twitterUsername}
        icon={faXTwitter}
        prefix="@"
        color={coloredIcon ? 'var(--c-social-x-twitter)' : undefined}
      />
    </Root>
  );
};

TwitterUser.fragments = {
  userProfile: gql`
    fragment TwitterUser_userProfile on UserProfile {
      id
      twitterUsername
    }
  ` as TypedDocumentNode<TwitterUser_userProfile>,
};
