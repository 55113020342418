import { generatePath } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import {
  FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG,
  FOOTBALL_MARKET,
  FOOTBALL_MY_CLUB_SLUG,
  FOOTBALL_MY_CLUB_SLUG_HISTORY,
  FOOTBALL_PLAY_PRO,
  FOOTBALL_PLAY_PRO_MY_LINEUPS_ID,
  FOOTBALL_PLAY_RIVALS_ARENA,
  FOOTBALL_PLAY_RIVALS_GAMES_ID,
  INVITE,
  LANDING,
  MLB,
  MLB_COMPOSE_TEAM_LEADERBOARDSLUG,
  MLB_MARKET,
  MLB_MY_CLUB_SLUG_HISTORY,
  MLB_PLAY_PRO,
  MLB_PLAY_PRO_FIXTURE_MY_LINEUPS_LINEUP,
  NBA_COMPOSE_TEAM_LEADERBOARDSLUG,
  NBA_MARKET,
  NBA_MY_CLUB_SLUG_HISTORY,
  NBA_PLAY,
} from '@sorare/core/src/constants/__generated__/routes';
import { FOOTBALL_HOME, NBA_HOME } from '@sorare/core/src/constants/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSentryContext } from '@sorare/core/src/contexts/sentry';
import useQueryString from '@sorare/core/src/hooks/useQueryString';

function parseIntent(uri: string) {
  // HACK: Firefox and Chrome currently do not parse non special urls correctly
  // https://github.com/nodejs/node/issues/37192#issuecomment-772004612
  // We work around this by setting a special scheme to extract host, pathname and searchParams
  const { host, pathname, searchParams } = new URL(
    uri.replace(/^sorare:\/\//, 'http://')
  );

  return { host, pathname, searchParams };
}

// The flutter app redirects to this page when it receives a push notification with a deeplink. This hook maps the deeplink to the right page
const useResolveDeeplink = (
  uri: string | null | undefined
): null | string | { to: string; state: unknown } => {
  const { currentUser } = useCurrentUserContext();
  const { sendSafeError } = useSentryContext();

  if (!uri || !currentUser) {
    sendSafeError(new Error('Deeplink without uri or currentUser'));
    return null;
  }

  const { host, pathname, searchParams } = parseIntent(uri);

  if (host === 'so5fixture' || (host === 'so5' && pathname === '/fixture')) {
    if (searchParams.get('type') === 'upcoming') {
      return FOOTBALL_PLAY_PRO;
    }
    return generatePath(FOOTBALL_MY_CLUB_SLUG_HISTORY, {
      slug: currentUser.slug,
    });
  }
  if (host === 'nbafixture' || (host === 'nba' && pathname === '/fixture')) {
    if (searchParams.get('type') === 'upcoming') {
      return NBA_PLAY;
    }
    return generatePath(NBA_MY_CLUB_SLUG_HISTORY, { slug: currentUser.slug });
  }
  if (host === 'mlbfixture' || (host === 'mlb' && pathname === '/fixture')) {
    if (searchParams.get('type') === 'upcoming') {
      return MLB_PLAY_PRO;
    }
    return generatePath(MLB_MY_CLUB_SLUG_HISTORY, { slug: currentUser.slug });
  }

  if (host === 'so5lineup' || (host === 'so5' && pathname === '/lineup')) {
    return generatePath(FOOTBALL_PLAY_PRO_MY_LINEUPS_ID, {
      id: searchParams.get('id'),
    });
  }
  if (host === 'mlblineup' || (host === 'mlb' && pathname === '/lineup')) {
    return generatePath(MLB_PLAY_PRO_FIXTURE_MY_LINEUPS_LINEUP, {
      fixture: 'ongoing',
      lineup: searchParams.get('id'),
    });
  }

  if (
    host === 'so5composeteam' ||
    (host === 'so5' && pathname === '/composeteam')
  ) {
    return generatePath(FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG, {
      leaderboardSlug: searchParams.get('slug'),
    });
  }
  if (
    host === 'nbacomposeteam' ||
    (host === 'nba' && pathname === '/composeteam')
  ) {
    return generatePath(NBA_COMPOSE_TEAM_LEADERBOARDSLUG, {
      leaderboardSlug: searchParams.get('slug'),
    });
  }
  if (
    host === 'mlbcomposeteam' ||
    (host === 'mlb' && pathname === '/composeteam')
  ) {
    return generatePath(MLB_COMPOSE_TEAM_LEADERBOARDSLUG, {
      leaderboardSlug: searchParams.get('slug'),
    });
  }

  if (
    host === 'so5' &&
    (pathname === '/rivals/game' || pathname === '/rivals/game/story')
  ) {
    const path = generatePath(FOOTBALL_PLAY_RIVALS_GAMES_ID, {
      id: searchParams.get('id'),
    });

    if (searchParams.get('action') === 'starting_lineup') {
      return { to: path, state: { createLineup: true } };
    }

    return path;
  }

  if (host === 'managerprofile') {
    return generatePath(FOOTBALL_MY_CLUB_SLUG, {
      slug: searchParams.get('slug'),
    });
  }

  if (host === 'so5' && pathname === '/rivals/matches') {
    return FOOTBALL_PLAY_RIVALS_ARENA;
  }

  if (host === 'so5' && pathname === '/rivals/arena/claim') {
    return generatePath(FOOTBALL_PLAY_RIVALS_ARENA);
  }

  if (host === 'so5' && pathname === '/rivals/arena') {
    return generatePath(FOOTBALL_PLAY_RIVALS_ARENA);
  }

  if (host === 'so5' && pathname === '/market') {
    return FOOTBALL_MARKET;
  }
  if (host === 'nba' && pathname === '/market') {
    return NBA_MARKET;
  }
  if (host === 'mlb' && pathname === '/market') {
    return MLB_MARKET;
  }

  if (host === 'mlbhome' || (host === 'mlb' && pathname === '/home')) {
    return MLB;
  }

  if (host === 'nbahome' || (host === 'nba' && pathname === '/home')) {
    return NBA_HOME;
  }

  if (host === 'so5home' || (host === 'so5' && pathname === '/home')) {
    return FOOTBALL_HOME;
  }

  if (host === 'referrals') {
    return INVITE;
  }

  sendSafeError(new Error(`Unhandled deeplink: ${uri}`));
  return null;
};

export const Deeplink = () => {
  const uri = useQueryString('uri');
  const to = useResolveDeeplink(uri);

  if (to === null) {
    return <Navigate to={LANDING} replace />;
  }

  if (typeof to === 'string') {
    return <Navigate to={to} replace />;
  }

  return <Navigate to={to.to} state={to.state} replace />;
};

export default Deeplink;
