import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import { Sport } from '__generated__/globalTypes';
import { sportOfPlayerType } from 'lib/players';

import {
  FavoriteQuery,
  FavoriteQueryVariables,
} from './__generated__/useLoadAllSubscriptions.graphql';

export type MySubscriptionElement = NonNullable<
  FavoriteQuery['currentUser']
>['mySubscriptions']['nodes'][number];

const FOLLOW_QUERY = gql`
  query FavoriteQuery($cursor: String, $types: [SubscribableType!]) {
    currentUser {
      slug
      mySubscriptions(
        after: $cursor
        first: 500
        sortType: DESC
        types: $types
      ) {
        nodes {
          slug
          anySubscribable {
            slug
          }
          preferences {
            slug
            notifyForRarities
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
` as TypedDocumentNode<FavoriteQuery, FavoriteQueryVariables>;

const emptyCatalogBySport = {
  [Sport.FOOTBALL]: [],
  [Sport.NBA]: [],
  [Sport.BASEBALL]: [],
} as Record<Sport, MySubscriptionElement[]>;

export const favoritePlayerType: Record<Sport, string> = {
  [Sport.FOOTBALL]: 'Player',
  [Sport.BASEBALL]: 'BaseballPlayer',
  [Sport.NBA]: 'NBAPlayer',
};

const sportOfCardType = {
  Card: Sport.FOOTBALL,
  BaseballCard: Sport.BASEBALL,
  NBACard: Sport.NBA,
};

interface Props {
  skip?: boolean;
}

export const useLoadAllSubscriptions = ({ skip }: Props) => {
  const [mySubscriptions, setMySubscriptions] = useState<
    MySubscriptionElement[]
  >([]);
  const [loadMore, { data, loading }] = useLazyQuery(FOLLOW_QUERY);
  const [mySubscriptionsLoaded, setMySubscriptionsLoaded] =
    useState<boolean>(false);
  const [cursor, setCursor] = useState<string | null | undefined>(undefined);

  // load first page, once
  useEffect(() => {
    if (!skip) loadMore();
  }, [skip, loadMore]);

  // load next pages
  useEffect(() => {
    if (loading || !data || skip) {
      return;
    }

    const hasMore = data.currentUser?.mySubscriptions.pageInfo.hasNextPage;
    if (hasMore) {
      const endCursor = data.currentUser?.mySubscriptions.pageInfo.endCursor;
      if (endCursor !== cursor) {
        setCursor(endCursor);
        loadMore({ variables: { cursor: endCursor } });
      }
    } else {
      setMySubscriptionsLoaded(true);
    }
  }, [skip, data, loading, cursor, setCursor, loadMore]);

  // append to `mySubscriptions`
  useEffect(() => {
    if (data?.currentUser?.mySubscriptions.nodes) {
      setMySubscriptions(m => [
        ...m,
        ...(data?.currentUser?.mySubscriptions.nodes || []),
      ]);
    }
  }, [data]);

  return useMemo(() => {
    if (!mySubscriptionsLoaded) {
      return {
        loading: true,
        favoritePlayersBySport: emptyCatalogBySport,
        favoriteCardsBySport: emptyCatalogBySport,
      };
    }

    return (mySubscriptions || []).reduce(
      (map, item) => {
        const sportOfPlayer: Sport | undefined = (sportOfPlayerType as any)[
          item.anySubscribable.__typename
        ];

        if (sportOfPlayer) {
          return {
            ...map,
            favoritePlayersBySport: {
              ...map.favoritePlayersBySport,
              [sportOfPlayer]: [
                ...map.favoritePlayersBySport[sportOfPlayer],
                item,
              ],
            },
          };
        }

        const sportOfCard: Sport | undefined = (sportOfCardType as any)[
          item.anySubscribable.__typename
        ];
        if (sportOfCard) {
          return {
            ...map,
            favoriteCardsBySport: {
              ...map.favoriteCardsBySport,
              [sportOfCard]: [...map.favoriteCardsBySport[sportOfCard], item],
            },
          };
        }
        return map;
      },
      {
        loading: false,
        favoritePlayersBySport: emptyCatalogBySport,
        favoriteCardsBySport: emptyCatalogBySport,
      }
    );
  }, [mySubscriptions, mySubscriptionsLoaded]);
};
