import { TypedDocumentNode, gql } from '@apollo/client';

import {
  AmountWithConversion,
  Props as AmountWithConversionProps,
} from '@sorare/core/src/components/buyActions/AmountWithConversion';
import { zeroMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { useStoreAmount } from '@sorare/core/src/hooks/useStoreAmount';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { PrimaryOfferPrice_primaryOffer } from './__generated__/index.graphql';

type Props = {
  primaryOffer: PrimaryOfferPrice_primaryOffer;
} & Omit<
  AmountWithConversionProps,
  'monetaryAmount' | 'actualMain' | 'actualExponent'
>;

export const PrimaryOfferPrice = ({ primaryOffer, ...props }: Props) => {
  const { storeMain, storeExponent } = useStoreAmount({
    monetaryAmount: primaryOffer.price || zeroMonetaryAmount,
    googlePrice: primaryOffer.googlePrice,
    applePrice: primaryOffer.applePrice,
  });

  if (!primaryOffer.price) return null;

  return (
    <AmountWithConversion
      monetaryAmount={primaryOffer.price}
      actualMain={storeMain}
      actualExponent={storeExponent}
      {...props}
      hideExponent={false}
    />
  );
};

PrimaryOfferPrice.fragments = {
  primaryOffer: gql`
    fragment PrimaryOfferPrice_primaryOffer on TokenPrimaryOffer {
      id
      price {
        ...MonetaryAmountFragment_monetaryAmount
      }
      googlePrice(countryCode: $googlePlayStoreCountryCode)
        @include(if: $isAndroidApp)
      applePrice @include(if: $isIosApp) {
        ...MonetaryAmountFragment_monetaryAmount
      }
    }
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<PrimaryOfferPrice_primaryOffer>,
};
