import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import SportSwitcher from '@sorare/core/src/contexts/sport/SportSwitcher';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';

import { CardPageContent as BaseballCardPage } from '@sorare/baseball/src/components/cards/CardPageContent';
import { CardPage as FootballCardPage } from '@sorare/football/src/components/card/CardPage';
import { CardPageContent as NBACardPage } from '@sorare/nba/src/components/cards/CardPageContent';

import {
  AnyCardSportQuery,
  AnyCardSportQueryVariables,
} from './__generated__/index.graphql';

const ANY_CARD_QUERY = gql`
  query AnyCardSportQuery($slug: String!) {
    anyCard(slug: $slug) {
      slug
      sport
    }
  }
` as TypedDocumentNode<AnyCardSportQuery, AnyCardSportQueryVariables>;

type Props = { slug: string };

const AnyCardPage = ({ slug }: Props) => {
  const { data } = useQuery(ANY_CARD_QUERY, {
    variables: { slug: slug || '' },
    skip: !slug,
    fetchPolicy: 'cache-first',
  });

  const sport = data?.anyCard?.sport;

  const Page = useMemo(() => {
    if (sport === Sport.FOOTBALL) {
      return FootballCardPage;
    }
    if (sport === Sport.NBA) {
      return NBACardPage;
    }
    if (sport === Sport.BASEBALL) {
      return BaseballCardPage;
    }
    return undefined;
  }, [sport]);

  if (!Page) return null;

  return (
    <>
      <SportSwitcher sport={sport!} />
      <Page />
    </>
  );
};

export const AnyCardPageDialog = () => {
  const slug = useDialogParam(DialogKey.card);
  return <AnyCardPage slug={slug || ''} />;
};

export const AnyCardPageRoute = () => {
  const { slug } = useParams();
  return <AnyCardPage slug={slug || ''} />;
};

export default AnyCardPageDialog;
