import { Suspense, useEffect, useState } from 'react';

import { ErrorBoundary } from '@sorare/error-boundary';

import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { lazy } from 'lib/retry';

const LazySidetab = lazy(async () => import('./LazySidetab'));

// https://github.com/Typeform/embed/blob/main/packages/embed/src/utils/load-options-from-attributes.ts
const COMMA_PLACEHOLDER = '%ESCAPED_COMMA%';
const transformRecord = (
  value: string | null
): Record<string, string> | undefined => {
  if (!value) {
    return undefined;
  }
  const arrayOfRecordStrings = value
    .replace(/\\,/g, COMMA_PLACEHOLDER)
    .split(',')
    .filter(v => !!v)
    .map(v => v.replace(COMMA_PLACEHOLDER, ','));
  return arrayOfRecordStrings.reduce((record, recordString) => {
    const match = recordString.match(/^([^=]+)=(.*)$/);
    if (match) {
      const [, key, v] = match;
      return { ...record, [key.trim()]: v };
    }
    return record;
  }, {});
};

export /**
 * This component will listen all posted message and will render a Typeform Sidetab component when a message
 * with name 'typeform-sidetab' is received.
 *
 * In Braze, you can create a campaign with Custom HTML Code like the following:
 *
 * ```html
 * <script>
 * window.parent.postMessage({
 *   name: 'typeform-sidetab',
 *   id: "RmLwyuie",
 *   customIcon: "https://images.typeform.com/images/mYq8cAVYDt3M",
 *   opacity: 100,
 *   autoClose: true,
 *   transitiveSearchParams: true,
 *   buttonColor: "#FFFFFF",
 *   buttonText: "Answer our survey",
 *   medium: "snippet",
 *   hidden: "email=",
 *   style: "all:unset;"
 * });
 * </script>
 * ```
 */
const SidetabHandler = () => {
  const [props, setProps] = useState<any>();
  const { isMobileApp } = useIsMobileApp();

  useEffect(() => {
    const handler = (message: any) => {
      if (message?.data?.name === 'typeform-sidetab') {
        document.body.classList.remove('ab-pause-scrolling');
        const { name, hidden, ...rest } = message.data;
        setProps({ ...rest, hidden: transformRecord(hidden) });
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  if (!props || isMobileApp) return null;

  // ensure no configuration error can break the page
  return (
    <ErrorBoundary fallback={null}>
      <Suspense>
        <LazySidetab
          {...props}
          onClose={() => {
            setTimeout(() => {
              setProps(undefined);
            }, 2_000);
          }}
        />
      </Suspense>
    </ErrorBoundary>
  );
};
