import { CSSProperties, Children, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  isolation: isolate;
  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  & > * + * {
    /** Shift non-1st cards left to create an overlapping group. */
    margin-left: var(--offset);
  }
`;
const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  children?: ReactNode;
  className?: string;
  offset?: number;
  reverse?: boolean;
};

export const OverlapItems = ({
  children,
  className,
  offset = 6,
  reverse,
}: Props) => {
  return (
    <Wrapper
      className={className}
      style={{ '--offset': `${-offset}px` } as CSSProperties}
    >
      {Children.toArray(children).map((child, index) => (
        <StyledSpan
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={reverse ? { zIndex: Children.count(children) - index } : {}}
        >
          {child}
        </StyledSpan>
      ))}
    </Wrapper>
  );
};
