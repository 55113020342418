import { unitMapping } from '@sorare/core/src/lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
};

export const Cards = ({ size = 2, className }: Props) => {
  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.23008 1.4583C5.13993 1.1271 4.79953 0.930591 4.46763 1.01813L0.609138 2.03582C0.27351 2.12434 0.0743072 2.46939 0.165476 2.80431L1.87377 9.07992C1.96393 9.41112 2.30432 9.60763 2.63623 9.52009L2.9173 9.44595L2.89619 9.5235L7.36381 10.7018C7.69571 10.7894 8.0361 10.5929 8.12626 10.2617L9.83456 3.98607C9.92573 3.65115 9.72652 3.3061 9.3909 3.21758L5.42418 2.17135L5.23008 1.4583ZM4.65994 8.98633L6.49472 8.5024C6.83035 8.41388 7.02955 8.06883 6.93838 7.73391L5.71814 3.25124L8.79534 4.06286L7.26787 9.67418L4.65994 8.98633Z"
        fill="currentColor"
      />
    </svg>
  );
};
