import { useIntl } from 'react-intl';

import { CardIcon } from 'components/stats/CardIcon';
import {
  StatKey,
  cardStatKeys,
  decisiveStatKeys,
  statLabels,
} from 'lib/scoring';
import { unitMapping } from 'lib/style';

import assist from './assets/assist.svg';
import clean_sheet_gk from './assets/clean_sheet_gk.svg';
import clearance_off_the_line from './assets/clearance_off_the_line.svg';
import error_leading_to_goal from './assets/error_leading_to_goal.svg';
import goal from './assets/goal.svg';
import goal_conceded from './assets/goal_conceded.svg';
import last_man_tackle from './assets/last_man_tackle.svg';
import penalty_conceded from './assets/penalty_conceded.svg';
import penalty_saved from './assets/penalty_saved.svg';
import penalty_scored from './assets/penalty_scored.svg';
import three_goals_conceded from './assets/three_goals_conceded.svg';

export const isDecisiveStatKey = (key: string): key is StatKey =>
  (decisiveStatKeys as string[]).includes(key);
export const isCardStatKey = (key: string): key is StatKey =>
  cardStatKeys.includes(key);

type Props = {
  statKey: string;
  className?: string;
  size?: keyof typeof unitMapping;
};
export const StatIcon = ({ statKey, className, size = 2 }: Props) => {
  const { formatMessage } = useIntl();

  if (!(statKey in statLabels)) {
    return null;
  }

  const title = formatMessage(statLabels[statKey as keyof typeof statLabels]);

  if (isCardStatKey(statKey)) {
    if (statKey === 'yellow_card') {
      return <CardIcon className={className} color="yellow" size={size} />;
    }
    if (statKey === 'red_card') {
      return <CardIcon className={className} color="red" size={size} />;
    }
    return null;
  }

  let image;
  if (isDecisiveStatKey(statKey)) {
    if (statKey === 'penalties_saved' || statKey === 'penalty_save') {
      image = penalty_saved;
    }
    if (statKey === 'penalty_conceded') {
      image = penalty_conceded;
    }
    if (statKey === 'goals_conceded') {
      image = goal_conceded;
    }
    if (statKey === 'three_goals_conceded') {
      image = three_goals_conceded;
    }
    if (statKey === 'own_goals') {
      image = goal_conceded;
    }
    if (statKey === 'goals') {
      image = goal;
    }
    if (statKey === 'assist_penalty_won') {
      image = penalty_scored;
    }
    if (statKey === 'clean_sheet_60') {
      image = clean_sheet_gk;
    }
    if (statKey === 'adjusted_goal_assist' || statKey === 'goal_assist') {
      image = assist;
    }
    if (statKey === 'clearance_off_line') {
      image = clearance_off_the_line;
    }
    if (statKey === 'error_lead_to_goal') {
      image = error_leading_to_goal;
    }
    if (statKey === 'last_man_tackle') {
      image = last_man_tackle;
    }
  }

  if (!image) {
    return null;
  }

  return (
    <img
      className={className}
      width={unitMapping[size]}
      height={unitMapping[size]}
      title={title}
      src={image}
      alt=""
    />
  );
};
