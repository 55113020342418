import { useMemo } from 'react';

import { useInGameNotificationContext } from 'contexts/inGameNotification';
import { importantNotifications } from 'lib/notifications';

export const useImportantNotification = () => {
  const { notifications } = useInGameNotificationContext();

  const importantNotification = useMemo(() => {
    if (notifications.length) {
      return notifications
        .filter(n => n && !n.read)
        .find(n => n && importantNotifications.includes(n.name));
    }
    return null;
  }, [notifications]);

  return importantNotification;
};
