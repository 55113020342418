import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const Home = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    aria-labelledby={titleId}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.25 7.5V18.75H7.5V13.75C7.5 12.3693 8.61929 11.25 10 11.25C11.3807 11.25 12.5 12.3693 12.5 13.75V18.75H18.75V7.5L10 0L1.25 7.5Z"
      fill="currentColor"
    />
  </svg>
);
