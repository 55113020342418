import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  ShopItemType,
  Sport,
  updateSportProfileInput,
} from '@sorare/core/src/__generated__/globalTypes';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import useUpdateSportProfile from '@sorare/core/src/hooks/useUpdateSportProfile';

import { ShopItemLogo } from 'components/shopItems/ShopItemDialog/types';
import { BuyButton } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/BuyButton';
import { useOnBuyButtonClick } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/BuyButton/useOnBuyButtonClick';
import Details from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/Details';
import ItemImagePreview from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/ItemImagePreview';
import { Requirements } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/Requirements';

import { SkinContent_clubShopItem } from './__generated__/index.graphql';

const CenteredLoadingButton = styled(LoadingButton)`
  align-self: center;
`;

type Props = {
  item: SkinContent_clubShopItem;
  itemEquipped?: boolean;
  inventory: boolean;
  onBuy?: () => void;
  onSelect?: (shopItem: ShopItemLogo) => void;
  userCoinBalance: number;
};

export const SkinContent = ({
  item,
  itemEquipped,
  inventory,
  onBuy,
  onSelect,
  userCoinBalance,
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { sport } = useSportContext();
  const [currentStep, setCurrentStep] = useState<'step1' | 'step2'>(
    inventory || item.myPurchasesCount > 0 ? 'step2' : 'step1'
  );
  const { onBuyButtonClick, loading } = useOnBuyButtonClick(
    () => setCurrentStep('step2'),
    onBuy
  );
  const [loadingEquip, setLoadingEquip] = useState(false);
  const updateSportProfile = useUpdateSportProfile();

  const equipSkin = async () => {
    setLoadingEquip(true);

    const payload: updateSportProfileInput = { sport: sport || Sport.FOOTBALL };
    if (item.position === ShopItemType.CLUB_BADGE) {
      payload.clubBadgeId = item.id;
    } else if (item.position === ShopItemType.BACKGROUND) {
      payload.backgroundId = item.id;
    } else if (item.position === ShopItemType.ACHIEVEMENT_BADGE) {
      const currentBadgesIds = (
        currentUser?.footballUserProfile?.achievementBadges || []
      ).map(badge => badge?.id || null);
      const firstEmptySlotIndex = currentBadgesIds.findIndex(
        badge => badge === null
      );
      currentBadgesIds[firstEmptySlotIndex > 0 ? firstEmptySlotIndex : 0] =
        item.id;
      payload.achievementBadgesIds = currentBadgesIds;
    }
    await updateSportProfile(payload);
    setLoadingEquip(false);
  };

  return (
    <>
      <ItemImagePreview
        pictureUrl={item.pictureUrl}
        name={item.name}
        type={item.position}
      />
      <Details item={item} itemEquipped={itemEquipped} inventory={inventory} />
      {currentStep === 'step1' ? (
        <>
          {item.disabled && <Requirements item={item} />}
          <BuyButton
            item={item}
            onClick={() => onBuyButtonClick(item.id)}
            loading={loading}
            userCoinBalance={userCoinBalance}
          />
        </>
      ) : (
        <CenteredLoadingButton
          disabled={itemEquipped}
          loading={loadingEquip}
          color="primary"
          size="medium"
          onClick={() => {
            if (onSelect) {
              onSelect(item);
            } else {
              equipSkin();
            }
          }}
        >
          <FormattedMessage
            id="ClubShop.ItemPreviewDialog.Cta.Equip"
            defaultMessage="Equip"
          />
        </CenteredLoadingButton>
      )}
    </>
  );
};

SkinContent.fragments = {
  clubShopItem: gql`
    fragment SkinContent_clubShopItem on ClubShopItem {
      ... on ShopItemInterface {
        id
        pictureUrl
        name
        position
        myPurchasesCount
        myLimitResetAt
        disabled
      }
      ...Details_shopItem
      ...Requirements_clubShopItem
      ...BuyButton_clubShopItem
    }
    ${Details.fragments.shopItem}
    ${Requirements.fragments.clubShopItem}
    ${BuyButton.fragments.clubShopItem}
  ` as TypedDocumentNode<SkinContent_clubShopItem>,
};
