import {
  faChevronDown,
  faChevronUp,
  faEllipsis,
} from '@fortawesome/pro-regular-svg-icons';
import { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import { ElementType, ReactElement, useMemo, useState } from 'react';
import {
  UseRefinementListProps,
  useInstantSearch,
  useRefinementList,
} from 'react-instantsearch';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useDebounce } from 'react-use';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { FontAwesomeIcon } from 'atoms/icons';
import useScreenSize from 'hooks/device/useScreenSize';
import { useEvents } from 'lib/events/useEvents';
import { glossary } from 'lib/glossary';

import { FilterSearchInput } from '../FilterSearchInput';
import { FilterSection } from '../FilterSection';
import { Option as SearchOption } from '../Option';

interface OptionType {
  option: string;
}
interface AtomListProps {
  attribute: string;
  formatOption?: (
    value: string,
    formatMessage: (message: MessageDescriptor) => string
  ) => string;
  renderBefore?: (option: string) => ReactElement;
  AfterOption?: ElementType<OptionType>;
  searchable?: boolean;
  items: RefinementListItem[];
  refine: (value: string) => void;
  searchForItems?: (q: string) => void;
  hide?: (items: RefinementListItem[]) => boolean;
  isFromSearch?: boolean;
  hasExhaustiveItems?: boolean;
  isShowingMore?: boolean;
  toggleShowMore?: () => void;
  visible: boolean;
}

const ShowMoreButton = styled(Button)`
  &.compact {
    padding: 0;
    color: var(--c-neutral-600);
    font-weight: unset;
  }
`;

const Truncated = styled.p`
  color: var(--c-neutral-600);
`;
export interface RefineListProps
  extends Omit<UseRefinementListProps, 'limit' | 'showMoreLimit'>,
    Omit<
      AtomListProps,
      | 'items'
      | 'refine'
      | 'searchForItems'
      | 'hasExhaustiveItems'
      | 'toggleShowMore'
      | 'isShowingMore'
    > {}

const identity = (name: string) => name;

export const AtomList = ({
  attribute,
  formatOption = identity,
  renderBefore,
  AfterOption,
  searchable = false,
  refine,
  items,
  searchForItems,
  hide = undefined,
  isFromSearch,
  hasExhaustiveItems,
  toggleShowMore,
  isShowingMore,
  visible,
}: AtomListProps) => {
  const { formatMessage } = useIntl();
  const track = useEvents();
  const [search, setSearch] = useState('');

  useDebounce(() => searchForItems && searchForItems(search), 300, [search]);

  const handleChange = (event: {
    target: {
      value: string;
    };
  }) => {
    setSearch(event.target.value);
  };

  const doRefine = (value: string) => {
    setSearch('');
    refine(value);
    track('Use Market Filter', {
      filterName: attribute,
      filterValue: value,
    });
  };

  const hidden = !isFromSearch && (!items.length || hide?.(items));

  const showMore =
    !isFromSearch && toggleShowMore && (!hasExhaustiveItems || isShowingMore);

  if (!visible) return <FilterSection hidden={hidden} />;

  return (
    <FilterSection
      hidden={hidden}
      search={
        searchable ? (
          <FilterSearchInput handleChange={handleChange} value={search} />
        ) : null
      }
    >
      {items.map(item => (
        <SearchOption
          variant="checkbox"
          key={item.value}
          label={formatOption(item.label, formatMessage)}
          before={renderBefore ? renderBefore(item.label) : null}
          after={AfterOption && <AfterOption option={item.label} />}
          onClick={() => doRefine(item.value)}
          active={item.isRefined}
          count={item.count}
        />
      ))}
      {showMore && (
        <>
          {isShowingMore && !hasExhaustiveItems && (
            <Truncated>
              <FontAwesomeIcon icon={faEllipsis} />
            </Truncated>
          )}
          <ShowMoreButton
            size="compact"
            color="transparent"
            onClick={toggleShowMore}
            startIcon={
              <FontAwesomeIcon
                icon={isShowingMore ? faChevronUp : faChevronDown}
              />
            }
          >
            <FormattedMessage
              {...(isShowingMore ? glossary.showLess : glossary.showMore)}
            />
          </ShowMoreButton>
        </>
      )}
    </FilterSection>
  );
};

export const MAX_SHOW_MORE_LIMIT = 40;

export const RefineList = ({
  attribute,
  visible,
  ...rest
}: RefineListProps) => {
  const { up: isTabletOrDesktop } = useScreenSize('tablet');
  const limit = isTabletOrDesktop ? 10 : MAX_SHOW_MORE_LIMIT / 2;
  const showMoreLimit = MAX_SHOW_MORE_LIMIT;
  const {
    items,
    refine,
    searchForItems,
    isFromSearch,
    hasExhaustiveItems,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList({
    limit,
    showMoreLimit,
    attribute,
    ...rest,
  });
  const { indexUiState } = useInstantSearch();

  const allItems = useMemo(() => {
    const uiRefinements = indexUiState.refinementList?.[attribute] || [];

    const optimisticRefinedItems = items.map(item => ({
      ...item,
      // we rather consider the refinement state from the UI
      // so that we don't rely on the Algolia answer to come back to reflect the checkbox state
      isRefined: !!uiRefinements.find(v => v === item.value),
    }));

    // if you load the page with more refinements than the actual
    // number of facet values retrieved by the widget, we need to
    // append them to the list, assuming counts of 0 (as we don't know the count - will be hidden).
    const extraItems = uiRefinements
      .map(v =>
        items.find(item => item.value === v)
          ? undefined
          : {
              label: v,
              value: v,
              isRefined: true,
              count: 0,
            }
      )
      .filter(Boolean);

    return [...optimisticRefinedItems, ...extraItems];
  }, [attribute, indexUiState.refinementList, items]);

  return (
    <AtomList
      {...rest}
      attribute={attribute}
      visible={visible}
      refine={refine}
      items={allItems}
      searchForItems={searchForItems}
      isFromSearch={isFromSearch}
      hasExhaustiveItems={hasExhaustiveItems}
      isShowingMore={isShowingMore}
      toggleShowMore={toggleShowMore}
    />
  );
};
