import { FormattedMessage } from 'react-intl';

import { CreateFiatWalletSteps } from '../../type';
import { Content } from '../Content';

const news = [
  {
    id: 'expand',
    title: (
      <FormattedMessage
        id="walletSetup.activateYourCashWallet.expand.title"
        defaultMessage="Expand your reach"
      />
    ),
    desc: (
      <FormattedMessage
        id="walletSetup.activateYourCashWallet.expand.desc"
        defaultMessage="Get access to tens of thousands of Managers using Cash to buy cards in Manager Sales."
      />
    ),
  },
  {
    id: 'deposit',
    title: (
      <FormattedMessage
        id="walletSetup.activateYourCashWallet.listings.title"
        defaultMessage="Control your listings"
      />
    ),
    desc: (
      <FormattedMessage
        id="walletSetup.activateYourCashWallet.listings.desc"
        defaultMessage="Choose the currency your listings will be based on (Cash or ETH). You can always choose to list exclusively in ETH."
      />
    ),
  },
];
type Props = {
  setStep: (tab: CreateFiatWalletSteps) => void;
  onDismiss?: () => void;
};
export const ActivateYourCashWallet = (props: Props) => {
  return (
    <Content
      {...props}
      news={news}
      title={
        <FormattedMessage
          id="walletSetup.activateYourCashWallet.title"
          defaultMessage="Activate your Cash Wallet"
        />
      }
    />
  );
};
