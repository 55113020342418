import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';

import {
  Sport,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import {
  AcceptedCurrenciesValue,
  useAcceptedCurrencies,
} from '@sorare/core/src/hooks/useAcceptedCurrencies';
import { useMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { WalletPaymentMethod } from '@sorare/core/src/lib/paymentMethod';

import NewOfferBuilder, {
  SharedProps as NewOfferBuilderSharedProps,
} from '../NewOfferBuilder';
import {
  NewPrefilledOfferBuilder_publicUserInfoInterface,
  NewPrefilledOfferBuilder_tokenOffer,
} from './__generated__/index.graphql';

export type Props = NewOfferBuilderSharedProps & {
  offer: NewPrefilledOfferBuilder_tokenOffer;
  to: NewPrefilledOfferBuilder_publicUserInfoInterface;
};

const NewPrefilledOfferBuilder = ({
  onClose,
  to,
  offer,
  currentUser,
}: Props) => {
  const {
    walletPreferences: { showFiatWallet },
    fiatCurrency,
  } = useCurrentUserContext();
  const { acceptedCurrencies } = useAcceptedCurrencies({ user: to });

  const { toMonetaryAmount } = useMonetaryAmount();

  const cards = [...offer.senderSide.cards, ...offer.receiverSide.cards];

  const counterOfferSport = cards.length > 0 ? cards[0].sport : Sport.FOOTBALL;

  const receiveAmount = toMonetaryAmount(offer.receiverSide.amounts);
  const receiveMarketFeesAmount =
    offer?.marketFeeAmounts && toMonetaryAmount(offer.marketFeeAmounts);
  const sendAmount = toMonetaryAmount(offer.senderSide.amounts);

  const paymentMethod = useMemo(() => {
    if (
      offer.settlementCurrencies.includes(SupportedCurrency.WEI) &&
      [AcceptedCurrenciesValue.BOTH, AcceptedCurrenciesValue.ETH].includes(
        acceptedCurrencies
      )
    )
      return WalletPaymentMethod.ETH_WALLET;
    if (
      [AcceptedCurrenciesValue.BOTH, AcceptedCurrenciesValue.FIAT].includes(
        acceptedCurrencies
      )
    )
      return WalletPaymentMethod.FIAT_WALLET;
    return undefined;
  }, [offer, acceptedCurrencies]);

  return (
    <NewOfferBuilder
      receiveCards={offer.receiverSide.cards}
      receiveAmount={receiveAmount}
      receiveMarketFeesAmount={receiveMarketFeesAmount || undefined}
      sendCards={offer.senderSide.cards}
      sendAmount={sendAmount}
      onClose={onClose}
      to={to}
      counterOfferSport={counterOfferSport}
      currentUser={currentUser}
      lockReceiveEthInput={receiveAmount.eur <= 0}
      {...(showFiatWallet && {
        referenceCurrency:
          offer.senderSide.amounts.referenceCurrency === SupportedCurrency.WEI
            ? offer.senderSide.amounts.referenceCurrency
            : (fiatCurrency.code as SupportedCurrency),
      })}
      paymentMethod={paymentMethod}
    />
  );
};

NewPrefilledOfferBuilder.fragments = {
  tokenOffer: gql`
    fragment NewPrefilledOfferBuilder_tokenOffer on TokenOffer {
      id
      settlementCurrencies
      marketFeeAmounts {
        ...MonetaryAmountFragment_monetaryAmount
      }
      sender {
        ... on User {
          slug
          ...NewOfferBuilder_publicUserInfoInterface
        }
      }
      senderSide {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        cards: anyCards {
          slug
          ...NewOfferBuilder_anyCard
        }
      }
      receiver {
        ... on User {
          slug
          ...NewOfferBuilder_publicUserInfoInterface
        }
      }
      receiverSide {
        id
        wei
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        cards: anyCards {
          slug
          ...NewOfferBuilder_anyCard
        }
      }
    }
    ${monetaryAmountFragment}
    ${NewOfferBuilder.fragments.anyCard}
    ${NewOfferBuilder.fragments.user}
  ` as TypedDocumentNode<NewPrefilledOfferBuilder_tokenOffer>,
  user: gql`
    fragment NewPrefilledOfferBuilder_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      ...NewOfferBuilder_publicUserInfoInterface
    }
    ${NewOfferBuilder.fragments.user}
  ` as TypedDocumentNode<NewPrefilledOfferBuilder_publicUserInfoInterface>,
};
export default NewPrefilledOfferBuilder;
