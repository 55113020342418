import { useEffect } from 'react';

import { Sport } from '__generated__/globalTypes';

import { useSportContext } from '.';

type Props = {
  sport: Sport;
};

export const SportSwitcher = ({ sport }: Props) => {
  const { sport: currentSport, setSport } = useSportContext();

  useEffect(() => {
    if (sport !== currentSport) {
      setSport(sport);
    }
  }, [sport, currentSport, setSport]);

  return null;
};

export default SportSwitcher;
