import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { theme } from 'style/theme';

const Root = styled.div`
  position: relative;
  isolation: isolate;
  z-index: ${theme.zIndex.modal};
`;

export const Modal = ({ children }: PropsWithChildren) => {
  return createPortal(
    <Root
      role="presentation"
      // Since React Portal maintain event propagation, we need to manually stop them in the DOM in case this Drawer is used inside an Element with mouse events
      // There is no scenario where we want to keep the event propagation in a Drawer
      // https://github.com/facebook/react/issues/11387
      // https://react.dev/reference/react-dom/createPortal#:~:text=more%20examples%20below.-,A%20portal%20only%20changes%20the%20physical%20placement%20of%20the%20DOM%20node,-.%20In%20every%20other
      onMouseDown={e => e.stopPropagation()}
      onMouseUp={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </Root>,
    document.body
  );
};
