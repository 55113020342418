import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  endingSoon: {
    id: 'MySorare.common.endingSoon',
    defaultMessage: 'Ending Soon',
  },
  newlyListed: {
    id: 'MySorare.common.newlyListed',
    defaultMessage: 'Newly Listed',
  },
  allOffers: {
    id: 'MySorare.common.allOffers',
    defaultMessage: 'All Offers',
  },
  openOffers: {
    id: 'MySorare.common.openOffers',
    defaultMessage: 'Open Offers',
  },
  endedOffers: {
    id: 'MySorare.common.endedOffers',
    defaultMessage: 'Ended Offers',
  },
  acceptedOffers: {
    id: 'MySorare.common.acceptedOffers',
    defaultMessage: 'Accepted Offers',
  },
  rejectedOffers: {
    id: 'MySorare.common.rejectedOffers',
    defaultMessage: 'Rejected Offers',
  },
  latest: {
    id: 'MySorare.common.latest',
    defaultMessage: 'Latest',
  },
  oldest: {
    id: 'MySorare.common.oldest',
    defaultMessage: 'Oldest',
  },
  createNewOffer: {
    id: 'MySorare.common.createNewOffer',
    defaultMessage: 'Create new offer',
  },
});
