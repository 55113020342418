import { TypedDocumentNode, gql } from '@apollo/client';
import { faClose, faEllipsis, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import {
  Item,
  ItemText,
  SmartDropdown,
} from '@sorare/core/src/atoms/dropdowns/SmartDropdown';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { FavoriteHeartButton } from '@sorare/core/src/components/favorites/FavoriteHeartButton';
import { SocialShare } from '@sorare/core/src/components/user/SocialShare';
import { FOOTBALL_CARDS_SLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { isType } from '@sorare/core/src/gql';
import { useAbsoluteURL } from '@sorare/core/src/hooks/useAbsoluteURL';
import {
  socialShareEventContext,
  socialShareEventName,
} from '@sorare/core/src/lib/events';
import { glossary } from '@sorare/core/src/lib/glossary';
import { socialSharingMessages } from '@sorare/core/src/lib/share';

import { Actions_card } from './__generated__/index.graphql';

const Row = styled(SBHorizontal).attrs({ gap: 0 })`
  width: 100%;
`;
const FlexContainer = styled(Horizontal).attrs({ gap: 0.5 })``;

type Props = {
  card: Actions_card;
  loading?: boolean;
  onAddToListClick: () => void;
  onBack?: () => void;
};

export const Actions = ({ card, loading, onBack, onAddToListClick }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { formatMessage } = useIntl();

  const cardUrl = useAbsoluteURL(
    generatePath(FOOTBALL_CARDS_SLUG, {
      slug: card.slug,
    })
  );

  return (
    <Vertical gap={2}>
      <Row>
        <FlexContainer>
          <FavoriteHeartButton
            subscribable={card}
            currentUserSubscription={card.currentUserSubscription}
          />
          <FlexContainer>
            <SmartDropdown
              align="right"
              gap={4}
              label={props => (
                <IconButton
                  {...props}
                  disableDebounce
                  color="tertiary"
                  icon={faEllipsis}
                  small
                />
              )}
            >
              {({ closeDropdown }) => (
                <SocialShare
                  image={{
                    post: isType(card, 'Card')
                      ? card.pictureUrlForTwitter
                      : card.pictureUrl,
                    square: null,
                    story: null,
                  }}
                  message={link =>
                    formatMessage(socialSharingMessages.card, { link })
                  }
                  url={cardUrl}
                  trackingEventName={socialShareEventName.SHARE_CARD}
                  trackingEventContext={socialShareEventContext.CARD_PAGE}
                  renderButton={({ onClick, Icon, icon }) =>
                    closeDropdown ? (
                      <Item
                        type="button"
                        onClick={() => {
                          onClick();
                          closeDropdown?.();
                        }}
                        disabled={loading}
                      >
                        <ItemText>
                          <FormattedMessage {...glossary.share} />
                        </ItemText>
                        {Icon}
                      </Item>
                    ) : (
                      <IconButton
                        small
                        color="tertiary"
                        onClick={onClick}
                        disabled={loading}
                        icon={icon}
                      />
                    )
                  }
                />
              )}
              {currentUser &&
                currentUser.slug === card.user?.slug &&
                (({ closeDropdown }) => (
                  <Item
                    onClick={() => {
                      onAddToListClick();
                      closeDropdown?.();
                    }}
                    disabled={loading}
                    type="button"
                  >
                    <ItemText>
                      <FormattedMessage
                        id="CardPage.addToList"
                        defaultMessage="Add to list"
                      />
                    </ItemText>
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                  </Item>
                ))}
            </SmartDropdown>
          </FlexContainer>
          {onBack && (
            <IconButton
              icon={faClose}
              color="tertiary"
              small
              onClick={onBack}
            />
          )}
        </FlexContainer>
      </Row>
    </Vertical>
  );
};

Actions.fragments = {
  card: gql`
    fragment Actions_card on AnyCardInterface {
      slug
      pictureUrl
      currentUserSubscription {
        slug
      }
      user {
        slug
      }
      ... on Card {
        slug
        pictureUrlForTwitter: pictureUrl(derivative: "twitter")
      }
    }
  ` as TypedDocumentNode<Actions_card>,
};
