import { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { TokenOfferRejectionReason } from '@sorare/core/src/__generated__/globalTypes';
import { RadioGroup } from '@sorare/core/src/atoms/inputs/RadioGroup';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text16, Text18, Title3 } from '@sorare/core/src/atoms/typography';

type Props = {
  nickname: ReactNode;
  filters: TokenOfferRejectionReason[];
  onChange: (e: TokenOfferRejectionReason) => void;
};

const messages = defineMessages({
  [TokenOfferRejectionReason.ONLY_CASH]: {
    id: 'mySorare.common.directOffer.customAnswers.onlyCash',
    defaultMessage: 'I only want money',
  },
  [TokenOfferRejectionReason.OFFER_TOO_LOW]: {
    id: 'mySorare.common.directOffer.customAnswers.offerTooLow',
    defaultMessage: 'The offer is too low',
  },
  [TokenOfferRejectionReason.NOT_SELLING]: {
    id: 'mySorare.common.directOffer.customAnswers.notSelling',
    defaultMessage: 'I do not want to sell',
  },
  [TokenOfferRejectionReason.ADD_CASH]: {
    id: 'mySorare.common.directOffer.customAnswers.addCash',
    defaultMessage: 'I want more money',
  },
  [TokenOfferRejectionReason.IN_A_LINEUP]: {
    id: 'mySorare.common.directOffer.customAnswers.inALineup',
    defaultMessage: 'I play the card in a lineup',
  },
  [TokenOfferRejectionReason.CARD_NOT_WANTED]: {
    id: 'mySorare.common.directOffer.customAnswers.cardNotWanted',
    defaultMessage: 'I do not want to swap your card(s)',
  },
});

const options: TokenOfferRejectionReason[] = [
  TokenOfferRejectionReason.ONLY_CASH,
  TokenOfferRejectionReason.OFFER_TOO_LOW,
  TokenOfferRejectionReason.NOT_SELLING,
  TokenOfferRejectionReason.ADD_CASH,
  TokenOfferRejectionReason.IN_A_LINEUP,
  TokenOfferRejectionReason.CARD_NOT_WANTED,
];

export const OfferRejectionReason = ({
  filters,
  nickname,
  onChange,
}: Props) => {
  const radioOptions = options.map(option => ({
    value: option,
    label: (
      <Text16 color="var(--c-neutral-1000)">
        <FormattedMessage {...messages[option]} />
      </Text16>
    ),
    disabled: filters.includes(option),
  }));

  return (
    <Vertical gap={2}>
      <Title3>
        <FormattedMessage
          id="mySorare.common.directOffer.customAnswers.title"
          defaultMessage="Give {nickname} a reason for declining <smallGrey>(optional)</smallGrey>"
          values={{
            nickname,
            smallGrey: msg => (
              <Text18 as="span" color="var(--c-neutral-600)" bold={false}>
                {msg}
              </Text18>
            ),
          }}
        />
      </Title3>
      <RadioGroup
        withSpacing
        modalSelect
        rounded
        name="customAnswer"
        preventPreselection
        options={radioOptions}
        onChange={onChange}
      />
    </Vertical>
  );
};
