import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentPropsWithoutRef } from 'react';

import { NBACardProperties } from '@sorare/marketplace/src/components/card/NBACardProperties';

import { InjuryTag } from '@sorare/gameplay/src/components/player/InjuryTag';

import { NBACardPropertiesWithInjury_anyCard } from './__generated__/index.graphql';

type CardPropertiesProps = ComponentPropsWithoutRef<typeof NBACardProperties>;

type Props = Omit<CardPropertiesProps, 'extras' | 'card'> & {
  card: NBACardPropertiesWithInjury_anyCard;
};

export const NBACardPropertiesWithInjury = (props: Props) => {
  const { card } = props;
  const { anyPlayer } = card;
  return (
    <NBACardProperties {...props} extras={<InjuryTag player={anyPlayer} />} />
  );
};

NBACardPropertiesWithInjury.fragments = {
  anyCard: gql`
    fragment NBACardPropertiesWithInjury_anyCard on AnyCardInterface {
      slug
      anyPlayer {
        slug
        ...InjuryTag_anyPlayer
      }
      ...NBACardProperties_anyCard
    }
    ${NBACardProperties.fragments.anyCard}
    ${InjuryTag.fragments.anyPlayer}
  ` as TypedDocumentNode<NBACardPropertiesWithInjury_anyCard>,
};
