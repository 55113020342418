import { TypedDocumentNode, gql } from '@apollo/client';

import { isType } from '@sorare/core/src/gql';

import { PlayerInFixtureLinkBox as DumbPlayerInFixtureLinkBox } from '@sorare/us-sports/src/components/game/GameView/PlayerInFixtureLinkBox';

import { PlayerInFixtureLinkBox_anyCard } from './__generated__/index.graphql';

type Props = {
  card: PlayerInFixtureLinkBox_anyCard;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
};

export const PlayerInFixtureLinkBox = ({
  card,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
}: Props) => {
  const { anyPlayer } = card;
  if (!isType(anyPlayer, 'NBAPlayer') || !isType(card, 'NBACard')) {
    return null;
  }
  const so5Appearance = {
    __typename: 'So5Appearance' as const,
    id: card.slug,
    anyCard: card,
    score: 0,
    bonus: Number(card.power),
    boost: null,
  };

  return (
    <DumbPlayerInFixtureLinkBox
      fullImageUrl={so5Appearance.anyCard.pictureUrl!}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      playerCardScore={null}
      className={className}
    />
  );
};

PlayerInFixtureLinkBox.fragments = {
  anyCard: gql`
    fragment PlayerInFixtureLinkBox_anyCard on AnyCardInterface {
      slug
      pictureUrl
      power
      anyPlayer {
        slug
      }
    }
  ` as TypedDocumentNode<PlayerInFixtureLinkBox_anyCard>,
};
