import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { fantasy, glossary, rivals } from 'lib/glossary';

import { useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig } from './__generated__/useProbabilisticBundleConfigSummary.graphql';

export const useProbabilisticBundleConfigSummary = (
  boxConfig: useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig
): string[] => {
  const { formatMessage } = useIntl();
  const { slots } = boxConfig;

  const result = useMemo(() => {
    const summary = new Set<string>();
    slots.forEach(({ probableConfigs }) =>
      probableConfigs.forEach(
        ({
          arenaTickets,
          cards,
          rewardShopItems,
          deliverableItems,
          conversionCredit,
          cardShards,
        }) => {
          if (cards.length) {
            summary.add(formatMessage(glossary.cards));
          }
          if (arenaTickets > 0) {
            summary.add(formatMessage(rivals.arenaTickets));
          }
          deliverableItems.forEach(({ deliverableItem }) =>
            summary.add(deliverableItem.name)
          );
          if (conversionCredit) {
            summary.add(formatMessage(fantasy.credits));
          }
          rewardShopItems.forEach(({ shopItem }) => {
            summary.add(shopItem.name);
          });
          if (cardShards.length) {
            summary.add(formatMessage(fantasy.essenceShort));
          }
        }
      )
    );
    return Array.from(summary);
  }, [formatMessage, slots]);
  return result;
};

useProbabilisticBundleConfigSummary.fragments = {
  ProbabilisticBundleConfig: gql`
    fragment useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig on ProbabilisticBundleConfig {
      id
      slots {
        probableConfigs {
          arenaTickets
          deliverableItems {
            quantity
            deliverableItem {
              slug
              name
            }
          }
          cards {
            quality
          }
          rewardShopItems {
            quantity
            shopItem {
              id
              name
            }
          }
          conversionCredit {
            maxDiscount {
              usd
            }
            percentageDiscount
          }
          cardShards {
            quantity
          }
        }
      }
    }
  ` as TypedDocumentNode<useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig>,
};
