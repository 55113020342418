import { TypedDocumentNode, gql } from '@apollo/client';
import { faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import { isFuture } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import {
  Container,
  FullWidthScroll,
} from '@sorare/core/src/atoms/layout/Container';
import { ScarcityBackground } from '@sorare/core/src/atoms/layout/ScarcityBackground';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { LabelM, Title2, Title5 } from '@sorare/core/src/atoms/typography';
import { CardAvatar } from '@sorare/core/src/components/card/Avatar';
import { ANY_SPORT_PLAYERS_SLUG_CARDS } from '@sorare/core/src/constants/routes';
import { isType } from '@sorare/core/src/gql';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import {
  getPlayerInjuryColor,
  getPlayerInjuryMessage,
} from '@sorare/core/src/lib/baseball';
import { getHumanReadableSerialNumber } from '@sorare/core/src/lib/cards';
import { glossary, playerDetails } from '@sorare/core/src/lib/glossary';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';
import { HandledError } from '@sorare/core/src/routing/HandledError';

import CardBonus from '@sorare/marketplace/src/components/card/CardBonus';

import { PlayerInjuryReport } from '@sorare/gameplay/src/components/player/PlayerDetails/PlayerInjuryReport';
import { PlayerNewsUpdates } from '@sorare/gameplay/src/components/player/PlayerDetails/PlayerNewsUpdates';
import { PlayerPositions } from '@sorare/gameplay/src/components/player/PlayerPositions';
import { UpcomingGames } from '@sorare/gameplay/src/components/player/UpcomingGames';
import { LazyFixtureChart } from '@sorare/gameplay/src/components/stats/LastScores/LazyFixtureChart';

import { AgeWithTooltip } from 'components/player/AgeWithTooltip';
import { PlayerAvatar } from 'components/player/Avatar';
import { Status } from 'components/player/Status';

import {
  USInfoModalQuery,
  USInfoModalQueryVariables,
  USInfoModal_anyPlayer,
} from './__generated__/index.graphql';

const anyPlayerFragment = gql`
  fragment USInfoModal_anyPlayer on AnyPlayerInterface {
    slug
    displayName
    birthDay
    age
    sport
    anyPositions
    country {
      slug
      name
    }
    avatarPictureUrl
    activeClub {
      slug
      name
      upcomingGames(first: 5) {
        id
        so5Fixture {
          slug
          gameWeek
          aasmState
        }
        ...UpcomingGames_anyGame
      }
    }
    ...PlayerInjuryReport_anyPlayer
    ...PlayerNewsUpdates_anyPlayer
    ...UsSportsPlayerAvatar_player
    ... on BaseballPlayer {
      slug
      # eslint-disable-next-line @graphql-eslint/no-deprecated
      isActive
    }
    ... on NBAPlayer {
      slug
      # eslint-disable-next-line @graphql-eslint/no-deprecated
      isActive
    }
  }
  ${PlayerAvatar.fragments.player}
  ${PlayerInjuryReport.fragments.anyPlayer}
  ${UpcomingGames.fragments.anyGame}
  ${PlayerNewsUpdates.fragments.anyPlayer}
` as TypedDocumentNode<USInfoModal_anyPlayer>;

const US_INFO_MODAL_QUERY = gql`
  query USInfoModalQuery(
    $cardSlug: String!
    $playerSlug: String!
    $skipCard: Boolean!
  ) {
    anyCard(slug: $cardSlug) @skip(if: $skipCard) {
      slug
      rarityTyped
      serialNumber
      avatarUrl: pictureUrl(derivative: "avatar")
      anyPlayer {
        slug
        ...USInfoModal_anyPlayer
      }
      ...getHumanReadableSerialNumber_anyCard
      ...CardBonus_anyCard
    }
    anyPlayer(slug: $playerSlug) @include(if: $skipCard) {
      slug
      ...USInfoModal_anyPlayer
    }
  }
  ${anyPlayerFragment}
  ${CardBonus.fragments.anyCard}
  ${getHumanReadableSerialNumber.fragments.anyCard}
  ${anyPlayerFragment}
` as TypedDocumentNode<USInfoModalQuery, USInfoModalQueryVariables>;

const Wrapper = styled(Vertical).attrs({ gap: 0 })`
  color: var(--c-neutral-800);
`;

const LoadingWrapper = styled(Horizontal).attrs({ gap: 0 })`
  min-height: 75vh;
`;

const Header = styled(Vertical).attrs({ gap: 0, center: true })`
  background: linear-gradient(341.46deg, #171717 14.37%, #4d4b49 96.18%);
  color: var(--c-static-neutral-100);
  padding: var(--double-unit);
  position: relative;
  justify-content: stretch;
`;

const PlayerInfos = styled(LabelM)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: var(--unit);
`;

const DotSeparated = styled.div`
  & > * {
    display: inline;
  }
  & > * + *::before {
    content: ' • ';
  }
`;
const InjuryBanner = styled(Horizontal).attrs({ center: true })`
  padding: var(--unit);
  text-transform: uppercase;
  &.yellow {
    --injury-rgb: var(--c-rgb-yellow-600);
  }
  &.red {
    --injury-rgb: var(--c-rgb-red-600);
  }
  color: rgba(var(--injury-rgb));
  background: linear-gradient(
    160deg,
    rgba(var(--injury-rgb), 0.35) 0%,
    rgba(var(--injury-rgb), 0.1) 100%
  );
`;

const CardAvatarWithScoreOnTop = styled.div`
  position: relative;
  margin-bottom: var(--intermediate-unit);
`;

const RoundedCardAvatar = styled(CardAvatar)`
  border-radius: var(--double-unit);
  overflow: hidden;
  width: clamp(calc(5 * var(--unit)), calc(15 * var(--unit)), 25vw);
`;

const RoundedPlayerAvatar = styled(PlayerAvatar)`
  border-radius: var(--double-unit);
  overflow: hidden;
  width: clamp(calc(5 * var(--unit)), calc(15 * var(--unit)), 25vw);
`;

const Close = styled.div`
  position: absolute;
  right: var(--double-unit);
  top: var(--double-unit);
`;
const Content = styled(Vertical).attrs({ gap: 4 })`
  padding: var(--quadruple-unit) 0;
`;

const InfoModalContainer = styled(Container)`
  --container-padding: var(--double-unit);
  gap: var(--unit);
`;

const CardInfo = styled(ScarcityBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--unit);
  padding: var(--unit);
  font-weight: var(--t-bold);
`;

type AnyCard_player = NonNullable<USInfoModalQuery['anyCard']>['anyPlayer'];

type AnyPlayer = NonNullable<USInfoModal_anyPlayer>;

function isPlayerWithAvatar(
  player: AnyCard_player | AnyPlayer
): player is USInfoModal_anyPlayer {
  return (player as USInfoModal_anyPlayer).avatarPictureUrl !== undefined;
}

// On common daily drop card we don't have a card slug
type Props = {
  cardSlug?: string;
  playerSlug?: string;
  onClose?: () => void;
  withPlayerLink?: boolean;
};

export const USInfoModal = ({
  cardSlug = '',
  playerSlug = '',
  onClose,
  withPlayerLink = false,
}: Props) => {
  const { formatMessage } = useIntl();

  const { data, loading } = useQuery(US_INFO_MODAL_QUERY, {
    variables: {
      cardSlug,
      playerSlug,
      skipCard: !!playerSlug,
    },
    skip: !cardSlug && !playerSlug,
  });

  if (loading || !data) {
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );
  }

  const { anyCard, anyPlayer } = data;
  const player = anyPlayer || anyCard?.anyPlayer;

  if (
    !player ||
    !(isType(player, 'BaseballPlayer') || isType(player, 'NBAPlayer'))
  ) {
    return (
      <HandledError
        code={404}
        message={formatMessage({
          id: 'InfoModal.notFound',
          defaultMessage: "There was an error getting this Card's data",
        })}
      />
    );
  }

  return (
    <Wrapper role="dialog">
      {player.playerInjury && (
        <InjuryBanner
          className={getPlayerInjuryColor(player.playerInjury.status)}
        >
          <LabelM as="span" bold>
            <FormattedMessage
              {...getPlayerInjuryMessage(player.playerInjury.status, {
                long: true,
              })}
            />
          </LabelM>
        </InjuryBanner>
      )}
      <Header>
        {onClose && (
          <Close>
            <IconButton
              disableDebounce
              onClick={onClose}
              icon={faXmarkLarge}
              color="transparent"
              aria-label={formatMessage(glossary.close)}
            />
          </Close>
        )}
        <CardAvatarWithScoreOnTop>
          {anyCard ? (
            <RoundedCardAvatar card={anyCard} />
          ) : (
            isPlayerWithAvatar(player) && (
              <RoundedPlayerAvatar player={player} />
            )
          )}
        </CardAvatarWithScoreOnTop>
        {withPlayerLink ? (
          <Title2
            className="text-center"
            as={Link}
            to={generateSportPath(ANY_SPORT_PLAYERS_SLUG_CARDS, {
              params: { slug: player.slug },
              sport: player.sport,
            })}
          >
            {player.displayName}
          </Title2>
        ) : (
          <Title2 className="text-center">{player.displayName}</Title2>
        )}
        <PlayerInfos as="span">
          <Horizontal>
            <DotSeparated>
              {player.activeClub && <span>{player.activeClub.name}</span>}
              <PlayerPositions positions={player.anyPositions} />
            </DotSeparated>
          </Horizontal>
          <Horizontal>
            <DotSeparated>
              {player.birthDay && (
                <AgeWithTooltip birthDate={player.birthDay} age={player.age} />
              )}
              <span>{player.country.name}</span>
            </DotSeparated>
          </Horizontal>
        </PlayerInfos>
        <Status player={player} />
      </Header>
      {anyCard && (
        <CardInfo className={anyCard.rarityTyped}>
          <CardBonus card={anyCard} />
          <span>{getHumanReadableSerialNumber(anyCard)}</span>
        </CardInfo>
      )}
      <Content>
        {player.playerInjury && (
          <InfoModalContainer>
            <PlayerInjuryReport player={player} />
          </InfoModalContainer>
        )}
        {player.news && (
          <InfoModalContainer>
            <PlayerNewsUpdates player={player} />
          </InfoModalContainer>
        )}
        <InfoModalContainer>
          <FullWidthScroll>
            <LazyFixtureChart
              TitleComponent={Title5}
              playerSlug={player.slug}
            />
          </FullWidthScroll>
        </InfoModalContainer>
        <InfoModalContainer>
          <Title5>
            <FormattedMessage {...playerDetails.upcoming_games} />
          </Title5>
          <Vertical>
            <UpcomingGames
              games={
                player.activeClub?.upcomingGames.filter(
                  anyGame =>
                    anyGame &&
                    isFuture(anyGame.date) &&
                    anyGame.so5Fixture?.aasmState &&
                    ['opened', 'started'].includes(
                      anyGame.so5Fixture?.aasmState
                    )
                ) || []
              }
              playerTeamSlug={player.activeClub?.slug}
              scrollSnap
            />
          </Vertical>
        </InfoModalContainer>
      </Content>
    </Wrapper>
  );
};
