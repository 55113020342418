import { TypedDocumentNode, gql } from '@apollo/client';
import { Navigate, generatePath, useLocation } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import {
  FOOTBALL_PLAY_RIVALS_ARENA,
  FOOTBALL_PLAY_RIVALS_DRAFT_SLUG,
  FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_ARENA,
} from '@sorare/core/src/constants/__generated__/routes';
import { isType } from '@sorare/core/src/gql';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { isGameScheduled } from '@sorare/gameplay/src/lib/so5';

import {
  RivalsGameRedirectQuery,
  RivalsGameRedirectQueryVariables,
} from './__generated__/page.graphql';
import { PageParams } from './__generated__/routeParams';

const RIVALS_GAME_REDIRECT_QUERY = gql`
  query RivalsGameRedirectQuery($id: ID!) {
    node(id: $id) {
      id
      ... on FootballRivalsGame {
        slug
        season {
          slug
        }
        game {
          id
          status: statusTyped
        }
        myLineup {
          id
        }
      }
    }
  }
` as TypedDocumentNode<
  RivalsGameRedirectQuery,
  RivalsGameRedirectQueryVariables
>;

const RivalsGameRedirect = () => {
  const { id } = useTypedParams<PageParams>();
  const location = useLocation();

  const { data } = useQuery(RIVALS_GAME_REDIRECT_QUERY, {
    variables: {
      id: id!,
    },
  });

  if (!data) {
    return null;
  }

  if (!isType(data.node, 'FootballRivalsGame')) {
    return <Navigate replace to={generatePath(FOOTBALL_PLAY_RIVALS_ARENA)} />;
  }

  const rivalsGame = data.node;
  const { slug, game, myLineup, season } = rivalsGame;

  if (
    location.state?.createLineup &&
    !myLineup &&
    isGameScheduled(game.status)
  ) {
    return (
      <Navigate
        to={generatePath(FOOTBALL_PLAY_RIVALS_DRAFT_SLUG, {
          slug,
        })}
        replace
      />
    );
  }

  const seasonSlug = season?.slug;

  if (seasonSlug) {
    return (
      <Navigate
        to={generatePath(
          FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_ARENA,
          {
            gameSlug: slug,
            seasonSlug,
          }
        )}
        replace
      />
    );
  }

  return <Navigate replace to={generatePath(FOOTBALL_PLAY_RIVALS_ARENA)} />;
};

export default RivalsGameRedirect;
