import { animated, config, useSpring } from 'react-spring';
import { useFirstMountState } from 'react-use';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Progress } from '@sorare/core/src/atoms/loader/Progress';

const Wrapper = styled(Vertical).attrs({ gap: 0 })`
  position: relative;
`;

const ProgressLabel = styled(animated.small)`
  position: relative;
  color: var(--c-neutral-600);
  top: 0;
  font-size: var(--intermediate-unit);
  line-height: 1;
  align-self: flex-start;
`;

type Props = {
  value: number;
  min?: number;
  max: number;
  unit?: string;
  skipStartAnimation?: boolean;
  style?: React.CSSProperties;
};

export const ProgressBar = ({
  value: toValue,
  min = 0,
  max,
  unit = '',
  skipStartAnimation = false,
  style,
}: Props) => {
  const firstRender = useFirstMountState();
  const { value } = useSpring({
    config: config.molasses,
    from: { value: min },
    to: { value: Math.min(Math.max(toValue, min), max) },
    immediate: skipStartAnimation && firstRender,
  });

  return (
    <Wrapper style={style}>
      <ProgressLabel
        style={{
          left: value.to(v => `${(v / max) * 100}%`),
          transform: value.to(v => `translateX(${(v / max) * -100}%)`),
        }}
      >
        {value.to(v => `${v.toFixed(0)}${unit}`)}
      </ProgressLabel>
      <Progress
        max={max}
        value={toValue}
        skipStartAnimation={skipStartAnimation}
      />
    </Wrapper>
  );
};
