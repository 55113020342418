import { TypedDocumentNode, gql } from '@apollo/client';
import { isPast } from 'date-fns';

import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { useIsPrimaryBuyLive_anyCard } from './__generated__/useIsPrimaryBuyLive.graphql';

export const useIsPrimaryBuyLive = (
  card: Nullable<useIsPrimaryBuyLive_anyCard>
) => {
  const { latestPrimaryOffer, user } = card || {};
  const endDate = latestPrimaryOffer?.endDate;
  const price = latestPrimaryOffer?.price;

  const ended = endDate && isPast(endDate);

  // Checking the token has an owner is mandatory as the
  // PrimaryOffer can ends way after the token has been sold
  const isOnSale = !!latestPrimaryOffer && !user && !ended;

  const isPrimaryBuy = latestPrimaryOffer?.cards.length === 1;

  if (isOnSale && price && endDate && isPrimaryBuy) {
    return {
      endDate,
      ended,
      price,
    };
  }
  return undefined;
};

useIsPrimaryBuyLive.fragments = {
  anyCard: gql`
    fragment useIsPrimaryBuyLive_anyCard on AnyCardInterface {
      slug
      latestPrimaryOffer {
        id
        price {
          ...MonetaryAmountFragment_monetaryAmount
        }
        endDate
        cards: anyCards {
          slug
        }
      }
      user {
        slug
      }
    }
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<useIsPrimaryBuyLive_anyCard>,
};
