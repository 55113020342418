import { ComponentProps } from 'react';
import { defineMessages } from 'react-intl';

import { SIGNUP_AGE_LIMIT } from 'config';
import { useIntlContext } from 'contexts/intl';

import AcceptanceCheckbox from '../AcceptanceCheckbox';

const messages = defineMessages({
  iAgreeToAgeLimit: {
    id: 'AcceptAgeLimit.iAgreeToAgeLimitWithVariable',
    defaultMessage: 'I certify that I am {signupAgeLimit} years old or older.',
  },
});

export const AcceptAgeLimit = (
  props: Omit<ComponentProps<typeof AcceptanceCheckbox>, 'label'>
) => {
  const { formatMessage } = useIntlContext();

  return (
    <AcceptanceCheckbox
      {...props}
      label={formatMessage(messages.iAgreeToAgeLimit, {
        signupAgeLimit: SIGNUP_AGE_LIMIT,
      })}
    />
  );
};

export default AcceptAgeLimit;
