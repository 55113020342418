import { generatePath, useParams } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import { NBA_MY_CLUB_SLUG } from '@sorare/core/src/constants/__generated__/routes';
import { NBA_HOME } from '@sorare/core/src/constants/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

export const MyClubRedirect = () => {
  const { slug } = useParams();
  const { currentUser } = useCurrentUserContext();
  const actualSlug = slug || currentUser?.slug;
  if (!actualSlug) {
    return <Navigate replace to={NBA_HOME} />;
  }
  return (
    <Navigate
      replace
      to={generatePath(NBA_MY_CLUB_SLUG, {
        slug: actualSlug,
      })}
    />
  );
};

export default MyClubRedirect;
