import { TypedDocumentNode, gql } from '@apollo/client';
import qs from 'qs';
import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import {
  FOOTBALL_PLAY_PRO,
  FOOTBALL_PLAY_PRO_FIXTURE,
  FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
} from '@sorare/core/src/constants/__generated__/routes';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { isFixtureLive, isFixtureOpened } from '@sorare/gameplay/src/lib/so5';

import {
  LineupRedirectQuery,
  LineupRedirectQueryVariables,
} from './__generated__/page.graphql';
import { PageParams } from './__generated__/routeParams';

const LINEUP_REDIRECT_QUERY = gql`
  query LineupRedirectQuery($id: ID!) {
    so5 {
      so5Lineup(id: $id) {
        id
        so5Leaderboard {
          slug
          so5League {
            slug
          }
        }
        so5LeaderboardContender {
          slug
        }
        so5Fixture {
          slug
          ...isFixtureLive_so5Fixture
          ...isFixtureOpened_so5Fixture
        }
      }
    }
  }
  ${isFixtureLive.fragments.so5Fixture}
  ${isFixtureOpened.fragments.so5Fixture}
` as TypedDocumentNode<LineupRedirectQuery, LineupRedirectQueryVariables>;

const LineupRedirect = () => {
  const { id } = useTypedParams<PageParams>();
  const { data } = useQuery(LINEUP_REDIRECT_QUERY, {
    variables: {
      id: id!,
    },
  });

  if (!data) return null;

  const lineup = data.so5.so5Lineup;

  if (!lineup.so5Leaderboard?.slug) {
    return <Navigate replace to={generatePath(FOOTBALL_PLAY_PRO)} />;
  }

  if (isFixtureOpened(lineup.so5Fixture) || isFixtureLive(lineup.so5Fixture)) {
    return (
      <Navigate
        replace
        to={`${generatePath(FOOTBALL_PLAY_PRO_FIXTURE, {
          fixture: lineup.so5Fixture.slug,
        })}${qs.stringify(
          {
            id: idFromObject(id),
          },
          {
            addQueryPrefix: true,
          }
        )}`}
      />
    );
  }

  return (
    <Navigate
      replace
      to={`${generatePath(
        FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
        {
          fixture: lineup.so5Fixture.slug,
          competition: lineup.so5Leaderboard.so5League.slug,
          contender: lineup.so5LeaderboardContender.slug,
        }
      )}${qs.stringify(
        {
          id: idFromObject(id),
        },
        {
          addQueryPrefix: true,
        }
      )}`}
    />
  );
};

export default LineupRedirect;
