import useLifecycle, { LIFECYCLE } from '@sorare/core/src/hooks/useLifecycle';

export const useNextTimeUserShouldSeeAcceptedCurrenciesOnListing = () => {
  const { update, lifecycle } = useLifecycle();

  const nextTimeUserShouldSeeAcceptedCurrenciesOnListing =
    lifecycle?.nextTimeUserShouldSeeAcceptedCurrenciesOnListing;

  return {
    nextTimeUserShouldSeeAcceptedCurrenciesOnListing,
    update: async (value: string) =>
      update(LIFECYCLE.nextTimeUserShouldSeeAcceptedCurrenciesOnListing, value),
  };
};
