import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';

import U23EligibleIcon from '../U23EligibleIcon';

type Props = {
  until?: Date | null;
};

const U23Eligible = ({ until }: Props) => {
  return (
    <Tooltip
      title={
        <>
          <div>
            <strong>
              <FormattedMessage
                id="U23Eligible.title"
                defaultMessage="U23 Eligible"
              />
            </strong>
          </div>
          {until && (
            <div>
              <FormattedMessage
                id="U23Eligible.elibileUntil"
                defaultMessage="Until {until, date, long}"
                values={{ until }}
              />
            </div>
          )}
        </>
      }
    >
      <U23EligibleIcon />
    </Tooltip>
  );
};

export default U23Eligible;
