import { useEffect, useMemo, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { catchAll } from '@sorare/routing';

import { Sport } from '__generated__/globalTypes';
import { FOOTBALL, MLB, NBA } from 'constants/__generated__/routes';

const getSportFromPathname = (pathname: string) => {
  if (matchPath(catchAll(NBA), pathname)) {
    return Sport.NBA;
  }
  if (matchPath(catchAll(MLB), pathname)) {
    return Sport.BASEBALL;
  }
  if (matchPath(catchAll(FOOTBALL), pathname)) {
    return Sport.FOOTBALL;
  }
  return undefined;
};

export const useSport = () => {
  const { pathname } = useLocation();
  const pathnameRef = useRef(pathname);

  useEffect(() => {
    pathnameRef.current = pathname;
  }, [pathname]);

  return useMemo(() => {
    return getSportFromPathname(pathnameRef.current);
  }, []);
};
