import { TypedDocumentNode, gql } from '@apollo/client';
import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { generatePath, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { ValidWidths } from '@sorare/core/src/atoms/ui/ResponsiveImg';
import { StackedCards } from '@sorare/core/src/components/cards/StackedCards';
import { OpenItemDialogLink } from '@sorare/core/src/components/link/OpenItemDialogLink';
import { SEARCH_PARAMS } from '@sorare/core/src/components/search/InstantSearch/types';
import { indexStateToRoute } from '@sorare/core/src/components/search/InstantSearch/utils';
import { SECONDARY_MARKET_STACK_SHOW_BY_SPORT } from '@sorare/core/src/constants/routes';
import { useConfigContext } from '@sorare/core/src/contexts/config';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';

import { useIsPrimaryBuyLive } from 'hooks/primaryBuy/useIsPrimaryBuyLive';

import { StackedToken_anyCard } from './__generated__/index.graphql';

const StyledOpenItemDialogLink = styled(OpenItemDialogLink)`
  width: 100%;
  display: block;
  background-color: transparent;
  position: relative;
`;

const StackedToken = ({
  card,
  width = 320,
  count,
}: {
  card: StackedToken_anyCard;
  width?: ValidWidths;
  count: number;
}) => {
  const {
    sport,
    anyPlayer: player,
    anyTeam: team,
    rarityTyped: rarity,
    seasonYear,
  } = card;
  const { isIosApp, canUseIosNativeCardPage } = useIsMobileApp();
  const { algoliaIndexes } = useConfigContext();
  const track = useEvents();
  const location = useLocation();
  const { indexUiState } = useInstantSearch();
  const [searchParams] = useSearchParams();
  const primaryBuyLive = useIsPrimaryBuyLive(card);
  const { language } = useIntlContext();

  const url = useMemo(() => {
    const queryParams = Object.fromEntries(searchParams);
    const currentStateParams: Record<string, unknown> = {
      ...queryParams,
      ...indexStateToRoute(indexUiState, algoliaIndexes, language),
    };

    // do not forward some query params to the stack view as they are meaningless
    const notForwardableParamsKey: string[] = [
      SEARCH_PARAMS.SORT,
      SEARCH_PARAMS.PAGE,
      SEARCH_PARAMS.QUERY,
    ];
    const forwardableParams = Object.fromEntries(
      Object.entries(currentStateParams).filter(
        ([key]) => !notForwardableParamsKey.includes(key)
      )
    );

    // store backUrl
    forwardableParams.previousParams = location.search
      ? qs.stringify(currentStateParams, {
          format: 'RFC1738',
          arrayFormat: 'comma',
          encodeValuesOnly: true,
          skipNulls: true,
          addQueryPrefix: true,
        })
      : '';
    return `${generatePath(SECONDARY_MARKET_STACK_SHOW_BY_SPORT[sport], {
      playerSlug: player.slug,
      rarity,
    })}?${qs.stringify(forwardableParams)}`;
  }, [
    algoliaIndexes,
    indexUiState,
    language,
    location.search,
    player.slug,
    rarity,
    searchParams,
    sport,
  ]);

  const onClick = useCallback(() => {
    const params = {
      playerSlug: player.slug,
      scarcity: rarity,
      teamSlug: team.slug,
      season: seasonYear,
    };
    track('Click Stack', params);
  }, [seasonYear, player, team, rarity, track]);

  const stackedCards = <StackedCards card={card} width={width} count={count} />;

  if (primaryBuyLive) {
    if (!canUseIosNativeCardPage && isIosApp) return stackedCards;
    return (
      <StyledOpenItemDialogLink item={card} sport={card.sport}>
        {stackedCards}
      </StyledOpenItemDialogLink>
    );
  }
  return (
    <Link to={url} onClick={onClick}>
      {stackedCards}
    </Link>
  );
};

StackedToken.fragments = {
  anyCard: gql`
    fragment StackedToken_anyCard on AnyCardInterface {
      slug
      sport
      anyPlayer {
        slug
      }
      anyTeam {
        slug
      }
      rarityTyped
      seasonYear
      ...useIsPrimaryBuyLive_anyCard
      ...StackedCards_anyCard
    }
    ${useIsPrimaryBuyLive.fragments.anyCard}
    ${StackedCards.fragments.anyCard}
  ` as TypedDocumentNode<StackedToken_anyCard>,
};

export default StackedToken;
