import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { useMemo } from 'react';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { isType } from '@sorare/core/src/lib/gql';

import { TokenTransferValidator } from 'components/token/TokenTransferValidator';
import useRejectOffer from 'hooks/offers/useRejectOffer';

import CounteredOffer from './CounteredOffer';
import CounterpartUser from './CounterpartUser';
import DirectOfferBody from './DirectOfferBody';
import DirectOfferStatus from './DirectOfferStatus';
import { RejectionReason } from './RejectionReason';
import { MySorareDirectOffer_tokenOffer } from './__generated__/index.graphql';

type Props = {
  offer: MySorareDirectOffer_tokenOffer;
  inModale?: boolean;
  refetch?: () => Promise<void>;
};

const Container = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--double-unit);
  background: var(--c-neutral-200);
  color: var(--c-neutral-1000);
  border-radius: var(--double-unit);

  &.inModale {
    background: var(--c-neutral-300);
  }
`;
const Header = styled.div`
  padding-bottom: var(--double-unit);
`;

const DirectOffer = ({ offer, inModale, refetch }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const rejectOffer = useRejectOffer();
  const { status, blockchainId, sender, receiver } = offer;
  const blockAndReject: (() => Promise<void>) | undefined = useMemo(() => {
    if (status === 'accepted') {
      return undefined;
    }
    if (blockchainId === null) {
      return undefined;
    }
    return async () => {
      await rejectOffer({ blockchainId, block: true });
    };
  }, [status, blockchainId, rejectOffer]);

  if (!receiver || !isType(sender, 'User') || !isType(receiver, 'User')) {
    return null;
  }

  const isCurrentUserSender = currentUser?.slug === sender.slug;

  const counterpartUser = isCurrentUserSender ? receiver : sender;
  const counterpartNature = isCurrentUserSender ? 'receiver' : 'sender';

  return (
    <TokenTransferValidator
      cards={
        isCurrentUserSender ? offer.senderSide.cards : offer.receiverSide.cards
      }
      shouldValidate={offer.status === 'opened'}
      transferContext={isCurrentUserSender ? 'send_trade' : 'receive_trade'}
    >
      {({ validationMessages, loading: validationLoading, ConsentMessage }) => (
        <Container className={classNames({ inModale })}>
          <Header>
            <CounterpartUser
              user={counterpartUser}
              offer={offer}
              nature={counterpartNature}
              block={isCurrentUserSender ? undefined : blockAndReject}
            />
          </Header>
          {offer?.rejectionReason && (
            <RejectionReason
              currentUserHasRejected={!isCurrentUserSender}
              nickname={counterpartUser.nickname}
              rejectionReason={offer.rejectionReason}
              offer={offer}
              to={counterpartUser}
            />
          )}
          <DirectOfferBody
            offer={offer}
            counterpartUser={counterpartUser}
            isCurrentUserSender={isCurrentUserSender}
            validationMessages={validationMessages}
          />
          {offer.counteredOffer && (
            <CounteredOffer
              offer={offer.counteredOffer}
              counterpartUser={counterpartUser}
              wasCurrentUserSender={!isCurrentUserSender}
            />
          )}
          <DirectOfferStatus
            validationLoading={validationLoading}
            ConsentMessage={ConsentMessage}
            validationMessages={validationMessages}
            offer={offer}
            isCurrentUserSender={isCurrentUserSender}
            refetch={refetch}
          />
        </Container>
      )}
    </TokenTransferValidator>
  );
};

DirectOffer.fragments = {
  tokenOffer: gql`
    fragment MySorareDirectOffer_tokenOffer on TokenOffer {
      id
      status
      ...MySorareDirectOfferStatus_tokenOffer
      ...CounterpartUser_tokenOffer
      sender {
        ... on User {
          slug
          ...CounterpartUser_publicUserInfoInterface
          ...CounteredOffer_publicUserInfoInterface
          ...MySorareDirectOfferBody_publicUserInfoInterface
          ...RejectionReason_publicUserInfoInterface
        }
      }
      senderSide {
        id
        cards: anyCards {
          slug
          ...TokenTransferValidator_anyCard
        }
      }
      receiver {
        ... on User {
          slug
          ...CounterpartUser_publicUserInfoInterface
          ...CounteredOffer_publicUserInfoInterface
          ...MySorareDirectOfferBody_publicUserInfoInterface
        }
      }
      ...MySorareDirectOfferBody_tokenOffer
      counteredOffer {
        id
        ...CounteredOffer_tokenOffer
      }
      rejectionReason
      ...RejectionReason_tokenOffer
    }
    ${DirectOfferStatus.fragments.tokenOffer}
    ${DirectOfferBody.fragments.tokenOffer}
    ${CounterpartUser.fragments.user}
    ${CounteredOffer.fragments.user}
    ${CounteredOffer.fragments.tokenOffer}
    ${CounterpartUser.fragments.tokenOffer}
    ${TokenTransferValidator.fragments.anyCard}
    ${DirectOfferBody.fragments.user}
    ${RejectionReason.fragments.tokenOffer}
    ${RejectionReason.fragments.user}
  ` as TypedDocumentNode<MySorareDirectOffer_tokenOffer>,
};

export default DirectOffer;
