import { generatePath } from 'react-router-dom';

import { Navigate, useTypedParams } from '@sorare/routing';

import { MLB_MY_CLUB_SLUG } from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const MyClubRedirect = () => {
  const { slug } = useTypedParams<PageParams>();

  return (
    <Navigate
      replace
      to={generatePath(MLB_MY_CLUB_SLUG, {
        slug,
      })}
    />
  );
};

export default MyClubRedirect;
