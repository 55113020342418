import { TypedDocumentNode, gql } from '@apollo/client';
import { compareDesc } from 'date-fns';
import { ReactNode } from 'react';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';

import { Ownership } from '../Ownership';
import { OwnershipHistory_anyCard } from './__generated__/index.graphql';

interface Props {
  card: OwnershipHistory_anyCard;
  title?: ReactNode;
}

export const OwnershipHistory = ({ card, title }: Props) => {
  const { ownershipHistory } = card;

  const sortedOwnerHistory = [...ownershipHistory].sort((ownerA, ownerB) =>
    compareDesc(ownerA.from, ownerB.from)
  );

  if (sortedOwnerHistory.length === 0) return null;

  return (
    <Vertical>
      {title}
      {sortedOwnerHistory.map(owner => (
        <Ownership owner={owner} key={owner.id} />
      ))}
    </Vertical>
  );
};

OwnershipHistory.fragments = {
  anyCard: gql`
    fragment OwnershipHistory_anyCard on AnyCardInterface {
      slug
      ownershipHistory {
        id
        from
        ...Ownership_tokenOwner
        user {
          slug
        }
      }
    }
    ${Ownership.fragments.tokenOwner}
  ` as TypedDocumentNode<OwnershipHistory_anyCard>,
};
export default OwnershipHistory;
