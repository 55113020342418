import { useMutation } from '@apollo/client';
import { compareDesc } from 'date-fns';
import Favico from 'favico.js';
import { FC, useCallback, useEffect, useMemo } from 'react';

import { Sport } from '__generated__/globalTypes';
import { allGroups } from 'components/activity/constants';
import { UserDialog } from 'components/user/UserDialog';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import { idFromObject } from 'gql/idFromObject';
import { useQuery } from 'hooks/graphql/useQuery';
import { useGetNotificationCategories } from 'hooks/notification/useGetNotificationCategories';
import useLifecycle from 'hooks/useLifecycle';

import InGameNotificationContextProvider from './index';
import {
  IN_GAME_NOTIFICATION_QUERY,
  MARK_NOTIFICATIONS_AS_READ_MUTATION,
} from './queries';

const NOTIFICATION_TO_DISPLAY = 15;

/**
 * Provides the in-game notifications for the logged user.
 */
export const InGameNotificationProvider: FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { currentUser } = useCurrentUserContext();
  const { lifecycle } = useLifecycle();
  const getCategories = useGetNotificationCategories();
  const { sport } = useSportContext();
  const currentSport = sport || lifecycle?.lastVisitedSport || Sport.FOOTBALL;

  const { data, loading, refetch } = useQuery(IN_GAME_NOTIFICATION_QUERY, {
    variables: {
      pageSize: NOTIFICATION_TO_DISPLAY,
      sport: currentSport,
      notificationCategories: getCategories(allGroups),
    },
    skip: !currentUser,
  });

  useEffect(() => {
    // Refetch the badge to display the notifications when the (global) unread notifications count changes
    if (currentUser?.id) refetch();
  }, [currentUser?.id, currentUser?.allUnreadNotificationsCount, refetch]);

  const [markNotificationsAsReadMutation] = useMutation(
    MARK_NOTIFICATIONS_AS_READ_MUTATION
  );

  const favicons = useMemo(() => {
    const faviconList: any[] = [];
    document
      .querySelectorAll('link[rel=icon], link[rel="shortcut icon"]')
      .forEach(element => {
        faviconList.push(
          new Favico({
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            bgColor: '#294BFF',
            element,
          })
        );
      });
    return faviconList;
  }, []);

  const unreadNotifications =
    data?.currentUser?.currentSportUnreadNotificationsCount || 0;

  useEffect(() => {
    // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Errors/Dead_object
    try {
      favicons.forEach(element => {
        if (unreadNotifications && unreadNotifications > 0) {
          element.badge(unreadNotifications);
        } else {
          element.reset();
        }
      });
    } catch (e) {
      if (
        !(e instanceof TypeError) ||
        e.message !== "can't access dead object"
      ) {
        throw e;
      }
    }
  }, [favicons, unreadNotifications]);

  const markNotificationsAsRead = useCallback(
    async (notifications?: { id: string }[]) => {
      const notificationIds = notifications
        ?.map(({ id }) => idFromObject(id))
        .filter(Boolean);

      if ((unreadNotifications || 0) > 0 && !loading) {
        await markNotificationsAsReadMutation({
          variables: {
            input: {
              notificationIds,
            },
            sport: currentSport,
            notificationCategories: getCategories(allGroups),
          },
        });
      }
    },
    [
      loading,
      markNotificationsAsReadMutation,
      getCategories,
      unreadNotifications,
      currentSport,
    ]
  );

  const notifications = useMemo(
    () =>
      (data?.currentUser?.currentSportNotifications.nodes || [])
        .filter(Boolean)
        .sort((a, b) => compareDesc(a.createdAt, b.createdAt))
        .slice(0, NOTIFICATION_TO_DISPLAY),
    [data]
  );

  const contextValue = useMemo(
    () => ({
      notifications,
      unreadNotifications,
      loading,
      markNotificationsAsRead,
    }),
    [notifications, unreadNotifications, loading, markNotificationsAsRead]
  );

  return (
    <InGameNotificationContextProvider value={contextValue}>
      <UserDialog />
      {children}
    </InGameNotificationContextProvider>
  );
};

export default InGameNotificationProvider;
