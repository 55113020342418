import { TypedDocumentNode, gql } from '@apollo/client';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Caption } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { isType } from '@sorare/core/src/lib/gql';

import Boosts from 'components/card/CardPageContent/Boosts';

import CardLevelPreview from './CardLevelPreview';
import { Level_card } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 2 })``;
const LevelPreviewContainer = styled.div`
  flex: 1;
`;
const GaugeContainer = styled(Horizontal).attrs({ gap: 2 })``;
const FlexContainer = styled(Horizontal).attrs({ gap: 0.5 })``;
const BoostsButton = styled(ButtonBase)`
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: var(--triple-unit);
  padding: var(--half-unit) var(--unit);
  background:
    linear-gradient(0deg, var(--c-nd-50-opaque), var(--c-nd-50-opaque))
      padding-box,
    linear-gradient(
        92.13deg,
        #ffffff 0%,
        #eabfe2 28.88%,
        #ffffff 48.67%,
        #bedecb 64.58%,
        #ffffff 100%
      )
      border-box;
  text-transform: uppercase;
`;
const GradientOverlay = styled(Caption)`
  background-color: var(--c-neutral-1000);
  /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
  background: linear-gradient(
    92.13deg,
    #ffffff 0%,
    #eabfe2 28.88%,
    #ffffff 48.67%,
    #bedecb 64.58%,
    #ffffff 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;
const LevelUpCounterWrapper = styled(animated.div)`
  display: flex;
  gap: var(--half-unit);
`;

type Props = {
  card: Level_card;
};

export const Level = ({ card }: Props) => {
  const {
    flags: { displayLevelUpUsed = false },
  } = useFeatureFlags();
  const { currentUser } = useCurrentUserContext();
  const [allLevelUpUsed, setAllLevelUpUsed] = useState(false);

  const styles = useSpring({
    from: { x: 0 },
    to: { x: allLevelUpUsed ? 1 : 0 },
    config: { duration: 200 },
    reset: true,
    onRest: () => setAllLevelUpUsed(false),
  });

  const { levelUpAppliedCount, maxLevelUpAppliedCount } = card;
  const isMyCard = card.user?.slug && card.user?.slug === currentUser?.slug;

  const hasLevelUp = card.availableCardBoosts.some(({ shopItem }) =>
    isType(shopItem, 'LevelUpShopItem')
  );

  return (
    <Root>
      <GaugeContainer>
        <LevelPreviewContainer>
          <CardLevelPreview card={card} />
        </LevelPreviewContainer>
        {isMyCard && hasLevelUp && (
          <Dropdown
            align="right"
            label={props => (
              <BoostsButton {...props} disableDebounce>
                <FlexContainer>
                  <FontAwesomeIcon icon={faArrowUp} size="xs" />
                  <GradientOverlay color="var(--c-neutral-1000)" bold>
                    <FormattedMessage
                      id="CardPage.Level.Boosts"
                      defaultMessage="Boost"
                    />
                  </GradientOverlay>
                </FlexContainer>
              </BoostsButton>
            )}
            gap={16}
          >
            {({ closeDropdown }) => (
              <Boosts
                card={card}
                onMaxLevelUpAppliedCountReached={() => setAllLevelUpUsed(true)}
                closeDropdown={closeDropdown}
              />
            )}
          </Dropdown>
        )}
      </GaugeContainer>
      {displayLevelUpUsed && card.xp > 0 && (
        <LevelUpCounterWrapper
          style={{
            transform: styles.x
              .to([0, 0.25, 0.5, 0.75, 1], [0, 10, -10, 10, 0])
              .to(x => `translateX(${x}px)`),
          }}
        >
          <Caption bold>
            {levelUpAppliedCount}/{maxLevelUpAppliedCount}
          </Caption>
          <Caption>
            <FormattedMessage
              id="Level.LevelUpCount"
              defaultMessage="Level Up Boosts applied"
            />
          </Caption>
        </LevelUpCounterWrapper>
      )}
    </Root>
  );
};

Level.fragments = {
  card: gql`
    fragment Level_card on Card {
      slug
      xp
      user {
        slug
      }
      availableCardBoosts {
        # shopItem does not expose an ID because it's a union type
        # eslint-disable-next-line sorare/enforce-apollo-typepolicies
        shopItem {
          ... on ShopItemInterface {
            id
          }
        }
      }
      ...Boosts_card
      ...CardLevelPreview_card
    }
    ${Boosts.fragments.card}
    ${CardLevelPreview.fragments.card}
  ` as TypedDocumentNode<Level_card>,
};
