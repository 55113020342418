import { TypedDocumentNode, gql, useMutation } from '@apollo/client';

import {
  RemoveTokenFromDeckMutation,
  RemoveTokenFromDeckMutationVariables,
} from './__generated__/useRemoveTokenFromDeck.graphql';
import { deckFragment } from './fragments';

const REMOVE_TOKEN_FROM_DECK_MUTATION = gql`
  mutation RemoveTokenFromDeckMutation($input: removeTokenFromDeckInput!) {
    removeTokenFromDeck(input: $input) {
      deck {
        slug
        cards(first: 5) {
          nodes {
            slug
            pictureUrl
          }
        }
        ...deckFragments_deck
      }
      card {
        slug
        decks {
          slug
        }
      }
    }
  }
  ${deckFragment}
` as TypedDocumentNode<
  RemoveTokenFromDeckMutation,
  RemoveTokenFromDeckMutationVariables
>;

export default () => {
  const [removeCard] = useMutation(REMOVE_TOKEN_FROM_DECK_MUTATION);

  return (deckSlug: string) => async (slug: string) =>
    removeCard({
      variables: {
        input: {
          deckSlug,
          slug,
        },
      },
    });
};
