import { LDOptions, withLDProvider } from 'launchdarkly-react-client-sdk';

import { IS_PRERENDER, LAUNCH_DARKLY_CLIENT_SIDE_ID } from '../config';

const preRenderOptions: LDOptions | undefined = IS_PRERENDER
  ? {
      fetchGoals: false, // don't fetch experimentations
      streaming: false, // don't open the WS connection
      sendEvents: false, // don't send events to LaunchDarkly
      sendLDHeaders: false, // skip the Preflight
    }
  : {};

export const withFFProvider = () =>
  withLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
    options: {
      sendEventsOnlyForVariation: true,
      ...preRenderOptions,
    },
    context: {
      kind: 'user',
      key: '00000000-0000-0000-0000-000000000000',
    },
  });
