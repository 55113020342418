import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Box } from '@sorare/core/src/atoms/ui/Box';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import {
  boxBackgroundStyle,
  useTeamBackgroundStyleVariables,
} from 'components/collections/assets/background.style';

import { TeamBox_team } from './__generated__/index.graphql';

const Wrapper = styled(Box)`
  padding: 0 !important;
  overflow: hidden;

  --player-image-size: var(--triple-unit);
  @media ${tabletAndAbove} {
    --player-image-size: var(--quadruple-unit);
  }
`;

const Header = styled.div`
  ${boxBackgroundStyle}
  display: flex;
  padding: var(--intermediate-unit) var(--double-unit);
  font-weight: var(--t-bold);
  border-bottom: 1px solid var(--c-neutral-400);
`;

type Props = {
  team: TeamBox_team;
  title: ReactNode;
  children: ReactNode;
  to?: string;
};

export const TeamBox = ({ team, title, children, to = '' }: Props) => {
  const teamStyle = useTeamBackgroundStyleVariables(team);
  return (
    <Wrapper style={teamStyle}>
      <Header as={Link} to={to}>
        {title}
      </Header>
      {children}
    </Wrapper>
  );
};

TeamBox.fragments = {
  team: gql`
    fragment TeamBox_team on TeamInterface {
      slug
      pictureUrl
      ...getTeamStyleVariables_team
    }
    ${useTeamBackgroundStyleVariables.fragments.team}
  ` as TypedDocumentNode<TeamBox_team>,
};
