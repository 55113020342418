import { TypedDocumentNode, gql } from '@apollo/client';
import classnames from 'classnames';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { hideScrollbar } from '@sorare/core/src/style/utils';

import Formation from 'components/MatchView/Formation';

import FootballFieldLines from './FootballFieldLines';
import { FootballField_game } from './__generated__/index.graphql';

const Root = styled.div`
  isolation: isolate;
  position: relative;
  border-radius: 0 0 var(--double-unit) var(--double-unit);
  color: var(--c-static-neutral-1000);
  background-color: var(--c-nd-50);
  width: 100%;
  aspect-ratio: 600 / 1136;

  &.desktop {
    aspect-ratio: 1136 / 600;
  }
  ${hideScrollbar};
`;
const FootballFieldWrapper = styled.div`
  width: 100%;
`;
const FormationsContainer = styled(Vertical)`
  position: absolute;
  inset: 0;
  &.desktop {
    flex-direction: row;
    gap: 0;
    height: initial;
  }
  & > * {
    flex: 1;
  }
`;

type Props = {
  game: FootballField_game;
  onPlayerDetailsClick: (playerSlug: string, scoreId?: string) => void;
  desktop?: boolean;
};
const FootballField = ({ game, onPlayerDetailsClick, desktop }: Props) => {
  if (!(game.homeTeam && game.awayTeam)) {
    return null;
  }

  return (
    <Root className={classnames({ desktop })}>
      <FootballFieldWrapper className={classnames({ desktop })}>
        <FootballFieldLines desktop={desktop} />
      </FootballFieldWrapper>
      <FormationsContainer className={classnames({ desktop })}>
        <Formation
          desktop={desktop}
          game={game}
          isHomeFormation
          onPlayerDetailsClick={onPlayerDetailsClick}
        />
        <Formation
          desktop={desktop}
          game={game}
          onPlayerDetailsClick={onPlayerDetailsClick}
          reverse
        />
      </FormationsContainer>
    </Root>
  );
};

FootballField.fragments = {
  game: gql`
    fragment FootballField_game on Game {
      id
      awayTeam {
        slug
        code
        pictureUrl(derivative: "high_res")
      }
      homeTeam {
        slug
        code
        pictureUrl(derivative: "high_res")
      }
      ...Formation_game
    }
    ${Formation.fragments.game}
  ` as TypedDocumentNode<FootballField_game>,
};

export default FootballField;
