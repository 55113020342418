import {
  FormEvent,
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import {
  LoadingButton,
  Props as LoadingButtonProps,
} from 'atoms/buttons/LoadingButton';
import { useIntlContext } from 'contexts/intl';
import { glossary } from 'lib/glossary';
import { GraphQLError } from 'lib/gql';

import { ConfirmDialog } from '../ConfirmDialog';
import ErrorField from './Error';
import FormContextProvider, {
  FieldOptions,
  RegisterFieldOptions,
  useFormContext,
} from './context';

type FormatErrorOptions = {
  withAttribute: boolean;
  formatErrorMessage: (message: string, code?: number | null) => string;
};

export type SubmitButtonProps = Omit<LoadingButtonProps, 'loading'>;

type Props<R> = {
  className?: string;
  render: (
    FormError: FunctionComponent<React.PropsWithChildren<{ code?: boolean }>>,
    SubmitButton: FunctionComponent<React.PropsWithChildren<SubmitButtonProps>>,
    opts: { submitting: boolean; confirming: boolean },
    values: { [name: string]: unknown }
  ) => ReactNode;
  renderConfirm?: (
    values: any,
    opts: {
      onConfirm: () => void;
      onClose: () => void;
      loading: boolean;
      open: boolean;
    }
  ) => ReactNode;
  onSubmit: (
    values: any,
    onResult: (result: R) => void,
    onCancel: () => void | undefined
  ) => void;
  onSuccess: (result: R) => void;
  onError?: (result: R) => void;
  errorMessages?: Record<string, MessageDescriptor>;
  askForConfirmation?: boolean;
  confirmationMessage?: ReactNode;
  dialogTitle?: ReactNode;
  dialogSubtitle?: ReactNode;
  dialogCta?: ReactNode;
  dialogCtaProps?: Omit<SubmitButtonProps, 'size'>;
  autoComplete?: boolean;
  onChange?: (values: any, submit: () => void) => void;
};

type ErrorHandler<R, E> = {
  getErrors: (result: R) => readonly E[] | null;
  formatError: (error: E, options: FormatErrorOptions) => string | null;
  getField: (field: E) => string | undefined | null;
};

const FormError = (props: any) => {
  const { error } = useFormContext();
  return <ErrorField error={error} {...props} />;
};
const Submit = ({ size: variant, ...props }: SubmitButtonProps) => {
  const { submitting } = useFormContext();
  return (
    <LoadingButton
      type="submit"
      color="primary"
      loading={submitting}
      size={variant}
      {...props}
    />
  );
};

/**
 * The Form class is used to factorize forms code and better handle errors.
 * A Form defines a FormContext that is used by it children Fields to register themselves.
 * This registering allows the form to redistribute errors that are linked to fields directly to them.
 *
 * When rendering, Form also provides:
 * - an Error element with any error that could not be linked to a specific field.
 * - a Submit button that is disabled when the form is being submitted.
 */
export const Form = <R extends { error?: string }, E>({
  className = '',
  render,
  renderConfirm,
  onSubmit,
  onSuccess,
  onError,
  errorMessages,
  askForConfirmation = Boolean(renderConfirm),
  confirmationMessage,
  dialogTitle,
  dialogSubtitle,
  dialogCta,
  dialogCtaProps,
  autoComplete = false,
  errorHandler: { getErrors, getField, formatError },
  onChange,
}: Props<R> & { errorHandler: ErrorHandler<R, E> }) => {
  const [fields, setFields] = useState<{ [name: string]: FieldOptions<any> }>(
    {}
  );
  const [values, setValues] = useState<{ [name: string]: any }>({});
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const { formatMessage } = useIntlContext();
  const [promptConfirm, setPromptConfirm] = useState(false);

  const formatErrorMessage = useCallback(
    (message: string, code?: number | null) => {
      return code && errorMessages && code in errorMessages
        ? formatMessage(errorMessages[code])
        : message;
    },
    [errorMessages, formatMessage]
  );

  const setErrors = useCallback(
    (errors: readonly E[]) => {
      const orphanErrors: E[] = [];
      errors.forEach(e => {
        const field = getField(e);
        const opts = field && fields[field];
        if (opts) {
          opts.onError(
            formatError(e, {
              withAttribute: false,
              formatErrorMessage,
            })
          );
        } else {
          orphanErrors.push(e);
        }
      });
      if (orphanErrors.length > 0) {
        setError(
          orphanErrors
            .map(err =>
              formatError(err, { formatErrorMessage, withAttribute: false })
            )
            .join(' ')
        );
      }
    },
    [fields, formatError, formatErrorMessage, getField]
  );

  const onResult = useCallback(
    (result: R) => {
      setSubmitting(false);
      const errors = getErrors(result);

      if (errors) {
        setErrors(errors);
        if (onError) onError(result);
      } else if (result.error) {
        setError(result.error);
        if (onError) onError(result);
      } else {
        onSuccess(result);
      }
    },
    [getErrors, onError, onSuccess, setErrors]
  );

  const onCancel = () => {
    setSubmitting(false);
  };

  const formattedValues = Object.keys(fields).reduce<{
    [field: string]: any;
  }>((acc, field) => {
    acc[field] = values[field];
    return acc;
  }, {});

  const resetErrors = useCallback(() => {
    Object.values(fields).forEach(({ onError: onErr }) => onErr(null));
  }, [fields]);

  const handleConfirm = (payload: Record<string, string>) => {
    setError(null);
    setSubmitting(true);
    setPromptConfirm(false);

    resetErrors();
    onSubmit(payload, onResult, onCancel);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (askForConfirmation) {
      setPromptConfirm(true);
    } else {
      handleConfirm(formattedValues);
    }
  };

  const handleChange =
    (name: string) =>
    (
      value: string,
      target?: {
        setCustomValidity?: (error: string) => void;
        reportValidity?: () => boolean;
      }
    ) => {
      const opts = fields[name];
      if (!opts) {
        throw new Error(
          `Unexpected field ${name}! Fields should be registered.`
        );
      }
      const newValues = {
        ...values,
        [name]: value,
      };
      setValues(newValues);
      opts.onChange(value);
      const validationError = opts.validate?.(value);
      target?.setCustomValidity?.(validationError || '');
      target?.reportValidity?.();
      onChange?.(newValues, () => handleConfirm(newValues));
    };

  const registerField = useCallback(
    (name: string, { defaultValue, ...opts }: RegisterFieldOptions<any>) => {
      setFields(currentFields => ({
        ...currentFields,
        [name]: opts,
      }));
      setValues(currentValues => ({
        [name]: defaultValue || '',
        ...currentValues,
      }));
    },
    []
  );

  const onConfirm = () => handleConfirm(formattedValues);
  const onClose = () => setPromptConfirm(false);

  const ConfirmationDialog = renderConfirm?.(formattedValues, {
    onConfirm,
    onClose,
    open: promptConfirm,
    loading: submitting,
  }) || (
    <ConfirmDialog
      open={promptConfirm}
      onConfirm={onConfirm}
      onClose={onClose}
      {...(dialogTitle && { title: dialogTitle })}
      {...(dialogSubtitle && { subtitle: dialogSubtitle })}
      {...(dialogCtaProps && { ctaProps: dialogCtaProps })}
      message={
        confirmationMessage || (
          <FormattedMessage id="Form.confirm" defaultMessage="Are you sure?" />
        )
      }
      cta={dialogCta || formatMessage(glossary.yes)}
    />
  );

  return (
    <FormContextProvider
      value={{
        registerField,
        handleChange,
        error,
        submitting,
        fields,
      }}
    >
      <form
        className={className}
        autoComplete={autoComplete ? 'on' : 'off'}
        onSubmit={handleSubmit}
      >
        {askForConfirmation && promptConfirm && ConfirmationDialog}
        {render(
          FormError,
          Submit,
          {
            submitting,
            confirming: promptConfirm,
          },
          values
        )}
      </form>
    </FormContextProvider>
  );
};

export interface GraphQLResult {
  error?: string;
  errors?: readonly GraphQLError[] | null;
}

const graphqlErrorHandler: ErrorHandler<GraphQLResult, GraphQLError> = {
  getErrors: result =>
    result.errors && result.errors.length > 0 ? result.errors : null,
  formatError: (error, options: FormatErrorOptions) => {
    const { withAttribute, formatErrorMessage } = options;
    return `${
      withAttribute ? `${error.path ? error.path[1] : ''} ` : ''
    }${formatErrorMessage(error.message, error.code)}`;
  },
  getField: error => error.path?.[1],
};

export interface RestResult {
  error?: string;
  errors?: { [key: string]: string };
}

export const GraphqlForm = (props: Props<GraphQLResult>) => (
  <Form {...props} errorHandler={graphqlErrorHandler} />
);

export { default as Field } from './Field';
export { default as TextField } from './TextField';
export { default as EthereumAddressField } from './EthereumAddressField';
export { default as SwitchField } from './SwitchField';
