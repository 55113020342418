import { useCallback } from 'react';

import useSubscribe from './useSubscribe';
import useUnsubscribe from './useUnsubscribe';

export default (
  subscription: { slug: string } | null | undefined,
  subscribable: { __typename: string; slug: string },
  onToggled?: () => void
) => {
  const unsubscribe = useUnsubscribe();
  const subscribe = useSubscribe();

  const toggleSubscription = useCallback(async () => {
    if (subscription) {
      await unsubscribe(subscription);
    } else {
      await subscribe(subscribable);
    }
    onToggled?.();
  }, [subscription, onToggled, unsubscribe, subscribe, subscribable]);

  return toggleSubscription;
};
