import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '__generated__/globalTypes';
import useMutation from 'hooks/graphql/useMutation';

import {
  CreateDeckMutation,
  CreateDeckMutationVariables,
} from './__generated__/useCreateDeck.graphql';
import { deckFragment } from './fragments';

const CREATE_DECK_MUTATION = gql`
  mutation CreateDeckMutation($input: createDeckInput!) {
    createDeck(input: $input) {
      deck {
        slug
        ...deckFragments_deck
      }
      userSportProfile {
        ... on UserSportProfileInterface {
          id
          decks(query: "") {
            nodes {
              slug
              name
              visible
              sport
              tokensCount
            }
          }
        }
      }
      errors {
        message
        code
      }
    }
  }
  ${deckFragment}
` as TypedDocumentNode<CreateDeckMutation, CreateDeckMutationVariables>;

export default () => {
  const [createDeck] = useMutation(CREATE_DECK_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  return async (variables: { sport: Sport; name: string; visible: boolean }) =>
    createDeck({
      variables: {
        input: variables,
      },
    });
};
