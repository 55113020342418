import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { TokenOfferRejectionReason } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

import NewPrefilledOfferBuilder, {
  Props as NewPrefilledOfferBuilderProps,
} from 'components/directOffer/NewPrefilledOfferBuilder';

import {
  RejectionReason_publicUserInfoInterface,
  RejectionReason_tokenOffer,
} from './__generated__/index.graphql';

const currentUserHasRejectedMessages = defineMessages({
  [TokenOfferRejectionReason.OFFER_TOO_LOW]: {
    id: 'RejectionReason.currentUser.OFFER_TOO_LOW',
    defaultMessage: 'You rejected because the offer is too low.',
  },
  [TokenOfferRejectionReason.NOT_SELLING]: {
    id: 'RejectionReason.currentUser.NOT_SELLING',
    defaultMessage: 'You rejected because you do not want to sell.',
  },
  [TokenOfferRejectionReason.ONLY_CASH]: {
    id: 'RejectionReason.currentUser.ONLY_CASH',
    defaultMessage: 'You rejected because you only want money.',
  },
  [TokenOfferRejectionReason.ADD_CASH]: {
    id: 'RejectionReason.currentUser.ADD_CASH',
    defaultMessage: 'You rejected because you want more money.',
  },
  [TokenOfferRejectionReason.IN_A_LINEUP]: {
    id: 'RejectionReason.currentUser.IN_A_LINEUP',
    defaultMessage: 'You rejected because you play the card in a lineup.',
  },
  [TokenOfferRejectionReason.CARD_NOT_WANTED]: {
    id: 'RejectionReason.currentUser.CARD_NOT_WANTED',
    defaultMessage: 'You rejected because you do no want to swap this card(s).',
  },
});

const counterPartUserRejectedMessages = defineMessages({
  [TokenOfferRejectionReason.OFFER_TOO_LOW]: {
    id: 'RejectionReason.OFFER_TOO_LOW',
    defaultMessage: '{nickname} rejected because the offer is too low.',
  },
  [TokenOfferRejectionReason.NOT_SELLING]: {
    id: 'RejectionReason.NOT_SELLING',
    defaultMessage: '{nickname} doesn’t want to sell and rejected your offer.',
  },
  [TokenOfferRejectionReason.ONLY_CASH]: {
    id: 'RejectionReason.ONLY_CASH',
    defaultMessage: '{nickname} rejected because they only want money.',
  },
  [TokenOfferRejectionReason.ADD_CASH]: {
    id: 'RejectionReason.ADD_CASH',
    defaultMessage:
      '{nickname} rejected because they want you to add more money.',
  },
  [TokenOfferRejectionReason.IN_A_LINEUP]: {
    id: 'RejectionReason.IN_A_LINEUP',
    defaultMessage:
      '{nickname} rejected because they currently play the card in a lineup.',
  },
  [TokenOfferRejectionReason.CARD_NOT_WANTED]: {
    id: 'RejectionReason.CARD_NOT_WANTED',
    defaultMessage:
      '{nickname} rejected your offer because they do not want to swap your card(s).',
  },
});

type Props = Omit<
  NewPrefilledOfferBuilderProps,
  'onClose' | 'currentUser' | 'isCounterOffer'
> & {
  currentUserHasRejected: boolean;
  nickname: string;
  rejectionReason: TokenOfferRejectionReason;
  offer: RejectionReason_tokenOffer;
  to: RejectionReason_publicUserInfoInterface;
};
export const RejectionReason = ({
  currentUserHasRejected,
  nickname,
  offer,
  to,
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { rejectionReason } = offer;
  const [openNewTradeDialog, setOpenNewTradeDialog] = useState(false);
  if (!currentUser || !rejectionReason) return null;
  return (
    <Vertical gap={2}>
      <Text16>
        <FormattedMessage
          {...(currentUserHasRejected
            ? currentUserHasRejectedMessages[rejectionReason]
            : counterPartUserRejectedMessages[rejectionReason])}
          values={{ nickname }}
        />
      </Text16>
      {!currentUserHasRejected && (
        <div>
          <Button
            size="medium"
            color="tertiary"
            onClick={() => setOpenNewTradeDialog(true)}
          >
            <FormattedMessage
              id="rejectrionReason.proposeNewTrade"
              defaultMessage="Propose a new trade"
            />
          </Button>
          {openNewTradeDialog && (
            <NewPrefilledOfferBuilder
              onClose={() => setOpenNewTradeDialog(false)}
              to={to}
              offer={offer}
              currentUser={currentUser}
            />
          )}
        </div>
      )}
    </Vertical>
  );
};

RejectionReason.fragments = {
  tokenOffer: gql`
    fragment RejectionReason_tokenOffer on TokenOffer {
      id
      rejectionReason
      ...NewPrefilledOfferBuilder_tokenOffer
    }
    ${NewPrefilledOfferBuilder.fragments.tokenOffer}
  ` as TypedDocumentNode<RejectionReason_tokenOffer>,
  user: gql`
    fragment RejectionReason_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      ...NewPrefilledOfferBuilder_publicUserInfoInterface
    }
    ${NewPrefilledOfferBuilder.fragments.user}
  ` as TypedDocumentNode<RejectionReason_publicUserInfoInterface>,
};
