import { createContext, useContext } from 'react';

import { BrazeAPI, BrazeData } from './types';

export type BrazeContextType = {
  data: BrazeData;
  api: BrazeAPI | null;
};

export const brazeContext = createContext<BrazeContextType | null>(null);

export const useBrazeContext = () => useContext(brazeContext)!;

export default brazeContext.Provider;
