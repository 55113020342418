import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
} from 'react';
import { To } from 'react-router-dom';

export type Action = {
  onClick?: () => void;
  to?: To;
  label: ReactNode;
};

export type PrimaryBuyActions = {
  primary?: Action;
  secondary?: Action;
};

export type PrimaryBuyConfirmationOptions = {
  actions?: PrimaryBuyActions;
  variant?: 'rivals' | 'pro';
};

export type BuyConfirmationProviderStateProps = {
  tokenOfferId?: string;
  onClose?: () => void;
  beforeOnClose?: () => void;
  primaryBuyId?: string;
  primaryBuyConfirmationOptions?: PrimaryBuyConfirmationOptions;
};

export interface BuyConfirmationContext {
  showBuyingConfirmation: boolean;
  setBuyConfirmationProps: Dispatch<
    SetStateAction<BuyConfirmationProviderStateProps | null>
  >;
  setShowBuyingConfirmation: (bool: boolean) => void;
}

export const buyConfirmationContext =
  createContext<BuyConfirmationContext | null>(null);

export const useBuyConfirmationContext = () =>
  useContext(buyConfirmationContext)!;

export default buyConfirmationContext.Provider;
