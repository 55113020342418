import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Props as ButtonProps,
} from '@sorare/core/src/atoms/buttons/Button';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { UnvailableButtonForAndroid } from '@sorare/core/src/components/BuyableFeature';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { glossary, mobileApp } from '@sorare/core/src/lib/glossary';

import PrimaryOfferBuyFieldWrapper, {
  Props as PrimaryOfferBuyFieldWrapperProps,
} from './PrimaryOfferBuyFieldWrapper';
import { PrimaryBuyBuyField_anyCard } from './__generated__/index.graphql';

export type Props = Omit<PrimaryOfferBuyFieldWrapperProps, 'children'> &
  Omit<ButtonProps, 'loading'> & { cta?: ReactNode };

export const PrimaryBuyBuyField = ({
  card,
  primaryBuyConfirmationOptions,
  onSuccess,
  onBuyConfirmationClose,
  origin,
  gameSlug,
  showBuyConfirmation = true,
  onClick,
  onlyCreditCards = false,
  conversionCreditDisclaimer,
  defaultConversionCredit,
  composeSessionId,
  cta,
  ...props
}: Props) => {
  const { isAndroidApp } = useIsMobileApp();

  if (!card.latestPrimaryOffer?.price) return null;

  return (
    <PrimaryOfferBuyFieldWrapper
      card={card}
      primaryBuyConfirmationOptions={primaryBuyConfirmationOptions}
      onSuccess={onSuccess}
      onBuyConfirmationClose={onBuyConfirmationClose}
      origin={origin}
      gameSlug={gameSlug}
      showBuyConfirmation={showBuyConfirmation}
      onlyCreditCards={onlyCreditCards}
      conversionCreditDisclaimer={conversionCreditDisclaimer}
      defaultConversionCredit={defaultConversionCredit}
      composeSessionId={composeSessionId}
      onClick={onClick}
    >
      {({ onClick: onBuyClick, loading, disabled }) => {
        if (isAndroidApp && disabled) {
          return <UnvailableButtonForAndroid color="primary" {...props} />;
        }
        if (disabled) {
          return (
            <Button color="primary" {...props} disabled>
              <FormattedMessage {...mobileApp.unavailable} />
            </Button>
          );
        }
        return (
          <LoadingButton
            color="primary"
            onClick={onBuyClick}
            {...props}
            loading={loading}
          >
            {cta || <FormattedMessage {...glossary.buy} />}
          </LoadingButton>
        );
      }}
    </PrimaryOfferBuyFieldWrapper>
  );
};

PrimaryBuyBuyField.fragments = {
  anyCard: gql`
    fragment PrimaryBuyBuyField_anyCard on AnyCardInterface {
      slug
      latestPrimaryOffer {
        id
        appleProductId
      }
      ...PrimaryOfferBuyFieldWrapper_anyCard
    }
    ${PrimaryOfferBuyFieldWrapper.fragments.anyCard}
  ` as TypedDocumentNode<PrimaryBuyBuyField_anyCard>,
};

export default PrimaryBuyBuyField;
