import { TypedDocumentNode, gql, useMutation } from '@apollo/client';

import { TokenOfferRejectionReason } from '@sorare/core/src/__generated__/globalTypes';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';

import {
  RejectOfferMutation,
  RejectOfferMutationVariables,
} from './__generated__/useRejectOffer.graphql';

export const REJECT_OFFER_MUTATION = gql`
  mutation RejectOfferMutation($input: rejectOfferInput!) {
    rejectOffer(input: $input) {
      tokenOffer {
        id
        status
        rejectionReason
      }
      errors {
        path
        message
        code
      }
    }
  }
` as TypedDocumentNode<RejectOfferMutation, RejectOfferMutationVariables>;

type RejectionArgs = {
  blockchainId: string;
  block: boolean;
  rejectionReason?: TokenOfferRejectionReason;
};

export default () => {
  const [rejectOffer] = useMutation(REJECT_OFFER_MUTATION);
  const { showNotification } = useSnackNotificationContext();

  return async ({ blockchainId, block, rejectionReason }: RejectionArgs) => {
    const { errors } = await rejectOffer({
      variables: {
        input: {
          blockchainId,
          block,
          rejectionReason,
        },
      },
    });
    if (errors) {
      showNotification('errors', { errors });
      return errors;
    }
    return null;
  };
};
