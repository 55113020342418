import { faArrowDownLong } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { FontAwesomeIcon } from 'atoms/icons';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Text16, Text18 } from 'atoms/typography';
import { SlideShow } from 'atoms/ui/SlideShow';
import { ContentContainer } from 'components/landing/LandingMultiSport/ui';
import {
  messages as globalMessages,
  useHeroAnimationTimings,
} from 'components/landing/LandingMultiSport/utils';
import { IS_PRERENDER } from 'config';
import { useConnectionContext } from 'contexts/connection';
import { CurrentUser, useCurrentUserContext } from 'contexts/currentUser';
import useScreenSize from 'hooks/device/useScreenSize';
import { useStoreBadge } from 'hooks/useStoreBadge';
import { useStoreUrl } from 'hooks/useStoreUrl';
import { glossary } from 'lib/glossary';
import {
  desktopAndAbove,
  laptopAndAbove,
  tabletAndAbove,
} from 'style/mediaQuery';

import { PlayNowSportsButtons } from './PlayNowSportsButtons';

const Ratio16by9 = styled.div`
  width: 100vw;
  isolation: isolate;
  overflow: hidden;
  position: relative;
  padding-bottom: 100vh;

  @media ${laptopAndAbove} {
    padding-bottom: max(
      750px,
      min(
        calc(100vh - var(--navbar-height-mobile)),
        calc(100% / (1400 / 900) - var(--navbar-height-mobile))
      )
    );
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: var(--double-and-a-half-unit);
  align-items: flex-end;
  flex-direction: column;

  @media ${tabletAndAbove} {
    padding-bottom: calc(var(--unit) * 5);
  }

  @media ${laptopAndAbove} {
    padding-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      transparent calc(var(--navbar-height-mobile) * 2),
      transparent 25%,
      black
    );
    z-index: 1;
  }

  & > div {
    z-index: 2;
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FullHeightSlideShow = styled(SlideShow)`
  position: absolute;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: var(--navbar-height-mobile);
`;

const BigLogoWrapper = styled(animated.div)`
  width: 100%;
  bottom: var(--triple-unit);
  position: absolute;

  padding-inline: var(--double-unit);
  justify-content: center;
  @media ${laptopAndAbove} {
    top: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    transform: translateY(-100%);
    padding: 0;
  }
`;

const BigLogo = styled(SorareLogo)`
  width: 100%;
  height: auto;
  max-height: 80%;
`;

const TextContent = styled(animated.div)`
  display: flex;
  flex-direction: column;
  gap: var(--double-and-a-half-unit);

  @media ${laptopAndAbove} {
    gap: calc(var(--unit) * 5);
  }
`;

const Title = styled.h1`
  line-height: 1;
  font-weight: 400;
  font-size: 28px;

  @media ${laptopAndAbove} {
    font-size: 40px;
  }

  @media ${laptopAndAbove} {
    font-size: 48px;
  }
`;

const SubTitle = styled(Text16)`
  max-width: 455px;
  margin-bottom: var(--double-unit);

  @media ${tabletAndAbove} {
    font-size: 18px;
  }
`;

const CTAsWrapper = styled(Vertical)`
  align-items: start;
  justify-content: space-between;
  gap: var(--quadruple-unit);

  @media ${tabletAndAbove} {
    gap: var(--unit);
    align-items: center;
    flex-direction: row;
  }
`;

const StoresLinksWrapper = styled(Horizontal)`
  gap: var(--unit);

  img {
    height: 32px;

    @media ${desktopAndAbove} {
      height: 40px;
    }
  }
`;

const ScrollDownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--intermediate-unit) 0;
  padding-bottom: var(--double-unit);
  position: relative;
  border-bottom: 1px solid var(--c-neutral-700);

  --border-size: 1px;
  border-bottom: var(--border-size) solid var(--c-neutral-700);

  @media ${laptopAndAbove} {
    &::after {
      content: '';
      top: 0;
      right: 0;
      bottom: calc(-1 * var(--border-size));
      position: absolute;
      z-index: -1;
      background-color: black;
      width: calc((100vw - 100%) / 2 + 100%);
      transform: translateY(100%);
      transition: transform 0.75s ease-in-out;
      overflow: hidden;
    }

    &.animated::after {
      overflow: auto;
      transform: translateY(0);
    }
  }
`;

const MainContent = styled.div`
  width: 100%;
`;

const ScrollDown = styled(Horizontal).attrs({ gap: 2 })`
  padding-right: var(--quadruple-unit);
`;

export type HeroBlockProps = {
  title: string | ReactNode;
  poster: string;
  subtitle: string | ReactNode;
  videoSrc: string;
  onPlayNow?: () => void;
  mobileSlides: string[];
  scrollDownText?: string;
  alernativeLogo?: ReactNode;
  additionalText?: string | ReactNode;
  currentUser?: CurrentUser | null;
  showStoreLinks?: boolean;
  additionalCTA?: ReactNode;
};

export const DumbHero = ({
  title,
  poster,
  subtitle,
  videoSrc,
  onPlayNow,
  mobileSlides,
  currentUser,
  scrollDownText,
  additionalCTA,
  additionalText,
  showStoreLinks,
  alernativeLogo,
}: HeroBlockProps) => {
  const { formatMessage } = useIntl();
  const { appleBadge, googleBadge } = useStoreBadge();
  const { firstBatch, secondBatch } = useHeroAnimationTimings();
  const { up: isLaptop } = useScreenSize('laptop');
  const { up: isTabletOrDesktop } = useScreenSize('tablet');

  let animationValues;

  // Determine the animation values based on the toggle and logoFadeOut states:
  // If logoFadeOut is true, the logo should slide up off the screen and become fully transparent.
  // If logoFadeOut is false but toggle is true, the logo should slide up but only partially, and it should remain fully opaque.
  // If both logoFadeOut and toggle are false, the logo should remain in its original position and fully opaque.
  if (secondBatch) {
    animationValues = {
      transform: 'translateY(-100%)',
      opacity: 0,
    };
  } else if (firstBatch) {
    animationValues = {
      transform: 'translateY(-50%)',
      opacity: 1,
    };
  } else {
    animationValues = {
      transform: 'translateY(0%)',
      opacity: 1,
    };
  }

  const animation = useSpring({
    ...animationValues,
    config: { duration: 500 },
  });

  const textContentAnimation = useSpring({
    opacity: firstBatch ? 1 : 0,
    transform: firstBatch ? 'translateY(0%)' : 'translateY(50%)',
    config: { duration: 500 },
  });

  const bounce = useSpring({
    from: { y: 4 },
    to: [{ y: 4 }, { y: -4 }],
    config: { duration: 800 },
    loop: { reverse: true },
  });

  const storeUrl = useStoreUrl();

  return (
    <Ratio16by9>
      <Wrapper>
        {isLaptop ? (
          <Video
            {...(IS_PRERENDER ? { preload: 'none' } : { autoPlay: true })}
            loop
            muted
            playsInline
            poster={poster}
          >
            <source src={videoSrc} type="video/webm" />
          </Video>
        ) : (
          <FullHeightSlideShow images={mobileSlides} />
        )}
        <LogoContainer>
          <BigLogoWrapper style={isLaptop ? animation : {}}>
            {alernativeLogo || <BigLogo />}
          </BigLogoWrapper>
        </LogoContainer>
        <MainContent>
          <ContentContainer>
            <TextContent style={textContentAnimation}>
              <Title>{title}</Title>
              <div>
                <SubTitle>{subtitle}</SubTitle>
                {additionalText && <SubTitle>{additionalText}</SubTitle>}
                <CTAsWrapper>
                  {onPlayNow ? (
                    <Horizontal gap={3}>
                      <Button color="tertiary" size="small" onClick={onPlayNow}>
                        {formatMessage(glossary.playNow)}
                      </Button>
                      {additionalCTA}
                    </Horizontal>
                  ) : null}
                  {showStoreLinks && (
                    <StoresLinksWrapper gap={2}>
                      <a href={storeUrl} target="_blank" rel="noreferrer">
                        <img src={appleBadge} alt="" />
                      </a>
                      <a href={storeUrl} target="_blank" rel="noreferrer">
                        <img src={googleBadge} alt="" />
                      </a>
                    </StoresLinksWrapper>
                  )}
                </CTAsWrapper>
              </div>
              {isTabletOrDesktop && !currentUser && (
                <ScrollDownWrapper
                  className={classNames({
                    animated: isLaptop && secondBatch,
                  })}
                >
                  <ScrollDown>
                    <animated.div
                      style={{
                        transform: bounce.y.to(y => `translateY(${y}px)`),
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowDownLong} />
                    </animated.div>
                    <Text18>
                      {scrollDownText ||
                        formatMessage(globalMessages.ScrollDownCTA)}
                    </Text18>
                  </ScrollDown>
                </ScrollDownWrapper>
              )}
            </TextContent>
          </ContentContainer>
          {currentUser && <PlayNowSportsButtons />}
        </MainContent>
      </Wrapper>
    </Ratio16by9>
  );
};

export const HeroBlock = ({
  onPlayNow,
  ...props
}: Omit<HeroBlockProps, 'currentUser'>) => {
  const navigate = useNavigate();
  const { signUp } = useConnectionContext();
  const { currentUser } = useCurrentUserContext();

  const defaultPlayNowAction = currentUser ? () => navigate('/') : signUp;
  const onPlayNowAction = onPlayNow ?? defaultPlayNowAction;

  return (
    <DumbHero
      onPlayNow={onPlayNowAction}
      currentUser={currentUser}
      {...props}
    />
  );
};
