import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import {
  MuiThemeProvider,
  StylesProvider,
  createTheme,
  jssPreset,
} from '@material-ui/core/styles';
import createSpacing from '@material-ui/core/styles/createSpacing';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { ReactNode, useMemo } from 'react';

import { FontAwesomeIcon } from 'atoms/icons';
import { useIntlContext } from 'contexts/intl';

// Inject jss styles first in order to override them with Styled-components without resorting to && or important
// Inspired by https://v4.mui.com/guides/interoperability/#controlling-priority-3
// We can't use injectFirst as we override jss, so we need to handle this ourselves
// https://github.com/mui/material-ui/blob/b2e1f01ef7d2433c92829dffd30e4ba484bd3d4f/packages/mui-styles/src/StylesProvider/StylesProvider.js#L85-L89
const injectFirstNode = document.createComment('mui-inject-first');
document.head.insertBefore(injectFirstNode, document.head.firstChild);
export const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: injectFirstNode,
});

const spacing = createSpacing(10);

const borderRadius = 8;

const font = (
  fontFamily: string,
  fontWeight: number,
  opts = {}
): { fontFamily: string; fontWeight: number; fontStyle: string } => ({
  fontFamily,
  fontWeight,
  fontStyle: 'normal',
  ...opts,
});

const fonts = {
  sorareRegular: font(
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    400
  ),
  sorareBold: font(
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    700
  ),
};

export const materialUIOpacity = 0.42; // value used by material-ui. Using the same for consistency until the library is dropped.

export const theme = {
  spacing,
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    modal: 1300,
    backdrop: 1299,
    drawer: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  palette: {
    primary: {
      main: '#4C61EE', // Necessary duplicate for css variable palette because MUI use alpha/darken/lighten internally.
    },
    secondary: {
      light: '#ddd',
      main: '#000',
      dark: '#cccccc',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'var(--sorareFont)',
  },
  props: {
    MuiMenu: {
      anchorOrigin: {
        horizontal: 'center' as const,
        vertical: 'bottom' as const,
      },
      transformOrigin: {
        horizontal: 'center' as const,
        vertical: 'top' as const,
      },
    },
    MuiBreadcrumbs: {
      separator: <FontAwesomeIcon icon={faCaretRight} />,
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        color: 'var(--c-neutral-1000)',
      },
      rounded: {
        borderRadius,
      },
      elevation1: {
        boxShadow: '0px 14px 54px rgba(0, 0, 0, 0.2)',
      },
      elevation8: {
        boxShadow: '0px 14px 54px rgba(0, 0, 0, 0.2)',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(var(--c-static-rgb-neutral-1000), 0.6)',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: 'var(--c-neutral-600)',
        '&$checked': {
          color: 'var(--c-brand-600)',
        },
      },
      root: {
        '& .MuiIconButton-label': {
          position: 'relative',
          zIndex: 0,
        },
        '&:not($checked) .MuiIconButton-label:after': {
          content: '""',
          left: 4,
          top: 4,
          height: 15,
          width: 15,
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: -1,
        },
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiMenuItem: {
      root: {
        ...fonts.sorareBold,
        fontSize: 15,
        lineHeight: '22px',
        color: 'var(--c-neutral-1000)',
        '&:hover': {
          color: 'var(--c-brand-600)',
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid var(--c-neutral-300)',
        backgroundColor: 'var(--c-neutral-100)',
      },
      indicator: {
        backgroundColor: 'var(--c-brand-1000)',
      },
    },
    MuiDialog: {
      root: {
        // Dialogs are injected as children of body so they can be selected using the `~` selector
        // If a dialog is injected after the ConnectionDialog, it should be displayed over that dialog
        // And the absolutely positioned iframe at zIndex 1301.
        '[data-dialog=ConnectionDialog] ~ &': {
          zIndex: '1500 !important',
        },
        '&[data-dialog=EmailVerificationDialog]': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: 15,
        lineHeight: '24px',
        minWidth: '0 !important',
        '&$selected': {
          ...fonts.sorareBold,
          color: 'var(--c-brand-1000)',
        },
        '&:not($selected)': {
          ...fonts.sorareRegular,
          color: 'var(--c-neutral-600)',
        },
      },
    },
  },
  colors: {
    scarcity: {
      common: `var(--c-gradient-common)`,
      limited: `var(--c-gradient-limited)`,
      rare: `var(--c-gradient-rare)`,
      superRare: `var(--c-gradient-superRare)`,
      superRareMlb: `var(--c-gradient-superRareMlb)`,
      unique: `var(--c-gradient-unique)`,
      mix: `var(--c-gradient-mix)`,
      worldCup: `var(--c-gradient-worldCup)`,
      customSeries: `var(--c-gradient-customSeries)`,
    },
  },
  shape: {
    borderRadius,
  },
};

export type CustomTheme = typeof theme;

declare module '@material-ui/core/styles/createTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends CustomTheme {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions extends CustomTheme {}
}

interface Props {
  children: ReactNode;
}

export const useDirectionalTheme = () => {
  const { dir } = useIntlContext();

  return useMemo(() => createTheme({ ...theme, direction: dir }), [dir]);
};

const ThemeProvider = ({ children }: Props) => {
  const directionalTheme = useDirectionalTheme();
  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider
        theme={{
          ...directionalTheme,
        }}
      >
        {children}
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProvider;
