// Generated by the protocol buffer compiler.
/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import {
  Sport,
  sportFromJSON,
  sportToJSON,
} from '../../../events/shared/events';

export const protobufPackage = 'events.so5.shared';

export interface LoginUser {
  method: LoginUser_Method;
  failed: boolean;
}

export enum LoginUser_Method {
  EMAIL = 0,
  GOOGLE = 1,
  FACEBOOK = 2,
  APPLE = 3,
  UNRECOGNIZED = -1,
}

export function loginUser_MethodFromJSON(object: any): LoginUser_Method {
  switch (object) {
    case 0:
    case 'EMAIL':
      return LoginUser_Method.EMAIL;
    case 1:
    case 'GOOGLE':
      return LoginUser_Method.GOOGLE;
    case 2:
    case 'FACEBOOK':
      return LoginUser_Method.FACEBOOK;
    case 3:
    case 'APPLE':
      return LoginUser_Method.APPLE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LoginUser_Method.UNRECOGNIZED;
  }
}

export function loginUser_MethodToJSON(object: LoginUser_Method): string {
  switch (object) {
    case LoginUser_Method.EMAIL:
      return 'EMAIL';
    case LoginUser_Method.GOOGLE:
      return 'GOOGLE';
    case LoginUser_Method.FACEBOOK:
      return 'FACEBOOK';
    case LoginUser_Method.APPLE:
      return 'APPLE';
    default:
      return 'UNKNOWN';
  }
}

export interface LogoutUser {}

export interface ClickInstantBuy {
  offerId: string;
  cardSlug: string;
  ethAmount?: number | undefined;
  eurAmount: number;
  gameSlug?: string | undefined;
  origin: ClickInstantBuy_Flow;
  sport: Sport;
  scarcity: string;
  composeSessionId: string;
}

export enum ClickInstantBuy_Flow {
  PRE_MATCH = 0,
  LINEUP_UPGRADE = 1,
  MAN_MATCH = 2,
  MATCH_VIEW = 3,
  MARKETPLACE = 4,
  CUSTOM_LEAGUE_ONBOARDING = 5,
  COMPOSE_BUY = 6,
  FOR_YOU = 7,
  UNRECOGNIZED = -1,
}

export function clickInstantBuy_FlowFromJSON(
  object: any
): ClickInstantBuy_Flow {
  switch (object) {
    case 0:
    case 'PRE_MATCH':
      return ClickInstantBuy_Flow.PRE_MATCH;
    case 1:
    case 'LINEUP_UPGRADE':
      return ClickInstantBuy_Flow.LINEUP_UPGRADE;
    case 2:
    case 'MAN_MATCH':
      return ClickInstantBuy_Flow.MAN_MATCH;
    case 3:
    case 'MATCH_VIEW':
      return ClickInstantBuy_Flow.MATCH_VIEW;
    case 4:
    case 'MARKETPLACE':
      return ClickInstantBuy_Flow.MARKETPLACE;
    case 5:
    case 'CUSTOM_LEAGUE_ONBOARDING':
      return ClickInstantBuy_Flow.CUSTOM_LEAGUE_ONBOARDING;
    case 6:
    case 'COMPOSE_BUY':
      return ClickInstantBuy_Flow.COMPOSE_BUY;
    case 7:
    case 'FOR_YOU':
      return ClickInstantBuy_Flow.FOR_YOU;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ClickInstantBuy_Flow.UNRECOGNIZED;
  }
}

export function clickInstantBuy_FlowToJSON(
  object: ClickInstantBuy_Flow
): string {
  switch (object) {
    case ClickInstantBuy_Flow.PRE_MATCH:
      return 'PRE_MATCH';
    case ClickInstantBuy_Flow.LINEUP_UPGRADE:
      return 'LINEUP_UPGRADE';
    case ClickInstantBuy_Flow.MAN_MATCH:
      return 'MAN_MATCH';
    case ClickInstantBuy_Flow.MATCH_VIEW:
      return 'MATCH_VIEW';
    case ClickInstantBuy_Flow.MARKETPLACE:
      return 'MARKETPLACE';
    case ClickInstantBuy_Flow.CUSTOM_LEAGUE_ONBOARDING:
      return 'CUSTOM_LEAGUE_ONBOARDING';
    case ClickInstantBuy_Flow.COMPOSE_BUY:
      return 'COMPOSE_BUY';
    case ClickInstantBuy_Flow.FOR_YOU:
      return 'FOR_YOU';
    default:
      return 'UNKNOWN';
  }
}

export interface ClickBuyInAppPurchase {
  offerId: string;
  cardSlug: string;
  ethAmount?: number | undefined;
  eurAmount: number;
  gameSlug?: string | undefined;
  sport: Sport;
}

export interface ClickPreMatchUpsell {
  gameSlug: string;
  cardSlugs: string[];
  sport: Sport;
}

export interface ClickAfterPurchase {
  offerId: string;
  cardSlug: string;
  gameSlug?: string | undefined;
  sport: Sport;
  action: ClickAfterPurchase_Action;
}

export enum ClickAfterPurchase_Action {
  BUY_MORE = 0,
  SET_MY_LINEUP = 1,
  SEE_MY_CARDS = 2,
  BACK_TO_LIVE_FEED = 3,
  CONTINUE = 4,
  PLAY_PRO = 5,
  UNRECOGNIZED = -1,
}

export function clickAfterPurchase_ActionFromJSON(
  object: any
): ClickAfterPurchase_Action {
  switch (object) {
    case 0:
    case 'BUY_MORE':
      return ClickAfterPurchase_Action.BUY_MORE;
    case 1:
    case 'SET_MY_LINEUP':
      return ClickAfterPurchase_Action.SET_MY_LINEUP;
    case 2:
    case 'SEE_MY_CARDS':
      return ClickAfterPurchase_Action.SEE_MY_CARDS;
    case 3:
    case 'BACK_TO_LIVE_FEED':
      return ClickAfterPurchase_Action.BACK_TO_LIVE_FEED;
    case 4:
    case 'CONTINUE':
      return ClickAfterPurchase_Action.CONTINUE;
    case 5:
    case 'PLAY_PRO':
      return ClickAfterPurchase_Action.PLAY_PRO;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ClickAfterPurchase_Action.UNRECOGNIZED;
  }
}

export function clickAfterPurchase_ActionToJSON(
  object: ClickAfterPurchase_Action
): string {
  switch (object) {
    case ClickAfterPurchase_Action.BUY_MORE:
      return 'BUY_MORE';
    case ClickAfterPurchase_Action.SET_MY_LINEUP:
      return 'SET_MY_LINEUP';
    case ClickAfterPurchase_Action.SEE_MY_CARDS:
      return 'SEE_MY_CARDS';
    case ClickAfterPurchase_Action.BACK_TO_LIVE_FEED:
      return 'BACK_TO_LIVE_FEED';
    case ClickAfterPurchase_Action.CONTINUE:
      return 'CONTINUE';
    case ClickAfterPurchase_Action.PLAY_PRO:
      return 'PLAY_PRO';
    default:
      return 'UNKNOWN';
  }
}

export interface ClickAfterLineupUpgradePurchase {
  gameSlug: string;
  lineupId: string;
  action: ClickAfterLineupUpgradePurchase_Action;
  sport: Sport;
}

export enum ClickAfterLineupUpgradePurchase_Action {
  BUY_MORE = 0,
  CONFIRM_LINEUP = 1,
  UNRECOGNIZED = -1,
}

export function clickAfterLineupUpgradePurchase_ActionFromJSON(
  object: any
): ClickAfterLineupUpgradePurchase_Action {
  switch (object) {
    case 0:
    case 'BUY_MORE':
      return ClickAfterLineupUpgradePurchase_Action.BUY_MORE;
    case 1:
    case 'CONFIRM_LINEUP':
      return ClickAfterLineupUpgradePurchase_Action.CONFIRM_LINEUP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ClickAfterLineupUpgradePurchase_Action.UNRECOGNIZED;
  }
}

export function clickAfterLineupUpgradePurchase_ActionToJSON(
  object: ClickAfterLineupUpgradePurchase_Action
): string {
  switch (object) {
    case ClickAfterLineupUpgradePurchase_Action.BUY_MORE:
      return 'BUY_MORE';
    case ClickAfterLineupUpgradePurchase_Action.CONFIRM_LINEUP:
      return 'CONFIRM_LINEUP';
    default:
      return 'UNKNOWN';
  }
}

export interface ClickContinueWithoutUpgrade {
  gameSlug: string;
  lineupId: string;
  sport: Sport;
}

export interface DisplayUpgradeLineup {
  gameSlug: string;
  lineupId: string;
  sport: Sport;
}

export interface ClickBoostRivalsLineup {
  interactionContext: string;
  gameSlug: string;
  lineupId: string;
  sport: Sport;
}

export interface OpenRewardBox {
  sport: Sport;
  rewardBoxId: string;
}

export interface ViewRivalsLeaguesTutorial {
  step: number;
  source: ViewRivalsLeaguesTutorial_Source;
}

export enum ViewRivalsLeaguesTutorial_Source {
  ACTIVITY_FEED = 0,
  CREATION_FLOW = 1,
  UNRECOGNIZED = -1,
}

export function viewRivalsLeaguesTutorial_SourceFromJSON(
  object: any
): ViewRivalsLeaguesTutorial_Source {
  switch (object) {
    case 0:
    case 'ACTIVITY_FEED':
      return ViewRivalsLeaguesTutorial_Source.ACTIVITY_FEED;
    case 1:
    case 'CREATION_FLOW':
      return ViewRivalsLeaguesTutorial_Source.CREATION_FLOW;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ViewRivalsLeaguesTutorial_Source.UNRECOGNIZED;
  }
}

export function viewRivalsLeaguesTutorial_SourceToJSON(
  object: ViewRivalsLeaguesTutorial_Source
): string {
  switch (object) {
    case ViewRivalsLeaguesTutorial_Source.ACTIVITY_FEED:
      return 'ACTIVITY_FEED';
    case ViewRivalsLeaguesTutorial_Source.CREATION_FLOW:
      return 'CREATION_FLOW';
    default:
      return 'UNKNOWN';
  }
}

export interface RivalsLeagueCreated {
  slug: string;
}

export interface RivalsLeagueJoined {
  leagueSlug: string;
  userSlug: string;
  affiliate: boolean;
}

export interface ViewRivalsLeaguesGlobalLeaderboard {
  source: ViewRivalsLeaguesGlobalLeaderboard_Source;
}

export enum ViewRivalsLeaguesGlobalLeaderboard_Source {
  LEAGUES_LIST = 0,
  LEAGUE = 1,
  UNRECOGNIZED = -1,
}

export function viewRivalsLeaguesGlobalLeaderboard_SourceFromJSON(
  object: any
): ViewRivalsLeaguesGlobalLeaderboard_Source {
  switch (object) {
    case 0:
    case 'LEAGUES_LIST':
      return ViewRivalsLeaguesGlobalLeaderboard_Source.LEAGUES_LIST;
    case 1:
    case 'LEAGUE':
      return ViewRivalsLeaguesGlobalLeaderboard_Source.LEAGUE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ViewRivalsLeaguesGlobalLeaderboard_Source.UNRECOGNIZED;
  }
}

export function viewRivalsLeaguesGlobalLeaderboard_SourceToJSON(
  object: ViewRivalsLeaguesGlobalLeaderboard_Source
): string {
  switch (object) {
    case ViewRivalsLeaguesGlobalLeaderboard_Source.LEAGUES_LIST:
      return 'LEAGUES_LIST';
    case ViewRivalsLeaguesGlobalLeaderboard_Source.LEAGUE:
      return 'LEAGUE';
    default:
      return 'UNKNOWN';
  }
}

export interface SubmitJoinRivalsLeague {
  leagueSlug: string;
}

export interface ViewRivalsLeagueArenaBridgePopup {
  source: ViewRivalsLeagueArenaBridgePopup_Source;
}

export enum ViewRivalsLeagueArenaBridgePopup_Source {
  HEAD_TO_HEAD = 0,
  REWARD = 1,
  PROMOTION = 2,
  UNRECOGNIZED = -1,
}

export function viewRivalsLeagueArenaBridgePopup_SourceFromJSON(
  object: any
): ViewRivalsLeagueArenaBridgePopup_Source {
  switch (object) {
    case 0:
    case 'HEAD_TO_HEAD':
      return ViewRivalsLeagueArenaBridgePopup_Source.HEAD_TO_HEAD;
    case 1:
    case 'REWARD':
      return ViewRivalsLeagueArenaBridgePopup_Source.REWARD;
    case 2:
    case 'PROMOTION':
      return ViewRivalsLeagueArenaBridgePopup_Source.PROMOTION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ViewRivalsLeagueArenaBridgePopup_Source.UNRECOGNIZED;
  }
}

export function viewRivalsLeagueArenaBridgePopup_SourceToJSON(
  object: ViewRivalsLeagueArenaBridgePopup_Source
): string {
  switch (object) {
    case ViewRivalsLeagueArenaBridgePopup_Source.HEAD_TO_HEAD:
      return 'HEAD_TO_HEAD';
    case ViewRivalsLeagueArenaBridgePopup_Source.REWARD:
      return 'REWARD';
    case ViewRivalsLeagueArenaBridgePopup_Source.PROMOTION:
      return 'PROMOTION';
    default:
      return 'UNKNOWN';
  }
}

export interface EnterRivalsLeagueArenaBridge {
  source: EnterRivalsLeagueArenaBridge_Source;
}

export enum EnterRivalsLeagueArenaBridge_Source {
  HEAD_TO_HEAD = 0,
  REWARD = 1,
  PROMOTION = 2,
  UNRECOGNIZED = -1,
}

export function enterRivalsLeagueArenaBridge_SourceFromJSON(
  object: any
): EnterRivalsLeagueArenaBridge_Source {
  switch (object) {
    case 0:
    case 'HEAD_TO_HEAD':
      return EnterRivalsLeagueArenaBridge_Source.HEAD_TO_HEAD;
    case 1:
    case 'REWARD':
      return EnterRivalsLeagueArenaBridge_Source.REWARD;
    case 2:
    case 'PROMOTION':
      return EnterRivalsLeagueArenaBridge_Source.PROMOTION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EnterRivalsLeagueArenaBridge_Source.UNRECOGNIZED;
  }
}

export function enterRivalsLeagueArenaBridge_SourceToJSON(
  object: EnterRivalsLeagueArenaBridge_Source
): string {
  switch (object) {
    case EnterRivalsLeagueArenaBridge_Source.HEAD_TO_HEAD:
      return 'HEAD_TO_HEAD';
    case EnterRivalsLeagueArenaBridge_Source.REWARD:
      return 'REWARD';
    case EnterRivalsLeagueArenaBridge_Source.PROMOTION:
      return 'PROMOTION';
    default:
      return 'UNKNOWN';
  }
}

export interface EnterRivalsBridgeBanner {
  source: EnterRivalsBridgeBanner_Source;
}

export enum EnterRivalsBridgeBanner_Source {
  HEAD_TO_HEAD = 0,
  REWARD = 1,
  PROMOTION = 2,
  UNRECOGNIZED = -1,
}

export function enterRivalsBridgeBanner_SourceFromJSON(
  object: any
): EnterRivalsBridgeBanner_Source {
  switch (object) {
    case 0:
    case 'HEAD_TO_HEAD':
      return EnterRivalsBridgeBanner_Source.HEAD_TO_HEAD;
    case 1:
    case 'REWARD':
      return EnterRivalsBridgeBanner_Source.REWARD;
    case 2:
    case 'PROMOTION':
      return EnterRivalsBridgeBanner_Source.PROMOTION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EnterRivalsBridgeBanner_Source.UNRECOGNIZED;
  }
}

export function enterRivalsBridgeBanner_SourceToJSON(
  object: EnterRivalsBridgeBanner_Source
): string {
  switch (object) {
    case EnterRivalsBridgeBanner_Source.HEAD_TO_HEAD:
      return 'HEAD_TO_HEAD';
    case EnterRivalsBridgeBanner_Source.REWARD:
      return 'REWARD';
    case EnterRivalsBridgeBanner_Source.PROMOTION:
      return 'PROMOTION';
    default:
      return 'UNKNOWN';
  }
}

export interface ViewComposeTeam {
  interactionContext: string;
  sport: Sport;
  so5LeaderboardSlug: string;
  so5LeaderboardRarity: string;
  so5LeaderboardSeasonality: string;
  so5LeagueName: string;
  so5FixtureSlug: string;
  composeSessionId: string;
}

export interface OpenPlayerDetails {
  interactionContext: string;
  sport: Sport;
  so5LeaderboardSlug: string;
  so5LeaderboardRarity: string;
  so5LeaderboardSeasonality: string;
  so5LeagueName: string;
  so5FixtureSlug: string;
  playerSlug: string;
  cardSlug: string;
  composeSessionId: string;
}

export interface SelectCard {
  benchObjectId: string;
  so5LeaderboardSlug: string;
  position: string;
  composeSessionId: string;
  interactionContext: string;
  sport: Sport;
}

export interface ClickComposeClose {
  so5LeaderboardSlug: string;
  composeSessionId: string;
  interactionContext: string;
  sport: Sport;
}

function createBaseLoginUser(): LoginUser {
  return { method: 0, failed: false };
}

export const LoginUser = {
  fromJSON(object: any): LoginUser {
    return {
      method: isSet(object.method)
        ? loginUser_MethodFromJSON(object.method)
        : 0,
      failed: isSet(object.failed) ? Boolean(object.failed) : false,
    };
  },

  toJSON(message: LoginUser): unknown {
    const obj: any = {};
    message.method !== undefined &&
      (obj.method = loginUser_MethodToJSON(message.method));
    message.failed !== undefined && (obj.failed = message.failed);
    return obj;
  },
};

function createBaseLogoutUser(): LogoutUser {
  return {};
}

export const LogoutUser = {
  fromJSON(_: any): LogoutUser {
    return {};
  },

  toJSON(_: LogoutUser): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickInstantBuy(): ClickInstantBuy {
  return {
    offerId: '',
    cardSlug: '',
    ethAmount: undefined,
    eurAmount: 0,
    gameSlug: undefined,
    origin: 0,
    sport: 0,
    scarcity: '',
    composeSessionId: '',
  };
}

export const ClickInstantBuy = {
  fromJSON(object: any): ClickInstantBuy {
    return {
      offerId: isSet(object.offer_id) ? String(object.offer_id) : '',
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      ethAmount: isSet(object.eth_amount)
        ? Number(object.eth_amount)
        : undefined,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      gameSlug: isSet(object.game_slug) ? String(object.game_slug) : undefined,
      origin: isSet(object.origin)
        ? clickInstantBuy_FlowFromJSON(object.origin)
        : 0,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      composeSessionId: isSet(object.compose_session_id)
        ? String(object.compose_session_id)
        : '',
    };
  },

  toJSON(message: ClickInstantBuy): unknown {
    const obj: any = {};
    message.offerId !== undefined && (obj.offer_id = message.offerId);
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.ethAmount !== undefined &&
      (obj.eth_amount = Math.round(message.ethAmount));
    message.eurAmount !== undefined &&
      (obj.eur_amount = Math.round(message.eurAmount));
    message.gameSlug !== undefined && (obj.game_slug = message.gameSlug);
    message.origin !== undefined &&
      (obj.origin = clickInstantBuy_FlowToJSON(message.origin));
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.composeSessionId !== undefined &&
      (obj.compose_session_id = message.composeSessionId);
    return obj;
  },
};

function createBaseClickBuyInAppPurchase(): ClickBuyInAppPurchase {
  return {
    offerId: '',
    cardSlug: '',
    ethAmount: undefined,
    eurAmount: 0,
    gameSlug: undefined,
    sport: 0,
  };
}

export const ClickBuyInAppPurchase = {
  fromJSON(object: any): ClickBuyInAppPurchase {
    return {
      offerId: isSet(object.offer_id) ? String(object.offer_id) : '',
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      ethAmount: isSet(object.eth_amount)
        ? Number(object.eth_amount)
        : undefined,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      gameSlug: isSet(object.game_slug) ? String(object.game_slug) : undefined,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickBuyInAppPurchase): unknown {
    const obj: any = {};
    message.offerId !== undefined && (obj.offer_id = message.offerId);
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.ethAmount !== undefined &&
      (obj.eth_amount = Math.round(message.ethAmount));
    message.eurAmount !== undefined &&
      (obj.eur_amount = Math.round(message.eurAmount));
    message.gameSlug !== undefined && (obj.game_slug = message.gameSlug);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickPreMatchUpsell(): ClickPreMatchUpsell {
  return { gameSlug: '', cardSlugs: [], sport: 0 };
}

export const ClickPreMatchUpsell = {
  fromJSON(object: any): ClickPreMatchUpsell {
    return {
      gameSlug: isSet(object.game_slug) ? String(object.game_slug) : '',
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickPreMatchUpsell): unknown {
    const obj: any = {};
    message.gameSlug !== undefined && (obj.game_slug = message.gameSlug);
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickAfterPurchase(): ClickAfterPurchase {
  return {
    offerId: '',
    cardSlug: '',
    gameSlug: undefined,
    sport: 0,
    action: 0,
  };
}

export const ClickAfterPurchase = {
  fromJSON(object: any): ClickAfterPurchase {
    return {
      offerId: isSet(object.offer_id) ? String(object.offer_id) : '',
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      gameSlug: isSet(object.game_slug) ? String(object.game_slug) : undefined,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      action: isSet(object.action)
        ? clickAfterPurchase_ActionFromJSON(object.action)
        : 0,
    };
  },

  toJSON(message: ClickAfterPurchase): unknown {
    const obj: any = {};
    message.offerId !== undefined && (obj.offer_id = message.offerId);
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.gameSlug !== undefined && (obj.game_slug = message.gameSlug);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.action !== undefined &&
      (obj.action = clickAfterPurchase_ActionToJSON(message.action));
    return obj;
  },
};

function createBaseClickAfterLineupUpgradePurchase(): ClickAfterLineupUpgradePurchase {
  return { gameSlug: '', lineupId: '', action: 0, sport: 0 };
}

export const ClickAfterLineupUpgradePurchase = {
  fromJSON(object: any): ClickAfterLineupUpgradePurchase {
    return {
      gameSlug: isSet(object.game_slug) ? String(object.game_slug) : '',
      lineupId: isSet(object.lineup_id) ? String(object.lineup_id) : '',
      action: isSet(object.action)
        ? clickAfterLineupUpgradePurchase_ActionFromJSON(object.action)
        : 0,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickAfterLineupUpgradePurchase): unknown {
    const obj: any = {};
    message.gameSlug !== undefined && (obj.game_slug = message.gameSlug);
    message.lineupId !== undefined && (obj.lineup_id = message.lineupId);
    message.action !== undefined &&
      (obj.action = clickAfterLineupUpgradePurchase_ActionToJSON(
        message.action
      ));
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickContinueWithoutUpgrade(): ClickContinueWithoutUpgrade {
  return { gameSlug: '', lineupId: '', sport: 0 };
}

export const ClickContinueWithoutUpgrade = {
  fromJSON(object: any): ClickContinueWithoutUpgrade {
    return {
      gameSlug: isSet(object.game_slug) ? String(object.game_slug) : '',
      lineupId: isSet(object.lineup_id) ? String(object.lineup_id) : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickContinueWithoutUpgrade): unknown {
    const obj: any = {};
    message.gameSlug !== undefined && (obj.game_slug = message.gameSlug);
    message.lineupId !== undefined && (obj.lineup_id = message.lineupId);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseDisplayUpgradeLineup(): DisplayUpgradeLineup {
  return { gameSlug: '', lineupId: '', sport: 0 };
}

export const DisplayUpgradeLineup = {
  fromJSON(object: any): DisplayUpgradeLineup {
    return {
      gameSlug: isSet(object.game_slug) ? String(object.game_slug) : '',
      lineupId: isSet(object.lineup_id) ? String(object.lineup_id) : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: DisplayUpgradeLineup): unknown {
    const obj: any = {};
    message.gameSlug !== undefined && (obj.game_slug = message.gameSlug);
    message.lineupId !== undefined && (obj.lineup_id = message.lineupId);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickBoostRivalsLineup(): ClickBoostRivalsLineup {
  return { interactionContext: '', gameSlug: '', lineupId: '', sport: 0 };
}

export const ClickBoostRivalsLineup = {
  fromJSON(object: any): ClickBoostRivalsLineup {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      gameSlug: isSet(object.game_slug) ? String(object.game_slug) : '',
      lineupId: isSet(object.lineup_id) ? String(object.lineup_id) : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickBoostRivalsLineup): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.gameSlug !== undefined && (obj.game_slug = message.gameSlug);
    message.lineupId !== undefined && (obj.lineup_id = message.lineupId);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseOpenRewardBox(): OpenRewardBox {
  return { sport: 0, rewardBoxId: '' };
}

export const OpenRewardBox = {
  fromJSON(object: any): OpenRewardBox {
    return {
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      rewardBoxId: isSet(object.reward_box_id)
        ? String(object.reward_box_id)
        : '',
    };
  },

  toJSON(message: OpenRewardBox): unknown {
    const obj: any = {};
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.rewardBoxId !== undefined &&
      (obj.reward_box_id = message.rewardBoxId);
    return obj;
  },
};

function createBaseViewRivalsLeaguesTutorial(): ViewRivalsLeaguesTutorial {
  return { step: 0, source: 0 };
}

export const ViewRivalsLeaguesTutorial = {
  fromJSON(object: any): ViewRivalsLeaguesTutorial {
    return {
      step: isSet(object.step) ? Number(object.step) : 0,
      source: isSet(object.source)
        ? viewRivalsLeaguesTutorial_SourceFromJSON(object.source)
        : 0,
    };
  },

  toJSON(message: ViewRivalsLeaguesTutorial): unknown {
    const obj: any = {};
    message.step !== undefined && (obj.step = Math.round(message.step));
    message.source !== undefined &&
      (obj.source = viewRivalsLeaguesTutorial_SourceToJSON(message.source));
    return obj;
  },
};

function createBaseRivalsLeagueCreated(): RivalsLeagueCreated {
  return { slug: '' };
}

export const RivalsLeagueCreated = {
  fromJSON(object: any): RivalsLeagueCreated {
    return {
      slug: isSet(object.slug) ? String(object.slug) : '',
    };
  },

  toJSON(message: RivalsLeagueCreated): unknown {
    const obj: any = {};
    message.slug !== undefined && (obj.slug = message.slug);
    return obj;
  },
};

function createBaseRivalsLeagueJoined(): RivalsLeagueJoined {
  return { leagueSlug: '', userSlug: '', affiliate: false };
}

export const RivalsLeagueJoined = {
  fromJSON(object: any): RivalsLeagueJoined {
    return {
      leagueSlug: isSet(object.league_slug) ? String(object.league_slug) : '',
      userSlug: isSet(object.user_slug) ? String(object.user_slug) : '',
      affiliate: isSet(object.affiliate) ? Boolean(object.affiliate) : false,
    };
  },

  toJSON(message: RivalsLeagueJoined): unknown {
    const obj: any = {};
    message.leagueSlug !== undefined && (obj.league_slug = message.leagueSlug);
    message.userSlug !== undefined && (obj.user_slug = message.userSlug);
    message.affiliate !== undefined && (obj.affiliate = message.affiliate);
    return obj;
  },
};

function createBaseViewRivalsLeaguesGlobalLeaderboard(): ViewRivalsLeaguesGlobalLeaderboard {
  return { source: 0 };
}

export const ViewRivalsLeaguesGlobalLeaderboard = {
  fromJSON(object: any): ViewRivalsLeaguesGlobalLeaderboard {
    return {
      source: isSet(object.source)
        ? viewRivalsLeaguesGlobalLeaderboard_SourceFromJSON(object.source)
        : 0,
    };
  },

  toJSON(message: ViewRivalsLeaguesGlobalLeaderboard): unknown {
    const obj: any = {};
    message.source !== undefined &&
      (obj.source = viewRivalsLeaguesGlobalLeaderboard_SourceToJSON(
        message.source
      ));
    return obj;
  },
};

function createBaseSubmitJoinRivalsLeague(): SubmitJoinRivalsLeague {
  return { leagueSlug: '' };
}

export const SubmitJoinRivalsLeague = {
  fromJSON(object: any): SubmitJoinRivalsLeague {
    return {
      leagueSlug: isSet(object.league_slug) ? String(object.league_slug) : '',
    };
  },

  toJSON(message: SubmitJoinRivalsLeague): unknown {
    const obj: any = {};
    message.leagueSlug !== undefined && (obj.league_slug = message.leagueSlug);
    return obj;
  },
};

function createBaseViewRivalsLeagueArenaBridgePopup(): ViewRivalsLeagueArenaBridgePopup {
  return { source: 0 };
}

export const ViewRivalsLeagueArenaBridgePopup = {
  fromJSON(object: any): ViewRivalsLeagueArenaBridgePopup {
    return {
      source: isSet(object.source)
        ? viewRivalsLeagueArenaBridgePopup_SourceFromJSON(object.source)
        : 0,
    };
  },

  toJSON(message: ViewRivalsLeagueArenaBridgePopup): unknown {
    const obj: any = {};
    message.source !== undefined &&
      (obj.source = viewRivalsLeagueArenaBridgePopup_SourceToJSON(
        message.source
      ));
    return obj;
  },
};

function createBaseEnterRivalsLeagueArenaBridge(): EnterRivalsLeagueArenaBridge {
  return { source: 0 };
}

export const EnterRivalsLeagueArenaBridge = {
  fromJSON(object: any): EnterRivalsLeagueArenaBridge {
    return {
      source: isSet(object.source)
        ? enterRivalsLeagueArenaBridge_SourceFromJSON(object.source)
        : 0,
    };
  },

  toJSON(message: EnterRivalsLeagueArenaBridge): unknown {
    const obj: any = {};
    message.source !== undefined &&
      (obj.source = enterRivalsLeagueArenaBridge_SourceToJSON(message.source));
    return obj;
  },
};

function createBaseEnterRivalsBridgeBanner(): EnterRivalsBridgeBanner {
  return { source: 0 };
}

export const EnterRivalsBridgeBanner = {
  fromJSON(object: any): EnterRivalsBridgeBanner {
    return {
      source: isSet(object.source)
        ? enterRivalsBridgeBanner_SourceFromJSON(object.source)
        : 0,
    };
  },

  toJSON(message: EnterRivalsBridgeBanner): unknown {
    const obj: any = {};
    message.source !== undefined &&
      (obj.source = enterRivalsBridgeBanner_SourceToJSON(message.source));
    return obj;
  },
};

function createBaseViewComposeTeam(): ViewComposeTeam {
  return {
    interactionContext: '',
    sport: 0,
    so5LeaderboardSlug: '',
    so5LeaderboardRarity: '',
    so5LeaderboardSeasonality: '',
    so5LeagueName: '',
    so5FixtureSlug: '',
    composeSessionId: '',
  };
}

export const ViewComposeTeam = {
  fromJSON(object: any): ViewComposeTeam {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      so5LeaderboardSlug: isSet(object.so5_leaderboard_slug)
        ? String(object.so5_leaderboard_slug)
        : '',
      so5LeaderboardRarity: isSet(object.so5_leaderboard_rarity)
        ? String(object.so5_leaderboard_rarity)
        : '',
      so5LeaderboardSeasonality: isSet(object.so5_leaderboard_seasonality)
        ? String(object.so5_leaderboard_seasonality)
        : '',
      so5LeagueName: isSet(object.so5_league_name)
        ? String(object.so5_league_name)
        : '',
      so5FixtureSlug: isSet(object.so5_fixture_slug)
        ? String(object.so5_fixture_slug)
        : '',
      composeSessionId: isSet(object.compose_session_id)
        ? String(object.compose_session_id)
        : '',
    };
  },

  toJSON(message: ViewComposeTeam): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.so5LeaderboardSlug !== undefined &&
      (obj.so5_leaderboard_slug = message.so5LeaderboardSlug);
    message.so5LeaderboardRarity !== undefined &&
      (obj.so5_leaderboard_rarity = message.so5LeaderboardRarity);
    message.so5LeaderboardSeasonality !== undefined &&
      (obj.so5_leaderboard_seasonality = message.so5LeaderboardSeasonality);
    message.so5LeagueName !== undefined &&
      (obj.so5_league_name = message.so5LeagueName);
    message.so5FixtureSlug !== undefined &&
      (obj.so5_fixture_slug = message.so5FixtureSlug);
    message.composeSessionId !== undefined &&
      (obj.compose_session_id = message.composeSessionId);
    return obj;
  },
};

function createBaseOpenPlayerDetails(): OpenPlayerDetails {
  return {
    interactionContext: '',
    sport: 0,
    so5LeaderboardSlug: '',
    so5LeaderboardRarity: '',
    so5LeaderboardSeasonality: '',
    so5LeagueName: '',
    so5FixtureSlug: '',
    playerSlug: '',
    cardSlug: '',
    composeSessionId: '',
  };
}

export const OpenPlayerDetails = {
  fromJSON(object: any): OpenPlayerDetails {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      so5LeaderboardSlug: isSet(object.so5_leaderboard_slug)
        ? String(object.so5_leaderboard_slug)
        : '',
      so5LeaderboardRarity: isSet(object.so5_leaderboard_rarity)
        ? String(object.so5_leaderboard_rarity)
        : '',
      so5LeaderboardSeasonality: isSet(object.so5_leaderboard_seasonality)
        ? String(object.so5_leaderboard_seasonality)
        : '',
      so5LeagueName: isSet(object.so5_league_name)
        ? String(object.so5_league_name)
        : '',
      so5FixtureSlug: isSet(object.so5_fixture_slug)
        ? String(object.so5_fixture_slug)
        : '',
      playerSlug: isSet(object.player_slug) ? String(object.player_slug) : '',
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      composeSessionId: isSet(object.compose_session_id)
        ? String(object.compose_session_id)
        : '',
    };
  },

  toJSON(message: OpenPlayerDetails): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.so5LeaderboardSlug !== undefined &&
      (obj.so5_leaderboard_slug = message.so5LeaderboardSlug);
    message.so5LeaderboardRarity !== undefined &&
      (obj.so5_leaderboard_rarity = message.so5LeaderboardRarity);
    message.so5LeaderboardSeasonality !== undefined &&
      (obj.so5_leaderboard_seasonality = message.so5LeaderboardSeasonality);
    message.so5LeagueName !== undefined &&
      (obj.so5_league_name = message.so5LeagueName);
    message.so5FixtureSlug !== undefined &&
      (obj.so5_fixture_slug = message.so5FixtureSlug);
    message.playerSlug !== undefined && (obj.player_slug = message.playerSlug);
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.composeSessionId !== undefined &&
      (obj.compose_session_id = message.composeSessionId);
    return obj;
  },
};

function createBaseSelectCard(): SelectCard {
  return {
    benchObjectId: '',
    so5LeaderboardSlug: '',
    position: '',
    composeSessionId: '',
    interactionContext: '',
    sport: 0,
  };
}

export const SelectCard = {
  fromJSON(object: any): SelectCard {
    return {
      benchObjectId: isSet(object.bench_object_id)
        ? String(object.bench_object_id)
        : '',
      so5LeaderboardSlug: isSet(object.so5_leaderboard_slug)
        ? String(object.so5_leaderboard_slug)
        : '',
      position: isSet(object.position) ? String(object.position) : '',
      composeSessionId: isSet(object.compose_session_id)
        ? String(object.compose_session_id)
        : '',
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: SelectCard): unknown {
    const obj: any = {};
    message.benchObjectId !== undefined &&
      (obj.bench_object_id = message.benchObjectId);
    message.so5LeaderboardSlug !== undefined &&
      (obj.so5_leaderboard_slug = message.so5LeaderboardSlug);
    message.position !== undefined && (obj.position = message.position);
    message.composeSessionId !== undefined &&
      (obj.compose_session_id = message.composeSessionId);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickComposeClose(): ClickComposeClose {
  return {
    so5LeaderboardSlug: '',
    composeSessionId: '',
    interactionContext: '',
    sport: 0,
  };
}

export const ClickComposeClose = {
  fromJSON(object: any): ClickComposeClose {
    return {
      so5LeaderboardSlug: isSet(object.so5_leaderboard_slug)
        ? String(object.so5_leaderboard_slug)
        : '',
      composeSessionId: isSet(object.compose_session_id)
        ? String(object.compose_session_id)
        : '',
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickComposeClose): unknown {
    const obj: any = {};
    message.so5LeaderboardSlug !== undefined &&
      (obj.so5_leaderboard_slug = message.so5LeaderboardSlug);
    message.composeSessionId !== undefined &&
      (obj.compose_session_id = message.composeSessionId);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
