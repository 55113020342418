import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport, Tradeable } from '@sorare/core/src/__generated__/globalTypes';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import { cardTypes } from '@sorare/core/src/lib/cards';

import {
  SetTokenTradeStatus,
  SetTokenTradeStatusVariables,
} from './__generated__/useToggleAvailableForTrade.graphql';

const SET_TOKEN_TRADE_STATUS = gql`
  mutation SetTokenTradeStatus($assetId: String!, $tradeable: Boolean!) {
    setTokenTradeStatus(input: { assetId: $assetId, tradeable: $tradeable }) {
      card {
        slug
        tradeableStatus
      }
      errors {
        message
      }
    }
  }
` as TypedDocumentNode<SetTokenTradeStatus, SetTokenTradeStatusVariables>;

export const useToggleAvailableForTrade = (sport: Sport) => {
  const [setTokenTradeStatus] = useMutation<
    SetTokenTradeStatus,
    SetTokenTradeStatusVariables
  >(SET_TOKEN_TRADE_STATUS);

  const toggleAvailableForTrade = async (
    assetId: string,
    slug: string,
    tradeable: boolean
  ) => {
    await setTokenTradeStatus({
      variables: { assetId, tradeable },
      optimisticResponse: {
        setTokenTradeStatus: {
          __typename: 'setTokenTradeStatusPayload',
          card: {
            __typename: cardTypes[sport],
            slug,
            tradeableStatus: tradeable ? Tradeable.YES : Tradeable.NO,
          },
          errors: [],
        },
      },
    });
  };

  return { toggleAvailableForTrade };
};
