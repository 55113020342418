import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const Bell = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 0C5.23858 0 3 2.23858 3 5V7L1 9V11H15V9L13 7V5C13 2.23858 10.7614 0 8 0Z"
      fill="currentColor"
    />
    <path
      d="M5 13C5 14.6569 6.34315 16 8 16C9.65685 16 11 14.6569 11 13H5Z"
      fill="currentColor"
    />
  </svg>
);
