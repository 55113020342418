import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { SBHorizontal } from '@sorare/core/src/atoms/layout/flex';
import {
  LinkBox,
  LinkOther,
  LinkOverlay,
} from '@sorare/core/src/atoms/navigation/Box';
import { Text14, Text16 } from '@sorare/core/src/atoms/typography';
import { OpenItemDialogLink } from '@sorare/core/src/components/link/OpenItemDialogLink';

import PriceHistoryTooltip from 'components/price/PriceHistoryTooltip';
import CardDescription from 'components/token/CardDescription';
import FlexCard from 'components/token/FlexCard';

import { CardOffer_anyCard } from './__generated__/index.graphql';

type Props = {
  item: CardOffer_anyCard;
  children?: ReactNode;
};

const Container = styled.div`
  padding: var(--double-unit);
  background: var(--c-neutral-300);
  border-radius: var(--unit);
  border: 1px solid var(--c-neutral-400);
  transition: 0.1s ease-in-out all;
  .inModale & {
    background: var(--c-neutral-400);
  }
  &:hover {
    background: var(--c-neutral-400);
  }
`;

const CardInfo = styled.div`
  display: flex;
`;

const Card = styled.div`
  width: 30px;
`;

const Description = styled(SBHorizontal).attrs({ gap: 0 })`
  margin-left: var(--unit);
  flex-grow: 1;
`;

const CardOffer = ({ item, children }: Props) => {
  return (
    <LinkBox as={Container}>
      <CardInfo>
        <Card>
          <LinkOverlay as={OpenItemDialogLink} item={item} sport={item.sport}>
            <FlexCard width={80} card={item} />
          </LinkOverlay>
        </Card>

        <Description>
          <CardDescription
            Title={Text16}
            Details={Text14}
            detailsColor="var(--c-neutral-600)"
            card={item}
            withoutLink
          />
          {item.rarityTyped !== Rarity.unique && (
            <LinkOther as="span">
              <PriceHistoryTooltip card={item} context="My sorare" />
            </LinkOther>
          )}
        </Description>
      </CardInfo>
      {children}
    </LinkBox>
  );
};

CardOffer.fragments = {
  anyCard: gql`
    fragment CardOffer_anyCard on AnyCardInterface {
      slug
      sport
      ...FlexCard_anyCard
      ...PriceHistoryTooltip_anyCard
      ...CardDescription_anyCard
    }
    ${FlexCard.fragments.anyCard}
    ${PriceHistoryTooltip.fragments.anyCard}
    ${CardDescription.fragments.anyCard}
  ` as TypedDocumentNode<CardOffer_anyCard>,
};

export default CardOffer;
