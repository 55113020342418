import { TypedDocumentNode, gql } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { SettlementDelayReason } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { LabelM } from '@sorare/core/src/atoms/typography';
import TimeLeft from '@sorare/core/src/contexts/ticker/TimeLeft';
import { useTokenBelongsToUser } from '@sorare/core/src/hooks/useTokenBelongsToUser';
import { isSettlementDelayed } from '@sorare/core/src/lib/deal';
import { glossary } from '@sorare/core/src/lib/glossary';

import { DelayedSettlementHelpers_anyCard } from './__generated__/index.graphql';

type Props = {
  card: DelayedSettlementHelpers_anyCard;
};

const Helpers = styled.div`
  display: inline-flex;
  align-items: baseline;
  gap: var(--unit);
  color: var(--c-neutral-600);
`;
const HelperText = styled(LabelM)`
  display: flex;
`;

const DelayedSettlementHelpers = ({ card }: Props) => {
  const tokenBelongsToUser = useTokenBelongsToUser();
  const belongsToUser = tokenBelongsToUser(card);
  const { formatMessage } = useIntl();

  const delayedSettlement = isSettlementDelayed(card);
  if (!delayedSettlement || !card?.tokenOwner?.settleAt) return null;

  const endDate = card.tokenOwner.settleAt;

  return (
    <Helpers>
      <HelperText>
        {belongsToUser ? (
          <FormattedMessage
            id="DelayedSettlementHelpers.owner"
            defaultMessage="Can be listed in"
          />
        ) : (
          <FormattedMessage
            id="DelayedSettlementHelpers.buyer"
            defaultMessage="Available for trade in"
          />
        )}
        &nbsp;
        <TimeLeft
          time={endDate}
          endLabel={formatMessage(glossary.fewMoments)}
        />
      </HelperText>
      {card.tokenOwner?.settlementDelayReason ===
        SettlementDelayReason.CONVERSION_CREDIT_USED && (
        <Tooltip
          placement="bottom"
          title={
            <FormattedMessage
              id="DelayedSettlementHelpers.conversionCreditTooltip"
              defaultMessage="This Card was bought using a credit and can’t be listed for sale for {days} days."
              values={{ days: 14 }}
            />
          }
        >
          <div>
            <FontAwesomeIcon size="xs" icon={faInfoCircle} />
          </div>
        </Tooltip>
      )}
      {card.tokenOwner?.settlementDelayReason ===
        SettlementDelayReason.APPLE_IAP && (
        <Tooltip
          placement="bottom"
          title={
            <FormattedMessage
              id="DelayedSettlementHelpers.appleIAPTooltip"
              defaultMessage="This card was bought on iOS and can’t be listed for sale for {days} days."
              values={{ days: 14 }}
            />
          }
        >
          <div>
            <FontAwesomeIcon size="xs" icon={faInfoCircle} />
          </div>
        </Tooltip>
      )}
    </Helpers>
  );
};
DelayedSettlementHelpers.fragments = {
  anyCard: gql`
    fragment DelayedSettlementHelpers_anyCard on AnyCardInterface {
      slug
      tradeableStatus
      tokenOwner {
        id
        settleAt
        settlementDelayReason
      }
      ...useTokenBelongsToUser_anyCard
    }
    ${useTokenBelongsToUser.fragments.anyCard}
  ` as TypedDocumentNode<DelayedSettlementHelpers_anyCard>,
};
export default DelayedSettlementHelpers;
