import { createContext, useContext } from 'react';

interface RiskInstance {
  publishRiskData: () => Promise<string>;
}

export interface RiskJsContext {
  riskInstance: RiskInstance | undefined;
}

export const riskJsContext = createContext<RiskJsContext | null>(null);

export const useRiskJsContext = () => useContext(riskJsContext)!;

export default riskJsContext.Provider;
