import { TypedDocumentNode, gql, useMutation } from '@apollo/client';

import { coinBalancesFragment } from '@sorare/core/src/contexts/currentUser/queries';
import {
  Level,
  useSnackNotificationContext,
} from '@sorare/core/src/contexts/snackNotification';
import { formatGqlErrors } from '@sorare/core/src/gql';

import {
  BuyClubShopItemMutation,
  BuyClubShopItemMutationVariables,
} from './__generated__/useBuyShopItem.graphql';

const BUY_CLUB_SHOP_ITEM_MUTATION = gql`
  mutation BuyClubShopItemMutation($input: buyShopItemInput!) {
    buyShopItem(input: $input) {
      currentUser {
        slug
        ...CurrentUserProvider_coinBalances
      }
      shopItem {
        ... on ShopItemInterface {
          id
          myPurchasesCount
          myLimitResetAt
          myBalance
          myAvailableUserShopItems {
            id
          }
        }
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${coinBalancesFragment}
` as TypedDocumentNode<
  BuyClubShopItemMutation,
  BuyClubShopItemMutationVariables
>;

export default () => {
  const [buyShopItem] = useMutation(BUY_CLUB_SHOP_ITEM_MUTATION);
  const { showNotification } = useSnackNotificationContext();

  return async (shopItemId: string) => {
    const result = await buyShopItem({
      variables: {
        input: {
          shopItemId,
        },
      },
    });

    const errors = result.data?.buyShopItem?.errors || [];

    if (errors.length) {
      showNotification(
        'errors',
        { errors: formatGqlErrors(errors) },
        { level: Level.WARN }
      );
      return errors;
    }
    return null;
  };
};
