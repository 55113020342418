import Big from 'bignumber.js';

import { CardItem as Item } from '../CardItem';

type Props = {
  power: string;
  large?: boolean;
};

export const SimpleCardBonus = ({ power, large }: Props) => {
  const cardBonus = new Big(power).minus(1).multipliedBy(100);
  return <Item large={large} value={`+${cardBonus}%`} />;
};
