import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';

import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import {
  RefreshSignedAmountQuery,
  RefreshSignedAmountQueryVariables,
} from './__generated__/useRefreshSignedAmount.graphql';

const REFRESH_SIGNED_AMOUNT_QUERY = gql`
  query RefreshSignedAmountQuery($id: String!) {
    tokens {
      primaryOffer(id: $id) {
        id
        signedAmount
        price {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<
  RefreshSignedAmountQuery,
  RefreshSignedAmountQueryVariables
>;

export const useRefreshSignedAmount = (id: string) => {
  const [refresh] = useLazyQuery<
    RefreshSignedAmountQuery,
    RefreshSignedAmountQueryVariables
  >(REFRESH_SIGNED_AMOUNT_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  return refresh;
};
