import { createContext, useContext } from 'react';

import { CurrentMarketplaceUserQuery } from './__generated__/Provider.graphql';

export const CurrentMarketplaceUserContext = createContext<
  CurrentMarketplaceUserQuery['currentUser'] | null
>(null);

export const useCurrentMarketplaceUserContext = () =>
  useContext(CurrentMarketplaceUserContext)!;

export default CurrentMarketplaceUserContext.Provider;
