import classNames from 'classnames';
import styled from 'styled-components';

import { HexColor } from 'style/types';

const StyledSvg = styled.svg`
  &.reverse {
    transform: rotate(180deg);
  }
`;

type Props = { color?: HexColor; reverse?: boolean };
export const Arrows = ({
  color = 'var(--c-rivals-friendlies)',
  reverse,
}: Props) => {
  return (
    <StyledSvg
      width="39"
      height="28"
      viewBox="0 0 39 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames({ reverse })}
    >
      <path
        opacity="0.5"
        d="M19.7168 12.4976L39 27.8991L19.7168 8.49072L0.433585 27.8991L19.7168 12.4976Z"
        fill={color}
      />
      <path
        d="M19.7168 4.10797L39 19.5095L19.7168 0.101073L0.433585 19.5095L19.7168 4.10797Z"
        fill={color}
      />
    </StyledSvg>
  );
};
