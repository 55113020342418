import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { StarBall } from '@sorare/core/src/atoms/icons/StarBall';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyM, DisplayL, LabelM } from '@sorare/core/src/atoms/typography';
import { glossary } from '@sorare/core/src/lib/glossary';

import FlexCard from '@sorare/marketplace/src/components/token/FlexCard';

import { CongratsContent_anyCard } from './__generated__/index.graphql';
import postBuyDialogBg from './assets/post-buy-dialog-bg.jpg';

const Wrapper = styled(Vertical)`
  padding: var(--double-unit);
  background:
    linear-gradient(180deg, #2c2b70 0%, rgba(44, 43, 112, 0) 20%),
    linear-gradient(180deg, rgba(44, 43, 112, 0) 80%, #2c2b70 100%),
    url(${postBuyDialogBg}) no-repeat top / cover;
`;

const StyledStarBall = styled(StarBall)`
  width: var(--double-unit);
  margin-bottom: calc(3 * var(--unit));
`;

const CardWrapper = styled.div`
  flex: 1;
  align-self: center;
  position: relative;
  width: min(60%, 400px);
  padding: var(--triple-unit) 0;
  aspect-ratio: var(--card-aspect-ratio);
`;

const TitleRow = styled(Horizontal)`
  align-items: flex-end;
`;
const Title = styled(DisplayL)`
  width: min-content;
`;
type Props = {
  card: Nullable<CongratsContent_anyCard>;
  onClose: () => void;
  disableCta?: boolean;
};

export const CongratsContent = ({ card, onClose, disableCta }: Props) => {
  return (
    <Wrapper gap={4}>
      <CardWrapper>{card && <FlexCard card={card} width={320} />}</CardWrapper>
      <Vertical>
        <LabelM color="var(--c-score-high)">
          <FormattedMessage
            id="PostBuyExplanatoryDialog.subtitle"
            defaultMessage="Congratulations!"
          />
        </LabelM>
        <TitleRow gap={0}>
          <Title>
            <FormattedMessage {...glossary.earlyAccess} />
          </Title>
          <StyledStarBall />
        </TitleRow>
        <BodyM>
          <FormattedMessage
            id="PostBuyExplanatoryDialog.description"
            defaultMessage="You can buy, sell, and play it now. The player image will be revealed in September."
          />
        </BodyM>
      </Vertical>
      {!disableCta && (
        <Button fullWidth color="primary" onClick={onClose} size="large">
          <FormattedMessage
            id="PostBuyExplanatoryDialog.cta"
            defaultMessage="Play it now"
          />
        </Button>
      )}
    </Wrapper>
  );
};

CongratsContent.fragments = {
  anyCard: gql`
    fragment CongratsContent_anyCard on AnyCardInterface {
      slug
      ...FlexCard_anyCard
    }
    ${FlexCard.fragments.anyCard}
  ` as TypedDocumentNode<CongratsContent_anyCard>,
};
