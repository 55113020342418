import { TypedDocumentNode, gql } from '@apollo/client';

import {
  FetchTransactionStatus,
  FetchTransactionStatusVariables,
} from './__generated__/useFetchTransactionStatus.graphql';
import useMutation from './graphql/useMutation';

const FETCH_TRANSACTION_STATUS_MUTATION = gql`
  mutation FetchTransactionStatus($input: fetchTransactionStatusInput!) {
    fetchTransactionStatus(input: $input) {
      status
      message
    }
  }
` as TypedDocumentNode<FetchTransactionStatus, FetchTransactionStatusVariables>;

export default () => {
  return useMutation(FETCH_TRANSACTION_STATUS_MUTATION);
};
