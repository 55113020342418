import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { DocumentType } from '__generated__/globalTypes';
import { Vertical } from 'atoms/layout/flex';
import Dialog from 'components/dialog';
import { GraphQLResult, GraphqlForm } from 'components/form/Form';
import { FileWithDataURL } from 'components/form/UploadFile/useUploadFile';
import { DialogKey } from 'components/navigation/WithDialogs';
import { useCurrentUserContext } from 'contexts/currentUser';
import useScreenSize from 'hooks/device/useScreenSize';
import useMutation from 'hooks/graphql/useMutation';
import { useCloseDialog } from 'hooks/navigation/useCloseDialog';
import { useUploadWithPresign } from 'hooks/useUploadWithPresign';
import { useHasAdditionalKycRequestOpen } from 'hooks/wallets/useHasAdditionalKycRequestOpen';
import { glossary } from 'lib/glossary';

import { ProofOfIdentityForm } from '../ProofOfIdentityForm';
import { BankStatementUpload } from './BankStatementUpload';
import { Confirmation } from './Confirmation';
import { Intro } from './Intro';
import { PEPDeclarationUpload } from './PEPDeclarationUpload';
import { ProofOfAddressUpload } from './ProofOfAddressUpload';
import { Review } from './Review';
import {
  UnblockWalletMutation,
  UnblockWalletMutationVariables,
} from './__generated__/index.graphql';
import { UnblockWalletSteps, stepByDocumentType } from './type';

const Content = styled(Vertical).attrs({ gap: 3 })`
  justify-content: flex-start;
`;

const Body = styled(Content)`
  padding: var(--double-unit);
  min-height: 100%;
`;

const UNBLOCK_WALLET_MUTATION = gql`
  mutation UnblockWalletMutation($input: unblockFiatWalletInput!) {
    unblockFiatWallet(input: $input) {
      currentUser {
        slug
        id
        mangopayUser {
          id
          requiredDocuments
          additionalKycRequests {
            id
            aasmState
          }
        }
      }
      errors {
        message
      }
    }
  }
` as TypedDocumentNode<UnblockWalletMutation, UnblockWalletMutationVariables>;

const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
  height: 100%;
`;

export const UnblockWallet = () => {
  const closeDialog = useCloseDialog();
  const { currentUser } = useCurrentUserContext();
  const [frontPage, setFrontPage] = useState<FileWithDataURL>();
  const [backPage, setBackPage] = useState<FileWithDataURL>();
  const [proofOfAddressFile, setProofOfAddressFile] =
    useState<FileWithDataURL>();
  const [bankStatementFile, setBankStatementFile] = useState<FileWithDataURL>();
  const [pepDeclarationFile, setPepDeclarationFile] =
    useState<FileWithDataURL>();
  const uploadWithPresign = useUploadWithPresign();
  const [unblockWallet] = useMutation(UNBLOCK_WALLET_MUTATION, {
    showErrorsInForm: true,
  });
  const hasAdditionalKycRequestOpen = useHasAdditionalKycRequestOpen();

  const { up: isTablet } = useScreenSize('tablet');

  const [step, setStep] = useState<UnblockWalletSteps>(
    hasAdditionalKycRequestOpen
      ? UnblockWalletSteps.REVIEW
      : UnblockWalletSteps.INTRO
  );

  if (!currentUser?.mangopayUser) return null;

  const { requiredDocuments } = currentUser.mangopayUser;

  if (!requiredDocuments) return null;

  const flowSteps: UnblockWalletSteps[] = [
    UnblockWalletSteps.INTRO,
    ...requiredDocuments.map(document => stepByDocumentType[document]),
    UnblockWalletSteps.REVIEW,
    UnblockWalletSteps.CONFIRMATION,
  ];

  const currentStepIndex = flowSteps.findIndex(flowStep => flowStep === step);

  const goToNextStep = () => {
    setStep(flowSteps[currentStepIndex + 1]);
  };

  const goToPrevStep = () => {
    setStep(flowSteps[currentStepIndex - 1]);
  };

  const sharedProps = {
    onBack: goToPrevStep,
    onNext: goToNextStep,
  };

  const filesByDocumentType: {
    [key in DocumentType]: (FileWithDataURL | undefined)[] | undefined;
  } = {
    [DocumentType.PROOF_OF_IDENTITY]: [frontPage, backPage].filter(Boolean),
    [DocumentType.PROOF_OF_ADDRESS]: [proofOfAddressFile],
    [DocumentType.BANK_STATEMENT]: [bankStatementFile],
    [DocumentType.POTENTIAL_POLITICALLY_EXPOSED_PERSON_DECLARATION]: [
      pepDeclarationFile,
    ],
  };

  const filesByRequiredDocuments: {
    [key in DocumentType]?: FileWithDataURL[];
  } = Object.entries(filesByDocumentType)
    .filter(([key]) => {
      return requiredDocuments.includes(key as DocumentType);
    })
    .reduce((acc, [key, value]) => {
      return { ...acc, [key]: value };
    }, {});

  const onSubmit = async (onResult: (result: GraphQLResult) => void) => {
    const promises = Object.entries(filesByRequiredDocuments).map(
      async ([documentType, files]) => {
        if (!files) return undefined;
        const presignKeys = await Promise.all(
          files.map(async file => {
            if (!file?.file) return undefined;
            return uploadWithPresign(file.file);
          })
        );

        if (presignKeys.some(key => !key)) {
          return undefined;
        }
        return {
          documentType: documentType as DocumentType,
          frontPagePresignedKey: presignKeys[0] as string,
          ...(files.length > 1 && presignKeys[1]
            ? { backPagePresignedKey: presignKeys[1] as string }
            : {}),
        };
      }
    );
    const result = (await Promise.all(promises)).filter(Boolean);

    if (result.some(item => !item?.frontPagePresignedKey)) {
      return;
    }

    const mutationResult = await unblockWallet({
      variables: {
        input: {
          files: result,
        },
      },
    });

    onResult(mutationResult);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={() => {
        closeDialog(DialogKey.unblockWallet);
      }}
      open
      fullScreen={!isTablet}
    >
      <Body>
        {step === UnblockWalletSteps.INTRO && (
          <Intro documentsRequired={requiredDocuments} onNext={goToNextStep} />
        )}
        {step === UnblockWalletSteps.ADD_PROOF_OF_IDENTITY && (
          <ProofOfIdentityForm
            frontPage={frontPage}
            setFrontPage={setFrontPage}
            backPage={backPage}
            setBackPage={setBackPage}
            onBack={goToPrevStep}
            onSubmit={onResult => {
              onResult({});
            }}
            onSuccess={goToNextStep}
          />
        )}
        {step === UnblockWalletSteps.ADD_PROOF_OF_ADDRESS && (
          <ProofOfAddressUpload
            file={proofOfAddressFile}
            setFile={setProofOfAddressFile}
            {...sharedProps}
          />
        )}
        {step === UnblockWalletSteps.ADD_BANK_STATEMENT && (
          <BankStatementUpload
            file={bankStatementFile}
            setFile={setBankStatementFile}
            {...sharedProps}
          />
        )}
        {step === UnblockWalletSteps.ADD_PEP_DECLARATION && (
          <PEPDeclarationUpload
            file={pepDeclarationFile}
            setFile={setPepDeclarationFile}
            {...sharedProps}
          />
        )}
        {step === UnblockWalletSteps.REVIEW && (
          <StyledGraphqlForm
            onSuccess={() => {
              setStep(UnblockWalletSteps.CONFIRMATION);
            }}
            onSubmit={(_, onResult) => {
              onSubmit(onResult);
            }}
            render={(Error, SubmitButton) => {
              return (
                <Vertical gap={2}>
                  <Review
                    requiredDocumentsFiles={filesByRequiredDocuments}
                    submitButton={
                      <SubmitButton size="medium">
                        <FormattedMessage {...glossary.submit} />
                      </SubmitButton>
                    }
                    {...sharedProps}
                  />
                  <Error />
                </Vertical>
              );
            }}
          />
        )}
        {step === UnblockWalletSteps.CONFIRMATION && (
          <Confirmation
            onClick={() => {
              closeDialog(DialogKey.unblockWallet);
            }}
          />
        )}
      </Body>
    </Dialog>
  );
};
