import { Color } from '@sorare/core/src/style/types';

const Shards = ({ color }: { color?: Color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8547 2.34903L10.14 0.492188L9.62437 2.07903L7.81526 1.49121L7.14648 3.54949L7.63135 3.70703L4.20816 14.2425L3.73372 14.0884L3.06494 16.1467L12.5049 19.2139L13.1737 17.1556L12.6266 16.9778L16.0498 6.44234L16.5864 6.61672L17.2552 4.55844L15.3391 3.93587L15.8547 2.34903ZM13.4594 7.7288L8.97085 6.27038L6.89496 12.6593L11.8574 12.6593L13.4594 7.7288Z"
        fill={color || 'var(--c-brand-300)'}
      />
    </svg>
  );
};

export default Shards;
