import { TypedDocumentNode, gql } from '@apollo/client';

import useMutation from 'hooks/graphql/useMutation';
import { monetaryAmountFragment } from 'lib/monetaryAmount';

import {
  UpdateExternalEthDeposit,
  UpdateExternalEthDepositVariables,
} from './__generated__/useUpdateExternalEthDeposit.graphql';

const UPDATE_EXTERNAL_DEPOSIT_MUTATION = gql`
  mutation UpdateExternalEthDeposit($input: updateExternalEthDepositInput!) {
    updateExternalEthDeposit(input: $input) {
      currentUser {
        slug
        pendingDeposits {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
          date
          providerType
          transactionHash
        }
      }
      errors {
        code
        message
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<
  UpdateExternalEthDeposit,
  UpdateExternalEthDepositVariables
>;

export default () => {
  return useMutation<
    UpdateExternalEthDeposit,
    UpdateExternalEthDepositVariables
  >(UPDATE_EXTERNAL_DEPOSIT_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
};
