import { useTypedParams } from '@sorare/routing';

import GameplayLeaderboardPage from '@sorare/gameplay/src/components/collections/LeaderboardPage';

import { PageParams } from './__generated__/routeParams';

const LeaderboardPage = () => {
  const { collectionSlug, slug } = useTypedParams<PageParams>();
  return <GameplayLeaderboardPage collection={collectionSlug} slug={slug} />;
};

export default LeaderboardPage;
