import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useCallback, useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { MLB_PLAY_PRO_FIXTURE_MY_LINEUPS_LINEUP } from '@sorare/core/src/constants/__generated__/routes';
import { ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER } from '@sorare/core/src/constants/routes';
import { withFragments } from '@sorare/core/src/gql';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';
import { formatLineupDisplayName } from '@sorare/core/src/lib/so5';

import { LiveLineupConsentMessage } from '../LiveLineupConsentMessage';
import { TokenTransferChildrenProps } from '../types';
import { UsedCardWarning } from './UsedCardWarning';
import {
  UsedInLineupValidatorQuery,
  UsedInLineupValidatorQueryVariables,
  UsedInLineupValidator_anyCard,
  getSo5LineupUrl_so5Lineup,
} from './__generated__/index.graphql';

const getSo5LineupUrl = withFragments(
  (sport: Sport, lineup: getSo5LineupUrl_so5Lineup | undefined) => {
    if (!lineup?.so5Leaderboard) return undefined;

    if ([Sport.FOOTBALL, Sport.NBA].includes(sport)) {
      return generateSportPath(
        ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
        {
          params: {
            eventType: lineup.so5Leaderboard.so5Fixture.type.toLowerCase(),
            fixture: lineup.so5Leaderboard.so5Fixture.slug,
            competition: lineup.so5Leaderboard.so5LeaderboardGroup.slug,
            contender: lineup.so5LeaderboardContender.slug,
          },
          sport,
        }
      );
    }
    if (sport === Sport.BASEBALL) {
      return generatePath(MLB_PLAY_PRO_FIXTURE_MY_LINEUPS_LINEUP, {
        fixture: lineup.so5Leaderboard.so5Fixture.slug,
        lineup: lineup.id,
      });
    }
    return '';
  },
  {
    so5Lineup: gql`
      fragment getSo5LineupUrl_so5Lineup on So5Lineup {
        id
        so5LeaderboardContender {
          slug
        }
        so5Leaderboard {
          slug
          so5Fixture {
            slug
            type
          }
          so5LeaderboardGroup {
            slug
          }
        }
      }
    ` as TypedDocumentNode<getSo5LineupUrl_so5Lineup>,
  }
);

const USED_IN_LINEUP_VALIDATOR_QUERY = gql`
  query UsedInLineupValidatorQuery($cardSlugs: [String!]!) {
    anyCards(slugs: $cardSlugs) {
      slug
      sport
      openedSo5Lineups {
        id
        cancelledAt
        ...formatLineupDisplayName_so5Lineup
        ...getSo5LineupUrl_so5Lineup
      }
      liveSo5Lineup {
        id
        cancelledAt
        ...formatLineupDisplayName_so5Lineup
        ...getSo5LineupUrl_so5Lineup
      }
    }
  }
  ${getSo5LineupUrl.fragments.so5Lineup}
  ${formatLineupDisplayName.fragments.so5Lineup}
` as TypedDocumentNode<
  UsedInLineupValidatorQuery,
  UsedInLineupValidatorQueryVariables
>;

type Props = {
  cards: UsedInLineupValidator_anyCard[];
  transferType: 'list' | 'send_trade' | 'receive_trade';
  render: (props: TokenTransferChildrenProps) => ReactNode;
};

export const UsedInLineupValidator = ({
  cards,
  transferType,
  render,
}: Props) => {
  const { data, loading } = useQuery(USED_IN_LINEUP_VALIDATOR_QUERY, {
    variables: {
      cardSlugs: cards.map(card => card.slug),
    },
  });

  const filteredCards = useMemo(
    () => [
      ...(data?.anyCards ?? []).map(node => ({
        slug: node.slug,
        liveLineup: node.liveSo5Lineup
          ? formatLineupDisplayName(node.liveSo5Lineup)
          : undefined,
        openedLineups: node.openedSo5Lineups.map(lineup =>
          formatLineupDisplayName(lineup)
        ),
        lineupUrl: getSo5LineupUrl(
          node.sport,
          node.liveSo5Lineup ?? node.openedSo5Lineups[0]
        ),
        hasActiveLiveLineup:
          node.liveSo5Lineup && !node.liveSo5Lineup?.cancelledAt,
      })),
    ],
    [data]
  );

  const validationMessages: Record<string, React.JSX.Element> = {};
  filteredCards.forEach(card => {
    if (!card.hasActiveLiveLineup && card.openedLineups.length === 0) {
      return;
    }
    validationMessages[card.slug] = (
      <UsedCardWarning
        key={card.slug}
        transferType={transferType}
        openedLineups={card.openedLineups}
        liveLineup={card.liveLineup}
        lineupUrl={card.lineupUrl}
      />
    );
  }, {});

  const ConsentMessage = useCallback<
    NonNullable<TokenTransferChildrenProps['ConsentMessage']>
  >(
    props => {
      const liveLineupsCount =
        filteredCards?.filter(card => card.liveLineup).length || 0;
      return (
        <LiveLineupConsentMessage
          lineupsCount={liveLineupsCount}
          transferContext={transferType}
          {...props}
        />
      );
    },
    [filteredCards, transferType]
  );

  return render({
    validationMessages,
    loading,
    ConsentMessage: filteredCards?.some(card => card.hasActiveLiveLineup)
      ? ConsentMessage
      : undefined,
  });
};

UsedInLineupValidator.fragments = {
  anyCard: gql`
    fragment UsedInLineupValidator_anyCard on AnyCardInterface {
      slug
      sport
    }
  ` as TypedDocumentNode<UsedInLineupValidator_anyCard>,
};
