import { TypedDocumentNode, gql } from '@apollo/client';

import {
  CLUB_PLACEHOLDER,
  FRONTEND_ASSET_HOST,
} from '@sorare/core/src/constants/assets';
import { isType } from '@sorare/core/src/gql';

import { TeamPicture_team } from './__generated__/index.graphql';

interface Props {
  team: TeamPicture_team;
  className?: string;
  useEuropeanChampionshipFlag?: boolean;
}
const NATIONAL_TEAM_FLAG_ROOT =
  'https://frontend-assets.sorare.com/flags-european-championship';

export const TeamPicture = ({
  team,
  className,
  useEuropeanChampionshipFlag,
}: Props) => {
  const getSrc = () => {
    if (isType(team, 'Club')) {
      return team.pictureUrl!;
    }
    if (useEuropeanChampionshipFlag) {
      return `${NATIONAL_TEAM_FLAG_ROOT}/${team.country.code}.svg`;
    }
    return `${FRONTEND_ASSET_HOST}/flags/${team.country.code}.svg`;
  };

  return (
    <img
      className={className}
      alt={team.name}
      src={getSrc()}
      onError={e => {
        if (e.target instanceof HTMLImageElement) {
          e.target.src = CLUB_PLACEHOLDER;
        }
      }}
    />
  );
};

TeamPicture.fragments = {
  team: gql`
    fragment TeamPicture_team on Team {
      ... on TeamInterface {
        slug
        name
        pictureUrl
      }
      ... on NationalTeam {
        slug
        country {
          slug
          code
        }
      }
    }
  ` as TypedDocumentNode<TeamPicture_team>,
};

export default TeamPicture;
