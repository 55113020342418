import { TypedDocumentNode, gql } from '@apollo/client';

import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import SmallUser from 'components/user/SmallUser';

import {
  BidderFragment,
  CardFragment,
  OfferSideFragment,
  PrimaryOfferUpdate,
  PrimaryOfferUpdateFragment,
  PrimaryOfferUpdateVariables,
  TokenAuctionUpdate,
  TokenAuctionUpdateFragment,
  TokenAuctionUpdateVariables,
  TokenOfferUpdate,
  TokenOfferUpdateFragment,
  TokenOfferUpdateVariables,
} from './__generated__/subscriptions.graphql';

const bidderFragment = gql`
  fragment BidderFragment on BlockchainUser {
    ... on User {
      slug
      status
      ...SmallUser_user
    }
    ... on AnonymousUser {
      id
      ...SmallUser_anonymousUser
    }
  }
  ${SmallUser.fragments.user}
  ${SmallUser.fragments.anonymousUser}
` as TypedDocumentNode<BidderFragment>;

const cardFragment = gql`
  fragment CardFragment on AnyCardInterface {
    slug
    user {
      slug
    }
    tokenOwner {
      id
      from
      blockchain
      address
      amounts {
        ...MonetaryAmountFragment_monetaryAmount
      }
      transferType
      user {
        slug
      }
      account {
        id
        accountable {
          ... on Node {
            id
          }
          ... on EthereumAccount {
            id
            address
          }
          ... on StarkwareAccount {
            id
            starkKey
          }
        }
        owner {
          ... on User {
            slug
          }
          ... on Contract {
            id
          }
        }
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<CardFragment>;

export const tokenAuctionFragment = gql`
  fragment TokenAuctionUpdateFragment on TokenAuction {
    id
    open
    endDate
    currentPrice
    currency
    bidsCount
    minNextBid
    cards: anyCards {
      slug
      ...CardFragment
      latestEnglishAuction {
        id
      }
    }
    bestBid {
      id
      bidder {
        ...BidderFragment
      }
    }
    bids(first: 5) {
      nodes {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        bidder {
          ...BidderFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${cardFragment}
  ${bidderFragment}
  ${monetaryAmountFragment}
` as TypedDocumentNode<TokenAuctionUpdateFragment>;

const offerSideFragment = gql`
  fragment OfferSideFragment on TokenOfferSide {
    id
    wei
    anyCards {
      ...CardFragment
      slug
      liveSingleSaleOffer {
        id
      }
      myMintedSingleSaleOffer {
        id
      }
    }
  }
  ${cardFragment}
` as TypedDocumentNode<OfferSideFragment>;

export const tokenOfferFragment = gql`
  fragment TokenOfferUpdateFragment on TokenOffer {
    id
    endDate
    status
    senderSide {
      id
      ...OfferSideFragment
    }
    receiverSide {
      id
      ...OfferSideFragment
    }
  }
  ${offerSideFragment}
` as TypedDocumentNode<TokenOfferUpdateFragment>;

export const primaryOfferUpdateFragment = gql`
  fragment PrimaryOfferUpdateFragment on TokenPrimaryOffer {
    id
    endDate
    status
    buyer {
      slug
      ...SmallUser_user
    }
    price {
      ...MonetaryAmountFragment_monetaryAmount
    }
    signedAmount
  }
  ${SmallUser.fragments.user}
  ${monetaryAmountFragment}
` as TypedDocumentNode<PrimaryOfferUpdateFragment>;

export const tokenAuctionSubscription = gql`
  subscription TokenAuctionUpdate($sports: [Sport!]) {
    tokenAuctionWasUpdated(sports: $sports) {
      id
      ...TokenAuctionUpdateFragment
    }
  }
  ${tokenAuctionFragment}
` as TypedDocumentNode<TokenAuctionUpdate, TokenAuctionUpdateVariables>;

export const tokenOfferSubscription = gql`
  subscription TokenOfferUpdate($sports: [Sport!]) {
    tokenOfferWasUpdated(sports: $sports) {
      id
      ...TokenOfferUpdateFragment
    }
  }
  ${tokenOfferFragment}
` as TypedDocumentNode<TokenOfferUpdate, TokenOfferUpdateVariables>;

export const primaryOfferSubscription = gql`
  subscription PrimaryOfferUpdate($sports: [Sport!]) {
    primaryOfferWasUpdated(sports: $sports) {
      id
      ...PrimaryOfferUpdateFragment
    }
  }
  ${primaryOfferUpdateFragment}
` as TypedDocumentNode<PrimaryOfferUpdate, PrimaryOfferUpdateVariables>;
