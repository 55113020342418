interface Props {
  color?: string;
}

const Pack = ({ color }: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8459 15.2171C14.0412 15.4123 14.3578 15.4123 14.553 15.2171L15.4392 14.3309L18.5167 11.2535L19.4029 10.3673C19.5981 10.172 19.5981 9.85542 19.4028 9.66016L17.5524 7.80969C17.3571 7.61443 17.0405 7.61443 16.8453 7.80969L16.3126 8.34231C16.1174 8.53757 15.8008 8.53757 15.6055 8.34231L11.418 4.15474C11.2227 3.95948 11.2227 3.64289 11.418 3.44763L11.9506 2.91502C12.1458 2.71976 12.1458 2.40317 11.9506 2.20791L10.0962 0.353563C9.90096 0.158301 9.58437 0.158301 9.38911 0.353563L4.5393 5.20338C4.34404 5.39864 4.34404 5.71522 4.5393 5.91048L6.39365 7.76483C6.58891 7.96009 6.90549 7.96009 7.10076 7.76483L7.63342 7.23216C7.82869 7.0369 8.14527 7.0369 8.34053 7.23216L8.88186 7.77349C9.07712 7.96875 9.07712 8.28534 8.88186 8.4806L1.16345 16.199C0.968184 16.3943 0.968184 16.7109 1.16345 16.9061L2.85035 18.593C3.04562 18.7883 3.3622 18.7883 3.55746 18.593L11.2759 10.8746C11.4711 10.6793 11.7877 10.6794 11.983 10.8746L12.5281 11.4197C12.7234 11.615 12.7234 11.9316 12.5281 12.1268L11.9954 12.6595C11.8002 12.8548 11.8002 13.1713 11.9954 13.3666L13.8459 15.2171Z"
        fill={color || 'var(--c-yellow-600)'}
      />
    </svg>
  );
};

export default Pack;
