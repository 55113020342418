export const useGetBrowserInfo = () => {
  return () => {
    return {
      colorDepth: window.screen.colorDepth,
      language: navigator.language,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      timeZoneOffset: new Date().getTimezoneOffset(),
    };
  };
};
