import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';

import { ColoredPointsDelta } from 'components/scores/ColoredPointsDelta';
import { ColoredScore } from 'components/scores/ColoredScore';

const Wrapper = styled(Horizontal).attrs({ gap: 0 })`
  display: inline-flex;
  position: relative;
  font: var(--t-label-s);
`;

const ScoreDelta = styled.div`
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(105%, 0%);
`;

type Props = {
  score: number | string;
  averageGameScore?: number | string;
  playedInGame?: boolean;
  noGame?: boolean;
  isLive?: boolean;
};

export const ColoredScoreWithDelta = ({
  score,
  averageGameScore = 0,
  playedInGame = true,
  noGame = false,
  isLive = false,
}: Props) => {
  const gameScore = +score || 0;
  const referenceScore = +averageGameScore;
  const diff = gameScore - referenceScore;

  return (
    <Wrapper>
      <ColoredScore
        score={gameScore}
        noGame={noGame}
        playedInGame={playedInGame}
        isLive={isLive}
      />
      <ScoreDelta as="div">
        <ColoredPointsDelta score={diff} />
      </ScoreDelta>
    </Wrapper>
  );
};
