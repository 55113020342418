import { keccak_256 } from 'js-sha3';

import { useCurrentUserContext } from 'contexts/currentUser';
import { randomUUID } from 'lib/uuid';

import useFeatureFlags from './useFeatureFlags';
import useLocalStorage from './useLocalStorage';

export interface FeatureFlagFormat {
  [key: string]: number;
}

function isFeatureFlagFormat(obj: any): obj is FeatureFlagFormat {
  if (typeof obj !== 'object' || obj === null) return false;

  return Object.keys(obj).every(key => typeof obj[key] === 'number');
}

export const useLocalVariants = (flag: string) => {
  const { currentUser } = useCurrentUserContext() ?? {};
  const {
    flags: { [flag]: flagValue },
  } = useFeatureFlags();
  const [userId] = useLocalStorage(
    'userId',
    () => (currentUser ? currentUser.slug : randomUUID()),
    { forcePersist: true }
  );

  if (flagValue === undefined) return undefined;

  if (!isFeatureFlagFormat(flagValue)) {
    // eslint-disable-next-line no-console
    console.warn('Improperly formatted variant', flag, flagValue);
    return undefined;
  }

  const [hash] = keccak_256.digest(userId);

  const total = Object.values(flagValue).reduce((a, b) => a + b, 0);

  let cumulativeProbability = 0;

  return (
    Object.keys(flagValue)
      // Launchdarkly returns the object in random order so we need to sort it to always get the same result
      .sort((a, b) => a.localeCompare(b))
      .find(key => {
        cumulativeProbability += flagValue[key];
        return hash % total < cumulativeProbability;
      })
  );
};
