import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';

import { AveragePlayerScore } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { PlayerScore } from '@sorare/core/src/components/scoring/PlayerScore';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

import CardBonus from '../CardBonus';
import {
  BaseballCardProperties_anyCard,
  BaseballCardProperties_anyPlayer,
} from './__generated__/index.graphql';

type Props = {
  card: BaseballCardProperties_anyCard;
  withTransferMalus?: boolean;
  stats?: ReactNode;
  supensionsAndInjuries?: ReactNode;
  extras?: ReactNode;
};

const BaseballCardProperties = ({
  card,
  withTransferMalus = false,
  stats,
  supensionsAndInjuries,
  extras,
}: Props) => {
  const {
    flags: { useMlbSeasonAverageScore = true },
  } = useFeatureFlags();

  const { anyPlayer } = card;

  return (
    <Horizontal>
      {stats}
      <PlayerScore
        player={anyPlayer}
        mode={
          useMlbSeasonAverageScore
            ? AveragePlayerScore.SEASON_AVERAGE_SCORE
            : AveragePlayerScore.LAST_FIFTEEN_SO5_AVERAGE_SCORE
        }
      />
      <CardBonus card={card} withTransferMalus={withTransferMalus} />
      {supensionsAndInjuries}
      {extras}
    </Horizontal>
  );
};

const anyPlayerFragment = gql`
  fragment BaseballCardProperties_anyPlayer on AnyPlayerInterface {
    slug
    ...PlayerScore_anyPlayer
  }
  ${PlayerScore.fragments.anyPlayer}
` as TypedDocumentNode<BaseballCardProperties_anyPlayer>;

BaseballCardProperties.fragments = {
  anyPlayer: anyPlayerFragment,
  anyCard: gql`
    fragment BaseballCardProperties_anyCard on AnyCardInterface {
      slug
      anyPlayer {
        slug
        ...PlayerScore_anyPlayer
        ...BaseballCardProperties_anyPlayer
      }
      ...CardBonus_anyCard
    }
    ${CardBonus.fragments.anyCard}
    ${anyPlayerFragment}
  ` as TypedDocumentNode<BaseballCardProperties_anyCard>,
};

export default BaseballCardProperties;
