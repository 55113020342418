import { FormattedDate } from 'react-intl';

import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { useIntlContext } from '@sorare/core/src/contexts/intl';

export const AgeWithTooltip = ({
  birthDate,
  age,
}: {
  birthDate: Date;
  age: number;
}) => {
  const { formatMessage } = useIntlContext();
  return (
    <Tooltip
      title={
        <FormattedDate
          value={birthDate || undefined}
          year="numeric"
          month="numeric"
          day="numeric"
          timeZone="UTC"
        />
      }
      placement="top"
    >
      <span>
        {formatMessage(
          {
            id: 'PlayerPageHeader.age',
            defaultMessage: '{age} years old',
          },
          { age }
        )}
      </span>
    </Tooltip>
  );
};
