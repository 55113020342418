import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Container } from 'atoms/layout/Container';
import { breakpoints } from 'style/mediaQuery';

const Content = styled.div`
  width: 100%;
`;

type Props = { className?: string };
export const ContentContainer = ({
  children,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <Container maxWidth={breakpoints.mediumDesktop}>
      <Content className={className}>{children}</Content>
    </Container>
  );
};
