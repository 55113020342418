import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Title4 } from 'atoms/typography';
import Dialog from 'components/dialog';
import useScreenSize from 'hooks/device/useScreenSize';
import { tabletAndAbove } from 'style/mediaQuery';

type Props = {
  url: string;
  onClose: () => void;
  postMessageCallback: (value: string) => void;
};

const Iframe = styled.iframe`
  display: block;
  border: none;
  width: 100%;
  height: 100%;
  margin: auto;

  @media ${tabletAndAbove} {
    /* Dimensions taken from 3DS v2 spec */
    width: 500px;
    height: 600px;
    max-width: 100%;
    max-height: 100%;
  }
`;

const THREE_D_SECURE_MESSAGE = 'sorare:payment:3DS-authentication-complete';

export const ThreeDSDialogWrapper = ({
  url,
  onClose,
  postMessageCallback,
}: Props) => {
  const { up: isTablet } = useScreenSize('tablet');

  useEffect(() => {
    const cb = (event: MessageEvent) => {
      if (event.data.eventId === THREE_D_SECURE_MESSAGE) {
        postMessageCallback(event.data.data.location);
      }
    };
    window.addEventListener('message', cb);

    return () => window.removeEventListener('message', cb);
  }, [postMessageCallback]);

  return (
    <Dialog
      open
      disableBackdropClick
      disableEscapeKeyDown
      title={
        <Title4 className="text-center">
          <FormattedMessage
            id="ThreeDSIframe.title"
            defaultMessage="3D secure authentication"
          />
        </Title4>
      }
      onClose={onClose}
      maxWidth={false}
      fullScreen={!isTablet}
    >
      <Iframe src={url} />
    </Dialog>
  );
};
