import { CreditCardBrand } from '__generated__/globalTypes';

export type PaymentProvider_paymentMethod = {
  __typename: any;
  id: string;
  card: {
    __typename: any;
    last4: string;
    brand: CreditCardBrand;
  };
};

export enum WalletPaymentMethod {
  ETH_WALLET = 'eth_wallet',
  FIAT_WALLET = 'fiat_wallet',
}

export enum PaymentMethod {
  ETH_WALLET = 'eth_wallet',
  FIAT_WALLET = 'fiat_wallet',
  CREDIT_CARD = 'credit_card',
  NEW_CREDIT_CARD = 'new_credit_card',
  PAYMENT_REQUEST = 'payment_request',
  PAYPAL = 'paypal',
}

export type CrediCardPaymentMethodSuffix = `credit_card_`;
export type CrediCardPaymentMethod = `${CrediCardPaymentMethodSuffix}${string}`;

export type OrderedPaymentMethod = PaymentMethod | CrediCardPaymentMethod;
