import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import BuyTokenConfirmation from 'components/buyActions/BuyTokenConfirmation';

import {
  BuyTokenConfirmationQueryById,
  BuyTokenConfirmationQueryByIdVariables,
} from './__generated__/index.graphql';

const Root = styled.div`
  padding: var(--quadruple-unit) 0;
`;

const BUY_TOKEN_CONFIRMATION_QUERY = gql`
  query BuyTokenConfirmationQueryById($id: String!) {
    tokens {
      offer(id: $id) {
        id
        senderSide {
          id
          anyCards {
            slug
            ...BuyTokenConfirmation_anyCard
          }
        }
      }
    }
  }
  ${BuyTokenConfirmation.fragments.anyCard}
` as TypedDocumentNode<
  BuyTokenConfirmationQueryById,
  BuyTokenConfirmationQueryByIdVariables
>;

export type Props = {
  tokenOfferId: string;
  onClose: () => void;
};

export const BuyTokenConfirmationById = ({ tokenOfferId, onClose }: Props) => {
  const { data, loading } = useQuery(BUY_TOKEN_CONFIRMATION_QUERY, {
    variables: {
      id: idFromObject(tokenOfferId)!,
    },
    fetchPolicy: 'cache-first',
    skip: !tokenOfferId,
  });

  if (loading || !data) return null;

  const {
    tokens: { offer },
  } = data;

  if (!offer) return null;

  const { senderSide } = offer;
  const card = senderSide.anyCards[0];

  return (
    <Root>
      <BuyTokenConfirmation card={card} onClose={onClose} />
    </Root>
  );
};

export default BuyTokenConfirmationById;
