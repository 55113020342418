import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import {
  MonetaryAmountOutput,
  calculateFees,
  useMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { getMonetaryAmountIndex } from '@sorare/core/src/lib/monetaryAmount';

import FeesTooltipFromProps from '../FeesTooltipFromProps';

interface Props {
  monetaryAmount: MonetaryAmountOutput;
  referenceCurrency: SupportedCurrency;
  feesPercentagePoints: bigint;
}

const CalculatedFeesTooltip = ({
  monetaryAmount,
  referenceCurrency,
  feesPercentagePoints,
}: Props) => {
  const { toMonetaryAmount } = useMonetaryAmount();
  const { feesMonetaryAmount } = calculateFees({
    monetaryAmount,
    feesPercentagePoints,
  });

  const indexRefCurrency = getMonetaryAmountIndex(referenceCurrency);

  const marketFeeAmount = feesMonetaryAmount[indexRefCurrency];

  const marketFeeMonetaryAmount = toMonetaryAmount({
    [indexRefCurrency]: marketFeeAmount.toString(),
    referenceCurrency,
  });
  return (
    <FeesTooltipFromProps
      monetaryAmount={monetaryAmount}
      marketFeeMonetaryAmount={marketFeeMonetaryAmount}
      referenceCurrency={referenceCurrency}
    />
  );
};

export default CalculatedFeesTooltip;
