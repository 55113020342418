import { Color } from '@sorare/core/src/style/types';

type Props = { color?: Color };

const ReferralReward = ({ color }: Props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        className="cls-1"
        d="M16,6.58v4a.51.51,0,0,1-.5.5H15v3.35a1.65,1.65,0,0,1-1.64,1.65H2.64A1.65,1.65,0,0,1,1,14.43V11.08H.5a.51.51,0,0,1-.5-.5v-4a1.5,1.5,0,0,1,1.5-1.5h.88a2.67,2.67,0,0,1-.32-1.25A2.75,2.75,0,0,1,4.81,1.08C6.11,1.08,7,1.74,8,3.21,9.11,1.74,10,1.08,11.25,1.08A2.75,2.75,0,0,1,14,3.83a2.67,2.67,0,0,1-.32,1.25h.82A1.5,1.5,0,0,1,16,6.58Zm-8.75,0H1.5v3H6v1.5H2.5v3.35a.15.15,0,0,0,.14.15H7.25Zm.25-1.5L7.44,5C6,2.82,5.5,2.58,4.81,2.58a1.25,1.25,0,1,0,0,2.5Zm1.06,0h2.69a1.25,1.25,0,0,0,0-2.5c-.69,0-1.17.24-2.63,2.4Zm5.94,1.5H8.75v8h4.61a.15.15,0,0,0,.14-.15V11.08H10V9.58h4.5Z"
        fill={color || '#ff049b'}
      />
    </svg>
  );
};

export default ReferralReward;
