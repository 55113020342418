import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

export const FormControlLabel = styled(MuiFormControlLabel)`
  display: flex;
  gap: var(--unit);
  align-items: flex-start;
  margin: 0;

  label {
    color: var(--c-neutral-1000);
  }
`;
