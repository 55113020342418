/* eslint-disable @typescript-eslint/ban-types */
import { FC, ReactNode, createElement } from 'react';

type Props<T extends {}> = {
  wrap: boolean;
  Wrapper: FC<T>;
  children: ReactNode;
  props?: T;
};

export const ConditionalWrapper = <T extends {}>({
  wrap,
  Wrapper,
  children,
  props,
}: Props<T>) => {
  return wrap
    ? // use createElement instead of JSX as spreaded props will mess up typings
      createElement(Wrapper, props, children)
    : children;
};
