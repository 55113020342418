import { ReactNode } from 'react';
import styled from 'styled-components';

import { BackButton } from '../BackButton';
import { CloseButton } from '../CloseButton';

const Container = styled.div`
  display: grid;
  grid-template-areas: 'left center right';
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
  padding: var(--double-unit);
`;
export const Left = styled.div`
  grid-area: left;
`;
export const Center = styled.div`
  grid-area: center;
  overflow: hidden;
`;
export const Right = styled.div`
  grid-area: right;
`;

type Props = {
  onBack?: () => void;
  onClose?: () => void;
  title?: ReactNode;
  transparentButton?: boolean;
};

export const Header = ({
  onBack,
  title,
  onClose,
  transparentButton,
}: Props) => (
  <Container>
    <Left>{onBack && <BackButton onBack={onBack} />}</Left>
    <Center>{title}</Center>
    <Right>
      {onClose && (
        <CloseButton onClose={onClose} transparent={transparentButton} />
      )}
    </Right>
  </Container>
);
