import { useEffect, useState } from 'react';

type Props = {
  cursor?: 'pointer' | 'grab' | 'grabbing';
} & React.JSX.IntrinsicElements['group'];

export const Hoverable = ({ cursor = 'pointer', ...props }: Props) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? cursor : 'auto';
    return () => {
      document.body.style.cursor = 'auto';
    };
  }, [hovered, cursor]);

  return (
    <group
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      {...props}
    />
  );
};

export default Hoverable;
