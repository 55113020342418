import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
  IconDefinition,
} from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';

type Props = {
  nickname: string | null;
  icon: IconDefinition;
  prefix?: string;
  color?: FontAwesomeIconProps['color'];
};

export const SocialUser = ({ nickname, icon, prefix = '', color }: Props) => {
  if (!nickname) return null;

  return (
    <Horizontal>
      <FontAwesomeIcon icon={icon} color={color} />
      {prefix}
      {nickname}
    </Horizontal>
  );
};
