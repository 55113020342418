import { AdditionalKycRequestState } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';

export const useHasAdditionalKycRequestOpen = () => {
  const { currentUser } = useCurrentUserContext();
  return (currentUser?.mangopayUser?.additionalKycRequests || []).some(
    request =>
      [
        AdditionalKycRequestState.CREATED,
        AdditionalKycRequestState.VALIDATION_ASKED,
      ].includes(request.aasmState)
  );
};
