import qs from 'qs';
import { useCallback } from 'react';

import { getValue } from 'components/PersistsQueryStringParameters/storage';

import { Sport, UtmInput } from '../__generated__/globalTypes';

export enum UTM_SOURCES {
  TWITTER = 'twitter',
  WHATSAPP = 'whatsapp',
  FACEBOOK = 'facebook',
}
export enum UTM_CAMPAIGNS {
  LINEUP = 'sharing_lineup',
}
export enum UTM_MEDIUMS {
  SOCIAL = 'social',
}

export function getUtmTermFromSport(sport?: Sport): string {
  switch (sport) {
    case Sport.BASEBALL:
      return 'baseball';
    case Sport.NBA:
      return 'nba';
    default:
    case Sport.FOOTBALL:
      return 'football';
  }
}

type UtmParams = {
  url?: string;
  source?: UTM_SOURCES;
  content?: string;
  medium?: UTM_MEDIUMS;
  campaign?: UTM_CAMPAIGNS;
  term?: string;
  user?: string;
};

export const useUtmParams = () => {
  const getParams = useCallback(() => {
    const params: UtmInput = {
      utmCampaign: getValue('utm_campaign'),
      utmContent: getValue('utm_content'),
      utmMedium: getValue('utm_medium'),
      utmSource: getValue('utm_source'),
      utmTerm: getValue('utm_term'),
      trafficCategory: getValue('traffic_category'),
      partner: getValue('partner'),
      user: getValue('user'),
    };
    return params;
  }, []);

  const setParams = ({
    url,
    source,
    content,
    medium,
    term,
    campaign,
    user,
  }: UtmParams) => {
    if (!url) {
      return '';
    }
    const [baseUrl, queryParams] = url.split('?');
    const parsedQs = qs.parse(queryParams);
    return `${baseUrl}?${qs.stringify({
      ...parsedQs,
      utm_campaign: campaign,
      utm_source: source,
      utm_content: content,
      utm_medium: medium,
      utm_term: term,
      user,
    })}`;
  };

  return { getParams, setParams };
};
