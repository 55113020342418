import { TypedDocumentNode, gql } from '@apollo/client';

import {
  CurrentNBAUserProvider_onboardingState,
  CurrentNBAUserQuery,
  CurrentNBAUserQueryVariables,
} from './__generated__/queries.graphql';

export const onboardingState = gql`
  fragment CurrentNBAUserProvider_onboardingState on NBACurrentUserData {
    onboardingState {
      nextTask
    }
  }
` as TypedDocumentNode<CurrentNBAUserProvider_onboardingState>;

export const CURRENT_NBA_USER_QUERY = gql`
  query CurrentNBAUserQuery {
    currentUser {
      slug
      nickname
      # eslint-disable-next-line @graphql-eslint/no-deprecated
      nbaCurrentUserData {
        ...CurrentNBAUserProvider_onboardingState
      }
    }
  }
  ${onboardingState}
` as TypedDocumentNode<CurrentNBAUserQuery, CurrentNBAUserQueryVariables>;
