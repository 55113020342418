import { TypedDocumentNode, gql } from '@apollo/client';
import { defineMessages, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Tooltip } from 'atoms/tooltip/Tooltip';
import { useActivityIndicator } from 'hooks/users/useActivityIndicator';

import { ActivityIndicator_user } from './__generated__/index.graphql';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  --radius: var(--half-unit);
  --stroke: 1px;
  --gap: 2px;
  --offset: calc(var(--radius) / 2);
  --offset-right: var(--offset);
  --offset-bottom: var(--offset);
`;

const ChildrenWrapper = styled.div`
  mask-image: radial-gradient(
    circle at calc(100% - var(--offset-right)) calc(100% - var(--offset-bottom)),
    transparent,
    transparent calc(var(--radius) + var(--gap)),
    black calc(var(--radius) + var(--gap)),
    black
  );
`;

const TooltipStyled = styled(Tooltip)`
  display: inline-block;
  position: absolute;
  right: calc(-1 * (var(--radius) - var(--offset-right)));
  bottom: calc(-1 * (var(--radius) - var(--offset-bottom)));
`;

const ActivityIcon = styled.div<{ $active: boolean }>`
  background-color: var(--color);
  ${({ $active }) =>
    $active
      ? css`
          background-color: var(--c-green-600);
        `
      : css`
          border: var(--stroke) solid var(--c-neutral-500);
        `}

  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: var(--radius);
`;

const messages = defineMessages({
  online: {
    id: 'User.Header.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'User.Header.offline',
    defaultMessage: 'Offline',
  },
});

type Props = {
  user: ActivityIndicator_user;
  children: React.JSX.Element;
  disableClick?: React.ComponentProps<typeof Tooltip>['disableClick'];
  style?: React.CSSProperties;
};

export const ActivityIndicator = ({
  user,
  children,
  disableClick,
  style,
}: Props) => {
  const { formatMessage } = useIntl();
  const active = useActivityIndicator(user);

  if (active === null) {
    return children;
  }
  return (
    <Wrapper style={style}>
      <ChildrenWrapper>{children}</ChildrenWrapper>

      <TooltipStyled
        title={
          <>
            {user.status}
            {user.status && ' ('}
            {formatMessage(active ? messages.online : messages.offline)}
            {user.status && ')'}
          </>
        }
        placement="right"
        role="status"
        disableClick={disableClick}
      >
        <ActivityIcon $active={active} />
      </TooltipStyled>
    </Wrapper>
  );
};

ActivityIndicator.fragments = {
  user: gql`
    fragment ActivityIndicator_user on PublicUserInfoInterface {
      slug
      status
      ...useActivityIndicator_user
    }
    ${useActivityIndicator.fragments.user}
  ` as TypedDocumentNode<ActivityIndicator_user>,
};
