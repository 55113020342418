import { Navigate, RouteObject, useLocation } from 'react-router-dom';

import { catchAll } from '@sorare/routing';

import {
  ANY_SPORT_CARD_SHOW,
  FOOTBALL_PATH,
  MLB_PATH,
  NBA_PATH,
} from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { relative } from '@sorare/core/src/lib/routing';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';
import { RouteErrorBoundary } from '@sorare/core/src/routing/RouteErrorBoundary';

import { routeObjects as baseballPages } from '@sorare/baseball/src/pages';
import { routeObjects as blogPages } from '@sorare/blog/src/pages';
import { routeObjects as footballPages } from '@sorare/football/src/pages';
import { routeObjects as helpCenterPages } from '@sorare/help/src/pages';
import { routeObjects as nbaPages } from '@sorare/nba/src/pages';
import { routeObjects as sharedPages } from '@sorare/shared-pages/src/pages';
import { InnerAppLayout, OuterAppLayout } from 'AppLayout';
import { AnyCardPageRoute } from 'components/AnyCardPage';

const RedirectToSport = () => {
  const { sport } = useSportContext();
  const location = useLocation();
  return (
    <Navigate
      to={generateSportPath(location.pathname + location.search, {
        sport,
      })}
      replace
    />
  );
};

export const routeObjects: RouteObject[] = [
  {
    element: <OuterAppLayout />,
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        element: <InnerAppLayout />,
        children: [
          ...sharedPages,
          ...helpCenterPages,
          ...blogPages,
          {
            path: relative('/', catchAll(FOOTBALL_PATH)),
            children: footballPages,
          },
          {
            path: MLB_PATH,
            children: baseballPages,
          },
          {
            path: NBA_PATH,
            children: nbaPages,
          },
          {
            path: ANY_SPORT_CARD_SHOW,
            element: <AnyCardPageRoute />,
          },
          {
            path: '/*',
            element: <RedirectToSport />,
          },
        ],
      },
    ],
  },
];
