import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  ApproveAuthorizationRequests,
  ApproveMigrator,
  CreateWalletRecovery,
  Deal,
  LoadWallet,
  LogOut,
  Password,
  PasswordForgotten,
  PrivateKeyRecoveryPayload,
  Prompt,
  PromptDeposit,
  PromptRestoreWallet,
  PromptSignupWithUsername,
  RequestOAuth2,
  RequestResize,
  SettleDealSignatureType,
  SignEthMigration,
  SignLimitOrdersErrorCodes,
  SignMany,
  SignMigration,
  SignPaymentIntent,
  SignSettleDeal,
  SignTransfer,
  SignUpMobileView,
  SignWalletChallenge,
  Sport as WalletSport,
} from '@sorare/wallet-shared';
import { AuthorizationRequest } from '@sorare/wallet-shared/src/contexts/messaging/authorizations';
import { Sport } from '__generated__/globalTypes';
import useCheckPhoneNumberVerificationCode from 'components/user/VerifyPhoneNumber/useCheckPhoneNumberVerificationCode';
import {
  UpdateUserAttributes,
  UpdateUserEmailAttributes,
  useAuthContext,
} from 'contexts/auth';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSentryContext } from 'contexts/sentry';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import { WalletTab, useWalletDrawerContext } from 'contexts/walletDrawer';
import useLogOut from 'hooks/auth/useLogOut';
import { RecoveryOption } from 'hooks/recovery/useRecoveryOptions';
import { useWalletNeedsRecover } from 'hooks/recovery/useWalletNeedsRecover';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import useQueryString from 'hooks/useQueryString';
import useOAuthEthWallet from 'hooks/wallets/useOAuthEthWallet';
import { Side } from 'lib/deal';
import { useEvents } from 'lib/events/useEvents';

import WalletContextProvider, {
  OAuth2RequestHandler,
  Transfer,
  WalletPlaceHolderRequestedSize,
  WalletPlaceHolderResizeHandler,
  useMessagingContext,
} from '..';
import WalletAccessError from '../../../errors/walletAccess';
import usePromptResetPassword from './usePromptResetPassword';
import useUpdateUserEmail from './useUpdateUserEmail';

interface Props {
  children: ReactNode;
  setWindow: (window: Window | undefined) => void;
}

const SPORTS: Record<WalletSport, Sport> = {
  FOOTBALL: Sport.FOOTBALL,
  BASEBALL: Sport.BASEBALL,
  NBA: Sport.NBA,
};

const messages = defineMessages({
  confirmDevice: {
    id: 'user.useVerifyDevice',
    defaultMessage: 'Please verify your device first.',
  },
});

const Wallet = ({ children, setWindow }: Props) => {
  const { sendRequest, registerHandler } = useMessagingContext();
  const track = useEvents();
  const { formatMessage } = useIntl();
  const walletNeedsRecover = useWalletNeedsRecover();
  const { needsCreateEthWallet } = useOAuthEthWallet();
  const [latestPromptDimensions, updateLatestPromptDimensions] = useState<
    { promptType: string; size?: WalletPlaceHolderRequestedSize } | undefined
  >();
  const [dimensionsHandlers, updateDimensionsListener] = useState<
    WalletPlaceHolderResizeHandler[]
  >(() => []);
  const oauthHandlers = useRef<OAuth2RequestHandler[]>([]);
  const { updateUser } = useAuthContext();
  const logOutApp = useLogOut();
  const action = useQueryString('action');
  const [walletNode, setWalletNode] = useState<Element | null>(null);
  const [selectedRecoveryOption, setSelectedRecoveryOption] =
    useState<RecoveryOption | null>(null);
  const { showWallet, setCurrentTab, closeWalletAndDrawer, hideWallet } =
    useWalletDrawerContext();
  const { currentUser, refetch } = useCurrentUserContext();
  const { showNotification } = useSnackNotificationContext();
  const { sendSafeError } = useSentryContext();
  const updateUserEmail = useUpdateUserEmail();
  const checkPhoneNumberVerificationCode =
    useCheckPhoneNumberVerificationCode();
  const { isMobileApp, isAndroidApp, postMessage } = useIsMobileApp();

  const allow =
    currentUser?.confirmedDevice &&
    !walletNeedsRecover &&
    !needsCreateEthWallet;

  const prompt = useCallback(
    async (type: Prompt['request']['args']['type']) => {
      updateLatestPromptDimensions(prevValue => {
        // clear previously stored value if changing the prompt type
        if (prevValue?.promptType !== type) {
          return {
            promptType: type,
          };
        }
        return prevValue;
      });
      await sendRequest<Prompt>('prompt', { type });
    },
    [sendRequest]
  );

  const promptDeposit = useCallback(
    async (id: any) => {
      if (allow) {
        await sendRequest<PromptDeposit>('promptDeposit', { id });
        showWallet();
      }
    },
    [allow, sendRequest, showWallet]
  );

  const signIn = useCallback(async () => {
    if (isAndroidApp) {
      // Guard to prevent displaying login form from mobileApp
      postMessage('logout', { isExplicit: false });
    } else {
      prompt('signIn');
    }
  }, [isAndroidApp, postMessage, prompt]);

  const signUp = useCallback(
    async (username: string, sport: WalletSport) => {
      await sendRequest<PromptSignupWithUsername>('promptSignupWithUsername', {
        username,
        isAndroidApp,
        sport,
      });
    },
    [isAndroidApp, sendRequest]
  );

  const signUpMobileView = useCallback(async () => {
    await sendRequest<SignUpMobileView>('signUpMobileView', {
      isAndroidApp,
    });
  }, [isAndroidApp, sendRequest]);

  const passwordForgotten = useCallback(async () => {
    track('Click Password Forgotten');
    await sendRequest<PasswordForgotten>('passwordForgotten', {
      isMobileApp,
    });
  }, [isMobileApp, sendRequest, track]);

  const promptResetPassword = usePromptResetPassword();

  const logOut = useCallback(async () => {
    await sendRequest<LogOut>('logOut', {
      flushMessagingQueue: true,
    });
    logOutApp();
  }, [logOutApp, sendRequest]);

  const getPassword = useCallback<
    (requestArgs?: Password['request']['args']) => Promise<string | undefined>
  >(
    async (requestArgs = {}) => {
      showWallet();
      setCurrentTab(WalletTab.GET_PASSWORD);

      const {
        result: { passwordHash },
      } = await sendRequest<Password>('password', requestArgs);

      closeWalletAndDrawer();
      return passwordHash;
    },
    [closeWalletAndDrawer, sendRequest, showWallet, setCurrentTab]
  );

  const promptRestoreWallet = useCallback(
    async (
      privateKeyRecoveryPayloads: PrivateKeyRecoveryPayload[],
      recoveryOption?: RecoveryOption
    ) => {
      setCurrentTab(WalletTab.RESTORE_WALLET);
      setSelectedRecoveryOption(recoveryOption || null);
      showWallet();

      await sendRequest<PromptRestoreWallet>('promptRestoreWallet', {
        privateKeyRecoveryPayloads,
        privateKeyRecoveryPayload: privateKeyRecoveryPayloads[0],
      });
    },
    [sendRequest, showWallet, setCurrentTab]
  );

  const handleWalletSuccessullyRecovered = useCallback(async () => {
    await refetch();
    setCurrentTab(WalletTab.HOME);
    setSelectedRecoveryOption(null);
    showNotification('walletSuccessfullyRecovered');
    hideWallet();
  }, [refetch, setCurrentTab, showNotification, hideWallet]);

  const checkUserPhoneNumberVerificationCodeWithRecovery = useCallback(
    async (code: string): Promise<{ message: string }[] | null> => {
      if (!currentUser) throw new Error('Missing current user');
      if (!currentUser.unverifiedPhoneNumber)
        throw new Error('No pending phone verification');

      const { result, error } = await sendRequest<CreateWalletRecovery>(
        'createWalletRecovery',
        {
          recoveryMethod: 'phone',
          recoveryDestination: currentUser.unverifiedPhoneNumber,
        }
      );

      if (error) {
        return [
          {
            message: error,
          },
        ];
      }

      if (!result) {
        return [
          {
            message: formatMessage({
              id: 'updateUserEmailWithPassword.privateKeyGenerationError',
              defaultMessage: 'Unable to generate recovery key.',
            }),
          },
        ];
      }

      closeWalletAndDrawer();

      return checkPhoneNumberVerificationCode(code, result.privateKeyRecovery);
    },
    [
      formatMessage,
      sendRequest,
      checkPhoneNumberVerificationCode,
      currentUser,
      closeWalletAndDrawer,
    ]
  );

  const loadWallet = useCallback(async () => {
    if (!currentUser?.confirmedDevice) {
      showWallet();
      return {
        errors: [
          {
            message: formatMessage(messages.confirmDevice),
          },
        ],
      };
    }
    const { result, error } = await sendRequest<LoadWallet>('loadWallet', {});
    if (error) {
      return {
        errors: [{ message: error }],
      };
    }

    if (!result) {
      return {
        errors: [
          {
            message: formatMessage({
              id: 'updateUserWithPassword.walletNotUnlocked',
              defaultMessage: 'You must first unlock your wallet.',
            }),
          },
        ],
      };
    }
    closeWalletAndDrawer();
    return {};
  }, [
    formatMessage,
    sendRequest,
    closeWalletAndDrawer,
    currentUser?.confirmedDevice,
    showWallet,
  ]);

  const updateUserEmailWithPassword = useCallback(
    async (attributes: UpdateUserEmailAttributes) => {
      if (!currentUser) throw new Error('Missing current user');

      if (!attributes.email || attributes.email === currentUser.email) {
        return { errors: [] };
      }

      const { result, error } = await sendRequest<CreateWalletRecovery>(
        'createWalletRecovery',
        { recoveryMethod: 'email', recoveryDestination: attributes.email }
      );

      if (error) {
        return {
          errors: [
            {
              message: error,
            },
          ],
        };
      }

      if (!result) {
        return {
          errors: [
            {
              message: formatMessage({
                id: 'updateUserEmailWithPassword.privateKeyGenerationError',
                defaultMessage: 'Unable to generate recovery key.',
              }),
            },
          ],
        };
      }

      closeWalletAndDrawer();

      attributes.privateKeyRecovery = result.privateKeyRecovery;
      return updateUserEmail({ ...attributes });
    },
    [
      formatMessage,
      updateUserEmail,
      sendRequest,
      currentUser,
      closeWalletAndDrawer,
    ]
  );

  const updateUserWithPassword = useCallback(
    async (attributes: UpdateUserAttributes) => {
      if (!currentUser) throw new Error('Missing current user');
      const currentPasswordHash = await getPassword();
      if (currentPasswordHash) {
        return updateUser({ ...attributes, currentPasswordHash });
      }

      return {
        errors: [
          formatMessage({
            id: 'updateUserWithPassword.walletNotUnlocked',
            defaultMessage: 'You must first unlock your wallet.',
          }),
        ],
      };
    },
    [currentUser, getPassword, formatMessage, updateUser]
  );

  const signSettleDeal = useCallback(
    async (deal: Deal, actionType: SettleDealSignatureType) => {
      const { result } = await sendRequest<SignSettleDeal>('signSettleDeal', {
        deal,
        action: actionType,
      });

      closeWalletAndDrawer();
      if (!result) {
        throw new WalletAccessError();
      }
      return result.signature;
    },
    [closeWalletAndDrawer, sendRequest]
  );

  const signInternalTokensForDeal = useCallback(
    async (deal: Deal, side: Side) => {
      return signSettleDeal(
        deal,
        side === 'sender'
          ? SettleDealSignatureType.SendInternalTokens
          : SettleDealSignatureType.ReceiveInternalTokens
      );
    },
    [signSettleDeal]
  );

  const approveMigrator = useCallback(
    async (nonce: string | number) => {
      if (!allow) {
        showWallet();
        return undefined;
      }
      const { result } = await sendRequest<ApproveMigrator>('approveMigrator', {
        nonce,
      });

      closeWalletAndDrawer();
      return result;
    },
    [sendRequest, closeWalletAndDrawer, showWallet, allow]
  );

  const signMigration = useCallback(
    async (cardIds: string[], expirationBlock: number | string) => {
      if (!allow) {
        showWallet();
        return undefined;
      }
      const { result } = await sendRequest<SignMigration>('signMigration', {
        cardIds,
        expirationBlock,
      });

      closeWalletAndDrawer();
      return result?.signature;
    },
    [sendRequest, closeWalletAndDrawer, showWallet, allow]
  );

  const signEthMigration = useCallback(
    async (nonce: string, amount: bigint) => {
      if (!allow) {
        showWallet();
        return undefined;
      }
      const { result } = await sendRequest<SignEthMigration>(
        'signEthMigration',
        {
          dealId: nonce,
          sendAmountInWei: amount,
        }
      );

      closeWalletAndDrawer();
      return result?.signature;
    },
    [sendRequest, closeWalletAndDrawer, showWallet, allow]
  );

  const signTransfer = useCallback(
    async (transfer: Transfer) => {
      if (!allow) {
        showWallet();
        return null;
      }
      const { result } = await sendRequest<SignTransfer>('signTransfer', {
        transfer,
      });

      closeWalletAndDrawer();
      return result?.signature ? JSON.stringify(result.signature) : null;
    },
    [sendRequest, closeWalletAndDrawer, showWallet, allow]
  );

  const approveAuthorizationRequests = useCallback(
    async (authorizationRequests: AuthorizationRequest[]) => {
      if (!allow) {
        showWallet();
        return {
          approvals: undefined,
          starkKey: undefined,
        };
      }
      const { result, error } = await sendRequest<ApproveAuthorizationRequests>(
        'approveAuthorizationRequests',
        {
          authorizationRequests,
        }
      );

      closeWalletAndDrawer();
      if (error) {
        if (error.code !== SignLimitOrdersErrorCodes.PROMPT_CANCELED) {
          sendSafeError(error.message);
        }
        return {
          approvals: undefined,
          starkKey: undefined,
        };
      }
      return {
        authorizationApprovals: result?.authorizationApprovals,
        starkKey: result?.starkKey,
      };
    },
    [closeWalletAndDrawer, allow, sendRequest, sendSafeError, showWallet]
  );

  const signPaymentIntent = useCallback(
    async (id: string, amount: string) => {
      if (!allow) {
        showWallet();
        return null;
      }
      const { result } = await sendRequest<SignPaymentIntent>(
        'signPaymentIntent',
        {
          id,
          amount,
        }
      );

      closeWalletAndDrawer();
      return result?.signature ? JSON.stringify(result.signature) : null;
    },
    [sendRequest, closeWalletAndDrawer, showWallet, allow]
  );

  const signWalletChallenge = useCallback(
    async (challenge: string) => {
      if (!allow) {
        showWallet();
        return null;
      }
      const { result } = await sendRequest<SignWalletChallenge>(
        'signWalletChallenge',
        { challenge }
      );

      closeWalletAndDrawer();
      return result?.signature || null;
    },
    [sendRequest, closeWalletAndDrawer, showWallet, allow]
  );

  const signMany = useCallback(
    async (values: string[]) => {
      if (!allow) {
        showWallet();
        return null;
      }
      const { result } = await sendRequest<SignMany>('signMany', { values });

      closeWalletAndDrawer();
      return result?.signature || null;
    },
    [sendRequest, closeWalletAndDrawer, showWallet, allow]
  );

  useEffect(() => {
    if (action === 'signup') {
      if (isAndroidApp) {
        // Guard to prevent displaying login form from mobileApp
        postMessage('logout', { isExplicit: false });
      } else {
        prompt('signup');
      }
    } else if (action === 'signin') {
      signIn();
    }
  }, [action, signIn, postMessage, prompt, isAndroidApp]);

  useEffect(
    () =>
      registerHandler<RequestResize>(
        'requestPlaceholderResize',
        async dimensions => {
          updateLatestPromptDimensions(prevValue => {
            if (prevValue) {
              return {
                ...prevValue,
                size: dimensions,
              };
            }
            return prevValue;
          });
          return {};
        }
      ),
    [registerHandler]
  );

  useEffect(
    () =>
      registerHandler<RequestOAuth2>(
        'requestOAuth',
        async ({
          platform,
          sport,
          signup,
          nickname,
          acceptTerms,
          acceptAgeLimit,
          acceptToShareAll,
          acceptToShareSpecific,
        }) => {
          if (signup) {
            track('Click Signup By O Auth', { provider: platform });
          }
          await Promise.all(
            oauthHandlers.current.map(async handler =>
              handler(platform, {
                nickname,
                sport: sport && SPORTS[sport],
                acceptTerms,
                acceptAgeLimit,
                acceptToShareAll,
                acceptToShareSpecific,
              })
            )
          );
          return {};
        }
      ),
    [registerHandler, track]
  );

  const registerOnWalletResizeRequestHandler = useCallback((callback: any) => {
    updateDimensionsListener(listeners => {
      return [...listeners, callback];
    });
    return () => {
      updateDimensionsListener(listeners => {
        const index = listeners.indexOf(callback);
        if (index >= 0) {
          return [...listeners].splice(index, 1);
        }
        return listeners;
      });
    };
  }, []);

  const registerOAuthHandler = useCallback((callback: OAuth2RequestHandler) => {
    oauthHandlers.current.push(callback);
    return () => {
      const index = oauthHandlers.current.indexOf(callback);
      if (index >= 0) {
        oauthHandlers.current.splice(index, 1);
      }
    };
  }, []);

  useEffect(() => {
    const size = latestPromptDimensions?.size;
    if (size) {
      dimensionsHandlers.forEach(handler => handler(size));
    }
  }, [latestPromptDimensions, dimensionsHandlers]);

  useEffect(
    () =>
      registerHandler<PasswordForgotten>('passwordForgotten', async () => {
        return passwordForgotten().then(() => ({}));
      }),
    [registerHandler, passwordForgotten]
  );

  const value = useMemo(
    () => ({
      approveAuthorizationRequests,
      setWindow,
      signIn,
      signUp,
      signUpMobileView,
      passwordForgotten,
      promptResetPassword,
      logOut,
      loadWallet,
      prompt,
      promptDeposit,
      promptRestoreWallet,
      selectedRecoveryOption,
      handleWalletSuccessullyRecovered,
      getPassword,
      checkUserPhoneNumberVerificationCodeWithRecovery,
      updateUserEmailWithPassword,
      updateUserWithPassword,
      signSettleDeal,
      signInternalTokensForDeal,
      approveMigrator,
      signMigration,
      signEthMigration,
      signTransfer,
      signPaymentIntent,
      signWalletChallenge,
      signMany,
      walletNode,
      setWalletNode,
      setOnWalletResizeRequest: registerOnWalletResizeRequestHandler,
      registerOAuthHandler,
    }),
    [
      approveAuthorizationRequests,
      approveMigrator,
      checkUserPhoneNumberVerificationCodeWithRecovery,
      getPassword,
      handleWalletSuccessullyRecovered,
      logOut,
      loadWallet,
      passwordForgotten,
      prompt,
      promptDeposit,
      promptResetPassword,
      promptRestoreWallet,
      registerOAuthHandler,
      registerOnWalletResizeRequestHandler,
      selectedRecoveryOption,
      setWindow,
      signEthMigration,
      signIn,
      signInternalTokensForDeal,
      signMigration,
      signPaymentIntent,
      signSettleDeal,
      signTransfer,
      signUp,
      signUpMobileView,
      signWalletChallenge,
      signMany,
      updateUserEmailWithPassword,
      updateUserWithPassword,
      walletNode,
    ]
  );

  return (
    <WalletContextProvider value={value}>{children}</WalletContextProvider>
  );
};

export default Wallet;
