import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { HeadlineXL } from 'atoms/typography';
import { FRONTEND_ASSET_HOST } from 'constants/assets';

const Root = styled(Vertical).attrs({ gap: 0, center: true })`
  aspect-ratio: var(--card-aspect-ratio);
  width: 235px;
  max-width: 100%;
  justify-content: flex-end;
  position: relative;
`;

const Image = styled.img`
  aspect-ratio: var(--card-aspect-ratio);
  width: 100%;
  position: absolute;
  top: 0;
`;

const StyledHeadlineXL = styled(HeadlineXL)`
  position: absolute;
  bottom: 15%;
  z-index: 1;
  padding: var(--double-unit);
`;

const Box = styled.img`
  width: 50%;
  margin: 25% auto auto;
`;

type Props = {
  box: {
    name: string;
    pictureUrl: string;
  };
};

export const FrontBoxReward = ({ box }: Props) => {
  return (
    <Root as="article">
      <Image src={`${FRONTEND_ASSET_HOST}/cards/front/box.png`} alt="" />
      <Box src={box.pictureUrl} alt="" />
      <StyledHeadlineXL as="h1">{box.name}</StyledHeadlineXL>
    </Root>
  );
};
