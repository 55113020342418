const PATTERN = /^(?<content>(.+))\s+(?<lastWord>[^\s]+)$/;
export const UNBREAKABLE_SPACE = '\u00A0';
export const avoidOrphan = (text: string) => {
  return text.replace(PATTERN, `$<content>${UNBREAKABLE_SPACE}$<lastWord>`);
};

export type Font = `${number} ${number}px ${string}`;
export const getTextSize = (
  text: string,
  font: Font,
  letterSpacing: string = '0'
) => {
  if (import.meta.env.NODE_ENV === 'test') {
    return { width: 0, height: 0 };
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  context.font = font;

  context.letterSpacing = letterSpacing;

  const metrics = context.measureText(text);

  return {
    width: metrics.width,
    height: metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent,
  };
};
