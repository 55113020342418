import { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Sport } from '__generated__/globalTypes';
import { Button, Props as ButtonProps } from 'atoms/buttons/Button';
import useScreenSize from 'hooks/device/useScreenSize';
import { useEvents } from 'lib/events/useEvents';
import { glossary } from 'lib/glossary';

interface Props extends Omit<ButtonProps, 'title' | 'size'> {
  title?: string | ReactNode;
}

export const PlayNowButton = (props: Props) => {
  const { up: isTablet } = useScreenSize('tablet');
  const track = useEvents();
  const navigate = useNavigate();
  const { title, to, color, ...rest } = props;

  const trackClickPlayNow = useCallback(() => {
    track('Click Play Now', {
      sport: Sport.FOOTBALL,
    });
  }, [track]);

  const onClick = () => {
    trackClickPlayNow();
    if (to) navigate(to as string);
  };

  return (
    <Button
      color={color || 'secondary'}
      size={isTablet ? 'large' : 'medium'}
      onClick={onClick}
      {...rest}
    >
      {title || <FormattedMessage {...glossary.playNow} />}
    </Button>
  );
};
