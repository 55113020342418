import {
  ChangeEvent,
  KeyboardEvent,
  forwardRef,
  useCallback,
  useRef,
} from 'react';

// eslint-disable-next-line import/no-restricted-paths
import { useIntlContext } from 'contexts/intl';
import useRegexp from 'hooks/useRegexp';
import { mergeRefs } from 'lib/mergeRefs';

const parseValue = (val: string) => {
  const normalized = val.replace(/,/, '.');

  return Number.parseFloat(normalized) || 0;
};

export const DecimalInput = forwardRef<
  HTMLInputElement,
  {
    className?: string;
    value: string;
    placeholder?: string;
    disabled?: boolean;
    ariaLabel: string;
    onChange: (newValue: string, newFormattedValue: number) => void;
    autoFocus?: boolean;
    max: number;
    min?: number;
    step: string;
    increment?: number;
  }
>(
  (
    {
      value,
      className,
      placeholder,
      disabled,
      ariaLabel,
      onChange,
      autoFocus,
      max,
      min = 0,
      step,
      increment = Number(step) * 100,
    },
    ref
  ) => {
    const { formatNumber, formatMessage } = useIntlContext();
    const localRef = useRef<HTMLInputElement>(null);
    const decimals = step.toString().length - 2;
    const allowedRegexp = useRegexp(`^([0-9]+)?([\\.,][0-9]{0,${decimals}})?$`);

    const formattedValue = parseValue(value);

    const handleChange = useCallback(
      (newValue: string) => {
        if (!allowedRegexp.test(newValue)) return;

        const newFormattedValue = parseValue(newValue);

        onChange(newValue, newFormattedValue);

        if (newFormattedValue > max) {
          localRef.current!.setCustomValidity(
            formatMessage(
              {
                id: 'MonetaryInput.tooHigh',
                defaultMessage: 'Amount must be below {max}',
              },
              { max: formatNumber(max, { maximumFractionDigits: decimals }) }
            )
          );
        } else if (
          newFormattedValue < min &&
          !/[.,]0*$/.test(newValue) &&
          newValue.length > 0
        ) {
          localRef.current!.setCustomValidity(
            formatMessage(
              {
                id: 'MonetaryInput.tooLow',
                defaultMessage: 'Amount must be higher than {min}',
              },
              {
                min: formatNumber(min, {
                  maximumFractionDigits: decimals,
                }),
              }
            )
          );
        } else {
          localRef.current!.setCustomValidity('');
        }
        localRef.current!.reportValidity();
      },
      [allowedRegexp, formatMessage, max, min, decimals, onChange, formatNumber]
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
          const incr = event.key === 'ArrowUp' ? increment : -increment;
          const newValue = formatNumber(Math.max(min, formattedValue + incr), {
            maximumFractionDigits: decimals,
            useGrouping: false,
          });

          handleChange(newValue);
          event.preventDefault();
        }
      },
      [handleChange, increment, min, formattedValue, decimals, formatNumber]
    );

    const handleChangeEvent = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        handleChange(newValue);
      },
      [handleChange]
    );

    return (
      <input
        ref={mergeRefs(ref, localRef)}
        className={className}
        placeholder={placeholder}
        value={value}
        type="text"
        onChange={handleChangeEvent}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        aria-label={ariaLabel}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        inputMode="decimal"
      />
    );
  }
);
DecimalInput.displayName = 'DecimalInput';
