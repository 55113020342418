import { TypedDocumentNode, gql } from '@apollo/client';
import {
  faCalendarTimes,
  faCheck,
  faExclamationCircle,
  faHourglass,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  FiatWalletAccountState,
  SupportedCurrency,
  TokenOfferRejectionReason,
} from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Checkbox } from '@sorare/core/src/atoms/inputs/Checkbox';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyM, BodyS, Text14 } from '@sorare/core/src/atoms/typography';
import { MobileAppFeature } from '@sorare/core/src/components/BuyableFeature';
import { CreateFiatWallet } from '@sorare/core/src/components/fiatWallet/CreateFiatWallet';
import { UserName } from '@sorare/core/src/components/user/UserName';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import useToggle from '@sorare/core/src/hooks/useToggle';
import { useCashWalletKycEvents } from '@sorare/core/src/hooks/wallets/useCashWalletKycEvents';
import { useFiatBalance } from '@sorare/core/src/hooks/wallets/useFiatBalance';
import { glossary, mobileApp } from '@sorare/core/src/lib/glossary';
import { isType } from '@sorare/core/src/lib/gql';
import {
  EnterCashWalletKYCFlow_Source,
  EnterCashWalletKYCFlow_Target,
} from '@sorare/core/src/protos/events/platform/web/events';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import CounterOfferBuilder from 'components/directOffer/CounterOfferBuilder';
import { TokenTransferChildrenProps } from 'components/token/TokenTransferValidator/types';
import { useClickTradeEvent } from 'hooks/events/useClickTradeEvent';
import useCancelOffer from 'hooks/offers/useCancelOffer';
import useRejectOffer from 'hooks/offers/useRejectOffer';

import DirectOfferDialog, {
  Props as DirectOfferDialogProps,
} from '../DirectOfferDialog';
import OfferSummary from '../OfferSummary';
import { AcceptOfferDialog } from './AcceptOfferDialog';
import { OfferRejectionReason } from './OfferRejectionReason';
import { MySorareDirectOfferStatus_tokenOffer } from './__generated__/index.graphql';
import { useCheckOfferFairness } from './useCheckOfferFairness';

const messages = defineMessages({
  nothing: {
    id: 'DirectOfferStatus.nothing',
    defaultMessage: 'NOTHING',
  },
  counterOffer: {
    id: 'DirectOfferStatus.counterOffer',
    defaultMessage: 'Counter',
  },
  reject: {
    id: 'DirectOfferStatus.reject',
    defaultMessage: 'Reject',
  },
  block: {
    id: 'DirectOfferStatus.block',
    defaultMessage: 'Block',
  },
  accepted: {
    id: 'DirectOfferStatus.accepted',
    defaultMessage: 'Accepted',
  },
  creating: {
    id: 'DirectOfferStatus.creating',
    defaultMessage: 'Creating',
  },
  transferInProgress: {
    id: 'DirectOfferStatus.transferInProgress',
    defaultMessage: 'Transfer in Progress',
  },
  rejected: {
    id: 'DirectOfferStatus.rejected',
    defaultMessage: 'Rejected',
  },
  expired: {
    id: 'DirectOfferStatus.expired',
    defaultMessage: 'Expired',
  },
  confirmRejectTitle: {
    id: 'DirectOfferStatus.confirmReject.title',
    defaultMessage: 'Reject the offer',
  },
  confirmCancelTitle: {
    id: 'DirectOfferStatus.confirmCancel.title',
    defaultMessage: 'Cancel the offer',
  },
});

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: var(--double-unit);
  @media ${tabletAndAbove} {
    flex-direction: row;
  }
`;

const Layout = styled(Vertical).attrs({ gap: 2 })`
  width: 100%;
`;

const CanceledMessage = styled.div`
  color: var(--c-yellow-600);
  font-weight: var(--t-bold);
`;

const GreyMessage = styled.div`
  color: var(--c-neutral-600);
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: var(--double-unit);
`;

const CancelButtonWrapper = styled(Vertical)`
  align-items: flex-end;
`;

const OneSidedOffer = styled(Vertical)`
  align-items: flex-start;
  padding: var(--unit) var(--double-unit);
  border-radius: var(--unit);
  background-color: rgba(var(--c-rgb-red-600), 0.05);
  border: 1px solid var(--c-red-600);
`;

enum DirectOfferDialogType {
  REJECT_WITH_ANSWER = 'REJECT_WITH_ANSWER',
  REJECT = 'REJECT',
  CANCEL = 'CANCEL',
}

type Props = {
  offer: MySorareDirectOfferStatus_tokenOffer;
  isCurrentUserSender: boolean;
  validationLoading: boolean;
  refetch?: () => Promise<void>;
} & Pick<TokenTransferChildrenProps, 'validationMessages' | 'ConsentMessage'>;

const DirectOfferStatus = ({
  offer,
  isCurrentUserSender,
  validationMessages,
  ConsentMessage,
  validationLoading,
  refetch,
}: Props) => {
  const { trackEnter } = useCashWalletKycEvents();
  const [acceptUnfairOffer, setAcceptUnfairOffer] = useState(false);
  const [showUnfairOfferWarning, setShowUnfairOfferWarning] = useState(false);
  const { checkIsOfferUnfair } = useCheckOfferFairness({ offer });
  const oneSidedOffer = useMemo<boolean>(() => {
    if (
      isCurrentUserSender &&
      offer.receiverSide.cards.length === 0 &&
      !(offer.receiverSide.wei && offer.receiverSide.wei !== 0n)
    ) {
      return true;
    }
    if (
      offer.senderSide.cards.length === 0 &&
      !(offer.senderSide.wei && offer.senderSide.wei !== 0n)
    )
      return true;

    return false;
  }, [
    isCurrentUserSender,
    offer.receiverSide.cards.length,
    offer.receiverSide.wei,
    offer.senderSide.cards.length,
    offer.senderSide.wei,
  ]);
  const [showAcceptButton, setShowAcceptButton] = useState(!oneSidedOffer);

  const senderNickname = isType(offer.sender, 'User') ? (
    <UserName user={offer.sender} />
  ) : (
    'anonymous'
  );
  const receiverNickname = isType(offer.receiver, 'User') ? (
    <UserName user={offer.receiver} />
  ) : (
    'anonymous'
  );
  const counterpartUserNickname = isCurrentUserSender
    ? receiverNickname
    : senderNickname;
  const [needsCreateFiatWallet, setNeedsCreateFiatWallet] = useState(false);
  const [rejectionReason, setRejectionReason] = useState<
    TokenOfferRejectionReason | undefined
  >(undefined);
  const { canListAndTrade } = useFiatBalance();

  const { formatMessage } = useIntl();
  const trackClickTrade = useClickTradeEvent();
  const [submitting, toggleSubmitting] = useToggle(false);
  const { showNotification } = useSnackNotificationContext();
  const rejectOffer = useRejectOffer();
  const cancelOffer = useCancelOffer();
  const {
    currentUser,
    fiatCurrency: { code },
  } = useCurrentUserContext();
  const [counterOfferBuilderOpen, toggleCounterOfferBuilderOpen] =
    useToggle(false);
  const [acceptOfferDialogOpen, setAcceptOfferDialogOpen] = useState(false);

  const [directOfferDialogType, setDirectOfferDialogType] = useState<
    DirectOfferDialogType | false
  >(false);

  const settlementCurrency = useMemo(() => {
    const settlementCurrencyFromOffer =
      offer?.settlementCurrencies?.[0] || SupportedCurrency.WEI;
    if (settlementCurrencyFromOffer === SupportedCurrency.WEI)
      return SupportedCurrency.WEI;
    return code as SupportedCurrency;
  }, [code, offer?.settlementCurrencies]);

  const cta = async (
    action: () => Promise<unknown>,
    message: 'directOfferCancelled' | 'directOfferRejected'
  ) => {
    toggleSubmitting();
    const errors = await action();
    if (!errors) {
      showNotification(message);
    }
    toggleSubmitting();
    setDirectOfferDialogType(false);
  };

  const counterOfferTo = useMemo(() => {
    if (isCurrentUserSender) {
      if (offer.receiver && isType(offer.receiver, 'User')) {
        return offer.receiver;
      }
    } else if (offer.sender && isType(offer.sender, 'User')) {
      return offer.sender;
    }

    return null;
  }, [isCurrentUserSender, offer.receiver, offer.sender]);

  const canAddRejectReason = useMemo(() => {
    if (!isCurrentUserSender && offer.receiverSide.wei !== 0n) return false;
    return true;
  }, [isCurrentUserSender, offer.receiverSide.wei]);

  const rejectionReasonFilters = useMemo(() => {
    const filters: TokenOfferRejectionReason[] = [];
    if (
      (offer.receiverSide.wei !== 0n && !offer.receiverSide.cards.length) ||
      (offer.senderSide.wei !== 0n && !offer.senderSide.cards.length)
    ) {
      filters.push(TokenOfferRejectionReason.ONLY_CASH);
    }
    if (
      (isCurrentUserSender && !offer.receiverSide.cards.length) ||
      (!isCurrentUserSender && !offer.senderSide.cards.length)
    ) {
      filters.push(TokenOfferRejectionReason.CARD_NOT_WANTED);
    }
    return filters;
  }, [
    isCurrentUserSender,
    offer.receiverSide.cards.length,
    offer.receiverSide.wei,
    offer.senderSide.cards.length,
    offer.senderSide.wei,
  ]);

  const { status, blockchainId } = offer;

  if (!blockchainId) return null;

  const reject = ({
    block,
    rejectionReason: r,
  }: {
    block: boolean;
    rejectionReason?: TokenOfferRejectionReason;
  }) => {
    cta(
      async () => rejectOffer({ blockchainId, block, rejectionReason: r }),
      'directOfferRejected'
    );
  };

  const cancel = () => {
    cta(async () => cancelOffer(blockchainId), 'directOfferCancelled');
  };

  const confirmReject = () => {
    if (canAddRejectReason)
      setDirectOfferDialogType(DirectOfferDialogType.REJECT_WITH_ANSWER);
    else setDirectOfferDialogType(DirectOfferDialogType.REJECT);
  };

  const directOfferDialogProps: Record<
    DirectOfferDialogType,
    Omit<DirectOfferDialogProps, 'onClose'>
  > = {
    [DirectOfferDialogType.REJECT_WITH_ANSWER]: {
      title: formatMessage(messages.confirmRejectTitle),
      content: (
        <OfferRejectionReason
          nickname={counterpartUserNickname}
          filters={rejectionReasonFilters}
          onChange={a => {
            return setRejectionReason(a);
          }}
        />
      ),
      actions: (
        <Button
          size="medium"
          color="red"
          onClick={() => reject({ block: false, rejectionReason })}
        >
          <FormattedMessage {...messages.reject} />
        </Button>
      ),
      maxWidth: 'xs',
      fullWidth: true,
    },
    [DirectOfferDialogType.REJECT]: {
      title: formatMessage(messages.confirmRejectTitle),
      content: <OfferSummary offer={offer} />,
      actions: (
        <Button
          size="medium"
          color="red"
          onClick={() => reject({ block: false })}
        >
          <FormattedMessage {...messages.reject} />
        </Button>
      ),
    },
    [DirectOfferDialogType.CANCEL]: {
      title: formatMessage(messages.confirmCancelTitle),
      content: <OfferSummary offer={offer} />,
      actions: (
        <Button size="medium" color="red" onClick={cancel}>
          <FormattedMessage {...glossary.cancel} />
        </Button>
      ),
    },
  };

  const openCounterOfferBuilder = async () => {
    if (!acceptUnfairOffer && !oneSidedOffer) {
      if (await checkIsOfferUnfair()) {
        setShowUnfairOfferWarning(true);
        return;
      }
    }
    toggleCounterOfferBuilderOpen();
    trackClickTrade(offer.id);
  };

  const renderSentOffer = () => {
    return (
      <CancelButtonWrapper>
        <Button
          onClick={() => setDirectOfferDialogType(DirectOfferDialogType.CANCEL)}
          color="red"
          size="medium"
        >
          {formatMessage(glossary.cancel)}
        </Button>
        <MobileAppFeature>
          <BodyS className="text-right" color="var(--c-neutral-700)" as="p">
            <FormattedMessage {...mobileApp.startTrading} />
          </BodyS>
        </MobileAppFeature>
      </CancelButtonWrapper>
    );
  };

  const renderMintedOffer = () => {
    if (isCurrentUserSender) {
      return renderSentOffer();
    }

    return (
      <Layout>
        {showUnfairOfferWarning && (
          <OneSidedOffer>
            <BodyM color="var(--c-red-600)">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                color="var(--c-red-600)"
              />{' '}
              <FormattedMessage
                id="DirectOfferStatus.unfairOfferWarning"
                defaultMessage="Please verify the value of the trade before interacting with it!"
              />
            </BodyM>
            <Checkbox
              label={
                <BodyM color="var(--c-neutral-1000)">
                  <FormattedMessage
                    id="DirectOfferStatus.checkboxLabel"
                    defaultMessage="I verified the trade and I want to continue"
                  />
                </BodyM>
              }
              checked={acceptUnfairOffer}
              onChange={() => setAcceptUnfairOffer(!acceptUnfairOffer)}
            />
          </OneSidedOffer>
        )}
        {oneSidedOffer && (
          <OneSidedOffer>
            <Text14>
              <FormattedMessage
                id="DirectOfferStatus.oneSided"
                defaultMessage={`⚠️ This is a one sided offer, you are about to send something to a manager named "{nickname}" without anything in return, be careful.`}
                values={{
                  nickname: <em>{counterpartUserNickname}</em>,
                }}
              />
            </Text14>

            <Button
              size="small"
              color="secondary"
              onClick={() => setShowAcceptButton(true)}
            >
              <FormattedMessage
                id="DirectOfferStatus.confirm"
                defaultMessage="I want to accept anyway"
              />
            </Button>
          </OneSidedOffer>
        )}
        <Container>
          {showAcceptButton && (
            <Button
              disabled={showUnfairOfferWarning && !acceptUnfairOffer}
              onClick={async () => {
                if (
                  settlementCurrency !== SupportedCurrency.WEI &&
                  !canListAndTrade
                ) {
                  setNeedsCreateFiatWallet(true);
                  trackEnter({
                    source: EnterCashWalletKYCFlow_Source.ACCEPT_TRADE_OFFER,
                    target: EnterCashWalletKYCFlow_Target.OWNER,
                  });
                  return;
                }
                if (!acceptUnfairOffer && !oneSidedOffer) {
                  if (await checkIsOfferUnfair()) {
                    setShowUnfairOfferWarning(true);
                    return;
                  }
                }
                setAcceptOfferDialogOpen(true);
              }}
              color="primary"
              size="medium"
            >
              <FormattedMessage {...glossary.accept} />
            </Button>
          )}
          {counterOfferTo && currentUser && (
            <>
              <Button
                onClick={openCounterOfferBuilder}
                disabled={
                  (showUnfairOfferWarning && !acceptUnfairOffer) || submitting
                }
                color="quaternary"
                size="medium"
              >
                {formatMessage(messages.counterOffer)}
              </Button>
              {counterOfferBuilderOpen && (
                <CounterOfferBuilder
                  onClose={toggleCounterOfferBuilderOpen}
                  to={counterOfferTo}
                  previousOffer={offer}
                  currentUser={currentUser}
                />
              )}
            </>
          )}
          <Button onClick={confirmReject} color="red" size="medium">
            {formatMessage(messages.reject)}
          </Button>
        </Container>
        <MobileAppFeature>
          <BodyS className="text-right" color="var(--c-neutral-700)" as="p">
            <FormattedMessage {...mobileApp.startTrading} />
          </BodyS>
        </MobileAppFeature>
      </Layout>
    );
  };

  const renderOfferStatus = () => {
    switch (status) {
      case 'minted': {
        return (
          <GreyMessage>
            <FormattedMessage {...messages.creating} />
            <StyledFontAwesomeIcon color="green" icon={faHourglass} />
          </GreyMessage>
        );
      }
      case 'opened': {
        return renderMintedOffer();
      }
      case 'accepted': {
        return (
          <GreyMessage>
            <FormattedMessage {...messages.accepted} />
            <StyledFontAwesomeIcon color="green" icon={faCheck} />
          </GreyMessage>
        );
      }
      case 'pending_migration':
      case 'settlable': {
        return (
          <CanceledMessage>
            <FormattedMessage {...messages.transferInProgress} />
          </CanceledMessage>
        );
      }
      case 'flagged':
      case 'cancelled': {
        return (
          <GreyMessage>
            <FormattedMessage {...glossary.canceled} />
            <StyledFontAwesomeIcon color="red" icon={faTimes} />
          </GreyMessage>
        );
      }
      case 'rejected': {
        return (
          <GreyMessage>
            <FormattedMessage {...messages.rejected} />
            <StyledFontAwesomeIcon color="red" icon={faTimes} />
          </GreyMessage>
        );
      }
      case 'ended': {
        return (
          <GreyMessage>
            <FormattedMessage {...messages.expired} />
            <StyledFontAwesomeIcon icon={faCalendarTimes} />
          </GreyMessage>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Container>
      {renderOfferStatus()}
      {directOfferDialogType && (
        <DirectOfferDialog
          onClose={() => setDirectOfferDialogType(false)}
          {...directOfferDialogProps[directOfferDialogType]}
        />
      )}
      {acceptOfferDialogOpen && (
        <AcceptOfferDialog
          offer={offer}
          onClose={() => setAcceptOfferDialogOpen(false)}
          isCurrentUserSender={isCurrentUserSender}
          validationLoading={validationLoading}
          validationMessages={validationMessages}
          ConsentMessage={ConsentMessage}
          settlementCurrency={settlementCurrency}
        />
      )}
      {needsCreateFiatWallet && (
        <CreateFiatWallet
          onClose={() => setNeedsCreateFiatWallet(false)}
          onDismissActivationSuccess={() => {
            setNeedsCreateFiatWallet(false);
            setAcceptOfferDialogOpen(true);
          }}
          canDismissAfterActivation
          onDeclarativeFormSuccess={() => {
            if (refetch) refetch();
          }}
          statusTarget={FiatWalletAccountState.OWNER}
        />
      )}
    </Container>
  );
};

DirectOfferStatus.fragments = {
  tokenOffer: gql`
    fragment MySorareDirectOfferStatus_tokenOffer on TokenOffer {
      id
      blockchainId
      status
      settlementCurrencies
      senderSide {
        id
        wei
        cards: anyCards {
          slug
        }
      }
      receiverSide {
        id
        wei
        cards: anyCards {
          slug
        }
      }
      sender {
        ... on User {
          slug
          ...UserName_publicUserInfoInterface
        }
      }
      receiver {
        ... on User {
          slug
          ...UserName_publicUserInfoInterface
        }
      }
      ...OfferSummary_tokenOffer
      ...CounterOfferBuilder_tokenOffer
      ...AcceptOfferDialog_tokenOffer
      ...useCheckOfferFairness_tokenOffer
    }
    ${OfferSummary.fragments.tokenOffer}
    ${CounterOfferBuilder.fragments.tokenOffer}
    ${AcceptOfferDialog.fragments.tokenOffer}
    ${useCheckOfferFairness.fragments.tokenOffer}
    ${UserName.fragments.user}
  ` as TypedDocumentNode<MySorareDirectOfferStatus_tokenOffer>,
};

export default DirectOfferStatus;
