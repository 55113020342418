import { ReactNode, useMemo, useState } from 'react';
import { Params, matchPath, useLocation } from 'react-router-dom';

import { catchAll } from '@sorare/routing';

import { Sport } from '__generated__/globalTypes';
import { MLB_PATH, NBA_PATH } from 'constants/routes';
import { STORAGE } from 'hooks/useLocalStorage';
import {
  generateSportPath,
  matchAnySportPath,
} from 'lib/routing/generateSportPath';
import { local as localStorage } from 'lib/storage';

import SportContextProvider from '.';

interface Props {
  children: ReactNode;
}

const sportFromPathAndLocalStorage = (path: string) => {
  if (matchPath(catchAll(NBA_PATH), path)) {
    return Sport.NBA;
  }
  if (matchPath(catchAll(MLB_PATH), path)) {
    return Sport.BASEBALL;
  }
  if (matchPath('/', path)) {
    switch (localStorage.getItem(STORAGE.lastVisitedSport)) {
      case 'NBA':
        return Sport.NBA;
      case 'BASEBALL':
        return Sport.BASEBALL;
      case 'FOOTBALL':
        return Sport.FOOTBALL;
    }
  }
  return Sport.FOOTBALL;
};

const SportProvider = ({ children }: Props) => {
  const { pathname } = useLocation();
  const [sport, setSport] = useState<Sport>(
    sportFromPathAndLocalStorage(pathname)
  );

  const value = useMemo(
    () => ({
      sport,
      setSport,
      // wrap the `generateSportPath` to handle the default sport
      generateSportContextPath: (
        path: string,
        { params, sport: s = sport }: { params?: Params; sport?: Sport } = {}
      ) => generateSportPath(path, { params, sport: s }),
      // eslint-disable-next-line @typescript-eslint/no-shadow
      matchAnySportPath: (pattern: string, pathname: string, s?: Sport) =>
        matchAnySportPath(pattern, pathname, s || sport),
    }),
    [sport]
  );

  return <SportContextProvider value={value}>{children}</SportContextProvider>;
};

export default SportProvider;
