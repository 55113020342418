import { useMemo } from 'react';

import { EnabledWallet } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';

export const useSettlableWalletsSearch = () => {
  const { currentUser } = useCurrentUserContext();
  return useMemo(() => {
    const enabledWallets = currentUser?.profile?.enabledWallets;
    if (enabledWallets) {
      if (enabledWallets.length === 1) {
        if (enabledWallets[0] === EnabledWallet.FIAT) {
          // if the user only configured their FIAT wallet, only display them cards they can settle in FIAT
          return {
            filter: 'sale.settlement_wallets:fiat',
            params: 'fiat',
          };
        }
        if (enabledWallets[0] === EnabledWallet.ETH) {
          // if the user only configured their ETH wallet, still show them FIAT-only cards,
          // they might want to pay with by CC
        }
      }
    }

    // no filter means all cards
    return {
      filter: null,
      params: null,
    };
  }, [currentUser?.profile?.enabledWallets]);
};
