import { css } from 'styled-components';

export const setCurrentBackgroundColor = (color: string) => css`
  --current-background-color: ${color};
  background: var(--current-background-color);
`;

export const getCurrentBackgroundColor = () => css`
  background: var(--current-background-color);
`;
