import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { DialogKey } from 'components/navigation/WithDialogs';

export const useGenerateDialogPath = () => {
  const location = useLocation();
  return (
    dialogId: DialogKey,
    dialogParam: string,
    opts?: { basePath?: string; additionalParams?: Record<string, string> }
  ) =>
    `${opts?.basePath || location.pathname}${qs.stringify(
      {
        ...qs.parse(location.search, {
          ignoreQueryPrefix: true,
        }),
        [`${dialogId}`]: dialogParam,
        ...opts?.additionalParams,
      },
      {
        addQueryPrefix: true,
      }
    )}`;
};
