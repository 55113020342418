export const useGetCardsDetails = () => {
  const getCardsDetails = <T extends { assetId: string; slug: string }>(
    offer: {
      senderSide: {
        cards: T[];
      };
      receiverSide: {
        cards: T[];
      };
    } | null,
    isCurrentUserSender: boolean
  ): { sendCards: T[]; receivedCards: T[] } => {
    if (!offer) return { sendCards: [], receivedCards: [] };
    const { senderSide, receiverSide } = offer;
    const sendCards = isCurrentUserSender
      ? senderSide?.cards
      : receiverSide?.cards;

    const receivedCards = isCurrentUserSender
      ? receiverSide?.cards
      : senderSide?.cards;

    return { sendCards, receivedCards };
  };
  return getCardsDetails;
};
