import {
  BaseballPlayerLineupPosition,
  Position,
} from '@sorare/core/src/__generated__/globalTypes';

export type IndexedPosition =
  | 'Starting pitcher'
  | 'Relief pitcher'
  | 'First base'
  | 'Third base'
  | 'Designated hitter'
  | 'Catcher'
  | 'Second base'
  | 'Shortstop'
  | 'Outfield';

export const MAPPING: Record<IndexedPosition, BaseballPlayerLineupPosition> = {
  'Starting pitcher': BaseballPlayerLineupPosition.STARTING_PITCHER,
  'Relief pitcher': BaseballPlayerLineupPosition.RELIEF_PITCHER,
  'First base': BaseballPlayerLineupPosition.CORNER_INFIELDER,
  'Third base': BaseballPlayerLineupPosition.CORNER_INFIELDER,
  'Designated hitter': BaseballPlayerLineupPosition.CORNER_INFIELDER,
  Catcher: BaseballPlayerLineupPosition.MIDDLE_INFIELDER,
  'Second base': BaseballPlayerLineupPosition.MIDDLE_INFIELDER,
  Shortstop: BaseballPlayerLineupPosition.MIDDLE_INFIELDER,
  Outfield: BaseballPlayerLineupPosition.OUTFIELDER,
};

export const REVERSE_MAPPING: Record<
  BaseballPlayerLineupPosition,
  IndexedPosition[]
> = {
  [BaseballPlayerLineupPosition.STARTING_PITCHER]: ['Starting pitcher'],
  [BaseballPlayerLineupPosition.RELIEF_PITCHER]: ['Relief pitcher'],
  [BaseballPlayerLineupPosition.CORNER_INFIELDER]: [
    'First base',
    'Third base',
    'Designated hitter',
  ],
  [BaseballPlayerLineupPosition.MIDDLE_INFIELDER]: [
    'Catcher',
    'Second base',
    'Shortstop',
  ],
  [BaseballPlayerLineupPosition.OUTFIELDER]: ['Outfield'],
};

export const positions = [
  BaseballPlayerLineupPosition.STARTING_PITCHER,
  BaseballPlayerLineupPosition.RELIEF_PITCHER,
  BaseballPlayerLineupPosition.CORNER_INFIELDER,
  BaseballPlayerLineupPosition.MIDDLE_INFIELDER,
  BaseballPlayerLineupPosition.OUTFIELDER,
];

export const POSITIONS_MAPPING: Record<string, BaseballPlayerLineupPosition> = {
  [Position.baseball_starting_pitcher]:
    BaseballPlayerLineupPosition.STARTING_PITCHER,
  [Position.baseball_relief_pitcher]:
    BaseballPlayerLineupPosition.RELIEF_PITCHER,
  [Position.baseball_first_base]: BaseballPlayerLineupPosition.CORNER_INFIELDER,
  [Position.baseball_third_base]: BaseballPlayerLineupPosition.CORNER_INFIELDER,
  [Position.baseball_designated_hitter]:
    BaseballPlayerLineupPosition.CORNER_INFIELDER,
  [Position.baseball_catcher]: BaseballPlayerLineupPosition.MIDDLE_INFIELDER,
  [Position.baseball_second_base]:
    BaseballPlayerLineupPosition.MIDDLE_INFIELDER,
  [Position.baseball_shortstop]: BaseballPlayerLineupPosition.MIDDLE_INFIELDER,
  [Position.baseball_outfield]: BaseballPlayerLineupPosition.OUTFIELDER,
};
