import { TypedDocumentNode, gql } from '@apollo/client';
import { defineMessages } from 'react-intl';

import { useTokenBelongsToUser } from '@sorare/core/src/hooks/useTokenBelongsToUser';

import { useCannotBuy_anyCard } from './__generated__/useCannotBuy.graphql';
import useCannotTrade from './useCannotTrade';
import useCannotTransfer from './useCannotTransfer';

const messages = defineMessages({
  doesBelongToYou: {
    id: 'useCannotSell.doesBelongToYou',
    defaultMessage: 'This Card belongs to you',
  },
});

const useCannotBuy = () => {
  const belongsToUser = useTokenBelongsToUser();
  const cannotTrade = useCannotTrade();
  const cannotTransfer = useCannotTransfer();

  return (card: useCannotBuy_anyCard) => {
    if (cannotTrade()) return cannotTrade();
    if (cannotTransfer(card)) return cannotTransfer(card);
    if (belongsToUser(card)) return messages.doesBelongToYou;

    return null;
  };
};

useCannotBuy.fragments = {
  anyCard: gql`
    fragment useCannotBuy_anyCard on AnyCardInterface {
      slug
      tradeableStatus
      ...useTokenBelongsToUser_anyCard
    }
    ${useTokenBelongsToUser.fragments.anyCard}
  ` as TypedDocumentNode<useCannotBuy_anyCard>,
};

export default useCannotBuy;
