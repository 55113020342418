import { Color } from '@sorare/core/src/style/types';

const Reward = ({ color }: { color?: Color }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 2H14V0.75C14 0.34375 13.6562 0 13.25 0H4.75C4.3125 0 4 0.34375 4 0.75V2H0.75C0.3125 2 0 2.34375 0 2.75V4.5C0 5.625 0.6875 6.78125 1.90625 7.65625C2.90625 8.375 4.09375 8.8125 5.34375 8.96875C6.34375 10.5938 7.5 11.25 7.5 11.25V13.5H6C4.875 13.5 4 14.1562 4 15.25V15.625C4 15.8438 4.15625 16 4.375 16H13.625C13.8125 16 14 15.8438 14 15.625V15.25C14 14.1562 13.0938 13.5 12 13.5H10.5V11.25C10.5 11.25 11.625 10.5938 12.625 8.96875C13.875 8.8125 15.0625 8.375 16.0625 7.65625C17.2812 6.78125 18 5.625 18 4.5V2.75C18 2.34375 17.6562 2 17.25 2ZM3.09375 6.03125C2.3125 5.5 2 4.875 2 4.5V4H4C4.03125 5.03125 4.1875 5.9375 4.40625 6.71875C3.90625 6.53125 3.46875 6.3125 3.09375 6.03125ZM16 4.5C16 5.03125 15.4375 5.65625 14.875 6.03125C14.5 6.3125 14.0625 6.53125 13.5625 6.71875C13.7812 5.9375 13.9375 5.03125 13.9688 4H16V4.5Z"
        fill={color || 'var(--c-yellow-600)'}
      />
    </svg>
  );
};

export default Reward;
