import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  ConversionCreditCampaignStatus,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import {
  FOOTBALL_BESTOFEUROPE,
  LANDING,
} from '@sorare/core/src/constants/__generated__/routes';
import { LANDING_BY_SPORT } from '@sorare/core/src/constants/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import {
  SESSION_STORAGE,
  useSessionStorage,
} from '@sorare/core/src/hooks/useSessionStorage';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import {
  CampaignConversionCreditQuery,
  CampaignConversionCreditQueryVariables,
} from './__generated__/page.graphql';

const CAMPAIGN_CONVERSION_CREDIT_QUERY = gql`
  query CampaignConversionCreditQuery($code: String!) {
    tokens {
      conversionCreditCampaign(code: $code) {
        id
        sport
        maxDiscount {
          ...MonetaryAmountFragment_monetaryAmount
        }
        status
        percentageDiscount
        endDate
        campaignCode
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<
  CampaignConversionCreditQuery,
  CampaignConversionCreditQueryVariables
>;

const PromoSignupPage = () => {
  const { currentUser } = useCurrentUserContext();
  const [, setSignupPromo] = useSessionStorage(SESSION_STORAGE.signupPromo);
  const { code } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { data } = useQuery(CAMPAIGN_CONVERSION_CREDIT_QUERY, {
    variables: { code: code! },
    skip: !code,
  });

  useEffect(() => {
    if (!currentUser && data) {
      const campaign = data.tokens.conversionCreditCampaign;
      if (
        campaign?.sport &&
        campaign?.status === ConversionCreditCampaignStatus.ACTIVE
      ) {
        setSignupPromo({
          ...campaign,
          endDate: campaign.endDate.toISOString(),
        });
        // Temporary redirect to the best of Europe page for one defined campaign code
        if (
          campaign.sport === Sport.FOOTBALL &&
          campaign.campaignCode === 'BESTOF'
        ) {
          navigate(`${FOOTBALL_BESTOFEUROPE}${search}`);
        } else {
          navigate(`${LANDING_BY_SPORT[campaign.sport]}${search}`);
        }
      } else {
        navigate(LANDING, { replace: true });
      }
    }
  }, [currentUser, data, navigate, search, setSignupPromo]);

  if (currentUser || !code) {
    return <Navigate to={LANDING} replace />;
  }

  return null;
};

export default PromoSignupPage;
