import styled, { css } from 'styled-components';

export const ellipsis = css`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
`;

export const Ellipsis = styled.span`
  ${ellipsis}
`;
