import { ReactNode, Suspense } from 'react';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { lazy } from '@sorare/core/src/lib/retry';

const BlockchainProviders = lazy(async () => import('.'));

export const LazyBlockchainProviders = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Suspense fallback={<LoadingIndicator fullScreen />}>
      <BlockchainProviders>{children}</BlockchainProviders>
    </Suspense>
  );
};
