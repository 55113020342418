interface Props {
  className?: string;
  color?: string;
}

const Transfer = ({ className, color = 'var(--c-brand-300)' }: Props) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 4.25C0 4.6875 0.3125 5 0.75 5H12.6562L11.7188 5.875C11.4062 6.1875 11.4062 6.65625 11.7188 6.96875L12.0312 7.28125C12.3438 7.59375 12.8125 7.59375 13.0938 7.28125L15.6875 4.71875C16.0938 4.34375 16.0938 3.6875 15.6875 3.3125L13.0938 0.75C12.8125 0.4375 12.3438 0.4375 12.0312 0.75L11.7188 1.0625C11.4062 1.375 11.4062 1.84375 11.7188 2.15625L12.6562 3H0.75C0.3125 3 0 3.34375 0 3.75V4.25ZM15.25 9H3.3125L4.25 8.15625C4.5625 7.84375 4.5625 7.375 4.25 7.0625L3.9375 6.75C3.625 6.4375 3.15625 6.4375 2.875 6.75L0.28125 9.3125C-0.125 9.6875 -0.125 10.3438 0.28125 10.7188L2.875 13.2812C3.15625 13.5938 3.625 13.5938 3.9375 13.2812L4.25 12.9688C4.5625 12.6562 4.5625 12.1875 4.25 11.875L3.3125 11H15.25C15.6562 11 16 10.6875 16 10.25V9.75C16 9.34375 15.6562 9 15.25 9Z"
        fill={color}
      />
    </svg>
  );
};

export default Transfer;
