import { useState } from 'react';

import {
  ProofOfIdStep,
  ProofOfIdentityForm,
} from 'components/fiatWallet/ProofOfIdentityForm';
import { GraphQLResult } from 'components/form/Form';
import { FileWithDataURL } from 'components/form/UploadFile/useUploadFile';
import { useUploadWithPresign } from 'hooks/useUploadWithPresign';

import { CreateFiatWalletSteps } from '../type';
import { useBackButtonTargets } from '../useBackButtonTargets';
import { useValidateFiatWallet } from './useValidateFiatWallet';

type Props = {
  currentStep: CreateFiatWalletSteps;
  setStep: (step: CreateFiatWalletSteps) => void;
};

const ProofOfIdStepToCreateFiatWalletStep = {
  [ProofOfIdStep.CHOOSE_DOCUMENT]: CreateFiatWalletSteps.CHOOSE_DOCUMENT,
  [ProofOfIdStep.UPLOAD]: CreateFiatWalletSteps.UPLOAD,
};

const UploadAndSubmitProofOfIdentity = ({ setStep, currentStep }: Props) => {
  const [frontPage, setFrontPage] = useState<FileWithDataURL>();
  const [backPage, setBackPage] = useState<FileWithDataURL>();
  const uploadWithPresign = useUploadWithPresign();
  const { validateFiatWallet } = useValidateFiatWallet();
  const backTargets = useBackButtonTargets({});
  const backTarget = backTargets[currentStep];

  const onBack = () => {
    if (!backTarget) return;
    setStep(backTarget);
  };
  const onSuccess = () => {
    setStep(CreateFiatWalletSteps.DOCUMENT_UNDER_REVIEW);
  };

  const onSubmit = async (onResult: (result: GraphQLResult) => void) => {
    if (!frontPage) return;

    const presignPromises = [
      uploadWithPresign(frontPage.file),
      ...(backPage ? [uploadWithPresign(backPage.file)] : []),
    ];
    const presignedKeys = await Promise.all(presignPromises);

    if (presignedKeys.some(key => !key)) {
      return;
    }

    const result = await validateFiatWallet({
      frontPagePresignedKey: presignedKeys[0] as string,
      ...(presignedKeys.length > 1
        ? { backPagePresignedKey: presignedKeys[1] }
        : {}),
    });
    onResult(result);
  };

  const onStepChange = (newStep: ProofOfIdStep) => {
    setStep(ProofOfIdStepToCreateFiatWalletStep[newStep]);
  };

  return (
    <ProofOfIdentityForm
      frontPage={frontPage}
      setFrontPage={setFrontPage}
      backPage={backPage}
      setBackPage={setBackPage}
      onBack={onBack}
      onSubmit={onSubmit}
      onStepChange={onStepChange}
      onSuccess={onSuccess}
      cotrolledUploadProofOfIdStep={
        currentStep === CreateFiatWalletSteps.CHOOSE_DOCUMENT
          ? ProofOfIdStep.CHOOSE_DOCUMENT
          : ProofOfIdStep.UPLOAD
      }
    />
  );
};

export default UploadAndSubmitProofOfIdentity;
