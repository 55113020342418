import { TypedDocumentNode, gql } from '@apollo/client';
import { defineMessages } from 'react-intl';

import { AmountInput } from '@sorare/core/src/__generated__/globalTypes';
import { VerifyPhoneNumber } from '@sorare/core/src/components/user/VerifyPhoneNumber';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSnackErrorHandler } from '@sorare/core/src/hooks/useSnackErrorHandler';
import { MonetaryAmountParams } from '@sorare/core/src/lib/monetaryAmount';

import useCreateSingleSaleOffer from 'hooks/offers/useCreateSingleSaleOffer';

import OfferDialog from '../OfferDialog';
import { NewSaleDialog_anyCard } from './__generated__/index.graphql';

const messages = defineMessages({
  title: {
    id: 'NewSaleDialog.title',
    defaultMessage: 'List your Card for sale',
  },
  cta: {
    id: 'NewSaleDialog.submit',
    defaultMessage: 'List',
  },
  confirmationMessage: {
    id: 'NewSaleDialog.listedPrice',
    defaultMessage: 'Listed Price',
  },
});

export interface Props {
  open: boolean;
  onClose: () => void;
  card: NewSaleDialog_anyCard;
  initialAmount?: MonetaryAmountParams;
}

const NewSaleDialog = ({ card, onClose, open, initialAmount }: Props) => {
  const createOffer = useCreateSingleSaleOffer();
  const snackErrorHandler = useSnackErrorHandler();
  const { currentUser } = useCurrentUserContext();

  const submit = async ({
    amountInput,
    duration,
  }: {
    amountInput: AmountInput;
    duration?: number;
  }) => {
    await snackErrorHandler(createOffer)({
      amountInput,
      card,
      duration,
    });
  };

  if (currentUser && !currentUser.phoneNumberVerified) {
    return <VerifyPhoneNumber />;
  }

  return (
    <OfferDialog
      assetId={card.assetId}
      showSaleDuration
      open={open}
      cta={messages.cta}
      onClose={onClose}
      submit={submit}
      title={messages.title}
      confirmationMessage={messages.confirmationMessage}
      initialAmount={initialAmount}
    />
  );
};

NewSaleDialog.fragments = {
  anyCard: gql`
    fragment NewSaleDialog_anyCard on AnyCardInterface {
      slug
      sport
      ...useCreateSingleSaleOffer_anyCard
    }
    ${useCreateSingleSaleOffer.fragments.anyCard}
  ` as TypedDocumentNode<NewSaleDialog_anyCard>,
};

export default NewSaleDialog;
