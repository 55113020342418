import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { BlockHeader } from '@sorare/core/src/atoms/layout/BlockHeader';
import {
  FullWidth,
  FullWidthScroll,
} from '@sorare/core/src/atoms/layout/Container';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { NewsTitleContent } from '@sorare/core/src/components/player/News/NewsTitleContent';
import { NewsUpdates } from '@sorare/core/src/components/player/News/NewsUpdates';
import { isType } from '@sorare/core/src/gql';
import usePaginatedQuery from '@sorare/core/src/hooks/graphql/usePaginatedQuery';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { HandledError } from '@sorare/core/src/routing/HandledError';

import { LazyFixtureChart } from '@sorare/gameplay/src/components/stats/LastScores/LazyFixtureChart';
import { PlayerGameScoreDialog } from '@sorare/gameplay/src/components/stats/PlayerGameScoreDialog';

import CardWithTokenPageContent from '@sorare/us-sports/src/components/cards/CardWithTokenPageContent';

import { NBACardPropertiesWithInjury } from '../NBACardPropertiesWithInjury';
import {
  NBACardQuery,
  NBACardQueryVariables,
} from './__generated__/index.graphql';

type NBACard = Extract<
  NonNullable<NBACardQuery['anyCard']>,
  { __typename: 'NBACard' }
>;

const PlayerScoresHistory = ({ card }: { card: NBACard }) => {
  const [selectedPlayerGameScoreId, setSelectedPlayerGameScoreId] =
    useState('');
  const player = card.anyPlayer;
  if (!isType(player, 'NBAPlayer')) return null;

  return (
    <>
      <PlayerGameScoreDialog
        open={!!selectedPlayerGameScoreId}
        showUpsell={false}
        onClose={() => setSelectedPlayerGameScoreId('')}
        playerGameScoreId={selectedPlayerGameScoreId}
      />
      <FullWidthScroll>
        <LazyFixtureChart
          playerSlug={player.slug}
          onScoreSelect={({ scoreId }) => setSelectedPlayerGameScoreId(scoreId)}
        />
      </FullWidthScroll>
    </>
  );
};

const Block = styled.div`
  padding: var(--double-unit) var(--intermediate-unit) var(--intermediate-unit);
  background: var(--c-neutral-300);
  border-radius: var(--double-unit);
`;

const News = ({ card }: { card: NBACard }) => {
  const player = card.anyPlayer;
  if (!isType(player, 'NBAPlayer')) return null;
  const { displayName, news } = player;
  if (!news) return null;
  return (
    <Vertical>
      <BlockHeader
        title={
          <FullWidth>
            <NewsTitleContent />
          </FullWidth>
        }
      />
      <Block>
        <NewsUpdates updates={news} playerName={displayName} />
      </Block>
    </Vertical>
  );
};

const NBA_CARD_QUERY = gql`
  query NBACardQuery(
    $slug: String!
    $bidCursor: String
    $onlyPrimary: Boolean = false
    $googlePlayStoreCountryCode: String
    $isAndroidApp: Boolean = false
    $isIosApp: Boolean = false
  ) {
    anyCard(slug: $slug) {
      slug
      ...CardWithTokenPageContent_cardInterface
      ...NBACardPropertiesWithInjury_anyCard
      anyPlayer {
        slug
        displayName
        news(last: 10) {
          date
          ...NewsUpdates_PlayerNewsUpdate
        }
      }
    }
  }
  ${CardWithTokenPageContent.fragments.cardInterface}
  ${NBACardPropertiesWithInjury.fragments.anyCard}
  ${NewsUpdates.fragments.PlayerNewsUpdate}
` as TypedDocumentNode<NBACardQuery, NBACardQueryVariables>;

export const CardPageContent = () => {
  const { slug: routeSlug } = useParams();
  const dialogSlug = useDialogParam(DialogKey.card);
  const slug = dialogSlug || routeSlug;
  const { formatMessage } = useIntl();
  const { isIosApp, isAndroidApp, googlePlayStoreCountryCode } =
    useIsMobileApp();

  const { data, loading, loadMore } = usePaginatedQuery(NBA_CARD_QUERY, {
    variables: {
      slug: slug as string,
      isIosApp,
      isAndroidApp,
      googlePlayStoreCountryCode,
    },
    connections: ['TokenBidConnection'],
  });

  if (loading || data === undefined) {
    return <LoadingIndicator fullHeight />;
  }

  const { anyCard } = data;

  if (anyCard === null || !isType(anyCard, 'NBACard')) {
    return (
      <FullWidth>
        <HandledError
          code={404}
          message={formatMessage({
            id: 'card.notFound',
            defaultMessage: 'This Card does not exist',
          })}
        />
      </FullWidth>
    );
  }

  return (
    <CardWithTokenPageContent<NBACard>
      loadMoreBids={loadMore}
      card={anyCard}
      CardProperties={NBACardPropertiesWithInjury}
      PlayerScoresHistory={PlayerScoresHistory}
      News={News}
    />
  );
};

export default CardPageContent;
