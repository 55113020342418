import { RefObject, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useResizeObserver = (
  ref: RefObject<any>,
  callback: ResizeObserverCallback
) => {
  const cbRef = useRef(callback);

  useEffect(() => {
    cbRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const observer = new ResizeObserver(
      (entries: ResizeObserverEntry[], obs: ResizeObserver) =>
        cbRef.current(entries, obs)
    );
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);
};
