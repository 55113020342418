import { To, useHref, useResolvedPath } from 'react-router-dom';

import { ORIGIN } from 'config';

export const useAbsoluteURL = (
  to: To,
  { stripBaseName = false, origin = ORIGIN } = {}
) => {
  const { pathname, search, hash } = useResolvedPath(to);
  // Add basename
  const href = useHref(to);

  if (stripBaseName) {
    return `${origin}${pathname}${search}${hash}`;
  }
  return `${origin}${href}`;
};
