import { useCallback } from 'react';

import { useCurrentUserContext } from 'contexts/currentUser';
import { useWalletDrawerContext } from 'contexts/walletDrawer';
import { useWalletNeedsRecover } from 'hooks/recovery/useWalletNeedsRecover';

import useOAuthEthWallet from './useOAuthEthWallet';

type Handler = () => void;

export const useHandleWalletStateBeforePayment = () => {
  const { needsCreateEthWallet, promptGenerateKeys } = useOAuthEthWallet();
  const { currentUser } = useCurrentUserContext();
  const walletNeedsRecover = useWalletNeedsRecover();
  const { showDrawer } = useWalletDrawerContext();

  const promptKeys = useCallback(() => {
    promptGenerateKeys();
  }, [promptGenerateKeys]);

  const handleWalletStateBeforePayment = useCallback(
    (handler: Handler) => {
      if (needsCreateEthWallet) {
        return promptKeys();
      }
      if (walletNeedsRecover || !currentUser?.confirmedDevice) {
        return showDrawer();
      }

      return handler();
    },
    [
      currentUser?.confirmedDevice,
      needsCreateEthWallet,
      promptKeys,
      showDrawer,
      walletNeedsRecover,
    ]
  );

  return handleWalletStateBeforePayment;
};
