import { TypedDocumentNode, gql } from '@apollo/client';

import { OwnerTransfer } from '__generated__/globalTypes';
import { sendSafeError } from 'lib/error';
import { withFragments } from 'lib/gql';
import { Font, getTextSize } from 'lib/text';

import { drawOwner_AnyCardInterface } from './__generated__/drawOwner.graphql';
import auctionUrl from './assets/obtained/auction.svg';
import instantBuyUrl from './assets/obtained/instant_buy.svg';
import rewardUrl from './assets/obtained/reward.svg';
import shardsUrl from './assets/obtained/shards.svg';
import transfertUrl from './assets/obtained/transfert.svg';

const getObtainedInfos = (type: OwnerTransfer) => {
  switch (type) {
    case OwnerTransfer.ENGLISH_AUCTION:
    case OwnerTransfer.BUNDLED_ENGLISH_AUCTION:
    case OwnerTransfer.PACK:
      return { icon: auctionUrl, label: 'BOUGHT BY' };

    case OwnerTransfer.INSTANT_BUY:
      return { icon: instantBuyUrl, label: 'COLLECTED BY' };

    case OwnerTransfer.MINT:
    case OwnerTransfer.DEPOSIT:
    case OwnerTransfer.TRANSFER:
    case OwnerTransfer.WITHDRAWAL:
    case OwnerTransfer.SINGLE_SALE_OFFER:
    case OwnerTransfer.SINGLE_BUY_OFFER:
    case OwnerTransfer.DIRECT_OFFER:
      return { icon: transfertUrl, label: 'OBTAINED BY' };

    case OwnerTransfer.SHARDS:
      return { icon: shardsUrl, label: 'CRAFTED BY' };

    case OwnerTransfer.REFERRAL:
    case OwnerTransfer.REWARD:
      return { icon: rewardUrl, label: 'WON BY' };

    default:
      sendSafeError(`Unhandled HighlightedStatType ${type}`);
      return { icon: transfertUrl, label: 'OWNED BY' };
  }
};

export const drawOwner = withFragments(
  async ({
    ctx,
    width,
    card,
    y,
    fontName,
  }: {
    ctx: CanvasRenderingContext2D;
    width: number;
    card: drawOwner_AnyCardInterface;
    y: number;
    fontName: string;
  }) => {
    if (card.tokenOwner?.user) {
      const { icon, label } = getObtainedInfos(card.tokenOwner.transferType);
      const obtainedIcon = new Image();
      obtainedIcon.src = icon;
      ctx.fillStyle = '#fff';
      ctx.textAlign = 'center';
      const fontTransfer: Font = `500 30px ${fontName}`;
      const { width: ownerWidth } = getTextSize(label, fontTransfer);
      // display icon
      await obtainedIcon.decode();
      ctx.save();
      ctx.globalCompositeOperation = 'overlay';
      ctx.globalAlpha = 0.5;
      const iconSize = 100;
      ctx.drawImage(
        obtainedIcon,
        width / 2 - ownerWidth / 2 - iconSize / 2,
        y - iconSize / 2 - 10,
        iconSize,
        iconSize
      );
      ctx.restore();

      ctx.font = fontTransfer;
      ctx.fillText(label, width / 2, y);

      const fontUsername: Font = `600 60px ${fontName}`;
      ctx.font = fontUsername;
      const { width: nicknameWidth } = getTextSize(
        card.tokenOwner.user.nickname,
        fontUsername
      );
      const scale = Math.min((width * 0.8) / nicknameWidth, 1);
      ctx.save();
      ctx.translate(width / 2, y + 67);
      ctx.scale(scale, scale);
      ctx.fillText(card.tokenOwner.user.nickname, 0, 0);
      ctx.restore();

      if (card.tokenOwner.from) {
        ctx.font = `30px ${fontName}`;
        ctx.textAlign = 'center';
        ctx.fillText(
          new Date(card.tokenOwner.from)
            .toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
            .toLocaleUpperCase(),
          width / 2,
          y + 114
        );
      }
    }
  },

  {
    AnyCardInterface: gql`
      fragment drawOwner_AnyCardInterface on AnyCardInterface {
        slug

        tokenOwner {
          id
          from
          transferType
          user {
            slug
            nickname
          }
        }
      }
    ` as TypedDocumentNode<drawOwner_AnyCardInterface>,
  }
);
