import styled from 'styled-components';

import cardPlaceholder from 'assets/cards/placeholder.svg';
import {
  ResponsiveImg,
  ValidWidths,
  getClosestStandardWidth,
} from 'atoms/ui/ResponsiveImg';
import { CARD_ASPECT_RATIO } from 'lib/cards';

type CardImgProps = {
  width?: ValidWidths;
  height?: number;
  fallback?: string;
  alt?: string;
};
export const CardImg = styled(ResponsiveImg).attrs<CardImgProps>(
  ({ width, height, fallback, alt }) => ({
    width: width || 40,
    height: height || +(width || 40) / CARD_ASPECT_RATIO,
    cropWidth: getClosestStandardWidth(width || 40),
    fallback: fallback ?? cardPlaceholder,
    alt: alt ?? '',
  })
)<CardImgProps>`
  height: auto;
  width: 100%;
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
`;

export const CardImgLoadingWrapper = styled.div<{ loaded: boolean }>`
  position: relative;
  isolation: isolate;
  width: 100%;
  &::before {
    content: '';
    opacity: ${props => (props.loaded ? '0' : '1')};
    pointer-events: none;
    transition: opacity 0.1s ease-out;
    position: absolute;
    inset: 0;
    background: var(--c-neutral-300) url(${cardPlaceholder}) no-repeat center;
    background-size: cover;
    background-position: top center;
    border-radius: var(--double-unit);
    z-index: -1;
  }
`;
