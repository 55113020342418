import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import { To } from 'react-router-dom';

import { Link } from '@sorare/routing';

import useClickHandler from 'hooks/useClickHandler';

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  disableDebounce?: boolean;
  to?: To;
  viewTransition?: boolean;
  externalLink?: boolean;
}

export const ButtonBase = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(function ButtonWithRef(props, ref) {
  const {
    children,
    onClick,
    disableDebounce = true,
    type = 'button',
    to,
    externalLink,
    ...rest
  } = props;

  const clickHandler = useClickHandler(onClick, disableDebounce);

  if (to) {
    return (
      <Link
        to={to}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        onClick={onClick}
        {...rest}
        {...(externalLink
          ? ({ target: '_blank', rel: 'noopener noreferrer' } as any)
          : {})}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      ref={ref as ForwardedRef<HTMLButtonElement>}
      onClick={clickHandler}
      /* eslint-disable react/button-has-type */
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
});
