import { unitMapping } from 'lib/style';

type Props = {
  className?: string;
  size?: keyof typeof unitMapping;
};

export const Shirt = ({ className, size = 2 }: Props) => {
  return (
    <svg
      className={className}
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1.75L8 3.25L9.5 1.75H14V4.75L11.75 5.5V10H4.25V5.5L2 4.75V1.75H6.5ZM9.5 6.25C9.91421 6.25 10.25 5.91421 10.25 5.5C10.25 5.08579 9.91421 4.75 9.5 4.75C9.08579 4.75 8.75 5.08579 8.75 5.5C8.75 5.91421 9.08579 6.25 9.5 6.25Z"
        fill="currentColor"
      />
      <path d="M4.25 13V11.5H11.75V13H4.25Z" fill="currentColor" />
    </svg>
  );
};
