import { SVGProps } from 'react';

import { unitMapping } from 'lib/style';

interface SVGRProps {
  size?: keyof typeof unitMapping;
  className?: string;
  gradient?: 'green' | 'red';
}

export const UserCheck = ({
  size = 2,
  className,
  gradient,
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  let fill = 'currentColor';
  if (gradient) {
    fill = `url(#${gradient}-gradient)`;
  }

  return (
    <svg
      viewBox="0 0 31 31"
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3053 3.90056C13.087 3.90056 12.0994 4.88818 12.0994 6.10647C12.0994 7.32476 13.087 8.31238 14.3053 8.31238C15.5236 8.31238 16.5112 7.32476 16.5112 6.10647C16.5112 4.88818 15.5236 3.90056 14.3053 3.90056ZM8.56992 6.10647C8.56992 2.93891 11.1377 0.371094 14.3053 0.371094C17.4729 0.371094 20.0407 2.93891 20.0407 6.10647C20.0407 8.14888 18.9731 9.94195 17.3655 10.9581H17.8347C20.7586 10.9581 23.1289 13.3284 23.1289 16.2523H19.5995C19.5995 15.2776 18.8094 14.4875 17.8347 14.4875H10.7758C9.80117 14.4875 9.01107 15.2776 9.01107 16.2523V30.3701H5.48161L5.48161 16.2523C5.48161 13.3284 7.8519 10.9581 10.7758 10.9581H11.2451C9.63751 9.94195 8.56992 8.14888 8.56992 6.10647ZM19.5863 30.0924L18.3366 28.8344L14.5976 25.0706L17.1015 22.5831L19.5909 25.089L23.9639 20.7032L26.4632 23.1952L20.8383 28.8367L19.5863 30.0924Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="green-gradient"
          x1="18.3206"
          y1="35.7766"
          x2="7.76238"
          y2="18.8035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1FF00" />
          <stop offset="1" stopColor="#3DDC84" />
        </linearGradient>
        <linearGradient
          id="red-gradient"
          x1="18.1249"
          y1="35.9728"
          x2="7.46515"
          y2="18.9341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1FF00" />
          <stop offset="1" stopColor="#FF0974" />
        </linearGradient>
      </defs>
    </svg>
  );
};
