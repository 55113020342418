import { useCallback } from 'react';
import { defineMessage } from 'react-intl';

import { useIntlContext } from 'contexts/intl';
import { isAddress } from 'lib/ethereum';

import TextField, { Props as TextFieldProps } from '../TextField';

type Props = TextFieldProps;

const invalidAddress = defineMessage({
  id: 'Notification.invalidAddress',
  defaultMessage: 'Invalid Ethereum address',
});

const EthereumAddressField = (props: Props) => {
  const { formatMessage } = useIntlContext();
  const validateEthereumAddress = useCallback(
    (value: string) => {
      if (!isAddress(value)) {
        return formatMessage(invalidAddress);
      }
      return null;
    },
    [formatMessage]
  );

  return (
    <TextField
      {...props}
      type="text"
      validate={validateEthereumAddress}
      autoComplete="off"
    />
  );
};

export default EthereumAddressField;
