import { faCancel, faCheck, faPen } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { IconButton } from 'atoms/buttons/IconButton';
import { Horizontal } from 'atoms/layout/flex';
import { title2 } from 'atoms/typography';
import useToggle from 'hooks/useToggle';
import { glossary } from 'lib/glossary';

const Root = styled(Horizontal)`
  flex-basis: 100%;
  min-width: 0;
`;
const InputContainer = styled.div`
  --border-size: 2px;
  position: relative;
  min-width: 1em;
  width: min-content;
  padding-bottom: var(--border-size);
`;
const autoSizeCommonStyle = css`
  ${title2}
  padding: 0 var(--half-unit);
  border-bottom: var(--border-size) solid transparent;
`;
const Autosize = styled.span`
  visibility: hidden;
  white-space: pre;
  ${autoSizeCommonStyle}
`;
const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  border: none;
  background: var(--c-neutral-400);
  ${autoSizeCommonStyle}
  color: var(--c-neutral-1000);

  &:disabled {
    background: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.empty {
    border-bottom-color: var(--c-red-600);
  }
`;

type Props = InputHTMLAttributes<HTMLInputElement> & {
  value: string;
  initialEditing?: boolean;
  onConfirm?: (value: string) => void;
  onCancel?: (previousValue: string) => void;
};

export const InlineEditionInput = ({
  value,
  initialEditing,
  onConfirm,
  onCancel,
  ...rest
}: Props) => {
  const { formatMessage } = useIntl();
  const [editing, toggleEditing] = useToggle(!!initialEditing);
  const [editingHasChanged, setEditingHasChanged] = useState<boolean>(false);
  const [previousValue, setPreviousValue] = useState<string>(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const cancelBtnRef = useRef<HTMLButtonElement>(null);
  const empty = !value;

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [editing]);

  const switchEditing = () => {
    toggleEditing();
    setEditingHasChanged(true);
  };

  const edit = () => {
    switchEditing();
  };

  const confirm = () => {
    if (!empty) {
      onConfirm?.(value);
      setPreviousValue(value);
      switchEditing();
    }
  };

  const cancel = () => {
    onCancel?.(previousValue);
    switchEditing();
  };

  const showCancel =
    initialEditing === undefined || !initialEditing || editingHasChanged;

  return (
    <Root>
      <InputContainer>
        <Autosize>{value || rest.placeholder}</Autosize>
        <StyledInput
          type="text"
          value={value}
          disabled={!editing}
          ref={inputRef}
          onKeyUpCapture={event => {
            if (event.key === 'Enter') {
              confirm();
            }
            if (event.key === 'Escape') {
              cancel();
            }
          }}
          className={classNames({ empty })}
          {...rest}
        />
      </InputContainer>

      {editing ? (
        <>
          <IconButton
            icon={faCheck}
            color="transparent"
            disabled={empty}
            small
            onClick={confirm}
            aria-label={formatMessage(glossary.done)}
          />
          {showCancel && (
            <IconButton
              ref={cancelBtnRef}
              icon={faCancel}
              color="transparent"
              small
              onClick={cancel}
              aria-label={formatMessage(glossary.cancel)}
            />
          )}
        </>
      ) : (
        <IconButton
          icon={faPen}
          color="transparent"
          small
          onClick={edit}
          aria-label={formatMessage(glossary.edit)}
        />
      )}
    </Root>
  );
};

InlineEditionInput.displayName = 'InlineEditionInput';
