import { useMemo } from 'react';

import { useIntlContext } from 'contexts/intl';
import { sortBy } from 'lib/arrays';
import countries from 'lib/countries.json';

export const countryCodes = Object.fromEntries(
  Object.entries(countries).map(([code, names]) => [names.en, code])
);

export const useIntlCountries = (filterTwoChars?: boolean) => {
  const { locale } = useIntlContext();
  return useMemo(
    () =>
      sortBy(
        country => country.label,
        Object.entries(countries)
          .map(entry => ({
            value: entry[0],
            label:
              entry[1]?.[locale.slice(0, 2) as keyof (typeof entry)[1]] ||
              entry[1].en,
          }))
          .filter(({ value }) => value.length === 2 || !filterTwoChars)
      ),
    [filterTwoChars, locale]
  );
};
