import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Sport } from '__generated__/globalTypes';
import { useSportContext } from 'contexts/sport';
import { useIsTabletAndAbove } from 'hooks/device/useIsTabletAndAbove';
import { useTouchScreen } from 'hooks/device/useTouchScreen';
import useIsLanding from 'hooks/useIsLandingPage';
import useIsMlbPage from 'hooks/useIsMlbPage';
import useIsNBAPage from 'hooks/useIsNBAPage';
import { useLocationChanged } from 'hooks/useLocationChanged';
import { SESSION_STORAGE, useSessionStorage } from 'hooks/useSessionStorage';
import useSharedAccrossSportsPage from 'hooks/useSharedAccrossSportsPage';

import AppBarContextProvider from '.';

interface Props {
  children: ReactNode;
}

const AppBarProvider = ({ children }: Props) => {
  const isTabletAndAbove = useIsTabletAndAbove();
  const isMouseFriendlyDevice = !useTouchScreen();

  const locationChanged = useLocationChanged();
  const isMlbPage = useIsMlbPage();
  const isNBAPage = useIsNBAPage();
  const isLandingPage = useIsLanding();
  const sharedPage = useSharedAccrossSportsPage();
  const { sport: sportConfig } = useSportContext();
  const [sportContext, setSportContext] = useSessionStorage(
    SESSION_STORAGE.sport
  );

  useEffect(() => {
    if (!sharedPage && !sportContext && sportConfig) {
      setSportContext(sportConfig);
    }

    if (isMlbPage) {
      setSportContext(Sport.BASEBALL);
    } else if (isNBAPage) {
      setSportContext(Sport.NBA);
    } else if (!sharedPage) {
      setSportContext(Sport.FOOTBALL);
    } else if (isLandingPage) {
      setSportContext(null);
    }
  }, [
    sharedPage,
    sportContext,
    sportConfig,
    setSportContext,
    isMlbPage,
    isNBAPage,
    isLandingPage,
  ]);

  const [openedMenu, setOpenedMenu] = useState<string | undefined>(undefined);

  const openMenu = useCallback((menu: string) => {
    setOpenedMenu(menu);
  }, []);

  const closeMenu = useCallback(() => {
    setOpenedMenu(undefined);
  }, []);

  useEffect(() => {
    if (locationChanged) closeMenu();
  }, [locationChanged, closeMenu]);

  const value = useMemo(
    () => ({
      small: !(isTabletAndAbove && isMouseFriendlyDevice),
      sport: sportContext || undefined,
      openMenu,
      closeMenu,
      openedMenu,
    }),
    [
      closeMenu,
      isMouseFriendlyDevice,
      isTabletAndAbove,
      openMenu,
      openedMenu,
      sportContext,
    ]
  );

  return (
    <AppBarContextProvider value={value}>{children}</AppBarContextProvider>
  );
};

export default AppBarProvider;
