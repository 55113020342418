import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';
import { CSSProperties, css } from 'styled-components';

import { withFragments } from '@sorare/core/src/gql';

import { getTeamStyleVariables_team } from './__generated__/background.style.graphql';

export const backgroundStyle = css`
  --bg-rgb-inner: var(--c-static-rgb-neutral-700);
  --bg-rgb-outer: var(--c-static-rgb-neutral-1000);

  --bg-rgba-inner: var(
    --team-color-foreground,
    rgba(var(--bg-rgb-inner), var(--bg-foreground-opacity))
  );

  --bg-rgba-inner-background: var(
    --team-color-background,
    rgba(var(--bg-rgb-outer), 1)
  );

  --bg-rgba-outer-foreground: var(
    --team-color-foreground,
    rgba(var(--bg-rgb-outer), var(--bg-foreground-opacity))
  );

  --bg-gradient: linear-gradient(
    180deg,
    rgba(var(--bg-rgb-outer), 0) 25%,
    rgba(var(--bg-rgb-outer), 0.9) 75%
  );

  --bg-gradient-top: radial-gradient(
    200% 200% at 50% 200%,
    rgba(var(--bg-rgb-outer), 0) 85%,
    rgba(var(--bg-rgb-outer), 0.6) 100%
  );

  --bg-base-foreground: radial-gradient(
    50% 50% at 50% 50%,
    var(--bg-rgba-inner) 0%,
    var(--bg-rgba-outer-foreground) 100%
  );
  --bg-base-background: radial-gradient(
    50% 50% at 50% 50%,
    var(--bg-rgba-inner-background) 0%,
    rgba(var(--bg-rgb-outer), 0.1) 100%
  );

  background: var(--bg-gradient), var(--bg-gradient-top),
    var(--bg-base-foreground), var(--bg-pattern), var(--bg-base-background);
`;

export const boxBackgroundStyle = css`
  ${backgroundStyle};

  --bg-gradient: linear-gradient(
    180deg,
    rgba(var(--bg-rgb-outer), 0.5) 0%,
    rgba(var(--bg-rgb-outer), 0.5) 100%
  );

  --bg-gradient-top: radial-gradient(
    200% 200% at 50% 200%,
    rgba(var(--bg-rgb-outer), 0) 45%,
    rgba(var(--bg-rgb-outer), 0.6) 100%
  );

  --bg-pattern: right / contain no-repeat var(--team-svg-url);

  background: var(--bg-gradient), var(--bg-base-foreground), var(--bg-pattern);
`;

export const useTeamBackgroundStyleVariables = withFragments(
  (team?: getTeamStyleVariables_team | null, complete = true) => {
    const { pictureUrl, styleGuideColor } = team || {};
    return useMemo(() => {
      const style: Record<string, string> = {};
      style['--bg-foreground-opacity'] = pictureUrl ? '0.9' : '0.65';
      if (pictureUrl) {
        style['--team-svg-url'] = `url(${pictureUrl})`;
      }
      if (complete && styleGuideColor) {
        style['--team-color-background'] = styleGuideColor;
        style['--team-color-foreground'] = `${styleGuideColor}e6`;
      }

      return style as CSSProperties;
    }, [pictureUrl, styleGuideColor, complete]);
  },
  {
    team: gql`
      fragment getTeamStyleVariables_team on TeamInterface {
        id
        slug
        pictureUrl
        styleGuideColor
      }
    ` as TypedDocumentNode<getTeamStyleVariables_team>,
  }
);
