import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormatNumberOptions, FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, LabelS } from '@sorare/core/src/atoms/typography';
import { sortBy } from '@sorare/core/src/lib/arrays';
import { getShortPlayerName } from '@sorare/core/src/lib/players';
import { toSnakeCase } from '@sorare/core/src/lib/toSnakeCase';

import { TeamTableStatsBox } from '@sorare/us-sports/src/components/game/TeamTableStatsBox';
import { ColoredScoreWithDelta } from '@sorare/us-sports/src/components/scores/ColoredScoreWithDelta';

import {
  STAT_KEYS,
  StatKey,
  shortStatMessages,
} from 'components/competitionDetails/messages';

import {
  TeamPlayersStats_anyCard,
  TeamPlayersStats_basketballPlayerGameScore,
  TeamPlayersStats_so5Appearance,
  TeamPlayersStats_team,
} from './__generated__/index.graphql';

export const Score = styled(LabelM).attrs({ as: 'span' })`
  color: var(--c-neutral-500);
  font: var(--t-label-m);
  &.isPositive {
    color: var(--c-static-green-600);
  }
  &.isNegative {
    color: var(--c-static-red-800);
  }
`;

const TableStatsGrid = styled(TeamTableStatsBox)`
  width: 100%;
  --stat-columns: 10;
  --all-columns: calc(var(--stat-columns) + 2);

  display: grid;

  grid-template-columns:
    max-content repeat(var(--stat-columns), minmax(var(--quadruple-unit), 1fr))
    min-content;

  & > * + * {
    border-top: 1px solid var(--c-neutral-300);
  }

  align-self: flex-start;
  justify-self: flex-start;
  overflow-x: scroll;
`;

const tableStyle = css`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span var(--all-columns);
  align-items: center;
  background-color: var(--c-neutral-200);

  & > div:not(:first-child) {
    text-align: center;
  }
  & > div:last-child {
    display: flex;
    padding-inline: var(--intermediate-unit) var(--triple-unit);
    position: sticky;
    right: 0;
    background: inherit;
    align-self: stretch;
    justify-content: center;
  }
`;

const TableStatsHead = styled(TeamTableStatsBox.Header)`
  ${tableStyle}
`;
const TableStatsRow = styled(TeamTableStatsBox.Row)`
  ${tableStyle}

  &.highlighted {
    background: linear-gradient(
        to bottom,
        rgba(var(--c-rgb-brand-600), 0.1),
        rgba(var(--c-rgb-brand-600), 0.1)
      ),
      linear-gradient(to bottom, var(--c-neutral-200), var(--c-neutral-200));
  }

  &.isInactive {
    opacity: 50%;
  }
  & > div {
    padding: var(--half-unit);
    white-space: nowrap;
  }
  & > div:first-child {
    padding-inline: var(--intermediate-unit);
    position: sticky;
    left: 0;
    align-self: stretch;
    background: inherit;
    cursor: pointer;
  }
`;

const TableStatsCell = styled.div`
  font: var(--t-label-s);
`;

const Stat0 = styled.span`
  opacity: 0.5;
`;
const Score0 = styled.span`
  visibility: hidden;
`;

const formatStat = (
  statKey: StatKey,
  value: number,
  formatNumber: (value: number, opts?: FormatNumberOptions) => string,
  isStat: boolean
): ReactNode => {
  if (isStat && (statKey === 'doubleDouble' || statKey === 'tripleDouble')) {
    return value > 0 ? '✓' : '';
  }

  if (value === 0) {
    return isStat ? <Stat0>0</Stat0> : <Score0>0</Score0>;
  }
  return isStat
    ? formatNumber(value, {
        maximumFractionDigits: 1,
      })
    : formatNumber(value, {
        maximumFractionDigits: 0,
      });
};

type Props = {
  team: TeamPlayersStats_team;

  playerStats: {
    playerGameScore: TeamPlayersStats_basketballPlayerGameScore;
    so5Appearances?: TeamPlayersStats_so5Appearance[];
    unusedCards?: TeamPlayersStats_anyCard[];
  }[];
  playedInGame?: boolean;
  under?: boolean;
  openPlayerInfoModal: (playerSlug: string) => void;
  openCardInfoModal: (cardSlug: string) => void;
  onMouseHoverPlayer: (playerSlug: string) => void;
  onMouseLeavePlayer: () => void;
  mouseOnHoverPlayer: string | null;
};
export const TeamPlayersStats = ({
  team,
  playerStats,
  playedInGame,
  openPlayerInfoModal,
  mouseOnHoverPlayer,
  onMouseLeavePlayer,
  onMouseHoverPlayer,
}: Props) => {
  const { formatNumber } = useIntl();
  const sortedPlayerScores = sortBy(
    ({ playerGameScore }) =>
      playerGameScore.score +
      ((playerGameScore.basketballPlayerGameStats.minsPlayed || 0) > 0
        ? 1000
        : 0),
    playerStats.filter(
      ({ playerGameScore }) =>
        playedInGame === undefined ||
        playedInGame === !!playerGameScore.basketballPlayerGameStats.minsPlayed
    )
  );

  const topPlayerGameScore = sortedPlayerScores.reverse();

  return (
    <TableStatsGrid
      team={team}
      title={
        playedInGame === false ? null : (
          <TableStatsHead className="teamHeader">
            <div style={{ alignSelf: 'stretch' }}> </div>
            <TableStatsCell>
              <FormattedMessage
                id="NBAPerformanceTable.stats.minutes.short"
                defaultMessage="MIN"
              />
            </TableStatsCell>
            {STAT_KEYS.map(statKey => {
              return (
                <TableStatsCell key={statKey as string}>
                  <FormattedMessage {...shortStatMessages[statKey]} />
                </TableStatsCell>
              );
            })}
            <LabelS as="div">
              <FormattedMessage
                id="NBAPerformanceTable.stats.total.short"
                defaultMessage="TOT"
              />
            </LabelS>
          </TableStatsHead>
        )
      }
    >
      {topPlayerGameScore.map(({ playerGameScore }) => {
        const {
          basketballPlayerGameStats: detailedStats,
          anyPlayer: player,
          score,
        } = playerGameScore;

        return (
          <TableStatsRow
            key={player.slug}
            className={classNames({
              highlighted: mouseOnHoverPlayer === player.slug,
              isInactive: !playedInGame,
            })}
            onMouseEnter={() => onMouseHoverPlayer(player.slug)}
            onMouseLeave={onMouseLeavePlayer}
          >
            <Horizontal onClick={() => openPlayerInfoModal(player.slug)}>
              <LabelM as="div">{getShortPlayerName(player.displayName)}</LabelM>
            </Horizontal>
            {playedInGame ? (
              <Vertical key="minsPlayed" gap={0}>
                <LabelS as="div" color="var(--c-neutral-500)">
                  {`${
                    detailedStats?.minsPlayed
                      ? formatNumber(detailedStats?.minsPlayed, {
                          maximumFractionDigits: 0,
                        })
                      : 0
                  }'`}
                </LabelS>
                <Score>
                  <Score0>0</Score0>
                </Score>
              </Vertical>
            ) : (
              <div />
            )}
            {STAT_KEYS.map(statKey => {
              // Using `+ 0` to prevent '-0' from being displayed
              const detailedScore =
                (playerGameScore?.detailedScore?.find(
                  dS => dS.stat === toSnakeCase(statKey)
                )?.totalScore || 0) + 0;
              const detailedStat =
                (detailedStats?.[
                  statKey as keyof typeof detailedStats
                ] as number) || 0;

              // Empty stat columns if player did not play in this game
              if (!playedInGame) return <div />;
              return (
                <Vertical key={statKey as string} gap={0}>
                  <LabelS as="div" color="var(--c-neutral-500)">
                    {formatStat(statKey, detailedStat, formatNumber, true)}
                  </LabelS>
                  <Score
                    className={classNames({
                      isNegative: detailedScore < 0,
                      isPositive: detailedScore > 0,
                    })}
                  >
                    {formatStat(statKey, detailedScore, formatNumber, false)}
                  </Score>
                </Vertical>
              );
            })}
            <div>
              <ColoredScoreWithDelta
                score={score || 0}
                averageGameScore={player?.lastTenPlayedSo5AverageScore || 0}
                playedInGame={playedInGame}
              />
            </div>
          </TableStatsRow>
        );
      })}
    </TableStatsGrid>
  );
};

TeamPlayersStats.fragments = {
  basketballPlayerGameScore: gql`
    fragment TeamPlayersStats_basketballPlayerGameScore on BasketballPlayerGameScore {
      id
      score
      detailedScore {
        stat
        category
        points
        totalScore
        statValue
      }
      anyGame {
        id
      }
      anyPlayer {
        slug
        displayName
        lastTenPlayedSo5AverageScore: averageScore(
          type: LAST_TEN_PLAYED_SO5_AVERAGE_SCORE
        )
      }
      basketballPlayerGameStats {
        id
        points
        rebounds
        assists
        blocks
        steals
        turnovers
        doubleDouble
        tripleDouble
        threePointsMade
        minsPlayed
        anyTeam {
          slug
        }
      }
    }
  ` as TypedDocumentNode<TeamPlayersStats_basketballPlayerGameScore>,
  team: gql`
    fragment TeamPlayersStats_team on TeamInterface {
      slug
      id
      ...TeamTableStatsBox_team
    }
    ${TeamTableStatsBox.fragments.team}
  ` as TypedDocumentNode<TeamPlayersStats_team>,
  anyCard: gql`
    fragment TeamPlayersStats_anyCard on AnyCardInterface {
      slug
    }
  ` as TypedDocumentNode<TeamPlayersStats_anyCard>,
  so5Appearance: gql`
    fragment TeamPlayersStats_so5Appearance on So5Appearance {
      id
      anyCard {
        slug
      }
    }
  ` as TypedDocumentNode<TeamPlayersStats_so5Appearance>,
};
