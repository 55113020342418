import { TypedDocumentNode, gql } from '@apollo/client';
import { useReducer } from 'react';
import { useIntl } from 'react-intl';

import {
  Sport,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { VerifyPhoneNumber } from '@sorare/core/src/components/user/VerifyPhoneNumber';
import {
  CurrentUser,
  useCurrentUserContext,
} from '@sorare/core/src/contexts/currentUser';
import {
  MonetaryAmountOutput,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { convertToCardHit } from '@sorare/core/src/lib/algolia';
import { tradeLabels } from '@sorare/core/src/lib/glossary';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { WalletPaymentMethod } from '@sorare/core/src/lib/paymentMethod';

import BuildingPage from './BuildingPage';
import { OfferUser } from './OfferUser';
import Summary from './Summary';
import {
  NewOfferBuilder_anyCard,
  NewOfferBuilder_publicUserInfoInterface,
  NewOfferCardsQuery,
  NewOfferCardsQueryVariables,
} from './__generated__/index.graphql';
import reducer, { InitProps, init } from './reducer';
import { Actions, State, StateProps } from './types';
import useOnSubmit from './useOnSubmit';

export interface SharedProps {
  to: NewOfferBuilder_publicUserInfoInterface;
  onClose: () => void;
  counterOfferId?: string;
  currentUser: CurrentUser;
}

const anyCardFragment = gql`
  fragment NewOfferBuilder_anyCard on AnyCardInterface {
    slug
    publicMinPrices {
      ...MonetaryAmountFragment_monetaryAmount
    }
    ...useOnSubmit_anyCard
    ...OfferBuilderBuildingPage_anyCard
    ...OfferBuilderSummary_anyCard
  }
  ${monetaryAmountFragment}
  ${useOnSubmit.fragments.anyCard}
  ${Summary.fragments.anyCard}
  ${BuildingPage.fragments.anyCard}
` as TypedDocumentNode<NewOfferBuilder_anyCard>;

const NEW_OFFER_CARDS_QUERY = gql`
  query NewOfferCardsQuery($assetIds: [String!]!) {
    anyCards(assetIds: $assetIds) {
      slug
      ...NewOfferBuilder_anyCard
    }
  }
  ${anyCardFragment}
` as TypedDocumentNode<NewOfferCardsQuery, NewOfferCardsQueryVariables>;

const SummaryPage = ({
  to,
  counterOfferId,
  state,
  dispatch,
  onClose,
  currentUser,
}: SharedProps & StateProps<NewOfferBuilder_anyCard>) => {
  const { formatMessage } = useIntl();
  const title = formatMessage(
    counterOfferId ? tradeLabels.counterOfferWith : tradeLabels.tradeWith,
    {
      nickname: to.nickname,
    }
  );
  return (
    <Summary
      title={title}
      cancel={onClose}
      state={state}
      dispatch={dispatch}
      receiver={<OfferUser user={to} isReceiver />}
      sender={<OfferUser user={currentUser} />}
    />
  );
};

type OfferBuilderInitialPageProps = SharedProps & {
  counterOfferSport?: Sport;
  lockReceiveEthInput?: boolean;
};

const OfferBuilderInitialPage = ({
  state,
  dispatch,
  onClose,
  to,
  counterOfferId,
  counterOfferSport,
  currentUser,
  lockReceiveEthInput,
}: OfferBuilderInitialPageProps & StateProps<NewOfferBuilder_anyCard>) => {
  return (
    <BuildingPage<NewOfferBuilder_anyCard, NewOfferCardsQuery>
      onClose={onClose}
      query={NEW_OFFER_CARDS_QUERY}
      state={state}
      dispatch={dispatch}
      counterOfferId={counterOfferId}
      counterOfferSport={counterOfferSport}
      to={to}
      receiver={<OfferUser user={to} isReceiver />}
      sender={<OfferUser user={currentUser} />}
      lockReceiveEthInput={lockReceiveEthInput}
    />
  );
};

const offerBuilderReducer = reducer<NewOfferBuilder_anyCard>();

type Props = SharedProps &
  OfferBuilderInitialPageProps & {
    receiveCards?: NewOfferBuilder_anyCard[];
    receiveAmount?: MonetaryAmountOutput;
    receiveMarketFeesAmount?: MonetaryAmountOutput;
    sendCards?: NewOfferBuilder_anyCard[];
    sendAmount?: MonetaryAmountOutput;
    referenceCurrency?: SupportedCurrency;
    paymentMethod?: WalletPaymentMethod;
  };

export const NewOfferBuilder = ({
  onClose,
  to,
  receiveCards: initialReceiveCards = [],
  receiveAmount: initialReceiveAmount = zeroMonetaryAmount,
  receiveMarketFeesAmount: initialReceiveMarketFeesAmount = zeroMonetaryAmount,
  sendCards: initialSendCards = [],
  sendAmount: initialSendAmount = zeroMonetaryAmount,
  counterOfferId = undefined,
  counterOfferSport = undefined,
  currentUser,
  lockReceiveEthInput,
  referenceCurrency,
  paymentMethod,
}: Props) => {
  const {
    fiatCurrency: { code },
    walletPreferences: { showFiatWallet },
  } = useCurrentUserContext();
  const onSubmit = useOnSubmit<NewOfferBuilder_anyCard>(
    to,
    onClose,
    counterOfferId
  );

  const [state, dispatch] = useReducer<
    (
      state: State<NewOfferBuilder_anyCard>,
      action: Actions<NewOfferBuilder_anyCard>
    ) => State<NewOfferBuilder_anyCard>,
    InitProps<NewOfferBuilder_anyCard>
  >(
    offerBuilderReducer,
    {
      initialReceiveAmount,
      initialSendCards,
      initialSendAmount,
      submit: onSubmit,
      initialReceiveMarketFeesAmount,
      initialReceiveCurrency:
        referenceCurrency ||
        (showFiatWallet && (code as SupportedCurrency)) ||
        undefined,
      initialSendCurrency:
        referenceCurrency ||
        (showFiatWallet && (code as SupportedCurrency)) ||
        undefined,
      convertToAlgoliaCardHit: convertToCardHit,
      initialReceiveCards,
      ...(paymentMethod && { initialPaymentMethod: paymentMethod }),
    },
    init
  );

  if (currentUser && !currentUser.phoneNumberVerified) {
    return <VerifyPhoneNumber />;
  }

  const { stage } = state;

  if (stage === 'building')
    return (
      <OfferBuilderInitialPage
        counterOfferId={counterOfferId}
        counterOfferSport={counterOfferSport}
        onClose={onClose}
        to={to}
        dispatch={dispatch}
        state={state}
        currentUser={currentUser}
        lockReceiveEthInput={lockReceiveEthInput}
      />
    );

  return (
    <SummaryPage
      counterOfferId={counterOfferId}
      onClose={onClose}
      to={to}
      dispatch={dispatch}
      state={state}
      currentUser={currentUser}
    />
  );
};

NewOfferBuilder.fragments = {
  user: gql`
    fragment NewOfferBuilder_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      ...OfferBuilderBuildingPage_publicUserInfoInterface
      ...OfferUser_publicUserInfoInterface
    }
    ${BuildingPage.fragments.user}
    ${OfferUser.fragments.publicUserInfoInterface}
  ` as TypedDocumentNode<NewOfferBuilder_publicUserInfoInterface>,
  anyCard: anyCardFragment,
};

export default NewOfferBuilder;
