import { Sport } from '__generated__/globalTypes';

export const FEES_HELP_LINKS: Record<Sport, string> = {
  [Sport.BASEBALL]:
    'https://sorare.com/help/a/5795037458205/what-is-the-sorare-mlb-market-how-do-i-buy-sorare-mlb-cards',
  [Sport.NBA]:
    'https://sorare.com/help/a/14210188966173/what-is-the-sorare-nba-market-how-do-i-buy-sorare-nba-cards',
  [Sport.FOOTBALL]:
    'https://sorare.com/help/a/4402889674897/what-is-the-sorare-football-market-how-do-i-buy-sorare-football-cards',
};

export const LEARN_MORE_ABOUT_DOCUMENT_CHECK =
  'https://sorare.com/help/a/12382423835037/how-identity-verification-works';
export const LEARN_MORE_ABOUT_FIAT_WALLET =
  'https://sorare.com/help/a/4402888626577/what-is-a-sorare-wallet';
export const MANGOPAY_PRIVACY_POLICY = 'https://mangopay.com/privacy-statement';
export const MANGOPAY_TERMS =
  'https://mangopay.com/terms/payment-services_EN_2023.pdf';
export const NOT_SUPPORTED_COUNTRIES_LINK =
  'https://sorare.com/help/a/12441099406877/why-isn-t-cash-wallet-available-for-me-what-countries-is-cash-wallet-supported';
