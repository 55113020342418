import { useEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useNavigationType, useSearchParams } from 'react-router-dom';

import { Sport } from '__generated__/globalTypes';
import { useConfigContext } from 'contexts/config';
import { useIntlContext } from 'contexts/intl';
import { useVirtualToggleManager } from 'hooks/useVirtualToggleManager';

import { ExtendedUIState, RouteState, SEARCH_PARAMS } from '../types';
import { routeToState, stateToRoute } from '../utils';

export const NavigationHandler = ({
  sport,
  mainIndex,
  lastRouteState,
  initialUiState,
}: {
  sport: Sport | undefined;
  mainIndex: string;
  lastRouteState: RouteState;
  initialUiState: ExtendedUIState;
}) => {
  const { algoliaIndexes } = useConfigContext();
  const { setIndexUiState } = useInstantSearch<ExtendedUIState>();
  const [searchParams] = useSearchParams();
  const setVirtualToggleFilters = useVirtualToggleManager();
  const navigationType = useNavigationType();
  const { language } = useIntlContext();

  useEffect(() => {
    const algoliaParamsValues = Object.values(SEARCH_PARAMS) as string[];
    const filteredSearchParams = Object.fromEntries(
      Array.from(searchParams).filter(([key]) =>
        algoliaParamsValues.includes(key)
      )
    ) as Record<SEARCH_PARAMS, string>;

    // Serialize + Deserialize filteredSearchParams to ensure a consistent order of properties
    const stateFromRoute = routeToState(
      sport,
      filteredSearchParams,
      mainIndex,
      algoliaIndexes,
      language
    );
    const routeState = stateToRoute(
      stateFromRoute,
      mainIndex,
      algoliaIndexes,
      language
    );
    const strLastRouteState = JSON.stringify(lastRouteState);
    const strCurrentRouteState = JSON.stringify(routeState);

    if (strLastRouteState !== strCurrentRouteState) {
      let indexUiState = stateFromRoute[mainIndex];
      if (strCurrentRouteState === '{}') {
        indexUiState = initialUiState[mainIndex];
      }
      if (indexUiState.virtualToggle) {
        setVirtualToggleFilters(indexUiState.virtualToggle, false);
      }
      setIndexUiState({ ...indexUiState, shouldReplace: true });
    }
  }, [
    searchParams,
    algoliaIndexes,
    mainIndex,
    lastRouteState,
    setIndexUiState,
    setVirtualToggleFilters,
    navigationType,
    initialUiState,
    sport,
    language,
  ]);

  return null;
};
