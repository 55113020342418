import { useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

const Wrapper = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Slides = styled(animated.span)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  will-change: opacity;
`;

type SlideShowProps = {
  images: string[];
  interval?: number;
  className?: string;
};

export const SlideShow = ({
  images,
  className,
  interval = 3000,
}: SlideShowProps) => {
  const [index, setIndex] = useState(0);

  const transitions = useTransition(images[index], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1000 },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(state => (state + 1) % images.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [images.length, interval]);

  if (!images || images.length === 0) return null;
  return (
    <Wrapper className={className}>
      {transitions((props, item) => (
        <Slides style={{ ...props, backgroundImage: `url(${item})` }} />
      ))}
    </Wrapper>
  );
};
