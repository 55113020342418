// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Unreachable code error

import BigNumber from 'bignumber.js';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-extend-native
BigInt.prototype.toJSON = function toJSON(): string {
  return this.toString();
};

export const BigToBigInt = (big: BigNumber): bigint =>
  BigInt(
    big.toFormat(0, BigNumber.ROUND_DOWN, {
      decimalSeparator: '',
      groupSeparator: '',
    })
  );
