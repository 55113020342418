import { generatePath } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import { FOOTBALL_PLAY_PRO_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

const PlayProPage = () => {
  const { currentUser } = useCurrentUserContext();

  const fixture = currentUser?.forcedIntoOnboarding ? 'onboarding' : 'ongoing';

  return (
    <Navigate
      to={generatePath(FOOTBALL_PLAY_PRO_FIXTURE, {
        fixture,
      })}
      replace
    />
  );
};

export default PlayProPage;
