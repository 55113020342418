import { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { Vertical } from 'atoms/layout/flex';
import { Title4 } from 'atoms/typography';
import { TooltipFooter } from 'components/onboarding/managerTask/TooltipFooter';
import { glossary } from 'lib/glossary';

const ManagerTaskContainer: FC<
  React.PropsWithChildren<{
    labels: {
      title: MessageDescriptor;
      button?: MessageDescriptor;
    };
    current: number;
    total: number;
    onClick: () => void;
  }>
> = ({ labels, children, current, total, onClick }) => {
  return (
    <Vertical>
      <Title4>
        <FormattedMessage {...labels.title} />
      </Title4>
      {children}
      <TooltipFooter
        value={current}
        total={total}
        onClick={onClick}
        buttonLabel={labels.button || glossary.continue}
      />
    </Vertical>
  );
};

export default ManagerTaskContainer;
