import { unitMapping } from 'lib/style';

type Props = { active?: boolean; size?: keyof typeof unitMapping };

export const Market = ({ active, size = 3 }: Props) => {
  const activeFill = active ? 'currentColor' : 'none';
  const inactiveFill = active ? 'none' : 'currentColor';
  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3 10.8V8.39998L17.2336 3.59998H6.76639L2.7 8.39998V10.8H21.3ZM19.2 12.6H4.8V20.4H9.6V15.6H14.4V20.4H19.2V12.6Z"
        fill={activeFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.8 11.7V8.07003L6.3493 2.70001H17.6507L22.2 8.07003V11.7H20.1V21.3H13.5V16.5H10.5V21.3H3.9V11.7H1.8ZM20.4 9.90001V8.72999L16.8165 4.50001H7.1835L3.6 8.72999V9.90001H20.4ZM5.7 11.7V19.5H8.7V14.7H15.3V19.5H18.3V11.7H5.7Z"
        fill={inactiveFill}
      />
    </svg>
  );
};
