import { TypedDocumentNode, gql } from '@apollo/client';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Title6 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import TokenWithdrawalInfo from '../TokenWithdrawalInfo';
import {
  GetTransferRequestMutation,
  GetTransferRequestMutationVariables,
  TokenWithdrawalDialog_anyCard,
} from './__generated__/index.graphql';

type GetTransferRequestMutation_prepareCardWithdrawal_transferRequest =
  NonNullable<
    GetTransferRequestMutation['prepareCardWithdrawal']
  >['transferRequest'];

const GET_TRANSFER_REQUEST_MUTATION = gql`
  mutation GetTransferRequestMutation($input: prepareCardWithdrawalInput!) {
    prepareCardWithdrawal(input: $input) {
      transferRequest {
        ...TokenWithdrawalInfo_transferRequest
      }
      errors {
        message
        code
      }
    }
  }
  ${TokenWithdrawalInfo.fragments.transferRequest}
` as TypedDocumentNode<
  GetTransferRequestMutation,
  GetTransferRequestMutationVariables
>;

const messages = defineMessages({
  withdraw: {
    id: 'BlockchainInfo.withdraw',
    defaultMessage: 'Withdraw to Ethereum',
  },
  mintAndWithdraw: {
    id: 'BlockchainInfo.mintAndWithdraw',
    defaultMessage: 'Mint and withdraw to Ethereum',
  },
});

export interface Props {
  card: TokenWithdrawalDialog_anyCard;
}

const Body = styled.div`
  padding: var(--triple-unit);
`;
const Loading = styled.div`
  height: 460px;
`;

const TokenWithdrawalDialog = ({ card }: Props) => {
  const { assetId, ethereumOwner } = card;
  const { formatMessage } = useIntlContext();
  const [prepare] = useMutation(GET_TRANSFER_REQUEST_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const [open, setOpen] = useState(false);
  const [transferRequest, setTransferRequest] = useState<
    | GetTransferRequestMutation_prepareCardWithdrawal_transferRequest
    | null
    | undefined
  >(undefined);

  useEffect(() => {
    const getTransferRequest = async () => {
      const { data } = await prepare({
        variables: { input: { assetId } },
      });

      setTransferRequest(data?.prepareCardWithdrawal?.transferRequest || null);
    };

    if (open && transferRequest === undefined) getTransferRequest();
  }, [assetId, prepare, transferRequest, open]);

  const title = formatMessage(
    ethereumOwner ? messages.withdraw : messages.mintAndWithdraw
  );

  const renderContent = () => {
    if (transferRequest === undefined)
      return (
        <Loading>
          <LoadingIndicator />
        </Loading>
      );
    if (transferRequest === null) return 'Unable to withdraw Card';

    return (
      <TokenWithdrawalInfo
        card={card}
        transferRequest={transferRequest}
        onComplete={() => setOpen(false)}
      />
    );
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<FontAwesomeIcon icon={faEthereum} />}
        size="medium"
        color="transparent"
      >
        {title}
      </Button>
      <Dialog
        open={open}
        maxWidth="sm"
        onClose={() => setOpen(false)}
        title={<Title6 className="text-center">{title}</Title6>}
      >
        <Body>{renderContent()}</Body>
      </Dialog>
    </>
  );
};

TokenWithdrawalDialog.fragments = {
  anyCard: gql`
    fragment TokenWithdrawalDialog_anyCard on AnyCardInterface {
      slug
      ethereumOwner {
        id
      }
      ...TokenWithdrawalInfo_anyCard
    }
    ${TokenWithdrawalInfo.fragments.anyCard}
  ` as TypedDocumentNode<TokenWithdrawalDialog_anyCard>,
};

export default TokenWithdrawalDialog;
