import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { AuthBroadCastChannel } from 'lib/broadcastChannel';
import { useEvents } from 'lib/events/useEvents';
import { client as httpClient } from 'lib/http';
import { reloadPage } from 'routing/EnsureLatestAppVersion/reloadPage';

const useLogout = () => {
  const client = useApolloClient();
  const track = useEvents();
  const { isMobileApp, postMessage: mobileAppPostMessage } = useIsMobileApp();

  return useCallback(
    async () =>
      httpClient
        .delete('/users/sign_out.json')
        .then(async response => {
          if (response.status === 204) {
            track('[Client] Log Out');
            if (window.analytics) {
              window.analytics.reset();
            }
          }
          await client.clearStore();
          reloadPage();

          // log out all tabs
          AuthBroadCastChannel.postMessage('logged_out');

          // log out Flutter webview-based app
          if (isMobileApp) {
            mobileAppPostMessage('logout', { isExplicit: true });
          }

          return response;
        })
        .catch(response => ({ error: response.error })),
    [client, isMobileApp, mobileAppPostMessage, track]
  );
};

export default useLogout;
