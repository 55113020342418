export function getScrollParent(
  element: HTMLElement | null
): HTMLElement | null {
  if (element == null) {
    return null;
  }

  const { overflowY, overflowX } = window.getComputedStyle(element);
  const isScrollable =
    (overflowY !== 'visible' && overflowY !== 'hidden') ||
    (overflowX !== 'visible' && overflowX !== 'hidden');

  if (isScrollable) {
    return element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
      ? element
      : null;
  }
  return getScrollParent(element.parentElement);
}
