import { ReactNode } from 'react';
import styled from 'styled-components';

import { Tooltip } from 'atoms/tooltip/Tooltip';
import { TooltipContentWrapper } from 'atoms/tooltip/Tooltip/TooltipContentWrapper';
import { HighlightableWrapper } from 'atoms/ui/HighlightableWrapper';
import { useManagerTaskContext } from 'contexts/managerTask';

import { LearnCompetitionsOnboardingTask } from '../LearnCompetitionsOnboardingTask';
import { MarketplaceOnboardingTask } from '../MarketplaceOnboardingTask';

type TaskComponentType =
  | typeof LearnCompetitionsOnboardingTask
  | typeof MarketplaceOnboardingTask;

type Props<T extends TaskComponentType> = Pick<
  React.ComponentProps<typeof Tooltip>,
  'placement'
> & {
  children?: ReactNode;
  TaskComponent: T;
  taskProps?: Omit<React.ComponentProps<T>, 'onClick' | 'name'>;
  name: React.ComponentProps<T>['name'];
  noBackdrop?: boolean;
  inModal?: boolean;
  forceAreaHighlight?: boolean;
  fullWidth?: boolean;
  disable?: boolean;
  backgroundColor?: string;
  onClick: () => void;
};

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > ${TooltipContentWrapper} {
    display: block;
    width: 100%;
  }
`;

const OverlayButton = styled.div.attrs({ role: 'button' })`
  cursor: pointer;
  & > * {
    pointer-events: none;
  }
`;

const TooltipContent = styled.div`
  padding: 0 var(--unit);
  max-width: min(calc(100vw - var(--unit)), 340px);
`;

export const ManagerTaskTooltip = <T extends TaskComponentType>(
  props: Props<T>
) => {
  const {
    children,
    name,
    TaskComponent,
    taskProps,
    noBackdrop,
    placement,
    forceAreaHighlight = false,
    disable = false,
    backgroundColor,
    onClick,
  } = props;
  const { step } = useManagerTaskContext();
  const tooltipOpen = name === step;
  const highlightOpen = tooltipOpen || forceAreaHighlight;
  if (disable) return <>{children}</>;

  return (
    <HighlightableWrapper
      backgroundColor={backgroundColor}
      highlightOpen={highlightOpen}
      noBackdrop={noBackdrop}
    >
      <Center>
        <Tooltip
          disableFocusListener
          title={
            <TooltipContent>
              {/* @ts-expect-error TS2322 typescript seems to be confused by the generic component here */}
              <TaskComponent {...taskProps} onClick={onClick} name={name} />
            </TooltipContent>
          }
          open={tooltipOpen}
          placement={placement}
        >
          <OverlayButton onClick={onClick}>{children}</OverlayButton>
        </Tooltip>
      </Center>
    </HighlightableWrapper>
  );
};
