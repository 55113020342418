import { useCallback, useMemo } from 'react';

import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import { useFiatBalance } from '@sorare/core/src/hooks/wallets/useFiatBalance';
import { getMonetaryAmountIndex } from '@sorare/core/src/lib/monetaryAmount';

type Args =
  | {
      monetaryAmountToPayWithFiatWallet: MonetaryAmountOutput;
      monetaryAmountToPayWithEthWallet: MonetaryAmountOutput;
    }
  | MonetaryAmountOutput;

export const useHasInsufficientFundsInWallets = () => {
  const { currentUser } = useCurrentUserContext();
  const { availableBalanceInCents, fiatCurrency } = useFiatBalance();

  const availableBalance = useMemo(
    () => currentUser?.availableBalance || 0n,
    [currentUser]
  );

  const diff = (balance: bigint, weiAmountToPay: bigint) =>
    weiAmountToPay - balance;

  const bigAvailableFiatCentsBalance = BigInt(availableBalanceInCents);

  return useCallback(
    (args: Args) => {
      const monetaryAmountToPayWithEthWallet =
        'monetaryAmountToPayWithEthWallet' in args
          ? args.monetaryAmountToPayWithEthWallet
          : args;
      const monetaryAmountToPayWithFiatWallet =
        'monetaryAmountToPayWithFiatWallet' in args
          ? args.monetaryAmountToPayWithFiatWallet
          : args;

      const insufficientFundsInEthWallet =
        availableBalance < monetaryAmountToPayWithEthWallet.wei;
      const diffInWeiForEthWallet =
        (insufficientFundsInEthWallet &&
          diff(availableBalance, monetaryAmountToPayWithEthWallet.wei)) ||
        undefined;
      const insufficientFundsInFiatWallet =
        bigAvailableFiatCentsBalance <
        monetaryAmountToPayWithFiatWallet[getMonetaryAmountIndex(fiatCurrency)];
      const diffInFiatCentsForFiatWallet =
        (insufficientFundsInFiatWallet &&
          diff(
            bigAvailableFiatCentsBalance,
            BigInt(
              monetaryAmountToPayWithFiatWallet[
                getMonetaryAmountIndex(fiatCurrency)
              ]
            )
          )) ||
        undefined;
      return {
        insufficientFundsInEthWallet,
        diffInWeiForEthWallet,
        insufficientFundsInFiatWallet,
        diffInFiatCentsForFiatWallet,
      };
    },
    [availableBalance, bigAvailableFiatCentsBalance, fiatCurrency]
  );
};

export default useHasInsufficientFundsInWallets;
