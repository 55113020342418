import { keyframes } from 'styled-components';

export const shapeAnimationFromLeftMobile = keyframes`
  0% {
    opacity: 1;
    background-position: left, -100vw, -100vw;
  }

  30% {
    opacity: 1;
    background-position: left, -100vw, -31vw;
  }

  60% {
    opacity: 1;
    background-position: left, -45vw, -31vw;
  }

  100% {
    opacity: 0;
    background-position: left, -45vw, -31vw;
  }
`;

export const shapeAnimationFromLeft = keyframes`
  0% {
    opacity: 1;
    background-position: left, -100%, -100%;
  }

  30% {
    opacity: 1;
    background-position: left, 0%, -70%;
  }

  50% {
    opacity: 1;
    background-position: left, 0%, -10%;
  }

  100% {
    opacity: 0;
    background-position: left, 0%, -10%;
  }
`;

export const shapeAnimationFromRight = keyframes`
  0% {
    opacity: 1;
      background-position: right, 200%, 210%;
  }

  30% {
    opacity: 1;
    background-position: right, 100%, 190%;
  }

  50% {
    opacity: 1;
    background-position: right, 100%, 110%;
  }

  100% {
    opacity: 0;
    background-position: right, 100%, 110%;
  }
`;

export const shapeAnimationFromRightMobile = keyframes`
  0% {
    opacity: 1;
    background-position: right, 100vw, 100vw;
  }

  30% {
    opacity: 1;
    background-position: right, 100vw, 31vw;
  }

  60% {
    opacity: 1;
    background-position: right, 45vw, 31vw;
  }

  100% {
    opacity: 0;
    background-position: right, 45vw, 31vw;
  }
`;
