import { unitMapping } from 'lib/style';

type Props = {
  className?: string;
  size?: keyof typeof unitMapping;
};

export const LineupsIcon = ({ size = 2, className }: Props) => {
  return (
    <svg
      style={{ width: unitMapping[size] }}
      className={className}
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.960313 6.24887V2.02463L2.56031 2.4422V6.23159H3.20031V1.65925L5.12379 2.18305V6.23159H5.76031V0.199951L10.5603 0.761983V6.23159H11.1968V2.11505L12.8003 1.65925C12.8003 1.65925 12.8003 2.22157 12.8003 2.58188C12.8003 4.00718 12.8003 6.23159 12.8003 6.23159L13.448 6.23931V2.46793L15.0227 2.03234V6.26835L16.3203 6.23931L8.16031 8.83995V8.82966L0.000312805 6.22902L0.960313 6.24887Z"
        fill="white"
      />
    </svg>
  );
};
