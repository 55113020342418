import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentType, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { KnownTarget } from 'styled-components/dist/types';

import {
  AveragePlayerScore,
  Position,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { Select } from '@sorare/core/src/atoms/inputs/Select';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, LabelS, Title6 } from '@sorare/core/src/atoms/typography';
import { PlayerScore } from '@sorare/core/src/components/scoring/PlayerScore';
import { isType } from '@sorare/core/src/gql';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import {
  cardAttributes,
  fantasy,
  glossary,
} from '@sorare/core/src/lib/glossary';
import {
  getAppearancePercentage,
  positionNames,
} from '@sorare/core/src/lib/players';

import CardBonus from '@sorare/marketplace/src/components/card/CardBonus';

import { GameWeekProjection } from 'components/GameWeekProjection';

import { FixtureChart, Props as FixtureChartProps } from './FixtureChart';
import {
  LastScores_anyCard,
  LastScores_anyPlayer,
  LastScores_playerGameScoreInterface,
} from './__generated__/index.graphql';

export type Props = {
  card?: LastScores_anyCard;
  so5Scores: (LastScores_playerGameScoreInterface | null)[];
  player: LastScores_anyPlayer;
  cardPositions?: Position[];
  setPosition?: (position?: Position) => void;
  selectedPosition?: Position;
  InfiniteScrollLoader?: ReactNode;
  TitleComponent?: ComponentType<{ children: ReactNode; as: KnownTarget }>;
  onScoreSelect?: FixtureChartProps['onScoreSelect'];
  inDrawer?: boolean;
};

const Root = styled(Vertical).attrs({ gap: 4 })`
  overflow: auto;
`;
const Section = styled(Vertical)`
  overflow: hidden;
`;
const Title = styled(SBHorizontal)`
  flex-wrap: wrap;
`;
const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: calc(var(--unit) * 6);
  grid-auto-columns: max-content;
  overflow-y: hidden;
  max-width: 100%;
  align-items: start;
`;

const PerformancesSummary = ({
  player,
  card,
}: {
  card?: LastScores_anyCard;
  player: LastScores_anyPlayer;
}) => {
  const {
    flags: { useDailyStatProjection = false },
  } = useFeatureFlags();

  const [lastTenOrFifteenMode, lastTenOrFifteenLabel] =
    player.sport === Sport.NBA
      ? [
          AveragePlayerScore.LAST_TEN_PLAYED_SO5_AVERAGE_SCORE,
          cardAttributes.last10,
        ]
      : [
          AveragePlayerScore.LAST_FIFTEEN_SO5_AVERAGE_SCORE,
          cardAttributes.last15,
        ];

  return (
    <Grid>
      <Vertical center>
        <LabelS as="p" color="var(--c-neutral-600)">
          <FormattedMessage {...cardAttributes.last5} />
        </LabelS>
        <PlayerScore
          player={player}
          mode={AveragePlayerScore.LAST_FIVE_SO5_AVERAGE_SCORE}
        />
        {isType(player, 'Player') && (
          <BodyS color="var(--c-neutral-600)">
            <FormattedMessage
              {...fantasy.percentagePlayed}
              values={{
                percent:
                  +getAppearancePercentage(
                    AveragePlayerScore.LAST_FIVE_SO5_AVERAGE_SCORE,
                    player
                  ) / 100,
              }}
            />
          </BodyS>
        )}
      </Vertical>
      <Vertical center>
        <LabelS as="p" color="var(--c-neutral-600)">
          <FormattedMessage {...lastTenOrFifteenLabel} />
        </LabelS>
        <PlayerScore player={player} mode={lastTenOrFifteenMode} />
        {isType(player, 'Player') && (
          <BodyS color="var(--c-neutral-600)">
            <FormattedMessage
              {...fantasy.percentagePlayed}
              values={{
                percent:
                  +getAppearancePercentage(lastTenOrFifteenMode, player) / 100,
              }}
            />
          </BodyS>
        )}
      </Vertical>
      {useDailyStatProjection && isType(player, 'BaseballPlayer') && (
        <Vertical center>
          <LabelS as="p" color="var(--c-neutral-600)">
            <FormattedMessage {...glossary.projectionShort} />
          </LabelS>
          <GameWeekProjection player={player} />
        </Vertical>
      )}
      {card && (
        <Vertical center>
          <LabelS as="p" color="var(--c-neutral-600)">
            <FormattedMessage {...cardAttributes.bonus} />
          </LabelS>
          <CardBonus card={card} />
        </Vertical>
      )}
    </Grid>
  );
};

const LastScores = ({
  cardPositions = [],
  setPosition,
  selectedPosition,
  so5Scores,
  player,
  card,
  InfiniteScrollLoader,
  TitleComponent = Title6,
  onScoreSelect,
  inDrawer,
}: Props) => {
  const { formatMessage } = useIntl();

  if (!so5Scores?.length) return null;

  return (
    <Root>
      <Section>
        <SBHorizontal>
          <Title6>
            <FormattedMessage id="LastScores.stats" defaultMessage="Stats" />
          </Title6>
          {cardPositions.length > 1 && setPosition && (
            <Title>
              <Select
                menuLateralAlignment="right"
                value={
                  selectedPosition && {
                    value: selectedPosition,
                    label: formatMessage(positionNames[selectedPosition]),
                  }
                }
                onChange={e => {
                  setPosition(e!.value as Position);
                }}
                options={cardPositions.map(value => ({
                  value,
                  label: formatMessage(positionNames[value]),
                }))}
              />
            </Title>
          )}
        </SBHorizontal>
        <PerformancesSummary player={player} card={card} />
      </Section>
      <Section>
        <FixtureChart
          so5Scores={so5Scores}
          player={player}
          TitleComponent={TitleComponent}
          InfiniteScrollLoader={InfiniteScrollLoader}
          onScoreSelect={onScoreSelect}
          inDrawer={inDrawer}
        />
      </Section>
    </Root>
  );
};

LastScores.fragments = {
  playerGameScoreInterface: gql`
    fragment LastScores_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      ...FixtureChart_playerGameScoreInterface
    }
    ${FixtureChart.fragments.playerGameScoreInterface}
  ` as TypedDocumentNode<LastScores_playerGameScoreInterface>,
  anyPlayer: gql`
    fragment LastScores_anyPlayer on AnyPlayerInterface {
      slug
      sport
      ... on BaseballPlayer {
        slug
        rotowireProjectedHitterScore
        rotowireProjectedPitcherScore
      }
      ...FixtureChart_anyPlayer
      ...PlayerScore_anyPlayer
      ...GameWeekProjection_AnyPlayerInterface
      ...getAppearancePercentage_anyPlayerInterface
    }
    ${FixtureChart.fragments.anyPlayer}
    ${PlayerScore.fragments.anyPlayer}
    ${getAppearancePercentage.fragments.anyPlayer}
    ${GameWeekProjection.fragments.anyPlayerInterface}
  ` as TypedDocumentNode<LastScores_anyPlayer>,
  anyCard: gql`
    fragment LastScores_anyCard on AnyCardInterface {
      slug
      ...CardBonus_WithEngine_anyCard
    }
    ${CardBonus.fragments.anyCardWithEngine}
  ` as TypedDocumentNode<LastScores_anyCard>,
};

export default LastScores;
