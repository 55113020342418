import { TypedDocumentNode, gql } from '@apollo/client';
import { SearchParameters } from 'algoliasearch-helper';
import { BaseHit } from 'instantsearch.js';
import shuffle from 'shuffle-array';

import {
  BaseballPlayerPosition,
  Collection,
  NBAPlayerPosition,
  Position,
  Rarity,
  Sport,
  Tradeable,
  WalletStatus,
} from '__generated__/globalTypes';
import { FRONTEND_ASSET_HOST } from 'constants/assets';
import { AlgoliaCardIndexesNames } from 'contexts/config';
import { idFromObject } from 'gql/idFromObject';

import { Algolia_CardHit_anyCard } from './__generated__/algolia.graphql';
import { OLD_US_SPORTS_SUPPLY, SUPPLY, Scarcity } from './cards';

type AlgoliaSport = 'baseball' | 'football' | 'nba';

export const TOKENS_STACKED_LIMIT = 2;

export interface CardHit extends BaseHit {
  objectID: string;
  asset_id: string;
  rarity: Scarcity;
  serial_number: number;
  season: string;
  tradeable_status: string;
  wallet_status: string;
  picture_url: string;
  video_urls?: {
    webm: string;
    mov: string;
  };
  player: {
    slug: string;
    display_name: string;
  };
  user: {
    slug: string;
  };
  team: {
    slug: string;
    long_name: string;
  };
  sport: AlgoliaSport;
  position: Position;
  __position: number;
  power: string;
  sale?: SaleHit;
}

const AlgoliaSportsMappings: Readonly<{ [key in AlgoliaSport]: Sport }> = {
  nba: Sport.NBA,
  football: Sport.FOOTBALL,
  baseball: Sport.BASEBALL,
};

const SportsMappings: Readonly<{ [key in Sport]: AlgoliaSport }> = {
  [Sport.NBA]: 'nba',
  [Sport.FOOTBALL]: 'football',
  [Sport.BASEBALL]: 'baseball',
};

const FakeCollectionMappings: Readonly<{ [key in AlgoliaSport]: Collection }> =
  {
    nba: Collection.NBA,
    baseball: Collection.BASEBALL,
    football: Collection.FOOTBALL,
  };

export interface SaleHit {
  id: string;
  price: number;
}

export interface UserHit {
  objectID: string;
  slug: string;
  id: string;
  profile: { picture: string; verified?: boolean };
  nickname: string;
  sorare_address_hex: string;
}

export interface ClubHit extends BaseHit {
  domestic_league: { display_name: string };
  objectID: string;
  pictureUrl: string;
  name: string;
  released: boolean;
  __position: number;
}

export interface CountryHit {
  objectID: string;
  code: string;
  name_en: string;
}

export interface PlayerHit {
  objectID: string;
  avatarUrl: string;
  display_name: string;
  activeClub?: {
    name: string;
  };
}

export interface CompetitionHit {
  objectID: string;
  display_name: string;
  display_name_ar: string;
  display_name_de: string;
  display_name_en: string;
  display_name_es: string;
  display_name_fr: string;
  display_name_it: string;
  display_name_tr: string;
  display_name_ru: string;
  released: boolean;
  open_for_game_stats: boolean;
  code: string;
  pictureUrl: string;
}

export const convertUserHit = (
  hit: UserHit
): {
  __typename: 'User';
  slug: string;
  id: string;
  nickname: string;
  sorareAddress: string;
  suspended: boolean;
  pictureUrl: string;
  profile: {
    __typename: 'UserProfile';
    id: string;
    badge: {
      __typename: 'UserProfileBadge';
      slug: string;
      pictureUrl: string;
      description: string;
    } | null;
  };
} => ({
  __typename: 'User',
  id: hit.id,
  slug: hit.objectID,
  nickname: hit.nickname,
  sorareAddress: hit.sorare_address_hex,
  suspended: false,
  pictureUrl: hit.profile?.picture,
  profile: {
    __typename: 'UserProfile',
    id: hit.id,
    badge: hit.profile.verified
      ? {
          __typename: 'UserProfileBadge',
          slug: 'verified',
          description: 'Verified',
          pictureUrl: `${FRONTEND_ASSET_HOST}/users/badges/verified.png`,
        }
      : null,
  },
});

export const algoliaCardObjectIdPrefix: { [key in Sport]: string } = {
  [Sport.BASEBALL]: 'baseball-assetId',
  [Sport.NBA]: 'baseball-assetId',
  [Sport.FOOTBALL]: '',
};

export const algoliaCommonCardObjectIdPrefix: { [key in Sport]: string } = {
  [Sport.BASEBALL]: 'baseball-cardId',
  [Sport.NBA]: 'baseball-cardId',
  [Sport.FOOTBALL]: '',
};

export enum SaleType {
  SINGLE_SALE_OFFER = 'SingleSaleOffer',
  ENGLISH_AUCTION = 'EnglishAuction',
  PRIMARY_OFFER = 'PrimaryOffer',
}

export type MarketplaceHit = {
  objectID: string;
  sale?: { distinct_key: string | null; type: string | null };
  player?: { slug: string | null };
  rarity?: string | null;
  season?: number | null;
  slug: string;
};

export const buildAlgoliaObjectId = (card: {
  slug: string;
  assetId: string;
  sport: Sport;
}) => {
  if (card.sport === Sport.FOOTBALL) return card.slug;

  return `${algoliaCardObjectIdPrefix[card.sport]}:${card.assetId}`;
};

export const convertToCardHit = (card: Algolia_CardHit_anyCard): CardHit => ({
  ...card,
  objectID: buildAlgoliaObjectId(card),
  rarity: card.rarityTyped as Scarcity,
  serial_number: card.serialNumber,
  tradeable_status: card.tradeableStatus,
  wallet_status: card.walletStatus,
  picture_url: card.pictureUrl!,
  team: {
    slug: card.anyTeam.slug,
    long_name: card.anyTeam.name,
  },
  player: {
    slug: card.anyPlayer.slug,
    display_name: card.anyPlayer.displayName,
  },
  season: card.seasonYear.toString() || '',
  user: {
    slug: card.user?.slug || '',
  },
  sport: SportsMappings[card.sport],
  position: card.anyPositions[0],
  __position: 10,
  asset_id: card.assetId,
  power: '',
});

const convertCardHitToToken = (hit: CardHit) => ({
  ...hit,
  serialNumber: hit.serial_number,
  blockchainId: hit.blockchain_id,
  tradeableStatus: hit.tradeable_status as Tradeable,
  walletStatus: hit.wallet_status as WalletStatus,
  pictureUrl: hit.picture_url,
  anyPositions: [hit.position],
  anyPlayer: {
    __typename: 'Player' as const,
    slug: hit.player.slug,
    displayName: hit.player.display_name,
  },
  anyTeam: {
    __typename: 'Club' as const,
    slug: hit.team.slug,
    name: hit.team.long_name,
  },
  rarityTyped: hit.rarity as Rarity,
  displayRarity: hit.rarity,
  seasonYear: Number(hit.season),
  singleCivilYear: hit.sport === 'baseball',
  // FIXME we'd need the supply to be indexed
  supply: (['nba', 'baseball'].includes(hit.sport) && hit.season < '2023'
    ? OLD_US_SPORTS_SUPPLY
    : SUPPLY)[hit.rarity as Rarity],
  user: hit.user
    ? {
        __typename: 'User' as const,
        slug: hit.user.slug,
      }
    : null,
  tokenOwner: hit.user
    ? {
        __typename: 'TokenOwner' as const,
        id: hit.user.slug,
        user: { __typename: 'User' as const, slug: hit.user.slug },
      }
    : null,
  sport: AlgoliaSportsMappings[hit.sport] || Sport.FOOTBALL,
  collection: FakeCollectionMappings[hit.sport] || Collection.FOOTBALL, // this assertion is factually wrong but that's a separate concern
});

const convertCardHitToTypename = (hit: CardHit) => {
  switch (hit.sport) {
    case 'baseball':
      return 'BaseballCard';
    case 'nba':
      return 'NBACard';
    default:
      return 'Card';
  }
};

export const convertCardHitToCard = (
  hit: CardHit
):
  | {
      __typename: 'Card';
      assetId: string;
      slug: string;
      sport: Sport;
      rarityTyped: Rarity;
      displayRarity: string;
      pictureUrl: string;
      seasonYear: number;
      singleCivilYear: boolean;
      serialNumber: number;
      supply: number;
      anyPositions: Position[];
      anyTeam: {
        __typename: 'Club';
        slug: string;
        name: string;
      };
      anyPlayer: {
        __typename: 'Player';
        slug: string;
        displayName: string;
      };
      user: {
        __typename: 'User';
        slug: string;
      } | null;
      tokenOwner: {
        __typename: 'TokenOwner';
        id: string;
        user: {
          __typename: 'User';
          slug: string;
        };
      } | null;
      webmVideoUrl: string | null;
      webmLowResVideoUrl: string | null;
      movVideoUrl: string | null;
      movLowResVideoUrl: string | null;
    }
  | {
      __typename: 'BaseballCard';
      slug: string;
      baseballPositions: BaseballPlayerPosition[];
      assetId: string;
      sport: Sport;
      rarityTyped: Rarity;
      displayRarity: string;
      pictureUrl: string;
      seasonYear: number;
      singleCivilYear: boolean;
      serialNumber: number;
      supply: number;
      anyPositions: Position[];
      anyTeam: {
        __typename: 'Club';
        slug: string;
        name: string;
      };
      anyPlayer: {
        __typename: 'Player';
        slug: string;
        displayName: string;
      };
      user: {
        __typename: 'User';
        slug: string;
      } | null;
      tokenOwner: {
        __typename: 'TokenOwner';
        id: string;
        user: {
          __typename: 'User';
          slug: string;
        };
      } | null;
      webmVideoUrl: string | null;
      webmLowResVideoUrl: string | null;
      movVideoUrl: string | null;
      movLowResVideoUrl: string | null;
    }
  | {
      __typename: 'NBACard';
      assetId: string;
      slug: string;
      nbaPositions: NBAPlayerPosition[];
      sport: Sport;
      rarityTyped: Rarity;
      displayRarity: string;
      pictureUrl: string;
      seasonYear: number;
      singleCivilYear: boolean;
      serialNumber: number;
      supply: number;
      anyPositions: Position[];
      anyTeam: {
        __typename: 'Club';
        slug: string;
        name: string;
      };
      anyPlayer: {
        __typename: 'Player';
        slug: string;
        displayName: string;
      };
      user: {
        __typename: 'User';
        slug: string;
      } | null;
      tokenOwner: {
        __typename: 'TokenOwner';
        id: string;
        user: {
          __typename: 'User';
          slug: string;
        };
      } | null;
      webmVideoUrl: string | null;
      webmLowResVideoUrl: string | null;
      movVideoUrl: string | null;
      movLowResVideoUrl: string | null;
    } => ({
  __typename: convertCardHitToTypename(hit) as any,
  slug: hit.objectID,
  assetId: hit.asset_id,
  baseballPositions: [],
  nbaPositions: [],
  ...convertCardHitToToken(hit),
  webmVideoUrl: hit.video_urls?.webm || null,
  webmLowResVideoUrl: hit.video_urls?.webm || null,
  movVideoUrl: hit.video_urls?.mov || null,
  movLowResVideoUrl: hit.video_urls?.mov || null,
});

export const tokenHitFragment = gql`
  fragment Algolia_CardHit_anyCard on AnyCardInterface {
    assetId
    slug
    sport
    rarityTyped
    serialNumber
    seasonYear
    anyPositions
    anyTeam {
      slug
      name
    }
    anyPlayer {
      slug
      displayName
    }
    pictureUrl
    user {
      slug
    }
    tradeableStatus
    walletStatus
  }
` as TypedDocumentNode<Algolia_CardHit_anyCard>;

export function isCardHit(card: any): card is CardHit {
  return (card as CardHit).objectID !== undefined;
}

export const visibleCardFilter = 'visible=1';
export const releasedPlayerFilter = 'released=1';

const tradeableWalletStatus = ['internal', 'mapped'];

export const sportFilter = (sport: Sport): string =>
  `sport:${sport.toLowerCase()}`;

const sanitizeFilters = (filters: (string | null | false | undefined)[]) =>
  filters.filter(Boolean).filter(string => string !== '');

export const joinFiltersWithAnd = (
  filters: (string | null | false | undefined)[]
) => sanitizeFilters(filters).join(' AND ');

export const joinFiltersWithOr = (
  filters: (string | null | false | undefined)[]
) => (filters.length ? `(${sanitizeFilters(filters).join(' OR ')})` : '');

export function mergeResults<T extends { objectID: string }>(
  hits: T[],
  saved: T[]
) {
  const ids = hits.map(o => o.objectID);
  return saved.reduce((all, selectedCard) => {
    if (ids.includes(selectedCard.objectID)) {
      return all;
    }
    return [selectedCard, ...all];
  }, hits || []);
}

export function getAlgoliaHosts(
  algoliaApplicationId: string,
  useAlgoliaGeoOptimizedReadReplica: boolean
) {
  // APPID-dsn.algolia.net: the Algolia read replica that is the closest to the end-user's IP address (geoip)
  // APPID.algolia.net: one of the 3 machines of the primary cluster (round-robin)
  //
  // We build the algolia hosts to target following this strategy (the client will try one after the other):
  //  - first & main host:
  //     either use the DSN (closest) hostname if useAlgoliaGeoOptimizedReadReplica is TRUE
  //     or the regular round-robin between the 3 primary machines
  //  - next 3 hosts (DNS provider = NS1):
  //     the 3 primary machines on the `algolia.net` domain
  //  - next 3 hosts (DNS provider = Cloudflare, used when NS1 is down):
  //     the 3 primary machines on the `algolianet.com` domain
  const firstHost = `${algoliaApplicationId}${
    useAlgoliaGeoOptimizedReadReplica ? '-dsn' : ''
  }.algolia.net`;
  const algoliaHosts = [firstHost]
    .concat(
      shuffle([
        `${algoliaApplicationId}-1.algolia.net`,
        `${algoliaApplicationId}-2.algolia.net`,
        `${algoliaApplicationId}-3.algolia.net`,
      ])
    )
    .concat(
      shuffle([
        `${algoliaApplicationId}-1.algolianet.com`,
        `${algoliaApplicationId}-2.algolianet.com`,
        `${algoliaApplicationId}-3.algolianet.com`,
      ])
    );

  return algoliaHosts;
}

export const getUserCardFilters = (userId: string | undefined): string => {
  const filtersArray = [
    visibleCardFilter,
    `tradeable_status:yes`,
    `(${tradeableWalletStatus.map(r => `wallet_status:${r}`).join(' OR ')})`,
  ];
  if (userId) filtersArray.push(`user.id:${userId}`);
  return joinFiltersWithAnd(filtersArray);
};

export type EnablePopularPlayerMarketplaceFilterFlagValue =
  | 'enabled-and-default'
  | 'enabled'
  | 'disabled';

export type CardSortConfiguration = {
  indexPrefix?: 'Cards On Sale';
  withBestValue?: boolean;
  withHighestAverageScore?: true;
  withPopularPlayer?: true;
  withHighestPrice?: boolean;
  withEndingSoon?: boolean;
  withNew?: boolean;
};

export const createCardSorts = ({
  indexPrefix,
  withBestValue,
  withHighestAverageScore,
  withPopularPlayer,
  withHighestPrice = true,
  withEndingSoon = true,
  withNew = true,
}: CardSortConfiguration = {}): AlgoliaCardIndexesNames => {
  const result: AlgoliaCardIndexesNames = [
    ...(withNew ? (['Newly Listed'] as AlgoliaCardIndexesNames) : []),
    ...(withEndingSoon ? (['Ending Soon'] as AlgoliaCardIndexesNames) : []),
    'Lowest Price',
  ];

  if (withHighestPrice) {
    result.splice(2, 0, 'Highest Price');
  }
  if (withPopularPlayer) {
    result.splice(1, 0, 'Popular Player');
  }
  if (withBestValue) {
    result.splice(1, 0, 'Best Value');
  }
  if (withHighestAverageScore) {
    result.push('Highest Average Score');
  }
  return result.map(sort =>
    indexPrefix ? `${indexPrefix} ${sort}` : sort
  ) as AlgoliaCardIndexesNames;
};

export interface StackProps {
  algoliaDistinctKey: string;
  params?: SearchParameters;
  count: number;
}

export const assetIdFromHit = ({
  objectID,
  asset_id,
  sport,
}: {
  objectID: string;
  asset_id: string;
  sport: string;
}) => {
  if (sport === 'football') {
    return asset_id;
  }
  return idFromObject(objectID)!;
};
