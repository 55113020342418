import { Navigate, generatePath, useOutletContext } from 'react-router-dom';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { FOOTBALL_PLAY_INVENTORY_BOXES_TYPE } from '@sorare/core/src/constants/__generated__/routes';

const InventoryPage = () => {
  const { unopenedProbabilisticBundlesByRarity } =
    useOutletContext<{
      unopenedProbabilisticBundlesByRarity: Record<string, number>;
    }>() ?? {};

  if (unopenedProbabilisticBundlesByRarity === undefined) {
    return <LoadingIndicator fullHeight />;
  }
  const type = Object.entries(unopenedProbabilisticBundlesByRarity).reduce(
    (acc, [t, number]) => (number > 0 ? t : acc),
    Rarity.limited.toString()
  );

  return (
    <Navigate
      replace
      to={generatePath(FOOTBALL_PLAY_INVENTORY_BOXES_TYPE, {
        type,
      })}
    />
  );
};

export default InventoryPage;
