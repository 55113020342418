import { TypedDocumentNode, gql } from '@apollo/client';

import { UsedInLineupValidator } from './UsedInLineupValidator';
import { TokenTransferValidator_anyCard } from './__generated__/index.graphql';
import { TokenTransferValidatorProps } from './types';

export const TokenTransferValidator = ({
  cards,
  children,
  shouldValidate = true,
  transferContext,
}: TokenTransferValidatorProps) => {
  if (!shouldValidate) {
    return (
      <>
        {children({
          validationMessages: {},
          loading: false,
        })}
      </>
    );
  }

  return (
    <UsedInLineupValidator
      transferType={transferContext}
      cards={cards}
      render={children}
    />
  );
};

TokenTransferValidator.fragments = {
  anyCard: gql`
    fragment TokenTransferValidator_anyCard on AnyCardInterface {
      slug
      ...UsedInLineupValidator_anyCard
    }
    ${UsedInLineupValidator.fragments.anyCard}
  ` as TypedDocumentNode<TokenTransferValidator_anyCard>,
};
