import { gql } from '@apollo/client';

/**
 * This helper function makes gql`` calls lazy, which avoids incurring the parse
 * cost for all queries at module load time
 */
/* #__NO_SIDE_EFFECTS__ */
export function lazyGql(a: string[], ...rest: unknown[]) {
  let init = false;

  function ensureInit(target: Record<string, never>) {
    if (!init) {
      Object.assign(target, gql(a, ...rest));
      init = true;
    }
  }

  return new Proxy(
    {},
    {
      get(target, prop, receiver) {
        ensureInit(target);
        return Reflect.get(target, prop, receiver);
      },
      has(target, prop) {
        ensureInit(target);
        return Reflect.has(target, prop);
      },
      getOwnPropertyDescriptor(target, prop) {
        ensureInit(target);
        return Reflect.getOwnPropertyDescriptor(target, prop);
      },
      ownKeys(target) {
        ensureInit(target);
        return Reflect.ownKeys(target);
      },
    }
  );
}
