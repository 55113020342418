import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Bold } from 'atoms/typography/Bold';
import { DumbNotification } from 'components/activity/DumbNotification';
import { FOOTBALL_MY_CLUB_SLUG } from 'constants/__generated__/routes';

import { commonNotificationInterfaceFragment } from '../fragments';
import { UserRelationNotification_userRelationNotification } from './__generated__/index.graphql';

type Props = {
  notification: UserRelationNotification_userRelationNotification;
};

const messages = defineMessages({
  user_relation_followed: {
    id: 'Activity.Notifications.userRelationFollowed',
    defaultMessage:
      '<b>@{nickname}</b>{relatedUserName} found you in his contacts and is now following you!',
  },
  user_relation_revealed: {
    id: 'Activity.Notifications.userRelationRevealed',
    defaultMessage:
      'Your friend {relatedUserName} (<b>@{nickname}</b>) is a Sorare manager. Give him a follow!',
  },
});

export const UserRelationNotification = ({ notification, ...rest }: Props) => {
  const { createdAt, name, read, userRelation } = notification;
  const { userDiscoverableName, user, knownUser, knownAs } = userRelation;

  let relatedUser;
  let relatedUserName;

  if (!Object.keys(messages).includes(name)) return null;

  const message = messages[name as keyof typeof messages];

  switch (name) {
    case 'user_relation_followed':
      relatedUser = user;
      relatedUserName = userDiscoverableName
        ? ` (${userDiscoverableName})`
        : '';
      break;
    case 'user_relation_revealed':
      relatedUser = knownUser;
      relatedUserName = knownAs;
      break;
    default:
  }

  if (!relatedUser) return null;

  const link = generatePath(FOOTBALL_MY_CLUB_SLUG, {
    slug: relatedUser.slug,
  });

  return (
    <DumbNotification
      title={
        <FormattedMessage
          {...message}
          values={{
            b: Bold,
            nickname: relatedUser.nickname,
            relatedUserName,
          }}
        />
      }
      userAvatar={relatedUser}
      link={link}
      createdAt={createdAt}
      read={read}
      sport={null}
      {...rest}
    />
  );
};

UserRelationNotification.fragments = {
  userRelationNotification: gql`
    fragment UserRelationNotification_userRelationNotification on UserRelationNotification {
      id
      ...Notification_notificationInterface
      userRelation {
        id
        knownAs
        userDiscoverableName
        user {
          slug
          nickname
          ...DumbNotification_userAvatar
        }
        knownUser {
          slug
          nickname
          ...DumbNotification_userAvatar
        }
      }
    }
    ${commonNotificationInterfaceFragment}
    ${DumbNotification.fragments.avatarUser}
  ` as TypedDocumentNode<UserRelationNotification_userRelationNotification>,
};
