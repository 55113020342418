import { TypedDocumentNode, gql } from '@apollo/client';

import { AnyPlayerStatsIcon } from 'components/player/AnyPlayerStatsIcon';

import { TokenStatsIcon_anyCard } from './__generated__/index.graphql';

interface Props {
  card: TokenStatsIcon_anyCard;
  onInfoClick?: () => void;
}

export const TokenStatsIcon = ({ card, onInfoClick }: Props) => {
  return <AnyPlayerStatsIcon player={card.anyPlayer} onClick={onInfoClick} />;
};

TokenStatsIcon.fragments = {
  anyCard: gql`
    fragment TokenStatsIcon_anyCard on AnyCardInterface {
      slug
      anyPlayer {
        slug
        ...AnyPlayerStatsIcon_anyPlayer
      }
    }
    ${AnyPlayerStatsIcon.fragments.anyPlayer}
  ` as TypedDocumentNode<TokenStatsIcon_anyCard>,
};

export default TokenStatsIcon;
