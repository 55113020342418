import { TypedDocumentNode, gql } from '@apollo/client';

import { CardCoverageStatus } from '@sorare/core/src/__generated__/globalTypes';
import { getCardCoverageDescription } from '@sorare/core/src/lib/cardCoverage';

import So5Eligibility from '../So5Eligibility';
import { ItemEligibility_card } from './__generated__/index.graphql';

type Props = {
  cards: ItemEligibility_card[];
};

export const ItemEligibility = ({ cards }: Props) => {
  const firstCoverageStatus = cards.find(
    card => card.coverageStatus !== CardCoverageStatus.FULL
  )?.coverageStatus as Exclude<CardCoverageStatus, CardCoverageStatus.FULL>;

  if (!firstCoverageStatus) {
    return null;
  }

  const description = getCardCoverageDescription(firstCoverageStatus);
  if (description) {
    return (
      <So5Eligibility
        description={description}
        partial={[
          CardCoverageStatus.PARTIAL,
          CardCoverageStatus.LEGEND,
        ].includes(firstCoverageStatus)}
      />
    );
  }

  return null;
};

const cardFragment = gql`
  fragment ItemEligibility_card on Card {
    slug
    coverageStatus
  }
` as TypedDocumentNode<ItemEligibility_card>;

ItemEligibility.fragments = {
  card: cardFragment,
};

export default ItemEligibility;
