import Big from 'bignumber.js';
import { useMemo } from 'react';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { ConversionCreditWithAmounts } from '@sorare/core/src/hooks/useConversionCredits';
import { useMangopayCreditCardsEnabled } from '@sorare/core/src/hooks/useMangopayCreditCardsEnabled';
import {
  MonetaryAmountOutput,
  Sum,
  calculateFees,
  useMonetaryAmount,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import {
  MonetaryAmountCurrency,
  getMonetaryAmountIndex,
} from '@sorare/core/src/lib/monetaryAmount';

type Props = {
  isFiat: boolean;
  monetaryAmount: MonetaryAmountOutput;
  creditCardFee: number;
  activeFee: boolean;
  referenceCurrency: SupportedCurrency;
  isCreditCard?: boolean;
  conversionCreditToUse?: ConversionCreditWithAmounts;
};
export const STRIPE_MINIMUM_AMOUNT = 50;

export const useCalculateAmounts = ({
  isFiat,
  monetaryAmount,
  creditCardFee,
  activeFee,
  referenceCurrency,
  isCreditCard,
  conversionCreditToUse,
}: Props) => {
  const { toMonetaryAmount } = useMonetaryAmount();
  const useMangopayCreditCards = useMangopayCreditCardsEnabled();
  const indexableReferenceCurrency = getMonetaryAmountIndex(referenceCurrency);

  const percentageDiscount = conversionCreditToUse?.percentageDiscount || 0;
  const maxDiscountMonetary =
    conversionCreditToUse?.maxDiscount || zeroMonetaryAmount;

  const conversionCreditsAmounts = (
    ['gbp', 'eur', 'usd', 'wei'] as MonetaryAmountCurrency[]
  ).reduce<Record<MonetaryAmountCurrency, string>>(
    (acc, currency) => {
      acc[currency] = Big.minimum(
        new Big(monetaryAmount[currency].toString())
          .multipliedBy(percentageDiscount)
          .decimalPlaces(0),
        new Big(maxDiscountMonetary[currency].toString())
      )
        .multipliedBy(-1)
        .toString();
      return acc;
    },
    {} as Record<MonetaryAmountCurrency, string>
  );

  const conversionCreditMonetaryAmount = useMemo(() => {
    return toMonetaryAmount({
      ...conversionCreditsAmounts,
      referenceCurrency,
    });
  }, [conversionCreditsAmounts, referenceCurrency, toMonetaryAmount]);

  const feesBasisPoints = useMemo(
    () => (activeFee ? creditCardFee : 0),
    [activeFee, creditCardFee]
  );

  const feesMonetaryAmount: MonetaryAmountOutput = useMemo(() => {
    const { feesMonetaryAmount: feesMonetaryAmountOutput } = calculateFees({
      monetaryAmount,
      feesPercentagePoints: BigInt(feesBasisPoints) / 100n,
    });
    return toMonetaryAmount({ ...feesMonetaryAmountOutput, referenceCurrency });
  }, [monetaryAmount, feesBasisPoints, toMonetaryAmount, referenceCurrency]);

  const totalMonetaryAmount = useMemo(() => {
    const totalWithFeesAndMaxDiscount = Sum(
      isFiat
        ? [monetaryAmount, conversionCreditMonetaryAmount, feesMonetaryAmount]
        : [monetaryAmount, conversionCreditMonetaryAmount]
    );

    return toMonetaryAmount({
      ...totalWithFeesAndMaxDiscount,
      referenceCurrency,
    });
  }, [
    isFiat,
    monetaryAmount,
    conversionCreditMonetaryAmount,
    feesMonetaryAmount,
    toMonetaryAmount,
    referenceCurrency,
  ]);

  const payWithConversionCreditsOnly =
    (totalMonetaryAmount[indexableReferenceCurrency] === 0 ||
      totalMonetaryAmount[indexableReferenceCurrency] === 0n) &&
    conversionCreditMonetaryAmount[indexableReferenceCurrency] < 0;

  // For stripe, we cannot charge less than 0.5$. The 2 following hooks are used to display this minimum if needed
  const isBellowStripeMinimum =
    !payWithConversionCreditsOnly &&
    isCreditCard &&
    !useMangopayCreditCards &&
    totalMonetaryAmount.usd < STRIPE_MINIMUM_AMOUNT;

  const actualTotalMonetaryAmount = useMemo(() => {
    if (isBellowStripeMinimum) {
      return toMonetaryAmount({
        usd: STRIPE_MINIMUM_AMOUNT,
        referenceCurrency: SupportedCurrency.USD,
      });
    }
    return totalMonetaryAmount;
  }, [totalMonetaryAmount, isBellowStripeMinimum, toMonetaryAmount]);

  const actualConversionCreditMonetaryAmount = useMemo(() => {
    if (isBellowStripeMinimum) {
      return toMonetaryAmount({
        usd: STRIPE_MINIMUM_AMOUNT - monetaryAmount.usd,
        referenceCurrency: SupportedCurrency.USD,
      });
    }
    return conversionCreditMonetaryAmount;
  }, [
    conversionCreditMonetaryAmount,
    monetaryAmount.usd,
    isBellowStripeMinimum,
    toMonetaryAmount,
  ]);

  return {
    percentageDiscount,
    fees: feesBasisPoints / 10000,
    feesMonetaryAmount,
    conversionCreditMonetaryAmount: actualConversionCreditMonetaryAmount,
    maxDiscountMonetary: conversionCreditToUse?.maxDiscount,
    totalMonetaryAmount: actualTotalMonetaryAmount,
    initialAmountIsBellowStripeMinimum: isBellowStripeMinimum,
    payWithConversionCreditsOnly,
  };
};

export default useCalculateAmounts;
