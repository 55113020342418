import { useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useStyledScrollbar = () => {
  useLayoutEffect(() => {
    // Containing element;
    const hider = document.createElement('div');
    hider.className = 'sr-only';

    const measure = document.createElement('div');
    measure.style.width = '40px';
    measure.style.overflowY = 'scroll';

    hider.appendChild(measure);
    document.body.appendChild(hider);

    const cb = () => {
      const scrollbarWidth = measure.offsetWidth - measure.clientWidth;
      if (scrollbarWidth) {
        document.body.classList.add('styled-scrollbar');
      } else {
        document.body.classList.remove('styled-scrollbar');
      }
    };
    cb();
    const obs = new ResizeObserver(entries => {
      entries.forEach(() => {
        cb();
      });
    });
    obs.observe(measure);

    return () => {
      obs.disconnect();
      document.body.removeChild(hider);
    };
  }, []);
};
