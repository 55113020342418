import { defineMessages } from 'react-intl';

export const metadatas = {
  default: defineMessages({
    title: {
      id: 'Meta.nba.default.title',
      defaultMessage: 'Best Fantasy NBA – Sorare NBA',
    },
    description: {
      id: 'Meta.nba.default.description',
      defaultMessage:
        "Collect, play and win officially licensed digital cards featuring the world's best NBA players.",
    },
  }),
  market: defineMessages({
    title: {
      id: 'Meta.nba.market.title',
      defaultMessage: 'Cards Market – Sorare NBA',
    },
    description: {
      id: 'Meta.nba.market.description',
      defaultMessage:
        "Discover new Sorare cards featuring the world's best NBA teams and players on Sorare NBA",
    },
  }),
  newSignings: defineMessages({
    title: {
      id: 'Meta.nba.newSignings.title',
      defaultMessage: 'New Cards Auctions – Sorare NBA',
    },
    description: {
      id: 'Meta.nba.newSignings.description',
      defaultMessage:
        "Find and collect new Sorare cards featuring the world's best NBA teams and players on Sorare NBA",
    },
  }),
  transfers: defineMessages({
    title: {
      id: 'Meta.nba.transfers.title',
      defaultMessage: 'Manager Sales Market – Sorare NBA',
    },
    description: {
      id: 'Meta.nba.transfers.description',
      defaultMessage:
        'Find, collect and trade cards with other Sorare Managers',
    },
  }),
  instantBuy: defineMessages({
    title: {
      id: 'Meta.nba.instantBuy.title',
      defaultMessage: 'Instant Buy Market – Sorare NBA',
    },
    description: {
      id: 'Meta.nba.instantBuy.description',
      defaultMessage:
        "Find and collect new Sorare cards featuring the world's best NBA teams and players on Sorare NBA",
    },
  }),
  team: defineMessages({
    title: {
      id: 'Meta.nba.team.title',
      defaultMessage: '{team} – Team Profile – Sorare NBA',
    },
    description: {
      id: 'Meta.nba.team.description',
      defaultMessage:
        'Find all the statistics of {team}, collect and trade officially licensed digital cards of your favorite team on Sorare NBA, the #1 Fantasy NBA game',
    },
  }),
};
