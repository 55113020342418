import { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { LabelM } from 'atoms/typography';
import Dialog from 'components/dialog';

import Provider from '.';

const messages = defineMessages({
  title: {
    id: 'ResetPrivateKey.title',
    defaultMessage: 'Your wallet needs to be upgraded',
  },
});

const Root = styled.div`
  padding: 0 var(--triple-unit) var(--triple-unit);
`;

const ResetPrivateKeyProvider = ({ children }: React.PropsWithChildren) => {
  const [resetPrivateKey, setResetPrivateKey] = useState(false);

  return (
    <Provider value={{ resetPrivateKey, setResetPrivateKey }}>
      {children}
      {resetPrivateKey && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={resetPrivateKey}
          title={
            <LabelM as="h2" bold>
              <FormattedMessage {...messages.title} />
            </LabelM>
          }
          onClose={() => setResetPrivateKey(false)}
        >
          <Root>
            <LabelM as="p">
              <FormattedMessage
                id="ResetPrivateKey.message"
                defaultMessage="To continue buying and trading Cards on our platform your wallet needs to be updgraded. During this upgrade an additional private key will be added to your wallet. You will receive an email with the upgrade / recovery instructions. For security reasons it can take up to a day to receive this email."
              />
            </LabelM>
          </Root>
        </Dialog>
      )}
    </Provider>
  );
};

export default ResetPrivateKeyProvider;
