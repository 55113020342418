export enum PaymentMethod {
  BANK_TRANSFER = 'bank_transfer',
  NEW_CKO_CREDIT_CARD = 'new_credit_card',
  NEW_MANGOPAY_CREDIT_CARD = 'new_mangopay_credit_card',
  PAYPAL = 'paypal',
  IDEAL = 'ideal',
  APPLE_PAY = 'apple_pay',
}

export enum CreditCardPaymentMethod {
  CREDIT_CARD = 'credit_card',
}
