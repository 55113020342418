import { useMemo } from 'react';

import { useSettlableWalletsSearch } from '@sorare/core/src/hooks/useSettlableWalletsSearch';

interface Props {
  playerSlug?: string;
  teamSlug?: string;
  primary?: boolean;
  secondary?: boolean;
  primaryOffer?: boolean;
  rarity?: string;
  countrySlug?: string;
  nationalTeamSlug?: string;
  userId?: string;
  leagueSlug?: string;
  bundled?: boolean;
  onlySettlableCards?: boolean;
  excludePrimaryOffers?: boolean;
  onSale?: boolean;
}

const useDefaultFilters = ({
  playerSlug,
  teamSlug,
  primary,
  secondary,
  onSale,
  primaryOffer,
  rarity,
  countrySlug,
  nationalTeamSlug,
  userId,
  leagueSlug,
  bundled,
  onlySettlableCards,
  excludePrimaryOffers,
}: Props): string[] => {
  const { filter: settlableWalletsFilter } = useSettlableWalletsSearch();

  const filters = useMemo(
    () =>
      [
        playerSlug && `player.slug:${playerSlug}`,
        teamSlug && `team.slug:${teamSlug}`,
        primary && ['sale.primary:true', 'sale.type:EnglishAuction'],
        primaryOffer && ['sale.primary:true', 'sale.type:PrimaryOffer'],
        secondary && 'sale.primary:false',
        onSale && ['on_sale:true'],
        onlySettlableCards && settlableWalletsFilter,
        rarity && `rarity:${rarity}`,
        countrySlug && `country.code:${countrySlug}`,
        nationalTeamSlug && `active_national_team.slug:${nationalTeamSlug}`,
        userId && `user.id:${userId}`,
        leagueSlug && `active_league.slug:${leagueSlug}`,
        bundled !== undefined && `sale.bundled:${bundled}`,
        excludePrimaryOffers && 'NOT sale.type:PrimaryOffer',
      ]
        .flat()
        .filter(Boolean),
    [
      playerSlug,
      teamSlug,
      primary,
      primaryOffer,
      secondary,
      onlySettlableCards,
      settlableWalletsFilter,
      rarity,
      countrySlug,
      nationalTeamSlug,
      userId,
      leagueSlug,
      bundled,
      excludePrimaryOffers,
      onSale,
    ]
  );

  return filters;
};

export default useDefaultFilters;
