import { RefObject, useState } from 'react';

import { useResizeObserver } from 'hooks/ui/useResizeObserver';

export const useMeasureHeaderHeight = (
  headerRef: RefObject<HTMLDivElement>
) => {
  const [headerHeight, setHeaderHeight] = useState<number>();

  useResizeObserver(headerRef, () => {
    if (headerRef.current) {
      setHeaderHeight(
        // We floor the precise (floating point value) to ensure our headers properly overlap
        Math.floor(headerRef.current.getBoundingClientRect().height)
      );
    } else {
      setHeaderHeight(undefined);
    }
  });

  return {
    height: headerHeight,
    measurementReady: headerHeight !== undefined,
  };
};
