import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const Live = ({
  fill = '#101010',
  title,
  titleId,
  ...rest
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...rest}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6589 2.34512C13.4231 2.10933 13.0526 2.10933 12.8336 2.34512C12.5978 2.58091 12.5978 2.95145 12.8336 3.1704C14.1136 4.46727 14.821 6.16836 14.821 8.00419C14.821 9.82318 14.1136 11.5411 12.8168 12.8211C12.581 13.0569 12.581 13.4275 12.8168 13.6464C12.9347 13.7643 13.0862 13.8149 13.2378 13.8149C13.3894 13.8149 13.541 13.7643 13.6589 13.6464C15.1747 12.1306 16 10.1263 16 7.98735C16 5.8652 15.1747 3.8441 13.6589 2.34512ZM1.17897 8.00419C1.17897 6.16836 1.88636 4.46727 3.18323 3.18724C3.41902 2.95144 3.41902 2.58091 3.18323 2.36196C2.94743 2.12616 2.5769 2.12616 2.35795 2.36196C0.825282 3.8441 0 5.86519 0 8.00419C0 10.1432 0.825282 12.1474 2.34111 13.6633C2.459 13.7812 2.61058 13.8317 2.76217 13.8317C2.91375 13.8317 3.06533 13.7812 3.18323 13.6633C3.41902 13.4275 3.41902 13.0569 3.18323 12.838C1.88636 11.5411 1.17897 9.82318 1.17897 8.00419ZM10.8467 4.33253C11.0657 4.09674 11.4362 4.09674 11.672 4.33253C12.6489 5.3094 13.1878 6.62311 13.2047 7.98735C13.2047 9.36843 12.6657 10.6653 11.6889 11.6422C11.571 11.7601 11.4194 11.8106 11.2678 11.8106C11.1162 11.8106 10.9646 11.7601 10.8467 11.6422C10.6109 11.4232 10.6109 11.0527 10.8467 10.8169C11.6047 10.059 12.0257 9.04843 12.0257 7.98735C12.0257 6.92627 11.6047 5.91572 10.8467 5.15781C10.6109 4.93886 10.6109 4.56833 10.8467 4.33253ZM3.99037 8.00419C3.99037 6.92627 4.41143 5.93256 5.16934 5.17465C5.40514 4.93886 5.40514 4.56832 5.16934 4.34937C4.93355 4.11357 4.56301 4.11357 4.34406 4.34937C3.3672 5.32623 2.82824 6.6231 2.82824 8.02103C2.82824 9.40211 3.38404 10.7327 4.3609 11.6927C4.4788 11.8106 4.63038 11.8611 4.78197 11.8611C4.93355 11.8611 5.08513 11.8106 5.20303 11.6927C5.43882 11.4569 5.42198 11.0864 5.20303 10.8674C4.41143 10.0927 3.99037 9.06526 3.99037 8.00419ZM6.50146 6.23574C6.50146 5.81468 6.97305 5.56204 7.32674 5.79784L9.97101 7.53261C10.291 7.75157 10.291 8.20631 9.97101 8.42527L7.32674 10.1937C6.97305 10.4295 6.50146 10.1769 6.50146 9.75582V6.23574Z"
        fill={fill}
      />
    </svg>
  );
};
