import { useSearchParams } from 'react-router-dom';

import { Navigate, useTypedParams } from '@sorare/routing';

import {
  FOOTBALL,
  LANDING,
  MLB,
  NBA,
} from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const ROOTS: Record<string, string> = {
  football: FOOTBALL,
  baseball: MLB,
  mlb: MLB,
  nba: NBA,
};

const Referral = () => {
  const [searchParams] = useSearchParams();
  const { userSlug } = useTypedParams<PageParams>();
  if (userSlug && !searchParams.get('referrer')) {
    searchParams.set('referrer', userSlug);
  }

  const sport = searchParams.get('utm_term');
  const root = (sport && ROOTS[sport]) || LANDING;

  return <Navigate to={`${root}?${searchParams.toString()}`} replace />;
};

export default Referral;
