import useLifecycle, { LIFECYCLE } from '@sorare/core/src/hooks/useLifecycle';

export const useNextTimeUserShouldSeeActivateFiatWalletOnListing = () => {
  const { update, lifecycle } = useLifecycle();

  const nextTimeUserShouldSeeActivateFiatWalletOnListing =
    lifecycle?.nextTimeUserShouldSeeActivateFiatWalletOnListing;

  return {
    nextTimeUserShouldSeeActivateFiatWalletOnListing,
    update: async (value: string) =>
      update(LIFECYCLE.nextTimeUserShouldSeeActivateFiatWalletOnListing, value),
  };
};
