import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ORIGIN } from 'config';
import { FOOTBALL_MARKET, LANDING, MLB } from 'constants/__generated__/routes';
import { NBA_HOME, useDefaultSportPages } from 'constants/routes';
import useAfterLoggedInTarget from 'hooks/useAfterLoggedInTarget';
import { Lifecycle } from 'hooks/useLifecycle';
import { useRedirectUrl } from 'hooks/useRedirectUrl';

import { CurrentUserProvider_currentUser } from './__generated__/queries.graphql';

export default () => {
  const redirectUrl = useRedirectUrl();
  const afterLoggedInTarget = useAfterLoggedInTarget();
  const navigate = useNavigate();
  const defaultSportPages = useDefaultSportPages();

  return useCallback(
    (currentUser: CurrentUserProvider_currentUser | null | undefined) => {
      const lifecycle = currentUser?.userSettings?.lifecycle as Lifecycle;
      const lastVisitedSport = lifecycle?.lastVisitedSport;

      const fromPath =
        redirectUrl ||
        currentUser?.fromPath ||
        afterLoggedInTarget ||
        (lastVisitedSport && defaultSportPages[lastVisitedSport]);
      if (currentUser) {
        if (fromPath) {
          // make sure we don't redirect to an external URL as it would be a security issue.
          const url = new URL(fromPath, ORIGIN);
          navigate(`${url.pathname}${url.search}`, {
            state: { afterLoggedInTarget: null },
            replace: true,
          });
        } else {
          const onboardedOnFootball =
            currentUser.footballUserProfile?.onboarded;
          const onboardedOnMLB = currentUser.baseballUserProfile?.onboarded;
          const onboardedOnNBA = currentUser.nbaUserProfile?.onboarded;

          // if the user is onboarded on at least 2 sports (or none of them),
          // we don't redirect and show the Choose your Sport page

          const onboardedOnOnlyOneSport =
            [onboardedOnFootball, onboardedOnMLB, onboardedOnNBA].filter(
              Boolean
            ).length === 1;

          // But if the user is onboarded on only 1 sport,
          // we should skip the Choose your sport page and send them there directly
          if (onboardedOnOnlyOneSport) {
            if (onboardedOnMLB) {
              navigate(MLB);
            } else if (onboardedOnNBA) {
              navigate(NBA_HOME);
            }
            navigate(FOOTBALL_MARKET);
          } else {
            navigate(LANDING);
          }
        }
      } else {
        navigate(LANDING);
      }
    },
    // We don't want to update this callback on page change, `navigate` is only used to redirect
    // not to detect page change.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [redirectUrl, afterLoggedInTarget, defaultSportPages]
  );
};
