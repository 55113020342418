import { parseISO } from 'date-fns';

export const weiAmountTypePolicy = {
  read(value?: string | null) {
    return value ? BigInt(value) : value;
  },
};

export const dateTypePolicy = {
  read(value?: string | null | Date) {
    if (value instanceof Date) return value;

    return value ? parseISO(value) : value;
  },
};
