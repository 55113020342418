import { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client/core';

import { Block } from '@sorare/core/src/atoms/layout/Block';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Title5 } from '@sorare/core/src/atoms/typography';
import { NewsTitleContent } from '@sorare/core/src/components/player/News/NewsTitleContent';
import { NewsUpdates } from '@sorare/core/src/components/player/News/NewsUpdates';

import { PlayerNewsUpdates_anyPlayer } from './__generated__/index.graphql';

type Props = {
  player: PlayerNewsUpdates_anyPlayer;
};

export const PlayerNewsUpdates = ({ player }: Props) => {
  if (!player.news.length) return null;

  return (
    <Vertical gap={1}>
      <Title5>
        <NewsTitleContent />
      </Title5>
      <Block>
        <NewsUpdates
          updates={player.news}
          playerName={player.displayName}
          hideAnalysis
        />
      </Block>
    </Vertical>
  );
};

PlayerNewsUpdates.fragments = {
  anyPlayer: gql`
    fragment PlayerNewsUpdates_anyPlayer on AnyPlayerInterface {
      slug
      displayName
      news(last: 10) {
        ...NewsUpdates_PlayerNewsUpdate
      }
    }
    ${NewsUpdates.fragments.PlayerNewsUpdate}
  ` as TypedDocumentNode<PlayerNewsUpdates_anyPlayer>,
};
