import { CreditCardFormResult } from 'components/creditCard/AddCreditCardForm/type';

export const useValidateCardHolderName = () => {
  return (name?: { firstName: string; lastName: string }) => {
    if (!name) return false;
    return (
      name.firstName &&
      name.firstName.trim() !== '' &&
      name.lastName &&
      name.lastName.trim() !== ''
    );
  };
};

export const useValidateCardDetails = () => {
  const validateCardHolderName = useValidateCardHolderName();
  return (card: CreditCardFormResult | undefined) => {
    if (!card) return false;
    return (
      validateCardHolderName(card) &&
      card.cardNumber &&
      card.cardExpirationDate &&
      card.cardCvx
    );
  };
};
