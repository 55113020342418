import { FormattedMessage } from 'react-intl';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, HeadlineS } from '@sorare/core/src/atoms/typography';
import { Attributes } from '@sorare/core/src/components/settings/PostalAddressForm';

export const AddressPreview = ({ address }: { address: Attributes }) => {
  return (
    <Vertical>
      <HeadlineS as="h3">
        <FormattedMessage
          id="rewards.DeliverableItemFooter.adressPreview.title"
          defaultMessage="Saved address"
        />
      </HeadlineS>
      <div>
        <BodyS as="p">
          {address.firstName} {address.lastName}
        </BodyS>
        <BodyS as="p">{address.streetAddress}</BodyS>
        <BodyS as="p">{address.additionalAddress}</BodyS>
        <BodyS as="p">
          {address.city} {address.zipcode}
        </BodyS>
        {address.country && <BodyS as="p">{address.country.name}</BodyS>}
      </div>
    </Vertical>
  );
};
