import { TypedDocumentNode, gql, useApolloClient } from '@apollo/client';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode, useCallback, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { Text14, Title6 } from '@sorare/core/src/atoms/typography';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import Dialog from '@sorare/core/src/components/dialog';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import Warning from '@sorare/core/src/contexts/intl/Warning';
import { useSentryContext } from '@sorare/core/src/contexts/sentry';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import {
  AcceptedCurrenciesValue,
  useAcceptedCurrencies,
} from '@sorare/core/src/hooks/useAcceptedCurrencies';
import {
  CardHit,
  assetIdFromHit,
  tokenHitFragment,
} from '@sorare/core/src/lib/algolia';
import { tradeLabels } from '@sorare/core/src/lib/glossary';
import {
  getMonetaryAmountIndex,
  monetaryAmountFragment,
} from '@sorare/core/src/lib/monetaryAmount';
import { WalletPaymentMethod } from '@sorare/core/src/lib/paymentMethod';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { useStateOffer } from 'hooks/offers/useStateOffer';
import useHasInsufficientFundsInWallets from 'hooks/useHasInsufficientFundsInWallets';
import useMarketFeesHelperStatus from 'hooks/useMarketFeesHelperStatus';

import CardPicker from '../CardPicker';
import OfferSide from '../OfferSide';
import offerSideMessages from '../OfferSide/i18n';
import {
  refreshCardData,
  switchToConfirming,
  updateReceiveCards,
  updateSendCards,
} from '../actions';
import { Actions, StateProps } from '../types';
import AmountInput from './AmountInput';
import InputDuration from './InputDuration';
import { TradePaymentMethods } from './TradePaymentMethods';
import {
  OfferBuilderBuildingPage_anyCard,
  OfferBuilderBuildingPage_baseCard,
  OfferBuilderBuildingPage_publicUserInfoInterface,
} from './__generated__/index.graphql';

const StyledTooltip = styled(Tooltip)`
  width: 100%;
`;

const Container = styled(Vertical).attrs({ gap: 0 })`
  height: 100%;
  padding: 0 0 calc(var(--marginBottom) * 1px);
  margin: 0;
  min-width: 100%;
`;
const WarningContainer = styled.div`
  padding: var(--double-unit);
`;
const ETHOnlyWarningContainer = styled.div`
  display: inline-flex;
  gap: var(--double-unit);
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  & > :not(:first-child) {
    border-top: 1px solid var(--c-neutral-300);
  }
  & > :last-child {
    margin-bottom: var(--double-unit);
  }
  & > * {
    padding: var(--double-unit) var(--triple-unit);
    flex-grow: 1;
    width: 100%;
  }
  @media ${tabletAndAbove} {
    & > :not(:first-child) {
      border-top: none;
    }
    flex-direction: row;
    align-items: flex-start;
  }
`;
const ActionWrapper = styled.div`
  padding: var(--triple-unit);
  margin-top: auto;
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.2);
`;

const WarningHelper = styled(Horizontal)`
  padding: var(--intermediate-unit);
  background-color: var(--c-neutral-300);
  border: 1px solid var(--c-neutral-400);
  border-radius: var(--unit);
  color: var(--c-neutral-1000);
`;

const CounterOfferWarning = styled.div`
  padding: var(--unit) var(--double-unit);
  background-color: rgba(var(--c-rgb-yellow-300), 0.05);
  border-radius: var(--unit);
  border: var(--c-yellow-300) solid 1px;
`;

interface Props<DATA, QUERY_RESULT extends { anyCards: DATA[] }>
  extends StateProps<DATA> {
  to: OfferBuilderBuildingPage_publicUserInfoInterface;
  onClose: () => void;
  query: TypedDocumentNode<QUERY_RESULT, { assetIds: string[] }>;
  counterOfferId?: string;
  counterOfferSport?: Sport;
  sender: ReactNode;
  receiver: ReactNode;
  lockReceiveEthInput?: boolean;
}

const anyCardFragment = gql`
  fragment OfferBuilderBuildingPage_baseCard on AnyCardInterface {
    slug
    publicMinPrices {
      ...MonetaryAmountFragment_monetaryAmount
    }
    ...OfferSide_anyCard
  }
  ${monetaryAmountFragment}
  ${OfferSide.fragments.anyCard}
` as TypedDocumentNode<OfferBuilderBuildingPage_baseCard>;

const messages = defineMessages({
  sendTrade: {
    id: 'OfferBuilder.BuildingPage.sendTrade',
    defaultMessage: 'Send the trade',
  },
  sendCounterOffer: {
    id: 'OfferBuilder.BuildingPage.sendCounterOffer',
    defaultMessage: 'Review counter offer',
  },
  counterOfferWarning: {
    id: 'OfferBuilder.BuildingPage.counterOfferWarning',
    defaultMessage:
      '💡 Sending this counter offer will reject the previous offer.',
  },
});

const getAssetIdsFromCardHits = (cards: CardHit[]) => {
  return cards.map(card => assetIdFromHit(card));
};

const BuildingPage = <
  DATA extends OfferBuilderBuildingPage_anyCard,
  QUERY_RESULT extends { anyCards: DATA[] },
>({
  to,
  state,
  dispatch,
  onClose,
  query,
  counterOfferId,
  counterOfferSport,
  sender,
  receiver,
  lockReceiveEthInput,
}: Props<DATA, QUERY_RESULT>) => {
  const { acceptedCurrencies } = useAcceptedCurrencies({ user: to });
  const setConfirming = useCallback(
    () => dispatch(switchToConfirming),
    [dispatch]
  );
  const {
    sendAmount,
    receiveAmount,
    sendAmountCurrency,
    receiveAmountCurrency,
    sendCards,
    cardsData,
    receiveCards,
    stage,
    paymentMethod,
  } = state;

  const { isValid: valid, isTradeForNothing } = useStateOffer(state);

  const sendAmountGtZero =
    BigInt(sendAmount[getMonetaryAmountIndex(sendAmountCurrency!)]) > 0n;
  const receiveAmountGtZero =
    BigInt(receiveAmount[getMonetaryAmountIndex(receiveAmountCurrency!)]) > 0n;

  const acceptEthWallet = [
    AcceptedCurrenciesValue.BOTH,
    AcceptedCurrenciesValue.ETH,
  ].includes(acceptedCurrencies);

  const acceptCashWallet = [
    AcceptedCurrenciesValue.BOTH,
    AcceptedCurrenciesValue.FIAT,
  ].includes(acceptedCurrencies);

  const hasInsufficientFundsInWallets = useHasInsufficientFundsInWallets();
  const { insufficientFundsInEthWallet, insufficientFundsInFiatWallet } =
    hasInsufficientFundsInWallets(sendAmount);

  const invalid =
    !valid ||
    (insufficientFundsInEthWallet &&
      paymentMethod === WalletPaymentMethod.ETH_WALLET) ||
    (insufficientFundsInFiatWallet &&
      paymentMethod === WalletPaymentMethod.FIAT_WALLET);
  const client = useApolloClient();
  const { showNotification } = useSnackNotificationContext();
  const { sendSafeError } = useSentryContext();
  const [loading, setLoading] = useState(false);

  const { up: isTablet } = useScreenSize('tablet');
  const marketFeeStatus = useMarketFeesHelperStatus(
    sendCards.map(c => cardsData[c.objectID]).filter(Boolean)
  );
  const { sport } = useSportContext();

  const setCards = useCallback(
    async (
      previousState: CardHit[],
      newState: CardHit[],
      actionFactory: (cards: CardHit[]) => Actions<DATA>
    ) => {
      setLoading(true);
      try {
        const newCardData = { ...state.cardsData };
        previousState.forEach(
          c => newCardData[c.objectID!] && delete newCardData[c.objectID!]
        );
        const assetIds = getAssetIdsFromCardHits(newState);
        const { data } = await client.query<
          QUERY_RESULT,
          { assetIds: string[] }
        >({
          query,
          variables: {
            assetIds: assetIds.filter(Boolean),
          },
        });

        newState.forEach(c => {
          const associatedToken = data.anyCards.find(card => {
            if (card.sport === Sport.FOOTBALL) {
              return card.slug === c.objectID;
            }
            return card.assetId === idFromObject(c.objectID);
          });
          if (associatedToken) newCardData[c.objectID] = associatedToken;
        });

        dispatch(actionFactory(newState));
        dispatch(refreshCardData(newCardData));

        return Promise.resolve(true);
      } catch (e) {
        sendSafeError(e);
        if (e && typeof e === 'object' && 'message' in e) {
          showNotification('errors', { errors: e.message });
        }
        return Promise.resolve(false);
      } finally {
        setLoading(false);
      }
    },
    [sendSafeError, dispatch, query, client, showNotification, state]
  );

  const setSendCards = useCallback(
    async (cards: CardHit[]) =>
      setCards(state.sendCards, cards, updateSendCards),
    [setCards, state]
  );

  const setReceiveCards = useCallback(
    async (cards: CardHit[]) =>
      setCards(state.receiveCards, cards, updateReceiveCards),
    [setCards, state]
  );

  const {
    currentUser,
    walletPreferences: { enabledWallets, onlyShowFiatCurrency },
  } = useCurrentUserContext();

  const [cardSelectionState, setCardSelectionState] = useState<
    | {
        owner: { id: string };
        setCards: (cards: CardHit[]) => void;
        selectedCards: CardHit[];
      }
    | undefined
  >(undefined);

  const closeCardPicker = useCallback(
    () => setCardSelectionState(undefined),
    [setCardSelectionState]
  );

  const closeCardPickerOnSuccess = useCallback(
    (success: boolean) => {
      if (success) {
        closeCardPicker();
      }
    },
    [closeCardPicker]
  );

  const openReceiveCardSelectionPopup = useCallback(() => {
    setCardSelectionState({
      owner: to,
      selectedCards: receiveCards,
      setCards: (cards: CardHit[]) => {
        setReceiveCards(cards).then(closeCardPickerOnSuccess);
      },
    });
  }, [receiveCards, to, setReceiveCards, closeCardPickerOnSuccess]);

  const openSendCardSelectionPopup = useCallback(() => {
    setCardSelectionState({
      owner: currentUser!,
      selectedCards: sendCards,
      setCards: (cards: CardHit[]) => {
        setSendCards(cards).then(closeCardPickerOnSuccess);
      },
    });
  }, [sendCards, currentUser, setSendCards, closeCardPickerOnSuccess]);

  const canNotTradeMoney =
    (!acceptCashWallet && onlyShowFiatCurrency) ||
    (!acceptEthWallet && !enabledWallets);

  if (cardSelectionState) {
    return (
      <CardPicker
        onClose={closeCardPicker}
        title={offerSideMessages.addCard}
        selectedCards={cardSelectionState.selectedCards}
        confirmSelectedCards={cardSelectionState.setCards}
        owner={cardSelectionState.owner}
        counterOfferSport={counterOfferSport}
        maxCards={100}
      />
    );
  }

  const renderCta = () => {
    const cta = (
      <LoadingButton
        color="primary"
        size="medium"
        onClick={setConfirming}
        loading={stage === 'submitting' || loading}
        disabled={invalid}
        fullWidth
      >
        <FormattedMessage
          {...(counterOfferId ? messages.sendCounterOffer : messages.sendTrade)}
        />
      </LoadingButton>
    );
    if (isTradeForNothing)
      return (
        <StyledTooltip
          leaveTouchDelay={3000}
          title={
            <Text14>
              {sendAmountGtZero && receiveCards.length === 0 && (
                <FormattedMessage
                  id="OfferBuilder.BuildingPage.tradeForNothingWarning"
                  defaultMessage="You cannot send this trade without receiving at least one Card"
                />
              )}
              {receiveAmountGtZero && sendCards.length === 0 && (
                <FormattedMessage
                  id="OfferBuilder.BuildingPage.tradeForNothingWarningSending"
                  defaultMessage="You cannot send this trade without sending at least one Card"
                />
              )}
            </Text14>
          }
          placement="top"
        >
          {cta}
        </StyledTooltip>
      );
    return cta;
  };

  const cashOnlyOffersSportPreference = to.profile.marketplacePreferences
    .find(
      marketplacePreference =>
        marketplacePreference.sport === (sport || counterOfferSport)
    )
    ?.preferences.find(({ name }) => name === 'cash_only_offers');

  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      onClose={onClose}
      fullScreen={!isTablet}
      title={
        <Title6 className="text-center">
          <FormattedMessage
            {...(counterOfferId
              ? tradeLabels.counterOfferWith
              : tradeLabels.tradeWith)}
            values={{
              nickname: to.nickname,
            }}
          />
        </Title6>
      }
    >
      <Container>
        {counterOfferId && (
          <WarningContainer>
            <CounterOfferWarning>
              <Text14>
                <FormattedMessage {...messages.counterOfferWarning} />
              </Text14>
            </CounterOfferWarning>
          </WarningContainer>
        )}
        <Row>
          <OfferSide
            cards={sendCards}
            cardsData={cardsData}
            setCards={setSendCards}
            title={sender}
            toggleAddCardOpened={openSendCardSelectionPopup}
            isOwnSide
            addCardDisabledWarning={
              !!cashOnlyOffersSportPreference?.value && (
                <Warning variant="yellow">
                  <ETHOnlyWarningContainer>
                    <FontAwesomeIcon icon={faCircleInfo} size="1x" />
                    <Text14>
                      <FormattedMessage
                        id="NewOfferBuilder.OfferSide.managerOnlyAcceptingMoney"
                        defaultMessage="{manager} is only accepting money on this trade."
                        values={{
                          manager: to.nickname,
                        }}
                      />
                    </Text14>
                  </ETHOnlyWarningContainer>
                </Warning>
              )
            }
          >
            {canNotTradeMoney ? (
              <WarningHelper>
                <FontAwesomeIcon icon={faCircleInfo} />
                <Text14>
                  {acceptCashWallet && (
                    <FormattedMessage
                      id="NewOfferBuilder.TradePaymentMethods.cantAcceptETH"
                      defaultMessage="<b>{nickname}</b> can't accept ETH."
                      values={{ b: Bold, nickname: to.nickname }}
                    />
                  )}
                  {acceptEthWallet && (
                    <FormattedMessage
                      id="NewOfferBuilder.TradePaymentMethods.cantAcceptCash"
                      defaultMessage="<b>{nickname}</b> can't accept Cash."
                      values={{ b: Bold, nickname: to.nickname }}
                    />
                  )}
                </Text14>
              </WarningHelper>
            ) : (
              <AmountInput state={state} dispatch={dispatch} />
            )}

            {!canNotTradeMoney && !!enabledWallets && (
              <TradePaymentMethods
                state={state}
                dispatch={dispatch}
                onClose={onClose}
                to={to}
              />
            )}
          </OfferSide>
          <OfferSide
            cards={receiveCards}
            cardsData={cardsData}
            setCards={setReceiveCards}
            title={receiver}
            toggleAddCardOpened={openReceiveCardSelectionPopup}
            displayMinPrices
          >
            {counterOfferId && !lockReceiveEthInput && (
              <AmountInput
                state={state}
                dispatch={dispatch}
                receiver
                marketFeeStatus={marketFeeStatus}
              />
            )}
          </OfferSide>
        </Row>
        <Row>
          <InputDuration state={state} dispatch={dispatch} to={to} />
        </Row>
        <ActionWrapper>{renderCta()}</ActionWrapper>
      </Container>
    </Dialog>
  );
};

BuildingPage.fragments = {
  user: gql`
    fragment OfferBuilderBuildingPage_publicUserInfoInterface on PublicUserInfoInterface {
      id
      slug
      nickname
      profile {
        id
        marketplacePreferences(sports: [FOOTBALL, NBA, BASEBALL]) {
          sport
          preferences {
            name
            value
          }
        }
      }
      hoursToAnswerTrades
      ...TradePaymentMethods_publicUserInfoInterface
    }
    ${TradePaymentMethods.fragments.publicUserInfoInterface}
  ` as TypedDocumentNode<OfferBuilderBuildingPage_publicUserInfoInterface>,
  anyCard: gql`
    fragment OfferBuilderBuildingPage_anyCard on AnyCardInterface {
      slug
      sport
      ...Algolia_CardHit_anyCard
      ...useMarketFeesHelperStatus_anyCard
      ...OfferBuilderBuildingPage_baseCard
    }
    ${anyCardFragment}
    ${tokenHitFragment}
    ${useMarketFeesHelperStatus.fragments.anyCard}
  ` as TypedDocumentNode<OfferBuilderBuildingPage_anyCard>,
};

export default BuildingPage;
