import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { ListWithDetailVariants } from './types';

// navigate back to list path when switching from list to grid
// as grid is a full page view with no default detail selected
export const useNavigateToListPath = (
  variant: ListWithDetailVariants,
  listPath: string | undefined
) => {
  const [navigating, setNavigating] = useState(false);
  const prevVariant = usePrevious(variant);
  const navigate = useNavigate();

  if (
    variant === ListWithDetailVariants.GRID &&
    prevVariant === ListWithDetailVariants.LIST &&
    listPath &&
    !navigating
  ) {
    setNavigating(true);
  }

  useEffect(() => {
    if (!listPath) {
      return;
    }
    if (
      variant === ListWithDetailVariants.GRID &&
      prevVariant === ListWithDetailVariants.LIST
    ) {
      navigate(listPath, { replace: true });
      setNavigating(false);
    }
  }, [variant, prevVariant, listPath, navigate]);

  return navigating;
};
