import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { ExternalLink } from '@sorare/core/src/atoms/navigation/ExternalLink';
import { LabelM, Title6 } from '@sorare/core/src/atoms/typography';
import { tokenLink } from '@sorare/core/src/lib/etherscan';

import { TokenId_anyCard } from './__generated__/index.graphql';

export interface Props {
  card: TokenId_anyCard;
}

const TokenId = ({ card }: Props) => {
  const { ethereumId, contractAddress } = card;
  if (!ethereumId || !contractAddress) return null;

  return (
    <div>
      <Title6 color="var(--c-neutral-600)">
        <FormattedMessage
          id="BlockchainInfo.tokenId"
          defaultMessage="Token ID"
        />
      </Title6>
      <LabelM>
        <ExternalLink href={tokenLink(contractAddress, ethereumId)}>
          {ethereumId.slice(0, 3)}...{ethereumId.slice(-3)}
        </ExternalLink>
      </LabelM>
    </div>
  );
};

TokenId.fragments = {
  anyCard: gql`
    fragment TokenId_anyCard on AnyCardInterface {
      slug
      ethereumId
      contractAddress
    }
  ` as TypedDocumentNode<TokenId_anyCard>,
};

export default TokenId;
