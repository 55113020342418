import { shouldPolyfill as shouldDateTimeFormatPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldListFormatPolyfill } from '@formatjs/intl-listformat/should-polyfill';

import { type LOCALE_KEY } from '.';

type PREFIX<T extends string> = T extends `${infer PRE}-${string}` ? PRE : T;

type SupportedLocales = PREFIX<LOCALE_KEY>;

const dateTimePolyfills: Record<SupportedLocales, () => Promise<unknown>> = {
  ar: async () => import('@formatjs/intl-datetimeformat/locale-data/ar'),
  de: async () => import('@formatjs/intl-datetimeformat/locale-data/de'),
  en: async () => import('@formatjs/intl-datetimeformat/locale-data/en'),
  es: async () => import('@formatjs/intl-datetimeformat/locale-data/es'),
  fr: async () => import('@formatjs/intl-datetimeformat/locale-data/fr'),
  it: async () => import('@formatjs/intl-datetimeformat/locale-data/it'),
  ru: async () => import('@formatjs/intl-datetimeformat/locale-data/ru'),
  tr: async () => import('@formatjs/intl-datetimeformat/locale-data/tr'),
};

const listFormatPolyfills: Record<SupportedLocales, () => Promise<unknown>> = {
  ar: async () => import('@formatjs/intl-listformat/locale-data/ar'),
  de: async () => import('@formatjs/intl-listformat/locale-data/de'),
  en: async () => import('@formatjs/intl-listformat/locale-data/en'),
  es: async () => import('@formatjs/intl-listformat/locale-data/es'),
  fr: async () => import('@formatjs/intl-listformat/locale-data/fr'),
  it: async () => import('@formatjs/intl-listformat/locale-data/it'),
  ru: async () => import('@formatjs/intl-listformat/locale-data/ru'),
  tr: async () => import('@formatjs/intl-listformat/locale-data/tr'),
};

export async function polyfill(locale: string) {
  const polyfills: Promise<unknown>[] = [];
  let polyfillDateTime: string | undefined;
  let polyfillListFormat: string | undefined;
  if ((polyfillDateTime = shouldDateTimeFormatPolyfill(locale))) {
    polyfills.push(import('@formatjs/intl-datetimeformat/polyfill-force'));
  }
  if ((polyfillListFormat = shouldListFormatPolyfill(locale))) {
    polyfills.push(import('@formatjs/intl-listformat/polyfill-force'));
  }
  // Load the polyfills 1st BEFORE loading data
  await Promise.all(polyfills);

  const dataPolyfills = [];

  // Parallelize CLDR data loading
  if (polyfillDateTime) {
    dataPolyfills.push(import('@formatjs/intl-datetimeformat/add-all-tz'));
    dataPolyfills.push(
      (dateTimePolyfills[locale as SupportedLocales] ?? dateTimePolyfills.en)()
    );
  }
  if (polyfillListFormat) {
    dataPolyfills.push(
      (
        listFormatPolyfills[locale as SupportedLocales] ??
        listFormatPolyfills.en
      )()
    );
  }

  await Promise.all(dataPolyfills);
}
