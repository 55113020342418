import { useCallback } from 'react';

import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';

export const useClickTradeEvent = () => {
  const track = useEvents();

  const trackClickTrade = useCallback(
    (counterOfferId?: string) => {
      const params = {
        offerId: counterOfferId ? idFromObject(counterOfferId)! : '',
      };
      track('Click Trade', params);
    },
    [track]
  );
  return trackClickTrade;
};
