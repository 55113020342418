import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { DocumentType } from '__generated__/globalTypes';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { HeadlineL, HeadlineXS } from 'atoms/typography';
import { FilePreview } from 'components/fiatWallet/CreateFiatWallet/DocumentCheck/UploadInput';
import { StepWrapperFromProps } from 'components/fiatWallet/CreateFiatWallet/StepWrapper';
import { FileWithDataURL } from 'components/form/UploadFile/useUploadFile';

import { documentNameByType } from '../type';

export const Review = ({
  requiredDocumentsFiles,
  submitButton,
  onBack,
}: {
  requiredDocumentsFiles: {
    [key in DocumentType]?: FileWithDataURL[];
  };
  submitButton: ReactNode;
  onBack: () => void;
}) => {
  return (
    <StepWrapperFromProps onBack={onBack} submitButton={submitButton}>
      <HeadlineL>
        <FormattedMessage
          id="UnblockWallet.Review.title"
          defaultMessage="Review your documents"
        />
      </HeadlineL>
      <HeadlineXS>
        <FormattedMessage
          id="UnblockWallet.Review.subtitle"
          defaultMessage="Before sending your documents, please review them to make sure they are correct."
        />
      </HeadlineXS>
      {Object.entries(requiredDocumentsFiles).map(([documentType, files]) => {
        return (
          <Vertical key={documentType}>
            <HeadlineXS>
              <FormattedMessage
                {...documentNameByType[documentType as DocumentType]}
              />
            </HeadlineXS>
            <Horizontal>
              {files.map((file, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <FilePreview key={i} file={file} />
              ))}
            </Horizontal>
          </Vertical>
        );
      })}
    </StepWrapperFromProps>
  );
};
