import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentType, ReactNode } from 'react';
import styled from 'styled-components';
import { KnownTarget } from 'styled-components/dist/types';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Title6 } from '@sorare/core/src/atoms/typography';
import { SCORE_LIMIT } from '@sorare/core/src/components/player/FixtureChart';
import usePaginatedQuery from '@sorare/core/src/hooks/graphql/usePaginatedQuery';
import useInfiniteScroll from '@sorare/core/src/hooks/useInfiniteScroll';

import {
  FixtureChart,
  Props as FixtureProps,
} from 'components/stats/LastScores/FixtureChart';

import {
  LazyFixtureChartQuery,
  LazyFixtureChartQueryVariables,
} from './__generated__/index.graphql';

const LoadingCtn = styled(Horizontal)`
  padding: var(--quadruple-unit) 0;
  justify-content: center;
`;

const LAZY_FIXTURE_CHART_QUERY = gql`
  query LazyFixtureChartQuery($slug: String!, $first: Int, $after: String) {
    anyPlayer(slug: $slug) {
      slug
      allPlayerGameScores(first: $first, after: $after) {
        nodes {
          id
          ...FixtureChart_playerGameScoreInterface
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      ...FixtureChart_anyPlayer
    }
  }
  ${FixtureChart.fragments.anyPlayer}
  ${FixtureChart.fragments.playerGameScoreInterface}
` as TypedDocumentNode<LazyFixtureChartQuery, LazyFixtureChartQueryVariables>;

type Props = {
  playerSlug: string;
  disableScoreSelect?: boolean;
  onScoreSelect?: FixtureProps['onScoreSelect'];
  TitleComponent?: ComponentType<{ children: ReactNode; as: KnownTarget }>;
};

export const LazyFixtureChart = ({
  playerSlug,
  onScoreSelect,
  disableScoreSelect,
  TitleComponent = Title6,
}: Props) => {
  const { data, loading, loadMore } = usePaginatedQuery(
    LAZY_FIXTURE_CHART_QUERY,
    {
      variables: { slug: playerSlug, first: SCORE_LIMIT },
      connection: 'PlayerGameScoreInterfaceConnection',
    }
  );

  const player = data?.anyPlayer;

  const { InfiniteScrollLoader } = useInfiniteScroll(
    () => {
      loadMore(false, {
        slug: playerSlug!,
        after: data?.anyPlayer.allPlayerGameScores.pageInfo.endCursor,
        first: SCORE_LIMIT,
      });
    },
    data?.anyPlayer.allPlayerGameScores.pageInfo.hasNextPage || false,
    loading
  );

  if (loading)
    return (
      <LoadingCtn center>
        <LoadingIndicator grow />
      </LoadingCtn>
    );

  if (!player) return null;

  return (
    <FixtureChart
      player={player}
      so5Scores={player.allPlayerGameScores.nodes}
      TitleComponent={TitleComponent}
      onScoreSelect={onScoreSelect}
      InfiniteScrollLoader={<InfiniteScrollLoader />}
      disableScoreSelect={disableScoreSelect}
    />
  );
};
