import { useCallback, useEffect, useState } from 'react';

import {
  ConversionCreditCampaign,
  MonetaryAmount,
  Sport,
} from '__generated__/globalTypes';
import { session as sessionStorage } from 'lib/storage';

export enum SESSION_STORAGE {
  forcedEnv = 'forcedEnv',
  forcedFlags = 'forcedFlags',
  forcedFlag = 'forcedFlag',
  sport = 'sport',
  signupPromo = 'signup-promo',
  mobileApp = 'mobileApp',
  deeplink = 'deeplink',
  appBuildVersionNumber = 'appBuildVersionNumber',
  googlePlayStoreCountryCode = 'googlePlayStoreCountryCode',
  sawPaidCardExplanatoryDialog = 'sawPaidCardExplanatoryDialog',
  debugI18n = 'debugI18n',
  dismissedLineupPredictionTimerGWs = 'dismissedLineupPredictionTimerGWs',
  composeTeamSelection = 'composeTeamSelection',
  sawComposeTeamTutorial = 'sawComposeTeamTutorial',
  expandedGroupSlugPerFixture = 'expandedGroupSlugPerFixture',
}
export enum IOsMobileAppVersion {
  IOS_1 = 'ios-1',
  IOS_GOLD = 'ios-gold',
}

const isIOsMobileAppVersion = (value: string | null) =>
  !!value && (Object.values(IOsMobileAppVersion) as string[]).includes(value);

export type MobileAppVersion = 1 | 2 | 3 | 4 | IOsMobileAppVersion;
export type MobileAppBuildVersion = number;

export type SessionStorageEntries = {
  [SESSION_STORAGE.sport]: Sport;
  [SESSION_STORAGE.signupPromo]: Omit<
    ConversionCreditCampaign,
    'maxDiscount' | 'endDate'
  > & {
    endDate: string;
    maxDiscount: Omit<
      MonetaryAmount,
      'eurFixed' | 'usdFixed' | 'gbpFixed' | 'weiFixed'
    >;
  };
  [SESSION_STORAGE.deeplink]: string;
  [SESSION_STORAGE.mobileApp]: MobileAppVersion;
  [SESSION_STORAGE.appBuildVersionNumber]: MobileAppBuildVersion;
  [SESSION_STORAGE.googlePlayStoreCountryCode]: string;
  [SESSION_STORAGE.sawPaidCardExplanatoryDialog]: string;
  [SESSION_STORAGE.dismissedLineupPredictionTimerGWs]: string[];
  [SESSION_STORAGE.composeTeamSelection]: {
    so5LeaderboardSlug: string;
    composeSessionId: string;
    benchObjectIds: Nullable<string>[];
  };
  [SESSION_STORAGE.sawComposeTeamTutorial]: boolean;
  [SESSION_STORAGE.expandedGroupSlugPerFixture]: Record<string, string[]>;
};

export const getValue = <K extends keyof SessionStorageEntries>(key: K) => {
  const stringValue = sessionStorage.getItem(key);
  if (isIOsMobileAppVersion(stringValue)) {
    return stringValue as SessionStorageEntries[K];
  }
  if (stringValue) {
    try {
      return JSON.parse(stringValue) as SessionStorageEntries[K];
    } catch {
      return null;
    }
  }

  return null;
};

const setValue = <K extends keyof SessionStorageEntries>(
  key: K,
  v: SessionStorageEntries[K] | null
) => {
  if (v !== null) {
    sessionStorage.setItem(key, JSON.stringify(v));
  } else {
    sessionStorage.removeItem(key);
  }
  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      newValue: v !== null ? JSON.stringify(v) : null,
    })
  );
};

export const useSessionStorage = <K extends keyof SessionStorageEntries>(
  key: K
): [
  SessionStorageEntries[K] | null,
  (value: SessionStorageEntries[K] | null) => void,
] => {
  const [state, setState] = useState(getValue(key));

  useEffect(() => {
    const updateState = () => {
      setState(getValue(key));
    };
    window.addEventListener('storage', updateState);
    return () => {
      window.removeEventListener('storage', updateState);
    };
  }, [key]);

  const setValueThenState = useCallback(
    (v: SessionStorageEntries[K] | null) => setValue(key, v),
    [key]
  );

  return [state, setValueThenState];
};
