import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { ReactNode, useMemo } from 'react';

import { SESSION_STORAGE } from 'hooks/useSessionStorage';
import { session as sessionStorage } from 'lib/storage';

import FeatureFlagsContextProvider, { FeatureFlagsContextType } from '.';

interface Props {
  children: ReactNode;
}

const FeatureFlagsProvider = ({ children }: Props) => {
  const client = useLDClient();
  const flags = useFlags();

  const value = useMemo(() => {
    const forcedFlag = sessionStorage.getItem(SESSION_STORAGE.forcedFlag);
    const forcedFlagObj = forcedFlag ? JSON.parse(forcedFlag) : {};
    // avoid spreading all flags as this logs them as being still all used
    const proxiedFlags = new Proxy<typeof flags>(flags, {
      get(allFlags, prop, receiver) {
        const actual = Reflect.get(allFlags, prop, receiver);
        if (
          (
            Object.keys(forcedFlagObj) as (keyof typeof forcedFlagObj)[]
          ).includes(prop)
        ) {
          return forcedFlagObj[prop];
        }
        return actual;
      },
    });

    const untrackedFlags = client?.allFlags() ?? {};

    const identify: FeatureFlagsContextType['identify'] = !client
      ? undefined
      : async (
          { custom = {}, privateAttributeNames, ...user },
          hash?,
          onDone?
        ) =>
          client.identify(
            {
              kind: 'user',
              ...user,
              ...custom,
              _meta: {
                privateAttributes: privateAttributeNames,
              },
            },
            hash,
            onDone
          );

    return {
      flags: proxiedFlags,
      untrackedFlags,
      identify,
    };
  }, [client, flags]);

  return (
    <FeatureFlagsContextProvider value={value}>
      {children}
    </FeatureFlagsContextProvider>
  );
};

export default FeatureFlagsProvider;
