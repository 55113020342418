import { useIsMobileApp } from 'hooks/useIsMobileApp';

export const getUserEngagementIncrement = (tier: number) => {
  if (tier < 3) return 100;
  if (tier === 3) return 50;
  return 0;
};

export const useSendUserEngagementIncrement = () => {
  const { isAndroidApp, postMessage } = useIsMobileApp();

  const sendUserEngagementIncrement = (score: number) => {
    if (isAndroidApp && score)
      postMessage('userEngagementScoreIncrement', {
        score,
      });
  };
  const sendUserEngagementIncrementByTier = (tier: number) => {
    const score = getUserEngagementIncrement(tier);
    sendUserEngagementIncrement(score);
  };

  return {
    sendUserEngagementIncrement,
    sendUserEngagementIncrementByTier,
  };
};
