import { PropsWithChildren } from 'react';
import styled, { CSSProperties } from 'styled-components';

export const padding = 12;
export const borderWidth = 2;

const Root = styled.div`
  line-height: 1;
  text-transform: uppercase;
  font-family: 'Druk Text Wide';
  font-weight: bold;
  isolation: isolate;
  border-style: solid;
  border-width: ${borderWidth}px;
  transform: skewX(-20deg);
  white-space: nowrap;
  text-align: center;
  padding: var(--unit) ${padding}px;
  font-size: 1em;

  & > p {
    transform: skewX(20deg);
  }
`;

type Props = { className?: string; style?: CSSProperties };
export const TaglineTemplate = ({
  children,
  className,
  style,
}: PropsWithChildren<Props>) => {
  return (
    <Root className={className} style={style}>
      {children}
    </Root>
  );
};
