import { ReactNode, useRef, useState } from 'react';

export const DragToScroll = ({ children }: { children: ReactNode }) => {
  const ourRef = useRef<HTMLDivElement | null>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const handleDragStart = (e: any) => {
    if (!ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current.children[0];
    const startX = e.pageX - (slider as HTMLDivElement).offsetLeft;
    const startY = e.pageY - (slider as HTMLDivElement).offsetTop;
    const { scrollLeft } = slider;
    const { scrollTop } = slider;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = 'grabbing';
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
    document.body.style.cursor = 'default';
  };
  const handleDrag = (e: any) => {
    if (!isMouseDown || !ourRef.current?.children) return;
    e.preventDefault();
    const slider = ourRef.current.children[0];
    const x = e.pageX - (slider as HTMLDivElement).offsetLeft;
    const y = e.pageY - (slider as HTMLDivElement).offsetTop;
    const walkX = x - mouseCoords.current.startX;
    const walkY = y - mouseCoords.current.startY;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  };
  const onMouseLeave = () => {
    document.body.style.cursor = 'default';
    setIsMouseDown(false);
  };

  return (
    <div
      role="presentation"
      ref={ourRef}
      onMouseLeave={onMouseLeave}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
    >
      {children}
    </div>
  );
};
