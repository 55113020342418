import { ReactElement, ReactNode } from 'react';

import { Tooltip } from 'atoms/tooltip/Tooltip';
import { Chip } from 'atoms/ui/Chip';

export type Props = {
  value: string | number | ReactElement;
  title?: NonNullable<ReactNode>;
  large?: boolean;
};

export const CardItem = (props: Props) => {
  const { value, title, large } = props;

  if (!title) {
    return <Chip size={large ? 'medium' : 'small'}>{value}</Chip>;
  }

  return (
    <Tooltip title={title}>
      <Chip size={large ? 'medium' : 'small'}>{value}</Chip>
    </Tooltip>
  );
};
