import styled from 'styled-components';

import { LabelL } from '@sorare/core/src/atoms/typography';

export const DialogDescription = styled(LabelL)`
  background: var(--c-neutral-300);
  padding: var(--double-unit);
  text-align: center;
  border-radius: var(--double-unit);
`;
