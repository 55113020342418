import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { Marquee } from 'atoms/container/Marquee';
import { Horizontal } from 'atoms/layout/flex';

import common from './assets/common.jpg';
import custom_series from './assets/custom_series.jpg';
import limited from './assets/limited.jpg';
import rare from './assets/rare.jpg';
import super_rare from './assets/super_rare.jpg';
import unique from './assets/unique.jpg';

const BackgroundContainer = styled(Horizontal)`
  position: absolute;
  inset: 0;
  display: flex;
  z-index: -1;
`;

const Gradient = styled.div`
  width: 450px;
  max-width: 100%;
  background-size: 100%;
  height: 100%;
`;

const assets = {
  [Rarity.common]: common,
  [Rarity.custom_series]: custom_series,
  [Rarity.limited]: limited,
  [Rarity.rare]: rare,
  [Rarity.super_rare]: super_rare,
  [Rarity.unique]: unique,
};

export const ScarcityBackground = ({ rarity }: { rarity: Rarity }) => {
  return (
    <BackgroundContainer>
      <Marquee direction="reverse" gap={0} duration="25s" stretch>
        <Gradient style={{ backgroundImage: `url(${assets[rarity]})` }} />
      </Marquee>
    </BackgroundContainer>
  );
};
