import { FormattedMessage } from 'react-intl';

import {
  LoadingButton,
  Props as LoadingButtonProps,
} from 'atoms/buttons/LoadingButton';
import { glossary } from 'lib/glossary';

export interface Props extends Omit<LoadingButtonProps, 'size'> {
  hasMore?: boolean;
  loading: boolean;
  loadMore: () => void;
}

export const LoadMoreButton = ({
  hasMore,
  loading,
  loadMore,
  ...rest
}: Props) => {
  if (!hasMore) return null;

  return (
    <LoadingButton
      loading={loading}
      color="tertiary"
      size="medium"
      disabled={loading}
      onClick={loadMore}
      {...rest}
    >
      <FormattedMessage {...glossary.loadMore} />
    </LoadingButton>
  );
};
