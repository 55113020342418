import { addHours, addWeeks, isPast } from 'date-fns';
import { PropsWithChildren, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, useLocation } from 'react-router-dom';

import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Title4 } from 'atoms/typography';
import { DialogWithNavigation } from 'components/dialog/DialogWithNavigation';
import { AddRecoveryEmailForm } from 'components/settings/RecoveryEmail/AddRecoveryEmailForm';
import { VerifyRecoveryEmailForm } from 'components/settings/RecoveryEmail/VerifyRecoveryEmailForm';
import {
  FOOTBALL_MARKET,
  FOOTBALL_PLAY,
  MLB_MARKET,
  MLB_PLAY,
  NBA_PLAY,
} from 'constants/__generated__/routes';
import { NBA_MARKET } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import useScreenSize from 'hooks/device/useScreenSize';
import { useWalletNeedsRecover } from 'hooks/recovery/useWalletNeedsRecover';
import { useDeeplinkFromNativeApp } from 'hooks/useDeeplinkFromNativeApp';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import useLifecycle, { LIFECYCLE } from 'hooks/useLifecycle';
import useOAuthEthWallet from 'hooks/wallets/useOAuthEthWallet';
import { glossary, userAttributes } from 'lib/glossary';

import AccountSecurityCheckContextProvider, { SecurityCheckTab } from '.';
import AddPhoneNumber from './AddPhoneNumber';
import { Enable2FA } from './Enable2FA';
import { Home } from './Home';
import VerifyPhoneNumber from './VerifyPhoneNumber';

const tabTitles = {
  [SecurityCheckTab.HOME]: undefined,
  [SecurityCheckTab.ADD_RECOVERY_EMAIL]: glossary.recoveryEmail,
  [SecurityCheckTab.VERIFY_RECOVERY_EMAIL]: glossary.recoveryEmail,
  [SecurityCheckTab.ADD_PHONE_NUMBER]: userAttributes.phoneNumber,
  [SecurityCheckTab.VERIFY_PHONE_NUMBER]: userAttributes.phoneNumber,
  [SecurityCheckTab.ENABLE_2FA]: glossary.twofa,
};

const TARGETED_ROUTES = [
  FOOTBALL_PLAY,
  FOOTBALL_MARKET,
  MLB_PLAY,
  MLB_MARKET,
  NBA_PLAY,
  NBA_MARKET,
];

const AccountSecurityCheckProvider = ({ children }: PropsWithChildren) => {
  const { update, loading, lifecycle } = useLifecycle();
  const { up: isTabletOrDesktop } = useScreenSize('tablet');
  const location = useLocation();
  const [shouldCheckSecurityNow, setShouldCheckSecurityNow] =
    useState<boolean>(false);
  const [securityCheckTab, setSecurityCheckTab] = useState<SecurityCheckTab>(
    SecurityCheckTab.HOME
  );
  const [onBackTarget, setOnBackTarget] = useState<SecurityCheckTab>(
    SecurityCheckTab.HOME
  );

  const [unverifiedPhoneNumber, setUnverifiedPhoneNumber] = useState<
    string | null
  >(null);
  const [unverifiedRecoveryEmail, setUnverifiedRecoveryEmail] = useState<
    string | null
  >(null);

  const { currentUser } = useCurrentUserContext();
  const walletNeedsRecover = useWalletNeedsRecover();
  const { needsCreateEthWallet } = useOAuthEthWallet();
  const { isIosApp } = useIsMobileApp();

  const nextTimeUserNeedsCheckSecurity =
    (lifecycle?.nextTimeUserNeedsCheckSecurity as string | undefined) ||
    (currentUser?.createdAt &&
      addHours(currentUser.createdAt, 1).toISOString());

  const needsSecurityCheck =
    currentUser?.wallet?.holdsValue &&
    !needsCreateEthWallet &&
    !walletNeedsRecover &&
    !shouldCheckSecurityNow &&
    (!nextTimeUserNeedsCheckSecurity ||
      (nextTimeUserNeedsCheckSecurity &&
        isPast(new Date(nextTimeUserNeedsCheckSecurity))));

  const onClose = () => {
    update(
      LIFECYCLE.nextTimeUserNeedsCheckSecurity,
      addWeeks(Date.now(), 1).toISOString()
    );
    setShouldCheckSecurityNow(false);
  };

  const onBackButton = () => {
    setSecurityCheckTab(onBackTarget);
    setOnBackTarget(SecurityCheckTab.HOME);
  };

  if (
    needsSecurityCheck &&
    !loading &&
    TARGETED_ROUTES.some(route => matchPath(route, location.pathname))
  ) {
    setShouldCheckSecurityNow(true);
  }

  const { isWebviewFromNativeApp } = useDeeplinkFromNativeApp();

  if (isWebviewFromNativeApp || isIosApp) return children;

  return (
    <AccountSecurityCheckContextProvider
      value={{
        setSecurityCheckTab,
        setOnBackTarget,
        setUnverifiedRecoveryEmail,
        setUnverifiedPhoneNumber,
      }}
    >
      {children}
      {currentUser && (
        <DialogWithNavigation
          open={shouldCheckSecurityNow}
          fullScreen={!isTabletOrDesktop}
          onBackButton={
            securityCheckTab !== SecurityCheckTab.HOME
              ? onBackButton
              : undefined
          }
          title={
            tabTitles[securityCheckTab] ? (
              <Title4 color="var(--c-neutral-1000)">
                <FormattedMessage {...tabTitles[securityCheckTab]} />
              </Title4>
            ) : (
              <SorareLogo />
            )
          }
        >
          <>
            {securityCheckTab === SecurityCheckTab.HOME && (
              <Home onClose={onClose} />
            )}
            {securityCheckTab === SecurityCheckTab.ENABLE_2FA && (
              <Enable2FA
                onClose={() => setSecurityCheckTab(SecurityCheckTab.HOME)}
              />
            )}
            {securityCheckTab === SecurityCheckTab.ADD_PHONE_NUMBER && (
              <AddPhoneNumber
                onSuccess={(phoneNumber: string) => {
                  setSecurityCheckTab(SecurityCheckTab.VERIFY_PHONE_NUMBER);
                  setOnBackTarget(SecurityCheckTab.ADD_PHONE_NUMBER);
                  setUnverifiedPhoneNumber(phoneNumber);
                }}
              />
            )}
            {securityCheckTab === SecurityCheckTab.VERIFY_PHONE_NUMBER &&
              unverifiedPhoneNumber && (
                <VerifyPhoneNumber
                  unverifiedPhoneNumber={unverifiedPhoneNumber}
                  onCancel={() => {
                    setSecurityCheckTab(SecurityCheckTab.ADD_PHONE_NUMBER);
                    setUnverifiedPhoneNumber(null);
                    setOnBackTarget(SecurityCheckTab.HOME);
                  }}
                  onSuccess={() => {
                    setSecurityCheckTab(SecurityCheckTab.HOME);
                    setOnBackTarget(SecurityCheckTab.HOME);
                    setUnverifiedPhoneNumber(null);
                  }}
                />
              )}
            {securityCheckTab === SecurityCheckTab.ADD_RECOVERY_EMAIL && (
              <AddRecoveryEmailForm
                email={unverifiedRecoveryEmail}
                onSuccess={(newRecoveryEmail: string) => {
                  setUnverifiedRecoveryEmail(newRecoveryEmail);
                  setOnBackTarget(SecurityCheckTab.ADD_RECOVERY_EMAIL);
                  setSecurityCheckTab(SecurityCheckTab.VERIFY_RECOVERY_EMAIL);
                }}
              />
            )}
            {unverifiedRecoveryEmail &&
              securityCheckTab === SecurityCheckTab.VERIFY_RECOVERY_EMAIL && (
                <VerifyRecoveryEmailForm
                  email={unverifiedRecoveryEmail}
                  onSuccess={() => {
                    setOnBackTarget(SecurityCheckTab.HOME);
                    setSecurityCheckTab(SecurityCheckTab.HOME);
                    setUnverifiedRecoveryEmail(null);
                  }}
                />
              )}
          </>
        </DialogWithNavigation>
      )}
    </AccountSecurityCheckContextProvider>
  );
};

export default AccountSecurityCheckProvider;
