import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import playerPlaceholder from '@sorare/core/src/assets/players/placeholder.png';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LinkBox } from '@sorare/core/src/atoms/navigation/Box';
import { BodyS, LabelM, LabelS } from '@sorare/core/src/atoms/typography';
import { Cost } from '@sorare/core/src/components/player/Cost';
import { NBA_TEAMS_SLUG_PLAYERS } from '@sorare/core/src/constants/__generated__/routes';
import { isType } from '@sorare/core/src/gql';
import { sortBy } from '@sorare/core/src/lib/arrays';
import { playerUnavailability } from '@sorare/core/src/lib/glossary';
import { getShortPlayerName } from '@sorare/core/src/lib/players';

import { IneligibleIndicator } from '@sorare/marketplace/src/components/card/NBACardProperties/IneligibleIndicator';
import { AnyPlayerStatsIcon } from '@sorare/marketplace/src/components/player/AnyPlayerStatsIcon';

import { InjuryTag } from '@sorare/gameplay/src/components/player/InjuryTag';

import { TeamBox } from '@sorare/us-sports/src/components/game/TeamBox';

import { PlayerInFixtureLinkBox } from 'components/game/GameView/PlayerInFixtureLinkBox';
import { PlayerInLineupLinkBox } from 'components/game/GameView/PlayerInLineupLinkBox';

import {
  TeamPlayers_anyCard,
  TeamPlayers_anyPlayer,
  TeamPlayers_so5Appearance,
  TeamPlayers_so5Fixture,
  TeamPlayers_team,
} from './__generated__/index.graphql';

const ItemWrapper = styled(LinkBox)`
  display: grid;
  align-items: center;
  gap: var(--double-unit);
  grid-template-columns: var(--player-image-size) minmax(0, auto) max-content calc(
      var(--unit) * 6
    );
  & > *:last-child {
    justify-self: end;
  }
`;

const PlayersList = styled(Vertical).attrs({ gap: 0 })`
  margin: var(--half-unit) 0;
  & > * + * {
    border-top: 1px solid rgba(var(--c-rgb-neutral-300), 0.5);
  }
`;

const PlayersListItem = styled(Vertical)`
  padding: var(--half-unit) var(--double-unit);
  cursor: pointer;
  transition:
    opacity 0.1s ease-out,
    background-color 0.1s ease-out;

  &.isInjured {
    opacity: 50%;
    background-color: rgba(var(--c-rgb-yellow-600), 0.5);
  }
  &.isInactive {
    opacity: 20%;
    background-color: rgba(var(--c-rgb-red-600), 0.5);
  }

  &:hover {
    &:not(.isInjured):not(.isInactive) {
      background-color: rgba(var(--c-rgb-brand-600), 0.1);
    }
    opacity: 100%;
  }
`;
const PlayerImage = styled.div`
  position: relative;
  width: var(--player-image-size);
  height: var(--player-image-size);
  border-radius: 100%;
  border: 3px solid var(--c-static-neutral-300);
  background-position: center center;
  background-size: contain;
  background-color: var(--c-static-neutral-100);
  background-repeat: no-repeat;
`;

const PlayerName = styled(LabelM).attrs({ as: 'div' })`
  white-space: nowrap;
`;

const StyledCost = styled(Cost)`
  opacity: 0.6;

  &.isEligible {
    opacity: 1;
  }
`;

const HorizontalWithEllipsis = styled(Horizontal)`
  overflow: hidden;
  & > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & > *:first-child {
    flex: 0 0 auto;
  }
`;

const Appearances = styled(Horizontal).attrs({ gap: 2 })`
  align-items: flex-start;

  padding-top: var(--unit);
  padding-bottom: var(--double-unit);
`;

type Props = {
  title: ReactNode;
  team: TeamPlayers_team;
  players: {
    player: TeamPlayers_anyPlayer;
    so5Appearances?: TeamPlayers_so5Appearance[];
    unusedCards?: TeamPlayers_anyCard[];
  }[];
  fixture: TeamPlayers_so5Fixture;
  openPlayerInfoModal: (playerSlug: string) => void;
  openCardInfoModal: (cardSlug: string) => void;
};

export const TeamPlayers = ({
  title,
  team,
  players,
  fixture,
  openPlayerInfoModal,
  openCardInfoModal,
}: Props) => {
  const nbaPlayers = players
    .map(
      player =>
        isType(player.player, 'NBAPlayer') && {
          ...player,
          player: player.player,
        }
    )
    .filter(Boolean);

  const sortedPlayers = sortBy(({ player }) => {
    const score = player?.lastTenPlayedSo5AverageScore || 0;
    // put inactive players at the bottom
    if (!player.isActive) {
      return 1000 + score * -1;
    }
    // put injured players at the bottom, before inactive players
    if (player.playerInjury?.status) {
      return 500 + score * -1;
    }
    return score * -1;
  }, nbaPlayers);

  return (
    <TeamBox
      team={team}
      title={title}
      to={generatePath(NBA_TEAMS_SLUG_PLAYERS, {
        slug: team.slug,
      })}
    >
      <PlayersList>
        {sortedPlayers?.map(
          ({ player, so5Appearances = [], unusedCards = [] }) => {
            const score = player.lastTenPlayedSo5AverageScore;
            const nbCardsInGame = so5Appearances.length + unusedCards.length;
            return (
              <PlayersListItem
                key={player.slug}
                className={classNames({
                  isInactive: !player.isActive,
                  isInjured: Boolean(player.playerInjury?.status),
                })}
                onClick={() => openPlayerInfoModal(player.slug)}
              >
                <ItemWrapper>
                  <PlayerImage
                    style={{
                      backgroundImage: `url(${
                        player.avatarPictureUrl || playerPlaceholder
                      })`,
                    }}
                  />
                  <Horizontal>
                    <PlayerName>
                      {getShortPlayerName(player.displayName)}
                    </PlayerName>
                    <>
                      {player.playerInjury && (
                        <HorizontalWithEllipsis>
                          <InjuryTag player={player} />
                        </HorizontalWithEllipsis>
                      )}
                      {!player.isActive && !player.playerInjury && (
                        <HorizontalWithEllipsis>
                          <IneligibleIndicator player={player} />
                          <LabelS as="div">
                            <FormattedMessage
                              {...playerUnavailability.inactive}
                            />
                          </LabelS>
                        </HorizontalWithEllipsis>
                      )}
                    </>
                  </Horizontal>
                  <AnyPlayerStatsIcon player={player} />
                  <StyledCost
                    score={score}
                    disabled={nbCardsInGame === 0}
                    className={classNames({ isEligible: nbCardsInGame > 0 })}
                  />
                </ItemWrapper>
                {nbCardsInGame > 0 && (
                  <Appearances>
                    {so5Appearances.slice(0, 3).map(so5Appearance => (
                      <PlayerInLineupLinkBox
                        key={so5Appearance.anyCard?.slug}
                        fixture={fixture}
                        so5Appearance={so5Appearance}
                      />
                    ))}
                    {unusedCards
                      .slice(0, 3 - so5Appearances.slice(0, 3).length)
                      .map(card => (
                        <PlayerInFixtureLinkBox
                          key={card.slug}
                          card={card}
                          onClick={() => {
                            openCardInfoModal(card.slug);
                          }}
                        />
                      ))}
                    {nbCardsInGame > 3 && (
                      <BodyS as="div" color="var(--c-neutral-700)" bold>
                        +{nbCardsInGame - 3}
                      </BodyS>
                    )}
                  </Appearances>
                )}
              </PlayersListItem>
            );
          }
        )}
      </PlayersList>
    </TeamBox>
  );
};

TeamPlayers.fragments = {
  anyPlayer: gql`
    fragment TeamPlayers_anyPlayer on AnyPlayerInterface {
      slug
      displayName
      avatarPictureUrl
      lastTenPlayedSo5AverageScore: averageScore(
        type: LAST_TEN_PLAYED_SO5_AVERAGE_SCORE
      )
      ... on NBAPlayer {
        slug
        # eslint-disable-next-line @graphql-eslint/no-deprecated
        isActive
        # eslint-disable-next-line @graphql-eslint/no-deprecated
        playerInjury {
          status
        }
      }
      ...InjuryTag_anyPlayer
      ...AnyPlayerStatsIcon_anyPlayer
      ...IneligibleIndicator_NBAPlayer
    }
    ${AnyPlayerStatsIcon.fragments.anyPlayer}
    ${InjuryTag.fragments.anyPlayer}
    ${IneligibleIndicator.fragments.NBAPlayer}
  ` as TypedDocumentNode<TeamPlayers_anyPlayer>,
  team: gql`
    fragment TeamPlayers_team on TeamInterface {
      slug
      name
      ...TeamBox_team
    }
    ${TeamBox.fragments.team}
  ` as TypedDocumentNode<TeamPlayers_team>,
  so5Fixture: gql`
    fragment TeamPlayers_so5Fixture on So5Fixture {
      slug
      ...PlayerInLineupLinkBox_so5Fixture
    }
    ${PlayerInLineupLinkBox.fragments.so5Fixture}
  ` as TypedDocumentNode<TeamPlayers_so5Fixture>,
  so5Appearance: gql`
    fragment TeamPlayers_so5Appearance on So5Appearance {
      id
      anyCard {
        slug
      }
      so5Lineup {
        id
      }
      ...PlayerInLineupLinkBox_so5Appearance
    }
    ${PlayerInLineupLinkBox.fragments.so5Appearance}
  ` as TypedDocumentNode<TeamPlayers_so5Appearance>,
  anyCard: gql`
    fragment TeamPlayers_anyCard on AnyCardInterface {
      slug
      ...PlayerInFixtureLinkBox_anyCard
    }
    ${PlayerInFixtureLinkBox.fragments.anyCard}
  ` as TypedDocumentNode<TeamPlayers_anyCard>,
};
