import { useContext, useEffect } from 'react';

import { GeneratedKey, MessagingContext } from '@sorare/wallet-shared';
import { useAuthContext } from 'contexts/auth';
import { useCurrentUserContext } from 'contexts/currentUser';
import { WalletTab, useWalletDrawerContext } from 'contexts/walletDrawer';
import { formatUpdateUserErrors } from 'lib/http';

export default () => {
  const { registerHandler } = useContext(MessagingContext)!;
  const { updateUser } = useAuthContext();
  const { setCurrentTab, hideWallet } = useWalletDrawerContext();
  const { currentUser } = useCurrentUserContext();

  useEffect(() => {
    if (currentUser?.email) {
      registerHandler<GeneratedKey>(
        'generatedKey',
        async ({
          address,
          passwordHash,
          userPrivateKey,
          userPrivateKeyBackup,
          starkKey,
          wallet,
        }) => {
          const walletParams = wallet
            ? { wallet }
            : {
                starkKey,
                sorareAddress: address,
                sorarePrivateKey: userPrivateKey,
                sorarePrivateKeyBackup: userPrivateKeyBackup,
              };

          const { errors } = await updateUser({
            ...walletParams,
            passwordHash,
          });

          if (errors) return { error: formatUpdateUserErrors(errors) };
          setCurrentTab(WalletTab.HOME);
          hideWallet();
          return {};
        }
      );
    }
    return () => {};
  }, [
    currentUser?.email,
    hideWallet,
    registerHandler,
    setCurrentTab,
    updateUser,
  ]);
};
