import { PropsWithChildren, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { AppLayout as CoreLayout } from '@sorare/core/src/components/navigation/AppLayout';

export const AppLayout = ({
  children,
  extraHeaderButton,
}: PropsWithChildren<{ extraHeaderButton?: ReactNode }>) => {
  return (
    <CoreLayout extraHeaderButton={extraHeaderButton}>
      {children || <Outlet />}
    </CoreLayout>
  );
};
