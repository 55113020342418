import { MutableRefObject, createContext, useContext } from 'react';

interface GraphqlContext {
  refreshWsCable: () => void;
  setApiKey: (apiKey: string | undefined) => void;
  activeQueries: MutableRefObject<number>;
}

export const graphqlContext = createContext<GraphqlContext | null>(null);

export const useGraphqlContext = () => useContext(graphqlContext)!;

export default graphqlContext.Provider;
