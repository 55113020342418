import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const AddFriends = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    aria-labelledby={titleId}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 7.5C9.57107 7.5 11.25 5.82107 11.25 3.75C11.25 1.67893 9.57107 0 7.5 0C5.42893 0 3.75 1.67893 3.75 3.75C3.75 5.82107 5.42893 7.5 7.5 7.5ZM13.779 10.9811C13.902 10.42 14.402 10 15 10C15.6904 10 16.25 10.5596 16.25 11.25V13.75H18.75C19.4404 13.75 20 14.3096 20 15C20 15.6904 19.4404 16.25 18.75 16.25H16.25V18.75C16.25 19.4404 15.6904 20 15 20C14.3096 20 13.75 19.4404 13.75 18.75V17.5V16.25H11.25C10.5596 16.25 10 15.6904 10 15C10 14.3096 10.5596 13.75 11.25 13.75H13.75V11.25C13.75 11.1577 13.76 11.0677 13.779 10.9811ZM11.25 11.25C11.25 10.8139 11.3244 10.3952 11.4613 10.0059C11.3914 10.002 11.3209 10 11.25 10H3.75C1.67893 10 0 11.6789 0 13.75V17.5H8.45487C7.86107 16.8366 7.5 15.9604 7.5 15C7.5 12.9289 9.17893 11.25 11.25 11.25Z"
      fill="currentColor"
    />
  </svg>
);
