import { FormattedMessage } from 'react-intl';

import { SBHorizontal } from 'atoms/layout/flex';
import { PoweredByRotowire } from 'components/rotowire/PoweredByRotowire';

export const NewsTitleContent = () => {
  return (
    <SBHorizontal>
      <span>
        <FormattedMessage id="Player.News.news" defaultMessage="News" />
      </span>
      <PoweredByRotowire />
    </SBHorizontal>
  );
};
