type Props = {
  active?: boolean;
};

export const PlayMLB = ({ active }: Props) => {
  const activeFill = active ? 'currentColor' : 'none';
  const inactiveFill = active ? 'none' : 'currentColor';
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 1.52588C6.30307 1.52588 1.58801 6.63603 1.58804 11.938L1.58805 12.3108L12 22.7228L22.4119 12.3106L22.4119 11.9379C22.4119 6.63582 17.6964 1.52588 11.9998 1.52588ZM4.80303 12.9802L6.07583 14.253L12.0001 8.32873L17.9242 14.2528L19.1969 12.98L12.0001 5.78314L4.80303 12.9802ZM15.3 16.8771L12.0001 13.5771L8.70003 16.8772L9.97289 18.1499L12.0001 16.1227L14.0273 18.1499L15.3 16.8771Z"
        fill={activeFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58817 11.9381C1.58813 6.63606 6.30319 1.52591 11.9999 1.52591C17.6966 1.52591 22.4119 6.63585 22.4119 11.9379L22.4119 12.3107L12.0002 22.7228L1.58817 12.3108L1.58817 11.9381ZM3.39731 11.5744L4.80308 12.9802L12.0001 20.1772L15.3 16.8772L19.1969 12.9802L20.6027 11.5743C20.391 7.35279 16.5302 3.32591 11.9999 3.32591C7.46966 3.32591 3.60905 7.35289 3.39731 11.5744Z"
        fill={inactiveFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.78325L19.1969 12.9802L12.0001 20.1772L4.80308 12.9802L12 5.78325ZM6.0728 14.256L8.697 16.8802L12 20.1832L17.9272 14.256L12 8.32883L6.0728 14.256Z"
        fill={inactiveFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.5772L15.3 16.8772L12.0001 20.1772L8.697 16.8802L12 13.5772ZM9.9728 18.15L12.0001 20.1772L14.0272 18.15L12 16.1228L9.9728 18.15Z"
        fill={inactiveFill}
      />
    </svg>
  );
};
