import { TypedDocumentNode, gql, useMutation } from '@apollo/client';

import {
  AddTokensToDeckMutation,
  AddTokensToDeckMutationVariables,
} from './__generated__/useAddTokensToDeck.graphql';
import { deckFragment } from './fragments';

const ADD_TOKENS_TO_DECK_MUTATION = gql`
  mutation AddTokensToDeckMutation($input: addTokensToDeckInput!) {
    addTokensToDeck(input: $input) {
      deck {
        slug
        cards(first: 5) {
          nodes {
            slug
            pictureUrl
          }
        }
        ...deckFragments_deck
      }
      cards {
        slug
        decks {
          slug
        }
      }
    }
  }
  ${deckFragment}
` as TypedDocumentNode<
  AddTokensToDeckMutation,
  AddTokensToDeckMutationVariables
>;

export default () => {
  const [addCards] = useMutation(ADD_TOKENS_TO_DECK_MUTATION);

  return (deckSlug: string) => async (slugs: string[]) =>
    addCards({
      variables: {
        input: {
          deckSlug,
          slugs,
        },
      },
    });
};
