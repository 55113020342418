import { Outlet } from 'react-router-dom';

import { getRoutesFromFiles } from '@sorare/routing';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Dialog } from '@sorare/core/src/components/dialog';
import {
  DialogKey,
  WithDialogs,
} from '@sorare/core/src/components/navigation/WithDialogs';
import SportSwitcher from '@sorare/core/src/contexts/sport/SportSwitcher';

import { DeliverableItemOrderFormRoutedDialog } from '@sorare/gameplay/src/components/rewards/deliverableItem/DeliverableItemOrderFormRoutedDialog';

import BundledAuctionPage from 'components/auction/BundledAuctionPage';
import PrivateRoute from 'routing/PrivateRoute';

export const WithDialogLayout = () => {
  return (
    <WithDialogs
      dialogs={[
        {
          name: DialogKey.deliveryItemOrder,
          element: (
            <PrivateRoute element={<DeliverableItemOrderFormRoutedDialog />} />
          ),
        },
        {
          name: DialogKey.bundledAuction,
          element: (
            <Dialog open fullScreen>
              <BundledAuctionPage />
            </Dialog>
          ),
        },
      ]}
    >
      <SportSwitcher sport={Sport.FOOTBALL} />
      <Outlet />
    </WithDialogs>
  );
};

export const routeObjects = [
  {
    element: <WithDialogLayout />,
    children: [
      ...getRoutesFromFiles(
        import.meta.glob('./**/(page|layout).tsx'),
        '../../packages/football/src/pages'
      ),
    ],
  },
];
