import { TypedDocumentNode, gql, useApolloClient } from '@apollo/client';

import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useWalletContext } from '@sorare/core/src/contexts/wallet';
import { MigratorApprovalError } from '@sorare/core/src/errors';

import {
  ApproveMigratorMutation,
  ApproveMigratorMutationVariables,
  NextRelayBatchNonceQuery,
  NextRelayBatchNonceQueryVariables,
  useApproveMigrator_anyCard,
} from './__generated__/useApproveMigrator.graphql';

type useApproveMigrator_token_owner_account_accountable = NonNullable<
  NonNullable<useApproveMigrator_anyCard['tokenOwner']>['account']
>['accountable'];

type useApproveMigrator_token_owner_account_accountable_EthereumAccount =
  useApproveMigrator_token_owner_account_accountable & {
    __typename: 'EthereumAccount';
  };

const NEXT_RELAY_BATCH_NONCE_QUERY = gql`
  query NextRelayBatchNonceQuery($address: String!) {
    nextRelayBatchNonce(address: $address)
  }
` as TypedDocumentNode<
  NextRelayBatchNonceQuery,
  NextRelayBatchNonceQueryVariables
>;

const APPROVE_MIGRATOR_MUTATION = gql`
  mutation ApproveMigratorMutation($input: approveMigratorInput!) {
    approveMigrator(input: $input) {
      errors {
        path
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  ApproveMigratorMutation,
  ApproveMigratorMutationVariables
>;

function isEthereumAccount(
  accountable: useApproveMigrator_token_owner_account_accountable
): accountable is useApproveMigrator_token_owner_account_accountable_EthereumAccount {
  return accountable.__typename === 'EthereumAccount';
}

const useApproveMigrator = () => {
  const { currentUser } = useCurrentUserContext();
  const { approveMigrator: signApproveMigrator } = useWalletContext();
  const client = useApolloClient();

  const getNextNonce = async (address: string) => {
    const { data } = await client.query<
      NextRelayBatchNonceQuery,
      NextRelayBatchNonceQueryVariables
    >({
      query: NEXT_RELAY_BATCH_NONCE_QUERY,
      variables: { address },
    });

    return data.nextRelayBatchNonce;
  };

  const approve = async (address: string, nonce: number, signature: string) => {
    const { errors } = await client.mutate<
      ApproveMigratorMutation,
      ApproveMigratorMutationVariables
    >({
      mutation: APPROVE_MIGRATOR_MUTATION,
      variables: { input: { address, nonce, signature } },
    });

    if (errors && errors.length > 0)
      throw new MigratorApprovalError('unable to save approval');
  };

  return async (cards: useApproveMigrator_anyCard[]) => {
    if (!currentUser) return;

    const requiredApprovals = cards
      .filter(
        ({ tokenOwner }) =>
          tokenOwner?.account &&
          isEthereumAccount(tokenOwner.account.accountable) &&
          !tokenOwner.account?.accountable?.migratorApproved
      )
      .map(({ tokenOwner }) => tokenOwner!.address);

    if (requiredApprovals.length > 0) {
      const nonce = await getNextNonce(requiredApprovals[0]);
      const result = await signApproveMigrator(nonce);
      if (!result)
        throw new MigratorApprovalError('unable to obtain internal signature');

      await approve(requiredApprovals[0], nonce, result.signature);
    }
  };
};

useApproveMigrator.fragments = {
  anyCard: gql`
    fragment useApproveMigrator_anyCard on AnyCardInterface {
      slug
      walletStatus
      tradeableStatus
      tokenOwner {
        id
        blockchain
        address
        account {
          id
          accountable {
            ... on Node {
              id
            }
            ... on EthereumAccount {
              id
              migratorApproved
            }
            ... on StarkwareAccount {
              id
            }
          }
        }
      }
    }
  ` as TypedDocumentNode<useApproveMigrator_anyCard>,
};

export default useApproveMigrator;
