import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { FormattedMessage, FormattedTime } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Container } from 'atoms/layout/Container';
import { Horizontal } from 'atoms/layout/flex';
import { HomeLink } from 'atoms/navigation/HomeLink';
import { BodyM } from 'atoms/typography';
import { MaintenanceExperience } from 'components/landing/LandingMultiSport/Experience';
import { MaintenanceHero } from 'components/landing/LandingMultiSport/Hero';
import { Prizes } from 'components/landing/LandingMultiSport/Prizes';
import { LeaguesBlock } from 'components/landing/LeaguesBlock';
import { notifications } from 'contexts/snackNotification';
import { tabletAndAbove } from 'style/mediaQuery';

const MaintenanceHeader = styled(Horizontal)`
  padding: 12px;
  position: sticky;
  z-index: 1000;
  top: var(--double-unit);
  background: var(--c-static-neutral-800);
  border: 1px var(--c-static-neutral-700) solid;
  margin: var(--double-unit) 0;
  border-radius: var(--double-unit);
`;

const Wrapper = styled.div`
  height: var(--navbar-height-mobile);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--unit);

  @media ${tabletAndAbove} {
    padding: 0;
    height: var(--navbar-height-desktop);
  }
`;

type Props = {
  startDate: Date;
  endDate: Date;
};

export const Maintenance = ({ startDate, endDate }: Props) => {
  return (
    <>
      <Helmet>
        {/* https://developers.google.com/search/blog/2011/01/how-to-deal-with-planned-site-downtime */}
        <meta name="prerender-status-code" content="503" />
      </Helmet>
      <Container>
        <Wrapper>
          <HomeLink withStarball />
        </Wrapper>
        <MaintenanceHeader>
          <FontAwesomeIcon
            icon={faWarning}
            color="var(--c-static-yellow-800)"
          />
          <BodyM as="p">
            <FormattedMessage
              {...notifications.serviceUnderMaintenance}
              values={{
                start: (
                  <FormattedTime
                    value={startDate}
                    day="numeric"
                    month="short"
                    year="numeric"
                  />
                ),
                end: (
                  <FormattedTime
                    value={endDate}
                    day="numeric"
                    month="short"
                    year="numeric"
                  />
                ),
                msg: '',
              }}
            />
          </BodyM>
        </MaintenanceHeader>
      </Container>
      <MaintenanceHero />
      <LeaguesBlock maintenance />
      <MaintenanceExperience />
      <Prizes />
    </>
  );
};
