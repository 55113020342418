import { BaseSubscriptionOptions, DocumentNode } from '@apollo/client';

import { useSubscription } from '@sorare/core/src/hooks/useSubscription';

/**
 * This empty component holds the subscription
 * Without triggering a full rerender of the app on each subscription event
 */
export const Subscription = ({
  gql,
  options,
}: {
  gql: DocumentNode;
  options?: BaseSubscriptionOptions;
}) => {
  useSubscription(gql, options);
  return null;
};
