import { SVGProps } from 'react';

export const Whistle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.20711 4.79289L11 0L13.5 2.5L10.1452 7.1129C11.2767 8.02957 12 9.43032 12 11C12 13.7614 9.76142 16 7 16L6.98737 16L6.97475 16C4.24108 16 2 13.7839 2 11.0503L2.00006 11.0253L2 11C2 9.36352 2.78619 7.91067 4.00147 6.99853L4.79289 6.20711L6.29289 7.70711L7.70711 6.29289L6.20711 4.79289Z"
        fill="currentColor"
      />
    </svg>
  );
};
