import { MessageDescriptor, defineMessages } from 'react-intl';

import { CardCoverageStatus } from '__generated__/globalTypes';

export const messages = defineMessages<
  Exclude<CardCoverageStatus, CardCoverageStatus.FULL>
>({
  [CardCoverageStatus.NOT_COVERED]: {
    id: 'cardCoverage.messages.notCovered',
    defaultMessage: 'Ineligible League',
  },
  [CardCoverageStatus.NOT_ELIGIBLE]: {
    id: 'cardCoverage.messages.notEligible',
    defaultMessage: 'Ineligible Player',
  },
  [CardCoverageStatus.LEGEND]: {
    id: 'cardCoverage.messages.legend',
    defaultMessage: 'Legend Card',
  },
  [CardCoverageStatus.PARTIAL]: {
    id: 'cardCoverage.messages.partial',
    defaultMessage: 'Partial Eligibility',
  },
  [CardCoverageStatus.SPECIAL]: {
    id: 'cardCoverage.messages.special',
    defaultMessage: 'Collectible-only',
  },
});

export const descriptions = defineMessages({
  notCovered: {
    id: 'cardCoverage.notCovered',
    defaultMessage:
      'This player is in a Team not covered by Sorare Pro. His points will not be scored.',
  },
  notCoveredBundle: {
    id: 'cardCoverage.notCoveredBundle',
    defaultMessage:
      'Players in this bundle are in teams not covered by Sorare Pro. Their points will not be scored.',
  },
  notEligible: {
    id: 'cardCoverage.notEligible',
    defaultMessage: 'This Card is not eligible for Sorare Pro',
  },
  legend: {
    id: 'cardCoverage.legend',
    defaultMessage:
      'This player is not covered in Sorare Pro except for special occasions.',
  },
  partial: {
    id: 'cardCoverage.partial',
    defaultMessage:
      'This player is in a club covered only in specific tournaments. His points will not be scored for his domestic league.',
  },
  partialBundle: {
    id: 'cardCoverage.partialBundle',
    defaultMessage:
      'Players in this bundle are in teams covered only in specific tournaments. Points will not be scored for domestic leagues.',
  },
  collectible: {
    id: 'cardCoverage.collectible',
    defaultMessage:
      'This is a special edition collectible-only Card. It cannot be used in Sorare Pro.',
  },
});

export const getCardCoverageDescription = (
  coverageStatus: CardCoverageStatus,
  bundle?: boolean
): MessageDescriptor | null => {
  switch (coverageStatus) {
    case CardCoverageStatus.NOT_ELIGIBLE:
      return descriptions.notEligible;
    case CardCoverageStatus.LEGEND:
      return descriptions.legend;
    case CardCoverageStatus.PARTIAL:
      return bundle ? descriptions.partialBundle : descriptions.partial;
    case CardCoverageStatus.SPECIAL:
      return descriptions.collectible;
    case CardCoverageStatus.NOT_COVERED:
      return bundle ? descriptions.notCoveredBundle : descriptions.notCovered;
    default:
      return null;
  }
};
