import algoliasearchHelper, { SearchParameters } from 'algoliasearch-helper';
import { SearchResponse } from 'algoliasearch-helper/types/algoliasearch';
import { SearchClient } from 'algoliasearch/lite';
import { useEffect, useState } from 'react';

import { useSearchClient } from 'hooks/search/useSearchClient';
import { joinFiltersWithAnd } from 'lib/algolia';

interface Props {
  index: string;
  facetFilters: string;
  facets?: string[];
  distinct?: boolean;
  hitsPerPage?: number;
  params?: Partial<SearchParameters>;
  attributesToRetrieve?: string[] | null;
  optionalFilters?: string | string[];
  skip?: boolean;
}

export const facetedSearchCards = async <T>(
  searchClient: SearchClient,
  props: Omit<Props, 'skip'>
) => {
  const {
    index,
    facetFilters,
    facets,
    distinct,
    hitsPerPage,
    attributesToRetrieve,
    params,
    optionalFilters,
  } = props;

  const baseParams = algoliasearchHelper(searchClient, index, {
    ...params,
  }).getQuery();

  return searchClient
    .search<T>([
      {
        indexName: index,
        type: 'default',
        params: {
          ...baseParams,
          analytics: false,
          // Force to unknown facets to workaround instantsearch bug
          facets: facets || ['-'],
          ...(distinct !== undefined && { distinct }),
          ...(hitsPerPage !== undefined && { hitsPerPage }),
          ...(attributesToRetrieve && { attributesToRetrieve }),
          page: 0,
          filters: joinFiltersWithAnd(
            [baseParams.filters, facetFilters].filter(Boolean)
          ),
          optionalFilters,
        },
      },
    ])
    .then(({ results }) => results[0] as SearchResponse<T>);
};

export const useFacetedSearchCards = <T>(props: Props) => {
  const {
    skip,
    index,
    facetFilters,
    facets,
    distinct,
    hitsPerPage,
    params,
    attributesToRetrieve,
    optionalFilters,
  } = props;
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<SearchResponse<T>>();
  const searchClient = useSearchClient();
  const hasFetch = !!response;
  useEffect(() => {
    if (skip) {
      return;
    }

    if (!refresh && hasFetch) {
      return;
    }

    setLoading(true);

    facetedSearchCards<T>(searchClient, {
      index,
      facetFilters,
      facets,
      distinct,
      hitsPerPage,
      params,
      attributesToRetrieve,
    }).then(res => {
      setResponse(res as SearchResponse<T>);
      setLoading(false);
      setRefresh(false);
    });
  }, [
    attributesToRetrieve,
    distinct,
    facetFilters,
    facets,
    hitsPerPage,
    index,
    optionalFilters,
    params,
    searchClient,
    setRefresh,
    skip,
    refresh,
    hasFetch,
  ]);

  return {
    loading,
    results: skip ? null : response,
    refresh: () => setRefresh(true),
  };
};
