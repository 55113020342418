import { useEffect } from 'react';

import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import useLifecycle, { LIFECYCLE } from 'hooks/useLifecycle';
import { STORAGE } from 'hooks/useLocalStorage';
import { local as localStorage } from 'lib/storage';

export const StoreLastVisitedSport = () => {
  const { sport } = useSportContext();
  const { currentUser } = useCurrentUserContext();
  const { update: storeLastVisitedSport, lifecycle } = useLifecycle();
  const lastVisitedSport = lifecycle?.lastVisitedSport;

  useEffect(() => {
    if (currentUser?.slug && sport !== lastVisitedSport) {
      storeLastVisitedSport(LIFECYCLE.lastVisitedSport, sport);
    }
  }, [currentUser?.slug, lastVisitedSport, sport, storeLastVisitedSport]);

  useEffect(() => {
    // store the last visited sport in local storage without the `useLocalStorage` hook
    // that also watch for changes and rely on an initialValue which isn't applicable here
    localStorage.setItem(STORAGE.lastVisitedSport, sport);
  }, [sport]);

  return null;
};
