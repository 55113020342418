import { useIsMobileApp } from './useIsMobileApp';

export const useEmitClickButton = () => {
  const { isAndroidApp, postMessage } = useIsMobileApp();
  return () => {
    if (isAndroidApp) {
      postMessage('clickButton', {});
    }
  };
};
