import { useLazyQuery } from '@apollo/client';
import { ReactNode, useEffect } from 'react';

import {
  NBAOnboardingTask,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { useOnboardingInProgress } from '@sorare/core/src/components/onboarding/useOnboardingInProgress';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import usePrevious from '@sorare/core/src/hooks/usePrevious';

import CurrentNBAUserContextProvider from './index';
import { CURRENT_NBA_USER_QUERY } from './queries';

interface Props {
  children: ReactNode;
}

export const CurrentNBAUserProvider = ({ children }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const onboardingInProgress = useOnboardingInProgress(Sport.NBA);
  const [getCurrentNBAUser, { data, loading: fetchingNBAUser }] = useLazyQuery(
    CURRENT_NBA_USER_QUERY
  );
  const currentNBAUser = data?.currentUser;
  const onboardingStatus =
    currentNBAUser?.nbaCurrentUserData?.onboardingState?.nextTask;

  // BE considers users have onboarded although they may not have completed the frontend flow
  const needsOnboarding =
    onboardingStatus === NBAOnboardingTask.SELECT_STARTER_CARD ||
    onboardingInProgress;

  const currentUserSlug = currentUser?.slug;
  const previousSlug = usePrevious(currentUserSlug);
  useEffect(() => {
    if (fetchingNBAUser) {
      return;
    }
    if (currentUserSlug !== previousSlug) {
      getCurrentNBAUser();
    }
  }, [currentUserSlug, getCurrentNBAUser, fetchingNBAUser, previousSlug]);

  return (
    <CurrentNBAUserContextProvider
      value={{
        currentNBAUser,
        needsOnboarding,
        fetching: currentUserSlug !== previousSlug || fetchingNBAUser,
      }}
    >
      {children}
    </CurrentNBAUserContextProvider>
  );
};

export default CurrentNBAUserProvider;
