import { ReactNode, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FiatWalletAccountState } from '__generated__/globalTypes';
import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { Vertical } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
import { useCashWalletKycEvents } from 'hooks/wallets/useCashWalletKycEvents';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';
import { fiatWallet } from 'lib/glossary';
import {
  EnterCashWalletKYCFlow_Source,
  EnterCashWalletKYCFlow_Target,
} from 'protos/events/platform/web/events';

import { CreateFiatWallet } from '../CreateFiatWallet';

const WithPadding = styled.div`
  padding: 0 var(--double-unit) var(--double-unit);
`;

const Helper = styled(Vertical)`
  text-align: left;
  padding: var(--intermediate-unit);
  background-color: var(--c-neutral-400);
  border-radius: var(--unit);
  width: 100%;
`;

const StyledButtonBase = styled(ButtonBase)`
  color: var(--c-link);
  font-weight: var(--t-bold);
  text-align: left;
`;

type Props = {
  statusTarget: FiatWalletAccountState;
  defaultHelper: ReactNode;
  canListAndTradeHelper?: ReactNode;
  withPadding?: boolean;
  source: EnterCashWalletKYCFlow_Source;
};

export const NeedsValidatedFiatWalletHelper = ({
  canListAndTradeHelper,
  defaultHelper,
  statusTarget = FiatWalletAccountState.VALIDATED_OWNER,
  withPadding = false,
  source,
}: Props) => {
  const [showCreateFiatWallet, setShowCreateFiatWallet] = useState(false);
  const { trackEnter } = useCashWalletKycEvents();
  const { canListAndTrade, canDepositAndWithdraw } = useFiatBalance();
  const cta = useMemo(() => {
    if (statusTarget === FiatWalletAccountState.OWNER)
      return <FormattedMessage {...fiatWallet.activateCashWallet} />;
    return (
      <FormattedMessage
        {...(canListAndTrade
          ? fiatWallet.addMyId
          : fiatWallet.activateCashWalletAndAddAnId)}
      />
    );
  }, [canListAndTrade, statusTarget]);

  if (canDepositAndWithdraw) return null;

  if (statusTarget === FiatWalletAccountState.OWNER && canListAndTrade)
    return null;

  const helper = (
    <>
      <Helper>
        <LabelM color="var(--c-neutral-700)">
          {canListAndTrade ? canListAndTradeHelper : defaultHelper}
        </LabelM>
        <LabelM bold>
          <StyledButtonBase
            onClick={() => {
              setShowCreateFiatWallet(true);
              trackEnter({
                source,
                target:
                  statusTarget === FiatWalletAccountState.VALIDATED_OWNER
                    ? EnterCashWalletKYCFlow_Target.VALIDATED_OWNER
                    : EnterCashWalletKYCFlow_Target.OWNER,
              });
            }}
          >
            {cta}
          </StyledButtonBase>
        </LabelM>
      </Helper>
      {showCreateFiatWallet && (
        <CreateFiatWallet
          onDismissActivationSuccess={() => setShowCreateFiatWallet(false)}
          onClose={() => setShowCreateFiatWallet(false)}
          statusTarget={statusTarget}
          canDismissAfterActivation={false}
        />
      )}
    </>
  );
  if (withPadding) return <WithPadding>{helper}</WithPadding>;
  return helper;
};
