import { OwnerTransfer } from '@sorare/core/src/__generated__/globalTypes';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { Color } from '@sorare/core/src/style/types';

import Auction from 'components/transfers/Auction';
import InstantBuy from 'components/transfers/InstantBuy';
import Pack from 'components/transfers/Pack';
import ReferralReward from 'components/transfers/ReferralReward';
import Reward from 'components/transfers/Reward';
import Shards from 'components/transfers/Shards';
import Transfer from 'components/transfers/Transfer';

type Props = {
  transferType: OwnerTransfer;
  color?: Color;
};
export const TokenTransferTypeIcon = ({ transferType, color }: Props) => {
  if (
    [OwnerTransfer.PACK, OwnerTransfer.BUNDLED_ENGLISH_AUCTION].includes(
      transferType
    )
  ) {
    return (
      <Text16>
        <Pack color={color} />
      </Text16>
    );
  }

  if (OwnerTransfer.REFERRAL === transferType) {
    return <ReferralReward color={color} />;
  }
  if (OwnerTransfer.ENGLISH_AUCTION === transferType) {
    return <Auction color={color} />;
  }
  if (OwnerTransfer.REWARD === transferType) {
    return (
      <Text16>
        <Reward color={color} />
      </Text16>
    );
  }
  if (OwnerTransfer.SHARDS === transferType) {
    return (
      <Text16>
        <Shards color={color} />
      </Text16>
    );
  }
  if (OwnerTransfer.INSTANT_BUY === transferType) {
    return (
      <Text16>
        <InstantBuy color={color} />
      </Text16>
    );
  }
  return (
    <Text16>
      <Transfer color={color} />
    </Text16>
  );
};

export default TokenTransferTypeIcon;
