import { Navigate, Outlet, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

import { LayoutParams } from './__generated__/routeParams';

const SeasonsLeaderboardSeasonSlugLayout = () => {
  const { currentUser } = useCurrentUserContext();
  const { seasonSlug, userSlug } = useTypedParams<LayoutParams>();

  if (!userSlug) {
    return (
      <Navigate
        to={generatePath(FOOTBALL_PLAY_RIVALS_LEADERBOARD_SEASONSLUG_USERSLUG, {
          seasonSlug,
          userSlug: currentUser?.slug || '',
        })}
        replace
      />
    );
  }

  return <Outlet />;
};
export default SeasonsLeaderboardSeasonSlugLayout;
