import { getRoutesFromFiles } from '@sorare/routing';

export const routeObjects = [
  {
    path: 'help',
    children: getRoutesFromFiles(
      import.meta.glob('./**/(page|layout).tsx'),
      '../../packages/help/src/pages'
    ),
  },
];
