import { ReactNode, useState } from 'react';

import { FiatCurrency } from '__generated__/globalTypes';

import { ChooseCurrency } from '../ChooseCurrency';
import ReviewInformation from '../ReviewInformation';
import { TellUsAboutYou, TellUsAboutYouValue } from '../TellUsAboutYou';
import { CreateFiatWalletSteps } from '../type';

type Props = {
  unsupportedCountryCta?: ReactNode;
  step: CreateFiatWalletSteps;
  setStep: (step: CreateFiatWalletSteps) => void;
  onClose: () => void;
  onSuccess?: () => void;
};

export const DeclarativeForm = ({
  step,
  unsupportedCountryCta,
  setStep,
  onClose,
  onSuccess,
}: Props) => {
  const [tellUsAboutYou, setTellusAboutYou] = useState<
    TellUsAboutYouValue | undefined
  >();

  const { nationalityCode, countryOfResidenceCode, firstName, lastName, dob } =
    tellUsAboutYou || {};
  const [currency, setCurrency] = useState<FiatCurrency | undefined>();

  const formIsIncomplete =
    !nationalityCode ||
    !countryOfResidenceCode ||
    !dob ||
    !currency ||
    !firstName ||
    !lastName;

  if (step === CreateFiatWalletSteps.TELL_US_ABOUT_YOU) {
    return (
      <TellUsAboutYou
        unsupportedCountryCta={unsupportedCountryCta}
        setStep={setStep}
        presetValues={tellUsAboutYou}
        onSuccess={args => {
          setTellusAboutYou(args);
          setStep(CreateFiatWalletSteps.CHOOSE_CURRENCY);
        }}
        onSubmitWithChoosenCountryNotSupported={onClose}
      />
    );
  }
  if (step === CreateFiatWalletSteps.CHOOSE_CURRENCY) {
    return (
      <ChooseCurrency
        presetCurrency={currency}
        setStep={setStep}
        onChange={curr => {
          setCurrency(curr);
        }}
        onSubmit={async () => {
          if (!formIsIncomplete) {
            setStep(CreateFiatWalletSteps.REVIEW_INFORMATION);
          }
          return {};
        }}
        onSuccess={onSuccess}
      />
    );
  }
  return (
    (!formIsIncomplete && (
      <ReviewInformation
        args={{
          nationalityCode,
          countryOfResidenceCode,
          dob,
          firstName,
          lastName,
          currency,
        }}
        setStep={setStep}
        onChange={({ currency: currencyArgs, ...tellUsAboutYouArgs }) => {
          setCurrency(currencyArgs);
          setTellusAboutYou(tellUsAboutYouArgs);
        }}
      />
    )) ||
    null
  );
};
