import { TypedDocumentNode, gql } from '@apollo/client';

import { namedOperations } from '@sorare/core/src/__generated__/operations';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  ClaimDeliverableItemOrder,
  ClaimDeliverableItemOrderVariables,
} from './__generated__/useClaimDeliverableItemOrder.graphql';

const CLAIM_DELIVERABLE_ITEM_ORDER_MUTATION = gql`
  mutation ClaimDeliverableItemOrder($input: claimDeliverableItemOrderInput!) {
    claimDeliverableItemOrder(input: $input) {
      deliverableItemOrder {
        id
        aasmState
      }
      errors {
        message
        code
        path
      }
    }
  }
` as TypedDocumentNode<
  ClaimDeliverableItemOrder,
  ClaimDeliverableItemOrderVariables
>;

export const useClaimDeliverableItemOrder = () => {
  const [claim, { loading }] = useMutation(
    CLAIM_DELIVERABLE_ITEM_ORDER_MUTATION,
    { refetchQueries: [namedOperations.Query.UnclaimedDeliveryItemOrders] }
  );
  return { claim, loading };
};
