import { TypedDocumentNode, gql } from '@apollo/client';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import {
  ResponsiveImg,
  ValidWidths,
} from '@sorare/core/src/atoms/ui/ResponsiveImg';

import { UsSportsPlayerAvatar_player } from './__generated__/index.graphql';

const Picture = styled(ResponsiveImg)`
  max-width: 250px;
  width: 100%;
  vertical-align: bottom;
`;
const PicturePlaceholder = styled.div`
  width: 100%;
  background: var(--c-neutral-300);
  display: grid;
  justify-content: center;
  align-items: center;
  color: var(--c-neutral-600);
`;

type Props = {
  player: {
    displayName: string;
    avatarPictureUrl: string;
  };
  cropWidth?: ValidWidths;
  className?: string;
  showPlaceholder?: boolean;
};

export const PlayerAvatar = ({
  player,
  cropWidth = 320,
  className = '',
  showPlaceholder = true,
}: Props) => {
  if (player.avatarPictureUrl === '') {
    if (!showPlaceholder) return null;
    return (
      <PicturePlaceholder className={className}>
        <FontAwesomeIcon icon={faUser} size="3x" />
      </PicturePlaceholder>
    );
  }
  return (
    <Picture
      cropWidth={cropWidth}
      alt={player.displayName}
      src={player.avatarPictureUrl}
      title={player.displayName}
      className={className}
    />
  );
};

PlayerAvatar.fragments = {
  player: gql`
    fragment UsSportsPlayerAvatar_player on PlayerInterface {
      slug
      displayName
      avatarPictureUrl
    }
  ` as TypedDocumentNode<UsSportsPlayerAvatar_player>,
};
