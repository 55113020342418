import { TypedDocumentNode, gql } from '@apollo/client';

import { Card3DWithFallback } from 'components/3d/Card3DWithFallback';

import { TokenImg } from '../ui';
import { CardReward_anyCardReward } from './__generated__/index.graphql';

type Props = {
  reward: CardReward_anyCardReward;
};

export const CardReward = ({ reward }: Props) => {
  return (
    <TokenImg>
      <Card3DWithFallback card={reward.card} disableFallback startOnBack />
    </TokenImg>
  );
};

CardReward.fragments = {
  anyCardReward: gql`
    fragment CardReward_anyCardReward on AnyCardReward {
      id
      card {
        slug
        ...Card3DWithFallback_anyCardInterface
      }
    }
    ${Card3DWithFallback.fragments.anyCardInterface}
  ` as TypedDocumentNode<CardReward_anyCardReward>,
};
