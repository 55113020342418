import { TypedDocumentNode, gql } from '@apollo/client';

import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';

import MobileAppPrimaryOfferBuyFieldWrapper, {
  Props as MobileAppPrimaryOfferBuyFieldWrapperProps,
} from './MobileAppPrimaryOfferBuyFieldWrapper';
import WebPrimaryOfferBuyFieldWrapper, {
  Props as WebPrimaryOfferBuyFieldWrapperProps,
} from './WebPrimaryOfferBuyFieldWrapper';
import { PrimaryOfferBuyFieldWrapper_anyCard } from './__generated__/index.graphql';

export type Props = WebPrimaryOfferBuyFieldWrapperProps &
  MobileAppPrimaryOfferBuyFieldWrapperProps;

const PrimaryOfferBuyFieldWrapper = (props: Props) => {
  const { isMobileApp } = useIsMobileApp();

  if (isMobileApp) return <MobileAppPrimaryOfferBuyFieldWrapper {...props} />;
  return <WebPrimaryOfferBuyFieldWrapper {...props} />;
};

PrimaryOfferBuyFieldWrapper.fragments = {
  anyCard: gql`
    fragment PrimaryOfferBuyFieldWrapper_anyCard on AnyCardInterface {
      slug
      ...WebPrimaryOfferBuyFieldWrapper_anyCard
      ...MobileAppPrimaryOfferBuyFieldWrapper_anyCard
    }
    ${WebPrimaryOfferBuyFieldWrapper.fragments.anyCard}
    ${MobileAppPrimaryOfferBuyFieldWrapper.fragments.anyCard}
  ` as TypedDocumentNode<PrimaryOfferBuyFieldWrapper_anyCard>,
};

export default PrimaryOfferBuyFieldWrapper;
