import { useScrollTo as useScrollToHook } from './useScrollTo';
import { useScrollToQueryParameter as useQueryParameter } from './useScrollToQueryParameter';

export enum ScrollTo {
  Bid = 'Bid',
}

export const useScrollTo = useScrollToHook;

export const useScrollToQueryParameter = useQueryParameter;
