import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
};

export const Shards = ({ size = 2, className }: Props) => {
  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 79 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="28.4734"
        y="25.4558"
        width="35.836"
        height="48.2102"
        transform="rotate(18 28.4734 25.4558)"
        stroke="currentColor"
        strokeWidth="10.9113"
      />
      <rect
        x="25.8697"
        y="7.09351"
        width="52.4198"
        height="11.4295"
        transform="rotate(18 25.8697 7.09351)"
        fill="currentColor"
      />
      <rect
        x="4.31433"
        y="73.6211"
        width="52.4198"
        height="11.4295"
        transform="rotate(18 4.31433 73.6211)"
        fill="currentColor"
      />
      <path
        d="M14.3615 66.0722L48.9965 66.0723L43.7579 83.9979L11.9004 73.6468L14.3615 66.0722Z"
        fill="currentColor"
      />
      <rect
        x="38.1494"
        y="1.81519"
        width="31.7339"
        height="18.8053"
        transform="rotate(18 38.1494 1.81519)"
        fill="currentColor"
      />
    </svg>
  );
};
