import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  So5FixtureEvent,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { Block } from '@sorare/core/src/atoms/layout/Block';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import {
  LabelM,
  Text16,
  Title3,
  Title4,
} from '@sorare/core/src/atoms/typography';
import { ANY_SPORT_PLAY_EVENTTYPE_FIXTURE } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import {
  Props as UseAmountWithConversionProps,
  useAmountWithConversion,
} from '@sorare/core/src/hooks/useAmountWithConversion';
import { ConversionCreditWithAmounts } from '@sorare/core/src/hooks/useConversionCredits';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import {
  useMonetaryAmount,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { useStoreAmount } from '@sorare/core/src/hooks/useStoreAmount';
import { glossary, transferMarket } from '@sorare/core/src/lib/glossary';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { ClickInstantBuy_Flow } from '@sorare/core/src/protos/events/so5/shared/events';
import { breakpoints, tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { ConversionCredits } from 'components/buyActions/PaymentBox/ConversionCredits';
import useCalculateAmounts from 'components/buyActions/PaymentProvider/useCalculateAmounts';
import { useConversionCreditSelector } from 'components/buyActions/PaymentProvider/useConversionCreditSelector';
import { PromoBanners } from 'components/market/PromoBanners';

import PrimaryBuyBuyField from '../PrimaryBuyBuyField';
import { OpenPrimaryBuy_anyCard } from './__generated__/index.graphql';

const Root = styled(Block)`
  display: flex;
  flex-direction: column;
  gap: var(--double-unit);

  @media ${tabletAndAbove} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: var(--unit);

  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Column = styled(Vertical).attrs({ gap: 0.5 })``;

const Line = styled(Horizontal)`
  align-items: baseline;
`;

const CrossedText = styled(LabelM)`
  text-decoration: line-through;
`;

const PriceLine = ({
  primaryOffer,
  bigger,
  conversionCredit,
  ...props
}: Omit<UseAmountWithConversionProps, 'monetaryAmount'> & {
  conversionCredit?: ConversionCreditWithAmounts | undefined;
  primaryOffer: NonNullable<OpenPrimaryBuy_anyCard['latestPrimaryOffer']>;
  bigger: boolean;
}) => {
  const { toMonetaryAmount } = useMonetaryAmount();
  const { totalMonetaryAmount } = useCalculateAmounts({
    creditCardFee: 0,
    activeFee: false,
    isFiat: false,
    monetaryAmount: primaryOffer.price
      ? toMonetaryAmount(primaryOffer.price)
      : zeroMonetaryAmount,
    referenceCurrency:
      primaryOffer.price?.referenceCurrency || SupportedCurrency.WEI,
    conversionCreditToUse: conversionCredit,
  });

  const { main: beforeDiscountMain, exponent: beforeDiscountExponent } =
    useAmountWithConversion({
      monetaryAmount: primaryOffer.price || zeroMonetaryAmount,
      ...props,
    });

  const { main: afterDiscountMain, exponent: afterDiscountExponent } =
    useAmountWithConversion({
      monetaryAmount: totalMonetaryAmount,
      ...props,
    });
  const { storeMain, storeExponent } = useStoreAmount({
    monetaryAmount: primaryOffer.price || zeroMonetaryAmount,
    googlePrice: primaryOffer.googlePrice,
    applePrice: primaryOffer.applePrice,
  });

  const main = conversionCredit ? afterDiscountMain : beforeDiscountMain;
  const exponent = conversionCredit
    ? afterDiscountExponent
    : beforeDiscountExponent;

  if (bigger) {
    return (
      <>
        <Line gap={1}>
          <Title3 color="var(--c-neutral-1000)">{storeMain || main}</Title3>
          <Text16 color="var(--c-neutral-600)">
            {storeExponent || exponent}
          </Text16>
        </Line>
        {conversionCredit && (
          <Line gap={1}>
            <LabelM color="var(--c-neutral-600)">
              <FormattedMessage
                id="PriceLine.beforeCredits"
                defaultMessage="Before credits:"
              />
            </LabelM>
            <CrossedText color="var(--c-neutral-600)">
              {beforeDiscountMain} {beforeDiscountExponent}
            </CrossedText>
          </Line>
        )}
      </>
    );
  }
  return (
    <>
      <Line gap={1}>
        <Title4 color="var(--c-neutral-1000)">{storeMain || main}</Title4>
        <LabelM color="var(--c-neutral-600)">
          {storeExponent || exponent}
        </LabelM>
      </Line>
      {conversionCredit && (
        <Line gap={1}>
          <CrossedText color="var(--c-neutral-600)">
            {beforeDiscountMain} {beforeDiscountExponent}
          </CrossedText>
        </Line>
      )}
    </>
  );
};

type Props = {
  card: OpenPrimaryBuy_anyCard;
};

export const OpenPrimaryBuy = ({ card }: Props) => {
  const { up: isTabletOrAbove } = useScreenSize('tablet');
  const { generateSportContextPath } = useSportContext();

  const { toMonetaryAmount } = useMonetaryAmount();
  const primaryOffer = card.latestPrimaryOffer;
  const { isMobileApp } = useIsMobileApp();

  const price = primaryOffer?.price;

  const amount = price ? toMonetaryAmount(price) : zeroMonetaryAmount;

  const { conversionCredit, ...rest } = useConversionCreditSelector({
    amount,
    sport: card.sport,
    canUseConversionCredit: !isMobileApp,
  });
  const referenceCurrency = price?.referenceCurrency || SupportedCurrency.WEI;
  const { conversionCreditMonetaryAmount } = useCalculateAmounts({
    creditCardFee: 0,
    activeFee: false,
    isFiat: false,
    monetaryAmount: price ? toMonetaryAmount(price) : zeroMonetaryAmount,
    referenceCurrency: price?.referenceCurrency || SupportedCurrency.WEI,
    conversionCreditToUse: conversionCredit,
  });

  if (!price) return null;

  return (
    <Root>
      <Column>
        <PriceLine
          primaryOffer={primaryOffer}
          bigger={isTabletOrAbove}
          {...(conversionCredit ? { conversionCredit } : {})}
        />
        <Text16 color="var(--c-neutral-600)">
          <FormattedMessage {...transferMarket.instantBuy} />
        </Text16>
      </Column>
      <ButtonsContainer>
        <PrimaryBuyBuyField
          size="medium"
          card={card}
          origin={ClickInstantBuy_Flow.MARKETPLACE}
          defaultConversionCredit={conversionCredit}
          primaryBuyConfirmationOptions={{
            actions: {
              primary: {
                label: <FormattedMessage {...glossary.gotIt} />,
              },
              secondary: {
                to: generateSportContextPath(ANY_SPORT_PLAY_EVENTTYPE_FIXTURE, {
                  sport: card.sport,
                  params: {
                    eventType: So5FixtureEvent.CLASSIC.toLowerCase(),
                    fixture: '',
                  },
                }),
                label: <FormattedMessage {...glossary.playInPro} />,
              },
            },
            variant: 'pro',
          }}
        />
      </ButtonsContainer>
      {!isMobileApp && (
        <ConversionCredits
          amount={amount}
          conversionCredit={conversionCredit}
          row
          conversionCreditMonetaryAmount={conversionCreditMonetaryAmount}
          referenceCurrency={referenceCurrency}
          {...rest}
        />
      )}

      <PromoBanners promotionalEvents={primaryOffer} rounded />
    </Root>
  );
};

OpenPrimaryBuy.fragments = {
  anyCard: gql`
    fragment OpenPrimaryBuy_anyCard on AnyCardInterface {
      slug
      sport
      latestPrimaryOffer {
        id
        price {
          ...MonetaryAmountFragment_monetaryAmount
        }
        eligibleForBlueprintRewards
        googlePrice(countryCode: $googlePlayStoreCountryCode)
          @include(if: $isAndroidApp)
        applePrice @include(if: $isIosApp) {
          ...MonetaryAmountFragment_monetaryAmount
        }
        ...PromoBanners_PromotionalEventsInterface
      }
      ...PrimaryBuyBuyField_anyCard
    }
    ${monetaryAmountFragment}
    ${PrimaryBuyBuyField.fragments.anyCard}
    ${PromoBanners.fragments.PromotionalEventsInterface}
  ` as TypedDocumentNode<OpenPrimaryBuy_anyCard>,
};

export default OpenPrimaryBuy;
