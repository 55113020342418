import { Navigate } from 'react-router-dom';

import { LANDING } from 'constants/__generated__/routes';
import useAfterLoggedInTarget from 'hooks/useAfterLoggedInTarget';
import useTruncatedLocation from 'hooks/useTruncatedLocation';

export const useRedirectToLandingWithAction = (
  action: 'signup' | 'signin',
  to?: string
) => {
  const location = useTruncatedLocation();
  const afterLoggedInTarget = useAfterLoggedInTarget() || location;

  return function RedirectToLanding() {
    return (
      <Navigate
        replace
        to={{
          pathname: to || LANDING,
          search: `action=${action}`,
        }}
        state={{
          afterLoggedInTarget,
        }}
      />
    );
  };
};

export const useRedirectToSignup = (to?: string) =>
  useRedirectToLandingWithAction('signup', to);
export const useRedirectToSignin = (to?: string) =>
  useRedirectToLandingWithAction('signin', to);
