import { TypedDocumentNode, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { SCORE_LIMIT } from '@sorare/core/src/components/player/FixtureChart';
import { NoIndex } from '@sorare/core/src/contexts/seo';
import usePaginatedQuery from '@sorare/core/src/hooks/graphql/usePaginatedQuery';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';
import useInfiniteScroll from '@sorare/core/src/hooks/useInfiniteScroll';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { useTitleAndDescription } from '@sorare/core/src/hooks/useTitleAndDescription';
import { scarcityNames } from '@sorare/core/src/lib/cards';
import { format } from '@sorare/core/src/lib/seasons';
import { metadatas } from '@sorare/core/src/lib/seo/common';

import { CardPageContent } from 'components/card/CardPageContent';

import {
  FootballCardQuery,
  FootballCardQueryVariables,
} from './__generated__/index.graphql';

export const CARD_QUERY = gql`
  query FootballCardQuery(
    $slug: String!
    $bidCursor: String
    $scoreCursor: String
    $first: Int
    $onlyPrimary: Boolean = false
    $googlePlayStoreCountryCode: String
    $isAndroidApp: Boolean = false
    $isIosApp: Boolean = false
  ) {
    football {
      card(slug: $slug) {
        slug
        name
        rarityTyped
        singleCivilYear
        seasonYear
        anyPlayer {
          slug
          displayName
        }
        ...CardPage_card
      }
    }
  }
  ${CardPageContent.fragments.card}
` as TypedDocumentNode<FootballCardQuery, FootballCardQueryVariables>;

export const CardPage = () => {
  const { slug: slugFromParams } = useParams();
  const cardDialogParameter = useDialogParam(DialogKey.card);
  const slug = cardDialogParameter || slugFromParams;
  const { isIosApp, isAndroidApp, googlePlayStoreCountryCode } =
    useIsMobileApp();
  const { data, loading, loadMore } = usePaginatedQuery(CARD_QUERY, {
    variables: {
      slug: slug!,
      first: SCORE_LIMIT,
      isIosApp,
      isAndroidApp,
      googlePlayStoreCountryCode,
    },
    skip: !slug,
    connection: 'TokenBidConnection',
  });

  const { InfiniteScrollLoader } = useInfiniteScroll(
    () => {
      loadMore(
        false,
        {
          slug: slug!,
          scoreCursor: data?.football.card?.allSo5Scores?.pageInfo.endCursor,
          first: SCORE_LIMIT,
        },
        'So5ScoreConnection'
      );
    },
    !!data?.football.card?.allSo5Scores?.pageInfo.hasNextPage,
    loading
  );

  useTitleAndDescription(
    metadatas.card.title,
    metadatas.card.description,
    !!data?.football.card && {
      display_name: data.football.card.anyPlayer.displayName,
      scarcity: scarcityNames[data.football.card.rarityTyped],
      season: format(data.football.card.seasonYear, {
        singleCivilYear: data.football.card.singleCivilYear,
      }),
    }
  );

  return (
    <>
      <NoIndex />
      <CardPageContent
        card={data?.football.card}
        loadMoreBids={loadMore}
        InfiniteScrollLoader={<InfiniteScrollLoader />}
      />
    </>
  );
};
