import WebFont from 'webfontloader';

export const loadTypekitFonts = async (id: string) => {
  return new Promise<void>((resolve, reject) => {
    WebFont.load({
      typekit: { id },
      active: resolve,
      inactive: reject,
    });
  });
};
