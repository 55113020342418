import { TypedDocumentNode, gql } from '@apollo/client';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Injured as WarningIcon } from '@sorare/core/src/atoms/icons/Injured';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { TeamAvatar } from '@sorare/core/src/components/club/TeamAvatar';

import { RawGameCount } from '@sorare/gameplay/src/components/game/GameCount';

import {
  UpcomingPlayerCardScore_anyGame,
  UpcomingPlayerCardScore_anyPlayer,
} from './__generated__/index.graphql';

const Wrapper = styled(Vertical).attrs({ gap: 0, center: true })`
  justify-content: stretch;
`;

const Line = styled(Horizontal).attrs({ gap: 0.5 })`
  font: var(--t-12);
  font-weight: var(--t-bold);
  &.small {
    font-size: 10px;
  }
`;

const SmallTeamLogo = styled(TeamAvatar)`
  display: inline-flex;
  max-width: var(--double-unit);
  max-height: var(--double-unit);
`;

const Icon = styled.div`
  display: inline-flex;
  * {
    width: var(--double-unit);
    height: var(--double-unit);
  }
`;

type Props = {
  player: UpcomingPlayerCardScore_anyPlayer;
  upcomingGames: UpcomingPlayerCardScore_anyGame[];
  hasTBDGames?: boolean;
};

export const UpcomingPlayerCardScore = ({
  player,
  upcomingGames,
  hasTBDGames,
}: Props) => {
  const nbUpcomingGames = upcomingGames.length;

  return (
    <Wrapper>
      <Line>
        {nbUpcomingGames > 0 ? (
          <>
            {upcomingGames.map(g => {
              return (
                <SmallTeamLogo
                  key={g.id}
                  team={
                    player?.activeClub?.slug === g.awayTeam?.slug
                      ? g.homeTeam
                      : g.awayTeam
                  }
                />
              );
            })}
          </>
        ) : (
          <Icon>
            {hasTBDGames ? <FontAwesomeIcon icon={faClock} /> : <WarningIcon />}
          </Icon>
        )}
      </Line>
      <Line className="small">
        <RawGameCount
          nbGames={nbUpcomingGames}
          hasTBDGames={hasTBDGames}
          spellNoGame
        />
      </Line>
    </Wrapper>
  );
};

UpcomingPlayerCardScore.fragments = {
  anyPlayer: gql`
    fragment UpcomingPlayerCardScore_anyPlayer on AnyPlayerInterface {
      slug
      activeClub {
        slug
      }
    }
  ` as TypedDocumentNode<UpcomingPlayerCardScore_anyPlayer>,
  anyGame: gql`
    fragment UpcomingPlayerCardScore_anyGame on AnyGameInterface {
      id
      awayTeam {
        slug
        ...TeamAvatar_team
      }
      homeTeam {
        slug
        ...TeamAvatar_team
      }
    }
    ${TeamAvatar.fragments.team}
  ` as TypedDocumentNode<UpcomingPlayerCardScore_anyGame>,
};
