import { FormattedNumber } from 'react-intl';

import { Rarity, Sport } from '__generated__/globalTypes';
import { Button } from 'atoms/buttons/Button';
import { Box } from 'atoms/icons/Box';
import { Cards } from 'atoms/icons/Cards';
import { Shards } from 'atoms/icons/Shards';
import { LabelL } from 'atoms/typography';
import { Badge } from 'atoms/ui/Badge';
import {
  ANY_SPORT_PLAY_INVENTORY_BOXES_TYPE,
  ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
  ANY_SPORT_PLAY_INVENTORY_SHARDS_TYPE,
} from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';

type Props = {
  onClick?: () => void;
};

export const InventoryBalance = ({ onClick }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { sport = Sport.FOOTBALL, generateSportContextPath } =
    useSportContext();

  if (![Sport.FOOTBALL, Sport.NBA].includes(sport) || !currentUser) {
    return null;
  }
  const { anyUserSportProfile, shardsChests, unopenedBoxes } = currentUser;

  const { blueprintCardsCount = 0 } = anyUserSportProfile || {};
  const totalShards = shardsChests.reduce(
    (acc, chest) => acc + chest.cardShardsCount,
    0
  );

  const chestWithThresholdUnlocked = shardsChests.find(
    chest => chest.newThresholdUnlocked
  );

  return (
    <>
      {blueprintCardsCount > 0 && (
        <Button
          size="medium"
          color="transparent"
          to={generateSportContextPath(
            ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
            {
              params: {
                type: Rarity.limited,
              },
            }
          )}
          onClick={onClick}
        >
          <Cards />
          <LabelL as="span" bold>
            <FormattedNumber value={blueprintCardsCount} />
          </LabelL>
        </Button>
      )}
      <Button
        size="medium"
        color="transparent"
        to={generateSportContextPath(ANY_SPORT_PLAY_INVENTORY_BOXES_TYPE, {
          params: {
            type: Rarity.limited,
          },
        })}
        onClick={onClick}
      >
        <Box />
        <LabelL as="span" bold>
          <FormattedNumber value={unopenedBoxes.totalCount} />
        </LabelL>
      </Button>
      <Button
        size="medium"
        color="transparent"
        to={generateSportContextPath(ANY_SPORT_PLAY_INVENTORY_SHARDS_TYPE, {
          params: {
            type: chestWithThresholdUnlocked?.rarity || Rarity.limited,
          },
        })}
        onClick={onClick}
      >
        <Badge variant="dot" invisible={!chestWithThresholdUnlocked}>
          <Shards />
        </Badge>
        <LabelL as="span" bold>
          <FormattedNumber value={totalShards} />
        </LabelL>
      </Button>
    </>
  );
};
