import { ReactNode } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { AnimatedDiamond } from '@sorare/core/src/components/rivals/branded/AnimatedDiamond';
import { useIsDesktopAndAbove } from '@sorare/core/src/hooks/device/useIsDesktopAndAbove';
import { absCenter } from '@sorare/core/src/lib/style';
import { RGBColor } from '@sorare/core/src/style/types';

const appearKF = keyframes`
  from { transform: tranlate(-50%, -50%) scale(2); opacity: 0.2 }
  to { transform: translate(-50%, -50%) scale(1); opacity: 1 }
`;
const fadeAwayKF = keyframes`
  from { opacity: 1 };
  to { opacity: 0 };
`;
const Root = styled.div<{ fadeAway: boolean }>`
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  inset: 0;
  isolation: isolate;
  backdrop-filter: blur(22px);
  ${({ fadeAway }) =>
    fadeAway &&
    css`
      animation: ${fadeAwayKF} 0.5s forwards;
    `};
  animation-delay: 1.2s;
`;
const OuterDiamondWrapper = styled.div`
  ${absCenter}
  opacity: 0.5;
  z-index: 1;
  width: 120%;
`;
const InnerDiamondWrapper = styled.div`
  ${absCenter}
  width: 80%;
  z-index: 2;
`;
const Text = styled.div<{ animate: boolean }>`
  ${absCenter}
  ${({ animate }) =>
    animate
      ? css`
          transform: translate(-50%, -50%) scale(2);
          opacity: 0.2;
        `
      : css`
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        `};

  text-align: center;
  text-transform: uppercase;
  font-weight: var(--t-bold);
  z-index: 3;
  ${({ animate }) =>
    animate &&
    css`
      animation: ${appearKF} 0.5s forwards;
    `};
`;
const ExtraWrapper = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
`;

type Props = {
  color: RGBColor;
  text: string;
  animateText?: boolean;
  fadeAway?: boolean;
  extra?: ReactNode;
  forceMobile?: boolean;
  onAnimationEnd?: () => void;
};
export const DoubleAnimatedDiamond = ({
  color,
  text,
  animateText = true,
  fadeAway = true,
  extra,
  forceMobile,
  onAnimationEnd,
}: Props) => {
  const isDesktopAndAbove = useIsDesktopAndAbove();

  // scale font size to the width of the dialog
  let fontSize = `${(0.8 * 100) / text.length}vw`;
  if (!forceMobile && isDesktopAndAbove) {
    const widthOfDialog = 375;
    fontSize = `${(0.8 * widthOfDialog) / text.length}px`;
  }

  return (
    <Root fadeAway={fadeAway}>
      <OuterDiamondWrapper>
        <AnimatedDiamond
          fill={color}
          width="100%"
          delay={0.15}
          onAnimationEnd={onAnimationEnd}
        />
      </OuterDiamondWrapper>
      <InnerDiamondWrapper>
        <AnimatedDiamond fill={color} width="100%" />
      </InnerDiamondWrapper>
      <Text style={{ fontSize }} animate={animateText}>
        {text}
      </Text>
      {extra && <ExtraWrapper>{extra}</ExtraWrapper>}
    </Root>
  );
};
