import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { HeadlineL, HeadlineXS } from 'atoms/typography';
import { useIntlContext } from 'contexts/intl';

import { FileUploadStep, Props } from '../FileUploadStep';

const pepTemplateLinks = {
  en: 'https://www.mangopay.com/terms/PEP/PEP_form_EN.pdf',
  fr: 'https://www.mangopay.com/terms/PEP/PEP_form_FR.pdf',
  de: 'https://www.mangopay.com/terms/PEP/PEP_form_DE.pdf',
  es: 'https://www.mangopay.com/terms/PEP/PEP_form_ES.pdf',
};

const Link = styled.a`
  color: var(--c-brand-300);
  text-decoration: underline;
`;

export const PEPDeclarationUpload = (
  props: Omit<Props, 'children' | 'name'>
) => {
  const { language } = useIntlContext();
  return (
    <FileUploadStep name="pepDeclaration" {...props}>
      <HeadlineL>
        <FormattedMessage
          id="UnblockWallet.PepDeclarationUpload.title"
          defaultMessage="Fill out the politically Exposed Person form"
        />
      </HeadlineL>
      <HeadlineXS>
        <FormattedMessage
          id="UnblockWallet.PepDeclarationUpload.description"
          defaultMessage="Please fill out and upload the following Politically Exposed Person form:"
        />
      </HeadlineXS>
      <Link
        href={
          language in pepTemplateLinks
            ? pepTemplateLinks[language as keyof typeof pepTemplateLinks]
            : pepTemplateLinks.en
        }
        target="_blank"
        rel="noreferrer"
      >
        <FormattedMessage
          id="UnblockWallet.PepDeclarationUpload.download"
          defaultMessage="Download PEP declaration form"
        />
      </Link>
    </FileUploadStep>
  );
};
