import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

type Props = {
  width?: number;
  children: ReactNode;
};

const Root = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;

const Children = styled.div`
  display: flex;
  gap: var(--double-unit);
  & > * {
    width: var(--card-width);
    flex: 1 0 var(--card-width);
  }
`;

export const ResponsiveRow = ({ width = 160, children }: Props) => {
  return (
    <Root>
      <Children style={{ '--card-width': `${width}px` } as CSSProperties}>
        {children}
      </Children>
    </Root>
  );
};
