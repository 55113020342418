import classnames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';

import { Header, Props as HeaderProps } from './Header';

type Props = HeaderProps & {
  noBorder?: boolean;
  children?: ReactNode;
};

const Root = styled(Vertical).attrs({ gap: 2 })`
  align-items: stretch;
  background-color: var(--c-neutral-200);
  border-radius: var(--double-unit);
  width: 100%;
  padding: var(--double-unit);
  &.noBorder {
    border-top: none;
  }
`;

export const SettingsSection = ({
  title,
  description,
  noBorder,
  children,
  toggleButton,
}: Props) => {
  return (
    <Root className={classnames({ noBorder })}>
      <Header
        title={title}
        description={description}
        toggleButton={toggleButton}
      />
      {children}
    </Root>
  );
};
