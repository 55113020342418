import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { useGetInteractionContext } from '@sorare/core/src/hooks/useGetInteractionContext';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { fromWei } from '@sorare/core/src/lib/wei';

import { tokenInfoProperties, tokensInfoProperties } from 'lib/tokenEvents';

import { useClickBuyEvent_anyCard } from './__generated__/useClickBuyEvent.graphql';

export const useClickBuyEvent = () => {
  const track = useEvents();
  const getInteractionContext = useGetInteractionContext();
  const trackClickBuy = useCallback(
    (
      offerId: string,
      priceInWei: bigint,
      eurAmount: number,
      cards: useClickBuyEvent_anyCard[],
      sport: Sport,
      subPath?: string
    ) => {
      const sharedProperties = {
        offerId,
        ethAmount: fromWei(priceInWei),
        eurAmount,
        secondary: true,
        interactionContext: getInteractionContext(subPath),
        sport,
      };

      if (cards.length === 1) {
        const params = {
          ...sharedProperties,
          ...tokenInfoProperties(cards[0]),
        };

        track('Click Buy', params);
      } else {
        const params = {
          ...sharedProperties,
          ...tokensInfoProperties(cards),
        };

        track('Click Bundled Buy', params);
      }
    },
    [track, getInteractionContext]
  );

  return trackClickBuy;
};

useClickBuyEvent.fragments = {
  anyCard: gql`
    fragment useClickBuyEvent_anyCard on AnyCardInterface {
      slug
      sport
      rarityTyped
    }
  ` as TypedDocumentNode<useClickBuyEvent_anyCard>,
};
