import { isToday, isTomorrow } from 'date-fns';
import { FormatDateOptions, FormattedDate, FormattedMessage } from 'react-intl';

type Props = { value: string | number | Date } & FormatDateOptions;

export const RelativeDate = ({ value, ...rest }: Props) => {
  const date = new Date(value);

  if (isToday(date)) {
    return <FormattedMessage id="FormattedDate.today" defaultMessage="Today" />;
  }

  if (isTomorrow(date)) {
    return (
      <FormattedMessage id="FormattedDate.tomorrow" defaultMessage="Tomorrow" />
    );
  }

  return <FormattedDate value={value} {...rest} />;
};
