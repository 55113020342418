import { Position } from '@sorare/core/src/__generated__/globalTypes';
import {
  getPositionInitials,
  getPositionName,
} from '@sorare/core/src/lib/positions';

type Props = {
  positions: Position[];
};

export const PlayerPositions = ({ positions }: Props) => {
  if (positions.length === 1) {
    return <span>{getPositionName(positions[0])}</span>;
  }

  const positionsInitials = positions.map(p => getPositionInitials(p));
  return <span> {positionsInitials.join(', ')}</span>;
};
