import { TypedDocumentNode, gql } from '@apollo/client';

import {
  AmountInput,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import useMutation, { Error } from '@sorare/core/src/hooks/graphql/useMutation';

import { AuthorizationApproval } from '@sorare/wallet-shared/src/contexts/messaging/authorizations';
import { useGetAuthorizationApprovals } from 'hooks/useGetAuthorizationApprovals';

import {
  PrepareOfferMutation,
  PrepareOfferMutationVariables,
} from './__generated__/usePrepareOffer.graphql';

type AuthorizationRequest = NonNullable<
  NonNullable<PrepareOfferMutation['prepareOffer']>['authorizations']
>[number];

const PREPARE_OFFER_MUTATION = gql`
  mutation PrepareOfferMutation($input: prepareOfferInput!) {
    prepareOffer(input: $input) {
      authorizations {
        id
        ...useGetAuthorizationApprovals_authorizationRequest
      }
      errors {
        message
        code
      }
    }
  }
  ${useGetAuthorizationApprovals.fragments.authorizationRequest}
` as TypedDocumentNode<PrepareOfferMutation, PrepareOfferMutationVariables>;

const NO_SIGNATURE = Symbol('No signature returned');

type PrepareOfferArgs = {
  sendAssetIds: string[];
  receiveAssetIds: string[];
  settlementCurrencies?: SupportedCurrency[];
  sendAmount?: AmountInput;
  receiveAmount?: AmountInput;
};

type PrepareOfferOutput = {
  errors?: (Error | symbol)[];
  authorizations: AuthorizationRequest[];
  approvals: AuthorizationApproval[];
};

export default () => {
  const getAuthorizationApprovals = useGetAuthorizationApprovals();

  const [prepareOffer] = useMutation(PREPARE_OFFER_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const { showNotification } = useSnackNotificationContext();

  const errorOutput = {
    useAuthorizations: false,
    legacySignedLimitOrders: [],
    authorizations: [],
    approvals: [],
  };

  return async ({
    sendAssetIds,
    receiveAssetIds,
    sendAmount,
    receiveAmount,
    settlementCurrencies,
  }: PrepareOfferArgs): Promise<PrepareOfferOutput> => {
    const { data, success, errors } = await prepareOffer({
      variables: {
        input: {
          sendAssetIds,
          receiveAssetIds,
          sendAmount,
          receiveAmount,
          settlementCurrencies,
        },
      },
    });
    if (!success)
      return {
        errors,
        ...errorOutput,
      };

    const authorizations = data?.prepareOffer?.authorizations || [];

    const { authorizationApprovals } =
      await getAuthorizationApprovals(authorizations);

    if (!authorizationApprovals) {
      showNotification('unlockWallet');
      return {
        errors: [NO_SIGNATURE],
        ...errorOutput,
      };
    }

    return {
      authorizations,
      approvals: authorizationApprovals,
    };
  };
};
