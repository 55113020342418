import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Box } from '@sorare/core/src/atoms/ui/Box';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import {
  boxBackgroundStyle,
  useTeamBackgroundStyleVariables,
} from 'components/collections/assets/background.style';

import { TeamTableStatsBox_team } from './__generated__/index.graphql';

const Wrapper = styled(Box)`
  padding: 0 !important;
  overflow: hidden;

  --player-image-size: var(--quadruple-unit);
  @media ${tabletAndAbove} {
    --player-image-size: var(--quadruple-unit);
  }
`;

type Props = {
  team: TeamTableStatsBox_team;
  title: ReactNode;
  children: ReactNode;
  className?: string;
};

export const TeamTableStatsBox = ({
  team,
  title,
  children,
  className,
}: Props) => {
  const teamStyle = useTeamBackgroundStyleVariables(team);
  return (
    <Wrapper style={teamStyle} className={className}>
      {title}
      {children}
    </Wrapper>
  );
};

TeamTableStatsBox.Header = styled.div`
  font: var(--t-label-s);
  ${boxBackgroundStyle}
  padding: var(--intermediate-unit) 0;
  border-bottom: 1px solid var(--c-neutral-400);
`;

TeamTableStatsBox.Row = styled.div``;

TeamTableStatsBox.fragments = {
  team: gql`
    fragment TeamTableStatsBox_team on TeamInterface {
      slug
      ...getTeamStyleVariables_team
    }
    ${useTeamBackgroundStyleVariables.fragments.team}
  ` as TypedDocumentNode<TeamTableStatsBox_team>,
};
