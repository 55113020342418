import { TypedDocumentNode, gql } from '@apollo/client';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

import {
  PlayerFieldStatus,
  ScoreStatus,
} from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Bench } from '@sorare/core/src/atoms/icons/Bench';
import { Shirt } from '@sorare/core/src/atoms/icons/shirts/Shirt';
import { isType } from '@sorare/core/src/gql';
import { unitMapping } from '@sorare/core/src/lib/style';

import { SubIcon } from '../SubIcon';
import { PlayerGameScoreStatusIcon_playerGameScoreInterface } from './__generated__/index.graphql';

type Props = {
  playerGameScore: PlayerGameScoreStatusIcon_playerGameScoreInterface;
  size?: keyof typeof unitMapping;
};

export const PlayerGameScoreStatusIcon = ({
  playerGameScore,
  size = 1.5,
}: Props) => {
  const { scoreStatus, anyPlayerGameStats } = playerGameScore;

  const footballPlayerGameStats = isType(anyPlayerGameStats, 'PlayerGameStats')
    ? anyPlayerGameStats
    : null;

  if (footballPlayerGameStats?.gameStarted) {
    return <Shirt size={size} />;
  }
  if (
    footballPlayerGameStats?.fieldStatus === PlayerFieldStatus.ON_FIELD &&
    !footballPlayerGameStats?.gameStarted
  ) {
    return <SubIcon type="sub_in" size={size} />;
  }
  if (footballPlayerGameStats?.fieldStatus === PlayerFieldStatus.ON_BENCH) {
    return <Bench size={size} color="var(--c-neutral-600)" />;
  }
  if (scoreStatus === ScoreStatus.DID_NOT_PLAY) {
    return (
      <FontAwesomeIcon
        icon={faCircleXmark}
        color="var(--c-red-600)"
        width={size * 8}
        height={size * 8}
      />
    );
  }

  return null;
};

PlayerGameScoreStatusIcon.fragments = {
  playerGameScoreInterface: gql`
    fragment PlayerGameScoreStatusIcon_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      scoreStatus
      anyPlayerGameStats {
        id
        ... on PlayerGameStats {
          id
          fieldStatus
          gameStarted
        }
      }
    }
  ` as TypedDocumentNode<PlayerGameScoreStatusIcon_playerGameScoreInterface>,
};
