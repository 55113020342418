import { ChangeEvent, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { CreateWalletRecovery } from '@sorare/wallet-shared';
import { PrivateKeyRecoveryOptionStatusEnum } from '__generated__/globalTypes';
import { Vertical } from 'atoms/layout/flex';
import { Text14, Text16, Title3 } from 'atoms/typography';
import { GraphQLResult, GraphqlForm, TextField } from 'components/form/Form';
import { TwoFADialog } from 'components/form/TwoFADialog';
import { GoogleReCAPTCHA, ReCAPTCHA } from 'components/recaptcha';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useMessagingContext } from 'contexts/wallet';
import { useWalletDrawerContext } from 'contexts/walletDrawer';
import { useAddWalletRecoveryEmail } from 'hooks/recovery/useAddWalletRecoveryEmail';
import {
  RecoveryOption,
  useRecoveryOptions,
} from 'hooks/recovery/useRecoveryOptions';
import { useResendVerificationCodeForRecoveryEmail } from 'hooks/recovery/useResendVerificationCodeForRecoveryEmail';
import { glossary } from 'lib/glossary';

const Wrapper = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--triple-unit);
  form {
    margin-bottom: 0;
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

type Props = {
  email: string | null;
  onSuccess: (newRecoveryEmail: string) => void;
};

const getRecoveryOptionFromEmail = (
  email: string,
  recoveryOptions?: RecoveryOption[]
) => {
  return (
    recoveryOptions?.length &&
    recoveryOptions.find(o => o.destination === email)
  );
};

export const AddRecoveryEmailForm = ({
  onSuccess: doOnSuccess,
  email,
}: Props) => {
  const { formatMessage } = useIntl();
  const recaptchaRef = useRef<GoogleReCAPTCHA>(null);
  const { currentUser } = useCurrentUserContext();
  const { accountEmail, recoveryEmails } = useRecoveryOptions();
  const { addWalletRecoveryEmail } = useAddWalletRecoveryEmail();
  const { resendVerificationCodeForRecoveryEmail } =
    useResendVerificationCodeForRecoveryEmail();
  const { sendRequest } = useMessagingContext();
  const { closeWalletAndDrawer } = useWalletDrawerContext();
  const [newRecoveryEmail, setNewRecoveryEmail] = useState<string | null>(
    email
  );
  const [open2FA, setOpen2FA] = useState<boolean>(false);
  const [twoFACallback, setTwoFACallback] = useState<{
    resolve: any;
    reject: any;
  } | null>(null);

  const handleNewRecoveryEmail = (event: ChangeEvent<Element>) => {
    setNewRecoveryEmail((event.target as HTMLInputElement).value.toLowerCase());
  };

  const submit = async (
    values: any,
    onResult: (res: GraphQLResult) => void
  ) => {
    const recoveryDestination =
      values?.email?.toLowerCase() || newRecoveryEmail;

    const alreadyRegisteredOption = getRecoveryOptionFromEmail(
      recoveryDestination,
      recoveryEmails
    );

    if (
      alreadyRegisteredOption ||
      recoveryDestination === accountEmail?.destination
    ) {
      if (
        alreadyRegisteredOption &&
        alreadyRegisteredOption.status ===
          PrivateKeyRecoveryOptionStatusEnum.PENDING_VALIDATION
      ) {
        const { errors } = await resendVerificationCodeForRecoveryEmail(
          alreadyRegisteredOption.id
        );
        onResult({ errors });
        return;
      }
      onResult({
        error: formatMessage({
          id: 'Settings.addRecoveryEmailForm.alreadyRegisteredEmailError',
          defaultMessage: 'This email is already registered.',
        }),
      });
      return;
    }

    let abort = false;
    const otpAttempt = await new Promise<string | undefined>(
      (resolve, reject) => {
        if (!currentUser?.otpRequiredForLogin) return resolve(undefined);
        setTwoFACallback({ resolve, reject });
        setOpen2FA(true);
        return undefined;
      }
    ).catch(() => {
      abort = true;
      return undefined;
    });
    if (abort) return;

    const { result, error } = await sendRequest<CreateWalletRecovery>(
      'createWalletRecovery',
      {
        recoveryMethod: 'email',
        recoveryDestination,
      }
    );

    if (error) {
      onResult({ error });
      return;
    }

    if (!result) {
      onResult({
        error: formatMessage({
          id: 'updateUserEmailWithPassword.privateKeyGenerationError',
          defaultMessage: 'Unable to generate recovery key.',
        }),
      });
      return;
    }
    closeWalletAndDrawer();
    const { privateKeyRecovery } = result;

    const gqlResults = await addWalletRecoveryEmail({
      privateKeyRecovery,
      otpAttempt,
    });

    const recoveryOptions =
      gqlResults?.data?.addWalletRecovery?.currentUser?.wallet?.recoveryOptions;

    const hasNotRegistered = !getRecoveryOptionFromEmail(
      recoveryDestination,
      recoveryOptions
    );

    onResult({
      ...gqlResults,
      errors: [
        ...gqlResults.errors,
        ...(hasNotRegistered && gqlResults.errors.length === 0
          ? [
              {
                message: formatMessage({
                  id: 'Settings.addWalletRecoveryEmail.notRegistered',
                  defaultMessage: 'Email is not valid',
                }),
              },
            ]
          : []),
      ],
    });
  };

  const onSuccess = () => {
    if (newRecoveryEmail) doOnSuccess(newRecoveryEmail);
  };

  if (!currentUser) return null;

  const { otpRequiredForLogin } = currentUser;

  return (
    <Wrapper>
      <Vertical>
        <Title3 bold>
          <FormattedMessage {...glossary.recoveryEmail} />
        </Title3>
        <Text16>
          <FormattedMessage
            id="Settings.addRecoveryEmailForm.desc"
            defaultMessage="Your recovery email is used to contact you in case you get locked out or to help you recover your Sorare wallet."
          />
        </Text16>
      </Vertical>
      <Text14 color="var(--c-neutral-600)">
        <FormattedMessage
          id="Settings.addRecoveryEmailForm.helper"
          defaultMessage="You can add any email, even if not yours, as long as you are confident that you can easily and consistently access it."
        />
      </Text14>
      <GraphqlForm
        onSubmit={(values, doOnResult) => {
          submit(values, doOnResult);
        }}
        onSuccess={onSuccess}
        render={(Error, SubmitButton) => (
          <Vertical>
            <Error code />
            <StyledTextField
              name="email"
              value={newRecoveryEmail || ''}
              onChange={handleNewRecoveryEmail}
              label={
                <Text14 bold>
                  <FormattedMessage {...glossary.recoveryEmail} />
                </Text14>
              }
            />
            <ReCAPTCHA ref={recaptchaRef} />
            <SubmitButton fullWidth size="large">
              <FormattedMessage {...glossary.next} />
            </SubmitButton>
          </Vertical>
        )}
      />
      {otpRequiredForLogin && (
        <TwoFADialog
          onSubmit={(values, onResult) => {
            twoFACallback?.resolve(values.otpAttempt);
            onResult(values);
          }}
          onSuccess={() => setOpen2FA(false)}
          onClose={() => setOpen2FA(false)}
          onCancel={() => twoFACallback?.reject('no 2FA')}
          open={open2FA}
          reason="recoverEmail"
        />
      )}
    </Wrapper>
  );
};
