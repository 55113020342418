import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '__generated__/globalTypes';
import { withFragments } from 'lib/gql';

import { statsOverlay_AnyCardInterface } from './__generated__/statsOverlay.graphql';
import { footballStatsOverlay2024 } from './football/2024';
import { nbaStatsOverlay2024 } from './nba/2024';

export const statsOverlay = withFragments(
  (card: statsOverlay_AnyCardInterface) => {
    if (card.sport === Sport.FOOTBALL) {
      if (card.seasonYear === 2024) {
        return footballStatsOverlay2024(card);
      }
    }
    if (card.sport === Sport.NBA) {
      if (card.seasonYear === 2024) {
        return nbaStatsOverlay2024(card);
      }
    }
    return undefined;
  },
  {
    AnyCardInterface: gql`
      fragment statsOverlay_AnyCardInterface on AnyCardInterface {
        slug
        sport
        seasonYear
        ...footballStatsOverlay2024_AnyCardInterface
        ...nbaStatsOverlay2024_AnyCardInterface
      }
      ${footballStatsOverlay2024.fragments.AnyCardInterface}
      ${nbaStatsOverlay2024.fragments.AnyCardInterface}
    ` as TypedDocumentNode<statsOverlay_AnyCardInterface>,
  }
);
