import { generatePath } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import { ANY_SPORT_BLOG } from '@sorare/core/src/constants/routes';
import { useCurrentSport } from '@sorare/core/src/hooks/useCurrentSport';

const BlogPage = () => {
  const currentSport = useCurrentSport();

  return (
    <Navigate
      to={generatePath(ANY_SPORT_BLOG, {
        sport: currentSport.toLowerCase(),
      })}
      replace
    />
  );
};

export default BlogPage;
