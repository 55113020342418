import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const Share = ({
  title,
  titleId,
  color,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg height="15" viewBox="0 0 27 33" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.81202 32.4971H21.3004C22.9077 32.4971 24.1598 32.0485 25.0569 31.1515C25.9539 30.2638 26.4025 29.0163 26.4025 27.409V15.0744C26.4025 13.4578 25.9539 12.2056 25.0569 11.3179C24.1598 10.4302 22.9077 9.98634 21.3004 9.98634H18.1186V14.4436H20.6556C21.0761 14.4436 21.3939 14.5464 21.6088 14.752C21.8237 14.9482 21.9312 15.2753 21.9312 15.7332V26.7362C21.9312 27.2035 21.8237 27.5352 21.6088 27.7314C21.3939 27.937 21.0761 28.0398 20.6556 28.0398H6.45679C6.03629 28.0398 5.71858 27.937 5.50365 27.7314C5.28873 27.5352 5.18127 27.2035 5.18127 26.7362V15.7332C5.18127 15.2753 5.28873 14.9482 5.50365 14.752C5.71858 14.5464 6.03629 14.4436 6.45679 14.4436H9.10593V9.98634H5.81202C4.20478 9.98634 2.95262 10.4302 2.05556 11.3179C1.15849 12.2056 0.709961 13.4578 0.709961 15.0744V27.409C0.709961 29.0163 1.15849 30.2638 2.05556 31.1515C2.95262 32.0485 4.20478 32.4971 5.81202 32.4971ZM13.5632 21.1856C14.1052 21.1856 14.5584 20.9987 14.9228 20.625C15.2873 20.2419 15.4695 19.7933 15.4695 19.2794V6.95874L15.3013 5.03846L15.8339 6.13176L17.0954 7.49138C17.4412 7.84646 17.8523 8.02401 18.3289 8.02401C18.74 8.02401 19.1138 7.87917 19.4502 7.58949C19.7866 7.29982 19.9548 6.92137 19.9548 6.45414C19.9548 6.03365 19.7913 5.65987 19.4642 5.33281L15.1331 1.18389C14.8714 0.931586 14.6051 0.754042 14.3341 0.651253C14.0725 0.548465 13.8155 0.49707 13.5632 0.49707C13.2922 0.49707 13.0259 0.548465 12.7643 0.651253C12.5026 0.754042 12.241 0.931586 11.9793 1.18389L7.6482 5.33281C7.32115 5.65987 7.15762 6.03365 7.15762 6.45414C7.15762 6.92137 7.32582 7.29982 7.66222 7.58949C7.99862 7.87917 8.36772 8.02401 8.76953 8.02401C9.25544 8.02401 9.6666 7.84646 10.003 7.49138L11.2785 6.13176L11.8111 5.03846L11.6289 6.95874V19.2794C11.6289 19.7933 11.8111 20.2419 12.1756 20.625C12.5494 20.9987 13.0119 21.1856 13.5632 21.1856Z"
      fill="currentColor"
    />
  </svg>
);
