import { TypedDocumentNode, gql } from '@apollo/client';
import { faArrowLeft, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { GameStatus } from '@sorare/core/src/__generated__/globalTypes';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { FullWidthContainer } from '@sorare/core/src/atoms/layout/FullWidthContainer';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, HeadlineXL, LabelM } from '@sorare/core/src/atoms/typography';
import { TeamAvatar } from '@sorare/core/src/components/club/TeamAvatar';
import { CloseButton } from '@sorare/core/src/components/dialog/CloseButton';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { useSafePreviousNavigate } from '@sorare/core/src/hooks/useSafePreviousNavigate';
import { fantasy, glossary } from '@sorare/core/src/lib/glossary';
import { getSafePreviousLocation } from '@sorare/core/src/lib/navigation/getSafePreviousLocation';
import { desktopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { GameTime } from 'components/game/GameView/GameTime';

import { GameHeader_anyGame } from './__generated__/index.graphql';

const ScoreboardWrapper = styled(FullWidthContainer.NoPadding)`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: var(--double-unit);
`;

const TeamWrapper = styled(Horizontal).attrs({ gap: 2 })`
  &.leftTeam {
    justify-content: flex-end;
  }
`;

const TeamName = styled.p`
  display: none;
  @media (${desktopAndAbove}) {
    display: block;
  }
`;

const Score = styled(HeadlineXL)`
  text-align: center;
  width: var(--team-score-width);
`;

const Navigation = styled(FullWidthContainer.NoPadding)`
  display: grid;
  align-items: center;
  grid-template-columns: 36px 1fr 36px;
  padding: var(--double-unit);
`;

const HiddenNavigationOnDesktop = styled(Navigation)`
  @media ${desktopAndAbove} {
    display: none;
  }
`;

const HorizontalCentered = styled(LabelM)`
  display: grid;
  gap: var(--double-unit);
  grid-template-columns: 1fr max-content 1fr;
  & > .left {
    margin-left: auto;
  }
`;

const NavigationGrid = styled.div`
  display: grid;
  padding: var(--double-unit);
  grid-template-columns: 100px 1fr 100px;
  border-bottom: 1px solid var(--c-neutral-400);
`;

const BackPlaceholder = styled(Horizontal).attrs({ gap: 1 })``;

const ClosePlaceholder = styled(Horizontal).attrs({ gap: 1 })`
  justify-content: flex-end;
`;

type Props = {
  game: GameHeader_anyGame;
  backRoute?: string;
  noBorder?: boolean;
  inDialog?: boolean;
};

export const GameHeader = ({ game, backRoute, noBorder, inDialog }: Props) => {
  const goBack = useSafePreviousNavigate(backRoute!);
  const { up: isLaptop } = useScreenSize('laptop');
  if (!game) {
    return null;
  }

  return (
    <>
      {!inDialog && backRoute && (
        <HiddenNavigationOnDesktop>
          <IconButton
            icon={faArrowLeft}
            color="tertiary"
            to={getSafePreviousLocation(backRoute)}
            small
          />
          <HorizontalCentered as="div">
            <strong className="left">{game.awayTeam?.name}</strong>
            <span>
              <FormattedMessage {...fantasy.vs} />
            </span>
            <strong>{game.homeTeam?.name}</strong>
          </HorizontalCentered>
        </HiddenNavigationOnDesktop>
      )}
      <NavigationGrid>
        <BackPlaceholder>
          {inDialog && goBack && backRoute && isLaptop && (
            <>
              <IconButton
                icon={faChevronLeft}
                color="tertiary"
                small
                onClick={goBack}
              />
              <BodyS color="var(--c-neutral-1000)">
                <FormattedMessage {...glossary.back} />
              </BodyS>
            </>
          )}
        </BackPlaceholder>
        <ScoreboardWrapper className={noBorder ? '' : 'border'}>
          <TeamWrapper className="leftTeam">
            <TeamName>{game.awayTeam?.name}</TeamName>
            <TeamAvatar team={game.awayTeam!} />
            <Score as="div">
              {[GameStatus.played, GameStatus.playing].includes(
                game.statusTyped
              ) && game.awayScore}
            </Score>
          </TeamWrapper>
          <GameTime game={game} />

          <TeamWrapper>
            <Score as="div">
              {[GameStatus.played, GameStatus.playing].includes(
                game.statusTyped
              ) && game.homeScore}
            </Score>
            <TeamAvatar team={game.homeTeam} />
            <TeamName>{game.homeTeam?.name}</TeamName>
          </TeamWrapper>
        </ScoreboardWrapper>
        <ClosePlaceholder>
          {inDialog && goBack && backRoute && !isLaptop && (
            <CloseButton onClose={goBack} />
          )}
        </ClosePlaceholder>
      </NavigationGrid>
    </>
  );
};

GameHeader.fragments = {
  anyGame: gql`
    fragment GameHeader_anyGame on AnyGameInterface {
      id
      awayScore
      awayTeam {
        slug
        name
        ...TeamAvatar_team
      }
      homeScore
      homeTeam {
        slug
        name
        ...TeamAvatar_team
      }
      ...GameTime_anyGame
    }
    ${GameTime.fragments.anyGame}
    ${TeamAvatar.fragments.team}
  ` as TypedDocumentNode<GameHeader_anyGame>,
};
