import { ReactNode, useMemo, useState } from 'react';

import BuyingConfirmationProvider from 'contexts/buyingConfirmation/Provider';

import MarketplaceContextProvider, { MarketplaceContextType } from '.';

interface Props
  extends Omit<MarketplaceContextType, 'hideDetails' | 'setHideDetails'> {
  children: ReactNode;
}

const MarketplaceProvider = ({ children }: Props) => {
  const [hideDetails, setHideDetails] = useState(false);

  const value = useMemo(
    () => ({
      hideDetails,
      setHideDetails,
    }),
    [hideDetails]
  );

  return (
    <MarketplaceContextProvider value={value}>
      <BuyingConfirmationProvider>{children}</BuyingConfirmationProvider>
    </MarketplaceContextProvider>
  );
};

export default MarketplaceProvider;
