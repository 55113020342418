import { TypedDocumentNode, gql } from '@apollo/client';

import {
  useGetSuspensionsAndInjuries_anyPlayer,
  useGetSuspensionsAndInjuries_playerGameScoreInterface,
} from './__generated__/useGetSuspensionsAndInjuries.graphql';

const useGetSuspensionsAndInjuries = (
  player?: useGetSuspensionsAndInjuries_anyPlayer
) => {
  const useGetSuspensionsAndInjuriesCallback = <
    T extends {
      suspensions: useGetSuspensionsAndInjuries_anyPlayer['suspensions'];
      injuries: useGetSuspensionsAndInjuries_anyPlayer['injuries'];
    },
  >(
    game: useGetSuspensionsAndInjuries_playerGameScoreInterface['anyGame']
  ): {
    suspensions: T['suspensions'];
    injuries: T['injuries'];
  } => {
    const suspensions = (player?.suspensions || []).filter(suspension => {
      return (
        suspension.competition.slug === game.competition.slug &&
        suspension.startDate < game.date &&
        (!suspension.endDate || suspension.endDate > game.date)
      );
    });
    const injuries = (player?.injuries || []).filter(injury => {
      return (
        injury.startDate &&
        injury.startDate < game.date &&
        (!injury.expectedEndDate || injury.expectedEndDate > game.date)
      );
    });
    return { suspensions, injuries };
  };

  return useGetSuspensionsAndInjuriesCallback;
};

useGetSuspensionsAndInjuries.fragments = {
  playerGameScoreInterface: gql`
    fragment useGetSuspensionsAndInjuries_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      anyGame {
        id
        date
        competition {
          slug
        }
      }
    }
  ` as TypedDocumentNode<useGetSuspensionsAndInjuries_playerGameScoreInterface>,
  anyPlayer: gql`
    fragment useGetSuspensionsAndInjuries_anyPlayer on AnyPlayerInterface {
      slug
      injuries {
        id
        startDate
        expectedEndDate
      }
      suspensions {
        id
        competition {
          slug
          id
        }
        startDate
        endDate
      }
    }
  ` as TypedDocumentNode<useGetSuspensionsAndInjuries_anyPlayer>,
};

export default useGetSuspensionsAndInjuries;
