import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import playerPlaceholder from '@sorare/core/src/assets/players/placeholder.png';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { hoverStyle } from '@sorare/core/src/atoms/ui/Box';
import { ResponsiveImg } from '@sorare/core/src/atoms/ui/ResponsiveImg';
import { getShortPlayerName } from '@sorare/core/src/lib/players';

import { SquaredFeaturedPlayer_anyPlayerInterface } from './__generated__/index.graphql';

const Wrapper = styled(Vertical)`
  cursor: pointer;
  gap: var(--unit);
  flex: 1;

  font-size: max(10px, var(--image-unit, 12px));
  --player-image-size: 6em;
  width: calc(6em + var(--double-unit));
`;

const PlayerImage = styled.div`
  width: var(--player-image-size);
  height: var(--player-image-size);
  border: 3px solid var(--c-static-neutral-800);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .highlighted & {
    ${hoverStyle}
  }
`;

const PlayerImg = styled(ResponsiveImg)`
  height: 100%;
`;

const PlayerName = styled.strong`
  font-size: max(1em, var(--intermediate-unit));
  white-space: nowrap;
`;

type Props = {
  player: SquaredFeaturedPlayer_anyPlayerInterface;
  score: ReactNode;
  onClick?: () => void;
  onMouseHover?: () => void;
  onMouseLeave?: () => void;
  highlighted?: boolean;
  style?: CSSProperties;
};

export const SquaredFeaturedPlayer = ({
  player,
  score,
  onClick,
  onMouseHover,
  onMouseLeave,
  highlighted = false,
  style,
}: Props) => {
  return (
    <Wrapper
      center
      onClick={onClick}
      onMouseEnter={onMouseHover}
      onMouseLeave={onMouseLeave}
      className={classNames({ highlighted })}
      style={style}
    >
      <PlayerImage>
        <PlayerImg
          src={player.avatarPictureUrl || playerPlaceholder}
          cropWidth={160}
          alt=""
        />
      </PlayerImage>
      <PlayerName>{getShortPlayerName(player.displayName)}</PlayerName>

      {score}
    </Wrapper>
  );
};

SquaredFeaturedPlayer.fragments = {
  anyPlayerInterface: gql`
    fragment SquaredFeaturedPlayer_anyPlayerInterface on AnyPlayerInterface {
      slug
      displayName
      avatarPictureUrl
    }
  ` as TypedDocumentNode<SquaredFeaturedPlayer_anyPlayerInterface>,
};
