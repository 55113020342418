export { default as mapNodes } from './mapNodes';

export interface GqlType {
  __typename: string;
}

export type GraphQLResult = { __typename: string };
export type ValueOfTypename<T extends GraphQLResult> = T['__typename'];

export const isType = <
  Result extends GraphQLResult,
  Typename extends ValueOfTypename<Result>,
>(
  result: Result | null | undefined,
  typename: Typename
): result is Extract<Result, { __typename: Typename }> => {
  return result?.__typename === typename;
};

export const isTypeFn = <
  Result extends GraphQLResult,
  Typename extends ValueOfTypename<Result>,
>(
  typename: Typename
) => {
  const predicate = (
    result: Result | null | undefined
  ): result is Extract<Result, { __typename: Typename }> =>
    result?.__typename === typename;
  return predicate;
};

/* #__NO_SIDE_EFFECTS__ */
export const withFragments = <T extends NonNullable<unknown>, U>(
  func: T,
  fragments: U
): T & { fragments: U } => {
  return Object.assign(func, { fragments });
};

interface OwnerType {
  account: {
    owner: {
      __typename: string;
    } | null;
  } | null;
}

export const isUserOwner = <T extends { __typename: string }>(
  owner: OwnerType | null | undefined
): owner is { account: { owner: T } } => {
  return owner?.account?.owner?.__typename === 'User';
};

export const replaceByIncoming = (_existing: any[], incoming: any[]) =>
  incoming;

export const mergeArrayOfUnnormalizedObjects = (
  existing: any[],
  incoming: any[],
  { mergeObjects }: { mergeObjects: (a: any, b: any) => any }
) => {
  if (!existing || existing.length !== incoming.length) {
    return incoming;
  }
  return incoming.map((item, index) => mergeObjects(existing[index], item));
};

export interface GraphQLError {
  path?: string[] | null;
  message: string;
  code?: number | null;
}

export const formatGqlErrors = (errors: GraphQLError[]) => {
  return errors.map(e => e.message);
};

export const randomizedPollInterval = (target_interval_minutes: number) => {
  return Math.floor(
    target_interval_minutes * 60 * 1000 * (0.95 + Math.random() * 0.1) // +/- 5%
  );
};
