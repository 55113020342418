import { SVGProps } from 'react';

import { unitMapping } from 'lib/style';

interface SVGRProps {
  size?: keyof typeof unitMapping;
  title?: string;
  titleId?: string;
}

export const Cards = ({
  size = 2,
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    style={{ width: unitMapping[size], height: unitMapping[size] }}
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4601 1.19664C10.2798 0.534236 9.59904 0.141211 8.93523 0.316291L1.21825 2.35167C0.546989 2.52871 0.148584 3.21881 0.330922 3.88865L3.74752 16.4399C3.92783 17.1023 4.60861 17.4953 5.27242 17.3202L5.83458 17.1719L5.79236 17.327L14.7276 19.6837C15.3914 19.8588 16.0722 19.4658 16.2525 18.8034L19.6691 6.25217C19.8514 5.58233 19.453 4.89223 18.7818 4.71518L10.8483 2.62272L10.4601 1.19664ZM9.31985 16.2527L12.9894 15.2848C13.6607 15.1078 14.0591 14.4177 13.8767 13.7478L11.4363 4.78252L17.5907 6.40576L14.5357 17.6284L9.31985 16.2527Z"
      fill="currentColor"
    />
  </svg>
);
