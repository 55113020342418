import { useCallback } from 'react';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { useEventsContext } from '@sorare/core/src/contexts/events';
import { useGetProtoSport } from '@sorare/core/src/hooks/events/useGetProtoSport';
import { useGetInteractionContext } from '@sorare/core/src/hooks/useGetInteractionContext';
import { sportToJSON } from '@sorare/core/src/protos/events/shared/events';

export type TrackEvent =
  | 'Change ComposeTeam Filter'
  | 'Click Compose Lineup'
  | 'Click Edit Lineup'
  | 'Click Navbar Item'
  | 'Click Player Cards Available See All'
  | 'Click Swap'
  | 'Enter Tournament in Onboarding'
  | 'Open ComposeTeam Player Details Panel'
  | 'Pick First Card'
  | 'Redirect To Marketplace'
  | 'Save Lineup'
  | 'Select Lineup Over Cap'
  | 'View My Favorites Cards'
  | 'View My Favorites Players'
  | 'Expand Competition Details'
  | 'View Competition Details'
  | 'Viewed Compose Team Requirements';

type useEventsProps = {
  sport?: Sport;
  context?: string;
};

function getContextPropName(event: TrackEvent) {
  switch (event) {
    case 'Redirect To Marketplace':
      return 'source';
    case 'Click Compose Lineup':
    case 'Click Edit Lineup':
    case 'Click Swap':
      // Note: Using 'context' doesn’t conform to most events but it’s how So5
      // set it up, so using it for consistency
      return 'context';
    default:
      return undefined;
  }
}

export const useEvents = (initialProperties: useEventsProps = {}) => {
  const { track } = useEventsContext();
  const getSport = useGetProtoSport();
  const getInteractionContext = useGetInteractionContext();
  const sport = initialProperties.sport ?? sportToJSON(getSport());
  const context = initialProperties.context ?? getInteractionContext();

  return useCallback(
    (event: TrackEvent, properties: Record<string, any> = {}) => {
      const baseProperties: { [property: string]: any } = { sport };
      const contextProp = getContextPropName(event);
      if (contextProp) baseProperties[contextProp] = context;

      track(event, { ...baseProperties, ...properties });
    },
    [track, sport, context]
  );
};
