import { MessageDescriptor, defineMessages } from 'react-intl';
import styled from 'styled-components';

export const TabNum = styled.span`
  font-variant-numeric: tabular-nums;
`;

export type TimeElements = {
  months: number;
  days: number;
  hours: number;
  minutes: string;
  seconds: string;
};

type TimeMessages = {
  monthsLeft: MessageDescriptor;
  daysLeft: MessageDescriptor;
  hoursLeft: MessageDescriptor;
  minutesLeft: MessageDescriptor;
  secondsLeft: MessageDescriptor;
};

export type TimeOptions = {
  /**
   * Instead of returning number of months, aggregate the total number of days.
   */
  totalDays?: boolean;
  customMessages?: typeof defaultTimeMessages;
};

export const defaultTimeMessages = defineMessages({
  monthsLeft: {
    id: 'TimeLeft.monthsLeft',
    defaultMessage: '{months}m {days}d',
  },
  daysLeft: {
    id: 'TimeLeft.daysLeft',
    defaultMessage: '{days}d {hours}h',
  },
  hoursLeft: {
    id: 'TimeLeft.hoursLeft',
    defaultMessage: '{hours}h {minutes}m',
  },
  minutesLeft: {
    id: 'TimeLeft.minutesLeft',
    defaultMessage: '{minutes}m {seconds}s',
  },
  secondsLeft: {
    id: 'TimeLeft.secondsLeft',
    defaultMessage: '{minutes}m {seconds}s',
  },
});

const pad = (duration: number) => duration.toString().padStart(2, '0');

export interface SplitOptions {
  withPadding?: boolean;
}

export const split = (
  duration: Duration,
  splitOptions?: SplitOptions
): TimeElements => {
  return {
    months: duration.months || 0,
    days: duration.days || 0,
    hours: duration.hours || 0,
    minutes:
      splitOptions?.withPadding === false
        ? String(duration.minutes || 0)
        : pad(duration.minutes || 0),
    seconds:
      splitOptions?.withPadding === false
        ? String(duration.seconds || 0)
        : pad(duration.seconds || 0),
  };
};

export const formatDurationMessage = (
  duration: Duration,
  elements: TimeElements,
  messages: TimeMessages,
  totalDays?: boolean
) => {
  let message;
  if (elements.months > 0 && !totalDays) {
    message = messages.monthsLeft;
  } else if (elements.days > 0) {
    message = messages.daysLeft;
  } else if (elements.hours > 0) {
    message = messages.hoursLeft;
  } else if ((duration.minutes || 0) > 0) {
    message = messages.minutesLeft;
  } else {
    message = messages.secondsLeft;
  }
  return message;
};
