import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Block } from '@sorare/core/src/atoms/layout/Block';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Text14 } from '@sorare/core/src/atoms/typography';
import { TeamAvatar } from '@sorare/core/src/components/club/TeamAvatar';
import { FlagAvatar } from '@sorare/core/src/components/country/FlagAvatar';
import { PlayerHandsInfo } from '@sorare/core/src/components/player/PlayerHandsInfo';
import {
  ANY_US_SPORTS_TEAM_SHOW,
  FOOTBALL_CLUB_SHOW,
  FOOTBALL_COUNTRY_SHOW,
  LEGACY_COUNTRY_SHOW,
} from '@sorare/core/src/constants/routes';
import { isType } from '@sorare/core/src/gql';
import { battingThrowingHandMessage } from '@sorare/core/src/lib/baseball';
import { cardAttributes } from '@sorare/core/src/lib/glossary';
import {
  attributes as playerAttributes,
  useFormatAnyPositions,
} from '@sorare/core/src/lib/players';

import { CardAttributes_anyCard } from './__generated__/index.graphql';

interface Props<T> {
  card: T;
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled(Text14)`
  color: var(--c-neutral-600);
`;

const TeamRow = styled(Horizontal)`
  color: var(--c-neutral-1000);
`;

const Country = ({ card }: { card: CardAttributes_anyCard }) => {
  const { anyPlayer } = card;
  const { country } = anyPlayer;
  const link = isType(card, 'Card')
    ? FOOTBALL_COUNTRY_SHOW
    : LEGACY_COUNTRY_SHOW;
  return (
    <TeamRow
      as={Link}
      to={generatePath(link, { slug: country.code, countryCode: country.code })}
    >
      <FlagAvatar country={country} imageRes={64} size={16} />
      <LabelM>{country.name}</LabelM>
    </TeamRow>
  );
};

const Team = ({ card }: { card: CardAttributes_anyCard }) => {
  const { anyTeam, sport } = card;
  const isClub = isType(anyTeam, 'Club');

  return (
    <TeamRow
      as={isClub ? Link : 'div'}
      to={
        isClub
          ? generatePath(
              sport === Sport.FOOTBALL
                ? FOOTBALL_CLUB_SHOW
                : ANY_US_SPORTS_TEAM_SHOW,
              { slug: anyTeam.slug }
            )
          : undefined
      }
    >
      <TeamAvatar team={anyTeam} size={16} />
      <LabelM as="span">{anyTeam.name}</LabelM>
    </TeamRow>
  );
};

export const CardAttributes = <T extends CardAttributes_anyCard>({
  card,
}: Props<T>) => {
  const { xp, anyPositions } = card;
  const formatAnyPositions = useFormatAnyPositions();

  return (
    <Block>
      <Vertical>
        <Row>
          <Title>
            <FormattedMessage {...cardAttributes.team} />
          </Title>
          <Team card={card} />
        </Row>
        {isType(card, 'BaseballCard') && (
          <Row>
            <Title>
              <FormattedMessage {...battingThrowingHandMessage.header} />
            </Title>
            <PlayerHandsInfo as={Text14} player={card.anyPlayer} />
          </Row>
        )}
        <Row>
          <Title>
            <FormattedMessage {...cardAttributes.position} />
          </Title>
          <Text14>{formatAnyPositions(anyPositions)}</Text14>
        </Row>
        {isType(card, 'Card') && (
          <Row>
            <Title>
              <FormattedMessage {...cardAttributes.age} />
            </Title>
            <Text14>{card.age}</Text14>
          </Row>
        )}
        <Row>
          <Title>
            <FormattedMessage {...playerAttributes.country} />
          </Title>
          <Country card={card} />
        </Row>
        <Row>
          <Title>
            <FormattedMessage {...cardAttributes.xpTitle} />
          </Title>
          <Text14>
            <FormattedMessage {...cardAttributes.xpValue} values={{ xp }} />
          </Text14>
        </Row>
      </Vertical>
    </Block>
  );
};

CardAttributes.fragments = {
  card: gql`
    fragment CardAttributes_anyCard on AnyCardInterface {
      slug
      sport
      anyPositions
      anyPlayer {
        slug
        country {
          slug
          name
          code
        }
        ...PlayerHandsInfo_AnyPlayerInterface
      }
      anyTeam {
        slug
        name
        ...TeamAvatar_team
      }
      xp
      ... on Card {
        slug
        age
      }
      ... on BaseballCard {
        slug
      }
      ... on NBACard {
        slug
      }
      ... on CardSubscription {
        slug
      }
    }
    ${PlayerHandsInfo.fragments.AnyPlayerInterface}
    ${TeamAvatar.fragments.team}
  ` as TypedDocumentNode<CardAttributes_anyCard>,
};

export default CardAttributes;
