import { MessageDescriptor, useIntl } from 'react-intl';
import styled from 'styled-components';

import { useTimeLeft } from 'hooks/useTimeLeft';

const Wrapper = styled.div`
  display: inline;
`;

const NoBreak = styled.div`
  display: inline;
  white-space: nowrap;
`;

type Props = {
  timeLeftMessage: MessageDescriptor;
  next: Date;
  className?: string;
  variables?: Record<string, any>;
};
export const TimeUntilNext = ({
  timeLeftMessage,
  next,
  className,
  variables = {},
}: Props) => {
  const { formatMessage } = useIntl();

  const { message } = useTimeLeft(next);

  return (
    <Wrapper className={className}>
      {formatMessage(timeLeftMessage, {
        ...variables,
        timeLeft: <NoBreak>{message}</NoBreak>,
      })}
    </Wrapper>
  );
};
