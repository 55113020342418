import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const Plus = ({
  fill = '#777777',
  title,
  titleId,
  ...rest
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...rest}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M7.5 0.75H4.5V4.5L0.75 4.5V7.5H4.5V11.25H7.5V7.5H11.25V4.5L7.5 4.5V0.75Z"
        fill={fill}
      />
    </svg>
  );
};
