import { TypedDocumentNode, gql } from '@apollo/client';
import classnames from 'classnames';
import { forwardRef } from 'react';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { FRONTEND_ASSET_HOST } from '@sorare/core/src/constants/assets';
import { isType } from '@sorare/core/src/gql';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';

import Player from 'components/MatchView/Player';

import { Formation_game } from './__generated__/index.graphql';

const Root = styled(Vertical)`
  position: relative;
  width: 100%;
  justify-content: space-around;
  scroll-snap-align: center;
  &.reverse {
    flex-direction: column-reverse;
  }
  &.desktop {
    flex-direction: row;
    &.reverse {
      flex-direction: row-reverse;
    }
  }
`;
const PlayerLine = styled(Horizontal).attrs({ gap: 0.5 })`
  align-items: flex-start;
  justify-content: space-around;
  &.desktop {
    flex-direction: column-reverse;
    align-items: center;
    &.reverse {
      flex-direction: column;
    }
  }
`;
const PlayerWrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  flex: 1;
`;
const TeamLogo = styled.img`
  width: var(--quadruple-unit);
  position: absolute;
  top: var(--double-unit);
  left: var(--double-unit);

  &.reverse {
    top: auto;
    bottom: var(--double-unit);
  }

  &.desktop.reverse {
    left: auto;
    bottom: auto;
    right: var(--double-unit);
  }
`;

type FormationProps = {
  desktop?: boolean;
  game: Formation_game;
  isHomeFormation?: boolean;
  onPlayerDetailsClick: (playerSlug: string, scoreId?: string) => void;
  reverse?: boolean;
};
const Formation = forwardRef<HTMLDivElement, FormationProps>(function Formation(
  { desktop, game, onPlayerDetailsClick, isHomeFormation, reverse },
  ref
) {
  const { homeFormation, awayFormation, awayTeam, homeTeam } = game;

  const formation = isHomeFormation ? homeFormation : awayFormation;
  const team = isHomeFormation ? homeTeam : awayTeam;
  const pictureUrl = isType(team, 'NationalTeam')
    ? `${FRONTEND_ASSET_HOST}/flags/${team.country.slug}.svg`
    : team?.pictureUrl;

  return (
    <Root
      className={classnames({
        desktop,
        reverse,
      })}
      ref={ref}
    >
      {pictureUrl && (
        <TeamLogo
          className={classnames({ desktop, reverse })}
          src={pictureUrl}
          alt=""
        />
      )}
      {formation.startingLineup.map((formationRow, i) => (
        <PlayerLine
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={classnames({
            desktop,
            reverse,
          })}
        >
          {formationRow.map(
            player =>
              player.playerGameScore && (
                <PlayerWrapper key={player.slug}>
                  <button
                    type="button"
                    onClick={() =>
                      onPlayerDetailsClick(
                        player.slug,
                        idFromObject(player.playerGameScore?.id)
                      )
                    }
                  >
                    <Player key={player.slug} player={player} />
                  </button>
                </PlayerWrapper>
              )
          )}
        </PlayerLine>
      ))}
    </Root>
  );
});

Formation.fragments = {
  game: gql`
    fragment Formation_game on Game {
      id
      minute
      awayTeam {
        slug
        ... on TeamInterface {
          slug
          pictureUrl(derivative: "high_res")
          country {
            slug
          }
        }
      }
      homeTeam {
        slug
        ... on TeamInterface {
          slug
          pictureUrl(derivative: "high_res")
          country {
            slug
          }
        }
      }
      homeFormation {
        startingLineup {
          id
          slug
          ...Player_anyPlayer
        }
      }
      awayFormation {
        startingLineup {
          id
          slug
          ...Player_anyPlayer
        }
      }
    }
    ${Player.fragments.anyPlayer}
  ` as TypedDocumentNode<Formation_game>,
};

export default Formation;
