import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Sport, SupportedCurrency } from '__generated__/globalTypes';
import { Button } from 'atoms/buttons/Button';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyS, HeadlineXL } from 'atoms/typography';
import Dialog from 'components/dialog';
import { DialogKey } from 'components/navigation/WithDialogs';
import { ConversionCredit } from 'components/wallet/BankEthAccounting/ConversionCredits/ConversionCredit';
import { INSTANT_BUY_URL } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import { useWalletDrawerContext } from 'contexts/walletDrawer';
import { idFromObject } from 'gql/idFromObject';
import { useCloseDialog } from 'hooks/navigation/useCloseDialog';
import { useDialogParam } from 'hooks/navigation/useDialogParam';
import { useAmountWithConversion } from 'hooks/useAmountWithConversion';
import { useConversionCredits } from 'hooks/useConversionCredits';
import { zeroMonetaryAmount } from 'hooks/useMonetaryAmount';
import { glossary, sportsLabelsMessages } from 'lib/glossary';

const Root = styled(Vertical).attrs({ gap: 2, center: true })`
  padding: 0 var(--triple-unit) var(--triple-unit);
  text-align: center;
  justify-content: stretch;
`;

const messages = defineMessages({
  addedToWallet: {
    id: 'CreditClaimedDialog.addedToWallet',
    defaultMessage: 'This credit has been added to your Wallet.',
  },
  description: {
    id: 'CreditClaimedDialog.description',
    defaultMessage:
      'You’ll get a {percentage} discount up to {amount} on all purchases.',
  },
  descriptionWithSport: {
    id: 'CreditClaimedDialog.descriptionWithSport',
    defaultMessage:
      'You’ll get a {percentage} discount up to {amount} on all purchases in the {sport} Market.',
  },
});

const Footer = styled(Horizontal)`
  width: 100%;
`;

const StyledConversionCredit = styled(ConversionCredit)`
  border: 1px solid var(--c-neutral-400);
  border-radius: var(--unit);
  padding: var(--double-unit);
`;

export const CreditClaimedDialog = () => {
  const {
    fiatCurrency: { code: currencyCode },
  } = useCurrentUserContext();
  const { formatNumber, formatMessage } = useIntl();
  const { sport: sportContext } = useSportContext();
  const { toggleDrawer } = useWalletDrawerContext();
  const { conversionCredits } = useConversionCredits({});
  const closeDialog = useCloseDialog();
  const id = useDialogParam(DialogKey.claimedConversionCredit);

  const claimedConversionCredit = conversionCredits?.find(
    cc => idFromObject(cc.id) === id
  );

  const { main } = useAmountWithConversion({
    monetaryAmount: claimedConversionCredit?.maxDiscount || zeroMonetaryAmount,
  });

  if (!claimedConversionCredit) return null;

  const percentage = formatNumber(claimedConversionCredit.percentageDiscount, {
    style: 'percent',
    maximumFractionDigits: 0,
  });

  return (
    <Dialog
      open
      onClose={() => {
        closeDialog(DialogKey.claimedConversionCredit);
      }}
      maxWidth="xs"
      fullWidth
    >
      <Root>
        <HeadlineXL as="h2">
          <FormattedMessage
            id="CreditClaimedDialog.congrats"
            defaultMessage="Congratulations!{br}You won a {percentage} discount."
            values={{ percentage, br: <br /> }}
          />
        </HeadlineXL>
        <BodyS color="var(--c-neutral-600)" as="p">
          <FormattedMessage
            {...(claimedConversionCredit.sport
              ? messages.descriptionWithSport
              : messages.description)}
            values={{
              percentage,
              amount: main,
              sport: claimedConversionCredit.sport
                ? formatMessage(
                    sportsLabelsMessages[claimedConversionCredit.sport]
                  )
                : '',
            }}
          />
        </BodyS>
        <StyledConversionCredit
          referenceCurrency={currencyCode as SupportedCurrency}
          conversionCredit={claimedConversionCredit}
        />
        <Footer grow>
          <Button
            onClick={() => {
              toggleDrawer();
              closeDialog(DialogKey.claimedConversionCredit);
            }}
            fullWidth
            color="tertiary"
            size="small"
          >
            <FormattedMessage
              id="CreditClaimedDialog.seeWallet"
              defaultMessage="See wallet"
            />
          </Button>
          <Button
            to={
              INSTANT_BUY_URL[
                claimedConversionCredit.sport || sportContext || Sport.FOOTBALL
              ]
            }
            fullWidth
            size="small"
          >
            <FormattedMessage {...glossary.goToMarket} />
          </Button>
        </Footer>
      </Root>
    </Dialog>
  );
};

export default CreditClaimedDialog;
