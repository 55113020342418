import { isFuture } from 'date-fns';

export const livePrimaryOffer = (
  card:
    | {
        latestPrimaryOffer: { endDate: Date } | null;
        tokenOwner: { __typename: 'TokenOwner' } | null;
      }
    | undefined
    | null
) =>
  !!(
    card?.latestPrimaryOffer &&
    isFuture(card.latestPrimaryOffer.endDate) &&
    !card.tokenOwner
  );
