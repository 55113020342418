import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useConnectionContext } from '@sorare/core/src/contexts/connection';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

const MobilePasswordForgotten = () => {
  const { showMobilePasswordForgotten } = useConnectionContext();
  const { currentUser } = useCurrentUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    showMobilePasswordForgotten();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [currentUser, navigate, showMobilePasswordForgotten]);

  return null;
};

export default MobilePasswordForgotten;
