import { SVGProps } from 'react';

import { unitMapping } from 'lib/style';

interface SVGRProps {
  title?: string;
  titleId?: string;
  size?: keyof typeof unitMapping;
}

export const Star = ({
  size = 2,
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    style={{ width: unitMapping[size], height: unitMapping[size] }}
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.00001 0H9.00001L10.4871 4.57681L15.2994 4.57681L15.9175 6.47892L12.0242 9.30754L13.5113 13.8843L11.8933 15.0599L8.00002 12.2313L4.10677 15.0599L2.48873 13.8843L3.97581 9.30756L0.0825195 6.47893L0.700554 4.57682L5.51292 4.57681L7.00001 0Z"
      fill="currentColor"
    />
  </svg>
);
