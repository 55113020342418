import {
  HELP,
  HELP_A_ARTICLEID_SLUG,
  HELP_C_CATEGORYID_SLUG,
  HELP_S_SECTIONID_SLUG,
} from '@sorare/core/src/constants/__generated__/routes';

export const HELP_ROOT = HELP;
export const HELP_CATEGORY = HELP_C_CATEGORYID_SLUG;
export const HELP_SECTION = HELP_S_SECTIONID_SLUG;
export const HELP_ARTICLE = HELP_A_ARTICLEID_SLUG;

export const LINKS_RX =
  /https:\/\/help.sorare.com\/hc(?:\/.+?)?\/articles\/([0-9]+)/g;
export const NEW_REQUEST_RX =
  /https:\/\/help.sorare.com\/hc(?:\/.+?)?\/requests\/new/g;
export const NEW_REQUEST_HASH = '#new-request';
