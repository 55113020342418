import { useApolloClient } from '@apollo/client';
import { ReactElement, useCallback } from 'react';

import { useCurrentUserContext } from 'contexts/currentUser';
import useAfterLoggedInTarget from 'hooks/useAfterLoggedInTarget';
import { client as httpClient, onError } from 'lib/http';
import { paramsToSnakeCase } from 'lib/toSnakeCase';

import AuthContextProvider, { UpdateUserAttributes } from '.';

interface Props {
  children: ReactElement;
}

export const AuthProvider = ({ children }: Props) => {
  const { refetch } = useCurrentUserContext();
  const client = useApolloClient();
  const fromPath = useAfterLoggedInTarget();

  const refreshCurrentUser = async () => client.resetStore();

  const updateUser = useCallback(
    async (attributes: UpdateUserAttributes) => {
      const { passwordHash, currentPasswordHash, ...rest } = attributes;
      const params: UpdateUserAttributes & {
        currentPassword?: string;
        password?: string;
      } = rest;
      if (passwordHash) {
        params.password = passwordHash;
        params.currentPassword = passwordHash;
      }
      if (currentPasswordHash) params.currentPassword = currentPasswordHash;

      try {
        const result = await httpClient.put('/users.json', {
          user: paramsToSnakeCase(params as any),
        });
        refetch();
        return result;
      } catch (error) {
        return onError(error);
      }
    },
    [refetch]
  );

  const createResetPasswordRequest = useCallback(
    async (email: string, recaptchaToken: string) => {
      try {
        const result = await httpClient.post('/users/password.json', {
          user: {
            email,
            from_path: fromPath,
            recaptcha_token_v2: recaptchaToken,
          },
        });
        return result;
      } catch (error) {
        return onError(error);
      }
    },
    [fromPath]
  );

  const resetPassword = useCallback(
    async (passwordHash: string, token: string) => {
      try {
        return await httpClient.put('/users/password.json', {
          user: {
            reset_password_token: token,
            password: passwordHash,
            password_confirmation: passwordHash,
          },
        });
      } catch (error) {
        return onError(error);
      }
    },
    []
  );

  return (
    <AuthContextProvider
      value={{
        updateUser,
        refreshCurrentUser,
        createResetPasswordRequest,
        resetPassword,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};

export default AuthProvider;
