import styled, { keyframes } from 'styled-components';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -312px 0;
  }
  100% {
    background-position: calc(312px + 100%) 0;
  }
`;

export const Skeleton = styled.div.attrs({
  tabIndex: 0,
  role: 'progressbar',
  'aria-busy': true,
  'aria-valuemin': 0,
  'aria-valuemax': 100,
})`
  --skeleton-highlight: rgba(var(--c-rgb-neutral-800), 0.05);
  display: inline-block;
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background: linear-gradient(
    to right,
    transparent,
    var(--skeleton-highlight),
    transparent
  );
  background-color: rgba(var(--c-rgb-neutral-1000), 0.05);
  background-size: 312px 100%;
  background-repeat: no-repeat;
  border-radius: var(--half-unit);
`;
