import { TypedDocumentNode, gql } from '@apollo/client';
import { isPast } from 'date-fns';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { DotsLoader } from '@sorare/core/src/atoms/loader/DotsLoader';

import { AuctionBidsInfo } from 'components/auction/Auction/AuctionDetails/AuctionBidsInfo';

import AuctionTimeLeft from '../AuctionTimeLeft';
import { AuctionStatus_auction } from './__generated__/index.graphql';

type Props = {
  auction: AuctionStatus_auction;
};

const Root = styled(Horizontal).attrs({ gap: 0.5 })`
  font: var(--t-12);
  color: var(--c-neutral-600);
  width: 100%;
  min-height: var(--triple-unit);
  flex-wrap: wrap;
`;

export const AuctionStatus = ({ auction }: Props) => {
  const { endDate } = auction;
  const ended = isPast(endDate);
  return (
    <Root>
      <AuctionBidsInfo auction={auction} />
      {' • '}
      {ended ? <DotsLoader small /> : <AuctionTimeLeft endDate={endDate} />}
    </Root>
  );
};

AuctionStatus.fragments = {
  auction: gql`
    fragment AuctionStatus_auction on TokenAuction {
      id
      endDate
      ...AuctionBidsInfo_auction
    }
    ${AuctionBidsInfo.fragments.auction}
  ` as TypedDocumentNode<AuctionStatus_auction>,
};
