import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { tabletAndAbove } from 'style/mediaQuery';

export const Banner = styled.div`
  color: var(--c-neutral-1000);
  position: relative;
  padding: var(--unit);
  background: var(--c-brand-600);
  font-weight: var(--t-bold);
  &.rounded {
    border-radius: var(--unit);
  }
`;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--unit);
  @media ${tabletAndAbove} {
    flex-direction: row-reverse;
    justify-content: center;
  }
`;
export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  @media ${tabletAndAbove} {
    flex-direction: row-reverse;
    gap: var(--unit);
  }
`;
export const StyledSpan = styled.span`
  font-weight: var(--t-bold);
`;
export const StyledLink = styled(Link)`
  text-decoration: underline;
  &,
  &:hover,
  &:focus {
    color: var(--c-static-neutral-100);
  }
`;
export const TermsLink = styled(ButtonBase)`
  color: var(--c-static-neutral-100);
  text-decoration: underline;
`;
