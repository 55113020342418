import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  intlFormat,
  isPast,
} from 'date-fns';
import { IntlFormatters } from 'react-intl';
import { css } from 'styled-components';

import { SEARCH_PARAMS } from '@sorare/core/src/components/search/InstantSearch/types';
import { LIFECYCLE } from '@sorare/core/src/hooks/useLifecycle';
import { sortBy } from '@sorare/core/src/lib/arrays';
import { earlyAccessLaunchMessages } from '@sorare/core/src/lib/glossary';

import challengerBgWithPlayers from './assets/challenger-background-with-player.jpg';
import challengerBg from './assets/challenger-background.jpg';
import challengerCards from './assets/challenger-cards.png';
import challengerIcon from './assets/challenger-icon.svg';
import championBgWithPlayers from './assets/champion-background-with-player.jpg';
import championBg from './assets/champion-background.jpg';
import championCards from './assets/champion-cards.png';
import championIcon from './assets/champion-icon.svg';
import contenderBgWithPlayers from './assets/contender-background-with-player.jpg';
import contenderBg from './assets/contender-background.jpg';
import contenderCards from './assets/contender-cards.png';
import contenderIcon from './assets/contender-icon.svg';

export const earlyAccessFontGradient = css`
  background: linear-gradient(
    92deg,
    #fff 0%,
    #eabfe2 28.88%,
    #fff 48.67%,
    #bedecb 64.58%,
    #fff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const now = new Date();

const CONTENDER_LAUNCH_DATE = '2024-07-17T12:00:00.000Z' as const;
const CHALLENGER_LAUNCH_DATE = '2024-07-22T12:00:00.000Z' as const;
const CHAMPION_LAUNCH_DATE = '2024-07-31T12:00:00.000Z' as const;

const CONTENDER_END_DATE = '2024-07-31T12:00:00.000Z' as const;
const CHALLENGER_END_DATE = '2024-08-05T12:00:00.000Z' as const;
const CHAMPION_END_DATE = '2024-08-16T14:00:00.000Z' as const;

export const isInstantBuyReady = (startDate: Date) => {
  return isPast(add(startDate, { hours: 12 }));
};

export const earlyAccessEvents = [
  {
    slug: 'champion',
    displayName: 'Champion',
    backgroundWithPlayers: championBgWithPlayers,
    background: championBg,
    cards: championCards,
    icon: championIcon,
    searchParams: SEARCH_PARAMS.CHAMPION_LAUNCH,
    startDate: new Date(CHAMPION_LAUNCH_DATE),
    endDate: new Date(CHAMPION_END_DATE),
    launchMessage: earlyAccessLaunchMessages.champion,
    lifecycle: LIFECYCLE.sawChampionEarlyAccessAnnouncementDialog,
    leagues: [
      'premier-league-gb-eng',
      'bundesliga-de',
      'laliga-es',
      'ligue-1-fr',
      'serie-a-it',
    ] as string[],
  },
  {
    slug: 'challenger',
    displayName: 'Challenger',
    backgroundWithPlayers: challengerBgWithPlayers,
    background: challengerBg,
    cards: challengerCards,
    icon: challengerIcon,
    searchParams: SEARCH_PARAMS.CHALLENGER_LAUNCH,
    startDate: new Date(CHALLENGER_LAUNCH_DATE),
    endDate: new Date(CHALLENGER_END_DATE),
    launchMessage: earlyAccessLaunchMessages.challenger,
    lifecycle: LIFECYCLE.sawChallengerEarlyAccessAnnouncementDialog,
    leagues: [
      'eredivisie',
      'campeonato-brasileiro-serie-a',
      'primeira-liga-pt',
      'football-league-championship',
      'spor-toto-super-lig',
      'jupiler-pro-league',
      'superliga-dk',
      'mlspa',
    ] as string[],
  },
  {
    slug: 'contender',
    displayName: 'Contender',
    backgroundWithPlayers: contenderBgWithPlayers,
    background: contenderBg,
    cards: contenderCards,
    icon: contenderIcon,
    searchParams: SEARCH_PARAMS.CONTENDER_LAUNCH,
    startDate: new Date(CONTENDER_LAUNCH_DATE),
    endDate: new Date(CONTENDER_END_DATE),
    launchMessage: earlyAccessLaunchMessages.contender,
    lifecycle: LIFECYCLE.sawStartedEarlyAccessAnnouncementDialog,
    leagues: [
      'liga-mx',
      'russian-premier-league',
      'superliga-argentina-de-futbol',
      'austrian-bundesliga',
      'premiership-gb-sct',
      '1-hnl',
      'super-league-ch',
      'j1-league',
      'eliteserien',
      'ligue-2-fr',
      'segunda-division-es',
      'serie-b-it',
      '2-bundesliga',
      'k-league-1',
      'primera-division-cl',
      'liga-pro',
      'primera-division-pe',
      'chinese-super-league',
      'primera-a',
    ] as string[],
  },
] as const;

export const formatLaunchDate = (launchDate: Date, locale: string) => {
  const diffInDays = differenceInDays(launchDate, now);

  return intlFormat(
    launchDate,
    diffInDays < 7
      ? {
          weekday: 'long',
          hour: '2-digit',
          minute: '2-digit',
        }
      : {
          weekday: 'long',
          month: 'short',
          day: 'numeric',
        },
    {
      locale,
    }
  );
};

export const formatTimeLeft = (
  endDate: Date,
  formatMessage: IntlFormatters['formatMessage']
) => {
  const daysLeft = differenceInDays(endDate, now);
  if (daysLeft >= 1)
    return formatMessage(
      {
        id: 'earlyAccess.formatTimeLeft.1dayOrMore',
        defaultMessage:
          '{daysLeft} {daysLeft, plural, one {day left} other {days left}}',
      },
      {
        daysLeft,
      }
    );

  const hoursLeft = differenceInHours(endDate, now);
  if (hoursLeft >= 1)
    return formatMessage(
      {
        id: 'earlyAccess.formatTimeLeft.1hourOrMore',
        defaultMessage:
          '{hoursLeft} {hoursLeft, plural, one {hour left} other {hours left}}',
      },
      {
        hoursLeft,
      }
    );
  const minutesLeft = differenceInMinutes(endDate, now);
  return formatMessage(
    {
      id: 'earlyAccess.formatTimeLeft.1minuteOrMore',
      defaultMessage:
        '{minutesLeft} {minutesLeft, plural, one {minute left} other {minutes left}}',
    },
    {
      minutesLeft,
    }
  );
};

export const getCurrentEventLaunch = ():
  | (typeof earlyAccessEvents)[number]
  | undefined => {
  return sortBy(
    e => e.startDate,
    earlyAccessEvents.filter(e => isPast(e.startDate))
  ).reverse()[0];
};
