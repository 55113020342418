import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { useTimeLeft } from '@sorare/core/src/hooks/useTimeLeft';
import { TabNum } from '@sorare/core/src/lib/time';

const Root = styled(Horizontal).attrs({ gap: 0 })``;

type Props = { time: Date; separator?: string };
export const RivalsCountdown = ({ time, separator = ':' }: Props) => {
  const { elements, isEnded } = useTimeLeft(time);

  if (isEnded) {
    return <TabNum>00:00:00</TabNum>;
  }

  return (
    <Root as="span">
      <TabNum>
        {String((elements?.hours || 0) + (elements?.days || 0) * 24).padStart(
          2,
          '0'
        )}
      </TabNum>
      {separator}
      <TabNum>{String(elements?.minutes).padStart(2, '0')}</TabNum>
      {separator}
      <TabNum>{String(elements?.seconds).padStart(2, '0')}</TabNum>
    </Root>
  );
};
