import { addBreadcrumb, captureException } from '@sentry/core';
import { Component, ErrorInfo, ReactNode } from 'react';

export class SilencedError extends Error {
  error: Error;

  constructor(message: string, error: Error) {
    super(message);
    this.error = error;
  }
}

type Props = {
  children: ReactNode;
  doNotReportToSentry?: (error: Error) => boolean;
  onCatch?: (error: Error) => void;
} & (
  | {
      fallback: ReactNode;
      fallbackRender?: never;
    }
  | {
      fallbackRender: (error: Error) => ReactNode;
      fallback?: never;
    }
);
type State = { error?: Error };

const isSilencedError = (error: unknown) => {
  return (
    error instanceof SilencedError ||
    (error instanceof Error && error?.cause instanceof SilencedError)
  );
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, { componentStack }: ErrorInfo): void {
    const { onCatch, doNotReportToSentry } = this.props;
    onCatch?.(error);
    if (!isSilencedError(error) && !doNotReportToSentry?.(error)) {
      addBreadcrumb({ level: 'debug', data: { componentStack } });
      captureException(error);
    }
  }

  render() {
    const { error } = this.state;
    const { children, fallback, fallbackRender } = this.props;

    if (error) {
      if (typeof fallbackRender === 'function') {
        return fallbackRender(error);
      }
      return fallback;
    }

    return children;
  }
}
