import styled from 'styled-components';

import { tabletAndAbove } from 'style/mediaQuery';

const NoPadding = styled.div``;

export const FullWidthContainer = styled.div`
  --container-padding: var(--intermediate-unit);
  @media ${tabletAndAbove} {
    --container-padding: var(--triple-unit);
  }
  padding: 0 var(--container-padding);

  ${NoPadding} {
    margin: 0 calc(-1 * var(--container-padding));
  }
` as typeof NoPadding & {
  NoPadding: typeof NoPadding;
};

FullWidthContainer.NoPadding = NoPadding;
