import { TypedDocumentNode, gql } from '@apollo/client';
import { defineMessages } from 'react-intl';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

import { useCannotSettleInFiat_anyCard } from './__generated__/useCannotSettleInFiat.graphql';

const messages = defineMessages({
  sellerDoesNotAcceptCash: {
    id: 'useCannotSettleInFiat.sellerDoesNotAcceptCash',
    defaultMessage: 'The seller does not accept cash',
  },
});

const useCannotSettleInFiat = () => {
  const {
    walletPreferences: { onlyShowFiatCurrency },
  } = useCurrentUserContext();

  const fiatSettlementCurrencies = [
    SupportedCurrency.USD,
    SupportedCurrency.EUR,
    SupportedCurrency.GBP,
  ];

  return (card: useCannotSettleInFiat_anyCard) => {
    const settlementCurrencies =
      card?.liveSingleSaleOffer?.settlementCurrencies;
    if (!settlementCurrencies) return null;
    if (
      onlyShowFiatCurrency &&
      !settlementCurrencies?.some(s => fiatSettlementCurrencies.indexOf(s) >= 0)
    )
      return messages.sellerDoesNotAcceptCash;

    return null;
  };
};

useCannotSettleInFiat.fragments = {
  anyCard: gql`
    fragment useCannotSettleInFiat_anyCard on AnyCardInterface {
      slug
      liveSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        settlementCurrencies
      }
    }
  ` as TypedDocumentNode<useCannotSettleInFiat_anyCard>,
};

export default useCannotSettleInFiat;
