import { useCallback } from 'react';
import { To, useNavigate } from 'react-router-dom';

import { getSafePreviousLocation } from 'lib/navigation/getSafePreviousLocation';

export const useSafePreviousNavigate = (
  defaultPreviousLocation: To,
  delta?: number
) => {
  const navigate = useNavigate();

  const safePreviousLocation = getSafePreviousLocation(
    defaultPreviousLocation,
    delta
  );
  return useCallback(() => {
    // Extra typing needed due to the way navigate is typed
    // both To & number are accepted but not To | number
    navigate(safePreviousLocation as To);
  }, [navigate, safePreviousLocation]);
};
