import { useContext } from 'react';

import {
  FeatureFlagsContextType,
  featureFlagsContext,
} from 'contexts/featureFlags';

const noFlags: FeatureFlagsContextType = {
  flags: {},
  untrackedFlags: {},
  identify: undefined,
};

export default (): FeatureFlagsContextType => {
  return useContext(featureFlagsContext) || noFlags;
};

export { useLDClient } from 'launchdarkly-react-client-sdk';
