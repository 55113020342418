import { useEffect, useState } from 'react';

import { useShortcut } from 'hooks/useShortcut';

type Props = {
  selectedIndex: number;
  selectItemAtIndex: (index: number) => void;
  nbItems: number;
  redirectIfNotFound?: boolean;
};

export const useKeyboardSelection = ({
  selectedIndex,
  selectItemAtIndex,
  nbItems,
  redirectIfNotFound = false,
}: Props) => {
  const [highlightedItemIndex, setHighlightedItemIndex] = useState<number>(
    redirectIfNotFound ? Math.max(0, selectedIndex) : selectedIndex
  );

  useShortcut(
    'ArrowDown',
    () => {
      setHighlightedItemIndex(current => {
        if (current === -1) {
          return -1;
        }
        return Math.min(nbItems - 1, current + 1);
      });
    },
    [nbItems, setHighlightedItemIndex]
  );
  useShortcut(
    'ArrowUp',
    () => {
      setHighlightedItemIndex(current => {
        if (current === -1) {
          return -1;
        }
        return Math.max(0, current - 1);
      });
    },
    [setHighlightedItemIndex]
  );
  useShortcut(
    'Enter',
    () => {
      if (highlightedItemIndex !== -1) {
        selectItemAtIndex(highlightedItemIndex);
      }
    },
    [selectItemAtIndex, highlightedItemIndex]
  );

  // Highlight currently selected item
  useEffect(() => {
    if (selectedIndex !== -1) {
      setHighlightedItemIndex(selectedIndex);
    }
  }, [selectedIndex]);

  // When the list of eligible items changes such that the highlighted item
  // is no longer present in the list, change the highlighted item to the
  // first eligible item in the list.
  useEffect(() => {
    if (highlightedItemIndex !== -1 && highlightedItemIndex > nbItems - 1) {
      setHighlightedItemIndex(0);
    }
  }, [highlightedItemIndex, nbItems]);

  return {
    highlightedItemIndex,
    setHighlightedItemIndex,
  };
};
