import { useMemo } from 'react';

import useLifecycle, { LIFECYCLE } from 'hooks/useLifecycle';
import {
  CrediCardPaymentMethod,
  PaymentMethod,
  PaymentProvider_paymentMethod,
} from 'lib/paymentMethod';

export const usePreferredPaymentMethod = () => {
  const { update, loading, lifecycle } = useLifecycle();
  const preferredPaymentMethod = lifecycle?.preferredPaymentMethod || null;

  const preferredOrderedPaymentMethod = useMemo(() => {
    if (!preferredPaymentMethod) return null;
    if (typeof preferredPaymentMethod !== 'string')
      return `credit_card_${preferredPaymentMethod.id}` as CrediCardPaymentMethod;
    return preferredPaymentMethod;
  }, [preferredPaymentMethod]);

  const preferredOrderedPaymentMethodGrouped = useMemo(() => {
    if (!preferredPaymentMethod) return null;
    if (typeof preferredPaymentMethod !== 'string')
      return PaymentMethod.CREDIT_CARD;
    return preferredPaymentMethod;
  }, [preferredPaymentMethod]);

  return {
    preferredPaymentMethodIsCreditCard:
      typeof preferredPaymentMethod !== 'string',
    preferredPaymentMethod,
    preferredOrderedPaymentMethod,
    preferredOrderedPaymentMethodGrouped,
    updatePreferredPaymentMethod: async (
      pm:
        | PaymentProvider_paymentMethod
        | PaymentMethod.ETH_WALLET
        | PaymentMethod.FIAT_WALLET
    ) => update(LIFECYCLE.preferredPaymentMethod, pm),
    loading,
  };
};
