import { TypedDocumentNode, gql } from '@apollo/client';
import gqlMacro from 'graphql-tag.macro';

import { Notification } from 'components/activity/Notification';
import NotificationDialog from 'components/notification/NotificationDialog';
import { currentSportUnreadNotificationsCountFragment } from 'contexts/currentUser/queries';

import {
  InGameNotificationProvider_notification,
  InGameNotificationProvider_notifications,
  InGameNotificationQuery,
  InGameNotificationQueryVariables,
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables,
} from './__generated__/queries.graphql';

const notification = gql`
  fragment InGameNotificationProvider_notification on NotificationInterface {
    id
    name
    read
    sport
    createdAt
    ...NotificationDialog_notification
    ...Notification_notification
  }
  ${NotificationDialog.fragments.notification}
  ${Notification.fragments.notification}
` as TypedDocumentNode<InGameNotificationProvider_notification>;

const notifications = gql`
  fragment InGameNotificationProvider_notifications on CurrentUser {
    slug
    ...CurrentUserProvider_currentSportUnreadNotificationsCount
    currentSportNotifications: anyNotifications(
      first: $pageSize
      after: $notificationCursor
      sports: [$sport]
      categories: $notificationCategories
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        ...InGameNotificationProvider_notification
      }
    }
  }
  ${notification}
  ${currentSportUnreadNotificationsCountFragment}
` as TypedDocumentNode<InGameNotificationProvider_notifications>;

export const IN_GAME_NOTIFICATION_QUERY = gqlMacro`
  query InGameNotificationQuery(
    $notificationCursor: String
    $pageSize: Int
    $sport: Sport!
    $notificationCategories: [NotificationCategoryInput!]
  ) {
    currentUser {
      slug
      ...InGameNotificationProvider_notifications
    }
  }
  ${notifications}
` as TypedDocumentNode<
  InGameNotificationQuery,
  InGameNotificationQueryVariables
>;

export const MARK_NOTIFICATIONS_AS_READ_MUTATION = gql`
  mutation MarkNotificationsAsReadMutation(
    $input: markNotificationsAsReadInput!
    $sport: Sport!
    $notificationCategories: [NotificationCategoryInput!]
  ) {
    markNotificationsAsRead(input: $input) {
      currentUser {
        slug
        ...CurrentUserProvider_currentSportUnreadNotificationsCount
      }
      notifications {
        id
        read
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${currentSportUnreadNotificationsCountFragment}
` as TypedDocumentNode<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>;
