import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';
import { useTimeLeft } from 'hooks/useTimeLeft';

type Props = { myLimitResetAt: Date };
export const ResetIn = ({ myLimitResetAt }: Props) => {
  const { message } = useTimeLeft(myLimitResetAt);
  return (
    <Horizontal gap={0.5}>
      <FontAwesomeIcon icon={faClock} size="xs" color="var(--c-neutral-600)" />
      <LabelS as="span" color="var(--c-neutral-600)">
        <FormattedMessage
          id="ClubShop.Item.Label.Reset"
          defaultMessage="Reset in {remaining}"
          values={{ remaining: message }}
        />
      </LabelS>
    </Horizontal>
  );
};
