import { TypedDocumentNode, gql } from '@apollo/client';

import { deckFragments_deck } from './__generated__/fragments.graphql';

export const deckFragment = gql`
  fragment deckFragments_deck on Deck {
    id
    slug
    name
    visible
    sport
    tokensCount
  }
` as TypedDocumentNode<deckFragments_deck>;
