import { faWallet } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { Suspense, lazy } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import footballLogo from 'assets/logos/football/main.svg';
import mlbLogo from 'assets/logos/mlb/mlb.svg';
import nbaLogo from 'assets/logos/nba/nba.svg';
import { Button } from 'atoms/buttons/Button';
import { Dropdown } from 'atoms/dropdowns/Dropdown';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Container } from 'atoms/layout/Container';
import { Horizontal, SBHorizontal, Vertical } from 'atoms/layout/flex';
import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { LinkOther } from 'atoms/navigation/Box';
import { SmallerStarBall } from 'atoms/navigation/SmallerStarBall';
import { ResponsiveSearchBar } from 'components/search/ResponsiveSearchBar';
import { LANDING } from 'constants/__generated__/routes';
import { FOOTBALL_PATH, MLB_PATH, NBA_PATH } from 'constants/routes';
import { useConnectionContext } from 'contexts/connection';
import useScreenSize from 'hooks/device/useScreenSize';
import { useEvents } from 'lib/events/useEvents';
import { glossary, sportsLabelsMessages } from 'lib/glossary';
import { MenuIconButton } from 'routing/MultiSportAppBar/MenuIconButton';
import AppBarProvider from 'routing/MultiSportAppBar/context/Provider';
import { breakpoints, laptopAndAbove, mobileAndAbove } from 'style/mediaQuery';
import { regularMaterial } from 'style/utils';

const CryptoWalletConnect = lazy(async () => import('../CryptoWalletConnect'));

const Wrapper = styled(SBHorizontal).attrs({ gap: 0 })`
  z-index: 1;
  position: relative;
  margin: auto;
  width: 100%;
  height: var(--navbar-height-mobile);
`;

const SportsLinks = styled.div`
  display: flex;
  flex: 1;
  gap: var(--unit);
`;

const Logo = styled(Link)`
  display: flex;
  gap: var(--unit);
  align-items: center;
  @media ${mobileAndAbove} {
    margin-right: var(--double-unit);
  }
`;

const SportButton = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: var(--unit);
  justify-content: space-between;
  @media ${laptopAndAbove} {
    padding: var(--unit);
    &:hover {
      border-radius: var(--unit);
      background-color: var(--c-static-neutral-800);
    }
  }
`;

const MobileSportButtonsWrapper = styled(SBHorizontal)`
  padding: 0 0 var(--unit);
  overflow: auto;
`;

const CryptoWalletButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--unit);
  padding: var(--double-unit);
  max-width: 100%;
  width: 300px;
  max-height: calc(
    var(--radix-popover-content-available-height, 100vh) - var(--double-unit)
  );
  overflow: hidden;
  flex-shrink: 0;
  border-radius: var(--intermediate-unit);
  ${regularMaterial}
  border: 1px solid var(--c-nd-100);
  margin-top: var(--unit);
  &:not(.isDesktop) {
    top: 0px !important;
    left: 0px !important;
    flex-shrink: unset;
    max-width: 100vw;
    max-height: var(--radix-popover-content-available-height, 100vh);
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    overflow: scroll;
  }
`;

declare global {
  interface Window {
    ethereum?: any;
    web3?: any;
  }
}

export const CryptoWalletButton = () => {
  const { formatMessage } = useIntl();
  const { up: isDesktop } = useScreenSize('desktop');
  const track = useEvents();

  // without loading wagmi.js through the BlockchainProvider, rely on the presence of window.web3 or window.ethereum
  // to determine if the user has a crypto wallet
  const withCryptoWallet = !!window.web3 || !!window.ethereum;
  if (!withCryptoWallet) {
    return null;
  }

  return (
    <Dropdown
      transparent
      onOpen={() => track('Click Homepage Crypto Wallet Icon', {})}
      label={props => (
        <MenuIconButton
          icon={faWallet}
          aria-label={formatMessage(glossary.connectWallet)}
          {...props}
        />
      )}
    >
      {({ closeDropdown }) => (
        <CryptoWalletButtonContent className={classNames({ isDesktop })}>
          <Suspense fallback={<LoadingIndicator />}>
            <CryptoWalletConnect toggleClose={closeDropdown} />
          </Suspense>
        </CryptoWalletButtonContent>
      )}
    </Dropdown>
  );
};

const ConnexionButtons = () => {
  const { signIn, signUp } = useConnectionContext();
  return (
    <Horizontal center>
      <CryptoWalletButton />
      <Button size="small" color="primary" onClick={signUp}>
        <FormattedMessage {...glossary.signup} />
      </Button>
      <Button size="small" color="tertiary" onClick={signIn}>
        <FormattedMessage {...glossary.signin} />
      </Button>
    </Horizontal>
  );
};

const SportButtons = () => {
  return (
    <>
      <LinkOther as={SportButton} to={FOOTBALL_PATH}>
        <FormattedMessage {...sportsLabelsMessages.FOOTBALL} />
        <img src={footballLogo} alt="" width="24px" height="24px" />
      </LinkOther>
      <LinkOther as={SportButton} to={NBA_PATH}>
        <FormattedMessage {...sportsLabelsMessages.NBA} />
        <img src={nbaLogo} alt="" width="10px" height="23px" />
      </LinkOther>
      <LinkOther as={SportButton} to={MLB_PATH}>
        <FormattedMessage {...sportsLabelsMessages.BASEBALL} />
        <img src={mlbLogo} alt="" width="30px" height="17px" />
      </LinkOther>
    </>
  );
};

export const LandingAppHeader = () => {
  const { up: isMobile } = useScreenSize('mobile');
  const { up: isLaptop } = useScreenSize('laptop');
  return (
    <Container maxWidth={breakpoints.mediumDesktop}>
      <Vertical gap={0}>
        <Wrapper>
          <SportsLinks>
            <Logo to={LANDING} title="Sorare">
              {isMobile ? <SorareLogo /> : <SmallerStarBall />}
            </Logo>
            {isLaptop && <SportButtons />}
          </SportsLinks>
          <AppBarProvider>
            <ResponsiveSearchBar />
          </AppBarProvider>
          <ConnexionButtons />
        </Wrapper>
        {!isLaptop && (
          <MobileSportButtonsWrapper gap={2}>
            <SportButtons />
          </MobileSportButtonsWrapper>
        )}
      </Vertical>
    </Container>
  );
};
