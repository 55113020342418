import { defineMessages } from 'react-intl';

export const metadatas = {
  default: defineMessages({
    title: {
      id: 'Meta.football.default.title',
      defaultMessage: 'Global Fantasy Football – Sorare Football',
    },
    description: {
      id: 'Meta.football.default.description',
      defaultMessage:
        "Collect, play and win officially licensed digital cards featuring the world's best global football players.",
    },
  }),
  market: defineMessages({
    title: {
      id: 'Meta.football.market.title',
      defaultMessage: 'Cards Market – Sorare Football',
    },
    description: {
      id: 'Meta.football.market.description',
      defaultMessage:
        "Discover new Sorare Football cards featuring the world's best global football players available from top leagues such as Premier League, LaLiga, SerieA, Bundesliga and Ligue 1",
    },
  }),
  newSignings: defineMessages({
    title: {
      id: 'Meta.football.newSignings.title',
      defaultMessage: 'New Cards Auctions – Sorare Football',
    },
    description: {
      id: 'Meta.football.newSignings.description',
      defaultMessage:
        "Find and collect Sorare Football cards featuring the world's best global football players available from top leagues such as Premier League, LaLiga, SerieA, Bundesliga and Ligue 1",
    },
  }),
  transfers: defineMessages({
    title: {
      id: 'Meta.football.transfer.title',
      defaultMessage: 'Manager Sales Market – Sorare Football',
    },
    description: {
      id: 'Meta.football.transfer.description',
      defaultMessage:
        'Find, collect and trade cards with other Sorare Managers',
    },
  }),
  playerOverview: defineMessages({
    title: {
      id: 'Meta.football.player.overview.title',
      defaultMessage: '{player} – Player Profile',
    },
    description: {
      id: 'Meta.football.player.overview.description',
      defaultMessage:
        'Find all statistics of {player}: ratings, goals, assists, rankings and more. Collect and trade officially licensed digital cards of your favorite players.',
    },
  }),
  nationalTeamOverview: defineMessages({
    title: {
      id: 'Meta.football.nationalTeam.overview.title',
      defaultMessage: '{country} National Football Team – Team Profile',
    },
    description: {
      id: 'Meta.football.nationalTeam.overview.description',
      defaultMessage:
        'Overview of games, players and statistics of {country} National Football Team. Collect and trade digital cards of your favorite players.',
    },
  }),
  clubOverview: defineMessages({
    title: {
      id: 'Meta.football.club.overview.title',
      defaultMessage: '{club} – Club Profile',
    },
    description: {
      id: 'Meta.football.club.overview.description',
      defaultMessage:
        'Overview of standings, fixtures, transfers, squad and statistics of {club}. Collect and trade officially licensed digital cards of your favorite players.',
    },
  }),
  clubStandings: defineMessages({
    title: {
      id: 'Meta.football.club.standings.title',
      defaultMessage: '{club} Standings',
    },
    description: {
      id: 'Meta.football.club.standings.description',
      defaultMessage:
        'History and current {club} standings. Collect and trade officially licensed digital cards of your favourite players on Sorare.',
    },
  }),
  clubFixtures: defineMessages({
    title: {
      id: 'Meta.football.club.fixtures.title',
      defaultMessage: '{club} Fixtures',
    },
    description: {
      id: 'Meta.football.club.fixtures.description',
      defaultMessage:
        'All {club} fixtures, scores, results and upcoming games. Collect and trade officially licensed digital cards of your favourite players on Sorare.',
    },
  }),
  clubSquad: defineMessages({
    title: {
      id: 'Meta.football.club.squad.title',
      defaultMessage: '{club} Players',
    },
    description: {
      id: 'Meta.football.club.squad.description',
      defaultMessage:
        '{club} players: forward, defender, midfielder, goalkeeper and manager. Collect and trade officially licensed digital cards on Sorare.',
    },
  }),
  clubCards: defineMessages({
    title: {
      id: 'Meta.football.club.cards.title',
      defaultMessage: '{club} Cards – Collect and Trade',
    },
    description: {
      id: 'Meta.football.club.cards.description',
      defaultMessage:
        'Find all the officially licensed digital cards of {club}: Limited, Rare, Super Rare, Unique. Only on Sorare.',
    },
  }),
  leagueOverview: defineMessages({
    title: {
      id: 'Meta.football.league.overview.title',
      defaultMessage: '{league} – League Profile',
    },
    description: {
      id: 'Meta.football.league.overview.description',
      defaultMessage:
        'Overview of fixtures, tables, squads and statistics of the {league}. Collect and trade officially licensed digital cards of your favorite players.',
    },
  }),
  leagueFixtures: defineMessages({
    title: {
      id: 'Meta.football.league.fixtures.title',
      defaultMessage: '{league} Fixtures',
    },
    description: {
      id: 'Meta.football.league.fixtures.description',
      defaultMessage:
        'All {league} fixtures, scores, results and upcoming games. Collect and trade officially licensed digital cards of your favorite players.',
    },
  }),
  leagueCards: defineMessages({
    title: {
      id: 'Meta.football.league.cards.title',
      defaultMessage: '{league} Cards – Collect and Trade',
    },
    description: {
      id: 'Meta.football.league.cards.description',
      defaultMessage:
        'Find all the officially licensed digital cards of {league}: Limited, Rare, Super Rare, Unique. Only on Sorare.',
    },
  }),
  country: defineMessages({
    title: {
      id: 'Meta.football.country.title',
      defaultMessage: '{country} – Top Players – Sorare Football',
    },
    description: {
      id: 'Meta.football.country.description',
      defaultMessage:
        'Collect, trade and play with Sorare cards of players from {country} in different scarcities and own your game',
    },
  }),
  instantBuy: defineMessages({
    title: {
      id: 'Meta.football.starterCards.title',
      defaultMessage: 'Instant Buy Market – Sorare Football',
    },
    description: {
      id: 'Meta.football.starterCards.description',
      defaultMessage:
        "Find and collect Sorare cards featuring the world's best global football players available from top leagues such as Premier League, LaLiga, SerieA, Bundesliga and Ligue 1",
    },
  }),
  composeTeam: defineMessages({
    title: {
      id: 'Meta.football.composeTeam.title',
      defaultMessage: '{leaderboardName} – Sorare Football',
    },
  }),
};
