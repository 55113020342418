import { generatePath } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import {
  MLB,
  MLB_MY_CLUB_SLUG,
} from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

const MyClubRedirect = () => {
  const { currentUser } = useCurrentUserContext();
  const actualSlug = currentUser?.slug;
  if (!actualSlug) {
    return <Navigate replace to={MLB} />;
  }
  return (
    <Navigate
      replace
      to={generatePath(MLB_MY_CLUB_SLUG, {
        slug: actualSlug,
      })}
    />
  );
};

export default MyClubRedirect;
