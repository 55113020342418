import { TypedDocumentNode, gql } from '@apollo/client';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { GameStatus } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { Caption, LabelL } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';
import { CloseButton } from '@sorare/core/src/components/dialog/CloseButton';
import {
  FOOTBALL_CLUB_SHOW,
  FOOTBALL_COUNTRY_SHOW,
} from '@sorare/core/src/constants/routes';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { isType } from '@sorare/core/src/gql';
import { gameStatusMessages } from '@sorare/core/src/lib/glossary';

import { GameStatusLabel } from 'components/so5/GameStatusLabel';

import { Overview_game } from './__generated__/index.graphql';

const Root = styled.div`
  display: grid;
  grid-template-areas:
    'game_name game_name game_status'
    'home_flag home_name home_score'
    'away_flag away_name away_score';
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  justify-content: center;
  padding: var(--double-unit);
  column-gap: var(--double-unit);
  row-gap: var(--unit);
  width: 100%;
  border-radius: var(--unit) var(--unit) 0 0;
  background-color: var(--c-nd-50);

  &.desktop {
    grid-template-areas: 'game_name home_name home_flag home_score dash away_score away_flag away_name game_status';
    grid-template-columns: 200px 1fr min-content repeat(3, 18px) min-content 1fr 200px;
    justify-items: center;
    & > :first-child {
      justify-self: start;
    }
    & > :nth-child(2) {
      justify-self: end;
    }
    & > :nth-child(8) {
      justify-self: start;
    }
    & > :last-child {
      justify-self: end;
    }

    &.hideScoresAndGameWeek {
      grid-template-areas: 'game_name home_name home_flag dash away_flag away_name game_status';
      grid-template-columns: 200px 1fr min-content 18px min-content 1fr 200px;
      & > :nth-child(6) {
        justify-self: start;
      }
    }
  }
`;
const Block = styled(Horizontal).attrs({ gap: 0 })`
  color: var(--c-neutral-1000);
`;
const FlexContainer = styled(Block)`
  gap: var(--unit);
`;
const FlexColContainer = styled(Block)`
  flex-direction: row;
  align-items: flex-start;
  gap: var(--unit);
  &.desktop {
    flex-direction: column;
    gap: 0;
  }
`;
const GreyCaption = styled(Caption)`
  color: var(--c-neutral-600);
`;
const TeamImage = styled.img`
  height: 16px;
  border-radius: 2px;
`;
const Dash = styled.div`
  grid-area: dash;
  display: none;
  text-align: center;
  &.desktop {
    display: block;
  }
`;
const GameName = styled(Block)`
  grid-area: game_name;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font: var(--t-12);
`;
const GameStatusBlock = styled(Block)`
  grid-area: game_status;
  display: flex;
  align-items: center;
  gap: var(--unit);
`;
const AwayName = styled(Block)`
  grid-area: away_name;
  text-align: left;
`;
const HomeName = styled(Block)`
  grid-area: home_name;
`;
const AwayFlag = styled(Block)`
  grid-area: away_flag;
`;
const HomeFlag = styled(Block)`
  grid-area: home_flag;
`;
const HomeScore = styled(Block)`
  grid-area: home_score;
`;
const AwayScore = styled(Block)`
  grid-area: away_score;
`;
const Score = styled(LabelL).attrs({ as: 'span', bold: true })`
  width: 100%;
  text-align: right;
`;

const normalCaseStatuses = [GameStatus.playing, GameStatus.played];

const specialCaseStatuses = [
  GameStatus.suspended,
  GameStatus.postponed,
  GameStatus.cancelled,
  GameStatus.canceled,
];

const teamPath = (
  team: NonNullable<Overview_game['homeTeam'] | Overview_game['awayTeam']>
) => {
  const isClub = isType(team, 'Club');

  if (isClub) {
    return generatePath(FOOTBALL_CLUB_SHOW, {
      slug: team.slug,
    });
  }

  return generatePath(FOOTBALL_COUNTRY_SHOW, {
    countryCode: team.country.code,
  });
};

type Props = {
  game: Overview_game;
  desktop?: boolean;
  hideScoresAndGameWeek?: boolean;
  onClose?: () => void;
};
const Overview = ({ game, desktop, hideScoresAndGameWeek, onClose }: Props) => {
  const { formatMessage } = useIntl();
  const { formatDate } = useIntlContext();
  const { date, homeTeam, awayTeam, status, so5Fixture } = game;

  const isNormalCase = normalCaseStatuses.includes(status);
  const isSpecialCase = specialCaseStatuses.includes(status);
  const isUpcoming = status === GameStatus.scheduled;

  const fullDate = formatDate(date, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <Root className={classnames({ desktop, hideScoresAndGameWeek })}>
      <GameName>
        {(isNormalCase || isUpcoming) && (
          <FlexColContainer className={classnames({ desktop })}>
            <Tooltip title={fullDate}>
              <FlexContainer>
                <GameStatusLabel game={game} />
                {so5Fixture && !hideScoresAndGameWeek && (
                  <>
                    <span>-</span>
                    <span>{so5Fixture.shortDisplayName}</span>
                  </>
                )}
              </FlexContainer>
            </Tooltip>
            <GreyCaption>{game.competition?.displayName}</GreyCaption>
          </FlexColContainer>
        )}
      </GameName>

      <>
        {homeTeam && (
          <>
            <HomeName>
              <Link to={teamPath(homeTeam)}>{homeTeam.name}</Link>
            </HomeName>
            <HomeFlag>
              <TeamImage src={homeTeam.pictureUrl || ''} />
            </HomeFlag>
            {!hideScoresAndGameWeek && (
              <HomeScore>
                <Score>{isNormalCase ? game.homeGoals : '-'}</Score>
              </HomeScore>
            )}
          </>
        )}

        <Dash className={classnames({ desktop })}>{isNormalCase && '-'}</Dash>

        {awayTeam && (
          <>
            {!hideScoresAndGameWeek && (
              <AwayScore>
                <Score>{isNormalCase ? game.awayGoals : '-'}</Score>
              </AwayScore>
            )}
            <AwayFlag>
              <TeamImage src={awayTeam.pictureUrl || ''} />
            </AwayFlag>
            <AwayName>
              <Link to={teamPath(awayTeam)}>{awayTeam.name}</Link>
            </AwayName>
          </>
        )}
      </>

      <GameStatusBlock>
        {isSpecialCase && (
          <Chip color="red">{formatMessage(gameStatusMessages[status])}</Chip>
        )}
        {onClose && <CloseButton onClose={onClose} />}
      </GameStatusBlock>
    </Root>
  );
};

Overview.fragments = {
  game: gql`
    fragment Overview_game on Game {
      id
      date
      status: statusTyped
      awayGoals
      homeGoals
      winner {
        slug
      }
      awayTeam {
        slug
        name
        country {
          slug
          code
        }
        pictureUrl(derivative: "high_res")
      }
      homeTeam {
        slug
        name
        country {
          slug
          code
        }
        pictureUrl(derivative: "high_res")
      }
      so5Fixture {
        slug
        shortDisplayName
      }
      competition {
        slug
        displayName
      }
      ...GameStatusLabel_anyGameInterface
    }
    ${GameStatusLabel.fragments.anyGameInterface}
  ` as TypedDocumentNode<Overview_game>,
};

export default Overview;
