import { TypedDocumentNode, gql } from '@apollo/client';
import { CSSProperties } from 'react';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { useIsTabletAndAbove } from '@sorare/core/src/hooks/device/useIsTabletAndAbove';
import { sortBy } from '@sorare/core/src/lib/arrays';
import {
  largeDesktopAndAbove,
  tabletAndAbove,
} from '@sorare/core/src/style/mediaQuery';

import { SquaredFeaturedPlayer } from '@sorare/us-sports/src/components/game/SquaredFeaturedPlayer';
import { ColoredScore } from '@sorare/us-sports/src/components/scores/ColoredScore';
import { ColoredScoreWithDelta } from '@sorare/us-sports/src/components/scores/ColoredScoreWithDelta';

import {
  TopFieldPlayers_anyCard,
  TopFieldPlayers_basketballPlayerGameScore,
  TopFieldPlayers_so5Appearance,
} from './__generated__/index.graphql';

const Wrapper = styled.div`
  padding: 0;

  --player-first: var(--double-unit);
  --player-second: var(--intermediate-unit);
  --player-third: var(--intermediate-unit);
  --player-fourth: var(--unit);
  --player-fifth: var(--unit);
  @media ${tabletAndAbove} {
    padding: var(--quadruple-unit) calc(8 * var(--unit));

    --player-first: var(--double-unit);
    --player-second: var(--intermediate-unit);
    --player-third: var(--intermediate-unit);
    --player-fourth: var(--unit);
    --player-fifth: var(--unit);
  }

  @media ${largeDesktopAndAbove} {
    --player-first: var(--triple-unit);
    --player-second: var(--double-and-a-half-unit);
    --player-third: var(--double-unit);
    --player-fourth: var(--intermediate-unit);
    --player-fifth: var(--intermediate-unit);
  }
`;

type Props = {
  playerStats: {
    playerGameScore: TopFieldPlayers_basketballPlayerGameScore;
    so5Appearances?: TopFieldPlayers_so5Appearance[];
    unusedCards?: TopFieldPlayers_anyCard[];
  }[];
  openPlayerInfoModal: (playerSlug: string) => void;
  onMouseHoverPlayer: (playerSlug: string) => void;
  onMouseLeavePlayer: () => void;
  mouseOnHoverPlayer: string | null;
};

const GAP = 3;

type SquaredPlayerProps = {
  playerGameScore: TopFieldPlayers_basketballPlayerGameScore;
  onClick: (playerSlug: string) => void;
  onMouseHover: (playerSlug: string) => void;
  onMouseLeave: () => void;
  highlighted: boolean;
  size: `var(--player-${string})`;
  withDelta?: boolean;
};
const SquaredPlayer = ({
  highlighted,
  playerGameScore,
  onClick,
  onMouseHover,
  onMouseLeave,
  size,
  withDelta = false,
}: SquaredPlayerProps) => {
  const { anyPlayer } = playerGameScore;
  return (
    <SquaredFeaturedPlayer
      key={anyPlayer.slug}
      player={anyPlayer}
      onClick={() => onClick(anyPlayer.slug)}
      onMouseHover={() => onMouseHover(anyPlayer.slug)}
      onMouseLeave={onMouseLeave}
      highlighted={highlighted}
      style={
        {
          '--image-unit': `${size}`,
        } as CSSProperties
      }
      score={
        withDelta ? (
          <ColoredScoreWithDelta
            score={playerGameScore.score || 0}
            averageGameScore={anyPlayer?.lastTenPlayedSo5AverageScore || 0}
            playedInGame
          />
        ) : (
          <ColoredScore score={playerGameScore.score || 0} playedInGame />
        )
      }
    />
  );
};

export const TopFieldPlayers = ({
  playerStats,
  openPlayerInfoModal,
  onMouseLeavePlayer,
  onMouseHoverPlayer,
  mouseOnHoverPlayer,
}: Props) => {
  const isTabletAndAbove = useIsTabletAndAbove();
  const sortedPlayersByScore = sortBy(
    ({ playerGameScore }) => playerGameScore.score || 0,
    [...playerStats]
  );
  const playedInGame = sortedPlayersByScore.filter(
    ({ playerGameScore }) =>
      (playerGameScore.basketballPlayerGameStats.minsPlayed || 0) > 0
  );

  const topPlayerStats = [...playedInGame].reverse();

  const [first, second, third, fourth, fifth] = topPlayerStats;
  return (
    <Wrapper>
      <Vertical gap={GAP} center>
        {isTabletAndAbove && (
          <Horizontal gap={GAP}>
            {fourth && (
              <SquaredPlayer
                playerGameScore={fourth.playerGameScore}
                highlighted={
                  mouseOnHoverPlayer === fourth.playerGameScore.anyPlayer.slug
                }
                size="var(--player-fourth)"
                onClick={openPlayerInfoModal}
                onMouseHover={onMouseHoverPlayer}
                onMouseLeave={onMouseLeavePlayer}
              />
            )}
            {fifth && (
              <SquaredPlayer
                playerGameScore={fifth.playerGameScore}
                highlighted={
                  mouseOnHoverPlayer === fifth.playerGameScore.anyPlayer.slug
                }
                size="var(--player-fifth)"
                onClick={openPlayerInfoModal}
                onMouseHover={onMouseHoverPlayer}
                onMouseLeave={onMouseLeavePlayer}
              />
            )}
          </Horizontal>
        )}
        <Horizontal>
          {second && (
            <SquaredPlayer
              playerGameScore={second.playerGameScore}
              highlighted={
                mouseOnHoverPlayer === second.playerGameScore.anyPlayer.slug
              }
              size="var(--player-second)"
              onClick={openPlayerInfoModal}
              onMouseHover={onMouseHoverPlayer}
              onMouseLeave={onMouseLeavePlayer}
            />
          )}
          {first && (
            <SquaredPlayer
              playerGameScore={first.playerGameScore}
              highlighted={
                mouseOnHoverPlayer === first.playerGameScore.anyPlayer.slug
              }
              size="var(--player-first)"
              onClick={openPlayerInfoModal}
              onMouseHover={onMouseHoverPlayer}
              onMouseLeave={onMouseLeavePlayer}
            />
          )}
          {third && (
            <SquaredPlayer
              playerGameScore={third.playerGameScore}
              highlighted={
                mouseOnHoverPlayer === third.playerGameScore.anyPlayer.slug
              }
              size="var(--player-third)"
              onClick={openPlayerInfoModal}
              onMouseHover={onMouseHoverPlayer}
              onMouseLeave={onMouseLeavePlayer}
            />
          )}
        </Horizontal>
      </Vertical>
    </Wrapper>
  );
};

TopFieldPlayers.fragments = {
  basketballPlayerGameScore: gql`
    fragment TopFieldPlayers_basketballPlayerGameScore on BasketballPlayerGameScore {
      id
      score
      anyPlayer {
        slug
        lastTenPlayedSo5AverageScore: averageScore(
          type: LAST_TEN_PLAYED_SO5_AVERAGE_SCORE
        )
        ...SquaredFeaturedPlayer_anyPlayerInterface
      }
      anyGame {
        id
      }
      basketballPlayerGameStats {
        id
        minsPlayed
        anyTeam {
          slug
        }
      }
    }
    ${SquaredFeaturedPlayer.fragments.anyPlayerInterface}
  ` as TypedDocumentNode<TopFieldPlayers_basketballPlayerGameScore>,
  anyCard: gql`
    fragment TopFieldPlayers_anyCard on AnyCardInterface {
      slug
    }
  ` as TypedDocumentNode<TopFieldPlayers_anyCard>,
  so5Appearance: gql`
    fragment TopFieldPlayers_so5Appearance on So5Appearance {
      id
      anyCard {
        slug
      }
    }
  ` as TypedDocumentNode<TopFieldPlayers_so5Appearance>,
};
