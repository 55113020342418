import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { FRONTEND_ASSET_HOST } from 'constants/assets';
import { unitMapping } from 'lib/style';

const Uppercase = styled.span`
  text-transform: uppercase;
`;

export const Country = ({
  size = 2,
  code,
  threeLetterCode,
}: {
  size?: keyof typeof unitMapping;
  code: string;
  threeLetterCode: string | null;
}) => {
  return (
    <Horizontal as="span">
      <img
        src={`${FRONTEND_ASSET_HOST}/flags/${code}.svg`}
        alt={threeLetterCode || code}
        style={{ height: unitMapping[size] }}
      />
      <Uppercase>{threeLetterCode || code}</Uppercase>
    </Horizontal>
  );
};
