import { ReactNode } from 'react';
import { SpringValue, animated } from 'react-spring';
import styled, { CSSProperties } from 'styled-components';

const Root = styled.div`
  position: relative;
  width: 100%;
  height: var(--gauge-height, var(--unit));
  padding: var(--gauge-bg-padding, 2px);
  background: var(
    --gauge-bg-color,
    linear-gradient(
      90deg,
      rgba(166, 249, 226, 0.1) 0%,
      rgba(44, 42, 42, 0.1) 19.27%,
      rgba(240, 182, 185, 0.1) 36.98%,
      rgba(166, 249, 226, 0.1) 62.18%,
      rgba(255, 255, 255, 0.1) 81.25%,
      rgba(200, 189, 251, 0.1) 100%
    )
  );
  border-radius: var(--gauge-radius, 3px);
`;

const Filled = styled(animated.div)`
  height: 100%;
  background: var(
    --gauge-fill-color,
    linear-gradient(
      90deg,
      #a6f9e2 0%,
      #ffffff 19.27%,
      #f0b6b9 36.98%,
      #a6f9e2 62.18%,
      #ffffff 81.25%,
      #c8bdfb 100%
    )
  );
  border-radius: inherit;
  max-width: 100%;
`;

interface SimpleFilledProps {
  percentage: SpringValue<string> | string;
  color?: string;
  bgColor?: string;
  bgPadding?: string;
  bgRadius?: string;
  height?: string;
}
interface CustomFilledProps {
  children: ReactNode;
}

type Props = CustomFilledProps | SimpleFilledProps;
export const Gauge = (props: Props) => {
  if ('children' in props) {
    const { children } = props;
    return <Root>{children}</Root>;
  }
  const { percentage, color, bgColor, bgRadius, bgPadding, height } = props;

  return (
    <Root
      style={
        {
          '--gauge-fill-color': color,
          '--gauge-bg-color': bgColor,
          '--gauge-bg-padding': bgPadding,
          '--gauge-radius': bgRadius,
          '--gauge-height': height,
        } as CSSProperties
      }
    >
      <Filled style={{ width: percentage }} />
    </Root>
  );
};
