import { useCallback, useEffect } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { useGenerateDialogPath } from '@sorare/core/src/hooks/navigation/useGenerateDialogPath';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { stripPrefix } from '@sorare/core/src/i18n/useTranslations';
import { HandleNativeAppMessage } from '@sorare/core/src/protos/webview/rpc/handle_native_app_message';

export const MobileAppInit = () => {
  const { isMobileApp } = useIsMobileApp();
  const navigate = useNavigate();
  const generateDialogPath = useGenerateDialogPath();

  const mobileNavigate = useCallback(
    (
      path: string | -1,
      options?: NavigateOptions & { scrollTop?: boolean }
    ) => {
      if (typeof path === 'string') {
        navigate(stripPrefix(path), options);
      } else {
        navigate(-1);
      }
      if (options?.scrollTop) {
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }
      return true;
    },
    [navigate]
  );

  useEffect(() => {
    if (!isMobileApp) {
      return;
    }

    // legacy navigation handler
    (window as any).mobileAppPushPath = mobileNavigate;

    // new generic message handler
    (window as any).mobileAppPostMessage = (
      message: HandleNativeAppMessage
    ) => {
      if (message.setPrivacyPreferences) {
        // FIXME(SU): implement privacy preferences
      } else if (message.navigateToPath) {
        mobileNavigate(message.navigateToPath.path!, {
          replace: message.navigateToPath.replace,
          scrollTop: message.navigateToPath.scrollTop,
        });
      } else if (message.showInstantBuyConfirmationDialog) {
        if (message.showInstantBuyConfirmationDialog.primaryOfferId) {
          navigate(
            generateDialogPath(
              DialogKey.primaryOfferBought,
              message.showInstantBuyConfirmationDialog.primaryOfferId
            )
          );
        }
      }

      return true;
    };
  }, [isMobileApp, mobileNavigate, navigate, generateDialogPath]);

  return null;
};
