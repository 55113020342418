import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useConnectionContext } from 'contexts/connection';
import { useCurrentUserContext } from 'contexts/currentUser';

import useTruncatedLocation from './useTruncatedLocation';

let retryCallback: (() => void) | undefined;
export default function useLoggedCallback<T>(
  callback: (arg: T) => void,
  executeCallbackAfterLoggedIn?: boolean,
  showSignup?: boolean
) {
  const { currentUser } = useCurrentUserContext();
  const { signIn, signUp } = useConnectionContext();
  const location = useTruncatedLocation();
  const navigate = useNavigate();

  const shouldExecuteCallback = !!(currentUser && executeCallbackAfterLoggedIn);
  useEffect(() => {
    if (shouldExecuteCallback) {
      retryCallback?.();
      retryCallback = undefined;
    }
  }, [shouldExecuteCallback, callback]);

  return (arg: T) => {
    if (currentUser) {
      callback(arg);
      retryCallback = undefined;
    } else {
      if (executeCallbackAfterLoggedIn) {
        retryCallback = () => callback(arg);
      }
      navigate(location, {
        state: {
          afterLoggedInTarget: location,
        },
        replace: true,
      });
      if (showSignup) {
        signUp();
      } else {
        signIn();
      }
    }
  };
}
