import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

import { GameStatus } from '@sorare/core/src/__generated__/globalTypes';
import { withFragments } from '@sorare/core/src/gql';
import useDocumentVisibility from '@sorare/core/src/hooks/useDocumentVisibility';

import useHasLiveGame from 'hooks/useHasLiveGame';

import { useLiveGamePollInterval_game } from './__generated__/useLiveGamePollInterval.graphql';

const useLiveGamePollInterval = withFragments(
  (
    livePollInterval: number,
    games: { date: Date; status: GameStatus }[],
    onIntervalChange: (interval: number) => void
  ) => {
    const ref = useRef<ReturnType<typeof setTimeout>>();
    const [pollInterval, setPollInterval] = useState(0);
    const hasLiveGame = useHasLiveGame(games);
    const isDocumentVisible = useDocumentVisibility();

    if (isDocumentVisible && hasLiveGame && pollInterval === 0) {
      setPollInterval(livePollInterval);
      ref.current = setTimeout(
        () => onIntervalChange(livePollInterval),
        livePollInterval
      );
    } else if ((!isDocumentVisible || !hasLiveGame) && pollInterval !== 0) {
      setPollInterval(0);
      onIntervalChange(0);
    }

    useEffect(
      () => () => {
        clearTimeout(ref.current);
      },
      []
    );
  },
  {
    game: gql`
      fragment useLiveGamePollInterval_game on Game {
        id
        status: statusTyped
      }
    ` as TypedDocumentNode<useLiveGamePollInterval_game>,
  }
);

export default useLiveGamePollInterval;
