/* eslint-disable no-restricted-properties */
import { TypedDocumentNode } from '@apollo/client';
import gqlMacro from 'graphql-tag.macro';
import qs from 'qs';
import { useEffect } from 'react';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { ORIGIN } from '@sorare/core/src/config';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import { stripPrefix } from '@sorare/core/src/i18n/useTranslations';

import {
  ConsumeEphemeralTokenMutation,
  ConsumeEphemeralTokenMutationVariables,
} from './__generated__/index.graphql';

const consumeMutation = gqlMacro`
  mutation ConsumeEphemeralTokenMutation($input: consumeEphemeralTokenInput!) {
    consumeEphemeralToken(input: $input) {
      currentUser {
        slug
      }
      redirectUrl
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  ConsumeEphemeralTokenMutation,
  ConsumeEphemeralTokenMutationVariables
>;

// We try to take a cross-tab lock to ensure that a single `consumeEphemeralToken` mutation is run at a time
// because our sign-in process is resetting the CSRF token, leading - in some cases - to mixed
// CSRF+session cookies invalidating the user session at the next GraphQL request.
const mightLock = async (fn: () => Promise<void>) => {
  if ('locks' in navigator && 'request' in navigator.locks) {
    return navigator.locks.request('consumeEphemeralToken', fn);
  }
  return fn();
};

interface Props {
  navigateTo: (path: string) => void;
}

export const isEphemeralLink = (pathname: string) => {
  return pathname === '/link' || pathname.startsWith('/link/');
};

export const getToken = () => {
  const { pathname, search } = window.location;
  if (pathname === '/link') {
    return qs.parse(search, { ignoreQueryPrefix: true }).token as string;
  }
  return pathname.slice('/link/'.length);
};

// This component is used to consume an ephemeral token and redirect the user to the appropriate page
// but runs outside of the normal routing system, so it doesn't use the `useNavigate` nor `useParams` hooks.
export const EphemeralLink = ({ navigateTo }: Props) => {
  const token = getToken();

  const [consume] = useMutation(consumeMutation, {
    showErrorsWithSnackNotification: true,
  });

  useEffect(() => {
    if (!token) {
      navigateTo('/');
      return;
    }

    mightLock(async () => {
      const { data } = await consume({
        variables: { input: { token } },
      });

      if (data?.consumeEphemeralToken?.currentUser) {
        const { redirectUrl } = data.consumeEphemeralToken;
        if (redirectUrl) {
          const url = new URL(redirectUrl, ORIGIN);
          return navigateTo(`${stripPrefix(url.pathname)}${url.search}`);
        }
        return navigateTo('/');
      }
      return navigateTo('/');
    });
  }, [consume, navigateTo, token]);

  return <LoadingIndicator fullHeight />;
};
