import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import usePaginatedQuery from '@sorare/core/src/hooks/graphql/usePaginatedQuery';
import useInfiniteScroll from '@sorare/core/src/hooks/useInfiniteScroll';

import LeaderboardRow from './LeaderboardRow';
import {
  CollectionLeaderboardQuery,
  CollectionLeaderboardQueryVariables,
  CollectionLeaderboard_userCardCollection,
} from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--triple-unit) 0;
`;
const LeaderboardsWrapper = styled.div`
  background-color: var(--c-neutral-200);
  border-radius: var(--double-unit);
`;

const COLLECTION_LEADERBOARD_QUERY = gql`
  query CollectionLeaderboardQuery($slug: String!, $cursor: String) {
    cardCollection(slug: $slug) {
      slug
      bestByScore(after: $cursor, first: 10) {
        nodes {
          id
          ...LeaderboardRow_userCardCollection
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${LeaderboardRow.fragments.userCardCollection}
` as TypedDocumentNode<
  CollectionLeaderboardQuery,
  CollectionLeaderboardQueryVariables
>;

type Props = {
  slug: string;
  slotsCount: number;
  myUserCardCollection: Nullable<CollectionLeaderboard_userCardCollection>;
};
const CollectionLeaderboard = ({
  slug,
  myUserCardCollection,
  slotsCount,
}: Props) => {
  const { data, loading, loadMore } = usePaginatedQuery(
    COLLECTION_LEADERBOARD_QUERY,
    {
      variables: {
        slug,
      },
      connection: 'UserCardCollectionConnection',
    }
  );
  const pageInfo = data?.cardCollection.bestByScore.pageInfo;
  const { InfiniteScrollLoader } = useInfiniteScroll(
    () => {
      loadMore(false, {
        slug,
        cursor: pageInfo?.endCursor,
      });
    },
    !!pageInfo?.hasNextPage,
    loading
  );

  if (loading || !data) {
    return (
      <Root>
        <LoadingIndicator small />
      </Root>
    );
  }

  const allUsersCardCollections = data.cardCollection.bestByScore.nodes;

  const addMyRankToLeaderboard =
    myUserCardCollection &&
    allUsersCardCollections.every(
      ({ user }) => user.slug !== myUserCardCollection.user.slug
    );

  return (
    <Root>
      <LeaderboardsWrapper>
        {allUsersCardCollections.map(userCardCollection => (
          <LeaderboardRow
            key={userCardCollection.id}
            userCardCollection={userCardCollection}
            slotsCount={slotsCount}
            collectionSlug={slug}
            highlighted={
              userCardCollection.user.slug === myUserCardCollection?.user.slug
            }
          />
        ))}
        <InfiniteScrollLoader />
        {addMyRankToLeaderboard && (
          <LeaderboardRow
            key={myUserCardCollection.id}
            userCardCollection={myUserCardCollection}
            slotsCount={slotsCount}
            highlighted
            collectionSlug={slug}
          />
        )}
      </LeaderboardsWrapper>
    </Root>
  );
};

CollectionLeaderboard.fragments = {
  userCardCollection: gql`
    fragment CollectionLeaderboard_userCardCollection on UserCardCollection {
      id
      user {
        slug
      }
      ...LeaderboardRow_userCardCollection
    }
    ${LeaderboardRow.fragments.userCardCollection}
  ` as TypedDocumentNode<CollectionLeaderboard_userCardCollection>,
};

export default CollectionLeaderboard;
