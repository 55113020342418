import { ReactNode, useState } from 'react';

import {
  CreditCardPaymentMethod,
  PaymentMethod as DepositMethod,
} from 'components/wallet/BankEthAccounting/AddFundsToFiatWallet/types';
import { useCurrentUserContext } from 'contexts/currentUser';
import { Lifecycle } from 'hooks/useLifecycle';

import DepositContextProvider from '.';

interface Props {
  children: ReactNode;
}

const DepositProvider = ({ children }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const preferredDepositMethod = (
    currentUser?.userSettings.lifecycle as Lifecycle
  )?.preferredDepositMethod;
  const [defaultDepositMethod, setDefaultDepositMethod] = useState<
    DepositMethod | undefined
  >(
    preferredDepositMethod !== CreditCardPaymentMethod.CREDIT_CARD
      ? preferredDepositMethod
      : undefined
  );

  return (
    <DepositContextProvider
      value={{ defaultDepositMethod, setDefaultDepositMethod }}
    >
      {children}
    </DepositContextProvider>
  );
};

export default DepositProvider;
