import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { Vertical } from 'atoms/layout/flex';
import { glossary } from 'lib/glossary';

import { CreateFiatWalletSteps } from '../type';
import { useBackButtonTargets } from '../useBackButtonTargets';

const Root = styled.div`
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const Content = styled(Vertical).attrs({ gap: 2 })`
  min-height: 100%;
`;
const StyledButtonBase = styled(ButtonBase)`
  text-decoration: underline;
`;

type StepWrapperFromPropsProps = {
  children: ReactNode;
  onBack?: () => void;
  submitButton: ReactNode;
};

export const StepWrapperFromProps = ({
  children,
  onBack,
  submitButton,
}: StepWrapperFromPropsProps) => {
  return (
    <Content>
      {children}
      <Root>
        {submitButton}
        {onBack && (
          <StyledButtonBase onClick={onBack}>
            <FormattedMessage {...glossary.back} />
          </StyledButtonBase>
        )}
      </Root>
    </Content>
  );
};

type Props = {
  step: CreateFiatWalletSteps;
  setStep: (step: CreateFiatWalletSteps) => void;
} & Omit<StepWrapperFromPropsProps, 'onBack'>;

export const StepWrapper = ({
  children,
  step,
  setStep,
  submitButton,
}: Props) => {
  const backTargets = useBackButtonTargets({});
  const backTarget = backTargets[step];
  return (
    <StepWrapperFromProps
      submitButton={submitButton}
      onBack={
        backTarget
          ? () => {
              setStep(backTarget);
            }
          : undefined
      }
    >
      {children}
    </StepWrapperFromProps>
  );
};
