import { DialogProps } from '@material-ui/core';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Title6 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';

export type Props = {
  title: ReactNode;
  onClose: () => void;
  content: ReactNode;
  actions: ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: boolean;
};

const Content = styled(Vertical).attrs({ gap: 2 })`
  padding: 0 var(--triple-unit);
`;
const Actions = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--triple-unit);
  > * {
    width: 100%;
  }
`;

const DirectOfferDialog = ({
  title,
  content,
  onClose,
  actions,
  maxWidth = 'md',
  fullWidth = false,
}: Props) => {
  const { up: tablet } = useScreenSize('tablet');

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen={!tablet}
      title={<Title6 className="text-center">{title}</Title6>}
      footer={<Actions>{actions}</Actions>}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <Content>{content}</Content>
    </Dialog>
  );
};

export default DirectOfferDialog;
