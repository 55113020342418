import { TypedDocumentNode, gql } from '@apollo/client';
import { faStopwatch } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Blockchain } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Caption } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIntlContext } from '@sorare/core/src/contexts/intl';

import TokenWithdrawalDialog from '../TokenWithdrawalDialog';
import { TokenWithdrawal_anyCard } from './__generated__/index.graphql';

const messages = defineMessages({
  transferInProgress: {
    id: 'BlockchainInfo.transferInProgress',
    defaultMessage: 'Transfer in progress',
  },
});

export interface Props {
  card: TokenWithdrawal_anyCard;
}

const TransferInProgress = styled(Button)`
  display: flex;
  gap: 5px;
  &:disabled {
    background-color: rgb(255, 201, 49, 0.25);
    color: #da8302;
    opacity: 1;
  }
`;

const TokenWithdrawal = ({ card }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { formatMessage } = useIntlContext();
  const { pendingWithdrawal, tokenOwner } = card;

  const inRollup = tokenOwner?.blockchain === Blockchain.STARKWARE;

  const renderTransferInProgress = () => {
    if (!pendingWithdrawal) return null;

    return (
      <>
        <TransferInProgress
          size="medium"
          disabled
          startIcon={<FontAwesomeIcon icon={faStopwatch} />}
        >
          {formatMessage(messages.transferInProgress)}
        </TransferInProgress>
        <Caption color="var(--c-neutral-600)">
          <FormattedMessage
            id="BlockchainInfo.duration"
            defaultMessage="Estimated duration 8-10 hours"
          />
        </Caption>
      </>
    );
  };

  const renderTransfer = () => {
    if (
      pendingWithdrawal ||
      !inRollup ||
      !card.tokenOwner ||
      card.tokenOwner.optimistic
    )
      return null;
    if (!card.user) return null;
    if (card.user?.slug !== currentUser?.slug) return null;

    return <TokenWithdrawalDialog card={card} />;
  };

  return (
    <>
      {renderTransfer()}
      {renderTransferInProgress()}
    </>
  );
};

TokenWithdrawal.fragments = {
  anyCard: gql`
    fragment TokenWithdrawal_anyCard on AnyCardInterface {
      slug
      tokenOwner {
        id
        blockchain
        optimistic
      }
      user {
        slug
      }
      pendingWithdrawal {
        id
      }
      ...TokenWithdrawalDialog_anyCard
    }
    ${TokenWithdrawalDialog.fragments.anyCard}
  ` as TypedDocumentNode<TokenWithdrawal_anyCard>,
};

export default TokenWithdrawal;
