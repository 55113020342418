import { Suspense, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';

import { So5LeaderboardType } from '@sorare/core/src/__generated__/globalTypes';
import { NBA_PLAY } from '@sorare/core/src/constants/routes';
import { useSeoContext } from '@sorare/core/src/contexts/seo';
import { useIsLoggedIn } from '@sorare/core/src/hooks/auth/useIsLoggedIn';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { lazy } from '@sorare/core/src/lib/retry';

import competeCardsAvif from './assets/compete/cards.avif';
import competeCards from './assets/compete/cards.png';
import fieldAvif from './assets/compete/field.avif';
import field from './assets/compete/field.jpg';
import metadataImage from './assets/public/metadataImage.jpg';
import scoutBackgroundAvif from './assets/scout/background.avif';
import scoutBackground from './assets/scout/background.jpg';
import scoutPlayersAvif from './assets/scout/players.avif';
import scoutPlayers from './assets/scout/players.png';
import winCardsAvif from './assets/win/cards.avif';
import winCards from './assets/win/cards.png';

const NbaPublicHome = lazy(async () => import('components/landing'));
const EarlyAccess = lazy(async () => import('components/landing/EarlyAccess'));

const messages = defineMessages({
  metadatasTitle: {
    id: 'Landing.NBA.metadatasTitle',
    defaultMessage:
      'Play fantasy basketball with ownable NBA player cards - Sorare',
  },
  metadatasDescription: {
    id: 'Landing.NBA.metadatasDescription',
    defaultMessage:
      "Play Sorare's free fantasy basketball game. Collect, buy, sell & compete with ownable digital player cards from all 30 NBA teams to win cash, ETH, tickets, more",
  },
});

const assets = {
  scout: {
    background: {
      avif: scoutBackgroundAvif,
      pictureUrl: scoutBackground,
    },
    players: { avif: scoutPlayers, pictureUrl: scoutPlayersAvif },
  },
  compete: {
    field: {
      avif: fieldAvif,
      pictureUrl: field,
    },
    cards: { avif: competeCardsAvif, pictureUrl: competeCards },
  },
  win: {
    cards: {
      avif: winCardsAvif,
      pictureUrl: winCards,
    },
  },
};

type Props = {
  isRoot?: boolean;
};

const NbaPage = ({ isRoot }: Props) => {
  const { flags: { useEarlyAccessLanding = false } = {} } = useFeatureFlags();
  const isLoggedIn = useIsLoggedIn();
  const { formatMessage } = useIntl();
  const { setPageMetadata } = useSeoContext();
  const track = useEvents();

  useEffect(
    () =>
      setPageMetadata(formatMessage(messages.metadatasTitle), {
        img: metadataImage,
        description: formatMessage(messages.metadatasDescription),
      }),
    [setPageMetadata, formatMessage]
  );

  useEffect(() => {
    if (!isLoggedIn) {
      track('View Homepage Disconnected', {
        landing_variant: useEarlyAccessLanding ? 'Nba Early Access' : 'Nba',
      });
    }
  }, [useEarlyAccessLanding, isLoggedIn, track]);

  if (!isLoggedIn) {
    return (
      <Suspense>
        {useEarlyAccessLanding ? (
          <EarlyAccess
            showFAQ={!isRoot}
            assets={assets}
            leaderboards={[
              So5LeaderboardType.NBA_LIMITED_CHAMPION,
              So5LeaderboardType.NBA_UNIQUE_CHAMPION,
              So5LeaderboardType.NBA_RARE_CHAMPION,
            ]}
          />
        ) : (
          <NbaPublicHome />
        )}
      </Suspense>
    );
  }

  return <Navigate to={NBA_PLAY} replace />;
};

export default NbaPage;
