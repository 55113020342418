import { TypedDocumentNode, gql } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { ScarcityIcon } from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Caption, LabelM } from '@sorare/core/src/atoms/typography';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { useProbabilisticBundleConfigSummary } from '@sorare/core/src/hooks/useProbabilisticBundleConfigSummary';
import { glossary } from '@sorare/core/src/lib/glossary';
import { mobileAndAbove } from '@sorare/core/src/style/mediaQuery';

import {
  PromoBanner_ProbabilisticBundleConfig,
  PromoBanner_PromotionalEvent,
  PromoBannersAuctionQuery,
  PromoBannersAuctionQueryVariables,
  PromoBannersPrimaryOfferQuery,
  PromoBannersPrimaryOfferQueryVariables,
  PromoBanners_PromotionalEventsInterface,
} from './__generated__/index.graphql';

const PromoBannersWrapper = styled(Vertical).attrs({ gap: 1 })`
  width: 100%;
`;

const Root = styled(Horizontal).attrs({ center: true, gap: 1 })`
  width: 100%;
  color: var(--c-neutral-100);
  padding: 2px 4px;

  background: linear-gradient(
    84.1deg,
    #f8d3da 0%,
    #b3a9f4 28.32%,
    #fbe9fb 54.01%,
    #4f94fd 100%
  );

  &.rounded {
    border-radius: var(--unit);
  }

  @media ${mobileAndAbove} {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-template-areas: 'gutter content button';
  }
  min-height: var(--quadruple-unit);
`;

const Content = styled(Horizontal).attrs({ center: true, gap: 1 })`
  grid-area: content;
`;

const ButtonContainer = styled.div`
  color: var(--c-neutral-1000);
  grid-area: button;
`;

const Text = styled(Horizontal).attrs({ gap: 0 })`
  flex-wrap: wrap;
  column-gap: var(--unit);
`;

const Terms = styled(Caption)`
  text-decoration: underline;
  color: inherit;
`;

type PromoBannerProps = {
  rounded?: boolean;
  count?: number;
  promotionalEvent: PromoBanner_PromotionalEvent;
  probabilisticBundleConfig: PromoBanner_ProbabilisticBundleConfig;
};

const PromoBanner = ({
  rounded,
  count = 1,
  promotionalEvent,
  probabilisticBundleConfig,
}: PromoBannerProps) => {
  const { termsAndConditionsUrl, blogUrl } = promotionalEvent;
  const summary = useProbabilisticBundleConfigSummary(
    probabilisticBundleConfig
  );

  const { locale } = useIntl();
  const formatter = new Intl.ListFormat(locale, {
    style: 'long',
    type: 'disjunction',
  });

  return (
    <Root className={classnames({ rounded })}>
      <Content>
        <LabelM bold>{count > 1 && `${count}x `}</LabelM>
        <ScarcityIcon size="md" scarcity={probabilisticBundleConfig.rarity} />
        <Text>
          <LabelM bold>
            <FormattedMessage
              id="PromoBanner.message"
              defaultMessage="Win {rewards} when buying"
              values={{
                rewards: formatter.format(summary),
              }}
            />
          </LabelM>

          {termsAndConditionsUrl && (
            <Terms
              rel="noopener noreferrer"
              target="_blank"
              href={termsAndConditionsUrl}
              as="a"
            >
              (<FormattedMessage {...glossary.termsApply} />)
            </Terms>
          )}
        </Text>
      </Content>
      {blogUrl && (
        <ButtonContainer>
          <IconButton
            href={blogUrl}
            externalLink
            small
            icon={faInfoCircle}
            color="transparent"
          />
        </ButtonContainer>
      )}
    </Root>
  );
};

PromoBanner.fragments = {
  PromotionalEvent: gql`
    fragment PromoBanner_PromotionalEvent on PromotionalEvent {
      slug
      blogUrl
      termsAndConditionsUrl
    }
  ` as TypedDocumentNode<PromoBanner_PromotionalEvent>,
  ProbabilisticBundleConfig: gql`
    fragment PromoBanner_ProbabilisticBundleConfig on ProbabilisticBundleConfig {
      id
      rarity
      ...useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig
    }
    ${useProbabilisticBundleConfigSummary.fragments.ProbabilisticBundleConfig}
  ` as TypedDocumentNode<PromoBanner_ProbabilisticBundleConfig>,
};

type Props = {
  rounded?: boolean;
  promotionalEvents: PromoBanners_PromotionalEventsInterface;
};

export const PromoBanners = ({ rounded, promotionalEvents }: Props) => {
  const groupedPromotions = promotionalEvents.eligiblePromotionalEvents.reduce<
    Record<
      string,
      {
        count: number;
        promotionalEvent: PromoBanners_PromotionalEventsInterface['eligiblePromotionalEvents'][number];
        reward: PromoBanners_PromotionalEventsInterface['eligibleRewardsProbabilisticBundleConfigs'][number];
      }
    >
  >((acc, promotionalEvent, index) => {
    if (!acc[promotionalEvent.slug]) {
      acc[promotionalEvent.slug] = {
        count: 1,
        promotionalEvent,
        reward:
          promotionalEvents.eligibleRewardsProbabilisticBundleConfigs[index],
      };
    } else {
      acc[promotionalEvent.slug].count += 1;
    }
    return acc;
  }, {});

  return (
    <PromoBannersWrapper>
      {Object.entries(groupedPromotions).map(
        ([slug, { count, promotionalEvent, reward }]) => (
          <PromoBanner
            key={slug}
            count={count}
            rounded={rounded}
            promotionalEvent={promotionalEvent}
            probabilisticBundleConfig={reward}
          />
        )
      )}
    </PromoBannersWrapper>
  );
};

PromoBanners.fragments = {
  PromotionalEventsInterface: gql`
    fragment PromoBanners_PromotionalEventsInterface on PromotionalEventsInterface {
      eligiblePromotionalEvents {
        slug
        ...PromoBanner_PromotionalEvent
      }
      eligibleRewardsProbabilisticBundleConfigs {
        id
        ...PromoBanner_ProbabilisticBundleConfig
      }
    }
    ${PromoBanner.fragments.PromotionalEvent}
    ${PromoBanner.fragments.ProbabilisticBundleConfig}
  ` as TypedDocumentNode<PromoBanners_PromotionalEventsInterface>,
};

const QUERY_AUCTION = gql`
  query PromoBannersAuctionQuery($auctionId: String!) {
    tokens {
      auction(id: $auctionId) {
        id
        ...PromoBanners_PromotionalEventsInterface
      }
    }
  }
  ${PromoBanners.fragments.PromotionalEventsInterface}
` as TypedDocumentNode<
  PromoBannersAuctionQuery,
  PromoBannersAuctionQueryVariables
>;

const QUERY_PRIMARY_OFFER = gql`
  query PromoBannersPrimaryOfferQuery($primaryOfferId: String!) {
    tokens {
      primaryOffer(id: $primaryOfferId) {
        id
        ...PromoBanners_PromotionalEventsInterface
      }
    }
  }
  ${PromoBanners.fragments.PromotionalEventsInterface}
` as TypedDocumentNode<
  PromoBannersPrimaryOfferQuery,
  PromoBannersPrimaryOfferQueryVariables
>;

export const PromoBannersIndependant = ({
  auctionId,
  primaryOfferId,
  rounded,
}: {
  auctionId?: string;
  primaryOfferId?: string;
  rounded?: boolean;
}) => {
  const { data: dataAuction } = useQuery(QUERY_AUCTION, {
    skip: !auctionId,
    variables: {
      auctionId: auctionId!,
    },
  });

  const { data: dataPrimaryOffer } = useQuery(QUERY_PRIMARY_OFFER, {
    skip: !primaryOfferId,
    variables: {
      primaryOfferId: primaryOfferId!,
    },
  });

  const promotionalEvents =
    dataAuction?.tokens.auction || dataPrimaryOffer?.tokens.primaryOffer;

  return promotionalEvents ? (
    <PromoBanners promotionalEvents={promotionalEvents} rounded={rounded} />
  ) : null;
};
