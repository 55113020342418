import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';

const StyledChip = styled(Chip)`
  background: var(--c-green-600);
  border-radius: 50%;
`;

export const ProbablePitcher = () => {
  const { formatMessage } = useIntl();
  return (
    <Tooltip
      title={formatMessage({
        id: 'ProbablePitcher.tooltip',
        defaultMessage: 'Probable Pitcher',
      })}
    >
      <StyledChip size="medium">
        <FormattedMessage id="ProbablePitcher.accronym" defaultMessage="PP" />
      </StyledChip>
    </Tooltip>
  );
};
