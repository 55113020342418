import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { groupBy } from '@sorare/core/src/lib/arrays';
import {
  largeDesktopAndAbove,
  tabletAndAbove,
} from '@sorare/core/src/style/mediaQuery';

import { LiveCardsInLineup } from './LiveCardsInLineup';
import { TeamPlayersStats } from './TeamPlayersStats';
import { TopFieldPlayers } from './TopFieldPlayers';
import {
  LiveGameView_anyCard,
  LiveGameView_anyGame,
  LiveGameView_basketballPlayerGameScore,
  LiveGameView_so5Fixture,
} from './__generated__/index.graphql';

const Wrapper = styled.div`
  margin: var(--double-unit) 0;
`;

const ResponsiveWrapper = styled.div<{ stretch?: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--quadruple-unit);
  flex-wrap: wrap;

  & > * {
    flex-basis: 100%;
    align-self: flex-start;
    border-radius: var(--double-unit);
    overflow: hidden;

    @media (${tabletAndAbove}) {
      flex-basis: calc(50% - var(--double-unit));
    }

    @media (${largeDesktopAndAbove}) {
      flex-basis: calc(50% - var(--double-unit));
    }
  }
`;

const SquaredPlayersResponsiveWrapper = styled(ResponsiveWrapper)`
  gap: var(--unit);

  @media (${tabletAndAbove}) {
    gap: var(--quadruple-unit);
  }

  & > * {
    flex-basis: 100%;
    align-self: stretch;
    overflow: hidden;

    @media (${tabletAndAbove}) {
      flex-basis: calc(50% - var(--double-unit));
    }
  }
`;

const Scrollable = styled.div``;

type Props = {
  fixture: LiveGameView_so5Fixture;
  game: LiveGameView_anyGame;
  allMyCards: LiveGameView_anyCard[];
  playerGameScores: LiveGameView_basketballPlayerGameScore[];
  openPlayerInfoModal: (playerSlug: string) => void;
  openCardInfoModal: (cardSlug: string) => void;
  setMouseOnHoverPlayer?: (playerSlug: string | null) => void;
  mouseOnHoverPlayer?: string | null;
  children?: ReactNode;
};

export const LiveGameView = ({
  fixture,
  game,
  allMyCards,
  playerGameScores,
  openPlayerInfoModal,
  openCardInfoModal,
  setMouseOnHoverPlayer,
  mouseOnHoverPlayer = null,
  children,
}: Props) => {
  const cardsInLineups = groupBy(
    fixture?.mySo5Lineups
      ?.filter(lineup => !lineup.so5Leaderboard?.trainingCenter)
      .flatMap(({ so5Appearances }) => so5Appearances) || [],
    card => card.anyCard!.anyPlayer?.slug
  );

  const unusedCards = groupBy(
    allMyCards.filter(
      card =>
        !(cardsInLineups[card.anyPlayer.slug] || []).find(
          c => card.slug === c.anyCard?.slug
        )
    ),
    card => card.anyPlayer?.slug
  );

  const [awayPlayerStats, homePlayerStats] = useMemo(() => {
    const formatted = playerGameScores.map(playerGameScore => ({
      playerGameScore,
      so5Appearances: cardsInLineups[playerGameScore.anyPlayer.slug],
      unusedCards: unusedCards[playerGameScore.anyPlayer.slug],
    }));

    return [
      formatted.filter(
        pS =>
          pS.playerGameScore.basketballPlayerGameStats.anyTeam.slug ===
          game.awayTeam?.slug
      ),
      formatted.filter(
        pS =>
          pS.playerGameScore.basketballPlayerGameStats.anyTeam.slug ===
          game.homeTeam?.slug
      ),
    ];
  }, [
    cardsInLineups,
    game.awayTeam?.slug,
    game.homeTeam?.slug,
    playerGameScores,
    unusedCards,
  ]);

  const onMouseHoverPlayer = setMouseOnHoverPlayer as (
    playerSlug: string
  ) => void;

  const onMouseLeavePlayer = useCallback(() => {
    setMouseOnHoverPlayer?.(null);
  }, [setMouseOnHoverPlayer]);

  return (
    <Wrapper>
      <Vertical gap={4}>
        <SquaredPlayersResponsiveWrapper>
          <TopFieldPlayers
            playerStats={awayPlayerStats}
            openPlayerInfoModal={openPlayerInfoModal}
            onMouseHoverPlayer={onMouseHoverPlayer}
            onMouseLeavePlayer={onMouseLeavePlayer}
            mouseOnHoverPlayer={mouseOnHoverPlayer}
          />
          <TopFieldPlayers
            playerStats={homePlayerStats}
            openPlayerInfoModal={openPlayerInfoModal}
            onMouseHoverPlayer={onMouseHoverPlayer}
            onMouseLeavePlayer={onMouseLeavePlayer}
            mouseOnHoverPlayer={mouseOnHoverPlayer}
          />
        </SquaredPlayersResponsiveWrapper>
        <Vertical gap={2}>
          <ResponsiveWrapper>
            <Vertical gap={2}>
              <Scrollable>
                <TeamPlayersStats
                  team={game.awayTeam!}
                  playedInGame
                  playerStats={awayPlayerStats}
                  openPlayerInfoModal={openPlayerInfoModal}
                  openCardInfoModal={openCardInfoModal}
                  onMouseHoverPlayer={onMouseHoverPlayer}
                  onMouseLeavePlayer={onMouseLeavePlayer}
                  mouseOnHoverPlayer={mouseOnHoverPlayer}
                />
              </Scrollable>
              <Scrollable>
                <TeamPlayersStats
                  team={game.awayTeam!}
                  playedInGame={false}
                  playerStats={awayPlayerStats}
                  openPlayerInfoModal={openPlayerInfoModal}
                  openCardInfoModal={openCardInfoModal}
                  onMouseHoverPlayer={onMouseHoverPlayer}
                  onMouseLeavePlayer={onMouseLeavePlayer}
                  mouseOnHoverPlayer={mouseOnHoverPlayer}
                />
              </Scrollable>
            </Vertical>
            <Vertical gap={2}>
              <Scrollable>
                <TeamPlayersStats
                  team={game.homeTeam!}
                  playedInGame
                  playerStats={homePlayerStats}
                  openPlayerInfoModal={openPlayerInfoModal}
                  openCardInfoModal={openCardInfoModal}
                  onMouseHoverPlayer={onMouseHoverPlayer}
                  onMouseLeavePlayer={onMouseLeavePlayer}
                  mouseOnHoverPlayer={mouseOnHoverPlayer}
                />
              </Scrollable>
              <Scrollable>
                <TeamPlayersStats
                  team={game.homeTeam!}
                  playedInGame={false}
                  playerStats={homePlayerStats}
                  openPlayerInfoModal={openPlayerInfoModal}
                  openCardInfoModal={openCardInfoModal}
                  onMouseHoverPlayer={onMouseHoverPlayer}
                  onMouseLeavePlayer={onMouseLeavePlayer}
                  mouseOnHoverPlayer={mouseOnHoverPlayer}
                />
              </Scrollable>
            </Vertical>
          </ResponsiveWrapper>

          <ResponsiveWrapper>
            <LiveCardsInLineup
              fixture={fixture}
              playerStats={awayPlayerStats}
              openCardInfoModal={openCardInfoModal}
              onMouseHoverPlayer={onMouseHoverPlayer}
              onMouseLeavePlayer={onMouseLeavePlayer}
            />

            <LiveCardsInLineup
              fixture={fixture}
              playerStats={homePlayerStats}
              openCardInfoModal={openCardInfoModal}
              onMouseHoverPlayer={onMouseHoverPlayer}
              onMouseLeavePlayer={onMouseLeavePlayer}
            />
          </ResponsiveWrapper>
        </Vertical>
        {children}
      </Vertical>
    </Wrapper>
  );
};

LiveGameView.fragments = {
  basketballPlayerGameScore: gql`
    fragment LiveGameView_basketballPlayerGameScore on BasketballPlayerGameScore {
      id
      ...TopFieldPlayers_basketballPlayerGameScore
      ...TeamPlayersStats_basketballPlayerGameScore
      ...LiveCardsInLineup_basketballPlayerGameScore
    }
    ${TopFieldPlayers.fragments.basketballPlayerGameScore}
    ${TeamPlayersStats.fragments.basketballPlayerGameScore}
    ${LiveCardsInLineup.fragments.basketballPlayerGameScore}
  ` as TypedDocumentNode<LiveGameView_basketballPlayerGameScore>,
  anyGame: gql`
    fragment LiveGameView_anyGame on AnyGameInterface {
      id
      awayTeam {
        slug
        name
        ...TeamPlayersStats_team
      }
      homeTeam {
        slug
        name
        ...TeamPlayersStats_team
      }
    }
    ${TeamPlayersStats.fragments.team}
  ` as TypedDocumentNode<LiveGameView_anyGame>,
  so5Fixture: gql`
    fragment LiveGameView_so5Fixture on So5Fixture {
      slug
      mySo5Lineups {
        id
        so5Leaderboard {
          slug
          trainingCenter
        }
        so5Appearances {
          id
          anyCard {
            slug
            anyPlayer {
              slug
            }
          }
          ...LiveCardsInLineup_so5Appearance
          ...TeamPlayersStats_so5Appearance
          ...TopFieldPlayers_so5Appearance
        }
      }

      ...LiveCardsInLineup_so5Fixture
    }
    ${LiveCardsInLineup.fragments.so5Fixture}
    ${TopFieldPlayers.fragments.so5Appearance}
    ${TeamPlayersStats.fragments.so5Appearance}
    ${LiveCardsInLineup.fragments.so5Appearance}
  ` as TypedDocumentNode<LiveGameView_so5Fixture>,
  anyCard: gql`
    fragment LiveGameView_anyCard on AnyCardInterface {
      slug
      ...TopFieldPlayers_anyCard
      ...TeamPlayersStats_anyCard
      ...LiveCardsInLineup_anyCard
    }
    ${TopFieldPlayers.fragments.anyCard}
    ${TeamPlayersStats.fragments.anyCard}
    ${LiveCardsInLineup.fragments.anyCard}
  ` as TypedDocumentNode<LiveGameView_anyCard>,
};
