import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { ValidWidths } from 'atoms/ui/ResponsiveImg';
import { Card } from 'components/card/Card';

import { StackedCards_anyCard } from './__generated__/index.graphql';

const ImagesContainer = styled.div`
  position: relative;
  isolation: isolate;

  > * {
    transition: transform 0.2s ease-in-out;
    max-width: 100%;
    border-radius: 10px;

    &:first-child {
      transform: scale(0.96);
      transform-origin: center top;
    }
    &:not(:first-child) {
      position: absolute;
      z-index: -1;
      inset: 0;
      transform-origin: center bottom;
    }
    /* first child when only 2 cards */
    &:first-child:nth-last-child(2) {
      transform: scale(0.98);
    }
    &:nth-child(2) {
      transform: scale(0.84);
      filter: brightness(60%);
    }
    /* last child when only 2 cards */
    &:last-child:nth-child(2) {
      transform: scale(0.9);
      filter: brightness(80%);
    }
    &:nth-child(3) {
      transform: translateY(-2%) scale(0.9);
      filter: brightness(80%);
    }
  }

  &:hover {
    > *:first-child {
      transform: translateY(calc(-1 * var(--half-unit))) scale(0.96);
    }
    /* first child when only 2 cards */
    > *:first-child:nth-last-child(2) {
      transform: translateY(calc(-1 * var(--half-unit))) scale(0.98);
    }
    > *:nth-child(3) {
      transform: translateY(calc(-2% - 2px)) scale(0.9);
    }
  }
`;

export const StackedCards = ({
  count,
  width = 320,
  card,
}: {
  count: number;
  width?: ValidWidths;
  card: StackedCards_anyCard;
}) => {
  return (
    <ImagesContainer>
      <Card width={width} card={card} />
      {count > 1 && <Card width={width} card={card} />}
      {count > 2 && <Card width={width} card={card} />}
    </ImagesContainer>
  );
};

StackedCards.fragments = {
  anyCard: gql`
    fragment StackedCards_anyCard on AnyCardInterface {
      slug
      ...Card_anyCard
    }
    ${Card.fragments.anyCard}
  ` as TypedDocumentNode<StackedCards_anyCard>,
};
