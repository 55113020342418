import { MutableRefObject, RefCallback } from 'react';

export function mergeRefs<T>(
  ...refs: Nullable<RefCallback<T> | MutableRefObject<T>>[]
) {
  return (instance: T | null) => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(instance as T);
      } else if (ref != null) {
        ref.current = instance as T;
      }
    });
  };
}
