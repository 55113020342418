import { User, setUser } from '@sentry/react';
import { ReactNode, useCallback } from 'react';

import { ErrorBoundary } from '@sorare/error-boundary';

import { sendSafeError } from 'lib/error';
import { FallbackComponent } from 'routing/FallbackComponent';

import SentryContextProvider from '.';

export const SentryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const identifyUser = useCallback((user: User | null) => {
    setUser(user);
  }, []);

  const sendSafeErrorCb = useCallback(sendSafeError, []);

  return (
    <SentryContextProvider
      value={{
        identifyUser,
        sendSafeError: sendSafeErrorCb,
      }}
    >
      <ErrorBoundary
        fallbackRender={error => <FallbackComponent error={error} />}
      >
        {children}
      </ErrorBoundary>
    </SentryContextProvider>
  );
};

export default SentryProvider;
