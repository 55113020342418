import { TypedDocumentNode, gql } from '@apollo/client';
import { Navigate, generatePath } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { useTypedParams } from '@sorare/routing';

import {
  MLB_PLAY_PRO_FIXTURE,
  MLB_PLAY_PRO_FIXTURE_POSTSEASON_USERGROUP_USER,
} from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { STORAGE } from '@sorare/core/src/hooks/useLocalStorage';
import { ScarcityType } from '@sorare/core/src/lib/scarcity';

import { getFixtureQueryVariables } from '@sorare/gameplay/src/lib/so5';

import { PageParams } from '../__generated__/routeParams';
import {
  BaseballPlayProFixturePostseasonQuery,
  BaseballPlayProFixturePostseasonQueryVariables,
} from './__generated__/page.graphql';

const query = gql`
  query BaseballPlayProFixturePostseasonQuery($type: So5State, $slug: String) {
    so5 {
      so5Fixture(type: $type, slug: $slug, sport: BASEBALL) {
        slug
        gameWeek
        so5Leaderboards {
          slug
          rarityType
          universalSo5UserGroups {
            slug
            rarityType
          }
        }
      }
    }
  }
` as TypedDocumentNode<
  BaseballPlayProFixturePostseasonQuery,
  BaseballPlayProFixturePostseasonQueryVariables
>;

const PlayProFixturePostseasonPage = () => {
  const [preferredScarcity, setPreferredScarcity] =
    useLocalStorage<ScarcityType>(
      STORAGE.mlbMonthlyChaseLastPreferredScarcity,
      'common'
    );
  const { fixture: fixtureSlug } = useTypedParams<PageParams>();
  const { currentUser } = useCurrentUserContext();

  const userSlug = currentUser?.slug || '';

  const { data } = useQuery(query, {
    variables: {
      ...getFixtureQueryVariables(fixtureSlug),
    },
  });

  const chaseLeaderboards =
    data?.so5.so5Fixture?.so5Leaderboards.filter(
      l => l.universalSo5UserGroups.length > 0
    ) ?? [];

  const userGroupSlug = chaseLeaderboards?.find(({ rarityType }) => {
    return rarityType === preferredScarcity;
  })?.universalSo5UserGroups[0]?.slug;

  // no chase leaderboard found, redirect to main lobby
  if ((data && chaseLeaderboards.length === 0) || !userSlug) {
    return (
      <Navigate
        to={generatePath(MLB_PLAY_PRO_FIXTURE, { fixture: fixtureSlug })}
        replace
      />
    );
  }

  if (chaseLeaderboards.length > 0 && !userGroupSlug) {
    setPreferredScarcity(chaseLeaderboards[0]?.rarityType as ScarcityType);
  }

  // chase leaderboard found, redirect to Chase lobby
  if (userGroupSlug) {
    return (
      <Navigate
        to={generatePath(MLB_PLAY_PRO_FIXTURE_POSTSEASON_USERGROUP_USER, {
          fixture: fixtureSlug,
          userGroup: userGroupSlug,
          user: userSlug,
        })}
        replace
      />
    );
  }

  return null;
};

export default PlayProFixturePostseasonPage;
