import styled from 'styled-components';

export const ScarcityColoredDiv = styled.div`
  &.common {
    background: var(--c-gradient-common);
  }
  &.limited {
    background: var(--c-gradient-limited);
  }
  &.rare {
    background: var(--c-gradient-rare);
  }
  &.superRare,
  &.super_rare {
    background: var(--c-gradient-superRare);
  }
  &.unique {
    background: #feecb3;
  }
`;
