import styled from 'styled-components';

import { Container } from 'atoms/layout/Container';
import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { hideScrollbar } from 'style/utils';

export const FullViewportScroll = styled.div`
  ${hideScrollbar}
  display: flex;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  ${Container} &, ${FullWidthContainer} & {
    margin: 0 calc(-1 * var(--container-padding));
    padding: 0 var(--container-padding);
    scroll-padding-inline: var(--container-padding);
  }
`;
