import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';

import { Ineligible } from '@sorare/core/src/atoms/icons/Ineligible';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';

import TooltipContent from '../TooltipContent';

const titleMessages = defineMessages({
  partial: {
    id: 'So5Eligibility.partial',
    defaultMessage: 'Sorare Pro Partially eligible',
  },
  ineligible: {
    id: 'So5Eligibility.ineligible',
    defaultMessage: 'Sorare Pro Ineligible',
  },
});

type Props = {
  partial?: boolean;
  description: MessageDescriptor;
};

export const So5Eligibility = ({ description, partial }: Props) => {
  const { formatMessage } = useIntl();
  const title = partial ? titleMessages.partial : titleMessages.ineligible;

  return (
    <Tooltip title={<TooltipContent title={title} description={description} />}>
      <Ineligible title={formatMessage(title)} blue={partial} />
    </Tooltip>
  );
};

export default So5Eligibility;
