import { To } from 'react-router-dom';

import { hasPreviousLocation } from 'lib/routing';

export const getSafePreviousLocation = (
  defaultPreviousLocation: string | To,
  delta?: number
) => {
  const backDelta = delta || -1;

  if (hasPreviousLocation(backDelta)) {
    return backDelta as To;
  }
  return defaultPreviousLocation;
};
