import { matchPath } from 'react-router-dom';

import { Sport as SportType } from '__generated__/globalTypes';
import {
  ACTIVITY_NEWS_ID,
  FOOTBALL_PLAY_PRO_FIXTURE_MATCH_CENTER,
} from 'constants/__generated__/routes';

import { ANY_SPORT_MY_CLUB_CHALLENGES, INVITE } from '../constants/routes';

// This is a helper function used to define what to pass to Segments' .page() function
// which can then be used to explicitly map to an AdWords Page Load conversion
export const getPageName = (pathname: string): string | undefined => {
  if (matchPath(INVITE, pathname)) {
    return 'Referrals';
  }
  if (matchPath(ACTIVITY_NEWS_ID, pathname)) {
    return 'News';
  }
  if (matchPath(ANY_SPORT_MY_CLUB_CHALLENGES, pathname)) {
    return 'Challenges';
  }
  if (matchPath(FOOTBALL_PLAY_PRO_FIXTURE_MATCH_CENTER, pathname)) {
    return 'Match Center';
  }
  return undefined;
};

export const socialShareEventName = {
  SHARE_CARD: 'Share Card',
  SHARE_LINEUP: 'Share Lineup',
  SHARE_USER_GROUP: 'Share User Group',
  SHARE_SQUAD: 'Share Squad',
  SHARE_COLLECTION: 'Share Collection',
  SHARE_REFERRAL: 'Share Referral Link',
  SHARE_AUCTION_WON: 'Share Auction Won',
  SHARE_MANAGER_PROFILE: 'Share Manager Profile',
  SHARE_GALLERY: 'Share Gallery',
  SHARE_HIGHLIGHTED_DECK: 'Share Highlighted Deck',
  SHARE_RIVALS_WAITLIST: 'Share Rivals Waitlist',
  SHARE_RIVALS_INVITE: 'Share Rivals Invite',
  SHARE_BLOG_POST: 'Share Blog Post',
  SHARE_RIVALS_RECAP: 'Share Rivals Single Recap',
} as const;

export type SocialShareEventName =
  (typeof socialShareEventName)[keyof typeof socialShareEventName];

export const socialShareEventContext = {
  COMPETITION_DETAILS: 'Competition Details',
  LEADERBOARD: 'Leaderboard',
  MY_TEAMS: 'My Teams',
  LIVE_PRO: 'Live Pro',
  LINEUP_BUTTONS: 'Lineup buttons',
  COLLECTION: 'Collection Page',
  USER_GROUP_DETAILS: 'User Group Details',
  USER_GROUP_CONGRATS: 'User Group Congratulations Dialog',
  USER_GROUP_INVITE_FRIENDS: 'User Group Invite Friends',
  REFERRAL_PROGRAM: 'referral_program',
  HERO_HOMEPAGE: 'hero_homepage',
  ONBOARDING: 'onboarding',
  SQUAD: 'squad',
  HOME_DASHBOARD: 'Home Dashboard',
  AUCTION_WON: 'Auction Won Dialog',
  CARD_PAGE: 'Card Page',
  MANAGER_PROFILE: 'Manager Profile',
  RIVALS_OVERVIEW: 'Rivals overview',
  RIVALS_MATCHUPS: 'Rivals matchups',
  RIVALS_ARENA: 'Rivals Arena',
  RIVALS_WAITLIST: 'Rivals Waitlist',
  RIVALS_FIRST_DRAFT: 'Rivals first draft',
  RIVALS_ARENA_TICKET_DAILY_CLAIM: 'Rivals arena ticket daily claim',
  RIVALS_LEAGUES: 'Rivals Leagues',
  BLOG: 'Blog',
  RIVALS_STORY: 'Rivals Story',
} as const;

const FOOTBALL = 'football';

export type SocialShareEventContext =
  (typeof socialShareEventContext)[keyof typeof socialShareEventContext];

export const shareByCopyLinkEvent = (
  name: SocialShareEventName,
  context: SocialShareEventContext,
  sport?: SportType
) => ({
  name,
  properties: {
    sport: sport ?? FOOTBALL,
    target: 'copy_link',
    context,
  },
});

export const shareByImageEvent = (
  name: SocialShareEventName,
  context: SocialShareEventContext,
  sport?: SportType
) => ({
  name,
  properties: {
    sport: sport ?? FOOTBALL,
    target: 'image',
    context,
  },
});

export const shareOnTwitterEvent = (
  name: SocialShareEventName,
  context: SocialShareEventContext,
  sport?: SportType
) => ({
  name,
  properties: {
    sport: sport ?? FOOTBALL,
    target: 'twitter',
    context,
  },
});

export const shareOnFacebookEvent = (
  name: SocialShareEventName,
  context: SocialShareEventContext,
  sport?: SportType
) => ({
  name,
  properties: {
    sport: sport ?? FOOTBALL,
    target: 'facebook',
    context,
  },
});

export const shareOnWhatsappEvent = (
  name: SocialShareEventName,
  context: SocialShareEventContext,
  sport?: SportType
) => ({
  name,
  properties: {
    sport: sport ?? FOOTBALL,
    target: 'whatsapp',
    context,
  },
});

export const shareWithShareSheetEvent = (
  name: SocialShareEventName,
  context: SocialShareEventContext,
  sport?: SportType
) => ({
  name,
  properties: {
    sport: sport ?? FOOTBALL,
    target: 'share_sheet',
    context,
  },
});
