import { TypedDocumentNode, gql } from '@apollo/client';

import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import {
  AddAuctionReminderMutation,
  AddAuctionReminderMutationVariables,
} from './__generated__/useAddReminder.graphql';

const ADD_REMINDER = gql`
  mutation AddAuctionReminderMutation($input: addAuctionReminderInput!) {
    addAuctionReminder(input: $input) {
      auction {
        id
        myReminder {
          id
          enabled
        }
      }
      errors {
        code
        message
      }
    }
  }
` as TypedDocumentNode<
  AddAuctionReminderMutation,
  AddAuctionReminderMutationVariables
>;

type Props = {
  auction: {
    id: string;
  };
};

export const useAddReminder = () => {
  const [addReminder, { loading }] = useMutation(ADD_REMINDER, {
    showErrorsWithSnackNotification: true,
  });

  return {
    addReminder: async ({ auction }: Props) => {
      const { data } = await addReminder({
        variables: { input: { tokenAuctionId: auction.id } },
      });
      return data?.addAuctionReminder?.auction?.myReminder;
    },
    loading,
  };
};
