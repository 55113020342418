import { useLayoutEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { QSUTMKeys, keys, storageFactory } from './storage';

// this implements a last-ever attribution model (last touchpoint gets the credit)
export const PersistsQueryStringParameters = () => {
  const [searchParams] = useSearchParams();
  const storage = useRef(storageFactory());

  useLayoutEffect(() => {
    // if any UTM params are present, clear former ones from local storage first to avoid merging them together
    if (QSUTMKeys.some(key => searchParams.get(key))) {
      QSUTMKeys.forEach(key => storage.current.remove(keys[key]));
    }

    Object.entries(keys).forEach(([qsKey, lskey]) => {
      const value = searchParams.get(qsKey);
      if (value) {
        storage.current.set(lskey, value.toString());
      }
    });
  }, [searchParams]);

  return null;
};
