import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { useEvents } from '@sorare/core/src/lib/events/useEvents';

import { tokenInfoProperties } from 'lib/tokenEvents';

import { useClickCardEvent_anyCard } from './__generated__/useClickCardEvent.graphql';

export const useClickCardEvent = () => {
  const track = useEvents();
  const trackClickCard = useCallback(
    (
      card: Omit<useClickCardEvent_anyCard, '__typename'>,
      secondary: boolean
    ) => {
      track('Click Card', {
        ...tokenInfoProperties(card),
        secondary,
      });
    },
    [track]
  );
  return trackClickCard;
};

useClickCardEvent.fragments = {
  anyCard: gql`
    fragment useClickCardEvent_anyCard on AnyCardInterface {
      slug
      sport
      rarityTyped
    }
  ` as TypedDocumentNode<useClickCardEvent_anyCard>,
};
