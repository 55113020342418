import { useOutletContext } from 'react-router-dom';

import { InventoryBlueprintTypeQuery } from './__generated__/index.graphql';

type LayoutData = {
  blueprints: NonNullable<
    InventoryBlueprintTypeQuery['currentUser']
  >['blueprintCards']['nodes'];
  currentIndex: number;
  setCurrentIndex: (index: number | ((index: number) => number)) => void;
  nextCard: () => void;
  setShowSummary: (show: boolean) => void;
  currentUser: InventoryBlueprintTypeQuery['currentUser'];
  loading: boolean;
};

export const useInventoryRevealableCardsTypeLayoutData = () => {
  return useOutletContext<LayoutData>();
};
