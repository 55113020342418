import { TypedDocumentNode, gql } from '@apollo/client';

import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';

import { BundledAuctionPreview } from 'components/auction/BundledAuctionPreview';
import FlexCard from 'components/token/FlexCard';
import StackedToken from 'components/token/StackedToken';

import { TokenImg_anyCard } from './__generated__/index.graphql';

type Props = {
  card: TokenImg_anyCard;
  stackedTokensCount?: number;
  isBundledAuction?: boolean;
  isDesktopLayout: boolean;
};

export const TokenImg = ({
  card,
  stackedTokensCount,
  isBundledAuction,
  isDesktopLayout,
}: Props) => {
  const imageWidth = isDesktopLayout ? 320 : 160;

  const { isIosApp, canUseIosNativeCardPage } = useIsMobileApp();

  if (stackedTokensCount && stackedTokensCount > 1) {
    return (
      <StackedToken card={card} count={stackedTokensCount} width={imageWidth} />
    );
  }
  if (card.latestEnglishAuction && isBundledAuction) {
    return (
      <BundledAuctionPreview
        bundledAuction={card.latestEnglishAuction}
        displayOneCard={!isDesktopLayout}
        noMargin
      />
    );
  }

  return (
    <FlexCard
      card={card}
      width={imageWidth}
      withLink={canUseIosNativeCardPage || !isIosApp}
    />
  );
};

TokenImg.fragments = {
  anyCard: gql`
    fragment TokenImg_anyCard on AnyCardInterface {
      slug
      latestEnglishAuction {
        id
        cards: anyCards {
          slug
        }
        ...BundledAuctionPreview_auction
      }
      ...FlexCard_anyCard
      ...StackedToken_anyCard
    }
    ${FlexCard.fragments.anyCard}
    ${StackedToken.fragments.anyCard}
    ${BundledAuctionPreview.fragments.auction}
  ` as TypedDocumentNode<TokenImg_anyCard>,
};
