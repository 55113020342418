import { ReactNode, Suspense, useState } from 'react';

import CancelSalePopin from 'components/offer/CancelSalePopin';

import SingleSaleOfferContextProvider, { SingleSaleOfferPopinInfo } from '.';

interface Props {
  children: ReactNode;
}

const SingleSaleOfferProvider = ({ children }: Props) => {
  const [popinInfo, setPopinInfo] = useState<SingleSaleOfferPopinInfo | null>(
    null
  );

  return (
    <SingleSaleOfferContextProvider
      value={{
        showPopin: setPopinInfo,
      }}
    >
      {popinInfo && (
        <Suspense fallback={null}>
          <CancelSalePopin
            slug={popinInfo.slug}
            onClose={() => setPopinInfo(null)}
          />
        </Suspense>
      )}
      {children}
    </SingleSaleOfferContextProvider>
  );
};

export default SingleSaleOfferProvider;
