import { createContext, useContext } from 'react';

import { PaymentMethod as DepositMethod } from 'components/wallet/BankEthAccounting/AddFundsToFiatWallet/types';

export interface DepositContextType {
  defaultDepositMethod?: DepositMethod;
  setDefaultDepositMethod: (mounted: DepositMethod) => void;
}

export const depositContext = createContext<DepositContextType | null>(null);

export const useDepositContext = () => useContext(depositContext)!;

export default depositContext.Provider;
