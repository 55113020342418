import { TypedDocumentNode, gql } from '@apollo/client';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text14, Title3 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';

import BidResume from './BidResume';
import { BidConfirmedDialogContent_tokenAuction } from './__generated__/index.graphql';

const messages = defineMessages({
  title: {
    id: 'BidConfirmedDialogContent.title',
    defaultMessage: 'Score! You are the highest bidder.',
  },
  autoBidExplanation: {
    id: 'BidConfirmedDialogContent.autoBidExplanation',
    defaultMessage:
      'Sorare will continue to bid up on your behalf until your max bid is reached.',
  },
  buttonLabel: {
    id: 'BidConfirmedDialogContent.buttonLabel',
    defaultMessage: 'Got it!',
  },
});

const Content = styled(Vertical).attrs({ gap: 2 })`
  padding: 0 var(--triple-unit) var(--triple-unit) var(--triple-unit);
`;
const CheckContainer = styled(Horizontal).attrs({ gap: 0, center: true })`
  align-self: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--c-green-600);
`;
const LeftAlignText = styled(Text14)`
  text-align: left;
`;
const CheckIcon = styled(FontAwesomeIcon)`
  color: var(--c-nd-100-opaque);
`;

type Props = {
  auction: BidConfirmedDialogContent_tokenAuction;
  open: boolean;
  assetsPreview?: ReactNode;
  onClose: () => void;
};
const BidConfirmedDialog = ({
  auction,
  assetsPreview,
  open,
  onClose,
}: Props) => (
  <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
    <Content>
      <CheckContainer>
        <CheckIcon icon={faCheck} size="2x" />
      </CheckContainer>
      <Title3 className="text-center">
        <FormattedMessage {...messages.title} />
      </Title3>
      <BidResume auction={auction} />
      {auction.autoBid && (
        <LeftAlignText>
          <FormattedMessage {...messages.autoBidExplanation} />
        </LeftAlignText>
      )}
      {assetsPreview}
      <Button onClick={onClose} size="medium" color="primary">
        <FormattedMessage {...messages.buttonLabel} />
      </Button>
    </Content>
  </Dialog>
);

BidConfirmedDialog.fragments = {
  tokenAuction: gql`
    fragment BidConfirmedDialogContent_tokenAuction on TokenAuction {
      id
      autoBid
      ...BidResume_tokenAuction
    }
    ${BidResume.fragments.tokenAuction}
  ` as TypedDocumentNode<BidConfirmedDialogContent_tokenAuction>,
};

export default BidConfirmedDialog;
