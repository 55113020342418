import {
  FixtureState,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { Avatar_user } from '@sorare/core/src/components/user/Avatar/__generated__/index.graphql';
import { LobbyRarity, rarityName } from '@sorare/core/src/lib/scarcity';

export const getLeaderboardScarcityFromSlug = (
  slug?: string
): LobbyRarity | undefined => {
  if (!slug) return undefined;
  return (Object.keys(rarityName) as LobbyRarity[]).find(s => {
    let scarcity: string = s;
    if (s === 'super_rare') scarcity = 'super-rare';
    if (s === 'rare_pro') scarcity = 'rare-pro';
    return slug.includes(scarcity);
  });
};

export const RANKED_SCARCITY = {
  common: 1,
  training: 2,
  mix: 3,
  limited: 4,
  rare: 5,
  rare_pro: 6,
  super_rare: 7,
  unique: 8,
  custom_series: 9,
};

export const isFixtureStarted = (fixture: {
  fixtureState: FixtureState;
}): boolean =>
  [FixtureState.started, FixtureState.closed].includes(fixture.fixtureState);

export const isFixtureClosed = (fixture: {
  fixtureState: FixtureState;
}): boolean => fixture.fixtureState === FixtureState.closed;

export type UsSport = Exclude<Sport, typeof Sport.FOOTBALL>;

export const calculateScoreWithBonus = (score: number, bonus: number) => {
  return {
    score: score > 0 ? score * bonus : score,
    bonus: score > 0 ? score * bonus : 0,
  };
};

export const convertToAvatarUser = (user: {
  slug: string;
  nickname: string;
  suspended?: boolean;
  avatarUrl?: string | null;
}) => {
  const { slug, nickname, suspended = false, avatarUrl = null } = user;
  return { slug, nickname, suspended, pictureUrl: avatarUrl } as Avatar_user;
};

export const LEADERBOARDS_WITH_SPECIAL_EDITION_CARD_REWARDS = [
  // staging
  'nba-2023-gameweek-49-common-xmas-day',
  'nba-2023-gameweek-49-limited-xmas-day',
  'nba-2023-gameweek-49-rare-xmas-day',
  'nba-2023-gameweek-49-super-rare-xmas-day',
  'nba-2023-gameweek-49-unique-xmas-day',
  // prod
  'nba-2023-gameweek-18-common-xmas-day',
  'nba-2023-gameweek-18-limited-xmas-day',
  'nba-2023-gameweek-18-rare-xmas-day',
  'nba-2023-gameweek-18-super-rare-xmas-day',
  'nba-2023-gameweek-18-unique-xmas-day',
];
