export enum CreateFiatWalletSteps {
  ACTIVATE_YOUR_CASH_WALLET = 'activateYourCashWallet',
  WHATS_NEW = 'whatsNew',
  INTRO = 'intro',
  TELL_US_ABOUT_YOU = 'tellUsAboutYou',
  CHOOSE_CURRENCY = 'chooseCurrency',
  ACTIVATION_SUCCESS = 'success',
  CHOOSE_DOCUMENT = 'chooseDocument',
  UPLOAD = 'upload',
  HANDLE_ID_REVIEW_ERROR = 'handleIdReviewError',
  DOCUMENT_UNDER_REVIEW = 'documentUnderReview',
  REVIEW_INFO_BEFORE_ADDING_ID = 'reviewInfoBeforeAddingId',
  REVIEW_INFORMATION = 'reviewInformation',
}

export const OrderedSteps = {
  [CreateFiatWalletSteps.ACTIVATE_YOUR_CASH_WALLET]: 1,
  [CreateFiatWalletSteps.WHATS_NEW]: 1,
  [CreateFiatWalletSteps.HANDLE_ID_REVIEW_ERROR]: 1,
  [CreateFiatWalletSteps.INTRO]: 2,
  [CreateFiatWalletSteps.TELL_US_ABOUT_YOU]: 3,
  [CreateFiatWalletSteps.CHOOSE_CURRENCY]: 4,
  [CreateFiatWalletSteps.REVIEW_INFORMATION]: 5,
  [CreateFiatWalletSteps.ACTIVATION_SUCCESS]: 6,
  [CreateFiatWalletSteps.REVIEW_INFO_BEFORE_ADDING_ID]: 7,
  [CreateFiatWalletSteps.CHOOSE_DOCUMENT]: 8,
  [CreateFiatWalletSteps.UPLOAD]: 9,
  [CreateFiatWalletSteps.DOCUMENT_UNDER_REVIEW]: 10,
};
