import { useEffect, useState } from 'react';

import { useVirtualToggleManager } from 'hooks/useVirtualToggleManager';

import { ExtendedIndexUIState } from '../types';

export const InitialVirtualToggleHandler = ({
  initialIndexUIState,
}: {
  initialIndexUIState: ExtendedIndexUIState;
}) => {
  const [defaultStateProcessed, setDefaultStateProcessed] = useState(
    initialIndexUIState === undefined
  );
  const setVirtualToggle = useVirtualToggleManager();

  useEffect(() => {
    if (!defaultStateProcessed) {
      setDefaultStateProcessed(true);
      setVirtualToggle({ ...initialIndexUIState.virtualToggle }, false);
    }
  }, [defaultStateProcessed, setVirtualToggle, initialIndexUIState]);

  return null;
};
