import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { HeadlineL, HeadlineXS } from 'atoms/typography';

import { FileUploadStep, Props } from '../FileUploadStep';

const List = styled.ul`
  margin: 0;
  padding-left: var(--triple-unit);

  li {
    list-style-type: disc;
  }
`;

export const ProofOfAddressUpload = (
  props: Omit<Props, 'children' | 'name'>
) => {
  return (
    <FileUploadStep name="proofOfAddress" {...props}>
      <HeadlineL>
        <FormattedMessage
          id="UnblockWallet.ProofOfAddressUpload.title"
          defaultMessage="Upload a proof of address"
        />
      </HeadlineL>
      <HeadlineXS>
        <FormattedMessage
          id="UnblockWallet.ProofOfAddressUpload.description"
          defaultMessage="The following proofs of address are accepted and must be dated within the last 3 months:"
        />
      </HeadlineXS>
      <List>
        <li>
          <FormattedMessage
            id="UnblockWallet.ProofOfAddressUpload.governmentIssuedLetter"
            defaultMessage="Government-issued letter"
          />
        </li>
        <li>
          <FormattedMessage
            id="UnblockWallet.ProofOfAddressUpload.utilityBill"
            defaultMessage="Utility bill (energy, water, internet, home insurance, etc. - no mobile phones)"
          />
        </li>
        <li>
          <FormattedMessage
            id="UnblockWallet.ProofOfAddressUpload.bankStatement"
            defaultMessage="Bank statement (except digital bank)"
          />
        </li>
        <li>
          <FormattedMessage
            id="UnblockWallet.ProofOfAddressUpload.councilTaxBill"
            defaultMessage="Certificate of residence issued by the local council."
          />
        </li>
      </List>
    </FileUploadStep>
  );
};
