import { stringify } from 'qs';
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { OnBackForgotPassword } from '@sorare/wallet-shared';
import { Sport, TermsAndConditionsStatus } from '__generated__/globalTypes';
import { GoogleReCAPTCHA, ReCAPTCHA } from 'components/recaptcha';
import { TERMS_AND_CONDITIONS } from 'constants/__generated__/routes';
import {
  OAUTH_LOCKED_ACCOUNT_ERROR_CODE,
  OAUTH_MISSING_EMAIL_ERROR_CODE,
} from 'constants/errors';
import { useCurrentUserContext } from 'contexts/currentUser';
import { Level, useSnackNotificationContext } from 'contexts/snackNotification';
import { useMessagingContext, useWalletContext } from 'contexts/wallet';
import useQueryString from 'hooks/useQueryString';
import useToggle from 'hooks/useToggle';
import { useEvents } from 'lib/events/useEvents';

import ConnectionContextProvider, {
  AcceptTermsInfo,
  Mode,
  PopMode,
  Prompt2faCallback,
  PromptTermsCallback,
  PromptTermsOptions,
  isConnectionMode,
} from '.';
import ConnectionDialog, { isConnectionDialogMode } from './ConnectionDialog';
import NewDeviceDialog from './NewDeviceDialog';
import PasswordForgottenDialog from './PasswordForgottenDialog';
import PromptTermsDialog from './PromptTermsDialog';
import PromptTwoFactAuthDialog from './PromptTwoFactAuthDialog';
import ResetPasswordDialog from './ResetPasswordDialog';

interface Props {
  children: ReactNode;
}

const INITIAL_TERMS_STATUS = TermsAndConditionsStatus.INITIAL.toLowerCase();

const ConnectionProvider = ({ children }: Props) => {
  const action = useQueryString('action');
  const code = useQueryString('code');
  const id = useQueryString('id');
  const [mode, setMode] = useState<Mode | null>(null);
  const [defaultSport, setDefaultSport] = useState<Sport>();
  const [popMode, setPopMode] = useState<PopMode | null>(null);
  const [isMobileWebviewSignUp, setIsMobileWebviewSignUp] =
    useState<boolean>(false);
  const challengeFromQuery = useQueryString('otp_session_challenge');
  const [otpSessionChallenge, setOtpSessionChallenge] =
    useState(challengeFromQuery);
  const tcuToken = useQueryString('tcuToken');
  const termStatus = useQueryString('termsStatus');
  const { showNotification } = useSnackNotificationContext();

  const navigate = useNavigate();

  const { currentUser } = useCurrentUserContext();
  const [passwordForgotten, togglePasswordForgotten, setPasswordForgotten] =
    useToggle(false);
  const [prompt2faCallback, setPrompt2faCallback] =
    useState<Prompt2faCallback | null>(null);
  const [promptTermsCallback, setPromptTermsCallback] =
    useState<PromptTermsCallback | null>(null);
  const [reasonFor2Fa, setReasonFor2FA] = useState<string | undefined>();
  const [promptTermsOptions, setPromptTermsOptions] =
    useState<PromptTermsOptions>({});
  const [acceptedTerms, setAcceptedTerms] = useState<AcceptTermsInfo | null>(
    null
  );
  const { passwordForgotten: promptPasswordForgotten } = useWalletContext();

  const recaptchaRef = useRef<GoogleReCAPTCHA>(null);

  const { registerHandler } = useMessagingContext();
  const track = useEvents();

  const signIn = useCallback(() => {
    track('Click Sign In');
    setAcceptedTerms(null);
    return setMode('signin');
  }, [track]);

  const signUp = useCallback(
    (e?: SyntheticEvent, sport?: Sport) => {
      setDefaultSport(sport);
      track('Click Sign Up', { variation: '' });
      setAcceptedTerms(null);
      return setMode('signup');
    },
    [track]
  );

  const showMobileWebviewSignUpFlow = useCallback(() => {
    setAcceptedTerms(null);
    setIsMobileWebviewSignUp(true);
    setMode('signup');
  }, []);
  const showMobilePasswordForgotten = useCallback(() => {
    setAcceptedTerms(null);
    setIsMobileWebviewSignUp(true);
    setMode('forgotPassword');
  }, []);
  const toggleResetPassword = useCallback(() => {
    setMode('signin');
  }, []);

  const handleClose = useCallback(() => setMode('dismissed'), []);
  const handleTermsClose = useCallback(() => setPopMode(null), [setPopMode]);

  const closeConnectionDialog = useCallback(() => {
    setMode(null);
    setPopMode(null);
  }, []);

  const prompt2fa = useCallback(
    (cb: Prompt2faCallback, challenge: string, reason?: string) => {
      setMode('2fa');
      setPrompt2faCallback(cb);
      setOtpSessionChallenge(challenge);
      setReasonFor2FA(reason);
    },
    [setOtpSessionChallenge]
  );

  const promptTerms = useCallback(
    (options: PromptTermsOptions, cb?: PromptTermsCallback) => {
      setPopMode('mustAcceptTerms');
      setPromptTermsOptions(options);
      if (cb) setPromptTermsCallback(cb);
    },
    []
  );

  const promptNewDeviceConfirmation = useCallback(
    () => setMode('newDevice'),
    []
  );

  useEffect(() => {
    if (!mode && action && isConnectionMode(action)) {
      setMode(action);
    }
  }, [action, mode]);

  // store the initial value of mustAcceptTcus to
  // avoid the redirection to `TERMS` once accepted
  const mustAcceptTCU = useRef<boolean>(!!currentUser?.mustAcceptTcus);
  const initialTCUAcceptance = useRef<boolean>(
    termStatus === INITIAL_TERMS_STATUS
  );

  useEffect(() => {
    if (mode === 'showTerms' && !mustAcceptTCU.current) {
      setMode(null);
      navigate(TERMS_AND_CONDITIONS, { replace: true });
    }
  }, [mode, setMode, mustAcceptTCU, navigate]);

  useEffect(() => {
    if (passwordForgotten) promptPasswordForgotten();
    registerHandler<OnBackForgotPassword>('onBackForgotPassword', async () => {
      setPasswordForgotten(false);
      return {};
    });
  }, [
    passwordForgotten,
    promptPasswordForgotten,
    registerHandler,
    setPasswordForgotten,
  ]);

  if (
    mode &&
    ['recoverKey', 'restoreWallet', 'addFundsEth', 'kyc'].includes(mode) &&
    !currentUser
  ) {
    const afterLoggedInTarget = `?${stringify({ action: mode, id })}`;

    setMode('signin');
    navigate('', {
      state: {
        afterLoggedInTarget,
      },
    });
  }

  if (mode === 'forgotPassword') {
    if (!currentUser) {
      setMode('signin');
      togglePasswordForgotten();
    }
  }

  useEffect(() => {
    if (mode === 'signup') {
      if (code === OAUTH_MISSING_EMAIL_ERROR_CODE) {
        showNotification('oauthMissingEmail', {}, { level: Level.ERROR });
      } else if (code === OAUTH_LOCKED_ACCOUNT_ERROR_CODE) {
        showNotification('oauthLockedAccount', {}, { level: Level.ERROR });
      }
    }
  }, [code, mode, showNotification]);

  const value = useMemo(
    () => ({
      signIn,
      signUp,
      showMobileWebviewSignUpFlow,
      showMobilePasswordForgotten,
      togglePasswordForgotten,
      toggleResetPassword,
      prompt2fa,
      promptTerms,
      passwordForgotten,
      promptNewDeviceConfirmation,
      acceptedTerms,
      closeConnectionDialog,
      recaptchaRef,
    }),
    [
      signIn,
      signUp,
      showMobileWebviewSignUpFlow,
      showMobilePasswordForgotten,
      togglePasswordForgotten,
      toggleResetPassword,
      prompt2fa,
      promptTerms,
      passwordForgotten,
      promptNewDeviceConfirmation,
      acceptedTerms,
      closeConnectionDialog,
    ]
  );

  return (
    <ConnectionContextProvider value={value}>
      {isConnectionDialogMode(mode) && !passwordForgotten && !currentUser && (
        <ConnectionDialog
          open
          isMobileWebviewSignUp={isMobileWebviewSignUp}
          mode={mode}
          popMode={popMode}
          setMode={setMode}
          onClose={handleClose}
          defaultSport={defaultSport}
        />
      )}
      {passwordForgotten && (
        <PasswordForgottenDialog
          open
          onBack={() => setPasswordForgotten(false)}
        />
      )}
      {mode === 'resetPassword' && (
        <ResetPasswordDialog open onClose={handleClose} />
      )}
      {mode === 'newDevice' && <NewDeviceDialog onClose={handleClose} />}
      {mode === '2fa' && otpSessionChallenge && (
        <PromptTwoFactAuthDialog
          open
          otpSessionChallenge={otpSessionChallenge}
          onSignedIn={prompt2faCallback}
          onClose={handleClose}
          reason={reasonFor2Fa}
        />
      )}
      {/* OAUTH GRACE PERIOD */}
      {mode === 'showTerms' && mustAcceptTCU.current && (
        <PromptTermsDialog
          open
          onClose={handleClose}
          options={{
            closable: true,
            initialTermsDisplay: initialTCUAcceptance.current,
          }}
          onAccept={null}
          isMobileWebviewSignUp={isMobileWebviewSignUp}
        />
      )}

      {/* OAUTH AFTER GRACE PERIOD */}
      {mode === 'acceptTerms' && tcuToken && (
        <PromptTermsDialog
          open
          onClose={handleClose}
          options={{
            closable: false,
            tcuToken,
            initialTermsDisplay: initialTCUAcceptance.current,
          }}
          onAccept={promptTermsCallback}
          isMobileWebviewSignUp={isMobileWebviewSignUp}
        />
      )}
      {/* ALL THE REST  */}
      {popMode === 'mustAcceptTerms' && (
        <PromptTermsDialog
          open
          onAccept={promptTermsCallback}
          onClose={handleTermsClose}
          options={promptTermsOptions}
          isMobileWebviewSignUp={isMobileWebviewSignUp}
        />
      )}
      {(mode === 'signup' || mode === 'signin') && (
        <ReCAPTCHA ref={recaptchaRef} />
      )}
      {children}
    </ConnectionContextProvider>
  );
};

export default ConnectionProvider;
