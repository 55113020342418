import { useState } from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Text16, Title4, Title5 } from 'atoms/typography';
import Dialog from 'components/dialog';
import { GraphQLResult, GraphqlForm, TextField } from 'components/form/Form';
import { OTP_ATTEMPT_LENGTH } from 'constants/verificationCode';
import NewDeviceDialog from 'contexts/connection/NewDeviceDialog';
import { useIntlContext } from 'contexts/intl';
import useScreenSize from 'hooks/device/useScreenSize';
import { glossary } from 'lib/glossary';
import { theme } from 'style/theme';

const messages = defineMessages({
  field: {
    id: 'Prompt2faDialog.field',
    defaultMessage: 'code',
  },
  authenticateFromNewCountry: {
    id: 'Prompt2faDialog.title.authenticateFromNewCountry',
    defaultMessage: 'Authenticating from another country',
  },
  enterVerificationCodeSentViaEmail: {
    id: 'Prompt2faDialog.authenticateFromNewCountry',
    defaultMessage:
      'Please enter the verification code that you received via email.',
  },
  enter6DigitCodeFromAuthenticatorApp: {
    id: 'Prompt2faDialog.enterCodeFromAuthenticatorApp',
    defaultMessage: 'Enter the 6-digit code from your authenticator app',
  },
  recoveryCode: {
    id: 'Prompt2faDialog.recovery_code',
    defaultMessage:
      "If you've lost your device, you may enter one of your recovery codes.",
  },
  twoFaPlaceHolder: {
    id: 'Prompt2faDialog.2faPlaceHolder',
    defaultMessage: '6-digit code or recovery code',
  },
  verificationCodePlaceHolder: {
    id: 'Prompt2faDialog.verificationCodePlaceHolder',
    defaultMessage: 'Verification code',
  },
});

type TitleProps = {
  authenticateFromNewCountry: boolean;
};
const TitleDiv = styled(Horizontal).attrs({ gap: 0, center: true })`
  padding: 0 var(--quadruple-unit);
  width: 100%;
`;
const Title = ({ authenticateFromNewCountry }: TitleProps) => {
  const { formatMessage } = useIntlContext();
  return (
    <TitleDiv>
      <Title5 style={{ textAlign: 'center' }}>
        {formatMessage(
          authenticateFromNewCountry
            ? messages.authenticateFromNewCountry
            : glossary.twofa
        )}
      </Title5>
    </TitleDiv>
  );
};

type HeaderProps = {
  authenticateFromNewCountry: boolean;
  desktop: boolean;
};
const HeaderDiv = styled.div<{ desktop: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--double-unit);
  padding: ${({ desktop }) => (desktop ? '0 calc(var(--unit) * 6)' : '0')};
`;
const Header = ({ authenticateFromNewCountry, desktop }: HeaderProps) => {
  const { formatMessage } = useIntlContext();
  if (authenticateFromNewCountry) {
    return (
      <Title4>
        {formatMessage(messages.enterVerificationCodeSentViaEmail)}
      </Title4>
    );
  }
  return (
    <HeaderDiv desktop={desktop}>
      <Title4>
        {formatMessage(messages.enter6DigitCodeFromAuthenticatorApp)}
      </Title4>
      <Text16>{formatMessage(messages.recoveryCode)}</Text16>
    </HeaderDiv>
  );
};

const Root = styled(GraphqlForm)`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: var(--double-unit);
  margin: var(--double-unit);
  overflow: auto;
`;
const Input = styled(TextField)`
  width: 100%;
  & input {
    border-radius: 2em;
    padding: var(--intermediate-unit) var(--double-unit);
    text-align: center;
  }
`;

type Props = {
  open: boolean;
  reason?: string;
  onSubmit: (values: any, onResult: (result: GraphQLResult) => void) => void;
  onSuccess?: (result: GraphQLResult) => void;
  onError?: (
    result: GraphQLResult & {
      tcuToken?: string;
    }
  ) => void;
  onCancel?: () => void;
  onClose: () => void;
};
export const TwoFADialog = ({
  open,
  onSubmit,
  onSuccess,
  onClose,
  onError,
  onCancel,
  reason,
}: Props) => {
  const authenticateFromNewCountry = reason === 'authenticate_from_new_country';
  const { formatMessage } = useIntlContext();
  const [newDeviceConfirmation, promptNewDeviceConfirmation] =
    useState<boolean>(false);

  const doOnSuccess = (result: GraphQLResult) => {
    if (onSuccess) onSuccess(result);
    onClose();
  };

  const handleClose = () => {
    if (onCancel) onCancel();
    onClose();
  };

  const doOnError = (result: GraphQLResult & { tcuToken?: string }) => {
    if (
      result?.errors
        ?.map(({ message }) => message)
        .includes('authenticate_from_new_device') ||
      result?.error === 'authenticate_from_new_device'
    ) {
      promptNewDeviceConfirmation(true);
    }
    if (onError) onError(result);
  };

  const { up: isTablet } = useScreenSize('tablet');

  if (newDeviceConfirmation) return <NewDeviceDialog onClose={handleClose} />;

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
      fullScreen={!isTablet}
      // Dialog can be opened from the drawer, which has a z-index of theme.zIndex.modal but containing the wallet iframe which has a z-index of theme.zIndex.modal + 1
      // Quick fix to make sure the dialog is on top of the iframe. TO IMPROVE: close wallet or include 2FA in the wallet iframe
      style={{ zIndex: theme.zIndex.modal + 1 }}
      title={<Title authenticateFromNewCountry={authenticateFromNewCountry} />}
    >
      <Vertical>
        <Root
          onChange={(values: any, submit: () => void) => {
            const { otpAttempt } = values;
            if (otpAttempt?.length === OTP_ATTEMPT_LENGTH) submit();
          }}
          onSubmit={onSubmit}
          onSuccess={doOnSuccess}
          onError={doOnError}
          render={(Error, SubmitButton) => (
            <>
              <Header
                authenticateFromNewCountry={authenticateFromNewCountry}
                desktop={isTablet}
              />

              <Error code />
              <Input
                name="otpAttempt"
                type="text"
                autoFocus
                autoComplete="one-time-code"
                placeholder={formatMessage(
                  authenticateFromNewCountry
                    ? messages.verificationCodePlaceHolder
                    : messages.twoFaPlaceHolder
                )}
              />
              <SubmitButton fullWidth size="medium">
                {formatMessage(glossary.submit)}
              </SubmitButton>
            </>
          )}
        />
      </Vertical>
    </Dialog>
  );
};
