import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { Text14 } from 'atoms/typography';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';
import { payment } from 'lib/glossary';
import { Color } from 'style/types';

import { FiatWalletIcon } from '../Icon/FiatWalletIcon';
import { SorareWallet } from '../SorareWallet';

type Props = {
  withoutBalance?: boolean;
  column?: boolean;
  balanceColor?: Color;
  label?: MessageDescriptor;
};

export const FiatWallet = ({
  withoutBalance,
  column,
  balanceColor = 'var(--c-neutral-1000)',
  label = payment.sorareCashWallet,
}: Props) => {
  const { availableBalanceWithCurrencySymbol } = useFiatBalance();
  return (
    <SorareWallet
      icon={<FiatWalletIcon />}
      label={<FormattedMessage {...label} />}
      balance={
        <Text14 color={balanceColor}>
          {availableBalanceWithCurrencySymbol}
        </Text14>
      }
      withoutBalance={withoutBalance}
      column={column}
    />
  );
};
