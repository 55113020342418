import { Rarity, Sport } from '@sorare/core/src/__generated__/globalTypes';

type EventToken = {
  slug: string;
  sport: Sport;
  rarityTyped: Rarity;
};

export const tokenInfoProperties = (token: EventToken) => ({
  cardSlug: token.slug,
  scarcity: token.rarityTyped,
  sport: token.sport,
});

export const tokensInfoProperties = (tokens: EventToken[]) => ({
  cardSlugs: tokens.map(token => token.slug),
  cardCount: tokens.length,
  limited: tokens.filter(token => token.rarityTyped === Rarity.limited).length,
  rare: tokens.filter(token => token.rarityTyped === Rarity.rare).length,
  superRare: tokens.filter(token => token.rarityTyped === Rarity.super_rare)
    .length,
  unique: tokens.filter(token => token.rarityTyped === Rarity.unique).length,
});
