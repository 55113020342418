import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { So5FixtureEvent } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import {
  ANY_SPORT_CLUB_SHOP_INVENTORY,
  ANY_SPORT_PLAY_EVENTTYPE,
} from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { glossary } from '@sorare/core/src/lib/glossary';

import { BuyButton } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/BuyButton';
import { useOnBuyButtonClick } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/BuyButton/useOnBuyButtonClick';
import Details from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/Details';
import ItemImagePreview from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/ItemImagePreview';
import { Requirements } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/Requirements';

import { AppearanceBoostContent_clubShopItem } from './__generated__/index.graphql';

type Props = {
  item: AppearanceBoostContent_clubShopItem;

  inventory: boolean;
  onBuy?: () => void;
  userCoinBalance: number;
  onClose: () => void;
};

export const AppearanceBoostContent = ({
  item,
  inventory,
  onBuy,
  userCoinBalance,
  onClose,
}: Props) => {
  const { generateSportContextPath } = useSportContext();

  const [currentStep, setCurrentStep] = useState<'step1' | 'step2'>(
    inventory ? 'step2' : 'step1'
  );
  const { onBuyButtonClick, loading } = useOnBuyButtonClick(
    () => setCurrentStep('step2'),
    onBuy
  );

  return (
    <>
      <ItemImagePreview
        pictureUrl={item.pictureUrl}
        name={item.name}
        type={item.position}
      />
      <Details item={item} inventory={inventory} />
      {currentStep === 'step1' && (
        <>
          {item.disabled && <Requirements item={item} />}

          <BuyButton
            item={item}
            onClick={() => onBuyButtonClick(item.id)}
            loading={loading}
            userCoinBalance={userCoinBalance}
          />
        </>
      )}
      {currentStep === 'step2' && (
        <Horizontal center gap={2}>
          <Button
            to={generateSportContextPath(ANY_SPORT_PLAY_EVENTTYPE, {
              params: {
                eventType: So5FixtureEvent.CLASSIC.toLowerCase(),
              },
            })}
            onClick={() => {
              onClose();
            }}
            color="primary"
            size="medium"
          >
            <FormattedMessage {...glossary.playNow} />
          </Button>
          <Button
            to={generateSportContextPath(ANY_SPORT_CLUB_SHOP_INVENTORY)}
            onClick={() => {
              onClose();
            }}
            color="tertiary"
            size="medium"
          >
            <FormattedMessage {...glossary.viewMyItems} />
          </Button>
        </Horizontal>
      )}
    </>
  );
};

AppearanceBoostContent.fragments = {
  clubShopItem: gql`
    fragment AppearanceBoostContent_clubShopItem on ClubShopItem {
      ... on ShopItemInterface {
        id
        pictureUrl
        name
        position
        myLimitResetAt
        myBalance
        expirationDate
      }
      ...Details_shopItem
      ...ConsumableCta_shopItem
      ...Requirements_clubShopItem
    }
    ${Details.fragments.shopItem}
    ${Requirements.fragments.clubShopItem}
  ` as TypedDocumentNode<AppearanceBoostContent_clubShopItem>,
};
