import { createContext, useContext } from 'react';

import { CurrentBaseballUserQuery } from './__generated__/queries.graphql';

type CurrentBaseballUserQuery_currentUser = NonNullable<
  CurrentBaseballUserQuery['currentUser']
>;

export type CurrentBaseballUser = CurrentBaseballUserQuery_currentUser;

interface CurrentBaseballUserContext {
  currentBaseballUser: CurrentBaseballUser | null | undefined;
  fetching: boolean;
}

export const currentBaseballUserContext =
  createContext<CurrentBaseballUserContext | null>(null);

export const useCurrentUserContext = () =>
  useContext(currentBaseballUserContext)!;

export default currentBaseballUserContext.Provider;
