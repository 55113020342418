import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { mobileApp } from '@sorare/core/src/lib/glossary';

export default () => {
  const { currentUser } = useCurrentUserContext();
  const { isIosApp } = useIsMobileApp();

  return () => {
    if (isIosApp) return mobileApp.startTrading;
    if (!currentUser) return null;

    return null;
  };
};
