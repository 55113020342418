import { TypedDocumentNode, gql } from '@apollo/client';

import useMutation from 'hooks/graphql/useMutation';

import {
  EditDeckMutation,
  EditDeckMutationVariables,
} from './__generated__/useEditDeck.graphql';

const EDIT_DECK_MUTATION = gql`
  mutation EditDeckMutation($input: editDeckInput!) {
    editDeck(input: $input) {
      deck {
        slug
        name
        visible
        sport
        tokensCount
      }
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<EditDeckMutation, EditDeckMutationVariables>;

export default () => {
  const [editDeck] = useMutation(EDIT_DECK_MUTATION, {
    showErrorsWithSnackNotification: true,
  });

  return async (
    deck: { slug: string },
    variables: { name: string; visible: boolean }
  ) => {
    return editDeck({
      variables: {
        input: {
          deckSlug: deck.slug,
          ...variables,
        },
      },
    });
  };
};
