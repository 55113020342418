import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { rivals } from '@sorare/core/src/lib/glossary';

const Root = styled(Horizontal).attrs({ center: true })`
  padding: var(--half-unit);
  background-color: rgba(var(--c-rgb-green-300), 0.16);
  border-radius: 50%;
  color: var(--c-score-high);

  &.benched {
    background-color: rgba(var(--c-rgb-red-800), 0.16);
    color: var(--c-red-600);
  }
`;
type Props = {
  isStarting: boolean;
};
export const PlayingStatusIcon = ({ isStarting }: Props) => {
  const { formatMessage } = useIntl();

  if (isStarting) {
    return (
      <Root>
        <FontAwesomeIcon
          icon={faCircleCheck}
          title={formatMessage(rivals.starting)}
        />
      </Root>
    );
  }

  return (
    <Root className="benched">
      <FontAwesomeIcon
        icon={faCircleXmark}
        title={formatMessage(rivals.notStarting)}
      />
    </Root>
  );
};
