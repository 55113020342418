import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { cardAttributes } from '@sorare/core/src/lib/glossary';

type Props = {
  score: number;
  renderScore: (score: number) => ReactNode;
  scoreTootip?: ReactNode;
  bonusNode: ReactNode;
  extra?: ReactNode;
  stats?: ReactNode;
};

const Wrapper = styled(Horizontal).attrs({ center: true })``;

const CardProperties = ({
  score,
  renderScore,
  scoreTootip,
  bonusNode,
  extra,
  stats,
  ...rest
}: Props) => {
  return (
    <Wrapper {...rest}>
      {stats}
      <Tooltip
        title={
          scoreTootip || (
            <FormattedMessage {...cardAttributes.seasonAverageScore} />
          )
        }
      >
        {renderScore(score)}
      </Tooltip>
      {bonusNode}
      {extra}
    </Wrapper>
  );
};

export default CardProperties;
