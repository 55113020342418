import { Configure, useInstantSearch } from 'react-instantsearch';

type Props = {
  defaultIndex: string;
  getOptionalFilters: (index: string) => string[];
};

export const OptionalFilters = ({
  defaultIndex,
  getOptionalFilters,
}: Props) => {
  const { indexUiState } = useInstantSearch();

  const currentIndex = indexUiState.sortBy || defaultIndex;

  if (!currentIndex) return null;

  return <Configure optionalFilters={getOptionalFilters(currentIndex)} />;
};
