import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Tradeable } from '@sorare/core/src/__generated__/globalTypes';
import { Switch } from '@sorare/core/src/atoms/inputs/Switch';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Text16 } from '@sorare/core/src/atoms/typography';
import { isTransferable } from '@sorare/core/src/lib/deal';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import MinimumPrice from 'components/directOffer/MinimumPrice';

import { AvailableForTrade_anyCard } from './__generated__/index.graphql';
import { useToggleAvailableForTrade } from './useToggleAvailableForTrade';

type Props = {
  card: AvailableForTrade_anyCard;
};
const Root = styled(Vertical)`
  gap: var(--unit);
  @media ${tabletAndAbove} {
    padding-top: var(--double-unit);
    border-top: 1px solid var(--c-neutral-400);
  }
`;

const Field = styled(SBHorizontal)`
  align-items: center;
`;

export const AvailableForTrade = ({ card }: Props) => {
  const { toggleAvailableForTrade } = useToggleAvailableForTrade(card.sport);
  const tradeable = isTransferable(card);
  const disabled = [
    Tradeable.DEPOSIT_REQUIRED,
    Tradeable.NOT_YET,
    Tradeable.UNDEFINED,
  ].includes(card.tradeableStatus);

  return (
    <Root>
      <Field gap={2}>
        <Vertical gap={0}>
          <Text16 bold>
            <FormattedMessage
              id="AvailableForTrade.title"
              defaultMessage="Available for trade"
            />
          </Text16>
          <LabelM color="var(--c-neutral-600)">
            <FormattedMessage
              id="AvailableForTrade.description"
              defaultMessage="Make this card available for trade offers."
            />
          </LabelM>
        </Vertical>
        <Switch
          onChange={() => {
            toggleAvailableForTrade(card.assetId, card.slug, !tradeable);
          }}
          disabled={disabled}
          checked={tradeable}
        />
      </Field>
      {tradeable && <MinimumPrice card={card} />}
    </Root>
  );
};

AvailableForTrade.fragments = {
  anyCard: gql`
    fragment AvailableForTrade_anyCard on AnyCardInterface {
      slug
      assetId
      tradeableStatus
      sport
      tokenOwner {
        id
        account {
          id
          owner {
            ... on User {
              id
              slug
            }
            ... on Contract {
              id
              name
            }
          }
        }
      }
      ...MinimumPrice_anyCard
    }
    ${MinimumPrice.fragments.anyCard}
  ` as TypedDocumentNode<AvailableForTrade_anyCard>,
};
