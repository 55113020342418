import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import useMutation from 'hooks/graphql/useMutation';

import {
  PrepareEthDepositMutation,
  PrepareEthDepositMutationVariables,
} from './__generated__/usePrepareEthDeposit.graphql';

const PREPARE_ETH_DEPOSIT_MUTATION = gql`
  mutation PrepareEthDepositMutation($input: prepareEthDepositInput!) {
    prepareEthDeposit(input: $input) {
      ethDeposit {
        starkKey
        vaultId
        assetType
        weiAmount
      }
      errors {
        code
        message
      }
    }
  }
` as TypedDocumentNode<
  PrepareEthDepositMutation,
  PrepareEthDepositMutationVariables
>;

const usePrepareMutation = () => {
  const [mutate] = useMutation(PREPARE_ETH_DEPOSIT_MUTATION, {
    showErrorsWithSnackNotification: true,
  });

  return useCallback(
    async (weiAmount: bigint) => {
      const { data } = await mutate({ variables: { input: { weiAmount } } });

      return data?.prepareEthDeposit?.ethDeposit;
    },
    [mutate]
  );
};

export default usePrepareMutation;
