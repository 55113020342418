/* eslint-disable no-restricted-properties */

export const reloadPage = (version?: string) => {
  if (version != null) {
    const url = new URL(window.location.href);
    url.searchParams.set('t', version);
    window.location.replace(url.href);
  }
  window.location.reload();
};
