import { createContext, useContext } from 'react';

export interface MarketplaceContextType {
  setHideDetails: (newValue: boolean) => void;
  hideDetails: boolean;
}

export const MarketplaceContext = createContext<MarketplaceContextType | null>(
  null
);

export const useMarketplaceContext = () => useContext(MarketplaceContext)!;

export default MarketplaceContext.Provider;
