import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Body } from '@sorare/core/src/atoms/layout/Body';
import { ResponsiveRow } from '@sorare/core/src/atoms/layout/ResponsiveRow';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Title2, Title3 } from '@sorare/core/src/atoms/typography';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { isType } from '@sorare/core/src/gql';
import usePaginatedQuery from '@sorare/core/src/hooks/graphql/usePaginatedQuery';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';
import {
  ScrollTo,
  useScrollTo,
  useScrollToQueryParameter,
} from '@sorare/core/src/hooks/scrollTo';

import BidHistory from '@sorare/marketplace/src/components/auction/BidHistory';
import OpenAuction from '@sorare/marketplace/src/components/auction/OpenAuction';
import FootballCardProperties from '@sorare/marketplace/src/components/card/FootballCardProperties';
import MultiCardPageContent from '@sorare/marketplace/src/components/market/MultiCardPageContent';
import CardPreview from '@sorare/marketplace/src/components/starterbundle/CardPreview';
import FlexCard from '@sorare/marketplace/src/components/token/FlexCard';
import { tokenPageMessages } from '@sorare/marketplace/src/components/token/TokenPage/tokenPageMessages';

import RelatedTeam from 'components/club/RelatedTeam';

import BundledAuctionTitle from './BundledAuctionTitle';
import {
  BundledAuctionQuery,
  BundledAuctionQueryVariables,
} from './__generated__/index.graphql';

const messages = defineMessages({
  relatedPage: {
    id: 'BundledAuction.relatedPage',
    defaultMessage: 'Related page',
  },
  items: {
    id: 'BundledAuction.items',
    defaultMessage: '{count, number} items',
  },
});

const BidsHistory = styled(Vertical).attrs({ gap: 2 })``;

const BUNDLED_AUCTION_QUERY = gql`
  query BundledAuctionQuery($id: String!, $bidCursor: String) {
    tokens {
      auction(id: $id) {
        id
        team
        ...OpenAuction_auction
        cards: anyCards {
          slug
          anyTeam {
            slug
            ...RelatedTeam_team
          }
          ...FootballCardProperties_card
          ...BundledAuctionTitle_card
          ...FlexCard_anyCard
          ...CardPreview_anyCard
        }
        bids(first: 5, after: $bidCursor) {
          ...BidHistory_tokenBidConnection
        }
      }
    }
  }
  ${BundledAuctionTitle.fragments.card}
  ${OpenAuction.fragments.auction}
  ${FlexCard.fragments.anyCard}
  ${CardPreview.fragments.anyCard}
  ${BidHistory.fragments.bid}
  ${RelatedTeam.fragments.team}
  ${FootballCardProperties.fragments.card}
` as TypedDocumentNode<BundledAuctionQuery, BundledAuctionQueryVariables>;

type AnyCard = BundledAuctionQuery['tokens']['auction']['cards'][number];
type Card = AnyCard & {
  __typename: 'Card';
};
const isFootballCard = (card: AnyCard): card is Card => isType(card, 'Card');

const bidAreaId = (auction: { id: string }) => `bidding-${auction.id}`;

export const BundledAuctionPage = () => {
  const { id: idFromSlug } = useParams();
  const bundledAuctionDialogParameter = useDialogParam(
    DialogKey.bundledAuction
  );
  const scrollToQueryParameter = useScrollToQueryParameter();
  const id = bundledAuctionDialogParameter || idFromSlug;
  const scrollTo = bundledAuctionDialogParameter
    ? undefined
    : scrollToQueryParameter;
  const scrollToBidForAuction = useScrollTo(bidAreaId);
  const {
    data,
    loading,
    loadMore: loadMoreBids,
  } = usePaginatedQuery(BUNDLED_AUCTION_QUERY, {
    variables: {
      id: id!,
    },
    skip: !id,
    connection: 'TokenBidConnection',
  });

  useEffect(() => {
    if (scrollTo === ScrollTo.Bid && data?.tokens?.auction) {
      scrollToBidForAuction(data?.tokens?.auction);
    }
  }, [scrollTo, scrollToBidForAuction, data?.tokens?.auction]);

  if (loading) return <LoadingIndicator fullHeight />;

  const auction = data?.tokens.auction;

  if (!auction) return null;

  const { cards, bids } = auction;
  const footballCards: Card[] = cards.filter(isFootballCard);

  return (
    <Body color="white">
      <MultiCardPageContent
        cardsPreview={
          <>
            {cards.map(c => (
              <FlexCard key={c.slug} card={c} />
            ))}
          </>
        }
        detailsContent={
          <>
            <BundledAuctionTitle
              team={auction.team}
              cards={footballCards}
              Variant={Title2}
            />
            <Vertical id={bidAreaId(auction)}>
              <OpenAuction auction={auction} />
            </Vertical>
            {bids && bids.totalCount > 0 && (
              <Vertical>
                <BidsHistory>
                  <Title3>
                    <FormattedMessage
                      {...tokenPageMessages.bidCount}
                      values={{
                        count: bids.totalCount,
                      }}
                    />
                  </Title3>
                  <BidHistory
                    bids={bids}
                    loadMoreBids={loadMoreBids}
                    loading={false}
                  />
                </BidsHistory>
              </Vertical>
            )}
            <Vertical>
              <Title3>
                <FormattedMessage
                  id="BundledAuction.cards"
                  defaultMessage="In this bundle"
                />
              </Title3>
              <ResponsiveRow>
                {auction.cards.map(card => {
                  if (!isFootballCard(card)) return null;

                  return (
                    <CardPreview
                      key={card.slug}
                      card={card}
                      cardProperties={
                        <FootballCardProperties
                          card={card}
                          withTransferMalus={false}
                        />
                      }
                    />
                  );
                })}
              </ResponsiveRow>
            </Vertical>
            <Vertical>
              <Title3>
                <FormattedMessage {...messages.relatedPage} />
              </Title3>
              <RelatedTeam team={footballCards[0].anyTeam} />
            </Vertical>
          </>
        }
      />
    </Body>
  );
};

export default BundledAuctionPage;
