import { TypedDocumentNode, gql } from '@apollo/client';

import { isType } from '@sorare/core/src/gql';

import ItemEligibility from 'components/card/FootballCardProperties/ItemEligibility';
import { ItemEligibility_card } from 'components/card/FootballCardProperties/ItemEligibility/__generated__/index.graphql';

import { AuctionEligibility_tokenAuction } from './__generated__/index.graphql';

type Props = {
  auction: AuctionEligibility_tokenAuction;
};

export const AuctionEligibility = ({ auction }: Props) => {
  const { cards } = auction;

  if (cards.every(card => isType(card, 'Card'))) {
    return <ItemEligibility cards={cards as ItemEligibility_card[]} />;
  }

  return null;
};

AuctionEligibility.fragments = {
  tokenAuction: gql`
    fragment AuctionEligibility_tokenAuction on TokenAuction {
      id
      cards: anyCards {
        slug
        ...ItemEligibility_card
      }
    }
    ${ItemEligibility.fragments.card}
  ` as TypedDocumentNode<AuctionEligibility_tokenAuction>,
};
