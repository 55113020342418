import { useCallback } from 'react';

import { FiatWalletAccountState } from '__generated__/globalTypes';
import { CreateFiatWalletSteps } from 'components/fiatWallet/CreateFiatWallet/type';
import { useEvents } from 'lib/events/useEvents';
import {
  CashWalletStatus,
  EnterCashWalletKYCFlow_Source,
  EnterCashWalletKYCFlow_Target,
  ExitCashWalletKYCFlow_ExitStep,
} from 'protos/events/platform/web/events';

import { useFiatBalance } from './useFiatBalance';

const getTrackingCashWalletStatus = (
  fiatWalletState?: FiatWalletAccountState
) => {
  switch (fiatWalletState) {
    case FiatWalletAccountState.OWNER:
      return CashWalletStatus.OWNER;
    case FiatWalletAccountState.VALIDATED_OWNER:
      return CashWalletStatus.VALIDATED_OWNER;
    case FiatWalletAccountState.PAYER:
      return CashWalletStatus.PAYER;
    default:
      return CashWalletStatus.UNRECOGNIZED;
  }
};

const getTrackingCreateFiatWalletStep = (step?: CreateFiatWalletSteps) => {
  switch (step) {
    case CreateFiatWalletSteps.ACTIVATE_YOUR_CASH_WALLET:
      return ExitCashWalletKYCFlow_ExitStep.ACTIVATE_YOUR_CASH_WALLET;
    case CreateFiatWalletSteps.WHATS_NEW:
      return ExitCashWalletKYCFlow_ExitStep.WHATS_NEW;
    case CreateFiatWalletSteps.INTRO:
      return ExitCashWalletKYCFlow_ExitStep.INTRO;
    case CreateFiatWalletSteps.TELL_US_ABOUT_YOU:
      return ExitCashWalletKYCFlow_ExitStep.TELL_US_ABOUT_YOU;
    case CreateFiatWalletSteps.CHOOSE_CURRENCY:
      return ExitCashWalletKYCFlow_ExitStep.CHOOSE_CURRENCY;
    case CreateFiatWalletSteps.ACTIVATION_SUCCESS:
      return ExitCashWalletKYCFlow_ExitStep.ACTIVATION_SUCCESS;
    case CreateFiatWalletSteps.CHOOSE_DOCUMENT:
      return ExitCashWalletKYCFlow_ExitStep.CHOOSE_DOCUMENT;
    case CreateFiatWalletSteps.UPLOAD:
      return ExitCashWalletKYCFlow_ExitStep.UPLOAD;
    case CreateFiatWalletSteps.HANDLE_ID_REVIEW_ERROR:
      return ExitCashWalletKYCFlow_ExitStep.HANDLE_ID_REVIEW_ERROR;
    case CreateFiatWalletSteps.DOCUMENT_UNDER_REVIEW:
      return ExitCashWalletKYCFlow_ExitStep.DOCUMENT_UNDER_REVIEW;
    case CreateFiatWalletSteps.REVIEW_INFO_BEFORE_ADDING_ID:
      return ExitCashWalletKYCFlow_ExitStep.REVIEW_INFO_BEFORE_ADDING_ID;
    case CreateFiatWalletSteps.REVIEW_INFORMATION:
      return ExitCashWalletKYCFlow_ExitStep.REVIEW_INFORMATION;
    default:
      return ExitCashWalletKYCFlow_ExitStep.UNRECOGNIZED;
  }
};

export const useCashWalletKycEvents = () => {
  const track = useEvents();

  const { fiatWalletState, fiatCurrency } = useFiatBalance();
  const cashWalletStatus = getTrackingCashWalletStatus(fiatWalletState);

  const trackEnter = useCallback(
    (args: {
      target: EnterCashWalletKYCFlow_Target;
      source: EnterCashWalletKYCFlow_Source;
    }) => {
      track('Enter Cash Wallet KYC Flow', {
        ...args,
        fiatCurrency,
        cashWalletStatus,
      });
    },
    [cashWalletStatus, fiatCurrency, track]
  );

  const trackExit = useCallback(
    (step: CreateFiatWalletSteps) => {
      track('Exit Cash Wallet KYC Flow', {
        kycExitStep: getTrackingCreateFiatWalletStep(step),
        fiatCurrency,
        cashWalletStatus,
      });
    },
    [cashWalletStatus, fiatCurrency, track]
  );

  return { trackEnter, trackExit };
};
