import { FormattedMessage } from 'react-intl';

import { UserBalance } from 'components/wallet/UserBalance';
import { payment } from 'lib/glossary';

import { EthWalletIcon } from '../Icon/EthWalletIcon';
import { SorareWallet } from '../SorareWallet';

type Props = {
  withoutBalance?: boolean;
};

export const EthWallet = ({ withoutBalance }: Props) => {
  return (
    <SorareWallet
      icon={<EthWalletIcon />}
      label={<FormattedMessage {...payment.sorareEthWallet} />}
      balance={<UserBalance inline disableToggle />}
      withoutBalance={withoutBalance}
    />
  );
};
