import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import {
  Currency,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import {
  EthereumAddressField,
  GraphqlForm,
} from '@sorare/core/src/components/form/Form';
import { UserBalance } from '@sorare/core/src/components/wallet/UserBalance';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { useWalletContext } from '@sorare/core/src/contexts/wallet';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { useUnquantizeAmount } from '@sorare/core/src/hooks/useUnquantizeAmount';
import { glossary } from '@sorare/core/src/lib/glossary';

import { TokenName } from 'components/token/TokenName';

import {
  CreateTokenWithdrawalMutation,
  CreateTokenWithdrawalMutationVariables,
  TokenWithdrawalInfo_anyCard,
  TokenWithdrawalInfo_transferRequest,
} from './__generated__/index.graphql';

// BatchMinter.mintCardsForAddressWithSig costs 270,000 gas
// StarkExchange.withdrawAndMint costs 170,000 gas
const MINTING_COST_RATIO = (270000n * 100n) / (170000n + 270000n);

const CREATE_TOKEN_WITHDRAWAL_MUTATION = gql`
  mutation CreateTokenWithdrawalMutation($input: createCardWithdrawalInput!) {
    createCardWithdrawal(input: $input) {
      anyCard {
        slug
        pendingWithdrawal {
          id
        }
      }
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  CreateTokenWithdrawalMutation,
  CreateTokenWithdrawalMutationVariables
>;

const messages = defineMessages({
  destinationError: {
    id: 'TokenWithdrawalInfo.destinationError',
    defaultMessage: 'Invalid destination address',
  },
  balanceError: {
    id: 'TokenWithdrawalInfo.balanceError',
    defaultMessage: 'Insufficient balance',
  },
  addressPlaceholder: {
    id: 'TokenWithdrawalInfo.addressPlaceholder',
    defaultMessage: 'Ethereum Address',
  },
});

const Column = styled(Vertical).attrs({ gap: 0.5 })``;
const MainXL = styled.span`
  font: var(--t-bold) var(--t-32);
`;
const ExponentXL = styled(Text16)`
  color: var(--c-neutral-600);
`;

const FeeAmount = ({ feeAmount }: { feeAmount: bigint }) => {
  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: {
      referenceCurrency: SupportedCurrency.WEI,
      wei: feeAmount,
    },
    primaryCurrency: Currency.ETH,
  });
  return (
    <Column>
      {main && <MainXL>{main}</MainXL>}
      {exponent && <ExponentXL>{exponent}</ExponentXL>}
    </Column>
  );
};
export interface Props {
  card: TokenWithdrawalInfo_anyCard;
  transferRequest: TokenWithdrawalInfo_transferRequest;
  onComplete: () => void;
}

const TotalPrice = styled.div`
  padding-bottom: 30px;
  padding-top: 10px;
  border-bottom: 1px solid var(--c-neutral-300);
  text-align: center;
`;
const Sections = styled.div`
  & > div {
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
  }
  & > div:not(:last-child) {
    border-bottom: 1px solid var(--c-neutral-300);
  }
`;
const Label = styled(Text16)`
  text-decoration: underline;
`;

const Bottom = styled(Vertical).attrs({ gap: 0 })`
  gap: 20px;
`;
const CannotPayError = styled.div`
  background-color: rgba(var(--c-rgb-red-600), 0.25);
  color: var(--c-red-600);
  padding: 10px;
  border-radius: 8px;
`;
const Disclaimer = styled(Text16)`
  background-color: var(--c-neutral-200);
  border-left: 4px solid var(--c-neutral-600);
  border-radius: 8px;
  padding: 10px;
`;
const CardInfoContainer = styled(Vertical).attrs({ gap: 0 })`
  padding-bottom: 10px;
`;

const TokenWithdrawalInfo = ({ card, transferRequest, onComplete }: Props) => {
  const { ethereumOwner, assetId } = card;
  const { signTransfer } = useWalletContext();
  const { currentUser } = useCurrentUserContext();
  const unquantizeAmount = useUnquantizeAmount();
  const { formatMessage } = useIntlContext();
  const [create] = useMutation(CREATE_TOKEN_WITHDRAWAL_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const feeAmount = unquantizeAmount(
    transferRequest.feeInfoUser?.feeLimit || 0n
  );

  if (!currentUser) return null;

  const canPay = currentUser.availableBalance > feeAmount;

  return (
    <GraphqlForm
      onSubmit={async ({ address }, onResult) => {
        const signature = await signTransfer({
          ...transferRequest,
          receiverPublicKey: address!,
          feeInfoUser: transferRequest?.feeInfoUser || undefined,
        });
        const result = await create({
          variables: {
            input: {
              assetId,
              destination: address!,
              starkSignatures: [
                {
                  nonce: transferRequest!.nonce,
                  expirationTimestamp: transferRequest!.expirationTimestamp,
                  data: signature!,
                },
              ],
            },
          },
        });
        onResult(result);
      }}
      onSuccess={onComplete}
      render={(Error, SubmitButton) => (
        <>
          <TotalPrice>
            <FeeAmount feeAmount={feeAmount} />
          </TotalPrice>
          <Sections>
            {!ethereumOwner && (
              <>
                <div>
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="CardWithdrawalInfo.mintingTooltip"
                        defaultMessage="The process of crafting your Sorare Card into a digital asset on Ethereum mainnet."
                      />
                    }
                  >
                    <Label>
                      <FormattedMessage
                        id="CardWithdrawalInfo.minting"
                        defaultMessage="Minting"
                      />
                    </Label>
                  </Tooltip>
                  <AmountWithConversion
                    monetaryAmount={{
                      referenceCurrency: SupportedCurrency.WEI,
                      wei: (feeAmount * MINTING_COST_RATIO) / 100n,
                    }}
                    primaryCurrency={Currency.ETH}
                    withApproxSymbol
                  />
                </div>
                <div>
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="CardWithdrawalInfo.withdrawalTooltip"
                        defaultMessage="The process of sending your Sorare mainnet NFT to your Ethereum wallet."
                      />
                    }
                  >
                    <Label>
                      <FormattedMessage
                        id="CardWithdrawalInfo.withdrawal"
                        defaultMessage="Withdrawal"
                      />
                    </Label>
                  </Tooltip>
                  <AmountWithConversion
                    monetaryAmount={{
                      referenceCurrency: SupportedCurrency.WEI,
                      wei:
                        (feeAmount *
                          (ethereumOwner ? 100n : 100n - MINTING_COST_RATIO)) /
                        100n,
                    }}
                    primaryCurrency={Currency.ETH}
                    withApproxSymbol
                  />
                </div>
              </>
            )}
            <div>
              <Tooltip
                title={
                  <FormattedMessage
                    id="CardWithdrawalInfo.durationTooltip"
                    defaultMessage="The time needed to submit the STARK proof to Ethereum mainnet."
                  />
                }
              >
                <Label>
                  <FormattedMessage
                    id="CardWithdrawalInfo.durationTitle"
                    defaultMessage="Estimated duration"
                  />
                </Label>
              </Tooltip>
              <Text16>
                <FormattedMessage
                  id="CardWithdrawalInfo.durationTime"
                  defaultMessage="8-10 hours"
                />
              </Text16>
            </div>
            <EthereumAddressField
              label={
                <FormattedMessage
                  id="CardWithdrawalInfo.destinationLabel"
                  defaultMessage="Enter destination wallet address"
                />
              }
              name="address"
              placeholder={formatMessage(messages.addressPlaceholder)}
            />
          </Sections>
          <Bottom>
            <SubmitButton size="medium" disabled={!canPay}>
              {formatMessage(glossary.withdraw)}
            </SubmitButton>
            <Error />
            {!canPay && (
              <CannotPayError>
                {formatMessage(messages.balanceError)}
              </CannotPayError>
            )}
            <Disclaimer>
              <FormattedMessage
                id="CardWithdrawalInfo.disclaimer"
                defaultMessage="Your Card will not be available for sale during the transfer"
              />
            </Disclaimer>
            <div>
              <CardInfoContainer>
                <Text16 as="h6" color="var(--c-neutral-600)">
                  <FormattedMessage
                    id="CardWithdrawalInfo.cardLable"
                    defaultMessage="You withdraw"
                  />
                </Text16>
                <TokenName card={card} />
              </CardInfoContainer>
              <Text16 as="h6" color="var(--c-neutral-600)">
                <FormattedMessage
                  id="CardWithdrawalInfo.balance"
                  defaultMessage="Balance"
                />
              </Text16>
              <UserBalance inline />
            </div>
          </Bottom>
        </>
      )}
    />
  );
};

TokenWithdrawalInfo.fragments = {
  anyCard: gql`
    fragment TokenWithdrawalInfo_anyCard on AnyCardInterface {
      slug
      assetId
      ethereumOwner {
        id
      }
      ...TokenName_anyCard
    }
    ${TokenName.fragments.anyCard}
  ` as TypedDocumentNode<TokenWithdrawalInfo_anyCard>,
  transferRequest: gql`
    fragment TokenWithdrawalInfo_transferRequest on TransferRequest {
      senderVaultId
      receiverVaultId
      amount
      token
      nonce
      expirationTimestamp
      feeInfoUser {
        tokenId
        sourceVaultId
        feeLimit
      }
    }
  ` as TypedDocumentNode<TokenWithdrawalInfo_transferRequest>,
};

export default TokenWithdrawalInfo;
