import { defineMessages } from 'react-intl';

import { DocumentType } from '__generated__/globalTypes';

export enum UnblockWalletSteps {
  INTRO = 'INTRO',
  ADD_PROOF_OF_IDENTITY = 'ADD_PROOF_OF_IDENTITY',
  ADD_PROOF_OF_ADDRESS = 'ADD_PROOF_OF_ADDRESS',
  ADD_BANK_STATEMENT = 'ADD_BANK_STATEMENT',
  ADD_PEP_DECLARATION = 'ADD_PEP_DECLARATION',
  REVIEW = 'REVIEW',
  CONFIRMATION = 'CONFIRMATION',
}

export const stepByDocumentType: {
  [key in DocumentType]: UnblockWalletSteps;
} = {
  [DocumentType.PROOF_OF_IDENTITY]: UnblockWalletSteps.ADD_PROOF_OF_IDENTITY,
  [DocumentType.PROOF_OF_ADDRESS]: UnblockWalletSteps.ADD_PROOF_OF_ADDRESS,
  [DocumentType.BANK_STATEMENT]: UnblockWalletSteps.ADD_BANK_STATEMENT,
  [DocumentType.POTENTIAL_POLITICALLY_EXPOSED_PERSON_DECLARATION]:
    UnblockWalletSteps.ADD_PEP_DECLARATION,
};

export const documentNameByType = defineMessages<DocumentType>({
  [DocumentType.PROOF_OF_IDENTITY]: {
    id: 'UnblockWalletSteps.ADD_PROOF_OF_IDENTITY',
    defaultMessage: 'Proof of identity',
  },
  [DocumentType.PROOF_OF_ADDRESS]: {
    id: 'UnblockWalletSteps.PROOF_OF_ADDRESS',
    defaultMessage: 'Proof of address',
  },
  [DocumentType.BANK_STATEMENT]: {
    id: 'UnblockWalletSteps.BANK_STATEMENT',
    defaultMessage: 'Bank statement',
  },
  [DocumentType.POTENTIAL_POLITICALLY_EXPOSED_PERSON_DECLARATION]: {
    id: 'UnblockWalletSteps.POTENTIAL_POLITICALLY_EXPOSED_PERSON_DECLARATION',
    defaultMessage: 'PEP declaration',
  },
});
