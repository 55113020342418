import { DECLARATION, Middleware, tokenize } from 'stylis';

function getValues(
  value: string
): [string, string, string, string] | undefined {
  const tokens = tokenize(value);

  const out = [];
  let curr = '';
  for (const token of tokens) {
    if (token === ' ') {
      out.push(curr);
      curr = '';
    } else if (token === '!') {
      out.push(curr);
      curr = '!';
    } else if (token.startsWith('(') || curr === '!') {
      curr += token;
    } else {
      curr = token;
    }
  }
  if (curr) {
    out.push(curr);
  }

  if (out[out.length - 1] === '!important') {
    out.pop();
    for (let i = 0; i < out.length; i += 1) {
      out[i] += '!important';
    }
  }

  if (out.length === 1) {
    const [v] = out;
    return [v, v, v, v];
  }
  if (out.length === 2) {
    const [v, h] = out;
    return [v, h, v, h];
  }
  if (out.length === 3) {
    const [t, h, b] = out;
    return [t, h, b, h];
  }
  if (out.length === 4) {
    return out as [string, string, string, string];
  }
  return undefined;
}

export const insetPlugin: Middleware = element => {
  switch (element.type) {
    case DECLARATION: {
      if (element.props === 'inset' && typeof element.children === 'string') {
        const values = getValues(element.children);
        if (values) {
          element.value = [
            `top:${values[0]}`,
            `right:${values[1]}`,
            `bottom:${values[2]}`,
            `left:${values[3]}`,
            '',
          ].join(';');
        }
      }
      break;
    }
  }
};
