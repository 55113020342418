import { TypedDocumentNode, gql } from '@apollo/client';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { ChangeEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Checkbox } from '@sorare/core/src/atoms/inputs/Checkbox';
import { SearchInput } from '@sorare/core/src/atoms/inputs/SearchInput';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Caption, Title6 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import useAddTokensToDeck from '@sorare/core/src/hooks/decks/useAddTokensToDeck';
import { useDecks } from '@sorare/core/src/hooks/decks/useDecks';
import useRemoveTokenFromDeck from '@sorare/core/src/hooks/decks/useRemoveTokenFromDeck';
import { glossary } from '@sorare/core/src/lib/glossary';

import { AddTokenToDeck_anyCard } from './__generated__/index.graphql';

const Body = styled(Vertical)`
  padding: var(--triple-unit);
`;
const Footer = styled(Horizontal).attrs({ gap: 2, center: true })`
  position: sticky;
  bottom: 0;
  padding: var(--triple-unit);
`;
const DeckName = styled.div`
  margin-right: auto;
`;

type Props = {
  card: AddTokenToDeck_anyCard;
  onClose: () => void;
  addList: () => void;
};

export const AddTokenToDeck = ({ card, onClose, addList }: Props) => {
  const [query, setQuery] = useState<string>();
  const [confirming, setConfirming] = useState(false);

  const { decks: defaultDecks, loading } = useDecks({
    sport: card.sport,
    query,
  });
  const [decks, setDecks] = useState(defaultDecks);
  const defaultChecked = card.decks.map(d => d.slug);
  const [checked, setChecked] = useState<string[] | null>(null);
  const addTokensToDeck = useAddTokensToDeck();
  const removeTokenFromDeck = useRemoveTokenFromDeck();
  const { formatMessage } = useIntl();

  const updateDecksCount = (slug: string, amount: number) =>
    setDecks(d =>
      d.map(deck => {
        if (slug === deck.slug) {
          return { ...deck, tokensCount: deck.tokensCount + amount };
        }
        return deck;
      })
    );
  const onCheckboxChange =
    ({ slug }: { slug: string }) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setChecked(c => (c ? [...c, slug] : [slug]));
        updateDecksCount(slug, 1);
      } else {
        setChecked(c => c?.filter(checkedSlug => checkedSlug !== slug) || []);
        updateDecksCount(slug, -1);
      }
    };
  const onConfirm = () => {
    setConfirming(true);
    const [cardToRemoveFrom, cardToAddTo] = decks.reduce<[string[], string[]]>(
      (prev, current) => {
        const deck = defaultDecks.find(({ slug }) => slug === current.slug);
        const toAdd = deck && current.tokensCount > deck.tokensCount;
        const toRemove = deck && current.tokensCount < deck.tokensCount;
        if (toAdd) {
          return [prev[0], [...prev[1], current.slug]];
        }
        if (toRemove) {
          return [[...prev[0], current.slug], prev[1]];
        }
        return prev;
      },
      [[], []]
    );
    Promise.all([
      ...cardToRemoveFrom.map(async deckSlug =>
        removeTokenFromDeck(deckSlug)(card.slug)
      ),
      ...cardToAddTo.map(async deckSlug =>
        addTokensToDeck(deckSlug)([card.slug])
      ),
    ]).then(() => {
      setConfirming(false);
      onClose();
    });
  };

  if (!checked && defaultChecked?.length) {
    setChecked(defaultChecked);
  }
  if (defaultDecks.length !== decks.length) {
    setDecks(defaultDecks);
  }
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open
      onClose={onClose}
      scroll="paper"
      title={
        <Title6 className="text-center">
          <FormattedMessage
            id="AddCardToDeck.addToList"
            defaultMessage="Add to list"
          />
        </Title6>
      }
      footer={
        <Footer>
          <Button
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            color="tertiary"
            size="medium"
            fullWidth
            onClick={addList}
          >
            <FormattedMessage
              id="AddCardToDeck.newList"
              defaultMessage="New list"
            />
          </Button>
          <LoadingButton
            color="primary"
            size="medium"
            fullWidth
            onClick={onConfirm}
            loading={confirming}
          >
            <FormattedMessage {...glossary.confirm} />
          </LoadingButton>
        </Footer>
      }
    >
      <Body>
        <SearchInput
          value={query}
          onChange={e => setQuery(e.target.value)}
          placeholder={formatMessage(glossary.search)}
        />
        {loading && !decks?.length ? (
          <LoadingIndicator small />
        ) : (
          decks?.map(deck => (
            <Horizontal key={deck.slug}>
              <DeckName>{deck.name}</DeckName>
              <Caption>{deck.tokensCount}</Caption>
              <Checkbox
                checked={!!checked?.includes(deck.slug)}
                onChange={onCheckboxChange(deck)}
              />
            </Horizontal>
          ))
        )}
      </Body>
    </Dialog>
  );
};

AddTokenToDeck.fragments = {
  anyCard: gql`
    fragment AddTokenToDeck_anyCard on AnyCardInterface {
      slug
      sport
      decks {
        slug
      }
    }
  ` as TypedDocumentNode<AddTokenToDeck_anyCard>,
};
