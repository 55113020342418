import classNames from 'classnames';
import { useRef } from 'react';
import styled, { css } from 'styled-components';

import { useViewTransitionState } from 'hooks/useViewTransitionState';
import { getCurrentBackgroundColor } from 'lib/currentBackgroundColor';

import { useMeasureHeaderHeight } from './useMeasureHeaderHeight';

const Sticky = styled.div`
  position: sticky;
  top: var(--current-stack-height, 0px);

  z-index: 1;
  isolation: isolate;

  ${getCurrentBackgroundColor()};
`;

const Wrapper = styled.div`
  isolation: isolate;

  &.hidden {
    visibility: hidden;
  }

  &.disableSticky {
    ${Sticky} {
      position: static;
    }
  }
`;

const SwapVar = styled.div<{ $height: number | undefined }>`
  ${({ $height }) => css`
    --parent-stack-height: calc(
      var(--current-stack-height, 0px) + ${$height || 0}px
    );
  `};
`;
const Content = styled.div`
  --current-stack-height: var(--parent-stack-height, 0px);
  z-index: 0;
`;

export const resetStack = css`
  --parent-stack-height: 0px;
  --current-stack-height: 0px;
`;

export const ContentWithStickyHeader: React.FC<{
  children: React.ReactNode;
  header: React.ReactNode;
  className?: string;
  hideHeader?: boolean;
  disableSticky?: boolean;
}> = ({ header, children, className, hideHeader, disableSticky = false }) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { height, measurementReady } = useMeasureHeaderHeight(headerRef);
  const isTransitioning = useViewTransitionState('*');
  const hideWhileMeasuring = !measurementReady && !isTransitioning;

  if (hideHeader) {
    return children;
  }
  return (
    <Wrapper
      className={classNames(className, {
        hidden: hideWhileMeasuring,
        disableSticky,
      })}
    >
      <Sticky ref={headerRef}>{header}</Sticky>
      <SwapVar $height={height}>
        <Content>{children}</Content>
      </SwapVar>
    </Wrapper>
  );
};
