import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import crest from '@sorare/core/src/assets/user/crest.png';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Text16 } from '@sorare/core/src/atoms/typography';
import { ActiveUserAvatar } from '@sorare/core/src/components/user/ActiveUserAvatar';
import { ActiveUserAvatar_user } from '@sorare/core/src/components/user/ActiveUserAvatar/__generated__/index.graphql';
import { Avatar } from '@sorare/core/src/components/user/Avatar';
import {
  Avatar_ethereumAccount,
  Avatar_starkwareAccount,
} from '@sorare/core/src/components/user/Avatar/__generated__/index.graphql';
import { MyClubLink } from '@sorare/core/src/components/user/MyClubLink';
import { UserName } from '@sorare/core/src/components/user/UserName';
import { useConfigContext } from '@sorare/core/src/contexts/config';
import { tokenHolderLink } from '@sorare/core/src/lib/etherscan';
import { isType } from '@sorare/core/src/lib/gql';

import { OwnerAccount_account } from './__generated__/index.graphql';

type Props = {
  account: OwnerAccount_account | null;
};

const Line = styled(Horizontal)`
  align-items: center;
`;
const Owner = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const OwnedBy = styled(LabelM)`
  flex-shrink: 0;
`;

export const OwnerAccount = ({ account }: Props) => {
  const { sorareTokensAddress } = useConfigContext();

  if (!account) return null;

  const renderOwnerAccount = (
    user:
      | ActiveUserAvatar_user
      | Avatar_ethereumAccount
      | Avatar_starkwareAccount,
    owner: ReactNode
  ) => {
    return (
      <Line>
        <OwnedBy color="var(--c-neutral-600)">
          <FormattedMessage id="CurrentOwner.owner" defaultMessage="Owned by" />
        </OwnedBy>
        {isType(user, 'User') ? (
          <ActiveUserAvatar
            editable={false}
            user={user}
            variant="small"
            placeholderUrl={crest}
          />
        ) : (
          <Avatar user={user} variant="small" />
        )}
        <Owner>{owner}</Owner>
      </Line>
    );
  };

  if (account.owner && isType(account.owner, 'User')) {
    return renderOwnerAccount(
      account.owner,
      <MyClubLink user={account.owner}>
        <Text16>
          <UserName user={account.owner} />
        </Text16>
      </MyClubLink>
    );
  }

  if (isType(account.accountable, 'EthereumAccount')) {
    return renderOwnerAccount(
      account.accountable,
      <a
        href={tokenHolderLink(sorareTokensAddress, account.accountable.address)}
        title={account.accountable.address}
        target="_blank"
        rel="noreferrer"
      >
        {account.accountable.address}
      </a>
    );
  }

  if (isType(account.accountable, 'StarkwareAccount')) {
    return renderOwnerAccount(
      account.accountable,
      account.accountable.starkKey
    );
  }

  return null;
};

OwnerAccount.fragments = {
  account: gql`
    fragment OwnerAccount_account on Account {
      id
      owner {
        ... on User {
          slug
          ...UserName_publicUserInfoInterface
          ...ActiveUserAvatar_user
          ...MyClubLink_publicUserInfoInterface
        }
        ... on Contract {
          id
        }
      }
      accountable {
        ... on Node {
          id
        }
        ... on EthereumAccount {
          id
          address
          ...Avatar_ethereumAccount
        }
        ... on StarkwareAccount {
          id
          starkKey
          ...Avatar_starkwareAccount
        }
      }
    }
    ${UserName.fragments.user}
    ${ActiveUserAvatar.fragments.user}
    ${Avatar.fragments.ethereumAccount}
    ${Avatar.fragments.starkwareAccount}
    ${MyClubLink.fragments.user}
  ` as TypedDocumentNode<OwnerAccount_account>,
};

export default OwnerAccount;
