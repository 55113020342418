import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineS, LabelS } from '@sorare/core/src/atoms/typography';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { LiveGameView } from '../LiveGameView';
import { FeaturedPlayersOutperformers } from './FeaturedPlayersOutperformers';
import {
  PastGameView_anyCard,
  PastGameView_anyGame,
  PastGameView_basketballPlayerGameScore,
  PastGameView_so5Fixture,
} from './__generated__/index.graphql';

const ResponsiveWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--quadruple-unit);
  flex-wrap: wrap;

  & > * {
    flex-basis: 100%;
    align-self: stretch;
    border-radius: var(--double-unit);

    @media (${tabletAndAbove}) {
      flex-basis: calc(50% - var(--double-unit));
    }
  }
`;

type Props = {
  fixture: PastGameView_so5Fixture;
  game: PastGameView_anyGame;
  allMyCards: PastGameView_anyCard[];
  playerGameScores: PastGameView_basketballPlayerGameScore[];
  openPlayerInfoModal: (playerSlug: string) => void;
  openCardInfoModal: (cardSlug: string) => void;
  setMouseOnHoverPlayer?: (playerSlug: string | null) => void;
  mouseOnHoverPlayer?: string | null;
};

export const PastGameView = ({
  fixture,
  game,
  allMyCards,
  playerGameScores,
  openPlayerInfoModal,
  openCardInfoModal,
  mouseOnHoverPlayer = null,
  setMouseOnHoverPlayer,
}: Props) => {
  const homePlayerGameScores = playerGameScores.filter(
    pS => pS.anyPlayerGameStats.anyTeam.slug === game.homeTeam?.slug
  );
  const awayPlayerGameScores = playerGameScores.filter(
    pS => pS.anyPlayerGameStats.anyTeam.slug === game.awayTeam?.slug
  );

  const onMouseHoverPlayer = setMouseOnHoverPlayer as (
    playerSlug: string
  ) => void;

  const onMouseLeavePlayer = useCallback(() => {
    setMouseOnHoverPlayer?.(null);
  }, [setMouseOnHoverPlayer]);

  return (
    <LiveGameView
      fixture={fixture}
      game={game}
      allMyCards={allMyCards}
      playerGameScores={playerGameScores}
      openPlayerInfoModal={openPlayerInfoModal}
      openCardInfoModal={openCardInfoModal}
      setMouseOnHoverPlayer={setMouseOnHoverPlayer}
      mouseOnHoverPlayer={mouseOnHoverPlayer}
    >
      <ResponsiveWrapper>
        <FeaturedPlayersOutperformers
          team={game.awayTeam!}
          title={
            <HeadlineS as="h4">
              <FormattedMessage
                id="GameView.headings.Overperformers"
                defaultMessage="Overperformers"
              />
            </HeadlineS>
          }
          playerGamesScores={awayPlayerGameScores}
          openPlayerInfoModal={openPlayerInfoModal}
          onMouseHoverPlayer={onMouseHoverPlayer}
          onMouseLeavePlayer={onMouseLeavePlayer}
          mouseOnHoverPlayer={mouseOnHoverPlayer}
          over
        />
        <FeaturedPlayersOutperformers
          team={game.homeTeam!}
          title={
            <HeadlineS as="h4">
              <FormattedMessage
                id="GameView.headings.Overperformers"
                defaultMessage="Overperformers"
              />
            </HeadlineS>
          }
          playerGamesScores={homePlayerGameScores}
          openPlayerInfoModal={openPlayerInfoModal}
          onMouseHoverPlayer={onMouseHoverPlayer}
          onMouseLeavePlayer={onMouseLeavePlayer}
          mouseOnHoverPlayer={mouseOnHoverPlayer}
          over
        />
      </ResponsiveWrapper>
      <ResponsiveWrapper>
        <FeaturedPlayersOutperformers
          team={game.awayTeam!}
          title={
            <HeadlineS as="h4">
              <FormattedMessage
                id="GameView.headings.Underperformers"
                defaultMessage="Underperformers"
              />
            </HeadlineS>
          }
          playerGamesScores={awayPlayerGameScores}
          openPlayerInfoModal={openPlayerInfoModal}
          onMouseHoverPlayer={onMouseHoverPlayer}
          onMouseLeavePlayer={onMouseLeavePlayer}
          mouseOnHoverPlayer={mouseOnHoverPlayer}
        />
        <FeaturedPlayersOutperformers
          team={game.homeTeam!}
          title={
            <HeadlineS as="h4">
              <FormattedMessage
                id="GameView.headings.Underperformers"
                defaultMessage="Underperformers"
              />
            </HeadlineS>
          }
          playerGamesScores={homePlayerGameScores}
          openPlayerInfoModal={openPlayerInfoModal}
          onMouseHoverPlayer={onMouseHoverPlayer}
          onMouseLeavePlayer={onMouseLeavePlayer}
          mouseOnHoverPlayer={mouseOnHoverPlayer}
        />
      </ResponsiveWrapper>
      <Horizontal>
        <Vertical>
          <LabelS as="div">
            <FormattedMessage
              id="GameView.help.BestScores"
              defaultMessage="<b>Best scores:</b> Scores of the players who played in the game."
              values={{
                b: Bold,
              }}
            />
          </LabelS>
          <LabelS as="div">
            <FormattedMessage
              id="GameView.help.Overperformers"
              defaultMessage="<b>Overperformers:</b> Positive difference between L10 (last 10 games) average score and current game score."
              values={{
                b: Bold,
              }}
            />
          </LabelS>
          <LabelS as="div">
            <FormattedMessage
              id="GameView.help.Underperformers"
              defaultMessage="<b>Underperformers:</b> Negative difference between L10 (last 10 games) average score and current game score."
              values={{
                b: Bold,
              }}
            />
          </LabelS>
        </Vertical>
      </Horizontal>
    </LiveGameView>
  );
};

PastGameView.fragments = {
  basketballPlayerGameScore: gql`
    fragment PastGameView_basketballPlayerGameScore on BasketballPlayerGameScore {
      id
      anyPlayerGameStats {
        id
        anyTeam {
          slug
        }
      }
      ...LiveGameView_basketballPlayerGameScore
      ...FeaturedPlayersOutperformers_basketballPlayerGameScore
    }
    ${LiveGameView.fragments.basketballPlayerGameScore}
    ${FeaturedPlayersOutperformers.fragments.basketballPlayerGameScore}
  ` as TypedDocumentNode<PastGameView_basketballPlayerGameScore>,
  anyGame: gql`
    fragment PastGameView_anyGame on AnyGameInterface {
      id
      awayTeam {
        slug
        name
        ...FeaturedPlayersOutperformers_team
      }
      homeTeam {
        slug
        name
        ...FeaturedPlayersOutperformers_team
      }
    }
    ${FeaturedPlayersOutperformers.fragments.team}
  ` as TypedDocumentNode<PastGameView_anyGame>,
  so5Fixture: gql`
    fragment PastGameView_so5Fixture on So5Fixture {
      slug
      mySo5Lineups {
        id
        so5Leaderboard {
          slug
          trainingCenter
        }
        so5Appearances {
          id
          anyCard {
            slug
            anyPlayer {
              slug
            }
          }
        }
      }
      ...LiveGameView_so5Fixture
    }
    ${LiveGameView.fragments.so5Fixture}
  ` as TypedDocumentNode<PastGameView_so5Fixture>,
  anyCard: gql`
    fragment PastGameView_anyCard on AnyCardInterface {
      slug
      ...LiveGameView_anyCard
    }
    ${LiveGameView.fragments.anyCard}
  ` as TypedDocumentNode<PastGameView_anyCard>,
};
