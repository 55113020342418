import { MessageDescriptor } from 'react-intl';

import { StatCategory } from '@sorare/core/src/__generated__/globalTypes';
import { statLabels } from '@sorare/core/src/lib/scoring';

import {
  BattingStatKey,
  PitchingStatKey,
  longBattingStatMessages,
  longPitchingStatMessages,
} from 'lib/baseball';

export const DECISIVE_STAT_CATEGORY = 'DECISIVE' as const;

export const SORTED_STAT_CATEGORIES = [
  DECISIVE_STAT_CATEGORY,
  StatCategory.GENERAL,
  StatCategory.DEFENDING,
  StatCategory.POSSESSION,
  StatCategory.PASSING,
  StatCategory.ATTACKING,
  StatCategory.GOALKEEPING,
  StatCategory.HITTING,
  StatCategory.PITCHING,
] as const;

export const isCategoryDecisive = (category: StatCategory) =>
  [
    StatCategory.POSITIVE_DECISIVE_STAT,
    StatCategory.NEGATIVE_DECISIVE_STAT,
  ].includes(category);

export type CustomStatCategory = (typeof SORTED_STAT_CATEGORIES)[number];

export const so5StatToPitchingStatKey: Record<string, PitchingStatKey> = {
  pitching_innings_pitched: 'outs',
  pitching_strikeouts: 'strikeouts',
  pitching_hits_allowed: 'hitsAllowed',
  pitching_earned_runs: 'earnedRuns',
  pitching_walks: 'walks',
  pitching_hit_batsmen: 'hitBatsmen',
  pitching_wins: 'win',
  pitching_saves: 'save',
  pitching_holds: 'hold',
  pitching_relief_appearance: 'reliefAppearance',
};

export const so5StatToBattingStatKey: Record<string, BattingStatKey> = {
  hitting_runs: 'runs',
  hitting_runs_batted_in: 'rbi',
  hitting_singles: 'singles',
  hitting_doubles: 'doubles',
  hitting_triples: 'triples',
  hitting_home_runs: 'homeRuns',
  hitting_walks: 'walks',
  hitting_strikeouts: 'strikeouts',
  hitting_stolen_bases: 'stolenBases',
  hitting_hit_by_pitch: 'hitByPitch',
  hitting_caught_stealing: 'stealsCaught',
};

export const statToLabel = (stat: string): string | MessageDescriptor => {
  if (stat in statLabels) {
    return statLabels[stat as keyof typeof statLabels];
  }

  if (stat in so5StatToBattingStatKey) {
    return longBattingStatMessages[so5StatToBattingStatKey[stat]];
  }

  if (stat in so5StatToPitchingStatKey) {
    return longPitchingStatMessages[so5StatToPitchingStatKey[stat]];
  }

  return stat;
};
