import classnames from 'classnames';
import { useId } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { glossary } from 'lib/glossary';

import loadingGif from './loading.gif';

export interface Props {
  error?: string;
  style?: any;
  small?: boolean;
  smaller?: boolean;
  fullHeight?: boolean;
  grow?: boolean;
  fullScreen?: boolean;
}

const Container = styled.div<{ $white?: boolean }>`
  position: relative;
  color: inherit;
  width: 1em;
  height: 1em;
  & > svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const BaseLoadingIndicator = () => {
  const { formatMessage } = useIntl();
  const id = useId();
  const holeId = `hole-${id}`;

  return (
    <Container aria-label={formatMessage(glossary.loading)}>
      <svg
        width="60"
        height="60"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(17, 17)">
          <g className="star">
            <path
              transform="translate(-17, -17)"
              d="M15.4971 9.78677C16.4213 10.9386 17.6117 11.8484 18.9657 12.4378C20.3198 13.0271 21.7969 13.2784 23.2697 13.1699C23.329 13.1633 23.389 13.1738 23.4426 13.2001C23.4961 13.2264 23.5411 13.2674 23.5722 13.3184C23.6033 13.3694 23.6192 13.4281 23.618 13.4878C23.6169 13.5475 23.5987 13.6056 23.5657 13.6553C22.7562 14.8906 22.2592 16.3042 22.1174 17.7742C21.9756 19.2443 22.1933 20.7268 22.7517 22.094C22.7736 22.1481 22.7799 22.2073 22.7699 22.2648C22.76 22.3224 22.7343 22.376 22.6955 22.4197C22.6567 22.4634 22.6065 22.4954 22.5505 22.5121C22.4946 22.5288 22.4352 22.5296 22.3788 22.5143C20.9522 22.1245 19.452 22.0879 18.0081 22.4077C16.5642 22.7274 15.2199 23.3938 14.0912 24.3494C14.0461 24.3867 13.9915 24.4107 13.9336 24.4189C13.8757 24.4271 13.8167 24.4192 13.7631 24.3959C13.7095 24.3726 13.6634 24.3349 13.6298 24.287C13.5963 24.2391 13.5767 24.1828 13.5732 24.1245C13.5018 22.6499 13.073 21.2148 12.3238 19.9428C11.5746 18.6707 10.5274 17.5999 9.27239 16.8224C9.22294 16.7912 9.18312 16.7468 9.15734 16.6943C9.13157 16.6418 9.1209 16.5831 9.12642 16.5249C9.13193 16.4666 9.15338 16.411 9.18856 16.3643C9.22374 16.3176 9.27128 16.2815 9.32572 16.26C10.7078 15.7374 11.942 14.8863 12.9218 13.7802C13.9016 12.6741 14.5975 11.3462 14.9495 9.91108C14.9633 9.85443 14.9926 9.80276 15.0342 9.76186C15.0758 9.72096 15.1279 9.69245 15.1848 9.67954C15.2417 9.66663 15.301 9.66983 15.3562 9.68877C15.4114 9.70771 15.4602 9.74165 15.4971 9.78677Z"
              fill="currentColor"
            />
          </g>
        </g>
        <defs>
          <mask id={holeId}>
            <rect width="100%" height="100%" fill="white" />
            <g transform="translate(17, 17)">
              <circle className="circle" cx="0" cy="0" r="14" fill="black" />
            </g>
          </mask>
        </defs>
        <circle
          cx="17"
          cy="17"
          r="17"
          fill="currentColor"
          mask={`url(#${holeId})`}
        />
        <style>
          {`  @keyframes starAnimation {
      0% {
            transform: rotate(-10deg) scale(1.0);
        }
        30% {
            transform: rotate(-92deg) scale(0.85);
        }
        100% {
            transform: rotate(-72deg) scale(1.0);
        }
    }
    .star {
        animation: starAnimation 1.35s linear infinite;
    }

    @keyframes circleAnimation {
        0% {
            transform: scale(1);
        }

        30% {
            transform: scale(calc(12 / 14));
        }

        100% {
            transform: scale(1);
        }
    }
    .circle {
        animation: circleAnimation linear 1.35s infinite;
    }`}
        </style>
      </svg>
    </Container>
  );
};

const Root = styled(Horizontal).attrs({ gap: 0, center: true })`
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: auto;
  vertical-align: top;
  &.fullHeight {
    height: 100vh;
  }
  &.fullHeight.white {
    background-color: var(--c-neutral-1000);
  }
  &.grow {
    flex: 1;
  }
  &.fullScreen {
    position: fixed;
    inset: 0;
    overflow: auto;
    z-index: 10;
    background: var(--c-neutral-100);
  }
`;
const Error = styled.span``;
const StyledContainer = styled.span`
  position: relative;
  font-size: calc(8 * var(--unit));
  ${Root}.small & {
    font-size: calc(4 * var(--unit));
  }
  ${Root}.smaller & {
    font-size: calc(2 * var(--unit));
  }
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const LoadingIndicator = ({
  error,
  style = null,
  small,
  smaller,
  fullHeight,
  fullScreen,
  grow,
}: Props) => {
  const { formatMessage } = useIntl();
  const { isMobileApp } = useIsMobileApp();

  return (
    <Root
      className={classnames({
        small,
        smaller,
        fullHeight,
        fullScreen,
        grow,
      })}
      style={style}
    >
      {error ? (
        <Error>{error}</Error>
      ) : (
        <StyledContainer>
          {isMobileApp ? (
            <Container aria-label={formatMessage(glossary.loading)}>
              <img src={loadingGif} alt={formatMessage(glossary.loading)} />
            </Container>
          ) : (
            <BaseLoadingIndicator />
          )}
        </StyledContainer>
      )}
    </Root>
  );
};
