type Props = { active?: boolean };

export const AddFriends2 = ({ active }: Props) => {
  const activeFill = active ? 'currentColor' : 'none';
  const inactiveFill = active ? 'none' : 'currentColor';
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40002 5.4C8.40002 3.41177 10.0118 1.8 12 1.8C13.9882 1.8 15.6 3.41177 15.6 5.4C15.6 7.38822 13.9882 9 12 9C10.0118 9 8.40002 7.38822 8.40002 5.4ZM9.60001 9.9C7.77748 9.9 6.30002 11.3775 6.30002 13.2V22.2C6.30002 22.6971 6.70296 23.1 7.20002 23.1H14.7V20.7H12V16.5H14.7V13.8H17.7V13.2C17.7 11.3775 16.2226 9.9 14.4 9.9H9.60001ZM17.7 15V17.7H20.4V19.5H17.7V22.2H15.9V19.5H13.2V17.7H15.9V15H17.7Z"
        fill={activeFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2 5.39999C10.2 4.40588 11.0059 3.59999 12 3.59999C12.9941 3.59999 13.8 4.40588 13.8 5.39999C13.8 6.3941 12.9941 7.19999 12 7.19999C11.0059 7.19999 10.2 6.3941 10.2 5.39999ZM12 1.79999C10.0118 1.79999 8.4 3.41176 8.4 5.39999C8.4 7.38821 10.0118 8.99999 12 8.99999C13.9882 8.99999 15.6 7.38821 15.6 5.39999C15.6 3.41176 13.9882 1.79999 12 1.79999ZM6.3 13.2C6.3 11.3774 7.77746 9.89999 9.6 9.89999H14.4C16.2225 9.89999 17.7 11.3774 17.7 13.2H15.9C15.9 12.3716 15.2284 11.7 14.4 11.7H9.6C8.77158 11.7 8.1 12.3716 8.1 13.2V22.2H6.3V13.2ZM17.7 17.7V15H15.9V17.7H13.2V19.5H15.9V22.2H17.7V19.5H20.4V17.7H17.7Z"
        fill={inactiveFill}
      />
    </svg>
  );
};
