import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useQuery } from 'hooks/graphql/useQuery';

import {
  DecksQuery,
  DecksQueryVariables,
} from './__generated__/useDecks.graphql';
import { deckFragment } from './fragments';

const DECKS_QUERY = gql`
  query DecksQuery($query: String, $slug: String!, $sport: Sport!) {
    user(slug: $slug) {
      slug
      userSportProfile: anyUserSportProfile(sport: $sport) {
        id
        decks(query: $query) {
          nodes {
            id
            slug
            ...deckFragments_deck
          }
        }
      }
    }
  }
  ${deckFragment}
` as TypedDocumentNode<DecksQuery, DecksQueryVariables>;

type Props = {
  sport: Sport;
  query?: string;
  userSlug?: string;
  skip?: boolean;
};

export const useDecks = ({ sport, query, userSlug, skip }: Props) => {
  const { currentUser } = useCurrentUserContext();

  const slug = userSlug || currentUser?.slug;
  const { data, loading } = useQuery(DECKS_QUERY, {
    variables: {
      sport,
      query: query || '',
      slug: slug || '',
    },
    skip: skip || !slug,
    // decks are exclusively managed by the user in the frontend,
    // no need to refetch them on every page change
    fetchPolicy: 'cache-first',
  });

  return {
    decks: data?.user.userSportProfile?.decks.nodes || [],
    loading,
  };
};
