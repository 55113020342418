import { TypedDocumentNode, gql } from '@apollo/client';

import { HighlightedStatType } from '__generated__/globalTypes';
import { sendSafeError } from 'lib/error';
import { withFragments } from 'lib/gql';
import { Font, getTextSize } from 'lib/text';

import { drawHightlightedStat_AnyCardInterface } from './__generated__/drawHighlightedStat.graphql';
import assistsUrl from './assets/stats/assists.svg';
import beatL10Url from './assets/stats/beat_l10.svg';
import bigChanceCreatedUrl from './assets/stats/big_chance_created.svg';
import cleanSheetUrl from './assets/stats/clean_sheet.svg';
import goalsUrl from './assets/stats/goals.svg';
import lastManTackleUrl from './assets/stats/last_man_tackle.svg';
import penaltyStoppedUrl from './assets/stats/penalty_stopped.svg';
import savesUrl from './assets/stats/saves.svg';
import shotsOnGoalsUrl from './assets/stats/shots_on_goals.svg';

const getStatsInfo = (type: HighlightedStatType) => {
  switch (type) {
    case HighlightedStatType.BIG_CHANCE_CREATED:
      return { icon: bigChanceCreatedUrl, label: 'BIG CHANCES CREATED' };
    case HighlightedStatType.CLEAN_SHEET_60:
      return { icon: cleanSheetUrl, label: 'CLEAN SHEETS' };
    case HighlightedStatType.GOALS:
      return { icon: goalsUrl, label: 'GOALS' };
    case HighlightedStatType.GOAL_ASSIST:
      return { icon: assistsUrl, label: 'ASSISTS' };
    case HighlightedStatType.LAST_MAN_TACKLE:
      return { icon: lastManTackleUrl, label: 'LAST MAN TACKLES' };
    case HighlightedStatType.ONTARGET_SCORING_ATT:
      return { icon: shotsOnGoalsUrl, label: 'SHOTS ON GOAL' };
    case HighlightedStatType.PENALTY_SAVE:
      return { icon: penaltyStoppedUrl, label: 'PENALTY STOPPED' };
    case HighlightedStatType.SAVES:
      return { icon: savesUrl, label: 'SAVES' };
    case HighlightedStatType.BEAT_L10:
      return { icon: beatL10Url, label: 'BEAT L10' };
    default:
      sendSafeError(`Unhandled HighlightedStatType ${type}`);
      return { icon: goalsUrl, label: '' };
  }
};

export const drawHightlightedStat = withFragments(
  async ({
    ctx,
    card,
    x,
    y,
    fontName,
  }: {
    ctx: CanvasRenderingContext2D;
    card: drawHightlightedStat_AnyCardInterface;
    x: number;
    y: number;
    fontName: string;
  }) => {
    const highlightedStat = card.cardStats?.highlightedStats[0];
    if (!highlightedStat) {
      return;
    }

    const image = new Image();
    const { icon, label } = getStatsInfo(highlightedStat.type);
    image.src = icon;
    await image.decode();
    const { value } = highlightedStat;

    ctx.save();
    ctx.globalCompositeOperation = 'overlay';
    ctx.globalAlpha = 0.3;
    ctx.drawImage(image, x + 36, y, 200, 200);
    ctx.restore();

    ctx.fillStyle = '#fff';
    ctx.textAlign = 'center';
    ctx.font = `600 115px ${fontName}`;

    ctx.fillText(value.toString(), x + 136, y + 126);

    const font = `32px ${fontName}` as Font;
    const { width: titleWidth } = getTextSize(label, font);

    const scale = Math.min(272 / titleWidth, 1);
    ctx.font = font;
    ctx.save();
    ctx.translate(x + 136, y + 166);
    ctx.scale(scale, scale);
    ctx.fillText(label, 0, 0);
    ctx.restore();
  },
  {
    AnyCardInterface: gql`
      fragment drawHightlightedStat_AnyCardInterface on AnyCardInterface {
        slug
        cardStats {
          id
          highlightedStats {
            type
            name
            value
          }
        }
      }
    ` as TypedDocumentNode<drawHightlightedStat_AnyCardInterface>,
  }
);
