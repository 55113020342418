import { ReactNode, memo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { catchAll } from '@sorare/routing';

import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Horizontal } from 'atoms/layout/flex';
import { NotIOsAppFeature } from 'components/BuyableFeature';
import { Notifications } from 'components/notification/Notifications';
import { ResponsiveSearchBar } from 'components/search/ResponsiveSearchBar';
import {
  ANY_SPORT_MARKET,
  FOOTBALL_PLAY_RIVALS_WILDCARD,
} from 'constants/routes';
import { useSportContext } from 'contexts/sport';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import { useIsDesktopAndAbove } from 'hooks/device/useIsDesktopAndAbove';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { Balances } from 'routing/MultiSportAppBar/Balances';
import AppBarProvider from 'routing/MultiSportAppBar/context/Provider';
import { mobileAndAbove, tabletAndAbove } from 'style/mediaQuery';

import { MasterSwitch } from '../MasterSwitch';
import { CoinsAndBalances } from './CoinsAndBalances';
import { LandingAppHeader } from './LandingAppHeader';

const Container = styled(FullWidthContainer)`
  border-bottom: 1px solid var(--c-neutral-300);
`;

const Left = styled(Horizontal)`
  grid-area: left;
  flex-shrink: 1;
  min-width: 0;
`;
const SubNav = styled(Horizontal).attrs({ id: 'subnav', gap: 0.5 })`
  @media ${tabletAndAbove} {
    gap: var(--unit);
  }
`;

const WebWrapper = styled.div`
  height: 100%;
  gap: var(--unit);
  display: grid;
  grid-template-areas: 'left buttons';
  grid-template-columns: 1fr max-content;
  align-items: center;
  padding: var(--unit) 0;
  @media ${tabletAndAbove} {
    padding: var(--intermediate-unit) 0;
  }
  @media ${tabletAndAbove} {
    align-items: center;
  }
  &:has(${Left} button) {
    h1 {
      text-align: center;
      @media ${mobileAndAbove} {
        text-align: left;
      }
    }
    ${SubNav} {
      display: none;
      @media ${mobileAndAbove} {
        display: flex;
      }
    }
  }
`;

const IOSWrapper = styled.div`
  /** Page header should be handled by the ios app */
  display: none;
`;

const Buttons = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: flex-end;
  @media ${mobileAndAbove} {
    justify-content: space-between;
    align-items: center;
  }
  > * {
    justify-content: flex-end;
  }
`;

const PageHeader = styled(Horizontal).attrs({ gap: 0.5 })`
  flex: 1;
  max-width: 100%;
  @media ${tabletAndAbove} {
    gap: var(--double-unit);
  }
`;
const PageHeaderRightButton = styled(Horizontal).attrs({ gap: 0.5 })`
  margin-left: auto;
`;

const PageHeaderTitle = styled.div`
  overflow: hidden;
  width: 100%;
`;

const PageHeaderBreadcrumb = styled.div`
  overflow: hidden;
`;

type Props = {
  headerMode?: 'ios' | 'loggedIn';
  isRivals: boolean;
  isMarket: boolean;
  enableMyClubSplit: boolean;
  extraHeaderButton?: ReactNode;
};

const Header = memo(function Header({
  isRivals,
  isMarket,
  enableMyClubSplit,
  headerMode,
  extraHeaderButton,
}: Props) {
  const isDesktopAndAbove = useIsDesktopAndAbove();

  switch (headerMode) {
    case 'ios': {
      return (
        <FullWidthContainer>
          <IOSWrapper>
            <PageHeaderTitle id="page-header-title" />
            <PageHeaderBreadcrumb id="page-header-title-breadcrumb" />
          </IOSWrapper>
        </FullWidthContainer>
      );
    }
    case 'loggedIn': {
      return (
        <AppBarProvider>
          <Container>
            <WebWrapper>
              <Left>
                <PageHeader>
                  <PageHeaderTitle id="page-header-title" />
                  <PageHeaderBreadcrumb id="page-header-title-breadcrumb" />
                  <PageHeaderRightButton id="page-header-right-button" />
                </PageHeader>
              </Left>
              <Buttons>
                <Horizontal gap={isDesktopAndAbove ? 2 : 0.5}>
                  <SubNav>
                    {extraHeaderButton}
                    {((!isRivals && isDesktopAndAbove) ||
                      !enableMyClubSplit) && (
                      <CoinsAndBalances compact={!isDesktopAndAbove} />
                    )}
                    {isMarket && !isDesktopAndAbove && enableMyClubSplit && (
                      <NotIOsAppFeature>
                        <Balances />
                      </NotIOsAppFeature>
                    )}
                    <ResponsiveSearchBar />
                    <Notifications />
                  </SubNav>
                  <MasterSwitch />
                </Horizontal>
              </Buttons>
            </WebWrapper>
          </Container>
        </AppBarProvider>
      );
    }
    default: {
      return <LandingAppHeader />;
    }
  }
});

export const AppHeader = ({
  extraHeaderButton,
}: {
  extraHeaderButton?: React.ReactNode;
}) => {
  const { pathname } = useLocation();
  const isLoggedIn = useIsLoggedIn();
  const { matchAnySportPath } = useSportContext();
  const { isIosApp } = useIsMobileApp();
  let headerMode: Props['headerMode'] = isIosApp ? 'ios' : undefined;
  headerMode ||= isLoggedIn ? 'loggedIn' : undefined;
  const isRivals = !!matchPath(FOOTBALL_PLAY_RIVALS_WILDCARD, pathname);
  const isMarket = !!matchAnySportPath(catchAll(ANY_SPORT_MARKET), pathname);
  const {
    flags: { enableMyClubSplit = false },
  } = useFeatureFlags();
  return (
    <Header
      headerMode={headerMode}
      isRivals={isRivals}
      isMarket={isMarket}
      enableMyClubSplit={enableMyClubSplit}
      extraHeaderButton={extraHeaderButton}
    />
  );
};
