import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { HeadlineL, HeadlineXS } from 'atoms/typography';

import { FileUploadStep, Props } from '../FileUploadStep';

const List = styled.ul`
  margin: 0;
  padding-left: var(--triple-unit);

  li {
    list-style-type: disc;
  }
`;

export const BankStatementUpload = (
  props: Omit<Props, 'children' | 'name'>
) => {
  return (
    <FileUploadStep name="bankStatement" {...props}>
      <HeadlineL>
        <FormattedMessage
          id="UnblockWallet.BankStatementUpload.title"
          defaultMessage="Upload a bank statement"
        />
      </HeadlineL>
      <HeadlineXS>
        <FormattedMessage
          id="UnblockWallet.BankStatementUpload.description"
          defaultMessage="The bank statement must be dated within the last 3 months. Please ensure that the bank statement you provide includes the following information:"
        />
      </HeadlineXS>
      <List>
        <li>
          <FormattedMessage
            id="UnblockWallet.BankStatementUpload.fullName"
            defaultMessage="Your full name"
          />
        </li>
        <li>
          <FormattedMessage
            id="UnblockWallet.BankStatementUpload.bankLogo"
            defaultMessage="Bank and logo"
          />
        </li>
        <li>
          <FormattedMessage
            id="UnblockWallet.BankStatementUpload.date"
            defaultMessage="Date of issue (no older than 3 months)"
          />
        </li>
      </List>
    </FileUploadStep>
  );
};
