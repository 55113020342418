import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import { Button, Props as ButtonProps } from 'atoms/buttons/Button';
import { Dropdown } from 'atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from 'atoms/icons';
import { Text16 } from 'atoms/typography';
import { LOCALE_KEY, localeConfig } from 'contexts/intl';
import { useEvents } from 'lib/events/useEvents';

import { useSetLocale } from './useSetLocale';

type LocaleOption = {
  label: string;
  value: string;
};

export const AVAILABLE_LOCALES: LocaleOption[] = (
  Object.keys(localeConfig) as LOCALE_KEY[]
)
  .sort()
  .map(locale => {
    return {
      label: localeConfig[locale].name,
      value: locale,
    };
  });

const Label = styled(Text16).attrs({ as: 'label' })`
  display: flex;
  padding: var(--unit) var(--quadruple-unit) var(--unit) var(--double-unit);
  color: var(--c-neutral-600);
  &:hover,
  &:focus,
  &:focus-within,
  &.selected {
    background: var(--c-neutral-300);
    cursor: pointer;
  }
`;
type Props = {
  renderButton?: (
    props: { content: ReactNode } & Pick<
      ButtonProps,
      'onClick' | 'onMouseEnter' | 'onMouseLeave'
    >
  ) => ReactElement;
};

export const LanguageSwitcher = ({
  renderButton = ({ content, ...props }) => (
    <Button {...props} color="tertiary" size="small">
      {content}
    </Button>
  ),
}: Props) => {
  const track = useEvents();
  const trackChange = (source?: string) => {
    track('Click LanguageSwitcher', {
      source,
    });
  };
  const { locale, setLocale } = useSetLocale();
  const currentLocale =
    AVAILABLE_LOCALES.find(i => i.value === locale) ||
    AVAILABLE_LOCALES.find(i => i.value.split('-')[0] === locale);
  return (
    <Dropdown
      onOpen={() => trackChange()}
      label={props =>
        renderButton({
          ...props,
          content: (
            <>
              <span>{currentLocale?.label}</span>
              <FontAwesomeIcon icon={faAngleDown} />
            </>
          ),
        })
      }
      onChange={e => {
        if (e.target) {
          const { value: currentValue } = e.target as HTMLInputElement;
          if (currentValue) {
            setLocale(currentValue);
            trackChange(currentValue);
          }
        }
      }}
    >
      <>
        {AVAILABLE_LOCALES.map(lg => (
          <Label
            key={lg.value}
            className={classnames({
              selected: currentLocale?.value === lg.value,
            })}
          >
            {lg.label}
            <input
              type="radio"
              name="language"
              value={lg.value}
              defaultChecked={currentLocale?.value === lg.value}
              className="sr-only"
            />
          </Label>
        ))}
      </>
    </Dropdown>
  );
};
