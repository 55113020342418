import {
  faCheck,
  faInfoCircle,
  faStar,
} from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineXS, LabelM } from '@sorare/core/src/atoms/typography';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import EarlyAccessDialog from 'components/market/EarlyAccessDialog';
import { earlyAccessFontGradient } from 'lib/earlyAccess';

const Metas = styled(Vertical)`
  align-items: flex-start;
`;

const EarlyAccessMeta = styled(ButtonBase)`
  display: flex;
  gap: var(--unit);
  align-items: center;
  padding: var(--unit) var(--unit);
  border: 1px solid var(--c-neutral-300);
  border-radius: var(--double-unit);
  text-align: left;

  @media ${tabletAndAbove} {
    padding: var(--unit) var(--intermediate-unit);
    border: 1px solid var(--c-neutral-200);
  }
`;

const Title = styled(HeadlineXS)`
  display: flex;
  align-items: baseline;
  gap: var(--half-unit);
`;

const GradientLabelM = styled(LabelM)`
  ${earlyAccessFontGradient}
`;

export const EarlyAccessMetas = () => {
  const {
    flags: { useEnableEarlyAccessFlows },
  } = useFeatureFlags();
  const [dialogOpen, setDialogOpen] = useState(false);

  if (!useEnableEarlyAccessFlows) return null;

  return (
    <Vertical>
      <Title color="var(--c-neutral-600)">
        <FormattedMessage
          id="EarlyAccessMetas.title"
          defaultMessage="Early Access"
        />
        <IconButton
          color="transparent"
          small
          icon={faInfoCircle}
          onClick={() => setDialogOpen(true)}
        />
      </Title>
      <Metas>
        <Horizontal>
          <EarlyAccessMeta
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            <LabelM bold>
              <FormattedMessage
                id="EarlyAccessMetas.playableNow"
                defaultMessage="Playable now"
              />
            </LabelM>
          </EarlyAccessMeta>
          <EarlyAccessMeta
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faStar} />
            <GradientLabelM bold>
              <FormattedMessage
                id="EarlyAccessMetas.specialCard"
                defaultMessage="10% chance to open a special Card"
              />
            </GradientLabelM>
          </EarlyAccessMeta>
        </Horizontal>
      </Metas>
      <EarlyAccessDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </Vertical>
  );
};
