import { TypedDocumentNode, gql } from '@apollo/client';

import { AmountInput } from '@sorare/core/src/__generated__/globalTypes';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import { useCreateEthMigration } from '@sorare/core/src/hooks/starkware/useCreateEthMigration';
import { useSettlementCurrencies } from '@sorare/core/src/hooks/useSettlementCurrencies';
import { generateDealId } from '@sorare/core/src/lib/deal';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { useSingleSaleOfferContext } from 'contexts/singleSaleOffer';

import {
  CreateSingleSaleOfferMutation,
  CreateSingleSaleOfferMutationVariables,
  useCreateSingleSaleOffer_anyCard,
} from './__generated__/useCreateSingleSaleOffer.graphql';
import useApproveMigrator from './useApproveMigrator';
import useMigrateCards from './useMigrateCards';
import usePrepareOffer from './usePrepareOffer';

const CREATE_SINGLE_SALE_OFFER_MUTATION = gql`
  mutation CreateSingleSaleOfferMutation($input: createSingleSaleOfferInput!) {
    createSingleSaleOffer(input: $input) {
      tokenOffer {
        id
        sender {
          ... on User {
            slug
          }
        }
        createdAt
        startDate
        endDate
        blockchainId
        marketFeeAmounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        senderSide {
          id
          cards: anyCards {
            slug
            assetId
            tradeableStatus
            myMintedSingleSaleOffer {
              id
            }
            liveSingleSaleOffer {
              id
            }
          }
        }
        receiverSide {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<
  CreateSingleSaleOfferMutation,
  CreateSingleSaleOfferMutationVariables
>;

export type CreateSingleSaleOfferArgs = {
  amountInput: AmountInput;
  card: useCreateSingleSaleOffer_anyCard;
  duration?: number;
};

const useCreateSingleSaleOffer = () => {
  const [createOffer] = useMutation(CREATE_SINGLE_SALE_OFFER_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const settlementCurrencies = useSettlementCurrencies();
  const { showPopin } = useSingleSaleOfferContext();
  const approveMigrator = useApproveMigrator();
  const migrateCards = useMigrateCards();
  const createEthMigration = useCreateEthMigration();
  const prepareOffer = usePrepareOffer();

  return async ({
    amountInput: receiveAmount,
    card,
    duration,
  }: CreateSingleSaleOfferArgs) => {
    await approveMigrator([card]);
    await createEthMigration();
    const migrationData = await migrateCards([card]);

    const { approvals, errors: signingErrors } = await prepareOffer({
      sendAssetIds: [card.assetId],
      receiveAssetIds: [],
      receiveAmount,
      settlementCurrencies,
    });

    if (signingErrors?.length) return signingErrors;

    const result = await createOffer({
      variables: {
        input: {
          dealId: generateDealId(),
          assetId: card.assetId,
          migrationData,
          duration,
          receiveAmount,
          settlementCurrencies,
          approvals,
        },
      },
    });

    const updatedCard =
      result.data?.createSingleSaleOffer?.tokenOffer?.senderSide?.cards[0];

    if (updatedCard?.myMintedSingleSaleOffer) {
      showPopin({ slug: updatedCard?.slug });
    }

    return null;
  };
};

useCreateSingleSaleOffer.fragments = {
  anyCard: gql`
    fragment useCreateSingleSaleOffer_anyCard on AnyCardInterface {
      slug
      assetId
      ...useApproveMigrator_anyCard
      ...useMigrateCards_anyCard
    }
    ${useApproveMigrator.fragments.anyCard}
    ${useMigrateCards.fragments.anyCard}
  ` as TypedDocumentNode<useCreateSingleSaleOffer_anyCard>,
};

export default useCreateSingleSaleOffer;
