import { defineMessages } from 'react-intl';

import { FiatCurrency } from '__generated__/globalTypes';
import { useIntlContext } from 'contexts/intl';

const currencyMessages = defineMessages({
  [FiatCurrency.EUR]: {
    id: 'createFiatWallet.chooseCurrency.eur',
    defaultMessage: 'Euro (EUR)',
  },
  [FiatCurrency.GBP]: {
    id: 'createFiatWallet.chooseCurrency.gbp',
    defaultMessage: 'Pound sterling (GBP)',
  },
  [FiatCurrency.USD]: {
    id: 'createFiatWallet.chooseCurrency.usd',
    defaultMessage: 'United States dollar (USD)',
  },
});

export const useCurrencyOptions = () => {
  const { formatMessage } = useIntlContext();
  const currencies = Object.values(FiatCurrency).map(c => ({
    label: formatMessage(currencyMessages[c]),
    value: c,
  }));
  return currencies;
};
