import Helmet from 'react-helmet';

import { useIsMobileApp } from 'hooks/useIsMobileApp';

export const MobileAppHelmet = () => {
  const { isMobileApp } = useIsMobileApp();
  if (!isMobileApp) return null;
  return (
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
    </Helmet>
  );
};
