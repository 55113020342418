import { TypedDocumentNode, gql } from '@apollo/client';

import { useCurrentUserContext } from 'contexts/currentUser';

import { useTokenBelongsToUser_anyCard } from './__generated__/useTokenBelongsToUser.graphql';

export const useTokenBelongsToUser = () => {
  const { currentUser } = useCurrentUserContext();

  return (card: useTokenBelongsToUser_anyCard) => {
    return currentUser && card.user?.slug === currentUser.slug;
  };
};

useTokenBelongsToUser.fragments = {
  anyCard: gql`
    fragment useTokenBelongsToUser_anyCard on AnyCardInterface {
      slug
      user {
        slug
      }
    }
  ` as TypedDocumentNode<useTokenBelongsToUser_anyCard>,
};
