type Props = { active?: boolean };

export const PlayFootball = ({ active }: Props) => {
  const activeFill = active ? 'currentColor' : 'none';
  const inactiveFill = active ? 'none' : 'currentColor';
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1 1.5H3.89999V22.5H20.1V1.5ZM5.69999 11.1V12.9H18.3V11.1H5.69999ZM14.7 3.3H12.9V5.09998H11.1V3.3H9.3V6.89998H14.7V3.3ZM14.7 20.7V17.1H9.3V20.7H11.1V18.9H12.9V20.7H14.7Z"
        fill={activeFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.89999 1.5H20.1V22.5H3.89999V1.5ZM5.69999 3.3V20.7H18.3V3.3H5.69999Z"
        fill={inactiveFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29999 3.3H14.7V6.9H9.29999V3.3ZM11.1 3.3V5.1H12.9V3.3H11.1Z"
        fill={inactiveFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29999 20.7H14.7V17.1H9.29999V20.7ZM11.1 20.7V18.9H12.9V20.7H11.1Z"
        fill={inactiveFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.69999 11.1H18.3V12.9H5.69999V11.1Z"
        fill={inactiveFill}
      />
    </svg>
  );
};
