import { useEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import { useConfigContext } from 'contexts/config';

type Props = {
  interval: number;
};

const InstantSearchRefresh = ({ interval }: Props) => {
  const { algoliaIndexes } = useConfigContext();
  const { refresh, results } = useInstantSearch();
  const needRefresh = [
    algoliaIndexes['Ending Soon'],
    algoliaIndexes['Cards On Sale Ending Soon'],
  ].includes(results?.index);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!document.hidden && needRefresh) {
        refresh();
      }
    }, interval);

    return () => clearInterval(timer);
  }, [interval, needRefresh, refresh]);
  return null;
};

export default InstantSearchRefresh;
