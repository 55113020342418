import { FormattedMessage } from 'react-intl';

import { CreateFiatWalletSteps } from '../../type';
import { Content } from '../Content';

const news = [
  {
    id: 'fiat',
    title: (
      <FormattedMessage
        id="walletSetup.whatsNew.fiat.title"
        defaultMessage="Preferred & flexible currency"
      />
    ),
    desc: (
      <FormattedMessage
        id="walletSetup.whatsNew.fiat.desc"
        defaultMessage="Buy, sell, and receive rewards in cash. Or choose to use both cash and ETH."
      />
    ),
  },
  {
    id: 'deposit',
    title: (
      <FormattedMessage
        id="walletSetup.whatsNew.deposit.title"
        defaultMessage="Seamless deposits & withdrawals"
      />
    ),
    desc: (
      <FormattedMessage
        id="walletSetup.whatsNew.deposit.desc"
        defaultMessage="Add cash using a credit/debit card, or opt for a bank transfer to easily add or withdraw funds."
      />
    ),
  },
];
type Props = {
  setStep: (tab: CreateFiatWalletSteps) => void;
  onDismiss?: () => void;
};
export const WhatsNew = (props: Props) => {
  return (
    <Content
      {...props}
      news={news}
      title={
        <FormattedMessage
          id="walletSetup.whatsNew.title"
          defaultMessage="Introducing Cash Wallet"
        />
      }
    />
  );
};
