import classNames from 'classnames';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Reputation } from '@sorare/core/src/atoms/icons/rivals/branded/Reputation';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { StrokedText } from '@sorare/core/src/atoms/typography/StrokedText';
import { glossary } from '@sorare/core/src/lib/glossary';

const Root = styled.div`
  position: relative;
  isolation: isolate;
  width: var(--triple-unit);

  &.reputation {
    width: calc(5 * var(--unit));
  }
`;
const CountWrapper = styled.div`
  position: absolute;
  z-index: 1;

  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);

  .reputation & {
    left: unset;
    top: unset;
    right: 0;
    bottom: 0;
    transform: none;
  }
`;
const BoxPreview = styled.img`
  height: var(--triple-unit);
  object-fit: contain;
`;

type Props = { pictureUrl?: string; nb: number; reputation?: boolean };
export const RewardsPreview = ({ pictureUrl, nb, reputation }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Root className={classNames({ reputation })}>
      <CountWrapper>
        <StrokedText strokeWidth={2}>x{nb}</StrokedText>
      </CountWrapper>
      <Horizontal center>
        {reputation && <Reputation height={24} hideCount />}
        {pictureUrl && (
          <BoxPreview src={pictureUrl} alt={formatMessage(glossary.rewards)} />
        )}
      </Horizontal>
    </Root>
  );
};
