import twentyThree from './23.svg';

type Props = { size?: number };

export const U23EligibleIcon = ({ size = 24 }: Props) => {
  return (
    <img src={twentyThree} alt="Eligible U23" width={size} height={size} />
  );
};

export default U23EligibleIcon;
