import { TypedDocumentNode, gql } from '@apollo/client';
import styled, { CSSProperties } from 'styled-components';

import { CompetitionLogo_so5Leaderboard } from './__generated__/index.graphql';

const Logo = styled.div`
  width: var(--size, 40px);
  height: var(--size, 40px);
  display: flex;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
  }
`;

const CompetitionLogo = ({
  so5Leaderboard,
  size = 5,
}: {
  so5Leaderboard: CompetitionLogo_so5Leaderboard;
  size?: number;
}) => {
  const { svgLogoUrl, seasonality, so5League } = so5Leaderboard;
  return (
    <Logo
      style={
        {
          '--size': `calc(${size} * var(--unit))`,
        } as CSSProperties
      }
    >
      <img
        src={
          seasonality || so5League.displayName === 'Cap'
            ? so5League?.iconUrl
            : svgLogoUrl
        }
        alt={so5League.displayName}
      />
    </Logo>
  );
};

CompetitionLogo.fragments = {
  so5Leaderboard: gql`
    fragment CompetitionLogo_so5Leaderboard on So5Leaderboard {
      slug
      seasonality
      svgLogoUrl
      so5League {
        slug
        displayName
        iconUrl
      }
    }
  ` as TypedDocumentNode<CompetitionLogo_so5Leaderboard>,
};

export default CompetitionLogo;
