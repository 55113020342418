import 'intersection-observer';
import 'core-js/features/array/flat-map';
import 'core-js/features/array/flat';
import 'core-js/features/array/at';
import 'core-js/features/object/from-entries';
import 'core-js/features/string/replace-all';
import { Buffer } from 'buffer';

import smoothscroll from 'smoothscroll-polyfill';
import './bigint';

globalThis.Buffer = Buffer;

smoothscroll.polyfill();
