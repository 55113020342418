import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

export const useIsVisibleInViewport = <T extends Element>(
  callback?: () => void,
  triggerCallbackOnlyOnce: boolean = true
) => {
  const [node, setNode] = useState<T>();
  const [isVisible, setState] = useState<boolean>();
  const isVisibleRef = useRef(false);

  const handleRef = useCallback(
    (el: T | null) => {
      if (el) {
        setNode(el);
      }
    },
    [setNode]
  );

  useEffect(() => {
    if (node && typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(([entry]) => {
        setState(entry.isIntersecting);
        if (
          entry.isIntersecting &&
          triggerCallbackOnlyOnce &&
          !isVisibleRef.current
        ) {
          callback?.();
        }
      });

      observer.observe(node);

      return () => observer.unobserve(node);
    }
    return () => null;
  }, [node, callback, triggerCallbackOnlyOnce]);

  useLayoutEffect(() => {
    if (!isVisibleRef.current && isVisible) {
      isVisibleRef.current = true;
    }
  }, [isVisible]);

  return {
    handleRef,
    node,
    isVisible,
    wasVisibleOnce: isVisibleRef.current,
  };
};
