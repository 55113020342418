import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { Collapse } from '@sorare/core/src/atoms/layout/Collapse';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text14, Text16, Title5 } from '@sorare/core/src/atoms/typography';
import { Card } from '@sorare/core/src/components/card/Card';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import useToggle from '@sorare/core/src/hooks/useToggle';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';

import Team from 'components/Team';
import TokenDrawerSummary from 'components/buyActions/TokenDrawerSummary';
import { AnyCardPriceHistory } from 'components/price/AnyCardPriceHistory';
import CardDescription from 'components/token/CardDescription';

import { InSeasonWarning } from '../InSeasonWarning';
import { TokenSummary_anyCard } from './__generated__/index.graphql';

const Summary = styled(Vertical).attrs({ gap: 2 })``;

const Image = styled.div<{ small: boolean }>`
  flex-shrink: 0;
  width: 52px;
  ${({ small }) => (small ? 'width: 30px;' : '')}
`;

const Metas = styled.div`
  flex-grow: 1;
`;

type Props = {
  card: TokenSummary_anyCard;
  title?: ReactNode;
  price?: ReactNode;
  withoutRecentSales?: boolean;
  small?: boolean;
  context: string;
};

export const TokenSummary = ({
  card,
  title,
  price,
  withoutRecentSales = false,
  small = false,
  context,
}: Props) => {
  const [viewRecentSales, toggleViewRecentSales] = useToggle(false);
  const track = useEvents();

  const { up: isLaptop } = useScreenSize('laptop');

  const onRecentSaleClick = () => {
    if (!viewRecentSales) {
      track('Open Recent Sales', {
        context,
      });
    }
    toggleViewRecentSales();
  };

  return (
    <div>
      <Summary>
        {title}
        <Horizontal gap={1.5}>
          <Image small={small}>
            <Card card={card} />
          </Image>
          <Metas>
            <CardDescription
              withoutLink
              Title={Text16}
              titleColor="var(--c-neutral-1000)"
              detailsColor="var(--c-neutral-600)"
              Details={Text14}
              card={card}
            />
            <Team card={card} />
            {!withoutRecentSales && card.rarityTyped !== Rarity.unique && (
              <button type="button" onClick={onRecentSaleClick}>
                <Text14 color="var(--c-brand-600)">
                  {isLaptop && viewRecentSales ? (
                    <FormattedMessage
                      id="tokenSummary.recentSales.hide"
                      defaultMessage="Hide recent sales"
                    />
                  ) : (
                    <FormattedMessage
                      id="tokenSummary.recentSales.cta"
                      defaultMessage="View recent sales"
                    />
                  )}
                </Text14>
              </button>
            )}
          </Metas>
          {price}
        </Horizontal>
        <InSeasonWarning card={card} />
      </Summary>
      {!isLaptop ? (
        <TokenDrawerSummary
          open={viewRecentSales}
          onBackdropClick={toggleViewRecentSales}
          title={
            <Title5>
              <FormattedMessage
                id="tokenSummary.recentSalesDrawer.title"
                defaultMessage="Recent Sales"
              />
            </Title5>
          }
        >
          <AnyCardPriceHistory context="buy_modal" card={card} />
        </TokenDrawerSummary>
      ) : (
        <Collapse open={viewRecentSales}>
          <AnyCardPriceHistory context="buy_modal" card={card} />
        </Collapse>
      )}
    </div>
  );
};

TokenSummary.fragments = {
  anyCard: gql`
    fragment TokenSummary_anyCard on AnyCardInterface {
      slug
      rarityTyped
      ...Card_anyCard
      ...CardDescription_anyCard
      ...Team_anyCard
      ...InSeasonWarning_anyCard
      ...AnyCardPriceHistory_anyCard
    }
    ${AnyCardPriceHistory.fragments.anyCard}
    ${Card.fragments.anyCard}
    ${CardDescription.fragments.anyCard}
    ${Team.fragments.anyCard}
    ${InSeasonWarning.fragments.anyCard}
  ` as TypedDocumentNode<TokenSummary_anyCard>,
};

export default TokenSummary;
