import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { HeadlineM } from 'atoms/typography';
import Dialog from 'components/dialog';
import { EnterVerificationCode } from 'components/settings/PhoneNumberVerification/EnterVerificationCode';
import { Header } from 'components/settings/PhoneNumberVerification/Header';
import { SendVerificationCode } from 'components/settings/PhoneNumberVerification/SendVerificationCode';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import { useWalletContext } from 'contexts/wallet';
import useOAuthEthWallet from 'hooks/wallets/useOAuthEthWallet';
import { useEvents } from 'lib/events/useEvents';

import messages from './i18n';
import useVerifyPhoneNumber, {
  canProceedToVerificationCodeInput,
} from './useVerifyPhoneNumber';

const Root = styled(Vertical).attrs({ gap: 3 })`
  padding: 0 var(--triple-unit) var(--triple-unit);
`;
const HeaderWrapper = styled.div`
  padding: var(--triple-unit) var(--triple-unit) 0 var(--triple-unit);
`;
const Body = styled(Vertical)`
  padding: var(--unit);
`;

type Props = {
  onCancel?: () => void;
};
export const VerifyPhoneNumber = ({ onCancel }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const [enterPhoneNumber, setEnterPhoneNumber] = useState<boolean>();
  const track = useEvents();
  const { needsCreateEthWallet, promptGenerateKeys } = useOAuthEthWallet();

  const [unverifiedPhoneNumber, setUnverifiedPhoneNumber] = useState(
    currentUser?.unverifiedPhoneNumber || ''
  );

  const { showNotification } = useSnackNotificationContext();
  const verifyPhoneNumber = useVerifyPhoneNumber();
  const { checkUserPhoneNumberVerificationCodeWithRecovery } =
    useWalletContext();

  const navigate = useNavigate();

  const shouldOpen = currentUser && !currentUser.phoneNumberVerified;
  useEffect(() => {
    if (shouldOpen) {
      track('Show VerifyPhoneNumber');
    }
  }, [shouldOpen, track]);

  const enterCode =
    currentUser?.phoneNumberVerificationRequested && !enterPhoneNumber;

  if (!shouldOpen) return null;

  const sendVerificationCode = async (phoneNumber: string) => {
    track('Submit Phone Number', {
      resend: false,
    });
    return verifyPhoneNumber(phoneNumber).then(res => {
      if (canProceedToVerificationCodeInput(res)) {
        setUnverifiedPhoneNumber(phoneNumber);
        setEnterPhoneNumber(false);
      }
      return res || [];
    });
  };

  const submitVerificationCode = async (verificationCode: string) => {
    track('Submit Phone Verification Code', {});
    return checkUserPhoneNumberVerificationCodeWithRecovery(
      verificationCode
    ).then(res => {
      if (!res || res.length === 0) {
        showNotification('phoneNumberVerified');
      }
      return res || [];
    });
  };

  const onBack = () => setEnterPhoneNumber(true);
  const goBack = () => navigate(-1);

  // Case for when the user needs to create an eth wallet. Needed for submitting the verification code.
  if (shouldOpen && needsCreateEthWallet)
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        open
        onClose={onCancel || goBack}
        title={
          <HeadlineM as="h2" className="text-center">
            <FormattedMessage
              id="VerifyPhoneNumber.choosPassword"
              defaultMessage="Secure your Sorare account"
            />
          </HeadlineM>
        }
      >
        <Root>
          <Vertical>
            <p>
              <FormattedMessage
                id="VerifyPhoneNumber.needToSetPassword"
                defaultMessage="You first need secure your Sorare wallet with a password."
              />
            </p>
            <p>
              <FormattedMessage
                id="VerifyPhoneNumber.needToVerifyPhoneNumber"
                defaultMessage="It will be used to verify your phone number to continue. This number will be used as a recovery method for your wallet."
              />
            </p>
          </Vertical>
          <Button
            size="medium"
            onClick={() => {
              promptGenerateKeys();
            }}
          >
            <FormattedMessage
              id="VerifyPhoneNumber.setPassword"
              defaultMessage="Set a Sorare wallet password"
            />
          </Button>
        </Root>
      </Dialog>
    );

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open
      hideHeader
      onClose={onCancel || goBack}
    >
      <>
        <HeaderWrapper>
          <Header {...(enterCode && { onBack })} onClose={onCancel || goBack} />
        </HeaderWrapper>
        <Body>
          {enterCode ? (
            <EnterVerificationCode
              resendVerificationCode={async () => {
                track('Submit Phone Number', {
                  resend: true,
                });
                return verifyPhoneNumber(unverifiedPhoneNumber).then(
                  res => res || []
                );
              }}
              phoneNumber={unverifiedPhoneNumber}
              submitVerificationCode={submitVerificationCode}
              onCancel={onBack}
            />
          ) : (
            <SendVerificationCode
              sendVerificationCode={sendVerificationCode}
              title={messages.title}
            />
          )}
        </Body>
      </>
    </Dialog>
  );
};
