import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

const w = 80; // width of the big square that contains the tilted square
const AbsoluteDiv = styled.div`
  position: absolute;
  transform: translate(-50%, -40px);

  &.originOnTop {
    transform: translate(-50%);
  }
`;
export const GridDiv = styled.div`
  width: ${w}px;
  height: ${w}px;
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
`;

/* numbers here correspond to the width of the enclosing square, not the tilted square

<---- nb --->
_ _ _ _ _ _ _
|    ⟋⟍    |
|  ⟋    ⟍  |
|⟋        ⟍|
|⟍        ⟋|
|  ⟍    ⟋  |
|    ⟍⟋    |
 ‾ ‾ ‾ ‾ ‾ ‾
*/
type Props = {
  horizontalDelta?: number;
  verticalDelta?: number;
  children: ReactNode;
  maxWidth?: number;
  className?: string;
  originOnTop?: boolean;
};
export const BackgroundAligned = ({
  horizontalDelta = 0,
  verticalDelta = 0,
  children,
  maxWidth,
  originOnTop,
  className,
}: Props) => {
  return (
    <AbsoluteDiv
      className={classNames(className, { originOnTop })}
      style={{
        left: `calc(50% + ${horizontalDelta * w}px)`,
        top: `calc(${originOnTop ? '0%' : '50%'} + ${verticalDelta * w}px)`,
        maxWidth: maxWidth ? `${maxWidth * w}px` : 'none',
      }}
    >
      {children}
    </AbsoluteDiv>
  );
};
