import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { LabelM } from '@sorare/core/src/atoms/typography';
import { isType } from '@sorare/core/src/gql';

import { Team_anyCard, Team_commonPlayer } from './__generated__/index.graphql';

const Icon = styled.img`
  height: var(--intermediate-unit);
  vertical-align: baseline;
`;
const Row = styled.span`
  display: inline-flex;
  align-items: baseline;
  gap: var(--half-unit);
`;

type Props = {
  card: Team_anyCard | Team_commonPlayer;
};

const Team = ({ card }: Props) => {
  const { anyPlayer: player } = card;

  const { slug, name, pictureUrl } = isType(card, 'CommonPlayer')
    ? player.activeClub || {}
    : card.anyTeam;

  const activeClubSlug = player?.activeClub?.slug;
  const activeClubName = player?.activeClub?.name;
  const activeClubPictureUrl = player?.activeClub?.pictureUrl || '';

  return (
    <>
      <LabelM color="var(--c-neutral-600)">
        <Row>
          <Icon src={pictureUrl || ''} alt={name} />
          {name}
        </Row>
      </LabelM>
      {activeClubSlug !== slug && (
        <LabelM color="var(--c-neutral-600)">
          <FormattedMessage
            id="USSportsTeam.currentTeam"
            defaultMessage="Current team: {activeClubName}"
            values={{
              activeClubName: (
                <Row>
                  <Icon src={activeClubPictureUrl} alt={activeClubName} />
                  {activeClubName}
                </Row>
              ),
            }}
          />
        </LabelM>
      )}
    </>
  );
};

Team.fragments = {
  anyCard: gql`
    fragment Team_anyCard on AnyCardInterface {
      slug
      anyTeam {
        slug
        name
        pictureUrl
      }
      anyPlayer {
        slug
        activeClub {
          slug
          name
          pictureUrl
        }
      }
    }
  ` as TypedDocumentNode<Team_anyCard>,
  commonPlayer: gql`
    fragment Team_commonPlayer on CommonPlayer {
      id
      anyPlayer {
        slug
        activeClub {
          slug
          name
          pictureUrl
        }
      }
    }
  ` as TypedDocumentNode<Team_commonPlayer>,
};

export default Team;
