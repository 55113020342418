import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { InventoryCounterInline } from '@sorare/core/src/components/clubShop/ClubShopItem/Labels/InventoryCounterInline';
import { ResetIn } from '@sorare/core/src/components/clubShop/ClubShopItem/Labels/ResetIn';
import { isType } from '@sorare/core/src/gql';

import { BuyButton } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/BuyButton';
import { useOnBuyButtonClick } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/BuyButton/useOnBuyButtonClick';
import Details from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/Details';
import ItemImagePreview from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/ItemImagePreview';
import { Requirements } from 'components/shopItems/ShopItemPicker/ItemPreviewDialog/Requirements';

import { ExtraSwapContent_clubShopItem } from './__generated__/index.graphql';

const FlexContainer = styled(Vertical).attrs({ center: true })`
  justify-content: stretch;
`;

type Props = {
  item: ExtraSwapContent_clubShopItem;
  inventory: boolean;
  onBuy?: () => void;
  userCoinBalance: number;
};

export const ExtraSwapContent = ({
  item,
  inventory,
  onBuy,
  userCoinBalance,
}: Props) => {
  const [currentStep, setCurrentStep] = useState<'step1' | 'step2'>(
    inventory ? 'step2' : 'step1'
  );
  const { onBuyButtonClick, loading } = useOnBuyButtonClick(
    () => setCurrentStep('step2'),
    onBuy
  );

  if (!isType(item, 'ExtraSwapShopItem')) {
    return null;
  }

  const leaderboard = item.so5Leaderboard;

  return (
    <>
      <ItemImagePreview
        pictureUrl={item.pictureUrl}
        name={item.name}
        type={item.position}
      />
      <Details item={item} />
      {currentStep === 'step1' ? (
        <>
          <FlexContainer>
            {item.disabled && <Requirements item={item} />}
            {item.myLimitResetAt && (
              <ResetIn myLimitResetAt={item.myLimitResetAt} />
            )}
            {!item.disabled && (
              <InventoryCounterInline
                amount={item.myBalance}
                name={item.name}
              />
            )}
          </FlexContainer>

          <BuyButton
            item={item}
            onClick={() => onBuyButtonClick(item.id)}
            loading={loading}
            cannotSelectYet={!leaderboard?.commonDraftCampaign}
            userCoinBalance={userCoinBalance}
          />
        </>
      ) : null}
    </>
  );
};

ExtraSwapContent.fragments = {
  clubShopItem: gql`
    fragment ExtraSwapContent_clubShopItem on ClubShopItem {
      ... on ShopItemInterface {
        id
        disabled
        myLimitResetAt
        pictureUrl
        name
        position
        myBalance
      }
      ... on ExtraSwapShopItem {
        id
        so5Leaderboard {
          slug
          commonDraftCampaign {
            slug
            status
          }
        }
      }
      ...Details_shopItem
      ...Requirements_clubShopItem
    }
    ${Requirements.fragments.clubShopItem}
    ${Details.fragments.shopItem}
  ` as TypedDocumentNode<ExtraSwapContent_clubShopItem>,
};
