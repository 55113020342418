import { useIntlContext } from 'contexts/intl';

export const useStoreBadge = () => {
  const { locale } = useIntlContext();
  const splittedLocale = locale.split('-')[0];

  return {
    appleBadge: `https://one-badge.com/lang/${splittedLocale}/apple/appstore.svg`,
    googleBadge: `https://one-badge.com/lang/${splittedLocale}/google/playstore.svg`,
  };
};
