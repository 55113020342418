import { unitMapping } from '@sorare/core/src/lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
  hollow?: boolean;
};

export const Jersey = ({ size = 2, className, hollow }: Props) => {
  return (
    <svg
      className={className}
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42753 7.13052L2.42566 2.12865L0 3.99987L2.34667 6.39987L3.73333 5.38654V11.4058H7.99792H12.2667V5.38654L13.6533 6.39987L16 3.99987L13.5729 2.12756L8.56997 7.13052L7.99875 7.70175L7.42753 7.13052ZM12.2667 13.0215H7.99792H3.73333V14.7732H12.2667V13.0215ZM7.99875 5.41685L3.71552 1.13362L3.73333 1.11987H6.08L8 3.03987L9.92 1.11987H12.2667L12.2831 1.13253L7.99875 5.41685Z"
        fill={hollow ? 'url(#jersey-hollow-gradient)' : 'currentColor'}
      />
      <defs>
        <linearGradient
          id="jersey-hollow-gradient"
          x1="0.5"
          y1="7.75"
          x2="9.22771"
          y2="6.81853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8D3DA" />
          <stop offset="0.283178" stopColor="#B3A9F4" />
          <stop offset="0.5401" stopColor="#FBE9FB" />
          <stop offset="1" stopColor="#4F94FD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
