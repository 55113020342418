import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import {
  NBA_MARKET_MOBILE_INSTANT_BUY,
  NBA_MY_CLUB_SLUG_CARDS,
  NBA_PLAYERS_SLUG_CARDS,
  NBA_TEAMS_SLUG_CARDS,
} from '@sorare/core/src/constants/__generated__/routes';
import {
  NBA_CARDS_SLUG,
  NBA_INSTANT_BUY_MARKET,
  NBA_MARKET,
  NBA_PRIMARY_MARKET,
  NBA_SECONDARY_MARKET,
  NBA_SECONDARY_MARKET_STACK_SHOW,
} from '@sorare/core/src/constants/routes';

import { MarketSubscriptions } from '@sorare/marketplace/src/components/MarketSubscriptions';

const AUCTION_SUBSCRIPTION_ROUTES = [
  NBA_MARKET,
  NBA_PRIMARY_MARKET,
  NBA_CARDS_SLUG,
  NBA_PLAYERS_SLUG_CARDS,
  NBA_TEAMS_SLUG_CARDS,
  NBA_MY_CLUB_SLUG_CARDS,
];

const OFFERS_SUBSCRIPTION_ROUTES = [
  NBA_MARKET,
  NBA_SECONDARY_MARKET,
  NBA_SECONDARY_MARKET_STACK_SHOW,
  NBA_CARDS_SLUG,
  NBA_PLAYERS_SLUG_CARDS,
  NBA_TEAMS_SLUG_CARDS,
  NBA_MY_CLUB_SLUG_CARDS,
];

const PRIMARY_OFFERS_SUBSCRIPTION_ROUTES = [
  NBA_INSTANT_BUY_MARKET,
  NBA_MARKET_MOBILE_INSTANT_BUY,
  NBA_CARDS_SLUG,
  NBA_PLAYERS_SLUG_CARDS,
];

export const NBAMarketSubscriptions = () => {
  return (
    <MarketSubscriptions
      sports={[Sport.NBA]}
      auctionsRoutes={AUCTION_SUBSCRIPTION_ROUTES}
      offersRoutes={OFFERS_SUBSCRIPTION_ROUTES}
      primaryOffersRoutes={PRIMARY_OFFERS_SUBSCRIPTION_ROUTES}
    />
  );
};
