import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { useLayoutEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAbsoluteURL } from 'hooks/useAbsoluteURL';

import {
  VisitCampaignPage,
  VisitCampaignPageVariables,
} from './__generated__/index.graphql';

const VISIT_CAMPAIGN_PAGE = gql`
  mutation VisitCampaignPage($url: String!) {
    visitCampaignPage(input: { url: $url }) {
      errors {
        message
      }
    }
  }
` as TypedDocumentNode<VisitCampaignPage, VisitCampaignPageVariables>;

export const ReportCampaignVisit = () => {
  const [searchParams] = useSearchParams();
  const [visitCampaignPage] = useMutation(VISIT_CAMPAIGN_PAGE);
  const location = useLocation();
  const href = useAbsoluteURL(location, { stripBaseName: true });

  useLayoutEffect(() => {
    if (!searchParams.get('utm_campaign')) {
      return;
    }
    visitCampaignPage({ variables: { url: href } });
  }, [searchParams, visitCampaignPage, href]);

  return null;
};
