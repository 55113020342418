import { TypedDocumentNode, gql } from '@apollo/client';
import { add, isFuture, isPast } from 'date-fns';

import { auctionCurrentPrice } from 'lib/auctions';

import { useGetAuctionDetails_auction } from './__generated__/useGetAuctionDetails.graphql';

const useGetAuctionDetails = (auction: useGetAuctionDetails_auction | null) => {
  const endDate = auction?.endDate;
  if (!auction) return undefined;
  const price = auctionCurrentPrice(auction);

  const auctionIsOnSale = isFuture(add(auction.endDate, { seconds: 15 }));

  if (auctionIsOnSale && price && endDate) {
    const ended = isPast(endDate);
    return {
      endDate,
      ended,
      price,
      currency: auction?.currency,
    };
  }
  return undefined;
};

useGetAuctionDetails.fragments = {
  auction: gql`
    fragment useGetAuctionDetails_auction on TokenAuction {
      id
      endDate
      currency
      ...auctionCurrentPrice_auction
    }
    ${auctionCurrentPrice.fragments.auction}
  ` as TypedDocumentNode<useGetAuctionDetails_auction>,
};

export default useGetAuctionDetails;
