import { useEffect, useMemo, useState } from 'react';

type FetchPriority = 'high' | 'low' | 'auto';

export default (
  imagesUrls: (string | null | undefined)[],
  fetchPriority: FetchPriority = 'high'
): boolean => {
  const [loaded, setLoaded] = useState(false);

  const stringifiedList = JSON.stringify(imagesUrls.filter(Boolean));
  const memoizedList: string[] = useMemo(
    () => JSON.parse(stringifiedList),
    [stringifiedList]
  );

  useEffect(() => {
    let remainingImagesToLoad = memoizedList.length;
    memoizedList.forEach(url => {
      const img = new Image();
      // https://stackoverflow.com/questions/51470791/javascript-image-blocked-by-cors
      img.crossOrigin = 'anonymous';
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/fetchPriority
      img.fetchPriority = fetchPriority;
      img.onload = () => {
        remainingImagesToLoad -= 1;
        if (remainingImagesToLoad === 0) {
          setLoaded(true);
        }
      };
      img.src = url;
    });
  }, [memoizedList, fetchPriority]);

  return loaded;
};
