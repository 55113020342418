import { ReactNode, useCallback, useEffect, useState } from 'react';

import { CKO_PUBLIC_KEY, RISK_SCRIPT_URL } from 'config';

import RiskJsContextProvider from '.';

// This provider is responsible for loading the Risk.js script and initializing the Risk instance
// Recommanded by Checkout.com to collect device data before processing a payment
interface Props {
  children: ReactNode;
}

const scriptAlreadyLoaded = (src: string) => {
  return document.querySelector(`script[src="${src}"]`);
};

const loadScript = async (src: string) => {
  return new Promise((resolve, reject) => {
    if (scriptAlreadyLoaded(src)) {
      resolve(undefined);
      return;
    }

    const checkoutScript = document.createElement('script');
    checkoutScript.src = src;
    checkoutScript.onload = resolve;
    checkoutScript.onerror = reject;
    document.head.appendChild(checkoutScript);
  });
};

interface RiskInstance {
  publishRiskData: () => Promise<string>;
}

declare global {
  interface Window {
    Risk?: {
      init: (publicKey: string) => RiskInstance;
    };
  }
}

const RiskJsProvider = ({ children }: Props) => {
  const [riskInstance, setRiskInstance] = useState<RiskInstance | undefined>();
  const init = useCallback(() => {
    loadScript(RISK_SCRIPT_URL).then(async () => {
      if (window.Risk) {
        const b = window.Risk.init(CKO_PUBLIC_KEY);
        setRiskInstance(b);
      }
    });
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <RiskJsContextProvider
      value={{
        riskInstance,
      }}
    >
      {children}
    </RiskJsContextProvider>
  );
};

export default RiskJsProvider;
