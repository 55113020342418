import { useTypedParams } from '@sorare/routing';

import { Rarity, Sport } from '@sorare/core/src/__generated__/globalTypes';

import { BoxesLayout } from '@sorare/gameplay/src/layouts/my-cards/BoxesLayout';

import { PageParams } from './__generated__/routeParams';

const MLBBoxesLayout = () => {
  const { type } = useTypedParams<PageParams>();
  return <BoxesLayout sport={Sport.BASEBALL} type={type as Rarity} />;
};

export default MLBBoxesLayout;
