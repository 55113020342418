import { Navigate, generatePath } from 'react-router-dom';

import { FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG } from '@sorare/core/src/constants/__generated__/routes';
import { DEFAULT_SEASON_SLUG } from '@sorare/core/src/constants/routes';

const RivalsMyLineupsPage = () => {
  return (
    <Navigate
      to={generatePath(FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG, {
        seasonSlug: DEFAULT_SEASON_SLUG,
      })}
      replace
    />
  );
};
export default RivalsMyLineupsPage;
