import isPropValid from '@emotion/is-prop-valid';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { StyleSheetManager as SCStyleSheetManager } from 'styled-components';

import { IS_PRERENDER } from 'config';

import { insetPlugin } from './insetPlugin';

function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

type Props = {
  children: ReactElement;
};

const stylisPlugins = [insetPlugin];

export const StyleSheetManager = ({ children }: Props) => {
  return (
    <SCStyleSheetManager
      disableCSSOMInjection={IS_PRERENDER}
      enableVendorPrefixes
      shouldForwardProp={shouldForwardProp}
      stylisPlugins={stylisPlugins}
    >
      {children}
    </SCStyleSheetManager>
  );
};
