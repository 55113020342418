import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Rarity, Sport } from '@sorare/core/src/__generated__/globalTypes';
import { ConditionalWrapper } from '@sorare/core/src/atoms/layout/ConditionalWrapper';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { ListWithDetailLayout } from '@sorare/core/src/components/layout/ListWithDetailLayout';
import { APP_BAR_MOBILE_HEIGHT } from '@sorare/core/src/components/navigation/AppNavigation';
import { FRONTEND_ASSET_HOST } from '@sorare/core/src/constants/assets';
import { ANY_SPORT_MY_CARDS_BOXES_TYPE } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useIsDesktopAndAbove } from '@sorare/core/src/hooks/device/useIsDesktopAndAbove';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { matchAnySportPath } from '@sorare/core/src/lib/routing/generateSportPath';
import { desktopAndAbove } from '@sorare/core/src/style/mediaQuery';

import {
  BoxesLayoutQuery,
  BoxesLayoutQueryVariables,
} from './__generated__/index.graphql';

const BOXES_LAYOUT_QUERY = gql`
  query BoxesLayoutQuery($sport: Sport!) {
    currentUser {
      slug
      unopenedRewardBoxLimited: unopenedProbabilisticBundles(
        sport: $sport
        first: 0
        rarity: limited
        design: PRO
      ) {
        totalCount
      }
      unopenedRewardBoxRare: unopenedProbabilisticBundles(
        sport: $sport
        first: 0
        rarity: rare
        design: PRO
      ) {
        totalCount
      }
      unopenedRewardBoxSuperRare: unopenedProbabilisticBundles(
        sport: $sport
        first: 0
        rarity: super_rare
        design: PRO
      ) {
        totalCount
      }
      unopenedRewardBoxUnique: unopenedProbabilisticBundles(
        sport: $sport
        first: 0
        rarity: unique
        design: PRO
      ) {
        totalCount
      }
      unopenedRewardBoxRivals: unopenedProbabilisticBundles(
        sport: $sport
        first: 0
        design: RIVALS
      ) {
        totalCount
      }
    }
  }
` as TypedDocumentNode<BoxesLayoutQuery, BoxesLayoutQueryVariables>;

const Root = styled(Vertical)`
  height: calc(
    var(--100vh) - var(--current-stack-height) - ${APP_BAR_MOBILE_HEIGHT}px
  );

  @media ${desktopAndAbove} {
    height: 100%;
  }
`;
const OutletContainer = styled.div`
  flex: 1;
  min-height: 0;
  overflow: hidden;
`;

const Content = styled.div`
  padding: var(--unit) 0;
`;
const GridContainer = styled.div`
  width: min-content;
  margin: auto;
  max-width: 100%;
  padding: var(--half-unit) 0;
  overflow-x: auto;

  @media ${desktopAndAbove} {
    width: unset;
    margin: unset;
  }
`;
const Grid = styled.div`
  display: flex;
  gap: var(--unit);
  padding: 0 var(--double-unit);

  @media ${desktopAndAbove} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
  }
`;
const StyledLink = styled(Link)`
  aspect-ratio: 1;
  & > * {
    width: 100%;
    aspect-ratio: 1;
  }
`;
const Item = styled(Horizontal).attrs({ gap: 0, center: true })`
  border: 1px solid var(--c-neutral-300);
  background: radial-gradient(
    40% 40% at 50% 50%,
    #424242 0%,
    var(--c-neutral-100) 100%
  );
  border-radius: var(--unit);
  aspect-ratio: 1;
  position: relative;
  width: calc(10 * var(--unit));

  @media ${desktopAndAbove} {
    width: unset;
  }

  &:hover {
    border-color: var(--c-neutral-500);
  }

  &.selected {
    border-color: var(--c-brand-600);
  }

  &.global {
    img {
      filter: grayscale(1);
    }
  }

  img {
    width: 65%;
    aspect-ratio: 1;
    object-fit: contain;

    @media ${desktopAndAbove} {
      width: calc(8 * var(--unit));
    }
  }

  span {
    font: var(--t-body-m);
    line-height: 1;
    position: absolute;
    right: var(--unit);
    bottom: var(--unit);
  }
`;

type Props = {
  sport: Sport;
  type: Rarity;
};
export const BoxesLayout = ({ sport, type }: Props) => {
  const { generateSportContextPath } = useSportContext();

  const { data, loading } = useQuery(BOXES_LAYOUT_QUERY, {
    variables: {
      sport,
    },
  });
  const isDesktopAndAbove = useIsDesktopAndAbove();
  const { pathname } = useLocation();

  if (loading) return <LoadingIndicator fullScreen />;

  const {
    unopenedRewardBoxLimited,
    unopenedRewardBoxRare,
    unopenedRewardBoxSuperRare,
    unopenedRewardBoxUnique,
    unopenedRewardBoxRivals,
  } = data?.currentUser || {};

  const blockchainRarities = [
    Rarity.limited,
    Rarity.rare,
    Rarity.super_rare,
    Rarity.unique,
  ] as const;
  const boxTypes = [
    ...blockchainRarities,
    ...(sport === Sport.FOOTBALL ? (['rivals'] as const) : []),
  ] as const;

  const unopenedProbabilisticBundlesByRarity: Record<
    (typeof boxTypes)[number],
    number
  > = {
    limited: unopenedRewardBoxLimited?.totalCount ?? 0,
    rare: unopenedRewardBoxRare?.totalCount ?? 0,
    super_rare: unopenedRewardBoxSuperRare?.totalCount ?? 0,
    unique: unopenedRewardBoxUnique?.totalCount ?? 0,
    rivals: unopenedRewardBoxRivals?.totalCount ?? 0,
  };

  const outletContext = {
    unopenedProbabilisticBundlesByRarity,
  };
  return (
    <ConditionalWrapper
      Wrapper={ListWithDetailLayout}
      props={{ outletContext }}
      wrap={isDesktopAndAbove}
    >
      <Root>
        {!isDesktopAndAbove && (
          <OutletContainer>
            <Outlet context={outletContext} />
          </OutletContainer>
        )}
        <Content>
          <Vertical gap={3}>
            {(isDesktopAndAbove ||
              matchAnySportPath(ANY_SPORT_MY_CARDS_BOXES_TYPE, pathname)) && (
              <GridContainer>
                <Grid>
                  {boxTypes.map(boxType => {
                    return (
                      <StyledLink
                        key={boxType}
                        replace
                        to={generateSportContextPath(
                          ANY_SPORT_MY_CARDS_BOXES_TYPE,
                          {
                            params: {
                              type: boxType,
                            },
                          }
                        )}
                        aria-label={`${boxType} box`}
                      >
                        <Item
                          className={classNames({
                            selected:
                              matchAnySportPath(
                                ANY_SPORT_MY_CARDS_BOXES_TYPE,
                                pathname
                              ) && boxType === type,
                          })}
                        >
                          <img
                            src={`${FRONTEND_ASSET_HOST}/football/probabilistic_bundles/${
                              boxType === 'rivals'
                                ? 'rivals-daily-claim-3'
                                : `${boxType}-standard`
                            }/picture.png`}
                            alt=""
                          />
                          <span>
                            {unopenedProbabilisticBundlesByRarity[boxType]}
                          </span>
                        </Item>
                      </StyledLink>
                    );
                  })}
                </Grid>
              </GridContainer>
            )}
          </Vertical>
        </Content>
      </Root>
    </ConditionalWrapper>
  );
};

export default BoxesLayout;
