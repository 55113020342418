import { useEffect } from 'react';

import { useConnectionContext } from '@sorare/core/src/contexts/connection';

const MobileSignUp = () => {
  const { showMobileWebviewSignUpFlow } = useConnectionContext();

  useEffect(() => {
    showMobileWebviewSignUpFlow();
  }, [showMobileWebviewSignUpFlow]);

  return null;
};

export default MobileSignUp;
