import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import {
  GameStatus,
  So5FixtureEvent,
} from '@sorare/core/src/__generated__/globalTypes';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { NBA_PLAY } from '@sorare/core/src/constants/routes';
import { NoIndex } from '@sorare/core/src/contexts/seo';
import { isType, randomizedPollInterval } from '@sorare/core/src/gql';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';

import { GameView } from 'components/game/GameView';

import {
  GameOfBasketballQuery,
  GameOfBasketballQueryVariables,
} from './__generated__/page.graphql';
import { PageParams } from './__generated__/routeParams';

export const GAME_OF_BASKETBALL_QUERY = gql`
  query GameOfBasketballQuery(
    $gameId: ID!
    $gameIdWithoutPrefix: ID!
    $eventType: So5FixtureEvent
  ) {
    anyGame(id: $gameId) {
      id
      homeTeam {
        slug
        anyPlayers {
          nodes {
            slug
            ...GameView_anyPlayer
          }
        }
      }
      mySo5Lineups {
        id
        so5Appearances {
          id
          anyCard {
            slug
            ...GameView_anyCard
          }
        }
      }
      awayTeam {
        slug
        anyPlayers {
          nodes {
            slug
            ...GameView_anyPlayer
          }
        }
      }
      so5Fixture(so5FixtureType: $eventType) {
        slug
        ...GameView_so5Fixture
      }
      anyPlayers {
        slug
        playerGameScore(gameId: $gameIdWithoutPrefix) {
          id
          ... on BasketballPlayerGameScore {
            id
            ...GameView_basketballPlayerGameScore
          }
        }
      }
      ...GameView_anyGame
    }
  }
  ${GameView.fragments.basketballPlayerGameScore}
  ${GameView.fragments.anyGame}
  ${GameView.fragments.anyPlayer}
  ${GameView.fragments.so5Fixture}
  ${GameView.fragments.anyCard}
` as TypedDocumentNode<GameOfBasketballQuery, GameOfBasketballQueryVariables>;

export const GamePage = () => {
  const {
    gameId: gameIdFromParams,
    eventType: eventTypeFromParams = So5FixtureEvent.CLASSIC,
  } = useTypedParams<PageParams>();
  const gameIdDialogParameter = useDialogParam(DialogKey.game);
  const eventTypeDialogParameter = useDialogParam(DialogKey.eventType);
  const gameId = gameIdDialogParameter || gameIdFromParams;
  const eventType = eventTypeDialogParameter || eventTypeFromParams;

  const [pollInterval, setPollInterval] = useState(0);

  const { data } = useQuery(GAME_OF_BASKETBALL_QUERY, {
    variables: {
      gameId: gameId!,
      gameIdWithoutPrefix: idFromObject(gameId!),
      eventType: eventType.toUpperCase() as So5FixtureEvent,
    },
    skip: !gameId,
    pollInterval,
  });

  const anyGame = data?.anyGame;
  const fixture = anyGame?.so5Fixture;

  // Set pollInterval only if game is playing
  useEffect(() => {
    if (anyGame?.statusTyped === GameStatus.playing) {
      setPollInterval(randomizedPollInterval(0.5));
    } else {
      setPollInterval(0);
    }
  }, [anyGame?.statusTyped]);

  if (!gameId) return <Navigate to={NBA_PLAY} />;

  if (!anyGame || !fixture) {
    return <LoadingIndicator fullHeight />;
  }

  if (
    !anyGame?.awayTeam?.anyPlayers.nodes ||
    !anyGame?.homeTeam?.anyPlayers.nodes
  ) {
    return <LoadingIndicator fullHeight />;
  }

  const myCardsInGame = anyGame?.mySo5Lineups
    ?.flatMap(l => l.so5Appearances?.flatMap(({ anyCard }) => anyCard))
    .filter(Boolean);

  const playerGameScores = anyGame.anyPlayers
    .map(
      ({ playerGameScore }) =>
        isType(playerGameScore, 'BasketballPlayerGameScore') && playerGameScore
    )
    .filter(Boolean);

  return (
    <>
      <NoIndex />
      <GameView
        game={anyGame}
        allMyCards={myCardsInGame}
        awayTeamPlayers={anyGame.awayTeam.anyPlayers.nodes}
        homeTeamPlayers={anyGame.homeTeam.anyPlayers.nodes}
        playerGameScores={playerGameScores}
        fixture={fixture}
        inDialog
        backRoute={NBA_PLAY}
      />
    </>
  );
};

export default GamePage;
