import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { NBA_TEAMS_SLUG } from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const OldURL = () => {
  const { slug } = useTypedParams<PageParams>();
  return <Navigate to={generatePath(NBA_TEAMS_SLUG, { slug })} replace />;
};

export default OldURL;
