import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { useMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { useTokenOfferBelongsToUser } from '@sorare/core/src/hooks/useTokenOfferBelongsToUser';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import useHasInsufficientFundsInWallets from 'hooks/useHasInsufficientFundsInWallets';

import TokensAvailableOnPrimary from '../TokensAvailableOnPrimary';
import { TokensAvailableOnPrimaryWhenInsufficientFundsInWallet_anyCard } from './__generated__/index.graphql';

type Props = {
  card: TokensAvailableOnPrimaryWhenInsufficientFundsInWallet_anyCard;
  hitsPerRow: number;
  sport?: Sport;
};

export const TokensAvailableOnPrimaryWhenInsufficientFundsInWallet = ({
  card,
  hitsPerRow,
  sport,
}: Props) => {
  const { toMonetaryAmount } = useMonetaryAmount();
  const {
    anyPlayer: player,
    rarityTyped,
    liveSingleSaleOffer,
    myMintedSingleSaleOffer,
  } = card;

  const hasInsufficientFundsInWallets = useHasInsufficientFundsInWallets();

  const belongsToUser = useTokenOfferBelongsToUser();

  if (
    !sport ||
    !liveSingleSaleOffer ||
    (myMintedSingleSaleOffer && belongsToUser(myMintedSingleSaleOffer))
  )
    return null;

  const {
    receiverSide: { amounts },
  } = liveSingleSaleOffer;

  const { insufficientFundsInEthWallet } = hasInsufficientFundsInWallets(
    toMonetaryAmount(amounts)
  );
  if (insufficientFundsInEthWallet) return null;

  return (
    <TokensAvailableOnPrimary
      rarity={rarityTyped}
      playerSlug={player.slug}
      hitsPerRow={hitsPerRow}
      sport={sport}
    />
  );
};

TokensAvailableOnPrimaryWhenInsufficientFundsInWallet.fragments = {
  anyCard: gql`
    fragment TokensAvailableOnPrimaryWhenInsufficientFundsInWallet_anyCard on AnyCardInterface {
      slug
      rarityTyped
      anyPlayer {
        slug
      }
      liveSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        receiverSide {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
      }
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        ...useTokenOfferBelongsToUser_offer
      }
    }
    ${useTokenOfferBelongsToUser.fragments.offer}
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<TokensAvailableOnPrimaryWhenInsufficientFundsInWallet_anyCard>,
};
export default TokensAvailableOnPrimaryWhenInsufficientFundsInWallet;
