import { useTypedParams } from '@sorare/routing';

import { Rarity, Sport } from '@sorare/core/src/__generated__/globalTypes';

import { EssenceLayout } from '@sorare/gameplay/src/layouts/my-cards/EssenceLayout';

import { PageParams } from './__generated__/routeParams';

const FootballEssenceLayout = () => {
  const { type } = useTypedParams<PageParams>();
  return <EssenceLayout sport={Sport.FOOTBALL} type={type as Rarity} />;
};

export default FootballEssenceLayout;
