import qs from 'qs';
import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { MessagingContext, Prompt } from '@sorare/wallet-shared';
import { useCurrentUserContext } from 'contexts/currentUser';
import { WalletTab, useWalletDrawerContext } from 'contexts/walletDrawer';
import { nullAddress } from 'lib/ethereum';
import { useEvents } from 'lib/events/useEvents';

export default () => {
  const { sendRequest } = useContext(MessagingContext)!;
  const { setCurrentTab, showWallet } = useWalletDrawerContext();
  const track = useEvents();

  const { currentUser } = useCurrentUserContext();
  const location = useLocation();

  const { reset_password_token } = useMemo(
    () => qs.parse(location.search.slice(1)),
    [location.search]
  );

  const needsCreateEthWallet =
    currentUser?.email &&
    (!currentUser.sorareAddress ||
      currentUser.sorareAddress === nullAddress ||
      (!currentUser.starkKey &&
        currentUser.sorarePrivateKey &&
        !reset_password_token));

  const promptGenerateKeys = useCallback(async () => {
    setCurrentTab(WalletTab.GENERATE_KEYS);
    showWallet();
    track('View Set Wallet Password');
    await sendRequest<Prompt>('prompt', {
      type: 'generateKeys',
    });
  }, [sendRequest, setCurrentTab, showWallet, track]);

  return {
    needsCreateEthWallet,
    promptGenerateKeys,
  };
};
