import { defineMessages, useIntl } from 'react-intl';

import { NonComponentWithTooltip } from '@sorare/core/src/atoms/tooltip/NonComponentWithTooltip';
import { Color } from '@sorare/core/src/style/types';

import Icon from './Icon';

const messages = defineMessages({
  title: {
    id: 'transfers.instantBuy.title',
    defaultMessage: 'Instant buy',
  },
});

type Props = {
  color?: Color;
};

export const InstantBuy = ({ color }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <NonComponentWithTooltip title={formatMessage(messages.title)}>
      <Icon color={color} />
    </NonComponentWithTooltip>
  );
};

export default InstantBuy;
