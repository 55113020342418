import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Info } from '@sorare/core/src/atoms/icons/Info';
import { LinkOther } from '@sorare/core/src/atoms/navigation/Box';
import { ExternalLink } from '@sorare/core/src/atoms/navigation/ExternalLink';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { LabelM, Title6 } from '@sorare/core/src/atoms/typography';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import TokenId from '../TokenId';
import { BlockchainInfo_anyCard } from './__generated__/index.graphql';

type Props = {
  card: BlockchainInfo_anyCard;
};

const TooltipWrapper = styled.span`
  display: none;
  @media ${laptopAndAbove} {
    display: inline-block;
    color: var(--c-neutral-600);
    vertical-align: bottom;
  }
`;

const Ellipsis = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const messages = defineMessages({
  ipfsData: {
    id: 'BlockchainInfo.ipfsDataInfo',
    defaultMessage:
      'IPFS is a decentralized storage system that enables the secure and persistent storage of digital content. For Sorare NFTs, the IPFS data includes information about the unique digital asset, such as its image, as well as metadata describing its immutable properties. This ensures the NFT remains independently accessible and verifiable at all times.',
  },
  ipfsPicture: {
    id: 'BlockchainInfo.ipfsPictureInfo',
    defaultMessage:
      'IPFS is a decentralized storage system that enables the secure and persistent storage of digital content. Sorare uses IPFS to store the picture of the NFTs. This ensures the NFT remains independently accessible and verifiable at all times.',
  },
});

const BlockchainInfo = ({ card }: Props) => {
  const { ipfsUrl, ipfsPictureUrl } = card;
  const { formatMessage } = useIntl();

  return (
    <div>
      <TokenId card={card} />
      {ipfsUrl && (
        <>
          <Title6 color="var(--c-neutral-600)">
            <FormattedMessage
              id="BlockchainInfo.ipfsData"
              defaultMessage="IPFS Data {info}"
              values={{
                info: (
                  <TooltipWrapper>
                    <LinkOther
                      as={Tooltip}
                      title={formatMessage(messages.ipfsData)}
                    >
                      <small>
                        <Info />
                      </small>
                    </LinkOther>
                  </TooltipWrapper>
                ),
              }}
            />
          </Title6>
          <LabelM>
            <ExternalLink href={ipfsUrl}>
              <Ellipsis>{ipfsUrl}</Ellipsis>
            </ExternalLink>
          </LabelM>
        </>
      )}
      {ipfsPictureUrl && (
        <>
          <Title6 color="var(--c-neutral-600)">
            <FormattedMessage
              id="BlockchainInfo.ipfsPicture"
              defaultMessage="IPFS Picture {info}"
              values={{
                info: (
                  <TooltipWrapper>
                    <LinkOther
                      as={Tooltip}
                      title={formatMessage(messages.ipfsPicture)}
                    >
                      <small>
                        <Info />
                      </small>
                    </LinkOther>
                  </TooltipWrapper>
                ),
              }}
            />
          </Title6>
          <LabelM>
            <ExternalLink href={ipfsPictureUrl}>
              <Ellipsis>{ipfsPictureUrl}</Ellipsis>
            </ExternalLink>
          </LabelM>
        </>
      )}
    </div>
  );
};

BlockchainInfo.fragments = {
  anyCard: gql`
    fragment BlockchainInfo_anyCard on AnyCardInterface {
      slug
      ipfsUrl
      ipfsPictureUrl
      ...TokenId_anyCard
    }
    ${TokenId.fragments.anyCard}
  ` as TypedDocumentNode<BlockchainInfo_anyCard>,
};

export default BlockchainInfo;
