import { TypedDocumentNode, gql } from '@apollo/client';

import { updateSportProfileInput } from '__generated__/globalTypes';
import { useSnackNotificationContext } from 'contexts/snackNotification';

import {
  UpdateSportProfileMutation,
  UpdateSportProfileMutationVariables,
} from './__generated__/useUpdateSportProfile.graphql';
import { deckFragment } from './decks/fragments';
import useMutation from './graphql/useMutation';

const UPDATE_SPORT_PROFILE_MUTATION = gql`
  mutation UpdateSportProfileMutation($input: updateSportProfileInput!) {
    updateSportProfile(input: $input) {
      userSportProfile {
        ... on UserSportProfileInterface {
          id
          bannerUrl
          bannerColor
          shieldUrl
          clubName
          highlightedDeck {
            slug
            ...deckFragments_deck
          }
          background {
            id
            name
            pictureUrl
          }
          clubBadge {
            id
            name
            pictureUrl
          }
          achievementBadges {
            id
            name
            pictureUrl
          }
          selectedUserSportStatistics {
            id
            name
          }
        }
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${deckFragment}
` as TypedDocumentNode<
  UpdateSportProfileMutation,
  UpdateSportProfileMutationVariables
>;

export default () => {
  const [mutate] = useMutation(UPDATE_SPORT_PROFILE_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const { showNotification } = useSnackNotificationContext();

  return async (input: updateSportProfileInput) => {
    const { data, errors } = await mutate({
      variables: {
        input,
      },
    });

    if (data) {
      return data.updateSportProfile!;
    }
    showNotification('errors', errors);
    return null;
  };
};
