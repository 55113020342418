import { Snackbar } from 'atoms/snackbar/Snackbar';

import { Level, SnackNotificationOptions } from '..';
import { Message } from '../SnackMessage';

type Props = {
  notification: string | null;
  onClose: () => void;
  opts?: SnackNotificationOptions;
};

export const SnackNotification = ({
  onClose,
  notification,
  opts = {},
}: Props) => {
  const { link, autoHideDuration, level = Level.INFO } = opts;
  const defaultAutoHideDuration = link ? null : 5000;

  return (
    <Snackbar
      open={Boolean(notification)}
      autoHideDuration={
        typeof autoHideDuration !== 'undefined'
          ? autoHideDuration
          : defaultAutoHideDuration
      }
      onClose={onClose}
      message={<Message notification={notification} level={level} />}
      action={link}
    />
  );
};
