import { useContext, useEffect, useMemo, useState } from 'react';

import { Dict, Init, LogOut, MessagingContext } from '@sorare/wallet-shared';
import { SIGNUP_AGE_LIMIT } from 'config';
import { useConfigContext } from 'contexts/config';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useIntlContext } from 'contexts/intl';
import useFeatureFlags from 'hooks/useFeatureFlags';

import { messages } from '../../messages';

const selectedFlags = ['lastTermsOfServiceUpdatedAt'];

type SelectedFlagType = (typeof selectedFlags)[number];

const defaultsForSelectedFlags: { [key in SelectedFlagType]?: any } = {};

export default () => {
  const { sendRequest, registerHandler } = useContext(MessagingContext)!;
  const { currentUser, fiatCurrency } = useCurrentUserContext();
  const { formatMessage, dir } = useIntlContext();
  const { exchangeRate } = useConfigContext();

  const { flags } = useFeatureFlags();
  const [, setInitializedAs] = useState<string>();

  const dict = useMemo(
    () =>
      Object.entries(messages).reduce<Dict>((sum, cur) => {
        const key = cur[0] as keyof Dict;
        sum[key] = formatMessage(cur[1], {
          signupAgeLimit: SIGNUP_AGE_LIMIT,
        });
        return sum;
      }, {} as any),
    [formatMessage]
  );

  const featureFlags = useMemo(
    () => ({
      ...defaultsForSelectedFlags,
      ...Object.fromEntries(
        Object.entries(flags).filter(([key]) => selectedFlags.includes(key))
      ),
    }),
    [flags]
  );
  useEffect(() => {
    setInitializedAs(prevState => {
      if (!prevState) {
        return prevState;
      }
      if (currentUser?.id !== prevState) {
        sendRequest<LogOut>('logOut', {
          flushMessagingQueue: true,
        });
        return undefined;
      }
      return prevState;
    });
  }, [currentUser?.id, sendRequest]);

  useEffect(
    () =>
      registerHandler<Init>('init', async () => {
        const result = {
          dict,
          featureFlags,
          currency: fiatCurrency,
          langDir: dir,
          ethRate: exchangeRate.getEthRate(fiatCurrency.code),
        };

        if (!currentUser) {
          setInitializedAs(undefined);
          return { result };
        }

        const {
          id,
          email,
          nickname,
          sorareAddress,
          starkKey,
          sorarePrivateKey,
        } = currentUser;

        setInitializedAs(id);

        return {
          result: {
            ...result,
            user: {
              email,
              nickname,
              address: sorareAddress || undefined,
              userPrivateKey: sorarePrivateKey || undefined,
              starkKey: starkKey || undefined,
            },
          },
        };
      }),
    [
      exchangeRate,
      fiatCurrency,
      currentUser,
      dict,
      registerHandler,
      dir,
      featureFlags,
    ]
  );
};
