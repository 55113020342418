import {
  ReactNode,
  RefCallback,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import type { Material } from 'three';

type Fade = {
  setFade: (v: number) => void;
  addFadeMaterial: RefCallback<Material>;
};
const FadeContext = createContext<Fade>({
  setFade: () => {},
  addFadeMaterial: () => {},
});

type Props = {
  children: ReactNode;
};
export const FadeProvider = ({ children }: Props) => {
  const materials = useRef<Material[]>([]);

  const addFadeMaterial: RefCallback<Material> = useCallback(m => {
    if (m && !materials.current.includes(m)) {
      materials.current.push(m);
    }
  }, []);

  const setFade = useCallback(
    (opacity: number) => {
      materials.current.forEach(m => {
        if (m) {
          m.opacity = opacity;
          m.transparent = true;
          m.needsUpdate = true;
        }
      });
    },
    [materials]
  );

  const value = useMemo(
    () => ({ setFade, addFadeMaterial }),
    [addFadeMaterial, setFade]
  );

  return <FadeContext.Provider value={value}>{children}</FadeContext.Provider>;
};

export const useFade = () => {
  return useContext(FadeContext);
};
