import { ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

const Wrapper = styled(animated.div)`
  position: relative;
  overflow: hidden;
`;
const Gap = styled.div`
  display: flex;
  height: var(--double-unit);
`;

type Props = {
  open?: boolean;
  children: ReactNode;
  className?: string;
  hideGap?: boolean;
};
export const Collapse = ({ open, children, className, hideGap }: Props) => {
  const [measurementDiv, { height }] = useMeasure<HTMLDivElement>();

  const springArg = {
    to: {
      opacity: open ? 1 : 0,
      height: open ? height : 0,
    },
    config: {
      duration: 100,
    },
  };

  return (
    <Wrapper className={className} style={useSpring(springArg)}>
      <div ref={measurementDiv}>
        {!hideGap && <Gap />}
        {children}
      </div>
    </Wrapper>
  );
};
