import { ReactNode, Suspense } from 'react';

import NethoneProvider from '@sorare/core/src/contexts/nethone/Provider';
import RiskJsProvider from '@sorare/core/src/contexts/risk/Provider';
import { lazy } from '@sorare/core/src/lib/retry';

import { Props as PaymentBoxProps } from '../PaymentBox';
import { PaymentProps } from '../PaymentProvider';

const PaymentProvider = lazy(
  async () => import('components/buyActions/PaymentProvider')
);
const PaymentBox = lazy(async () => import('components/buyActions/PaymentBox'));

interface Props {
  fallback?: ReactNode;
  paymentProps: PaymentProps;
  paymentBoxProps: PaymentBoxProps;
}

export const LazyPaymentProvider = ({
  paymentProps,
  paymentBoxProps,
  fallback = null,
}: Props) => {
  return (
    <Suspense fallback={fallback}>
      <NethoneProvider>
        <RiskJsProvider>
          <PaymentProvider {...paymentProps}>
            <PaymentBox {...paymentBoxProps} />
          </PaymentProvider>
        </RiskJsProvider>
      </NethoneProvider>
    </Suspense>
  );
};

export default LazyPaymentProvider;
