import { Color } from 'style/types';

type Props = {
  fill?: Color;
  title?: string;
  hideBorders?: boolean;
} & React.JSX.IntrinsicElements['svg'];
export const Promotion = ({
  fill = 'var(--c-score-high)',
  title,
  hideBorders,
  ...rest
}: Props) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...rest}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8092 12.019L10.9998 6.22968L5.19046 12.019L3.58564 10.4142L10.9998 3L18.4141 10.4142L16.8092 12.019ZM16.8092 18.019L10.9998 12.2297L5.19046 18.019L3.58564 16.4142L10.9998 9L18.4141 16.4142L16.8092 18.019Z"
        fill={fill}
      />
      {!hideBorders && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 3.00006L3.58582 10.4143L5.19065 12.0191L11 6.22974L16.8094 12.0191L18.4142 10.4143L11 3.00006ZM18.2427 13.4143L21.2427 10.4143L11 0.17163L0.757396 10.4143L3.75739 13.4143L0.757396 16.4143L5.1882 20.8451L11 15.0533L16.8119 20.8451L21.2427 16.4143L18.2427 13.4143ZM11 9.00006L3.58582 16.4143L5.19065 18.0191L11 12.2297L16.8093 18.019L18.4142 16.4143L11 9.00006Z"
          fill="#212121"
        />
      )}
    </svg>
  );
};
