import { useCallback, useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { catchAll } from '@sorare/routing';

import { MLB, NBA } from 'constants/__generated__/routes';
import { Sport } from 'protos/events/shared/events';

export const useGetProtoSport = () => {
  const { pathname } = useLocation();
  const pathnameRef = useRef(pathname);

  useEffect(() => {
    pathnameRef.current = pathname;
  }, [pathname]);

  return useCallback(() => {
    if (matchPath(catchAll(NBA), pathnameRef.current)) {
      return Sport.NBA;
    }
    if (matchPath(catchAll(MLB), pathnameRef.current)) {
      return Sport.BASEBALL;
    }
    return Sport.FOOTBALL;
  }, []);
};
