import { TypedDocumentNode, gql } from '@apollo/client';
import { ElementType, Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { PreferredSide } from '__generated__/globalTypes';
import { isType } from 'lib/gql';

import { PlayerHandsInfo_AnyPlayerInterface } from './__generated__/index.graphql';

const battingThrowingHandMessage = defineMessages({
  [PreferredSide.LEFT]: {
    id: 'MLB.playerStats.battingAndThrowingHands.Left',
    defaultMessage: 'Left',
  },
  [PreferredSide.RIGHT]: {
    id: 'MLB.playerStats.battingAndThrowingHands.Right',
    defaultMessage: 'Right',
  },
  [PreferredSide.BOTH]: {
    id: 'MLB.playerStats.battingAndThrowingHands.Both',
    defaultMessage: 'Switch',
  },
});

type Props = {
  as?: ElementType;
  player: PlayerHandsInfo_AnyPlayerInterface;
};

// This component needs to be in core because we're using it in the marketplace package.
export const PlayerHandsInfo = ({ as, player }: Props) => {
  if (!isType(player, 'BaseballPlayer')) return null;

  const Wrapper = as || Fragment;
  return (
    <Wrapper>
      {player.batHand ? (
        <FormattedMessage {...battingThrowingHandMessage[player.batHand]} />
      ) : (
        '-'
      )}
      &nbsp;/&nbsp;
      {player.throwHand ? (
        <FormattedMessage {...battingThrowingHandMessage[player.throwHand]} />
      ) : (
        '-'
      )}
    </Wrapper>
  );
};

PlayerHandsInfo.fragments = {
  AnyPlayerInterface: gql`
    fragment PlayerHandsInfo_AnyPlayerInterface on AnyPlayerInterface {
      slug
      ... on BaseballPlayer {
        slug
        batHand
        throwHand
      }
    }
  ` as TypedDocumentNode<PlayerHandsInfo_AnyPlayerInterface>,
};
