import { TypedDocumentNode, gql } from '@apollo/client';
import {
  FC,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Currency,
  SupportedCurrency,
  TokenPaymentMethod,
} from '@sorare/core/src/__generated__/globalTypes';
import { Title5 } from '@sorare/core/src/atoms/typography';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { formatGqlErrors } from '@sorare/core/src/gql';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { ConversionCreditWithAmounts } from '@sorare/core/src/hooks/useConversionCredits';
import useLoggedCallback from '@sorare/core/src/hooks/useLoggedCallback';
import { useMangopayCreditCardsEnabled } from '@sorare/core/src/hooks/useMangopayCreditCardsEnabled';
import {
  useMonetaryAmount,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { useHandleWalletStateBeforePayment } from '@sorare/core/src/hooks/wallets/useHandleWalletStateBeforePayment';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { payment } from '@sorare/core/src/lib/glossary';
import {
  getMonetaryAmountIndex,
  monetaryAmountFragment,
} from '@sorare/core/src/lib/monetaryAmount';
import { fromWei } from '@sorare/core/src/lib/wei';
import { ClickInstantBuy_Flow } from '@sorare/core/src/protos/events/so5/shared/events';

import LazyPaymentProvider from 'components/buyActions/LazyPaymentProvider';
import CardOverview from 'components/buyActions/PaymentBox/CardOverview';
import {
  PrimaryBuyConfirmationOptions,
  useBuyConfirmationContext,
} from 'contexts/buyingConfirmation';
import useAcceptOffer from 'hooks/offers/useAcceptOffer';
import usePollPrimaryOfferBuyer from 'hooks/usePollPrimaryOfferBuyer';

import { WebPrimaryOfferBuyFieldWrapper_anyCard } from './__generated__/index.graphql';
import { useRefreshSignedAmount } from './useRefreshSignedAmount';

export interface Props {
  card: WebPrimaryOfferBuyFieldWrapper_anyCard;
  primaryBuyConfirmationOptions?: PrimaryBuyConfirmationOptions;
  cta?: ReactNode;
  onSuccess?: () => void;
  onBuyConfirmationClose?: () => void;
  origin: ClickInstantBuy_Flow;
  gameSlug?: string;
  showBuyConfirmation?: boolean;
  onlyCreditCards?: boolean;
  conversionCreditDisclaimer?: ReactNode;
  defaultConversionCredit?: ConversionCreditWithAmounts;
  composeSessionId?: string;
  children: FC<
    React.PropsWithChildren<{
      onClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
      loading: boolean;
      disabled?: boolean;
    }>
  >;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

export const WebPrimaryOfferBuyFieldWrapper = ({
  children,
  card,
  primaryBuyConfirmationOptions,
  onSuccess,
  onBuyConfirmationClose,
  origin,
  gameSlug,
  showBuyConfirmation = true,
  onlyCreditCards = false,
  conversionCreditDisclaimer,
  defaultConversionCredit,
  onClick,
  composeSessionId = '',
}: Props) => {
  const handleWalletStateBeforePayment = useHandleWalletStateBeforePayment();

  const { formatMessage } = useIntlContext();
  const primaryOffer = card.latestPrimaryOffer!;
  const { id, price, cards, signedAmount } = primaryOffer;
  const [priceInfos, setPriceInfos] = useState({ price, signedAmount });
  const { toMonetaryAmount } = useMonetaryAmount();
  const [paymentStarted, setPaymentStarted] = useState(false);
  const { setShowBuyingConfirmation } = useBuyConfirmationContext();

  const acceptOffer = useAcceptOffer();
  const track = useEvents();
  const [polling, setPolling] = useState(false);
  const [pollIsLoading, setPollIsLoading] = useState(false);
  const [timeoutPolling, setTimeoutPolling] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const loggedTogglePaymentStarted = useLoggedCallback<boolean>(b =>
    handleWalletStateBeforePayment(() => {
      setPaymentStarted(b);
    })
  );

  const refresh = useRefreshSignedAmount(idFromObject(id));
  const onPaymentSuccess = useCallback(() => {
    setPaymentStarted(false);
    if (showBuyConfirmation) {
      setShowBuyingConfirmation(true);
    }
    onSuccess?.();
  }, [showBuyConfirmation, setShowBuyingConfirmation, onSuccess]);

  const onPaymentSuccessWithTracking = () => {
    onPaymentSuccess();
  };

  const onPollingEnd = (success: boolean) => {
    setPolling(false);
    if (timeoutPolling) clearTimeout(timeoutPolling);
    if (success) onPaymentSuccessWithTracking();
    setPollIsLoading(false);
  };

  usePollPrimaryOfferBuyer(polling, primaryOffer, onPollingEnd);
  const useMangopayCreditCards = useMangopayCreditCardsEnabled();

  const refreshPriceAndSignedAMountBeforeBuy = async () => {
    const refreshResult = await refresh();
    if (refreshResult?.error)
      return { err: formatGqlErrors([refreshResult.error]) };
    const { price: refreshPrice, signedAmount: refreshSignedAmount } =
      refreshResult?.data?.tokens?.primaryOffer || {};
    if (
      price &&
      refreshSignedAmount &&
      refreshPrice &&
      refreshSignedAmount !== priceInfos.signedAmount
    ) {
      setPriceInfos({ price: refreshPrice, signedAmount: refreshSignedAmount });
      if (
        refreshPrice[getMonetaryAmountIndex(refreshPrice.referenceCurrency)] !==
        price[getMonetaryAmountIndex(price.referenceCurrency)]
      ) {
        // if price has changed, we need to update the payment box
        return {
          warnings: [
            formatMessage({
              id: 'primaryBuyField.Error.paymentBox.priceChangedV2',
              defaultMessage:
                'The price has changed, please review the offer and try again.',
            }),
          ],
        };
      }
    }
    return { signedAmount: refreshSignedAmount };
  };

  const buy = async ({
    conversionCreditId,
    supportedCurrency,
    tokenPaymentMethod,
  }: {
    conversionCreditId?: string;
    supportedCurrency: SupportedCurrency;
    tokenPaymentMethod?: TokenPaymentMethod;
  }) => {
    const refreshResult = await refreshPriceAndSignedAMountBeforeBuy();
    if (refreshResult?.err?.length) return refreshResult;
    if (!refreshResult.signedAmount || !signedAmount)
      return {
        err: [
          formatMessage({
            id: 'primaryBuyField.Error.paymentBox.priceUnvailable',
            defaultMessage: 'The card is unavailable, please try again later.',
          }),
        ],
      };

    const errors = await acceptOffer({
      offerId: id,
      receiveCards: cards,
      conversionCreditId,
      supportedCurrency,
      attemptReference: null,
      tokenPaymentMethod,
      signedAmount: refreshResult.signedAmount || signedAmount || undefined,
    });

    if (!errors || errors.length === 0) {
      return onPaymentSuccess();
    }

    return { err: errors };
  };

  const priceMonetaryAmount =
    (price && toMonetaryAmount(price)) || zeroMonetaryAmount;
  const onBuyButtonClick = (
    e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    track('Click Instant Buy', {
      offerId: id,
      cardSlug: card.slug,
      ethAmount: fromWei(priceMonetaryAmount.wei),
      eurAmount: priceMonetaryAmount.eur / 100,
      gameSlug,
      origin,
      scarcity: card.rarityTyped,
      sport: card.sport,
      composeSessionId,
    });
    loggedTogglePaymentStarted(true);
    onClick?.(e);
  };

  // For mangopay, we do not know if the 3DS has failed or not. We need to fetch the primary offer and check buyer
  const onPaymentSuccessWrapper = () => {
    if (useMangopayCreditCards) {
      setPollIsLoading(true);
      setPolling(true);
      setTimeoutPolling(
        setTimeout(() => {
          setPolling(false);
          setPollIsLoading(false);
        }, 10000)
      );
    } else {
      onPaymentSuccessWithTracking();
    }
  };

  if (!price || !signedAmount) return null;

  return (
    <>
      {children({
        loading: paymentStarted || pollIsLoading,
        onClick: onBuyButtonClick,
      })}
      {paymentStarted && (
        <LazyPaymentProvider
          paymentProps={{
            canChangeRefCurrency: true,
            objectId: id,
            onSuccess: onPaymentSuccessWrapper,
            onBeforeBuyWithCreditCard: refreshPriceAndSignedAMountBeforeBuy,
            signedAmount: signedAmount || undefined,
            onSubmit: buy,
            price,
            cta: payment.confirmAndPay,
            canUseConversionCredit: true,
            currencies: [Currency.FIAT, Currency.ETH],
            conversionCreditDisclaimer,
            sport: card.sport,
            onlyCreditCards,
            defaultConversionCredit,
          }}
          paymentBoxProps={{
            loadingPolling: polling,
            onClose: () => setPaymentStarted(false),
            title: (
              <Title5>
                <FormattedMessage {...payment.paymentBoxTitle} />
              </Title5>
            ),
            tokenPreview: <CardOverview cards={[card]} soldOnPrimary />,
            confirmationProviderStateProps: {
              primaryBuyId: id,
              primaryBuyConfirmationOptions,
              onClose: onBuyConfirmationClose,
            },
          }}
        />
      )}
    </>
  );
};

WebPrimaryOfferBuyFieldWrapper.fragments = {
  anyCard: gql`
    fragment WebPrimaryOfferBuyFieldWrapper_anyCard on AnyCardInterface {
      slug
      sport
      rarityTyped
      latestPrimaryOffer {
        id
        price {
          ...MonetaryAmountFragment_monetaryAmount
        }
        signedAmount
        cards: anyCards {
          slug
          ...useAcceptOffer_anyCard
        }
        ...usePollPrimaryOfferBuyer_primaryOffer
      }
      ...CardOverview_anyCard
    }
    ${monetaryAmountFragment}
    ${useAcceptOffer.fragments.anyCard}
    ${usePollPrimaryOfferBuyer.fragments.primaryOffer}
    ${CardOverview.fragments.anyCard}
  ` as TypedDocumentNode<WebPrimaryOfferBuyFieldWrapper_anyCard>,
};

export default WebPrimaryOfferBuyFieldWrapper;
