import { FormattedMessage } from 'react-intl';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { Caption, Text14 } from '@sorare/core/src/atoms/typography';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';

type Props = {
  wei: bigint;
};

export const SaleRow = ({ wei }: Props) => {
  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: {
      wei,
      referenceCurrency: SupportedCurrency.WEI,
    },
  });
  return (
    <Text14>
      <span>
        <FormattedMessage
          id="NewOfferBuilder.CardRow.SaleRow.onSale"
          defaultMessage="On sale"
        />
      </span>
      {' • '}
      <Text14 as="span">
        {main}
        {!!exponent && (
          <Caption as="span" color="var(--c-neutral-600)">
            {' '}
            ≈ ${exponent}
          </Caption>
        )}
      </Text14>
    </Text14>
  );
};
